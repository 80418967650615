import React, { Component } from 'react'
import moment from "moment";
import { Table, Container, Button, Modal, Icon, Header, Form } from "semantic-ui-react";
import { format } from "../../../helpers";
import { GET_DOCUMENTS_CONTRACTOR } from "../../../graphql/queries/contractors";
import { APPROVAL_DOCUMENT_CONTRACTOR, DELETE_DOCUMENT_CONTRACTOR } from "../../../graphql/mutations/contractors"
import { bugsnagClient } from "../../../bugsnag";
import { percentage, sendRejectedDocumentEmail } from "../../../helpers";
import { client } from "../../../graphql/apollo-config";
import _ from "lodash";
import OnLoading from "../OnLoading";
import { Can } from "../../hoc/permissions";

class DocumentsContractorQuery extends Component {
  state = {
    user: JSON.parse(window.localStorage.getItem("session")) ? JSON.parse(window.localStorage.getItem("session")).user : "",
    open: false,
    docReference: {},
    rejection: false,
    commentRejection: '',
    btnDisable: false,
    loading: true,
    error: false,
    data: null,
    column: null,
    direction: "descending"
  }

  componentWillMount() {
    this.getDocumentsContractor(this.props.first, this.props.first * (this.props.activePage - 1));
  }

  componentDidUpdate(prevProps) {
    const { activePage, first, search } = this.props;

    if (prevProps.activePage !== activePage || prevProps.first !== first) {
      this.getDocumentsContractor(first, first * (activePage - 1));
    }

    if (prevProps.search !== search) {
      const data = this.searchQuery(search, first, activePage);
      this.setState({ data: { contracts: data } });
    }
  }

  searchQuery = (search, first, activePage) => {
    const { contracts } = client.readQuery({ query: GET_DOCUMENTS_CONTRACTOR, variables: { first, skip: first * (activePage -1) }});
    const query = search.toLowerCase();

    return contracts.filter(item => {
      return search ?
        item.project.name.toLowerCase().includes(query) ||
        item.project.cli.toLowerCase().includes(query) ||
        item.contractor.name.toLowerCase().includes(query) ||
        format.date(item.startDate).toLowerCase().includes(query) ||
        format.date(item.finishDate).toLowerCase().includes(query) ||
        item.total.toString().includes(query) : item;
    });
  }

  getDocumentsContractor = async (first, skip = 0) => {
    try {
      const { loading, data } = await client.query({ query: GET_DOCUMENTS_CONTRACTOR, variables: { first, skip }, fetchPolicy: "network-only" });
      const activePage = skip === 0 ? 1 : this.props.activePage;
      const count = _.sum(data.count.map(i => i.documentsContractor.length));
      const rows = _.sum(data.contracts.map(i => i.documentsContractor.length));

      this.props.getCount(count, rows, activePage);
      this.setState({ loading, data });
    } catch (error) {
      this.setState({ error });
    }
  }

  openModal = (document) => {
    this.setState({
      open: true ,
      startDate: document.startDate,
      realFinishDate: document.realFinishDate,
      openPercentage: document.openPercentage,
      closePercentage: document.closePercentage,
      monthPercentage: document.monthPercentage,
      btnDisable: false,
      totalDocumentsContractor: document.totalDocumentsContractor,
      docReference: {
        idContract: document.idContract,
        idDocument: document.id,
        reference: document.refUpload[0].reference,
        description: document.description,
        documentType: document.type,
        nameContractor: document.nameContractor,
        email: document.email
      }
    })
  }

  viewDocument = () => {
    const { reference: file } = this.state.docReference;
    window.open(file, "_blank");
  }

  approvalDocument = async () => {
    try {
      const { docReference: { idContract, idDocument }, user: { id: idUserApproval} } = this.state;
      await APPROVAL_DOCUMENT_CONTRACTOR(idContract, idDocument, idUserApproval);
      this.getDocumentsContractor(this.props.first);
      this.setState({ open: false, docReference: {} })
    } catch (error) {
      bugsnagClient.notify(error.message);
    }
  }

  rejectDocument = async () => {
    this.setState({ btnDisable: true })

    const {
      commentRejection,
      startDate,
      realFinishDate,
      openPercentage,
      closePercentage,
      monthPercentage,
      totalDocumentsContractor = [],
      docReference: { idContract, idDocument, documentType, nameContractor, email, description }} = this.state;

    const contract = {
      id: idContract,
      startDate,
      realFinishDate,
      openPercentage,
      closePercentage,
      monthPercentage,
      documentsContractor: totalDocumentsContractor
    };

    const percentageDocuments = percentage.deletePercentage(documentType, contract);

    try {
      await DELETE_DOCUMENT_CONTRACTOR(idContract, idDocument, percentageDocuments);
      await sendRejectedDocumentEmail(nameContractor, email, commentRejection, description)
      this.getDocumentsContractor(this.props.first);
      this.setState({ open: false, docReference: {}, rejection: false, commentRejection: '' })
    } catch (error) {
      bugsnagClient.notify(error.message);
    }
  }

  closeModal = () => this.setState({ open: false, docReference: {} })

  handleSort = clickedColumn => {
    const { direction, column, data: { contracts } } = this.state;
    const sortBy = clickedColumn !== column ? "descending" : direction !== "ascending" ? "descending" : "ascending";
    const sorting = _.orderBy(contracts, [clickedColumn], [sortBy.replace("ending", "")]);

    this.setState({
      column: clickedColumn,
      data: { contracts: sorting },
      direction: clickedColumn !== column ? "ascending" : direction === "ascending" ? "descending" : "ascending"
    });
  }

  formatMonthlyDocuments = (wildcard) => {
    const { 1: date } = !!wildcard && wildcard.split("MONTH_")
    if (date.length === 6) {
      return _.capitalize(
        moment(date, "MMYYYY").format('MMMYYYY').replace('.','-')
      )
    }
  }

  render() {
    const { loading, error, data, column, direction } = this.state;

    if (loading || error || !data || !data.contracts.length) {
      return <OnLoading loading={loading} error={error} />
    }

    let rowDocumentsContractor = []

    data.contracts.forEach(contract => {
      let {
        id: idContract,
        currency,
        startDate,
        finishDate,
        realFinishDate,
        openPercentage,
        closePercentage,
        monthPercentage,
        total,
        project: { name: nameProject, cli },
        contractor: { name: nameContractor, user: { email }},
        documentsContractor,
        totalDocumentsContractor
      } = contract;

      let coreContract = {
        idContract,
        currency,
        startDate,
        finishDate,
        realFinishDate,
        openPercentage,
        closePercentage,
        monthPercentage,
        total,
        nameProject,
        cli,
        nameContractor,
        email,
        totalDocumentsContractor
      };

      documentsContractor.forEach(document => {
        const { wildcard } = document.refUpload.length ? document.refUpload[0] : {};
        rowDocumentsContractor.push({ ...coreContract, ...document, wildcard })
      })
    })

    if (rowDocumentsContractor.length === 0) {
      return (
        <Container fluid textAlign="center">
          "No se encontraron documentos para aprobar"
        </Container>
      );
    }

    const items = rowDocumentsContractor.map(doc => {
      const type = format.typeDocumentsContractor(doc.type)
      const formatDate = type === 'Mensual' ? this.formatMonthlyDocuments(doc.wildcard) : ''
      return (
        <Table.Row key={doc.id} textAlign="center">
          <Table.Cell>{doc.nameProject}</Table.Cell>
          <Table.Cell>{doc.cli}</Table.Cell>
          <Table.Cell>{doc.nameContractor}</Table.Cell>
          <Table.Cell>{format.date(doc.startDate)}</Table.Cell>
          <Table.Cell>{format.date(doc.finishDate)}</Table.Cell>
          <Table.Cell>{format.currency(doc.total, doc.currency)}</Table.Cell>
          <Table.Cell>{type}</Table.Cell>
          <Table.Cell>{formatDate}</Table.Cell>
          <Table.Cell>{doc.description}</Table.Cell>
          <Table.Cell textAlign="right">
            <Button
              icon="eye"
              content="Ver detalle"
              className="Modal__Form-ButtonAdd Green Small"
              onClick={() => this.openModal(doc)} />
          </Table.Cell>
        </Table.Row>
      )
    })

    return (
      <Container fluid className="Wrapper__Body-Container-Table">
        <Table basic="very" compact="very" textAlign="center" sortable className="Wrapper__Body-Table" singleLine={true}>
          <Table.Header className="Wrapper__Body-Table-Header">
            <Table.Row>
              <Table.HeaderCell
                sorted={column === "project.name" ? direction : "descending"}
                onClick={() => this.handleSort("project.name")}>
                Nombre del proyecto
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "project.cli" ? direction : "descending"}
                onClick={() => this.handleSort("project.cli")}>
                Presupuesto CLI
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "contractor.name" ? direction : "descending"}
                onClick={() => this.handleSort("contractor.name")}>
                Contratista
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "startDate" ? direction : "descending"}
                onClick={() => this.handleSort("startDate")}>
                Fecha inicio
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "finishDate" ? direction : "descending"}
                onClick={() => this.handleSort("finishDate")}>
                Fecha termino
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "total" ? direction : "descending"}
                onClick={() => this.handleSort("total")}>
                Monto contrato
              </Table.HeaderCell>
              <Table.HeaderCell>
                Tipo
              </Table.HeaderCell>
              <Table.HeaderCell>
                Fecha
              </Table.HeaderCell>
              <Table.HeaderCell>
                Nombre de documento
              </Table.HeaderCell>
              <Table.HeaderCell className="Wrapper__Body-Table-Header-NoHover"/>
            </Table.Row>
          </Table.Header>
          <Table.Body className="Wrapper__Body-Table-Body Small">
            {items}
          </Table.Body>
        </Table>

        <Modal size="tiny" open={this.state.open}>
          <Modal.Content>
            <div className="Modal__ApprovalDocument-Flex">
              <Header as='h2'>
                <Icon circular name='folder'/>
                {!this.state.rejection ? 'Verificación de documento' : 'Rechazo de documento'}
              </Header>
              <Icon link name='close' size='big' onClick={this.closeModal}/>
            </div>
            {!this.state.rejection &&
              <div className="Modal__ApprovalDocument-Flex-Center">
                <Icon bordered name='file outline' size="big" />
                <div className="Modal__ApprovalDocument-Doc">
                  <p>{this.state.docReference.description}</p>
                  <Button size="tiny" className="Modal__ApprovalDocument-BtnRed" onClick={this.viewDocument}>Ver</Button>
                </div>
              </div>
            }
            {this.state.rejection &&
              <Form>
                <Form.Group>
                  <Form.TextArea
                    className='Util-Full-With'
                    label="Comentario sobre el rechazo"
                    name="commentRejection"
                    rows="5"
                    value={this.state.commentRejection}
                    onChange={(e) => this.setState({ commentRejection: e.target.value })}
                  />
                </Form.Group>
              </Form>
            }
          </Modal.Content>
          <Can do="approvedocs" on="ContractorDocuments">
            <Modal.Actions>
              {!this.state.rejection ? (
                <React.Fragment>
                  <Button
                    type='button'
                    onClick={() => this.setState({ rejection: true })}>Rechazar</Button>
                  <Button
                    type='button'
                    className="Modal__ApprovalDocument-BtnRed"
                    onClick={this.approvalDocument}>Aprobar</Button>
                </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      type='button'
                      onClick={() => this.setState({ rejection: false, commentRejection: '' })}>Volver</Button>
                    <Button className="Modal__ApprovalDocument-BtnRed" onClick={this.rejectDocument} disabled={this.state.btnDisable}>Confirmar</Button>
                  </React.Fragment>
                )
              }
            </Modal.Actions>
          </Can>
        </Modal>
      </Container>
    )
  }
}

export default DocumentsContractorQuery;
