import React from "react";
import { Button } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye } from "react-icons/io";

export const ActionButtons = ({
  warehouse,
  openEdit,
  openModalDelete,
  openDetails,
  index
}) => {
  return (
    <Button.Group basic>
      <Button
        id={`edit-warehouse-btn-${warehouse.id}`}
        onClick={() => openEdit(warehouse.id)}
        icon
      >
        <IoMdCreate />
      </Button>
      <Button
        id={`delete-warehouse-btn-${warehouse.id}`}
        onClick={() => openModalDelete(true, warehouse, warehouse.orders)}
        icon>
        <IoIosTrash />
      </Button>
      <Button
        onClick={() => openDetails(warehouse, index)}
        icon>
        <IoMdEye />
      </Button>
    </Button.Group>
  );
};