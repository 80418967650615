import gql from "graphql-tag";
import { client } from "../../apollo-config";

const DELETE_DOCUMENT_UPLOADED_AB = async (id) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        deleteRefUploadDocumentAccountBalance(
          where: { id: "${id}" }
        ) {
          id
        }
      }
    `
  });
  return mutation;
};

export default DELETE_DOCUMENT_UPLOADED_AB;
