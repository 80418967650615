import moment from "moment";

moment.defineLocale("climas", {
    parentLocale: "es",
    monthsShort: [
        "ENE",
        "FEB",
        "MAR",
        "ABR",
        "MAY",
        "JUN",
        "JUL",
        "AGO",
        "SEP",
        "OCT",
        "NOV",
        "DIC"
    ]
});

export default (timestamp, format="DD/MMM/YYYY HH:mm") => {
    return moment(timestamp)
        .locale("climas")
        .format(format);
}
