import React from "react";
import { Button } from "semantic-ui-react";
import { IoIosTrash, IoMdCreate, IoMdKeypad} from "react-icons/io";

export const ActionButtons = ({ user, toggleOpenChangePassword, _deleteUsers, toggleOpenCreate }) => {
  return (
    <Button.Group basic>
      <Button
        id={`edit-user-btn-${user.id}`}
        onClick={() => toggleOpenChangePassword(user.id)}
        icon
      >
        <IoMdKeypad />
      </Button>
      <Button
        id={`update-user-btn-${user.id}`}
        onClick={() => toggleOpenCreate(user.id)}
        icon>
        <IoMdCreate />
      </Button>
      <Button
        onClick={() => _deleteUsers(user.id)}
        icon>
        <IoIosTrash />
      </Button>
    </Button.Group>
  );
};