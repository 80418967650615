import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Header, Grid, Modal, Button } from "semantic-ui-react";

export default class ImageDetail extends React.Component {
  render() {
    const images = [];
    this.props.task.images.map(image => {
      images.push({
        original: image.url,
        thumbnail: image.url,
        description: image.alt,
      });
      return images;
    });
    return (
      <Modal open={this.props.open}>
        <Grid columns="equal" padded>
          <Grid.Column floated="left">
            <Header dividing={false}>{this.props.task.taskName}</Header>
          </Grid.Column>
          <Grid.Column floated="right">
            <Button
              content="Cerrar"
              floated="right"
              style={{ background: "#b3373f", color: "#ffffff" }}
              onClick={this.props.closeModal}
            />
          </Grid.Column>
        </Grid>
        <Modal.Content>
          <ImageGallery
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            startIndex={this.props.task.activeIndex}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
