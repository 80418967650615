import React from "react";
import { Button, Segment, Input, Grid, Dropdown, Form } from "semantic-ui-react";
import ClientsQuery from "../../../../Molecules/clientsQuery";
import theme from "../../../../_theme";
import { PaginationBar } from "../../../../Molecules/PaginationBar";
import { getClients } from "../../../../../graphql/queries/clients";
import { useCurrentUser } from "../../../../../hooks";

const entriesOptions = [
  { key: 1, text: '25', value: 25 },
  { key: 2, text: '50', value: 50 }
]

export default class ClientsTemplte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: getClients,
      search: "",
      first: 10,
      skip: 1,
      totalRecords: 0,
      perPageRecords: 0,
      totalRecordsUpdated: false,
      filterVariables: {
        search: [],
        first: 10,
        skip: 1,
        order: null
      }
    };
  }

  onPageChange = (page) => {
    this.setState(prevState => ({
      ...prevState,
      skip: page,
      totalRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        skip: page
      }
    }));
  };

  onPerPageChange = (perPage) => {
    this.setState(prevState => ({
      ...prevState,
      first: perPage,
      skip: 1,
      totalRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        first: perPage,
        skip: 1
      }
    }));
  };

  searchNow = (e, { name, value }) => {
    this.setState(prevState => ({
      ...prevState,
      search: value,
      totalRecordsUpdated: false,
      filterVariables: {
        search: value.split(" "),
        first: prevState.first,
        skip: prevState.skip
      }
    }));
  };

  onCompleteData = data => {
    const totalRecords = data.clients.length;

    if (!this.state.totalRecordsUpdated) {
      if (totalRecords) {
        this.setState(prevState => ({
          ...prevState,
          totalRecordsUpdated: true,
          totalRecords: data.clients[0].count,
          perPageRecords: totalRecords
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          totalRecordsUpdated: true,
          totalRecords: 0,
          perPageRecords: 0
        }));
      }
    }
  };

  onOrderChange = (header) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        orderBy: header.orderEnum
      }
    }));
  }

  onReload = () => {
    this.setState(prevState => ({
      ...prevState,
    }))
  }

  render() {
    const { filter, filterVariables, totalRecords, perPageRecords } = this.state
    const { openCreate, onUpdate, openDetails } = this.props
    const { currentUser: { user: userRol } } = useCurrentUser();

    return (
      <Segment>
        <Grid>
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column width={13}>
              <Form className="Wrapper__Body-Form Jax">
                <Form.Group inline>
                  <Form.Field inline>
                    <label>Buscar</label>
                    <Input
                      id="search2"
                      name="search2"
                      style={theme.styles.searchInput}
                      icon="search"
                      onChange={this.searchNow}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width="3" textAlign="right">
              {userRol !== "PURCHASES_RESPONSIBLE" && (
                <Button
                  basic
                  icon="plus"
                  className="Wrapper__Body-Form-ButtonAdd"
                  id="add-client"
                  content="Cliente"
                  onClick={() => openCreate(false)}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ClientsQuery
                filter={filter}
                filterVariables={filterVariables}
                onUpdate={onUpdate}
                openDetails={openDetails}
                action={this.onCompleteData}
                onReload={this.onReload}
                onOrderChange={this.onOrderChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <PaginationBar
              onPageChange={(p) => this.onPageChange(p)}
              onPerPageChange={(p) => this.onPerPageChange(p)}
              totalRecords={totalRecords}
              perPageRecords={perPageRecords} />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
