import gql from "graphql-tag";

const GET_DOCUMENTS_CONTRACT = gql`
  {
    contracts(where: { status: ACTIVE }) {
      id
      currency
      total
      amountDeductives
      invoiced
      paided
      project {
        name
        cli
      }
      documentsContract {
        id
        type
        status
        concept
        dateElaborated
        iva
        amountIva
        total
        amount
        elaborated
        authorizer
        status
        refUpload{
          id
          type
          reference
          extension
          wildcard
        }
      }
      aditives {
        id
        currency
        total
        amountDeductives
        invoiced
        paided
        documentsAditivas {
          id
          type
          status
          concept
          dateElaborated
          iva
          amountIva
          total
          amount
          elaborated
          authorizer
          status
          refUpload{
            id
            type
            reference
            extension
            wildcard
          }
        }
      }
    }
  }
`;

export default GET_DOCUMENTS_CONTRACT;
