import React from "react";
import { Form, Button, Grid, Segment, Input, Dropdown } from "semantic-ui-react";
import VendorsQuery from "../../Molecules/vendorsQuery";
import { PaginationBar } from "../../Molecules/PaginationBar";
import theme from "../../_theme";
import { getVendors } from "../../../graphql/queries/catalogs";
import { titleCase } from "../../../helpers"

export default class VendorsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: "TODOS",
      filter: getVendors,
      totalRecords: 0,
      perPageRecords: 0,
      perPageRecordsUpdated: true,
      searchBoxUpdated: false,
      totalRecordsUpdated: false,
      filterVariables: {
        search: ""
      },
      options: [
        {
          key: "TODOS",
          value: "TODOS",
          text: "Todos",
        },
        {
          key: "FISICA",
          value: "FISICA",
          text: "Física",
        },
        {
          key: "MORAL",
          value: "MORAL",
          text: "Moral",
        },
        {
          key: "EXTRANJERO",
          value: "EXTRANJERO",
          text: "Extranjero",
        }
      ]
    };
  }

  onPageChange = (page) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        skip: prevState.perPageRecords * (page - 1)
      }
    }))
  };

  onPerPageChange = (perPage) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        first: perPage
      }
    }))
  };

  debounce = (callback, delay) => {
    let timeOutId = null;
    return (...args) => {
      if (timeOutId) clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    }
  }

  searchNow = (e, { name, value }) => {
    if(!value) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords: 0,
        perPageRecords: 10,
        perPageRecordsUpdated: true,
        searchBoxUpdated: false,
        totalRecordsUpdated: false,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        },
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: true,
        filter: getVendors,
        filterVariables: {
          person: prevState.filterVariables.person,
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        }
      }))
    }
  };

  onCompleteData = data => {
    const totalRecords = data.vendors.length

    if (!this.state.totalRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords,
        totalRecordsUpdated: true,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }

    if (!this.state.perPageRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        perPageRecordsUpdated: true,
        perPageRecords: totalRecords
      }))
    }

    if (this.state.searchBoxUpdated) {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: false,
        totalRecords,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }
  };

  onOrderChange = (header) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        orderBy: header.orderEnum
      }
    }));
  }

  onReload = () => {
    this.setState(prevState =>({
      ...prevState,
    }))
  }

  onChangeStatus = (e, { name, value }) => {
    if (value === "TODOS") {
      this.setState(prevState => ({
        ...prevState,
        person: value,
        searchBoxUpdated: true,
        filterVariables: {
          search: prevState.filterVariables.search,
          searchUpper: prevState.filterVariables.search.toUpperCase(),
          searchLower: prevState.filterVariables.search.toLowerCase(),
          searchCaps: titleCase(prevState.filterVariables.search),
        },
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        person: value,
        searchBoxUpdated: true,
        filterVariables: {
          search: prevState.filterVariables.search,
          searchUpper: prevState.filterVariables.search.toUpperCase(),
          searchLower: prevState.filterVariables.search.toLowerCase(),
          searchCaps: titleCase(prevState.filterVariables.search),
          person: value
        },
      }))
    } 
  };

  render() {
    const { filter, filterVariables, totalRecords, perPageRecords, options, person } = this.state
    const { openCreate, onUpdate, openDetails } = this.props

    return (
      <Segment>
        <Grid>
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column width={13}>
              <Form className="Wrapper__Body-Form Jax">
                <Form.Group inline>
                  <Form.Field inline>
                  <label>Tipo de persona</label>
                    <Dropdown
                      fluid
                      selection
                      style={theme.styles.filerByStatus}
                      id="person"
                      name="person"
                      options={options}
                      defaultValue={person}
                      onChange={this.onChangeStatus}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <label>Buscar</label>
                    <Input
                      id="search2"
                      name="search2"
                      style={theme.styles.searchInput}
                      icon="search"
                      onChange={this.debounce(this.searchNow, 500)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right" width={3}>
              <Button
                basic
                onClick={() => openCreate(false)}
                icon="plus"
                content="Proveedor"
                className="Wrapper__Body-Form-ButtonAdd"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column>
              <VendorsQuery
                filter={filter}
                filterVariables={filterVariables}
                action={this.onCompleteData}
                onReload={this.onReload}
                onUpdate={onUpdate}
                openDetails={openDetails}
                onOrderChange={this.onOrderChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <PaginationBar
              onPageChange={(p) => this.onPageChange(p)}
              onPerPageChange={(p) => this.onPerPageChange(p)}
              totalRecords={totalRecords}
              perPageRecords={perPageRecords} />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
