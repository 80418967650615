import React from "react";
import { Icon, Grid, Segment, Header, Button, Input } from "semantic-ui-react";
import PropTypes from "prop-types";

class ViewDocumentLink extends React.Component {
  constructor(props) {
    super(props);
    const status = this.props.refDownload ? "download" : "upload";

    this.state = {
      statusValue: {
        upload: {
          icon: "plus",
          description: "Cargar documentos",
          style: "Outline",
        },
        loading: {
          icon: "cloud upload",
          description: "Cargando documento...",
          style: "Outline"
        },
        loaded: {
          icon: "check",
          description: "Documento cargado",
          style: "Outline"
        }
      },
      status
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.refDownload !== prevProps.refDownload) {
      const status = this.props.refDownload ? "download" : "upload";
      this.setState({
        status
      });
    }
  }

  loadFinished = () => {
    this.setState({ status: "loaded" });
  }

  // return file, document type and document description
  // return loadFinished callback
  // review in server db schemas

  onChange = event => {
    const files = event.target.files;

    const { onSelectFile, documentType, title, ...rest } = this.props;

    this.setState({ status: "loading" });

    if (typeof onSelectFile === "function") onSelectFile(
      files,
      documentType,
      title,
      this.loadFinished,
      rest,
    );
  }

  onClick = event => {
    if (typeof this.props.onClickDownload === "function" && this.props.refDownload !== null) this.props.onClickDownload(this.props.refDownload);
  }

  handleDelete = event => {
    this.props.onClickDelete(this.props.documentType, this.props.refDownload);
  }

  render() {
    const { status, statusValue } = this.state;
    const statusDocument = this.props.statusDocument;
    let fileInput = null;

    const clickHandler = status === "upload" ? () => fileInput.inputRef.click() : this.onClick;

    return (
      <Segment className="Modal__SubTabNav-Documents-Segment">
        <Grid>
          {this.props.icon &&
            <Grid.Column width="2" textAlign="center">
              <Icon circular name="clipboard" size="large"/>
            </Grid.Column>
          }
          <Grid.Column width="8">
            <Header sub className="Modal__SubTabNav-Documents-AsSubTitle">{this.props.title}</Header>
            {this.props.subtitle &&
              <span>{this.props.subtitle}</span>
            }
          </Grid.Column>
          <Grid.Column width="6" textAlign="center" verticalAlign="middle">
            {status !== "download" ? (
              <React.Fragment>
                <Button
                  icon={statusValue[status].icon}
                  content={statusValue[status].description}
                  className={`Modal__Form-ButtonAdd ${statusValue[status].style} Small`}
                  onClick={clickHandler}
                />
                <Input
                  type="file"
                  hidden
                  key={Date.now()}
                  ref={element => {fileInput = element}}
                  onChange={this.onChange}
                  style={{ display: "none" }}
                />
              </React.Fragment>
            ) : (
              <div className="Modal__SubTabNav-Documents-Segment-TextRight">
                <Icon name="eye" size="large" link onClick={clickHandler} />
                {statusDocument !== "APPROVED" &&
                  <Icon name="trash alternate outline" size="large" link onClick={this.handleDelete}/>
                }
              </div>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

ViewDocumentLink.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClickDownload: PropTypes.func,
  onSelectFile: PropTypes.func,
  refDownload: PropTypes.string,
  documentType: PropTypes.string.isRequired
};

export default ViewDocumentLink;
