/**
 *
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your container
 */

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { auth } from "../../../auth";

const getRedirectPath = rol => {
  const PATH = {
    CONTRACTOR: "/contratistas",
    CONTRACTOR_RESPONSIBLE: [
      "/clientes",
      "/estado-de-cuenta",
      "/estado-de-cuenta/new",
      `/estado-de-cuenta/${window.location.pathname.split("a/")[1]}`,
      "/contratistas",
      "/usuarios"
    ],
    MANAGER: "/compras",
    ADMINISTRATOR: "/compras",
    PURCHASES_RESPONSIBLE: [
      "/compras",
      "/inventarios",
      "/catalogos",
      "/catalogos/agregar-almacen",
      `/catalogos/editar-almacen/${window.location.pathname.split("n/")[1]}`,
      `/inventarios/edit/${window.location.pathname.split("t/")[1]}`,
      "/clientes",
      "/estado-de-cuenta",
      "/estado-de-cuenta/new",
      `/estado-de-cuenta/${window.location.pathname.split("a/")[1]}`
    ],
    WAREHOUSE_RESPONSIBLE: [
      "/inventarios",
      "/catalogos",
      "/catalogos/agregar-almacen",
      `/catalogos/editar-almacen/${window.location.pathname.split("n/")[1]}`,
      `/inventarios/edit/${window.location.pathname.split("t/")[1]}`,
    ],
    TECHNICIAN: [
      "/compras",
      "/inventarios",
      `/inventarios/edit/${window.location.pathname.split("t/")[1]}`,
    ],
    ACCOUNT_BALANCE_RESPONSIBLE: [
      "/estado-de-cuenta",
      "/estado-de-cuenta/new",
      `/estado-de-cuenta/${window.location.pathname.split("a/")[1]}`,
      `/avance-de-proyecto`,
      `/avance/${window.location.pathname.split("e/")[1]}`,
    ],
    ADMINISTRATIVE_COORDINATOR: [
      "/clientes",
      "/compras",
      "/estado-de-cuenta",
      "/estado-de-cuenta/new",
      `/estado-de-cuenta/${window.location.pathname.split("a/")[1]}`,
      "/contratistas",
      "/inventarios",
      `/inventarios/edit/${window.location.pathname.split("t/")[1]}`
    ],
    ZONE_COORDINATOR: [
      "/inventarios",
      "/compras",
      "/catalogos",
      "/catalogos/agregar-almacen",
      `/catalogos/editar-almacen/${window.location.pathname.split("n/")[1]}`,
      "/contratistas",
      `/inventarios/edit/${window.location.pathname.split("t/")[1]}`,
    ]
  };
  return PATH[rol];
};

const redirect = async (rol, confirmed) => {
  if (!confirmed) {
    window.localStorage.removeItem("session");
    window.location.pathname = "/auth/login";
  }
  const path = getRedirectPath(rol);
  const currentPath = window.location.pathname;

  if (["ADMINISTRATOR", "MANAGER"].includes(rol)) {
    return true
  } else {
    if (path !== currentPath) {
      if (typeof path === "object") {
        if (path.includes(currentPath)) {
          return true
        } else {
          window.location = path[0];
        }
      } else {
        window.location = getRedirectPath(rol);
      }
    }
  }
};

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth.authState() !== null ? (
          <Component {...props} redirect={redirect(rest.rol, rest.confirmed)} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
}
