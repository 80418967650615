import React from "react";
import { Segment, Grid, Header } from "semantic-ui-react";

const NumericBox = props => {
  return (
    <Segment textAlign="center" className="Modal__SubTabNav-Numeric">
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" className="Modal__SubTabNav-Numeric-Title">Total contratado</Header>
            <Header as="h2" className="Modal__SubTabNav-Numeric-Subtitle">{props.totalContracted}</Header>
          </Grid.Column>
          <Grid.Column>
            <Header as="h3" className="Modal__SubTabNav-Numeric-Title">Total facturado</Header>
            <Header as="h2" className="Modal__SubTabNav-Numeric-Subtitle Green">{props.totalCharged}</Header>
          </Grid.Column>
          <Grid.Column>
            <Header as="h3" className="Modal__SubTabNav-Numeric-Title">Total pagado</Header>
            <Header as="h2" className="Modal__SubTabNav-Numeric-Subtitle Red">{props.totalPaid}</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

export default NumericBox;
