import React from "react";
import deleteClient from "../../../graphql/mutations/clients/deleteClient";
import { bugsnagClient } from "../../../bugsnag";
import DataTable from "../../Organisms/DataTable";
import * as _ from "lodash";
import { climasTemplate } from "../../Organisms/DataTable/climasTemplate";
import { ActionButtons } from "./actionButtons";

const ClientsQuery = props => {
  const {
    openDetails,
    onUpdate,
    filter,
    filterVariables,
    action,
    onReload,
    onOrderChange
  } = props;

  const onDeleteClient = async id => {
    const prompt = window.confirm("¿Desea eliminar el cliente?");

    if (!prompt) return;

    try {
      await deleteClient(id);
      onReload();
    } catch (err) {
      bugsnagClient.notify(err);
    }
  };

  const actionButtonsProps = {
    onDeleteClient,
    openDetails,
    onUpdate
  };

  return (
    <DataTable
      query={filter}
      variables={filterVariables}
      itemsKey="clients"
      template={climasTemplate}
      onOrderChange={onOrderChange}
      onCompleted={action}>
      <DataTable.Column
        label="Nombre comercial"
        name="commercialName"
        sortable />
      <DataTable.Column
        label="Contacto"
        name="contacts"
        content={(data) => _.get(data, "contacts[0].name", "N/A")} />
      <DataTable.Column
        label="Correo electrónico"
        name="contacts"
        content={(data) => _.get(data, "contacts[0].email", "N/A")} />
      <DataTable.Column
        label="Teléfono"
        name="contacts"
        content={(data) => _.get(data, "contacts[0].phone", "N/A")} />
      <DataTable.Column
        label="Ciudad"
        name="city"
        sortable />
      <DataTable.Column
        label="Colonia"
        name="colony"
        sortable />
      <DataTable.Column
        label=" "
        name="actions"
        content={
          (data, col, index) =>
            <ActionButtons
              client={data}
              index={index}
              { ...actionButtonsProps } />
        } />
    </DataTable>
  );
};

export default ClientsQuery;
