import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { GET_CONTRACTS_ADITIVES, GET_CLIENTS } from "../../queries/accountBalance"

const DELETE_CONTRACT_AB = async (isOriginalContract, id, idContractOriginal, newFinishDate) => {
  if (isOriginalContract) {
    await client.mutate({
      mutation: gql`
        mutation {
          deleteContractAccountBalance(where: { id: "${id}" }) {
            id
          }
        }`,
        refetchQueries: [{ query: GET_CONTRACTS_ADITIVES }, { query: GET_CLIENTS }]
    });
  } else {
    await client.mutate({
      mutation: gql`
        mutation {
          updateContractAccountBalance(
            where: { id: "${idContractOriginal}" }
            data: {
              realFinishDate: "${newFinishDate}"
              aditives: { delete: { id: "${id}" } }
            }
          ) {
            id
          }
        }`,
        refetchQueries: [{ query: GET_CONTRACTS_ADITIVES }, { query: GET_CLIENTS }]
    });
  }
};

export default DELETE_CONTRACT_AB;

