import gql from "graphql-tag";

const updateWarehouse = gql`
    mutation UpdateWarehouse($data: WarehouseUpdateInput!, $where: WarehouseWhereUniqueInput!){
        updateWarehouse(data: $data, where: $where) {
            id
            name
            street
            city
            state
            country
            postalCode
            contactName
            contactEmail
            contactPhone
            status
        }
    }
`

export default updateWarehouse;