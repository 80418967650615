export default {
  revisionIva: [
    {
      field: "vendorId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "folio",
      method: "isEmpty",
      validWhen: false,
      message: "Folio es obligatorio.",
    },
    {
      field: "applicant",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "cli",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "projectId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "resident",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "siteName",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "resident",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "responsible",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "wasUpdatedAt",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "petitioner",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "iva",
      method: "isEmpty",
      options: { min: 0, max: 16 },
      validWhen: false,
      message: "",
    },
  ],
  revisionIvaZero: [
    {
      field: "vendorId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "folio",
      method: "isEmpty",
      validWhen: false,
      message: "Folio es obligatorio.",
    },
    {
      field: "applicant",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "cli",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "projectId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "resident",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "siteName",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "resident",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "responsible",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "wasUpdatedAt",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "petitioner",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    }
  ],
  revisedIva: [
    {
      field: "folio",
      method: "isEmpty",
      validWhen: false,
      message: "Folio es obligatorio.",
    },
    {
      field: "applicant",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "cli",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "projectId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "resident",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "siteName",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "resident",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "responsible",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "wasUpdatedAt",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "petitioner",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "iva",
      method: "isEmpty",
      options: { min: 0, max: 16 },
      validWhen: false,
      message: "",
    },
    {
      field: "revisedBy",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryWarehouse",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "vendorId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  revisedIvaZero: [
    {
      field: "folio",
      method: "isEmpty",
      validWhen: false,
      message: "Folio es obligatorio.",
    },
    {
      field: "applicant",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "cli",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "projectId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "resident",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "siteName",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "resident",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "responsible",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "wasUpdatedAt",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "petitioner",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "revisedBy",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryWarehouse",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "vendorId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
};
