import gql from "graphql-tag";

const updatePurchaseOrder = gql`
  mutation UpdatePurchaseOrder(
    $data: PurchaseOrderUpdateInput!
    $where: PurchaseOrderWhereUniqueInput!
  ) {
    updatePurchaseOrder(data: $data, where: $where) {
      id
      data {
        shipmentDate
        arrivalDate
        trackingNumber
        shippingCompany
        shippingNotes
      }
    }
  }
`;

export default updatePurchaseOrder;
