import React from "react";
import { Query } from "react-apollo";
import {
  Grid,
  Button,
  Checkbox,
  Modal,
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableCell,
  TableRow,
  Icon,
  Loader,
} from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import tableHeaderFields from "./table-header-fields";
import FormHeader from "../../../../Molecules/formHeader";
import DateModal from "../bulkReception";
import getOrderSupplies from "../../../../../graphql/queries/inventories/getOrderSupplies";
import SupplyReceptionModal from "../../../../Organisms/SupplyReceptionModal";
import receiveSupplies from "../../../../../graphql/mutations/inventories/receiveSupplies";
import OnLoading from "../../../../Molecules/OnLoading";

export default class SupplyListReceptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      loaderModal: false,
      imgShown: false,
      imageModal: false,
      selectedItem: null,
      dateModal: false,
      received: [],
      supplies: [],
      suppliesToTrack: [],
      refetch: null,
      supply: {
        key: "",
        description: "",
        purchasePrice: "",
        quantity: "",
        units: "",
        received: "0",
        notes: "",
        warehouse: "",
        receptionDate: "",
        receivedBy: "",
        shippingDate: "",
        deliveryDate: "",
        shippingCompany: "",
        trackingNumber: "",
      },
      user: JSON.parse(window.localStorage.getItem("session"))
        ? JSON.parse(window.localStorage.getItem("session")).user
        : ""
    };
  }

  //
  handleModal = (
    item,
    warehouseName,
    supplies,
    vendorId,
    projectId,
    refetch
  ) => {
    if (item.photoID) {
      this.setState(data => ({
        ...data,
        openModal: !this.state.openModal,
        selectedItem: item,
        supplies:
          this.state.supplies.length === 0 ? supplies : this.state.supplies,
        vendorId,
        projectId,
        supplyId: item.id,
        supply: {
          id: item.id,
          insID: item.insID,
          name: item.name,
          description: item.description,
          purchasePrice: item.purchasePrice,
          quantity: item.quantity,
          units: item.units,
          received: item.received,
          notes: item.notes,
          warehouse: warehouseName,
          receptionDate: item.receptionDate,
          receivedBy: item.receivedBy,
          photoID: item.photoID,
          shippingDate: item.shippingDate,
          deliveryDate: item.deliveryDate,
          shippingCompany: item.shippingCompany,
          trackingNumber: item.trackingNumber,
        },
        img: item.photoID,
        imgShown: true,
        loaderModal: false,
        refetch: refetch,
      }));
    } else {
      this.setState(data => ({
        ...data,
        openModal: !this.state.openModal,
        selectedItem: item,
        supplies:
          this.state.supplies.length === 0 ? supplies : this.state.supplies,
        vendorId,
        projectId,
        supplyId: item.id,
        supply: {
          id: item.id,
          insID: item.insID,
          name: item.name,
          description: item.description,
          purchasePrice: item.purchasePrice,
          quantity: item.quantity,
          units: item.units,
          received: item.received,
          notes: item.notes,
          warehouse: warehouseName,
          receptionDate: item.receptionDate,
          receivedBy: item.receivedBy,
          photoID: item.photoID,
          shippingDate: item.shippingDate,
          deliveryDate: item.deliveryDate,
          shippingCompany: item.shippingCompany,
          trackingNumber: item.trackingNumber,
        },
        img: undefined,
        imgShown: true,
        refetch: refetch,
      }));
    }
  };

  arrayBufferToBase64 = buffer => {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach(b => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
  };

  handleChangeSupplyField = (e, { name, value }) => {
    const supply = this.state.supply;
    supply[name] = value;
    this.setState(data => ({ ...data, supply }));
  };

  receiveSupply = () => {
    let supplies = this.state.received;
    supplies.push(this.state.supply);
    delete supplies[0].id;
    this.setState(data => ({ ...data, openModal: false, received: supplies }));
  };

  saveReception = async () => {
    const { supply } = this.state;
    const { id: supplyId, ...supplyWithoutId } = supply;
    try {
      await receiveSupplies(
        window.location.pathname.split("t/")[1],
        { ...supplyWithoutId, originId: supplyId },
        this.state.supplyId
      );
      this.setState(data => ({ ...data, openModal: false }));
      this.state.refetch();
    } catch (e) {
      throw new Error(e);
    }
  };

  parseSupplies = () => {
    const str = JSON.stringify(this.state.supplies).replace(/"/g, "'");
    return str;
  };

  downloadImage = id => {
    const URL = `${process.env.REACT_APP_DOWNLOAD_ASSETS_URL}${id}`;
    const supply = this.state.supply;
    supply["photoID"] = id;
    this.setState(data => ({
      ...data,
      supply,
      img: id,
      imgShown: true,
    }));
  };

  toggle = (e, { value, item }) => {
    if (value.checked) {
      const suppliesToTrack = [...this.state.suppliesToTrack];
      suppliesToTrack.push({ item });
      this.setState(data => ({ ...data, suppliesToTrack }));
    } else {
      const suppliesToTrack = [...this.state.suppliesToTrack];
      const idx = suppliesToTrack.findIndex(supply => supply.item.id === item.id);
      suppliesToTrack.splice(idx, 1);
      this.setState(data => ({ ...data, suppliesToTrack }));
    }
  };

  render() {
    return (
      <div
        className="Modal__Form-SubModal"
        dimmer="blurring"
        open={this.props.open}
        style={{ minHeight: "350px", padding: "20px", overflowX: "auto" }}
      >
        <Table
          basic="very"
          compact
          textAlign="center"
          sortable
          className="Wrapper__Body-Table"
          style={{ overflowX: "auto", fontSize: "10px" }}
        >
          <TableHeader className="Wrapper__Body-Table-Header">
            <TableRow>
              {tableHeaderFields.map((name, index) => (
                <TableHeaderCell
                  style={{
                    border: "none",
                    borderBottom: "1px solid #dddddd",
                  }}
                  key={index}
                >
                  {name}
                </TableHeaderCell>
              ))}
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            <Query
              query={getOrderSupplies}
              fetchPolicy="network-only"
              notifyOnNetworkStatusChange
              variables={{ id: window.location.pathname.split("t/")[1] }}
            >
              {({ loading, error, data, refetch }) => {

                if (loading || error || !data) {
                  return <OnLoading loading={loading} error={error} />
                }

                let receivedSupplies = data.purchaseOrder.data.receivedSupplies;
                const supplies = data.purchaseOrder.data.supplies;
                supplies.map(item => {
                  const condition = _.some(receivedSupplies, {
                    originId: item.id,
                  });

                  if (
                    !condition
                    && receivedSupplies.findIndex(supply => supply.id === item.id) === -1
                    && receivedSupplies.length !== supplies.length
                  ) {
                    item["type"] = "order";
                    receivedSupplies.push(item);
                  }
                });

                return receivedSupplies.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={this.state.suppliesToTrack.findIndex(supply => supply.item.id === item.id) >= 0}
                          onChange={(e, value) =>
                            this.toggle(e, { value, item })
                          }
                        />
                      </TableCell>
                      <TableCell>{item.insID}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        {unescape(item.description).substring(0, 20)}...
                      </TableCell>
                      <TableCell>{item.units}</TableCell>
                      <TableCell>
                        {item.shippingDate
                          ? moment(item.shippingDate)
                            .format("DD/MM/YYYY")
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {item.deliveryDate
                          ? moment(item.deliveryDate)
                            .format("DD/MM/YYYY")
                          : "-"}
                      </TableCell>
                      <TableCell>{item.purchasePrice}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        {parseFloat(item.quantity * item.purchasePrice)}
                      </TableCell>
                      <TableCell>
                        <p
                          style={{
                            padding: "3px",
                            border: "2px solid #dddddd",
                            borderRadius: "6px",
                          }}
                        >
                          {!item.received ? 0 : item.received}
                        </p>
                      </TableCell>
                      <TableCell>
                        <Button
                          basic
                          icon
                          onClick={() =>
                            this.handleModal(
                              item,
                              data.purchaseOrder.data.warehouse[0].name,
                              data.purchaseOrder.data.supplies,
                              data.purchaseOrder.data.vendor.id,
                              data.purchaseOrder.data.project.id,
                              refetch
                            )
                          }
                        >
                          <Icon name="clipboard list" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                });
              }}
            </Query>
          </TableBody>
        </Table>
        {this.state.openModal ? (
          <SupplyReceptionModal
            open={this.state.openModal}
            onClose={this.handleModal}
            data={this.state.selectedItem}
            handleChange={this.handleChangeSupplyField}
            warehouseName={this.state.supply.warehouse}
            save={this.saveReception}
            uploadFile={this.uploadFile}
            img={this.state.img}
            downloadImage={this.downloadImage}
            imgShown={this.state.imgShown}
            imageModal={this.state.imageModal}
            userRol={this.state.user.userRol}
            openImageModal={() =>
              this.setState({ imageModal: !this.state.imageModal })
            }
          />
        ) : null}
        {this.state.dateModal ? (
          <DateModal
            poId={window.location.pathname.split("/")[3]}
            data={this.state.suppliesToTrack}
            open={this.state.dateModal}
            onClose={() => {
              this.setState(data => ({ ...data, dateModal: false }));
            }}
          />
        ) : null}
        <Grid columns="equal">
          <Grid.Column />
          <Grid.Column textAlign="right">
            <Button
              className="Modal__Form-ButtonAdd"
              onClick={
                this.state.suppliesToTrack.length === 0
                  ? null
                  : () => {
                    this.setState(data => ({ ...data, dateModal: true }));
                  }
              }
              style={{
                textAlign: "left",
                marginTop: "20px",
                opacity: this.state.suppliesToTrack.length === 0 ? 0.7 : null,
              }}
            >
              Modificar fecha
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
