import React from "react";
import { Query } from "react-apollo";
import {
  Grid,
  Button,
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableCell,
  TableRow
} from "semantic-ui-react";
import moment from "moment";
import { IoMdEye } from "react-icons/io";
import tableHeaderFields from "./table-header-fields";
import AddInvoice from "../addInvoice";
import DetailInvoice from "../detailInvoice";
import getReceptionInvoices from "../../../../../graphql/queries/inventories/getReceptionInvoices";
import OnLoading from "../../../../Molecules/OnLoading";

export default class SupplyListReceptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(window.localStorage.getItem("session"))
        ? JSON.parse(window.localStorage.getItem("session")).user
        : "",
      poId: window.location.pathname.split("t/")[1],
      openModalDetail: false,
      dateModal: false,
      invId: ""
    };
  }

  handleOpenDetail = (invoice) => {
    this.setState(prevState => ({
      ...prevState,
      invoice,
      openModalDetail: true
    }))
  }

  handleCloseDetail = () => {
    this.setState(prevState => ({
      ...prevState,
      openModalDetail: false
    }))
  }

  render() {
    const { user: { userRol }} = this.state;

    return (
      <div
        className="Modal__Form-SubModal"
        dimmer="blurring"
        open={this.props.open}
        style={{ minHeight: "350px", padding: "20px", overflowX: "auto" }}
      >
        <Table
          basic="very"
          compact
          textAlign="center"
          sortable
          className="Wrapper__Body-Table"
          style={{ overflowX: "auto", fontSize: "10px" }}
        >
          <TableHeader className="Wrapper__Body-Table-Header">
            <TableRow>
              {tableHeaderFields.map((name, index) => (
                <TableHeaderCell
                  style={{
                    border: "none",
                    borderBottom: "1px solid #dddddd",
                  }}
                  key={index}
                >
                  {name}
                </TableHeaderCell>
              ))}
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            <Query
              query={getReceptionInvoices}
              fetchPolicy="network-only"
              notifyOnNetworkStatusChange
              variables={{ id: this.state.poId }}
            >
              {({ loading, error, data, refetch }) => {

                if (loading || error || !data) {
                  return <OnLoading loading={loading} error={error} />
                }

                const paymentConditionsDict = {
                  CASH: "Contado",
                  CREDIT: "Crédito",
                };

                return (
                  <>
                    {this.state.dateModal ? (
                      <AddInvoice
                        poId={this.state.poId}
                        open={this.state.dateModal}
                        onClose={() => {
                          this.setState(data => ({ ...data, dateModal: false }));
                        }}
                        refetch={refetch}
                      />
                    ) : null}

                    {this.state.openModalDetail ? (
                      <DetailInvoice
                        poId={this.state.poId}
                        invoice={this.state.invoice}
                        open={this.state.openModalDetail}
                        onClose={this.handleCloseDetail}
                        refetch={refetch}
                      />
                    ) : null}

                    {data.purchaseOrder.invoices.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.number}</TableCell>
                        <TableCell>{item.receptionDate
                          ? moment(item.receptionDate)
                            .format("DD/MM/YYYY")
                          : "-"}</TableCell>
                        <TableCell>{paymentConditionsDict[item.paymentConditions]}</TableCell>
                        <TableCell>
                          {item.paymentDate
                            ? moment(item.paymentDate)
                              .format("DD/MM/YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell>{item.notes}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => this.handleOpenDetail(item)}
                            icon
                          >
                            <IoMdEye />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                );
              }}
            </Query>
          </TableBody>
        </Table>
        <Grid columns="equal">
          <Grid.Column textAlign="center">
            {userRol !== "TECHNICIAN" && (
              <Button
                className="Modal__Form-ButtonAdd"
                onClick={() => this.setState(data => ({ ...data, dateModal: true }))}
                style={{ marginTop: "20px" }}
              >
                Añadir factura
              </Button>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
