import gql from "graphql-tag";
import { client } from "../../apollo-config";

const DELETE_DOCUMENT_CONTRACT = async ({
  idDocumentContract,
  idContract,
  idRef,
  invoiced,
  paided
}) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContract(
          data: {
            invoiced: ${invoiced},
            paided: ${paided},
            documentsContract: {
              update: {
                where: { id: "${idDocumentContract}" }
                data: { refUpload: { delete: { id: "${idRef}" } } }
              }
            }
          }
          where: { id: "${idContract}" }
        ) {
          id
        }
      }
    `
  });
  return mutation;
};

export default DELETE_DOCUMENT_CONTRACT;
