import gql from "graphql-tag";

const GET_ORIGINAL_CONTRACT = gql`
  query GetOriginalContract($id: ID!) {
    contract(where: {id: $id}){
      invoiced
      project{
        cli
      }
      documentsContract{
        id
        type
        concept
        elaborated
        dateElaborated
        authorizer
        amount
        iva
        amountIva
        total
        status
        refUpload{
          id
          type
          reference
          extension
          wildcard
        }
      }
    }
  }
`

export default GET_ORIGINAL_CONTRACT
