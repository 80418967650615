import React from "react";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { TabPanel } from "../../Atoms";
import { TabModal, ContractFormAccountBalanceEdit, AccountBalanceCurrencyForm, TimeLineAccountBalance } from "../../Organisms";
import { GET_CONTRACT_ACCOUNT_BALANCE_BY_ID, GET_CONTRACT_ADITIVE_ACCOUNT_BALANCE_BY_ID } from "../../../graphql/queries/accountBalance";
import { UPDATE_CONTRACT_AB, UPDATE_ADITIVE_AB } from "../../../graphql/mutations/accountBalance";
import { client as apollo } from "../../../graphql/apollo-config";
import { helper } from "../../../helpers";
import { bugsnagClient } from "../../../bugsnag";

class EditContractAccountBalance extends React.Component {
  state = {
    readOnly: this.props.location.pathname.endsWith('detail') ? true : false,
    readOnlyByDocuments: false,
    idContract: this.props.match.params.id,
    isOriginalContract: this.props.location.state.originalContract,
    contract: {
      id: "",
      IDFolio: "",
      client: {
        id: ""
      },
      project: {
        id: "",
        cli: "",
        key: ""
      },
      currency: "",
      amountContract: "",
      percentAmortization: "",
      percentGuaranteeFund: "",
      startDate: "",
      finishDate: "",
      status: "",
      description: ""
    },
    errors : {},
    submitting: false,
    currentTab: 1
  }

  componentDidMount() {
    this.getContract();
  }

  updateReadOnlyByDocuments = () => {
    this.getContract();
  }

  getContract = async () => {
    const { isOriginalContract, idContract } = this.state;

    if(isOriginalContract) {
      const { data: { contractAccountBalance } } = await apollo.query({ query: GET_CONTRACT_ACCOUNT_BALANCE_BY_ID, variables: { id: idContract }, fetchPolicy: "network-only" });
      const { documentsContractAccountBalance } =  contractAccountBalance;

      if (documentsContractAccountBalance.length > 0) {
        this.setState(data => ({ contract: {...data.contract, ...contractAccountBalance}, readOnlyByDocuments: true }));
      } else {
        this.setState(data => ({ contract: {...data.contract, ...contractAccountBalance}, readOnlyByDocuments: false }));
      }

    } else {
      let { data : { aditiveAccountBalance } } = await apollo.query({ query: GET_CONTRACT_ADITIVE_ACCOUNT_BALANCE_BY_ID, variables: { id: idContract }, fetchPolicy: "network-only" })
      const { IDFolio, client, project, realFinishDate } = aditiveAccountBalance.originalContract;
      aditiveAccountBalance = {...aditiveAccountBalance, IDFolio, client, project, realFinishDate };
      const { documentsContractAccountBalance } =  aditiveAccountBalance;

      if (documentsContractAccountBalance.length > 0) {
        this.setState(data => ({ contract: {...data.contract, ...aditiveAccountBalance}, readOnlyByDocuments: true }));
      } else {
        this.setState(data => ({ contract: {...data.contract, ...aditiveAccountBalance}, readOnlyByDocuments: true }));
      }
    }
  }

  onCancel = () => {
    this.props.history.push({
      pathname: '/estado-de-cuenta',
      state: { myRoute: "contract" }
    })
  }

  handleOnChange = (e, data) => {
    let { name, value, options } = data;

    if (name === "project") {
      value = options.length !== 0 ? options.find(option => option.id === value) : value
    }

    this.setState(data => ({ contract: {...data.contract, [name]: value }}));
  }

  onSave = async () => {
    const { contract, isOriginalContract, currentTab } = this.state;

    if (this.handleValidator())
      return false;

    this.setState({ submitting: true });

    try {
      if (currentTab === 1) {
        if (isOriginalContract) {
          await UPDATE_CONTRACT_AB(contract);
        } else {
          await UPDATE_ADITIVE_AB(contract);
        }
      }
      this.props.history.push("/estado-de-cuenta");
    } catch (error) {
      bugsnagClient.notify(error);
    }
  }

  handleValidator = () => {
    const {errors, validator } = helper.validator(this.getInputs());
    this.setState({ errors });

    return validator;
  }

  getInputs = () => {
    const { contract } = this.state;

    return {
      id: contract.id,
      IDFolio: contract,
      client: contract.client,
      project: contract.project,
      currency: contract.currency,
      amountContract: contract.amountContract,
      percentAmortization: contract.percentAmortization,
      percentGuaranteeFund: contract.percentGuaranteeFund,
      startDate: contract.startDate,
      finishDate: contract.finishDate,
      status: contract.status,
      description: contract.description
    }
  }

  currentTab = (currentTab) => {
    this.setState({ currentTab });
  }

  render() {
    const { submitting, readOnly, readOnlyByDocuments, idContract, isOriginalContract, contract, errors } = this.state

    if (contract.id === '') {
      return (
        <Container fluid className="Wrapper__Body-Loader">
          <Dimmer active inverted >
            <Loader inverted content='Cargando...' />
          </Dimmer>
        </Container>
      )
    }
    
    return (
      <Container fluid className="Wrapper__Modal">
        <TabModal active="datos" save={this.onSave} close={this.onCancel} disabled={submitting} readOnly={readOnly}>
          <TabPanel name="datos" text="Información de contrato">
            <ContractFormAccountBalanceEdit
              idContract={idContract}
              isOriginalContract={isOriginalContract}
              readOnly={readOnly}
              readOnlyByDocuments={readOnlyByDocuments}
              data={contract}
              handleOnChange={this.handleOnChange}
              errors={errors}
              currentTab={this.currentTab}
            />
          </TabPanel>

          <TabPanel name="balance" text={`Estado de cuenta ${contract.currency}`}>
            <AccountBalanceCurrencyForm
              idContract={idContract}
              isOriginalContract={isOriginalContract}
              readOnly={readOnly}
              counterDocument={contract.documentsContractAccountBalance.length}
              updateReadOnlyByDocuments={this.updateReadOnlyByDocuments}
              currentTab={this.currentTab}
              />
          </TabPanel>
          <TabPanel name="timeline" text="Historial del estado de cuenta">
            <TimeLineAccountBalance
              idContract={idContract}
            />
          </TabPanel>
        </TabModal>
      </Container>
    )
  }
}

export default EditContractAccountBalance;
