import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  orderDataContainerLeft: {
    marginBottom: 10,
    left: 10,
  },
  orderDataContainerRight: {
    top: -90,
    marginBottom: 45,
    left: 250,
    maxWidth: "300px",
  },
  dataSectionText: {
    fontSize: 7,
    color: "#000000",
  },
});

export default props => (
  <View>
    <View style={styles.orderDataContainerLeft}>
      <View>
        <Text style={styles.dataSectionText}>Orden / PO: {props.order}</Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 10 }}>
          Proveedor / Vendor: {props.provider}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 20 }}>
          Para / To: {props.to}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 30 }}>
          De / from: {props.petitioner}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 40 }}>
          Moneda / Currency: {props.currency}
        </Text>
      </View>
    </View>
    {/* Right */}
    <View style={styles.orderDataContainerRight}>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 40 }}>
          Almacén / Warehouse: {props.warehouse}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 50 }}>
          Proyecto / Project: {props.project}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 60 }}>
          Presupuesto CLI / CLI: {props.cli}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 70, lineHeight: 2 }}>
          Dirección de proyecto / Project address: {props.address}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 80 }}>
          Condiciones de pago / Payment conditions:{" "}
          {props.paymentConditions === "CASH" ? "Contado" : "Crédito"}
        </Text>
      </View>
      <View>
        <Text style={{ ...styles.dataSectionText, top: 90 }}>
          Tiempo de entrega / Delivery time:{" "}
          {props.deliveryTime}
        </Text>
      </View>
    </View>
    <View>
      <Text style={{ fontSize: 9, top: -25, left: 10, color: "#000000" }}>
        Notas / Notes:
      </Text>
      <Text
        style={{ ...styles.dataSectionText, top: -20, left: 10, width: "90%" }}
      >
        {unescape(unescape(props.notes))}
      </Text>
    </View>
  </View>
);
