import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { Container, Feed, Icon } from "semantic-ui-react";
import "./style.css";
import { timelineDateFormat } from "../../../helpers";
import OnLoading from "../../Molecules/OnLoading";

const Message = ({ action, name }) => (
  <span>
    {name}&nbsp;{action}
  </span>
);

export default class Timeline extends React.Component {
  query = gql`{
    requisition(where:{id:"${this.props.reqId}"}){
      elaboratedEvent(where: { type_not: null }){
        name
        type
        date
        createdAt
      }
    }
  }`;

  mapEventType = data => {
    const type = {
      REQUISITION_CREATED: "creó la requisición",
      REQUISITION_REVISED: "revisó la requisición",
      REQUISITION_APPROVED: "aprobó la requisición",
      REQUISITION_CANCELED: "canceló la requisición",
      PURCHASE_ORDER_CREATED: "creó la orden de compra",
      PURCHASE_ORDER_REVISED: "revisó la orden de compra",
      PURCHASE_ORDER_APPROVED: "aprobó la orden de compra",
      PURCHASE_ORDER_CANCELED: "canceló la orden de compra",
    };

    return type[data];
  };

  render() {
    return (
      <Container>
        <Feed style={{ margin: "30px", borderLeft: "2px solid #1890FF" }}>
          <Query query={this.query} fetchPolicy={"network-only"}>
            {({ loading, error, data }) => {

              if (loading) return <OnLoading loading={loading} />

              if (data.requisition) {
                return data.requisition.elaboratedEvent.map(
                  ({ name, type, createdAt, date }, index) => {
                    if(!name)
                      return null;

                    return (
                      <Feed.Event key={index}>
                        <Feed.Label style={{ marginLeft: "-18px" }}>
                          <Icon
                            name="circle"
                            color="blue"
                            style={{ color: "#1890ff" }}
                          />
                        </Feed.Label>
                        <Feed.Content>
                          <Feed.Summary>
                            <Message
                              name={name}
                              action={this.mapEventType(type)}
                            />
                          </Feed.Summary>
                          <Feed.Date style={{ padding: "8px" }}>
                            <p>{timelineDateFormat(createdAt || date)}</p>
                          </Feed.Date>
                        </Feed.Content>
                      </Feed.Event>
                    );
                  }
                );
              } else {
                return null;
              }
            }}
          </Query>
        </Feed>
      </Container>
    );
  }
}
