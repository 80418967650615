import gql from "graphql-tag";

const GetProjectByUser = gql`
  query getProjectByUser($idUser: ID!) {
    projects(
      orderBy : name_ASC
      where: { OR: [
        { users_some: { id: $idUser } }
      ]}
    ) {
      key: id
      text: name,
      value: id,
      id,
      cli,
      address,
      name,
      cli,
      address,
      status,  
      responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
        id,
        name
      }
      residents: users(where: { userRol_in: [TECHNICIAN] }){
        id,
        name
      }
    }
  }
`;

export default GetProjectByUser;
