import gql from "graphql-tag";
import { client } from "../../apollo-config";

const updateRequisitionSupply = async ({ id, supply = [], suppliesToDelete = [], amount, subtotal, total }) => {
  return await client.mutate({
    variables: {
      id,
      supply: supply,
      suppliesToDelete: suppliesToDelete,
      amount,
      subtotal,
      total
    },
    mutation: gql`
      mutation(
          $id: ID!
          $supply: [OrderSupplyCreateInput!]
          $suppliesToDelete:[OrderSupplyWhereUniqueInput!]
          $amount: Float!
          $subtotal: Float!
          $total: Float!
      ) {
        updateRequisition(
          data: {
            amount: $amount
            subtotal: $subtotal
            total: $total
            supplies: {
              create: $supply
              delete: $suppliesToDelete
            }
          }
          where: { id: $id }
        ) {
          id
          supplies {
            id
            name
            insID
            description
            units
            quantity
            purchasePrice
          }
        }
      }
    `,
  });
};

export default updateRequisitionSupply;
