import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { styles } from "../../theme";
import validate from "../../../helpers/validator";

class AddContactModal extends React.Component {
  state = {
    modalOpen: false,
    NameError: false,
    EmailError: false,
    PhoneError: false,
    Phone_OfficeError: false,
    formIsValid: false
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  save = () => {
    this.validate();
    if (this.state.formIsValid) {
      this.props.onSave();
      this.setState({ modalOpen: false });
    } else {
      alert("Por favor rellena los campos faltantes");
    }
  };

  validate = () => {
    const { Name, Email, Phone, Phone_Office } = this.props.data;
    const validation = validate({
      Name,
      Email,
      Phone,
      Phone_Office
    });

    // fields are valid
    validation.map((field, index) => {
      const key = field.key;
      const errorKey = `${key}Error`;
      const _formIsValid = Object.keys(field).every(function(key) {
        return field["isValid"];
      });
      this.setState(data => ({
        ...data,
        formIsValid: _formIsValid,
        [errorKey]: !field.isValid
      }));
    });
  };

  render() {
    return (
      <Modal
        dimmer="blurring"
        style={styles.modal_small}
        trigger={
          <Button
            data={this.props.data}
            onClick={this.handleOpen}
            style={styles.button_action}
          >
            + Añadir Contacto
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="tiny"
      >
        <Modal.Header
          style={{
            height: "60px",
            width: "100%",
            marginBottom: "20px",
            textAlign: "left"
          }}
        >
          Contacto de proveedor
        </Modal.Header>

        <Modal.Content style={styles.modal_content}>
          <Modal.Description>
            <Form style={styles.form_group}>
              <Form.Field
                control={Input}
                label="Nombre y Apellido"
                name="Name"
                onChange={this.props.handleChange}
                size="mini"
                error={this.state.NameError}
              />
              <Form.Field
                control={Input}
                label="Correo electrónico"
                name="Email"
                onChange={this.props.handleChange}
                size="mini"
                error={this.state.EmailError}
              />
              <Form.Field
                control={Input}
                label="Telefono celular"
                name="Phone"
                onChange={this.props.handleChange}
                size="mini"
                error={this.state.PhoneError}
              />
              <Form.Field
                control={Input}
                label="Telefono de oficina"
                name="Phone_Office"
                onChange={this.props.handleChange}
                size="mini"
                error={this.state.Phone_OfficeError}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div style={styles.modal_button_holder}>
            <div>
              <Button style={styles.button_save} onClick={this.save}>
                Guardar
              </Button>
              <Button onClick={this.handleClose} style={styles.button_cancel}>
                Cancelar
              </Button>
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

AddContactModal.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func,
  handleChange: PropTypes.func
};

export default AddContactModal;
