import gql from "graphql-tag";

const getReceptionItemsByTechnician = gql`
  query receptionItems($userId: ID!, $first: Int, $skip: Int, $search: String, $floatSearch: Float) {
    purchaseOrders(
      first: $first,
      skip: $skip,
      where: {
        data: {
          status: IS_PO_APPROVED
          project: { users_some: { id: $userId } }
        }
        OR: [
          {
            folio_contains: $search
          },
          {
            data: {
              OR: [
                {
                  total: $floatSearch
                }
                {
                  project: {
                    OR: [
                      { name_contains: $search },
                      { cli_contains: $search }
                    ]
                  }
                }
                { vendor: { commercialName_contains: $search } }
                { deliveryDate_contains: $search }
                { deliveryWarehouse_contains: $search }
              ]
            }
          }
        ]
      }
    ) {
      id
      folio
      data {
        project {
          name
          cli
        }
        vendor {
          commercialName
        }
        deliveryWarehouse
        deliveryDate
        total
        status
        arrivalDate
        trackingNumber
        shipmentDate
        shippingCompany
        shippingCost
        shippingNotes
        followUpNumber
        deliveryStatus
        paymentStatus
        wasUpdatedAt
        supplies {
          id
        }
        receivedSupplies {
          id
          deliveryDate
        }
      }
    }
    ct: purchaseOrders(
      where: {
        data: {
          status: IS_PO_APPROVED
        }
        OR: [
          {
            folio_contains: $search
          },
          {
            data: {
              OR: [
                {
                  total: $floatSearch
                }
                {
                  project: {
                    OR: [
                      { cli_contains: $search },
                      { users_some: { id: $userId }}
                    ]
                  }
                }
                { vendor: { commercialName_contains: $search } }
                { deliveryDate_contains: $search }
                { deliveryWarehouse_contains: $search }
              ]
            }
          }
        ]
      }
    ) {
      id
    }
  }
`;
export default getReceptionItemsByTechnician;
