import React from "react";
import gql from "graphql-tag";
import "semantic-ui-css/semantic.min.css";
import { gantt } from "dhtmlx-gantt";
import { client } from "../../../graphql/apollo-config";
import { IMPORT_MS } from "../../../graphql/mutations/projectProgress";
import importMsfile from "./ganttapi.js";

class ImportMS extends React.Component {
  state = { selectedFile: null, loaded: 0, id: "" };
  styles = {
    button: {
      backgroundColor: "rgba(0,0,0,0)",
      border: "1px solid #295896",
      color: "#295896",
    },
  };
  fileInput = null;
  // If no id was specified, generate a random one
  uid =
    this.state.id ||
    Math.random()
      .toString(36)
      .substring(7);

  onUpload = () => {
    const file = this.fileInput.files[0];
    // I declare here because I cannot access to this context from inside the gantt method
    const mapProject = this.mapProject;
    const mapLinks = this.mapLinks;
    const removeManuallyCreatedTasks = this.removeManuallyCreatedTasks;
    const config = {
      data: file,
      taskProperties: ["Notes", "Name"],
      callback: async function(project) {
        if (project) {
          const { data, links } = project.data;
          const newTasks = mapProject(data);
          const newLinks = mapLinks(links);
          await removeManuallyCreatedTasks(); // remove old tasks
          const importedTasks = await IMPORT_MS(
            window.location.pathname.split("e/")[1],
            newTasks,
            newLinks
          );
          if (importedTasks) {
            gantt.clearAll();
            if (project.config.duration_unit) {
              gantt.config.duration_unit = project.config.duration_unit;
            }
            gantt.parse(project.data);
          }
        }
      },
    };

    importMsfile(config);
  };

  mapProject = data => {
    const tasks = [];

    data.map((task, index) => {
      const parsedDate = task["start_date"]
        ? task["start_date"].split(" ")[0]
        : null;
      return tasks.push({
        text: task.text,
        start_date: new Date(parsedDate),
        duration: parseInt(task.duration),
        index: parseInt(task.id),
        parent: parseInt(task.parent),
        manuallyCreated: true,
        progress: 0,
      });
    });
    return tasks;
  };

  mapLinks = data => {
    const links = [];

    data.map((link, index) => {
      return links.push({
        index: link.id.toString(),
        source: parseInt(link.source),
        target: parseInt(link.target),
        type: link.type,
      });
    });
    return links;
  };

  removeManuallyCreatedTasks = async () => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation {
          updateProject(
            where: { id: "${window.location.pathname.split("e/")[1]}" }
            data: { tasks: { deleteMany: { manuallyCreated: true } } }
          ) {
            id
          }
        }
      `,
    });
    return data;
  };

  render() {
    return (
      <span>
        <label
          htmlFor={this.uid}
          className="ui icon button"
          style={this.styles.button}
        >
          <i className="add icon" />
          Importar MS Project
        </label>
        <input
          type="file"
          id={this.uid}
          style={{ display: "none" }}
          onChange={this.onUpload}
          ref={input => {
            this.fileInput = input;
          }}
          accept=".mpp"
        />
      </span>
    );
  }
}
export default ImportMS;
