import React from "react";
import { Icon, Grid, Segment, Header, Container, Dimmer, Loader } from "semantic-ui-react";
import { format } from "../../../helpers";
import { QUERY_CONTRACTS, GET_CONTRACT_BY_CONTRACTOR } from "../../../graphql/queries/contractors";
import { client } from "../../../graphql/apollo-config";

class ContractSummary extends React.Component {
  state = {
    user: JSON.parse(window.localStorage.getItem("session"))
      ? JSON.parse(window.localStorage.getItem("session")).user
      : "",
    isLoading: true,
    contractOriginal: null
  }

  async componentDidMount () {
    const { id } = this.props;
    const { user: { id: idUser, userRol } } = this.state;
    const QUERY = userRol === "CONTRACTOR" ? GET_CONTRACT_BY_CONTRACTOR : QUERY_CONTRACTS;
    const VARIABLES = userRol === "CONTRACTOR" ? idUser : null;
    const { data: { contracts } } = await client.query({ query: QUERY, variables: { id: VARIABLES} });
    const contractOriginal = contracts.find(contract => contract.id === id);
    this.setState(prevState => ({
      ...prevState,
      isLoading: false,
      contractOriginal
    }))
  }

  render() {
    const { isLoading, contractOriginal } = this.state;

    if (isLoading) {
      return (
        <Container fluid className="Wrapper__Body-Loader">
          <Dimmer active inverted >
            <Loader inverted content='Cargando...' />
          </Dimmer>
        </Container>
      )
    }

    const { total, amountAditives, amountDeductives, currency } = contractOriginal
    const amountBalance = (total + amountAditives) - amountDeductives;

    return (
      <Grid.Row textAlign="center">
        <Grid.Column width="4">
          <Segment padded className="Modal__SubTabNav-Sumary">
            <Header as="h3" className="Modal__SubTabNav-Summary-Title">
              <Icon name="file alternate"/>
              Contrato original
            </Header>
            <Header as="h2" className="Modal__SubTabNav-Summary-Subtitle Blue">
              {format.currency(total, currency)} {currency}
            </Header>
          </Segment>
        </Grid.Column>
        <Grid.Column width="4">
          <Segment padded className="Modal__SubTabNav-Sumary">
            <Header as="h3" className="Modal__SubTabNav-Summary-Title">
              <Icon name="line graph"/>
              Aditivas
            </Header>
            <Header as="h2" className="Modal__SubTabNav-Summary-Subtitle Green">
              {format.currency(amountAditives, currency)} {currency}
            </Header>
          </Segment>
        </Grid.Column>
        <Grid.Column width="4">
          <Segment padded className="Modal__SubTabNav-Sumary">
            <Header as="h3" className="Modal__SubTabNav-Summary-Title">
              <Icon name="area graph"/>
              Deductivas
            </Header>
            <Header as="h2" className="Modal__SubTabNav-Summary-Subtitle Red">
              -{format.currency(amountDeductives, currency)} {currency}
            </Header>
          </Segment>
        </Grid.Column>
        <Grid.Column width="4">
          <Segment padded className="Modal__SubTabNav-Sumary">
            <Header as="h3" className="Modal__SubTabNav-Summary-Title">
              <Icon name="balance scale"/>
              Balance total
            </Header>
            <Header as="h2" className="Modal__SubTabNav-Summary-Subtitle Green">
              {format.currency(amountBalance, currency)} {currency}
            </Header>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default ContractSummary;
