import React from "react";
import DataTable from "../../Organisms/DataTable";
import { climasTemplate } from "../../Organisms/DataTable/climasTemplate";
import { client, MUTATIONS } from "../../../graphql/apollo-config";
import { ActionButtons } from "./actionButtons";
import { bugsnagClient } from "../../../bugsnag";

const deleteVendor = MUTATIONS.deleteVendor;

export default ({
  onUpdate,
  openDetails,
  action,
  filter,
  filterVariables,
  onOrderChange,
  onReload
}) => {
  const _deleteVendor = async id => {
    let prompt = window.confirm("Eliminar Proveedor");
    if (!prompt) return;

    try {
      await deleteVendor(id, client);
      onReload();
    } catch (err) {
      bugsnagClient.notify(err);
    }
  };

  const actionButtonsProps = {
    onUpdate,
    _deleteVendor,
    openDetails
  }

  return (
    <DataTable
      onOrderChange={onOrderChange}
      onCompleted={action}
      template={climasTemplate}
      query={filter}
      variables={filterVariables}
      itemsKey="vendors">
      <DataTable.Column
        name="commercialName"
        label="Nombre comercial"
        sortable />
       <DataTable.Column
        name="contacts[0][name]"
        label="Contacto"/>
      <DataTable.Column
        name="contacts[0][email]"
        label="Email"/>
      <DataTable.Column
        name="contacts[0][phone]"
        label="Teléfono"/>
      <DataTable.Column
        name="state"
        label="Estado"
        sortable />
      <DataTable.Column
        name="personType"
        label="Tipo de persona"
        sortable />
      <DataTable.Column
        name="actions"
        label=" "
        content={
          (row, column, index) =>
            <ActionButtons
              index={index}
              vendor={row}
              { ...actionButtonsProps } />
        } />
    </DataTable>
  );
};