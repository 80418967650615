import gql from "graphql-tag";

const GET_DOCUMENTS_CONTRACT_BY_CONTRACTOR = gql`
  query Contracts($id: ID!) {
    contracts(where: { contractor: { user: { id: $id } }, status_in: ACTIVE }) {
      id
      currency
      total
      amountDeductives
      invoiced
      paided
      project {
        name
        cli
      }
      documentsContract(
        where: {
          OR: [
            { status: BY_BILL },
            { status: INVOICED },
            { status: PAID_PARTIALLY },
            { status: PAID_OUT },
            { status: CANCELLED }
          ]
        }
      ) {
        id
        type
        status
        concept
        dateElaborated
        iva
        amountIva
        total
        amount
        elaborated
        authorizer
        status
        refUpload {
          id
          type
          reference
          extension
          wildcard
        }
      }
      aditives {
        documentsAditivas(
          where: {
            OR: [
              { status: BY_BILL },
              { status: INVOICED },
              { status: PAID_PARTIALLY },
              { status: PAID_OUT },
              { status: CANCELLED }
            ]
          }
        ) {
          id
          type
          status
          concept
          dateElaborated
          iva
          amountIva
          total
          amount
          elaborated
          authorizer
          status
          refUpload {
            id
            type
            reference
            extension
            wildcard
          }
        }
      }
    }
  }
`;

export default GET_DOCUMENTS_CONTRACT_BY_CONTRACTOR;
