import React from "react";
import { Card, Icon, Image, Loader, Modal } from "semantic-ui-react";

export default props => {
  const { uploadFile, image, imgShown, open } = props;
  let condition = imgShown && image; //&& imageID && format;

  return (
    <Card
      centered
      style={{
        background: "#dddddd",
        width: "150px",
        height: "150px",
        textAlign: "center",
        padding: "10px",
        overflow: "auto",
      }}
    >
      {condition ? (
        <Image
          src={image}
          width={150}
          alt=""
          onClick={open}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Card.Content>
          <br />
          <Icon size="huge" name="camera" color="#999999" />
          <div>
            <label htmlFor="file" class="ui icon button">
              Agregar foto
            </label>
            <input
              onChange={uploadFile}
              type="file"
              id="file"
              accept="image/png, image/jpeg, image/jpg"
              style={{ display: "none" }}
            />
          </div>
        </Card.Content>
      )}
    </Card>
  );
};
