import gql from "graphql-tag";
import { client } from "../apollo-config";

const updateVendor = async ({
  id,
  commercialName,
  street,
  number,
  colony,
  postalCode,
  city,
  state,
  country,
  personType,
  rfc,
  iva,
  razonSocial,
  addedContacts,
  updatedContacts,
  deletedContacts
}) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateVendor(
          where: { id: "${id}" },
          data:{
            commercialName: "${commercialName}"
            street: "${street}"
            number: "${number}"
            colony: "${colony}"
            postalCode: ${parseInt(postalCode)}
            city: "${city}"
            state: "${state}"
            country: "${country}"
            personType: ${personType}
            rfc: "${rfc}"
            iva: ${parseFloat(iva)}
            razonSocial: "${razonSocial}"
            contacts: {
                create: ${JSON.stringify(addedContacts).replace(/"([^(")"]+)":/g, "$1:")}
                updateMany: ${JSON.stringify(updatedContacts).replace(/"([^(")"]+)":/g, "$1:")}
                deleteMany: ${JSON.stringify(deletedContacts).replace(/"([^(")"]+)":/g, "$1:")}
              }
          }
        ) {
          id
        }
      }
    `
  });
  return mutation;
};

export default updateVendor;