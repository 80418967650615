import React from "react";
import { Form, Header, Grid } from "semantic-ui-react";
import { ViewDocumentLinkAccountBalance } from "../../Molecules";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import moment from "moment";
import CurrencyInput from 'react-currency-input'
import { helper } from "../../../helpers";

class EstimateFormAccountBalance extends React.Component {
  state = {
    statusOptions: [],
    acceptedFiles: ["application/pdf", "text/xml"]
  }

  componentDidMount() {
    const { status } = this.props.data;
    this.setState({ statusOptions: helper.addStatus(status) });
  }

  render () {
    const { statusOptions } = this.state;
    const { isOriginalContract, handleChange, currency, errors, readOnly, disabledStatus, findDocument, refUpload } = this.props;

    if (isOriginalContract) {
      var { data, client: { commercialName }, project: { name, cli } } = this.props;
    } else {
      var { data, originalContract: { client: { commercialName }, project: { name, cli }} } = this.props;
    }

    const readInput = data.status ? (data.status !== "CREATED" ? true : false) : false;
    const workReport = helper.findObject(refUpload, "WORK_REPORT");
    const signedReport = helper.findObject(refUpload, "SIGNED_REPORT");

    return (
      <Form className="Modal__Form-SubModal-Form" autoComplete="off">
        <Form.Group widths={16}>
          <Form.Input
            readOnly
            width="4"
            label="CLIENTE"
            value={commercialName}/>
          <Form.Input
            readOnly
            width="4"
            label="NOMBRE DEL PROYECTO"
            value={name}/>
          <Form.Input
            readOnly
            width="4"
            label="PRESUPUESTO CLI"
            value={cli}/>
        </Form.Group>
        <Header as="h3" className="Modal__Form-SubModal-Form-Header">
          Datos adicionales
        </Header>
        <Form.Group widths={16}>
          <Form.Input
            readOnly
            width="4"
            label="MONEDA"
            name="currency"
            value={currency}/>
          <Form.Field className='Util-DatePicker' width="4">
            <SemanticDatepicker
              required
              name="dateElaborated"
              label="FECHA"
              placeholder={!data.dateElaborated ? "dd/mm/aaaa" : moment(data.dateElaborated).add(1, 'day').format("DD/MM/YYYY")}
              type="basic"
              format="DD/MM/YYYY"
              onDateChange={
                (date) => handleChange(null, { name: 'dateElaborated', value: (date !== null && moment(date).format('YYYY-MM-DD')) })
              }
              error={errors.dateElaborated}
              readOnly={true}
              disabled={readOnly || readInput}
            />
          </Form.Field>
          <Form.Select
            required
            disabled={readOnly || disabledStatus}
            width="4"
            label="ESTATUS"
            placeholder="Seleccionar"
            name="status"
            value={data.status}
            options={statusOptions}
            onChange={handleChange}
            error={errors.status}/>
        </Form.Group>
        <Form.Group widths={16}>
          <Form.Input
            readOnly={readOnly || readInput}
            width="4"
            label="NÚMERO DE FACTURA"
            placeholder="8979"
            name="invoiceNumber"
            value={data.invoiceNumber}
            onChange={handleChange}
            error={errors.invoiceNumber}/>
          <Form.Field required error={errors.amount} disabled={readOnly || readInput} width="4">
            <label>IMPORTE</label>
            <CurrencyInput
              value={data.amount}
              onChangeEvent={(event, maskedvalue, floatvalue) => handleChange(null, { name: "amount", value: floatvalue })}/>
          </Form.Field>
          <Form.Field required error={errors.amountAmortization} disabled={!findDocument("DEDUCTIVE")} width="4">
            <label>AMORTIZACIÓN</label>
            <CurrencyInput
              value={data.amountAmortization}
              onChangeEvent={(event, maskedvalue, floatvalue) => handleChange(null, { name: "amountAmortization", value: floatvalue })}/>
          </Form.Field>
          <Form.Field required error={errors.amountGuaranteeFund} disabled={!findDocument("DEDUCTIVE")} width="4">
            <label>FONDO DE GARANTÍA</label>
            <CurrencyInput
              value={data.amountGuaranteeFund}
              onChangeEvent={(event, maskedvalue, floatvalue) => handleChange(null, { name: "amountGuaranteeFund", value: floatvalue })}/>
          </Form.Field>
        </Form.Group>
        <Form.Group widths={16}>
          <Form.TextArea
            required
            readOnly={readOnly || readInput}
            width="16"
            label="NOTAS"
            rows="5"
            name="concept"
            value={data.concept}
            onChange={handleChange}
            error={errors.concept}/>
        </Form.Group>
        <Grid>
          <Grid.Row>
            <Grid.Column floated="right" width="4">
              <Grid>
                <Grid.Row>
                  <Grid.Column width="6" />
                  <Grid.Column width="10">
                    <Form.Field required error={errors.total} disabled={!findDocument("DEDUCTIVE")}>
                      <label>TOTAL</label>
                      <CurrencyInput
                        value={data.total}
                        onChangeEvent={(event, maskedvalue, floatvalue) => handleChange(null, { name: "total", value: floatvalue })}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Column width="6" />
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header as="h5">REPORTES</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width="8">
              <ViewDocumentLinkAccountBalance
                title="Reporte de trabajo"
                onClickDownload={this.props.onClickDownload}
                onClickDelete={this.props.deleteDocument}
                onSelectFile={this.props.onSelectFile}
                refDownload={workReport}
                readOnly={readOnly}
                documentType="WORK_REPORT"
                acceptedFiles={this.state.acceptedFiles}
                icon
              />
            </Grid.Column>
            <Grid.Column width="8">
              <ViewDocumentLinkAccountBalance
                title="Reporte firmado"
                onClickDownload={this.props.onClickDownload}
                onClickDelete={this.props.deleteDocument}
                onSelectFile={this.props.onSelectFile}
                refDownload={signedReport}
                readOnly={readOnly}
                documentType="SIGNED_REPORT"
                acceptedFiles={this.state.acceptedFiles}
                icon
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    )
  }
}

export default EstimateFormAccountBalance;
