import { client } from "../graphql/apollo-config";
import { GET_ORIGINAL_CONTRACT, GET_COMPLETE_DOCUMENT_CONTRACTOR } from "../graphql/queries/contractors";
import moment from "moment";

const currency = (amount, currency) => {
  const currencies = {
    USD: { local: "en-US" },
    MXN: { local: "es-MX" }
  };

  const options = {
    style: "currency",
    currency,
    minimumFractionDigits: 2
  };

  const formatter = new Intl.NumberFormat(currencies[currency].local, options);
  return formatter.format(amount);
}

const date = (value, option = 1) => {
  if (!value) return "";
  const date = moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const format = { 1: "DD/MM/YYYY", 2: "YYYY-MM-DD", 3: "YYYY-MM-DDTHH:mm:ss.SSS[Z]", 4: "YYYY-MM-DD hh:mm:ss a" };
  return date.format(format[option]);
}

const status = (status) => {
  const contract = {
    ACTIVE: "ACTIVO",
    INACTIVE: "INACTIVO",
    FINISHED: "FINALIZADO",
  };
  return contract[status];
}

const typeDocumentsContract = (type) => {
  const typeDocs = {
    ESTIMATION: "Estimación",
    DEDUCTIVE: "Deductiva",
    ADVANCED_PAYMENT: "Anticipo",
    ADITIVE: "Aditiva",
    GUARANTEE_FUND: "Fondo de garantía"
  };
  return typeDocs[type]
}

const contractType = (type) => {
  const types = {
    ORIGINAL: "Original",
    ADDITIVE: "Aditiva",
  };
  return types[type];
}

const typeDocumentsContractor = (type) => {
  const typeDocs = {
    SIROC: "Siroc",
    ALTA_DEL_IMSS: "Alta",
    RESUMEN_SIROC_INICIAL: "Alta",
    INDENTIFICACION_PATRONAL: "Alta",
    RELACION_MENSUAL_DE_TRABAJADORES: "Mensual",
    REPORTE_BIMESTRAL: "Mensual",
    RESUMEN_SUA: "Mensual",
    PAGO_SUA: "Mensual",
    OPINION_CUMPLIMIENTO: "Mensual",
    ARCHIVO_SUA: "Mensual",
    NOMINAS: "Mensual",
    ISR_SALARIOS: "Mensual",
    ISR_DECLARACION: "Mensual",
    ISR_RECIBO: "Mensual",
    IVA_DECLARACION: "Mensual",
    IVA_ACUSE: "Mensual",
    IVA_PAGO: "Mensual",
    ISR_SALARIOS_SUBSIDIO: "Mensual",
    ICSOE: "Mensual",
    SISUB: "Mensual",
    SITUACION_FISCAL: "Mensual",
    BAJA_DEL_IMSS: "Cierre",
    RESUMEN_SIROC_FINAL: "Cierre"
  };
  return typeDocs[type]
}

const optionsPurchaseOrders = (status) => {
  const typeStatus = {
    IS_PO_REVIEW: "Revisión",
    IS_PO_REVISED: "Revisada",
    IS_PO_APPROVED: "Aprobada",
    IS_PO_CANCEL: "Cancelada",
    IS_PO_SENT: "Enviada",
    IS_REQ_REVISION: "Revisión ",
    IS_REQ_REVISED: "Revisada ",
    IS_REQ_APPROVED: "Aprobada ",
    IS_REQ_CANCEL: "Cancelada "
  };

  const typeStatusRevert = {
    Revisión: "IS_PO_REVIEW",
    Revisada: "IS_PO_REVISED",
    Aprobada: "IS_PO_APPROVED",
    Cancelada: "IS_PO_CANCEL",
    Enviada: "IS_PO_SENT",
    "Revisión ": "IS_REQ_REVISION",
    "Revisada ": "IS_REQ_REVISED",
    "Aprobada ": "IS_REQ_APPROVED",
    "Cancelada ": "IS_REQ_CANCEL"
  }

  if (typeStatus[status]) {
    return typeStatus[status]
  } else if (typeStatusRevert[status]) {
    return typeStatusRevert[status]
  } else {
    return status
  }
}


const statusDocumentContract = (status) => {
  const typeStatus = {
    INVOICED: "Facturado",
    PAID_OUT: "Pagado",
    CANCELLED: "Cancelado",
    BY_BILL: "Por facturar",
    BY_AUTHORIZING: "Por autorizar",
    PAID_PARTIALLY: "Pagado parcialmente",
    AUTHORIZED: "Autorizado",
    NA: "NA",
    CREATED: "Creado",
    ESTIMATED: "Estimado",
    DEDUCTED: "Deducido"
  };
  return typeStatus[status]
}

const addStatus = (status) => {
  return status.map((value, key) => {
    return {
      key: key + 1,
      text: statusDocumentContract(value),
      value
    }
  });
}

const getExtesion = (filename) => {
  return filename.split(".").pop();
}

const generatePassword = (length) => {
  const chars = "abcdefghijklmnopqrstuvwxyz!@$()ABCDEFGHIJKLMNOP1234567890";
  let pass = "";
  for (let x = 0; x < length; x++) {
    const i = Math.floor(Math.random() * chars.length);
    pass += chars.charAt(i);
  }

  return pass;
}

const getNameOriginal = (filename) => {
  const arr = filename.split('/');
  const arr2 = arr[3].split('-');
  arr2.shift();
  return arr2.join(' ');
}

const validDatesCreateContract = (contract) => {
  const { startDate, finishDate, startDateSiroc, finishDateSiroc } = contract

  const sDate = moment(startDate, 'YYYY-MM-DD');
  const fDate = moment(finishDate, 'YYYY-MM-DD');
  const sDateSiroc = moment(startDateSiroc, 'YYYY-MM-DD');
  const fDateSiroc = moment(finishDateSiroc, 'YYYY-MM-DD');

  if (fDate.diff(sDate, 'days', true) < 0) {
    return {
      error: true,
      message: 'El término de contrato debe de ser posterior a la fecha inicio del mismo'
    }
  }

  if ((sDateSiroc.diff(sDate, 'days', true) < 0) || fDate.diff(sDateSiroc, 'days', true) < 0) {
    return {
      error: true,
      message: 'La fecha del alta del imss, debe de ser posterior a la fecha inicio e inferior a la fecha termino del contrato'
    }
  }

  if ((fDateSiroc.diff(sDate, 'days', true) < 0) || fDate.diff(fDateSiroc, 'days', true) < 0) {
    return {
      error: true,
      message: 'La fecha del alta del imss, debe de ser posterior a la fecha inicio e inferior a la fecha termino del contrato'
    }
  }

  return { error: false }
}

const validAmountContract = async ({ id, total }) => {
  const { data: { contract: { invoiced } } } = await client.query({ query: GET_ORIGINAL_CONTRACT, variables: { id: id }, fetchPolicy: "network-only" })

  if (total < invoiced) {
    return {
      error: true,
      message: 'Lo facturado supera el monto del contrato'
    }
  } else {
    return false;
  }
}

/* start and end = moment Object */
const howDocumentMonthly = (startDate, finishDate) => {
  const start = moment(startDate, "YYYY-MM-DD");
  const finish = moment(finishDate, "YYYY-MM-DD");

  let months = (finish.year() - start.year()) * 12;
  months -= start.month();
  months += finish.month();
  return months === 0 ? 1 : months + 1;
}

const validCompleteDocumentContract = async ({ id }) => {
  const { data } = await client.query({ query: GET_COMPLETE_DOCUMENT_CONTRACTOR, variables: { id: id }, fetchPolicy: "network-only" })

  if (data.contract === null) {
    return {
      error: false
    }
  } else if (data.contract.documentsContractor.length === 0) {
    return {
      error: false
    }
  } else {
    return {
      error: true,
      message: 'No puedes actualizar el contrato, ya tiene los documentos completos, favor de revisar.'
    }
  }
}

const validStateContract = async (contract) => {
  /* Validaciones de las fechas de inicio y termino de contrato, asi como la de la alta y baja siroc */
  const { error: errorDates, message: messageDate } = validDatesCreateContract(contract);
  if (errorDates) return { error: errorDates, message: messageDate }

  /* Si se modifica el monto del contrato, este no debe ser infernior a lo actual facturado o pagado */
  const { error: errorAmount, message: messageAmount } = await validAmountContract(contract);
  if (errorAmount) return { error: errorAmount, message: messageAmount }

  /* Validar si tenemos todos los documentos completados para no actualizar el contrato, cuando no se refresca la pagina */
  const { error: errorComplete, message: messageComplete } = await validCompleteDocumentContract(contract);
  if (errorComplete) return { error: errorComplete, message: messageComplete }

  return { error: false }
}

const dateGraphql = search => {
  if (search) {
    if (search.date) {
      return {
        ...search,
        date: moment(search.date, 'YYYY-MM-DD'),
        date_lt: moment(search.date, 'YYYY-MM-DD').add(1, 'days')
      }
    } else {
      return { ...search, date: undefined, date_lt: undefined }
    }
  }

  return search
}

export default {
  currency,
  date,
  status,
  getExtesion,
  typeDocumentsContract,
  statusDocumentContract,
  typeDocumentsContractor,
  addStatus,
  generatePassword,
  getNameOriginal,
  validDatesCreateContract,
  howDocumentMonthly,
  validAmountContract,
  validStateContract,
  dateGraphql,
  optionsPurchaseOrders,
  contractType
};
