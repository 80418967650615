import gql from "graphql-tag";

const GET_CLIENTS = gql`
  {
    clients{
      key: id
      text: commercialName,
      value: id
      id
      commercialName
      projects {
        id
        name
        cli
        status  
      }
    }
    contractAccountBalances(last: 1) {
      IDFolio
    }
  }
`;

export default GET_CLIENTS;
