import React from "react";
import PropTypes from "prop-types";
import "./style.css";

class TabNavigation extends React.Component {
  state = {
    active: this.props.active
  }

  // change the value of active in state
  // and run callback function
  changeActive = (active) => {
    this.setState({
      active,
    });

    // run if a function
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(active);
    }
  }

  render() {
    return (
      <div className={`tab-navigation ${this.props.type} ${this.props.position}`}>
        {this.props.links.map(link => (
          <div
            key={link.name}
            className={ this.state.active === link.name ? "link link-active" : "link"}
            onClick={() => {
              if (this.state.active === link.name) return false;
              // send link name and onClick function
              this.changeActive(link.name);
            }}
          >
            {link.text}
          </div>
        ))}
      </div>
    );
  }
}

TabNavigation.propTypes = {
  type: PropTypes.oneOf(["secondary", ""]),
  position: PropTypes.oneOf(["center", ""]),
  active: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func,
}

TabNavigation.defaultProps = {
  type: "",
  position: "",
  links: [],
}

export default TabNavigation;
