import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { format } from "../../../helpers";
import { GET_DOCUMENTS_CONTRACTOR } from "../../queries/contractors";

const APPROVAL_DOCUMENT_CONTRACTOR = async (idContract, idDocument, idUserApproval) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        approvalDocumentContract(
          where: { id: "${idContract}" }
          data: {
            documentsContractor: {
              update: {
                where: { id: "${idDocument}" }
                data: {
                  authorizer: "${idUserApproval}"
                  dateAuthorizer: "${format.date(new Date(), 2)}"
                  status: APPROVED
                }
              }
            }
          }
        ) {
          id
        }
      }
    `,
    refetchQueries: [{ query: GET_DOCUMENTS_CONTRACTOR }]
  });
  return mutation;
};

export default APPROVAL_DOCUMENT_CONTRACTOR;
