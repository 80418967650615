export default config => {
  var formData = config.data;

  if (formData instanceof FormData) {
  } else if (formData instanceof File) {
    var data = new FormData();
    data.append("file", formData);
    config.data = data;
  }
  sendImportAjax(config);
};

function sendImportAjax(config) {
    var apiUrl = "https://export.dhtmlx.com/gantt";
    var url = config.server || apiUrl;
    var store = config.store || 0;
    var formData = config.data;
    var callback = config.callback;
  
    var settings = {};
    if (config.durationUnit) settings.durationUnit = config.durationUnit;
    if (config.projectProperties)
      settings.projectProperties = config.projectProperties;
    if (config.taskProperties) settings.taskProperties = config.taskProperties;
  
    formData.append("type", "msproject-parse");
    formData.append("data", JSON.stringify(settings));
  
    if (store) formData.append("store", store);
  
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function(e) {
      if (xhr.readyState === 4 && xhr.status === 0) {
        // network error
        if (callback) {
          callback(null);
        }
      }
    };
  
    xhr.onload = function() {
      var fail = xhr.status > 400;
      var info = null;
  
      if (!fail) {
        try {
          info = JSON.parse(xhr.responseText);
        } catch (e) {}
      }
  
      if (callback) {
        callback(info);
      }
    };
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.send(formData);
  }