import React from "react";
import { Query } from "react-apollo";
import {
  TableRow,
  TableCell,
  ButtonGroup,
  Button,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { IoMdCreate, IoIosTrash } from "react-icons/io";
import { getReceptionItems, getReceptionItemsByTechnician } from "../../../graphql/queries/inventories";
import deleteItem from "../../../graphql/mutations/inventories/deleteItem";
import { client } from "../../../graphql/apollo-config";
import _ from "lodash";
import OnLoading from "../../Molecules/OnLoading";

export default withRouter(
  class ReceptionsQuery extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: JSON.parse(window.localStorage.getItem("session"))
          ? JSON.parse(window.localStorage.getItem("session")).user
          : "",
        loading: true,
        status: null,
        paymentStatusOptions: [
          { key: 0, value: "PAID", text: "Pagado" },
          { key: 1, value: "PARIALLY_PAID", text: "Parcialmente pagado" },
          { key: 2, value: "COMPLETLY_PAID", text: "Completamente pagado" },
          { key: 3, value: "INVOICED", text: "Facturado" },
          { key: 4, value: "PENDING_INVOICE", text: "Por facturar" },
        ],
        statusOptions: [
          { text: "Pendiente", key: "PENDING", value: "PENDING" },
          {
            text: "Parcialmente recibido frontera",
            key: "PARCIAL_INTERNATIONAL",
            value: "PARCIAL_INTERNATIONAL",
          },
          {
            text: "Completamente recibido frontera",
            key: "COMPLETE_INTERNATIONAL",
            value: "COMPLETE_INTERNATIONAL",
          },
          {
            text: "Importado",
            key: "IMPORTED",
            value: "IMPORTED",
          },
          {
            text: "Parcialmente recibido nacional",
            key: "PARCIAL_NATIONAL",
            value: "PARCIAL_NATIONAL",
          },
          {
            text: "Completo",
            key: "COMPLETE",
            value: "COMPLETE",
          },
          {
            text: "Cancelado",
            key: "CANCELLED",
            value: "CANCELLED",
          },
        ],
      };
    }
    parseStatus = status => {
      const STATUS_TYPE = {
        PENDING: "Pendiente",
        PARCIAL_INTERNATIONAL: "Parcialmente recibido en frontera",
        COMPLETE_INTERNATIONAL: "Completamente recibido en frontera",
        IMPORTED: "Importado",
        PARCIAL_NATIONAL: "Parcialmente recibido nacional",
        COMPLETE: "Completo",
        CANCELLED: "Cancelado"
      };
      return STATUS_TYPE[status];
    };

    openEdit = id => this.props.history.push(`/inventarios/edit/${id}`);

    parsePaymentStatus = status => {
      const STATUS_TYPE = {
        PAID: "Pagado",
        PARIALLY_PAID: "Parcialmente pagado",
        COMPLETLY_PAID: "Completamente pagado",
        INVOICED: "Facturado",
        PENDING_INVOICE: "Por facturar"
      };
      return STATUS_TYPE[status];
    };

    handleDate = date => {
      const d = date
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      return d;
    };

    ReceptionStatus = props => (
      <Dropdown
        name="status"
        selection
        options={this.state.statusOptions}
        onChange={(e, { value, id }) => {
          this.handleStatusChange(e, { value, id });
        }}
        style={{ border: "none" }}
        defaultValue={props.defaultValue}
        id={props.id}
        disabled={props.disabled}
      />
    );

    ReceptionPaymentStatus = props => (
      <Dropdown
        name="paymentStatus"
        selection
        options={this.state.paymentStatusOptions}
        onChange={this.handlePaymentStatusChange}
        style={{ border: "none" }}
        defaultValue={props.defaultValue}
        id={props.id}
        disabled={props.disabled}
      />
    );

    handleStatusChange = (e, { value, id }) => {
      this.props.updateReceptionStatus(id, value);
    };

    handlePaymentStatusChange = (e, { value, id }) => {
      this.props.updateReceptionPaymentStatus(id, value);
    };

    delete = (id, idItem, status, refetch) => {
      const confirm = window.confirm("Desea eliminar el item?");
      if (status === "PENDING") {
        if (confirm) {
          deleteItem(id, idItem).then(res => {
            refetch();
          });
        }
      } else {
        alert("No puedes eliminar una orden en proceso");
      }
    };

    handledeliveryDate = (receivedSupplies) => {
      const date = [];

      receivedSupplies.map(({ deliveryDate }) => {
        if (deliveryDate !== null) date.push(deliveryDate.split("T")[0]);
      });

      const areUnique = date.every((val, i, arr) => val === arr[0]);

      if (date.length === 1) return moment(date[0]).format("DD/MM/YYYY");
      if (date.length > 1 && areUnique)
        return moment(date[0]).format("DD/MM/YYYY");
      if (date.length > 1 && !areUnique) return "Múltiple";
    };

    componentDidMount() {
      const { first } = this.props;
      this.getOrders(first);
    }

    componentDidUpdate(prevProps) {
      const { data } = this.state;
      const { sort, activePage, first, search } = this.props;

      if (prevProps.activePage !== activePage || prevProps.first !== first) {
        this.getOrders(first, search);
      }

      if (prevProps.sort.column !== sort.column || prevProps.sort.direction !== sort.direction) {
        const sorting = _.orderBy(data, [sort.column], [sort.direction.replace("ending", "")]);
        this.setState({
          data: sorting
        });
      }

      if (prevProps.search !== search) {
        this.getOrders(first, search);
      }
    }

    getOrders = async (first, search) => {
      const { user: { id: userId, userRol }} = this.state
      const { activePage } = this.props;

      const getQuery = {
        query: getReceptionItems,
        variables: {
          first,
          skip: first * (activePage - 1),
          search,
          floatSearch: isNaN(search) ? undefined : parseFloat(search)
        },
        fetchPolicy: "network-only"
      };

      if (userRol === "TECHNICIAN") {
        getQuery["query"] = getReceptionItemsByTechnician
        getQuery["variables"]["userId"] = userId
      }

      const { loading, error, data, refetch } = await client.query(getQuery);

      const totalRecords = data.ct.length
      const count = data.purchaseOrders.length;

      this.props.getCount(totalRecords, count, activePage);

      const orders = data.purchaseOrders
        .map(order => {
          const warehouse = JSON.parse(order.data.deliveryWarehouse);
          return {
            ...order,
            folio: parseInt(order.folio),
            warehouseId: warehouse.id,
            warehouseName: warehouse.name
          };
        });

      this.setState({ loading, error, data: orders, refetch });
    }

    render() {
      const { loading, error, data, refetch, user: { userRol }  } = this.state;
      const ReceptionStatus = this.ReceptionStatus;
      const ReceptionPaymentStatus = this.ReceptionPaymentStatus;

      if (loading || error || !data) {
        return <OnLoading loading={loading} error={error} />
      }

      return data.map(item => {
        return (
          <TableRow key={item.id}>
            <TableCell>{item.folio}</TableCell>
            <TableCell>{item.data.project.name}</TableCell>
            <TableCell>{item.data.vendor && item.data.vendor.commercialName}</TableCell>
            <TableCell>{item.warehouseName}</TableCell>
            <TableCell>
              {item.data.wasUpdatedAt !== null
                ? this.handleDate(item.data.wasUpdatedAt)
                : ""}
            </TableCell>
            <TableCell>
              {this.handledeliveryDate(
                item.data.receivedSupplies
              )}
            </TableCell>
            <TableCell>
              {parseFloat(item.data.total)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </TableCell>
            <TableCell>
              <ReceptionPaymentStatus
                defaultValue={item.data.paymentStatus}
                id={item.id}
                disabled={userRol === 'TECHNICIAN' ? true : false}
              />
            </TableCell>
            <TableCell>
              <ReceptionStatus
                defaultValue={item.data.deliveryStatus}
                id={item.id}
                refetch={refetch}
                disabled={userRol === 'TECHNICIAN' ? true : false}
              />
            </TableCell>
            <TableCell>
              <ButtonGroup basic>
                <Button onClick={() => this.openEdit(item.id)} icon>
                  <IoMdCreate name="edit" />
                </Button>
                {item.data.deliveryStatus !== "PENDING" || userRol === 'TECHNICIAN' ? (
                  <Button onClick={null} icon>
                    <IoIosTrash style={{ color: "#dddddd" }} />
                  </Button>
                ) : (
                    <Button
                      onClick={() => {
                        this.delete(
                          item.warehouseId,
                          item.id,
                          item.data.deliveryStatus,
                          refetch
                        );
                      }}
                      icon
                    >
                      <IoIosTrash />
                    </Button>
                  )}
                <Button
                  onClick={() => {
                    this.props.openReceptionModal(item.id);
                  }}
                  icon
                >
                  <Icon name="clipboard" />
                </Button>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        );
      });
    }
  }
);
