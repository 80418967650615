import React, { Component } from "react";
import { FormHeader } from "../../Molecules";
import { Button, Container, Header, Segment, Grid } from "semantic-ui-react";
import { ContractForm } from "../";
import { validator, format } from "../../../helpers";
import { bugsnagClient } from "../../../bugsnag";
import { alertProjectInactive } from "../../../helpers/alert-project-inactive";
import contractService from "../../../graphql/services/contract/create-contract";

class CreateContract extends Component {
  state = {
    isLoading: false,
    contract: {
      id: "",
      name: "",
      project: {
        cli: ""
      },
      contractor: "",
      siroc: "",
      documentsContractor: [],
      street: "",
      colony: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      externalNumber: "",
      total: "",
      currency: "MXN", // Currency USD not available
      startDate: "",
      finishDate: "",
      startDateSiroc: "",
      finishDateSiroc: "",
      description: "",
      contractType: "",
      guaranteeFund: ""
    },
    errors: {
      name: false,
      project: false,
      contractor: false,
      siroc: false,
      street: false,
      colony: false,
      city: false,
      state: false,
      country: false,
      postalCode: false,
      externalNumber: false,
      total: false,
      currency: false,
      startDate: false,
      finishDate: false,
      description: false,
      contractType: false,
      guaranteeFund: false
    },
  }

  // return object with values in the state to validate
  contractorValidateFields = () => {
    const { errors, contract } = this.state;
    let fieldsValue = {};

    for (const field in errors) {
      if (field === "project") {
        fieldsValue[field] = contract[field].cli;
      } else {
        fieldsValue[field] = contract[field];
      }
    }

    return fieldsValue;
  }

  validateFields = validationObject => {
    let errors = [];
    const validation = validator(validationObject);

    validation.forEach(field => {
      const key = field.key;
      if (!field.isValid) {
        errors.push(key);
      }
    });

    return errors;
  };

  // change contract state value
  change = (name, value, options) => {
    const { contract } = this.state;
    let isProjectInactive = false

    if (name === "project" || name === "contractor") {
      value = options.find(item => item.id===value);
      const { status, text } = value
      isProjectInactive = alertProjectInactive({ status, module: 'contract', projectName: text });
    }

    if (isProjectInactive) {
      contract[name].cli = "";
      this.setState({ contract });
      return
    }

    if (["total", "guaranteeFund"].includes(name))
      if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value))
        contract[name] = '';

    if (name === "documentsContractor") {
      this.setState(prevState => ({
        contract: {
          ...prevState.contract,
          documentsContractor: value
        }
      }))
      return
    }

    contract[name] = value;
    this.setState({ contract });
  }

  save = async () => {
    const { errors, contract } = this.state;

    /* Validaciones de las fechas de inicio y termino de contrato, asi como la de la alta y baja siroc */
    const { error, message } = format.validDatesCreateContract(contract);
    if (error) {
      return alert(message);
    }

    // launch loading
    // Si todo OK validamos los inputs
    this.setState({ isLoading: true });

    // validate inputs
    // get contractor errors state object
    const validationErrors = Object.assign({}, errors);
    // get inputs to validate
    const contractValidateObj = this.contractorValidateFields();
    const contractErrors = this.validateFields(contractValidateObj);

    if (contractErrors.length > 0) {
      Object.keys(validationErrors).forEach((key) => {
        validationErrors[key] = contractErrors.indexOf(key) !== -1;
      });

      this.setState(data => ({
        errors: validationErrors,
        isLoading: false,
      }));

      return false;
    }

    try {
      await contractService.createContract(contract);
      this.props.close(true);
    } catch (err) {
      if (err.graphQLErrors) {
        const [gqlError] = err.graphQLErrors
        const { code, message } = gqlError || {}
        if (["ORIGINAL_CONTRACT_ALREADY_EXISTS", "ORIGINAL_CONTRACT_NOT_FOUND"].includes(code)) {
          alert(message)
        }
      }
      bugsnagClient.notify(err)
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // close Add Contractor View
  cancel = () => {
    this.props.close();
  }

  render() {
    const { isLoading, contract, errors } = this.state
    // set modal title
    const modalTitle = this.props.id ? "Datos del contrato" : "Nuevo contrato";
    // disabled save function if isLoading true
    const saveFunction = !isLoading ? this.save : null;

    return (
      <Segment raised className="Modal">
        {this.props.readOnly ? (
          <Header as="h3" dividing className="Modal__Header">
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column textAlign="left" className="Modal__Header-Title">
                  Detalle del Contrato
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Container>
                    <Button onClick={this.cancel}>Cancelar</Button>
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
        ) : (
          <FormHeader
            text={modalTitle}
            onSave={saveFunction}
            onCancel={this.cancel}
          />
        )}

        <ContractForm
          change={this.change}
          contract={contract}
          errors={errors}
          readOnly={false}
          isUpdate={false}
        />
      </Segment>
    )
  }
}

export default CreateContract
