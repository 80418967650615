import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import getUserRolSelect from "../../../graphql/queries/users/getUserRolSelect"

const SelectUserRol = (props) => {
  const {
    rol,
    width,
    label,
    name,
    error,
    value,
    onChange,
    disabled = false
  } = props

  return (
    <Query query={getUserRolSelect} variables={{ rol }} fetchPolicy={"network-only"}>
      {({ loading, error, data }) => {
        if (loading) {
          return <p testid="loading-wrapper">loading...</p>;
        }
        if (error) {
          return <p testid="error-wrapper">error</p>;
        }

        const { users } = data

        return (
          <Dropdown
            width={width}
            fluid
            multiple
            selection
            options={users}
            label={label}
            name={name}
            error={error}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        );
      }}
    </Query>
  )
}

export default SelectUserRol;
