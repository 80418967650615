import gql from "graphql-tag";
import { client } from "../../apollo-config";

const deleteClient = async id => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        deleteClient(where: { id: "${id}" }) {
          id
        }
      }
    `
  });
  return mutation;
};

export default deleteClient;
