import gql from "graphql-tag";

const getProjects = gql`
  query projects(
    $first: Int
    $skip: Int
    $orderBy: ProjectOrderByInput
    $search: String
    $searchUpper: String
    $searchLower: String
    $searchCaps: String
  ) {
    projects(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: {
        OR: [
          { name_contains: $search },
          { name_contains: $searchUpper },
          { name_contains: $searchLower },
          { name_contains: $searchCaps },
          { cli_contains: $search },
          { cli_contains: $searchUpper },
          { cli_contains: $searchLower },
          { cli_contains: $searchCaps },
          { client: { commercialName_contains: $search } }
          { client: { commercialName_contains: $searchUpper } }
          { client: { commercialName_contains: $searchLower } }
          { client: { commercialName_contains: $searchCaps } }
        ]
      }
    ) {
      id
      cli
      client{
        id
        commercialName
      }
      name
      startDate
      finishDate
      wasUpdatedAt
      status
      address
      amount
      count
    }
  }
`;

export default getProjects;
