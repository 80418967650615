import gql from "graphql-tag";

const GET_ADITIVES_CONTRACT = gql`
  query GetAditivesContract($id: ID!) {
    contract(where: {id: $id}){
      currency
      amountDeductives
      amountAditives
      realFinishDate
      project{
        cli
      }
      aditives {
        id
        consecutive
        startDate
        finishDate
        description
        currency
        total
        invoiced
        paided
        amountDeductives
        documentsAditivas {
          id
          type
          concept
          elaborated
          dateElaborated
          authorizer
          amount
          iva
          amountIva
          total
          status
          refUpload {
            id
            type
            reference
            extension
            wildcard
          }
        }
      }
    }
  }
`

export default GET_ADITIVES_CONTRACT
