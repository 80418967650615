export default [
  "",
  "Clave",
  "Nombre",
  "Descripción",
  "Unidad de medida",
  "Fecha de envío",
  "Fecha de entrega",
  "Precio unitario",
  "Cantidad",
  "Total",
  "Cantidad recibidos",
  "",
];
