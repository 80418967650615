import gql from "graphql-tag";
import { client } from "../../apollo-config";

const getWarehouseDropdown = gql`
  {
    warehouses {
      id
      name
    }
  }
`;
export default getWarehouseDropdown;
