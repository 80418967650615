import React from "react";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import { Segment } from "semantic-ui-react";
import { Gantt } from "./components";
import "./styles.css";

export default () => (
  <Segment>
    <div className="gantt-container">
      <Gantt />
    </div>
  </Segment>
);
