import gql from "graphql-tag";
import { client } from "../../apollo-config";

const createPO = async (folio, reqId) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        createPurchaseOrder(
          data: {
            folio: "${folio}",
            convertedAt: "${new Date().getTime()}"
            data: {
              connect: {
                id:"${reqId}"
              }
            }
          }
        ) {
          id
          data {
            id
          }
        }
      }
    `,
  });
  return mutation;
};

export default createPO;
