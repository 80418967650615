import gql from "graphql-tag";
import { client } from "../../apollo-config";

const receiveSupplies = async (id, itemID) => {
  // Fix cli wrong map
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateWarehouse(
          where: { id: "${id}" }
          data: { orders: { disconnect: { id: "${itemID}" } } }
        ) {
          id
        }
      }
    `,
  });
  return mutation;
};

export default receiveSupplies;
