import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async id => {
  return await client.query({
    query: gql`{
      user(where: { id: "${id}"}){
        name
        email
        userRol
        
      }   
    }`,
    fetchPolicy: "network-only"
  });
};
