import gql from "graphql-tag";
import { client } from "../../apollo-config";

const createClient = async ({
  commercialName,
  street,
  number,
  colony,
  postalCode,
  city,
  state,
  country,
  rfc,
  contacts,
  razonSocial
}) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        createClient(
          data: {
            commercialName: "${commercialName}"
            street: "${street}"
            number: "${number}"
            colony: "${colony}"
            postalCode: ${parseInt(postalCode)}
            city: "${city}"
            state: "${state}"
            country: "${country}"
            rfc: "${rfc}"
            razonSocial: "${razonSocial}"
            contacts: {
              create: [${contacts.map(project => {
                const str = JSON.stringify(project);
                var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                return obj;
              })}]
            }
          }
        ) {
          id
        }
      }
    `
  });
  return mutation;
};

export default createClient;
