import gql from "graphql-tag";
import { client } from "../../apollo-config";

const connectPOToProject = async (purchaseOrderID, projectID) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateProject(data: {
            purchaseOrders:{
              connect:{
                id:"${purchaseOrderID}"
              }
            }
        } where:{id:"${projectID}"}) {
          id
        }
      }
    `,
  });
  return mutation;
};

export default connectPOToProject;
