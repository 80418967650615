import React from "react";
import { Form, Button, Segment, Grid, Label, Dropdown, Pagination } from "semantic-ui-react";
import RequisitionsQuery from "../../Molecules/requisitionsQuery";
import { updatePOStatus } from "../../../graphql/mutations/purchases";
import theme from "../../_theme";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from "moment";

const statusOptions = [
  { key: 0, text: "Todos", value: undefined },
  { key: 1, text: "Revisión", value: "IS_REQ_REVISION" },
  { key: 2, text: "Revisada", value: "IS_REQ_REVISED" },
  { key: 3, text: "Aprobada", value: "IS_REQ_APPROVED" },
  { key: 4, text: "Cancelada", value: "IS_REQ_CANCEL" }
]

export default class RequisitionsList extends React.Component {
  state = {
    search: {
      searchBox: undefined,
      status: undefined,
      date: undefined
    },
    totalRecords: 0,
    activePage: 1,
    first: 10,
    entrieSelected: 10,
    entriesOptions: [
      { key: 1, text: "10", value: 10 },
      { key: 2, text: "20", value: 20 },
    ]
  }

  onChangeSelect = (e, { value, data }) => {
    const id = data.id;
    const status = value;
    if (this.checkRequisitionsRoute()) {
      const confirmation = window.confirm(
        `¿Deseas cambiar el status la requisición a ${
        e.currentTarget.innerText
        }?`
      );
      if (confirmation) {
        updatePOStatus(this.isReq(status).status, id, this.isReq(status).isReq)
          .then(res => {
            window.location = "/compras";
          })
          .catch(e => console.log(e));
      }
    } else {
      return;
    }
  };

  isReq = status => {
    if (status === "IS_REQ_APPROVED") {
      return { isReq: true, status: "IS_REQ_APPROVED" };
    } else {
      return { isReq: true, status: status };
    }
  };

  checkRequisitionsRoute = () => {
    const route = this.props.activeRoute === "req" ? true : false;
    return route;
  };

  getCount = (totalRecords, first, activePage) => {
    this.setState({ totalRecords, first, activePage });
  }

  onPageChange = (e, { activePage }) => this.setState({ activePage });

  handleEntries = (e, { value }) => this.setState({ entrieSelected: value, activePage: 1 });

  render() {
    const { activePage, first, totalRecords, entriesOptions, entrieSelected } = this.state;

    return (
      <Segment>
        <Grid>
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column width={13}>
              <Form className="Wrapper__Body-Form">
                <Form.Group>
                  <Form.Input
                    inline
                    placeholder="Buscar"
                    icon="search"
                    onChange={(e, { value }) => this.setState(state => ({ search: { ...state.search, searchBox: value }, activePage: 1 }))}
                    value={this.state.search.searchBox}
                  />
                  <Form.Select
                    placeholder="Estatus"
                    name="status"
                    options={statusOptions}
                    value={this.state.search.status}
                    onChange={(e, { value }) => this.setState(state => ({ search: { ...state.search, status: value }, activePage: 1 }))}
                  />
                  <Form.Field className='Util-DatePicker'>
                    <SemanticDatepicker
                      name="date"
                      placeholder="Fecha"
                      type="basic"
                      format="DD/MM/YYYY"
                      onDateChange={(date) => this.setState(state => ({ search: { ...state.search, date: (date !== null && moment(date).format('YYYY-MM-DD')) }, activePage: 1 }))}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right" width="3">
              <Button
                id={
                  this.props.activeRoute === "req"
                    ? "add-requisition"
                    : "add-po"
                }
                onClick={this.props.toggleCreateReq}
                basic
                icon="plus"
                className="Wrapper__Body-Form-ButtonAdd"
                content={
                  this.props.activeRoute === "req"
                    ? "Requisición"
                    : "Orden de compra"
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <RequisitionsQuery
              activeRoute={this.props.activeRoute}
              onChange={this.onChangeSelect}
              openCreate={this.props.openPOFromReq}
              toggleEditRequisirtion={this.props.toggleEditRequisirtion}
              toggleDetailRequisirtion={this.props.toggleDetailRequisirtion}
              setReqRefetch={this.props.setReqRefetch}
              search={this.state.search}
              getCount={this.getCount}
              first={entrieSelected}
              activePage={activePage}
            />
          </Grid.Row>
          <Grid.Row centered>
            <Grid columns={3}>
              <Grid.Row centered>
                <Grid.Column textAlign="center">
                  <Label style={theme.styles.paginationLabel}>Ver</Label>
                  <Dropdown
                    id="recordsDropdown"
                    name="recordsDropdown"
                    selection
                    text={`${entrieSelected}`}
                    options={entriesOptions}
                    item
                    onChange={this.handleEntries}
                    compact />
                  <Label style={theme.styles.paginationLabel}>Entradas</Label>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Label style={theme.styles.entriesLabel}>Viendo</Label>
                  <Label style={theme.styles.entriesBoldLabel}>{first}</Label>
                  <Label style={theme.styles.entriesLabel}>de</Label>
                  <Label style={theme.styles.entriesBoldLabel}>{totalRecords}</Label>
                  <Label style={theme.styles.entriesLabel}>Entradas</Label>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  {totalRecords !== 0 &&
                    <Pagination
                      className='Pagination-Fix'
                      boundaryRange={1}
                      siblingRange={0}
                      size='mini'
                      activePage={activePage}
                      totalPages={Math.ceil(totalRecords / entrieSelected)}
                      onPageChange={this.onPageChange}
                    />
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

