import React from "react";
import { Icon, Grid, Header, Button } from "semantic-ui-react";
import { Can } from "../../hoc/permissions";
import graphLine from "./graphLine.png";
import { ViewDocumentSegment, ViewDocumentLink } from "../../Molecules";
import { format } from "../../../helpers";
import { bugsnagClient } from "../../../bugsnag";

class ContractOpenDocuments extends React.Component {
  state = {
    disabledSave: true,
    contractorDocuments: null,
    references: null,
  }

  componentDidMount() {
    this.updateContract();
  }

  componentDidUpdate(prevProps) {
    const { documents } = this.props;

    // documents created
    if ((prevProps.documents === null && documents)) {
      this.updateContract();
    } else if (prevProps.documents !== null && documents) {
      if (prevProps.documents.length !== documents.length) {
        this.updateContract();
      }
    }
  }

  updateContract = () => {
    const { documents } = this.props;

    if (documents) {
      const contractorDocuments =  JSON.parse(JSON.stringify(documents));
      const references = [];

      contractorDocuments.forEach((element) => {
        references.push({
          id: element.refUpload[0].id,
          reference: element.refUpload[0].reference,
          wildcard: element.type,
          statusDocument: element.status,
          type: "OTHERS"
        });
      });

      this.setState({
        contractorDocuments,
        references,
      });
    }
  }

  onSelectFile = async (files, documentType, title, loadFinished) => {
    const form = new FormData();

    if (files.length > 0) {
      form.append("file", files[0]);

      try {
        const res = await fetch(process.env.REACT_APP_S3, {
          method: "POST",
          body: form,
        });
        if (res.ok) {
          const file = await res.json();
          let { contractorDocuments } = this.state;
          const extension = format.getExtesion(file.imageUrl);

          if (contractorDocuments) {
            const documentIndex = contractorDocuments.findIndex((element) => {
              return element.type === documentType;
            });

            if (documentIndex !== -1) {
              contractorDocuments[documentIndex].description = title;
              contractorDocuments[documentIndex].refUpload.reference = file.imageUrl;
              contractorDocuments[documentIndex].refUpload.extension = extension;
            } else {
              contractorDocuments.push({
                type: documentType,
                description: title,
                refUpload: [
                  {
                    reference: file.imageUrl,
                    extension: extension,
                    wildcard: documentType,
                    type: "OTHERS"
                  }
                ],
              });
            }
          } else {
            contractorDocuments = [{
              type: documentType,
              description: title,
              refUpload: [
                {
                  reference: file.imageUrl,
                  extension: extension,
                  wildcard: documentType,
                  type: "OTHERS"
                }
              ],
            }];
          }

          this.setState({ contractorDocuments, disabledSave: false }, () => {
            loadFinished();
          });
        }
      } catch (err) {
        bugsnagClient.notify(err);
      }
    }
  }

  downloadHandler = (refDownload) => {
    window.open(refDownload, "_blank");
  }

  getDocumentRef = (wildcard) => {
    const { references } = this.state;

    if(references) {
      const findedReference = references.find((element) => {
        return element.wildcard === wildcard;
      });

      return typeof findedReference !== "undefined" ? findedReference.reference : null;
    }
    return null;
  }

  getStatusDocument = (wildcard) => {
    const { references } = this.state;

    if(references) {
      const findedReference = references.find((element) => {
        return element.wildcard === wildcard;
      });

      return typeof findedReference !== "undefined" ? findedReference.statusDocument : null;
    }
    return null;
  }

  saveDocuments = () => {
    const { contractorDocuments } = this.state;

    this.setState({
      disabledSave: true
    }, () => {
      this.props.saveContractDocument(contractorDocuments);
    });
  }

  deleteDocument = (documentType, documentReference) => {
    const { references, contractorDocuments } = this.state
    const newReferences = references.filter(oneRef => oneRef.reference !== documentReference);
    const newContractorDocuments = contractorDocuments.filter(oneContractorDocument => oneContractorDocument.refUpload[0].reference === documentReference);
    const idDocument = newContractorDocuments[0].id;

    this.setState({
      disabledSave: true,
      contractorDocuments: null,
      references: newReferences
    }, () => {
      this.props.deleteContractDocument(idDocument, documentType);
    });
  }

  disapproveDocumentHandler = (documentReference) => {
    const { references, contractorDocuments } = this.state
    const newReferences = references.map(oneRef => {
      if(oneRef.reference === documentReference) {
        return Object.assign(oneRef, {
          statusDocument: "BY_APPROVED"
        })
      }
      return oneRef
    });

    const newContractorDocuments = contractorDocuments.filter(oneContractorDocument => oneContractorDocument.refUpload[0].reference === documentReference);
    const idDocument = newContractorDocuments[0].id;

    this.setState({
      disabledSave: true,
      references: newReferences
    }, () => {
      this.props.rejectDocumentContractor(idDocument)
    });
  }

  render() {
    const { percentageTotal, readOnly, openAndCloseDocuments } = this.props

    return (
      <React.Fragment>
        <Grid.Row divided>
          <Grid.Column width="8">
            <Header as="h2" textAlign="center" className="Modal__SubTabNav-Documents-Title">Porcentaje de perfil</Header>
            <Header as="h2" textAlign="center" className="Modal__SubTabNav-Documents-Subtitle">
              <Icon name="clipboard list" />
              Documentos Cargados
            </Header>
            <Header as="h4" textAlign="center" className="Modal__SubTabNav-Documents-Indicator">
              <span>{percentageTotal} % <Icon name="arrow up"/></span>
              <figure>
                <img src={graphLine} width="200" alt="graph"/>
              </figure>
            </Header>
          </Grid.Column>
          <Grid.Column width="8">
            <Header as="h2" textAlign="center" className="Modal__SubTabNav-Documents-Title">Documentos</Header>
              <Can do="upload" on="ContractorDocuments">

                {openAndCloseDocuments !== 1
                  ? (
                    <>
                      <ViewDocumentLink
                        title="Acuse de recibo registro de obra de construcción"
                        onClickDownload={this.downloadHandler}
                        onClickDelete={this.deleteDocument}
                        onSelectFile={this.onSelectFile}
                        refDownload={this.getDocumentRef("ALTA_DEL_IMSS")}
                        statusDocument={this.getStatusDocument("ALTA_DEL_IMSS")}
                        documentType="ALTA_DEL_IMSS"
                        icon
                      />

                      <ViewDocumentLink
                        title="Resumen de obra SIROC"
                        onClickDownload={this.downloadHandler}
                        onClickDelete={this.deleteDocument}
                        onSelectFile={this.onSelectFile}
                        refDownload={this.getDocumentRef("RESUMEN_SIROC_INICIAL")}
                        statusDocument={this.getStatusDocument("RESUMEN_SIROC_INICIAL")}
                        documentType="RESUMEN_SIROC_INICIAL"
                        icon
                      />

                      <ViewDocumentLink
                        title="Tarjeta de identificación patronal"
                        onClickDownload={this.downloadHandler}
                        onClickDelete={this.deleteDocument}
                        onSelectFile={this.onSelectFile}
                        refDownload={this.getDocumentRef("INDENTIFICACION_PATRONAL")}
                        statusDocument={this.getStatusDocument("INDENTIFICACION_PATRONAL")}
                        documentType="INDENTIFICACION_PATRONAL"
                        icon
                      />
                    </>
                  ) : (
                    <ViewDocumentLink
                      title="Acuse de recibo registro de obra de construcción"
                      onClickDownload={this.downloadHandler}
                      onClickDelete={this.deleteDocument}
                      onSelectFile={this.onSelectFile}
                      refDownload={this.getDocumentRef("ALTA_DEL_IMSS")}
                      statusDocument={this.getStatusDocument("ALTA_DEL_IMSS")}
                      documentType="ALTA_DEL_IMSS"
                      icon
                    />
                  )
                }
              </Can>

              <Can do="download" on="ContractorDocuments">
                {openAndCloseDocuments !== 1
                  ? (
                    <>
                      <ViewDocumentSegment
                        title="Acuse de recibo registro de obra de construcción"
                        reference={this.getDocumentRef("ALTA_DEL_IMSS")}
                        onDownloadFile={this.downloadHandler}
                        uploadDocument={true}
                        readOnly={readOnly}
                        isContractor={true}
                        id={true}
                        onDisapproveDocument={this.disapproveDocumentHandler}
                        docsMonthly={false}
                        statusDocument={this.getStatusDocument("ALTA_DEL_IMSS")}
                        icon
                      />

                      <ViewDocumentSegment
                        title="Resumen de obra SIROC"
                        reference={this.getDocumentRef("RESUMEN_SIROC_INICIAL")}
                        onDownloadFile={this.downloadHandler}
                        uploadDocument={true}
                        readOnly={readOnly}
                        isContractor={true}
                        id={true}
                        onDisapproveDocument={this.disapproveDocumentHandler}
                        docsMonthly={false}
                        statusDocument={this.getStatusDocument("RESUMEN_SIROC_INICIAL")}
                        icon
                      />

                      <ViewDocumentSegment
                        title="Tarjeta de identificación patronal"
                        reference={this.getDocumentRef("INDENTIFICACION_PATRONAL")}
                        onDownloadFile={this.downloadHandler}
                        uploadDocument={true}
                        readOnly={readOnly}
                        isContractor={true}
                        id={true}
                        onDisapproveDocument={this.disapproveDocumentHandler}
                        docsMonthly={false}
                        statusDocument={this.getStatusDocument("INDENTIFICACION_PATRONAL")}
                        icon
                      />
                    </>
                  ) : (
                    <ViewDocumentSegment
                      title="Acuse de recibo registro de obra de construcción"
                      reference={this.getDocumentRef("ALTA_DEL_IMSS")}
                      onDownloadFile={this.downloadHandler}
                      uploadDocument={true}
                      readOnly={readOnly}
                      isContractor={true}
                      id={true}
                      onDisapproveDocument={this.disapproveDocumentHandler}
                      docsMonthly={false}
                      statusDocument={this.getStatusDocument("ALTA_DEL_IMSS")}
                      icon
                    />
                  )
                }
              </Can>
          </Grid.Column>
        </Grid.Row>

        <Can do="upload" on="ContractorDocuments">
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button
                content="Guardar"
                className="Modal__Form-ButtonAdd"
                onClick={this.saveDocuments}
                disabled={this.state.disabledSave}
              />
            </Grid.Column>
          </Grid.Row>
        </Can>

      </React.Fragment>
    );
  }
}

export default ContractOpenDocuments;
