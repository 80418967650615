import React from "react";
import { Container, Table, Accordion, Confirm} from "semantic-ui-react";
import OnLoading from "../OnLoading";
import RowsQueryAccountBalance from "./RowsQueryAccountBalance";
import { DELETE_CONTRACT_AB } from "../../../graphql/mutations/accountBalance";
import { GET_CONTRACTS_ADITIVES } from "../../../graphql/queries/accountBalance";
import { bugsnagClient } from "../../../bugsnag";
import { client } from "../../../graphql/apollo-config";
import { format } from "../../../helpers";
import _ from "lodash";

class AccountBalanceQuery extends React.Component {
  state = {
    activeIndex: -1 ,
    confirmOpen: false,
    accountContract: {},
    isOriginalContract: false,
    loading: true,
    error: false,
    data: null,
    column: null,
    direction: "descending"
  }

  componentWillMount() {
    this.getContracsAditives(this.props.first, this.props.first * (this.props.activePage - 1));
  }

  componentDidUpdate(prevProps) {
    const { activePage, first, search } = this.props;

    if (prevProps.activePage !== activePage || prevProps.first !== first) {
      this.getContracsAditives(first, first * (activePage - 1));
    }

    if (prevProps.search !== search) {
      const data = this.searchQuery(search, first, activePage);

      this.setState({ data: { contractAccountBalances: data } });
    }
  }

  searchQuery = (search, first, activePage) => {
    const { contractAccountBalances } = client.readQuery({ query: GET_CONTRACTS_ADITIVES, variables: { first, skip: first * (activePage -1) }});
    const query = search.toLowerCase();

    return contractAccountBalances.filter(item => {
      return search ?
        item.IDFolio.toLowerCase().includes(query) ||
        item.project.cli.toLowerCase().includes(query) ||
        item.project.name.toLowerCase().includes(query) ||
        format.date(item.startDate).toLowerCase().includes(query) ||
        format.date(item.finishDate).toLowerCase().includes(query) ||
        item.currency.toLowerCase().includes(query) ||
        item.amountContract.toString().includes(query) ||
        item.amountBilled.toString().includes(query) ||
        item.amountPaid.toString().includes(query) : item;
    });
  }

  getContracsAditives = async (first, skip = 0) => {
    try {
      const { loading, data } = await client.query({ query: GET_CONTRACTS_ADITIVES, variables: { first, skip }, fetchPolicy: "network-only" });
      const activePage = skip === 0 ? 1 : this.props.activePage;

      this.props.getCount(data.count.length, data.contractAccountBalances.length, activePage);
      this.setState({ loading, data });
    } catch (error) {
      this.setState({ error });
    }
  }

  handleConfirmModal = (item, isOriginalContract) => {
    this.setState({
      confirmOpen: !this.state.confirmOpen,
      accountContract: { ...item },
      isOriginalContract: isOriginalContract
    });
  }

  checkConsecAditiveDelete = (aditives, originalFinishDate, id) => {
    const indexOf = aditives.findIndex(element => element.id === id);

    if (indexOf !== 0 || aditives.length === 1) {
      const filter = aditives.filter(element => element.id !== id);
      if (filter.length > 0) {
        return filter[filter.length - 1].finishDate;
      } else {
        return originalFinishDate;
      }
    } else {
      alert('Las aditivas deben ser borradas en orden ascendentes');
      this.setState({ confirmOpen: !this.state.confirmOpen, accountContract: {} });
    }
  }

  deleteContract = async () => {
    const { isOriginalContract, accountContract: { aditives, originalFinishDate, id, idContractOriginal } } = this.state;
    let newFinishDate = null;

    if (isOriginalContract) {
      newFinishDate = originalFinishDate;
    } else {
      newFinishDate = this.checkConsecAditiveDelete(aditives, originalFinishDate, id);
    }

    try {
      await DELETE_CONTRACT_AB(isOriginalContract, id, idContractOriginal, newFinishDate);
      this.getContracsAditives(this.props.first);
      this.setState({ confirmOpen: !this.state.confirmOpen, accountContract: {} });
    } catch (error) {
      bugsnagClient.notify(error);
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleSort = (clickedColumn) => {
    const { direction, column, data: { contractAccountBalances } } = this.state;
    const sortBy = clickedColumn !== column ? "descending" : direction !== "ascending" ? "descending" : "ascending";
    const sorting = _.orderBy(contractAccountBalances, [clickedColumn], [sortBy.replace("ending", "")]);

    this.setState({
      column: clickedColumn,
      data: { contractAccountBalances: sorting },
      direction: clickedColumn !== column ? "ascending" : direction === "ascending" ? "descending" : "ascending"
    });
  }

  render () {
    const { loading, error, data, column, direction } = this.state;
    /*
      !data === undefined
      !data.contractAccountBalances.length === 0
    */

    if (loading || error || !data || !data.contractAccountBalances.length) {
      return <OnLoading loading={loading} error={error} />
    }
    return(
      <Container fluid className="Wrapper__Body-Container-Table">
        <Table basic="very" compact="very" textAlign="center" sortable className="Wrapper__Body-Table" singleLine={true}>
          <Table.Header className="Wrapper__Body-Table-Header">
            <Table.Row>
              <Table.HeaderCell/>
              <Table.HeaderCell>
                Tipo
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "IDFolio" ? direction : "descending"}
                onClick={() => this.handleSort("IDFolio")}>
                ID de contrato
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "project.name" ? direction : "descending"}
                onClick={() => this.handleSort("project.name")}>
                Nombre del proyecto
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "project.cli" ? direction : "descending"}
                onClick={() => this.handleSort("project.cli")}>
                Num de presupuesto CLI
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "startDate" ? direction : "descending"}
                onClick={() => this.handleSort("startDate")}>
                Fecha de inicio
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "finishDate" ? direction : "descending"}
                onClick={() => this.handleSort("finishDate")}>
                Fecha de término
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "currency" ? direction : "descending"}
                onClick={() => this.handleSort("currency")}>
                Moneda
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "amountContract" ? direction : "descending"}
                onClick={() => this.handleSort("amountContract")}>
                Monto
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "amountBilled" ? direction : "descending"}
                onClick={() => this.handleSort("amountBilled")}>
                Facturado
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "amountPaid" ? direction : "descending"}
                onClick={() => this.handleSort("amountPaid")}>
                Pagado
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "status" ? direction : "descending"}
                onClick={() => this.handleSort("status")}>
                Estado
              </Table.HeaderCell>
              <Table.HeaderCell className="Wrapper__Body-Table-Header-NoHover"/>
            </Table.Row>
          </Table.Header>
          <Accordion as={Table.Body} className="Wrapper__Body-Table-Body Small">
            {data.contractAccountBalances.map(original => (
              <React.Fragment key={original.id}>
                <RowsQueryAccountBalance
                  original={original}
                  aditive={null}
                  key={original.id}
                  activeIndex={this.state.activeIndex}
                  handleClick={this.handleClick}
                  handleConfirmModal={this.handleConfirmModal}
                />
                  {original.aditives.map(aditive => (
                    <RowsQueryAccountBalance
                      original={original}
                      aditive={aditive}
                      key={aditive.id}
                      activeIndex={this.state.activeIndex}
                      handleConfirmModal={this.handleConfirmModal}
                    />
                  ))}
              </React.Fragment>
            ))}
          </Accordion>
        </Table>
        <Confirm
          open={this.state.confirmOpen}
          cancelButton="Cancelar"
          confirmButton="Aceptar"
          onCancel={this.handleConfirmModal}
          onConfirm={() => this.deleteContract() }
          content={`¿Desea eliminar el registro? Esta acción no es reversible`}/>
      </Container>
    )
  }
}

export default AccountBalanceQuery;
