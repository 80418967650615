import React from "react";

export const ContractDocumentContext = React.createContext({
  currentItem: {},
  errors: {},
  documentStatus: [],
  contract: {},
});

export default ContractDocumentContext;
