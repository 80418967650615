export const openDocumentsContractor = ["ALTA_DEL_IMSS", "RESUMEN_SIROC_INICIAL", "INDENTIFICACION_PATRONAL"];

export const closeDocumentsContractor = ["BAJA_DEL_IMSS", "RESUMEN_SIROC_FINAL"];

export const noMonthlyDocumentsContractor = [
  ...openDocumentsContractor,
  ...closeDocumentsContractor
];

export const monthDocumentsContractor = [
  "RELACION_MENSUAL_DE_TRABAJADORES",
  "REPORTE_BIMESTRAL",
  "RESUMEN_SUA",
  "PAGO_SUA",
  "OPINION_CUMPLIMIENTO",
  "ARCHIVO_SUA",
  "NOMINAS",
  "ISR_SALARIOS",
  "ISR_DECLARACION",
  "ISR_RECIBO",
  "IVA_DECLARACION",
  "IVA_ACUSE",
  "IVA_PAGO",
  "ISR_SALARIOS_SUBSIDIO",
  "ICSOE",
  "SISUB",
  "SITUACION_FISCAL"
]

export const listMonthlyDocumentsContractor = [
  {
    name: "Cédula de determinación de cuotas",
    type: "RELACION_MENSUAL_DE_TRABAJADORES"
  },
  {
    name: "Reporte general de registro de obra",
    type: "RELACION_MENSUAL_DE_TRABAJADORES"
  },
  {
    name: "Resúmen de liquidación",
    type: "RELACION_MENSUAL_DE_TRABAJADORES"
  },
  {
    name: "Pago del banco",
    type: "REPORTE_BIMESTRAL"
  },
  {
    name: "Reporte bimestral",
    type: "RESUMEN_SUA"
  },
  {
    name: "Ema y Eba",
    type: "RESUMEN_SUA"
  },
  {
    name: "Opinión del cumplimiento SAT",
    type: "PAGO_SUA"
  },
  {
    name: "Opinión del cumplimiento IMSS",
    type: "PAGO_SUA"
  },
  {
    name: "SIPARE",
    type: "REPORTE_BIMESTRAL"
  },
  {
    name: "Opinión del cumplimiento del infonavit",
    type: "OPINION_CUMPLIMIENTO"
  },
  {
    name: "Archivo Sua",
    type: "ARCHIVO_SUA"
  },
  {
    name: "XML de nóminas",
    type: "NOMINAS"
  },
  {
    name: "Declaración ISR salarios",
    type: "ISR_SALARIOS"
  },
  {
    name: "Acuse de declaración ISR salarios",
    type: "ISR_DECLARACION"
  },
  {
    name: "Recibo pago ISR salarios",
    type: "ISR_RECIBO"
  },
  {
    name: "Declaración del IVA",
    type: "IVA_DECLARACION"
  },
  {
    name: "Acuse de la declaración del IVA",
    type: "IVA_ACUSE"
  },
  {
    name: "Recibo de pago IVA",
    type: "IVA_PAGO"
  },
  {
    name: "Papel de trabajo ISR Salarios y Subsidio",
    type: "ISR_SALARIOS_SUBSIDIO"
  },
  {
    name: "ICSOE",
    type: "ICSOE"
  },
  {
    name: "SISUB",
    type: "SISUB"
  },
  {
    name: "Constancia de situación fiscal",
    type: "SITUACION_FISCAL"
  }
]

export const limitDateDocuments = "2021-08-31"

/*
 Exclusive Contract for support new documents for contractor
 Only IDS in production

 1.-RESIDENCIA BAEZA => ORALIA GONZÁLEZ TORRES
 2.-EL ARBOL => GREGORIA GARCIA ROBLES
 3.-EVA II => ROGELIO TELLEZ
 4.-AMAZON TIJUANA (E4 ALAMAR) => SIRAA S.A. DE C.V.
 5.-EL ARBOL => AGUSTIN CARRILLO
 6.-AMBU => ORALIA GONZÁLEZ TORRES
 7.-INTERMEX BTS CJS-IS-11 => ORALIA GONZÁLEZ TORRES
 */

export const contractWithNewDocuments = [
  'ckmdqij322jzt0745tjb5i7dk',
  'cknt21ona43iu0745fqhyzx8n',
  'ckpo8ldzh6jok0745fuiin9nj',
  'ckrutlftd9yb607458vjbfx7e',
  'ckrmeb0a69n8607456wwo26zt',
  'cks8500oaamit0745tavfda28',
  'cks80vku7alcg07450f2l31rn'
]
