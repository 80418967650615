import { client } from "../../apollo-config";
const gql = require("graphql-tag");

const createPO = async ({
  folioReq,
  wasUpdatedAt,
  applicant,
  petitioner,
  status,
  revisedBy,
  authorizedBy,
  projectId,
  vendorId,
  deliveryWarehouse,
  deliveryDate,
  paymentConditions,
  currency,
  notes,
  subtotal,
  iva,
  total,
  retention,
  discount,
  retentionPercentage,
  discountPercentage,
  amount,
  supplies,
  elaboratedEvent,
}) => {
  const _supplies = supplies.map(supply => {
    supply["purchasePrice"] = parseFloat(supply["purchasePrice"]);
    supply["quantity"] = parseFloat(supply["quantity"]);
    const str = JSON.stringify(supply);
    var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
    return obj;
  });

  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        createPurchaseOrder(
          data: {
            folio: "null"
              data: {
                create: {
                  folio:"${folioReq}"
                  wasUpdatedAt:"${wasUpdatedAt}"
                  applicant:"${applicant}"
                  petitioner:"${petitioner}"
                  status:${status}
                  revisedBy:"${revisedBy}"
                  amount:${amount}
                  retentionPercentage:${
                    retentionPercentage ? retentionPercentage : 0.0
                  }
                  discountPercentage:${
                    discountPercentage ? discountPercentage : 0.0
                  }
                  elaboratedEvent:{
                    create:{
                      name:"${elaboratedEvent.name}"
                      date:"${elaboratedEvent.date}"
                      type:${elaboratedEvent.type}
                    }
                  }
                  retention:${retention ? retention : 0.0}
                  discount:${discount && !isNaN(discount) ? discount : 0.0}
                  authorizedBy:"${authorizedBy}"
                  project:{
                    connect:{
                        id:"${projectId}"
                    }
                  }
                  vendor:{
                    connect:{
                        id:"${vendorId}"
                    }
                  }
                  deliveryWarehouse:"${deliveryWarehouse}"
                  deliveryDate: "${deliveryDate}"
                  paymentConditions:${paymentConditions}
                  currency:${currency}
                  notes:"${notes}"
                  subtotal:${subtotal}
                  iva:${iva}
                  total:${total}
                  isReq:false
                  supplies:{
                    create:[${_supplies}]
                  }
                }
              }
          }
        ) {
          id
          data {
            amount
            discount
            discountPercentage
          }
        }
      }`,
  });
  return mutation;
};
export default createPO;
