import actions from "../../actions";

const initialState = {};

const requisitionsReducer = (state = initialState, action) => {
  if (action.type === actions.ADD_REQUISITION_SUPPLIES) {
    return {
      ...state,
      add_requisition_supplies: action.payload
    };
  }
  if (action.type === actions.ADD_REQUISITION_DATA) {
    return {
      ...state,
      add_requisition_data: action.payload
    };
  }
  if (action.type === actions.REQUISITIONS_LIST) {
    return {
      ...state,
      requisitions_list: action.payload
    };
  }
  if (action.type === actions.ADD_VENDOR_DATA) {
    return {
      ...state,
      add_vendor_data: action.payload
    };
  }
  if (action.type === actions.ADD_VENDOR_CONTACT) {
    return {
      ...state,
      add_vendor_contact: action.payload
    };
  }
  if (action.type === actions.CONTACTS_LIST) {
    return {
      ...state,
      contacts_list: action.payload
    };
  }
  return state;
};

export default requisitionsReducer;
