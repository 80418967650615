export const theme = {
  colors: {
    white: "#ffffff",
    dusty_red: "#b3363e",
    dusk_blue: "#295896",
    label_text: "#646464"
  },
  borders: {
    button: "5px",
    form_container: "10px"
  }
};

export const styles = {
  button_action: {
    backgroundColor: theme.colors.dusk_blue,
    color: theme.colors.white,
    borderRadius: theme.borders.button,
    fontSize: "12px",
    textAlign: "center"
  },
  button_cancel: {
    borderRadius: theme.borders.button,
    fontSize: "12px"
  },
  button_group: {
    fontSize: "10px",
    color: "#646464"
  },
  button_save: {
    backgroundColor: theme.colors.dusty_red,
    color: theme.colors.white,
    borderRadius: theme.borders.button,
    fontSize: "12px"
  },
  container: {
    margin: "10px auto",
    width: "100%",
    borderRadius: theme.borders.form_container
  },
  dashboardContainer: {
    margin: "0 auto",
    padding: "20px",
    width: "100%",
    backgroundColor: "#f2f2f2x"
  },
  form_group: {
    textAlign: "left",
    fontSize: "12px",
    color: theme.colors.label_text
  },
  logo: {
    margin: "10px",
    width: "100px",
    textAlign: "center"
  },
  modal_content: {
    margin: "0 auto",
    width: "60%"
  },
  modal_small: {
    margin: "0 auto",
    textAlign: "center",
    borderRadius: theme.borders.form_container
  },
  modal_button_holder: { margin: "0 auto", width: "100%", textAlign: "center" },
  table: {
    margin: "0 auto",
    width: "100%"
  },
  nav: {
    padding: "5px",
    boxShadow: "none",
    border: "none",
    borderBottom: "1px solid #dddddd",
    backgroundColor: "white"
  },
  sidebar: {
    margin: "0 auto",
    width: "6%",
    height: "100%",
    padding: "5px",
    backgroundColor: theme.colors.white,
    textAlign: "center",
    boxShadow: "none"
  },
  indicator: {
    width: "2.5px",
    height: "22px",
    objectFit: "contain",
    borderRadius: "10px",
    backgroundColor: "#b3363e",
    boxShadow: "1px 1.5px 5.5px 0 rgba(253, 30, 1, 0.74)",
    float: "left",
    marginLeft: "-5px",
    marginTop: "30px"
  },
  table_header: {
    margin: "0 auto",
    width: "100%",
    textAlign: "center"
  },
  table_header_text: {
    fontSize: "12px",
    color: theme.colors.label_text
  }
};
