import gql from "graphql-tag";

export const CREATE_CONTRACT = gql`
  mutation(
    $data: ContractCreateInput!
  ) {
    createContractByType(
      data: $data
    ) {
      id
    }
  }
`;
