import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Header,
  Input,
  Modal,
  Select,
  Segment,
  Table,
} from "semantic-ui-react";
import _ from "lodash";
import mixpanel from "../../../mixpanel";
import { client, MUTATIONS } from "../../../graphql/apollo-config";
import createClient from "../../../graphql/mutations/clients/createClient";
import updateClient from "../../../graphql/mutations/clients/updateClient";
import updateVendorContact from "../../../graphql/mutations/purchases/updateVendorContact";
import { IoMdCreate, IoIosTrash } from "react-icons/io";
import { FormHeader } from "../../Molecules";
import { validator, Stack } from "../../../helpers";
import { bugsnagClient } from "../../../bugsnag";
import joi from "joi";

export default class CreateClient extends React.Component {
  static propTypes = {
    update: PropTypes.bool,
    data: PropTypes.object,
    id: PropTypes.string,
    onCancel: PropTypes.func,
    isQuickCreate: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      contacts: [],
      contactTable: [],
      city: "",
      colony: "",
      commercialName: "",
      country: "",
      iva: "",
      number: "",
      personType: "",
      postalCode: "",
      razonSocial: "",
      rfc: "",
      state: "",
      street: "",
      name: "",
      email: "",
      phone: "",
      phone_office: "",
      modalOpen: false,
      modalUpdateOpen: false,
      cityError: false,
      colonyError: false,
      commercialNameError: false,
      countryError: false,
      ivaError: false,
      numberError: false,
      personTypeError: false,
      postalCodeError: false,
      razonSocialError: false,
      rfcError: false,
      stateError: false,
      streetError: false,
      nameError: false,
      emailError: false,
      phoneError: false,
      phone_officeError: false,
      formIsValid: false,
      errors: [],
      isForeign: true,
      isLoading: false,
      addedContacts: [],
      updatedContacts: [],
      deletedContacts: []
    };
  }

  _contacts = new Stack();

  onSave = () => {
    this.setState(data => ({ ...data, isLoading: true }));
    const _client = this.getVendorFields();
    _client["rfc"] = this.state.country === "MX" ? _client["rfc"] : "N/A";
    let _contacts = [];
    this.state.contactTable.map(contact => {
      _contacts.push({
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        phone_office: contact.phone_office,
      });
    });
    const validation = this.validateObjects(_client);
    _client.id = this.state.id;
    _client.client = client;
    _client["contacts"] = _contacts;
    if (validation.error === null) {
      createClient(_client)
        .then(res => {
          mixpanel.track("Cliente creado");
          this.props.toggleSave();
        })
        .catch(e => bugsnagClient.notify(e));
    } else {
      alert("Completa los campos faltantes");
      this.setState(data => ({ ...data, isLoading: false }));
    }
  };

  onUpdate = () => {
    this.setState(data => ({ ...data, isLoading: true }));
    const _client = this.getVendorFields();
    let _contacts = [];
    _client["rfc"] = this.state.country === "MX" ? _client["rfc"] : "N/A";
    this.state.contactTable.map(contact => {
      _contacts.push({
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        phone_office: contact.phone_office,
      });
    });
    const validation = this.validateObjects(_client);
    const c = _contacts.filter(model => {
      return !this.state.currentContacts.some(
        item => model.email === item.email
      );
    });

    _client.id = this.state.id;
    _client.client = client;
    _client.addedContacts = this.state.addedContacts;
    _client.updatedContacts = this.state.updatedContacts;
    _client.deletedContacts = this.state.deletedContacts;
    if (validation.error === null) {
      updateClient(_client)
        .then(res => {
          this.props.toggleSave();
        })
        .catch(e => {
          this.setState(data => ({ ...data, isLoading: false }));
        });
    } else {
      alert("Completa los campos faltantes");
      this.setState(data => ({ ...data, isLoading: false }));
    }
  };

  validateObjects = object => {
    const schema = {
      city: joi.string().required(),
      colony: joi.string().required(),
      commercialName: joi.string().required(),
      country: joi.string().required(),
      number: joi.string().required(),
      postalCode: joi.number().required(),
      razonSocial: joi.string().required(),
      rfc: joi.string().required(),
      state: joi.string().required(),
      street: joi.string().required(),
      contacts: joi
        .array()
        .min(1)
        .required(),
    };
    const { error, value } = joi.validate(object, schema);
    this.validateFields(value);
    return { value, error };
  };

  handleContactUpdate = () => {
    const currentContacts = this.state.currentContacts;
    const contacts = this.state.contacts;
    const exists = this.arraysEqual(currentContacts, contacts);
    return exists;
  };

  getContactFields = () => {
    const { name, email, phone, phone_office } = this.state;
    return { name, email, phone, phone_office };
  };

  arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (var i = arr1.length; i--; ) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  };

  getVendorFields = () => {
    const {
      city,
      colony,
      commercialName,
      country,
      number,
      postalCode,
      razonSocial,
      rfc,
      state,
      street,
      contactTable,
    } = this.state;
    let _contacts = [];
    contactTable.map(contact => {
      _contacts.push({
        email: contact.email,
        name: contact.email,
        phone: contact.phone,
        phone_office: contact.phone_office,
      });
    });
    return {
      city,
      colony,
      commercialName,
      country,
      number,
      postalCode,
      razonSocial,
      rfc,
      state,
      street,
      contacts: _contacts,
    };
  };

  validateFields = validationObject => {
    let errors = [];
    const validation = validator(validationObject);

    validation.map(field => {
      const key = field.key;
      const errorKey = `${key}Error`;
      if (!field.isValid) {
        errors.push(key);
      }
      this.setState(data => ({
        ...data,
        [errorKey]: !field.isValid,
      }));
    });
    return errors;
  };

  // Save contact to state
  onSaveContact = () => {
    const contactObject = this.getContactFields();
    const isValid = this.validateFields(contactObject);
    if (isValid.length === 0) {
      const exists = _.some(this.state.contacts, contactObject);
      if (!exists) {
        const contacts = [...this.state.contacts];
        contacts.push(contactObject);
        this.setState(data => ({
          ...data,
          contacts: contacts,
          contactTable: contacts,
          addedContacts: [...data.addedContacts, contactObject],
          modalOpen: false,
          name: "",
          email: "",
          phone: "",
          phone_office: "",
        }));
      } else {
        alert("El contacto ya existe");
      }
    }
  };

  // Update contact
  onUpdateContact = () => {
    const index = this.state.openContact;
    // Get stack
    const contactStack = [...this.state.contacts];
    const contactTable = [...this.state.contactTable];
    const newContactObject = this.getContactFields();
    const isValid = this.validateFields(newContactObject);

    if (isValid.length === 0) {
      contactStack[index] = newContactObject;
      contactTable[index] = newContactObject;

      const addedContacts = [...this.state.addedContacts];
      const addedContact = addedContacts.findIndex((contact) => contact.email === newContactObject.email);

      if (addedContact > -1) {
        addedContacts[addedContact] = newContactObject;
        return this.setState((state) => ({
          ...state,
          contacts: contactStack,
          modalUpdateOpen: false,
          name: "",
          email: "",
          currentEmail: "",
          phone: "",
          phone_office: "",
          addedContacts,
          contactTable
        }));
      }

      const updateObject = {
        where: { email: this.state.contacts[index].email },
        data: newContactObject
      };

      return this.setState((state) => ({
        ...state,
        contacts: contactStack,
        modalUpdateOpen: false,
        name: "",
        email: "",
        currentEmail: "",
        phone: "",
        phone_office: "",
        updatedContacts: [...state.updatedContacts, updateObject],
        contactTable
      }));
    }
    return;
  };

  // Remove contact from table
  deleteContact = index => {
    const contactStack = [...this.state.contacts];
    const contactTable = [...this.state.contactTable];
    const newContactStack = contactStack.filter((contact, idx) => idx !== index);
    const newContactTable = contactTable.filter((contact, idx) => idx !== index);
    const deletedContact = contactStack.find((contact, idx) => idx === index);

    const addedContacts = [...this.state.addedContacts];
    const addedContact = addedContacts.findIndex((contact) => contact.email === deletedContact.email);

    if (addedContact > -1) {
      const newAddedContacts = addedContacts.filter((contact, idx) => idx !== addedContact);
      return this.setState((state) => ({
        ...state,
        contacts: newContactStack,
        contactTable: newContactTable,
        modalUpdateOpen: false,
        name: "",
        email: "",
        currentEmail: "",
        phone: "",
        phone_office: "",
        addedContacts: newAddedContacts
      }));
    }

    return this.setState((state) => ({
      ...state,
      contacts: newContactStack,
      contactTable: newContactTable,
      deletedContacts: [...state.deletedContacts, deletedContact]
    }));
  };

  cleaningData = data => {
    //Replacing JSON forbidden characters
    let str = data.replace(/([$#"^{}¨!'?])/g, "");
    //Replacing not printable characters
    str = str.replace(/([\n\r\t?])/g, "");
    //Scaping special characters
    str = str.replace(/([<>"()?])/g, "\\$1");
    return str;
  };

  // inputs onChange
  handleChange = (e, { name, value }) => {
    this.setState(data => ({
      ...data,
      [name]: value,
    }));
  };

  handleChangeCountry = (e, { name, value }) => {
    if (value === "MX") {
      this.setState(data => ({
        ...data,
        [name]: value,
        isForeign: false,
        personTypeOptions: [
          { key: "moral", value: "MORAL", text: "Moral" },
          { key: "fisica", value: "FISICA", text: "Física" },
        ],
        personType: "FISICA",
        iva: 0.16,
        rfc: "",
      }));
    } else {
      this.setState(data => ({
        ...data,
        [name]: value,
        isForeign: true,
        personTypeOptions: [
          { key: "extranjero", value: "EXTRANJERO", text: "Extranjero" },
        ],
        personType: "EXTRANJERO",
        rfc: "",
        iva: "N/A",
      }));
    }
  };

  renderIVA = () => {
    const { hasIVA } = this.state;
    const input = hasIVA ? (
      <Input
        id="add-_client-iva"
        name="iva"
        type="text"
        onChange={this.handleChange}
        size="mini"
        style={{ width: "150px" }}
      />
    ) : null;

    return (
      <Form.Group widths="equal">
        <Checkbox
          id="add-_client-has-iva"
          onChange={() => this.setState({ hasIVA: !hasIVA })}
          defaultChecked={hasIVA}
        />
        &nbsp;¿CARGAR IVA?&nbsp;
        {input}
      </Form.Group>
    );
  };

  // open add contact modal
  handleOpen = () => this.setState({ modalOpen: true });

  // open update contact modal
  handleOpenUpdate = index => {
    const { name, email, phone, phone_office } = this.state.contactTable[index];
    this.setState({
      openContact: index,
      modalUpdateOpen: true,
      name,
      email,
      currentEmail: email,
      phone,
      phone_office,
    });
    this._contacts.splice(index);
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      modalUpdateOpen: false,
      name: "",
      email: "",
      phone: "",
      phone_office: "",
    });
  };

  modalForm = () => {
    return (
      <Form className="Modal__Form-SubModal-Form">
        <Form.Field
          label="NOMBRE Y APELLIDO"
          name="name"
          control={Input}
          onChange={this.handleChange}
          value={this.state.name}
          error={this.state.nameError}
        />
        <Form.Field
          label="CORREO ELECTRÓNICO"
          name="email"
          control={Input}
          value={this.state.email}
          onChange={this.handleChange}
          error={this.state.emailError}
        />
        <Form.Field
          label="TELÉFONO"
          name="phone"
          control={Input}
          value={this.state.phone}
          onChange={this.handleChange}
          error={this.state.phoneError}
        />
        <Form.Field
          label="TELÉFONO DE OFICINA"
          name="phone_office"
          control={Input}
          value={this.state.phone_office}
          onChange={this.handleChange}
          error={this.state.phone_officeError}
        />
      </Form>
    );
  };

  componentWillMount = () => {
    if (this.props.update) {
      const {
        id,
        city,
        colony,
        commercialName,
        country,
        iva,
        number,
        personType,
        postalCode,
        razonSocial,
        rfc,
        state,
        street,
      } = this.props.data;
      const contacts = this.props.data.contacts;
      this.setState(data => ({
        ...data,
        id,
        city,
        colony,
        commercialName,
        country,
        iva,
        number,
        personType,
        postalCode,
        razonSocial,
        rfc,
        state,
        street,
        contacts,
        currentContacts: contacts,
        contactTable: contacts,
        personTypeOptions:
          personType === "EXTRANJERO"
            ? [{ key: "extranjero", value: "EXTRANJERO", text: "Extranjero" }]
            : [
                { key: "fisica", value: "FISICA", text: "Fisica" },
                { key: "moral", value: "MORAL", text: "Moral" },
              ],
      }));
    }
  };
  close = () => {
    if (this.state.contacts.length > 0) {
      this.props.onCancel();
    } else {
      alert(
        "Haz eleminado los contactos de este cliente, añade un nuevo contacto"
      );
    }
  };
  render() {
    const ModalForm = this.modalForm;
    return (
      <Segment raised className="Modal">
        {!this.state.isLoading ? (
          <FormHeader
            id={this.props.update}
            saveId="save-_client-btn"
            cancelId="cancel-_client-btn"
            text={this.props.update ? "Editar cliente" : "Nuevo cliente"}
            onSave={this.props.update ? this.onUpdate : this.onSave}
            onCancel={this.props.update ? this.close : this.props.onCancel}
          />
        ) : (
          <FormHeader
            id={this.props.update}
            saveId="save-_client-btn"
            cancelId="cancel-_client-btn"
            text={this.props.update ? "Editar cliente" : "Nuevo cliente"}
            onSave={null}
            onCancel={this.props.update ? this.close : this.props.onCancel}
          />
        )}
        <Form className="Modal__Form">
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="NOMBRE COMERCIAL"
              name="commercialName"
              value={this.state.commercialName}
              onChange={this.handleChange}
              error={this.state.commercialNameError}
              autoComplete="off"
            />
            <Form.Field
              control={Input}
              label="CÓDIGO POSTAL"
              type="number"
              min="0"
              name="postalCode"
              value={this.state.postalCode}
              onChange={this.handleChange}
              error={this.state.postalCodeError}
            />
            <Form.Field
              control={Select}
              label="PAÍS"
              name="country"
              options={[
                { text: "Brasil", value: "BR" },
                { text: "Estados Unidos", value: "EU" },
                { text: "México", value: "MX" },
              ]}
              defaultChecked={this.state.country}
              value={this.state.country}
              onChange={this.handleChangeCountry}
              error={this.state.countryError}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              label="DIRECCIÓN"
              name="street"
              control={Input}
              placeholder="Calle"
              value={this.state.street}
              onChange={this.handleChange}
              error={this.state.streetError}
              width={6}
              autoComplete="off"
            />
            <Form.Field
              label="&nbsp;"
              name="number"
              control={Input}
              placeholder="Número"
              value={this.state.number}
              onChange={this.handleChange}
              error={this.state.numberError}
              width={6}
              autoComplete="off"
            />
            <Form.Field
              control={Input}
              label="CIUDAD"
              name="city"
              value={this.state.city}
              onChange={this.handleChange}
              error={this.state.cityError}
              width={12}
              autoComplete="off"
            />
            <Form.Field
              control={Input}
              label="RFC"
              name="rfc"
              value={this.state.rfc}
              onChange={this.handleChange}
              error={this.state.isForeign ? false : this.state.rfcError}
              readOnly={this.state.country === "MX" ? false : true}
              style={this.state.country === "MX" ? null : { opacity: "0.3" }}
              width={12}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="COLONIA"
              name="colony"
              value={this.state.colony}
              onChange={this.handleChange}
              error={this.state.colonyError}
              autoComplete="off"
            />
            <Form.Field
              control={Input}
              label="ESTADO"
              name="state"
              value={this.state.state}
              onChange={this.handleChange}
              error={this.state.stateError}
              autoComplete="off"
            />
            <Form.Field
              control={Input}
              label="RAZÓN SOCIAL"
              name="razonSocial"
              value={this.state.razonSocial}
              onChange={this.handleChange}
              error={this.state.razonSocialError}
              autoComplete="off"
            />
          </Form.Group>
        </Form>
        <Header as="h3" dividing className="Modal__Form-Header">
          Contactos
        </Header>
        <Table
          basic="very"
          compact="very"
          textAlign="center"
          className="Modal__Form-Table"
        >
          <Table.Header className="Modal__Form-Table-Header">
            <Table.Row>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Correo electrónico</Table.HeaderCell>
              <Table.HeaderCell>Teléfono</Table.HeaderCell>
              <Table.HeaderCell>Teléfono de oficina</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body className="Modal__Form-Table-Body">
            {this.state.contactTable.map((contact, index) => {
              const { name, email, phone, phone_office, id } = contact;
              return (
                <Table.Row key={name}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>{phone}</Table.Cell>
                  <Table.Cell>{phone_office}</Table.Cell>
                  <Table.Cell>
                    <Button.Group basic>
                      <Button icon onClick={() => this.handleOpenUpdate(index)}>
                        <IoMdCreate />
                      </Button>
                      <Button icon onClick={() => this.deleteContact(index)}>
                        <IoIosTrash />
                      </Button>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Container textAlign="center">
          <Modal
            className="Modal__Form-SubModal"
            centered={false}
            trigger={
              <Button
                onClick={this.handleOpen}
                className="Modal__Form-ButtonAdd"
                icon="plus"
                content="Añadir Contacto"
                size="large"
              />
            }
            open={this.state.modalOpen}
            header={
              <FormHeader
                saveId="save-contact-btn"
                cancelId="cancel-contact-btn"
                text="Contacto de cliente"
                onCancel={this.handleCloseModal}
                onSave={this.onSaveContact}
              />
            }
            content={<ModalForm />}
            size="tiny"
            dimmer="blurring"
          />
          <Modal
            className="Modal__Form-SubModal"
            centered={false}
            open={this.state.modalUpdateOpen}
            onClose={this.handleCloseModal}
            header={
              <FormHeader
                saveId="update-contact-btn"
                cancelId="cancel-update-contact-btn"
                text="Contacto de cliente"
                onCancel={this.handleCloseModal}
                onSave={this.onUpdateContact}
              />
            }
            content={<ModalForm />}
            size="tiny"
            dimmer="blurring"
          />
        </Container>
      </Segment>
    );
  }
}
