import React from "react";
import { Button, Segment, Grid, Form, Label, Dropdown, Pagination } from "semantic-ui-react";
import PurchaseOrdersQuery from "../../Molecules/purchaseOrdersQuery";
import updatePOStatus from "../../../graphql/mutations/purchases/updatepo_status";
import { Can } from "../../hoc/permissions";
import theme from "../../_theme";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from "moment";

const statusOptions = [
  { key: 0, text: "Todos", value: undefined },
  { key: 1, text: "Revisión", value: "IS_PO_REVIEW" },
  { key: 2, text: "Revisada", value: "IS_PO_REVISED" },
  { key: 3, text: "Aprobada", value: "IS_PO_APPROVED" },
  { key: 4, text: "Cancelada", value: "IS_PO_CANCEL" },
]

export default class PurchaseOrdersList extends React.Component {
  state = {
    search: {
      searchBox: undefined,
      status: undefined,
      date: undefined
    },
    totalRecords: 0,
    activePage: 1,
    first: 10,
    entrieSelected: 10,
    entriesOptions: [
      { key: 1, text: "10", value: 10 },
      { key: 2, text: "20", value: 20 },
    ]
  };

  onChangeSelect = (e, { value, data }) => {
    const id = data.id;
    const status = value;
    if (this.checkRequisitionsRoute()) {
      const confirmation = window.confirm(
        `¿Deseas cambiar el status la requisición a ${
        e.currentTarget.innerText
        }?`
      );
      if (confirmation) {
        updatePOStatus(this.isReq(status).status, id, this.isReq(status).isReq)
          .then(res => {
            alert("Estado actualizado exitosamente");
          })
          .catch(e => console.log(e));
      }
    } else {
      return;
    }
  };

  isReq = status => {
    if (status === "IS_REQ_APPROVED") {
      return { isReq: true, status: "IS_REQ_APPROVED" };
    } else {
      return { isReq: false, status: status };
    }
  };

  checkRequisitionsRoute = () => {
    const route = this.props.activeRoute === "pos" ? true : false;
    return route;
  };

  getCount = (totalRecords, first, activePage) => {
    this.setState({ totalRecords, first, activePage });
  }

  onPageChange = (e, { activePage }) => this.setState({ activePage });

  handleEntries = (e, { value }) => this.setState({ entrieSelected: value, activePage: 1 });

  render() {
    const { search, activePage, first, totalRecords, entriesOptions, entrieSelected } = this.state;
    const { activeRoute, toggleCreatePO, toggleDetailPO, toggleEditPO, setPORefetch } = this.props;

    return (
      <Segment>
        <Grid>
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column width={13}>
              <Form className="Wrapper__Body-Form">
                <Form.Group>
                  <Form.Input
                    inline
                    placeholder="Buscar"
                    icon="search"
                    onChange={(e, { value }) => this.setState(prevState => ({ search: { ...prevState.search, searchBox: value }, activePage: 1 }))}
                    value={search.searchBox}
                  />
                  <Form.Select
                    placeholder="Estatus"
                    name="status"
                    options={statusOptions}
                    value={search.status}
                    onChange={(e, { value }) => this.setState(prevState => ({ search: { ...prevState.search, status: value }, activePage: 1 }))}
                  />
                  <Form.Field className='Util-DatePicker'>
                    <SemanticDatepicker
                      name="date"
                      placeholder="Fecha"
                      type="basic"
                      format="DD/MM/YYYY"
                      onDateChange={(date) => this.setState(prevState => ({ search: { ...prevState.search, date: (date !== null && moment(date).format('YYYY-MM-DD')) }, activePage: 1 }))}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right" width={3}>
              <Can do="create" on="PurchaseOrder">
                <Button
                  basic
                  icon="plus"
                  id="add-po"
                  content="Orden de compra"
                  className="Wrapper__Body-Form-ButtonAdd"
                  onClick={toggleCreatePO}
                />
              </Can>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <PurchaseOrdersQuery
              activeRoute={activeRoute}
              onChange={this.onChangeSelect}
              toggleCreatePO={toggleCreatePO}
              toggleDetailPO={toggleDetailPO}
              toggleEditPO={toggleEditPO}
              search={search}
              getCount={this.getCount}
              first={entrieSelected}
              activePage={activePage}
              setPORefetch={setPORefetch}
            />
          </Grid.Row>
          <Grid.Row centered>
            <Grid columns={3}>
              <Grid.Row centered>
                <Grid.Column textAlign="center">
                  <Label style={theme.styles.paginationLabel}>Ver</Label>
                  <Dropdown
                    id="recordsDropdown"
                    name="recordsDropdown"
                    selection
                    text={`${entrieSelected}`}
                    options={entriesOptions}
                    item
                    onChange={this.handleEntries}
                    compact />
                  <Label style={theme.styles.paginationLabel}>Entradas</Label>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Label style={theme.styles.entriesLabel}>Viendo</Label>
                  <Label style={theme.styles.entriesBoldLabel}>{first}</Label>
                  <Label style={theme.styles.entriesLabel}>de</Label>
                  <Label style={theme.styles.entriesBoldLabel}>{totalRecords}</Label>
                  <Label style={theme.styles.entriesLabel}>Entradas</Label>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  {totalRecords !== 0 &&
                    <Pagination
                      className='Pagination-Fix'
                      boundaryRange={1}
                      siblingRange={0}
                      size='mini'
                      activePage={activePage}
                      totalPages={Math.ceil(totalRecords / entrieSelected)}
                      onPageChange={this.onPageChange}
                    />
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
