import gql from "graphql-tag";
import { client } from "../../apollo-config";

const DELETE_ORIGINAL_CONTRACT = async ({
  idContract,
  idOriginalContract,
  invoiced,
  paided,
  amountDeductives
}, { GET_ORIGINAL_CONTRACT, QUERY_CONTRACTS }) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContract(
        data: {
          invoiced: ${invoiced},
          paided: ${paided},
          amountDeductives: ${amountDeductives}
          documentsContract: {
            delete: {
              id: "${idOriginalContract}"
            }
          }
        },
        where: { id: "${idContract}" }
        ) {
          id
          invoiced
          paided
          amountDeductives
        }
      }
    `,
    update: (proxy, { data: { updateContract } }) => {
      const query = proxy.readQuery({ query: GET_ORIGINAL_CONTRACT, variables: { id: idContract }});
      const documentsContract = query.contract.documentsContract.filter(doc => doc.id !== idOriginalContract);

      const data = {
        contract: { ...query.contract,
          documentsContract
        }
      }

      proxy.writeQuery({
        query: GET_ORIGINAL_CONTRACT,
        variables: { id: idContract },
        data
      })

      const { first, skip } = JSON.parse(window.localStorage.getItem("pagination"));
      const result = proxy.readQuery({ query: QUERY_CONTRACTS, variables: { first, skip, id: null } });
      const { invoiced, paided, amountDeductives } = updateContract;

      result.contracts.map(contract => {
        if (contract.id === idContract) {
          contract.paided = paided;
          contract.invoiced = invoiced;
          contract.amountDeductives = amountDeductives;
        }
        return contract
      })

      proxy.writeQuery({
        query: QUERY_CONTRACTS,
        data: result
      })
    }
  });
  return mutation;
};

export default DELETE_ORIGINAL_CONTRACT;
