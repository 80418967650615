import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { format } from "../../../helpers";
const user = JSON.parse(window.localStorage.getItem("session")) ? JSON.parse(window.localStorage.getItem("session")).user : {}
const idContractor = user.userRol === "CONTRACTOR" ? user.id : null;

const CREATE_ORIGINAL_CONTRACT = async ({
  id,
  idContract,
  type,
  concept,
  dateElaborated,
  status,
  elaborated,
  authorizer,
  iva,
  amount,
  amountIva,
  total,
  amountDeductives,
  totalContrated,
  totalInvoiced,
  totalPaided,
  refUpload
}, { QUERY_CONTRACTS, GET_ORIGINAL_CONTRACT }) => {
  const mutation = await client.mutate({
    mutation: gql `
      mutation {
        updateContract(
          data: {
            total: ${parseFloat(totalContrated)}
            amountDeductives: ${parseFloat(amountDeductives)}
            invoiced: ${parseFloat(totalInvoiced)}
            paided: ${parseFloat(totalPaided)}
            documentsContract: {
              upsert: {
                update: {
                  type: ${type}
                  concept: "${concept}"
                  dateElaborated: "${format.date(dateElaborated, 2)}"
                  elaborated: "${elaborated}"
                  status: ${status}
                  authorizer: "${authorizer}"
                  dateAuthorizer: "${format.date(new Date(), 2)}"
                  iva: ${parseFloat(iva)}
                  amount: ${parseFloat(amount)}
                  amountIva: ${parseFloat(amountIva)}
                  total: ${parseFloat(total)}
                  refUpload: {
                    create: [${refUpload.map(doc => {
                      return `${doc.hasOwnProperty("new") ? `{
                          type: ${doc.type}
                          reference: "${doc.reference}"
                          extension: "${doc.extension}"
                          createDate: "${format.date(new Date(), 2)}"
                          wildcard: "${doc.wildcard}"
                      }` : []}`
                    })}]
                  }
                }
                create: {
                  type: ${type}
                  concept: "${concept}"
                  dateElaborated: "${format.date(new Date(), 2)}"
                  elaborated: "${elaborated}"
                  status: ${status}
                  authorizer: "${authorizer}"
                  dateAuthorizer: "${format.date(new Date(), 2)}"
                  iva: ${parseFloat(iva)}
                  amount: ${parseFloat(amount)}
                  amountIva: ${parseFloat(amountIva)}
                  total: ${parseFloat(total)}
                  refUpload: {
                    create: [${refUpload.map(doc => `{
                      type: ${doc.type}
                      reference: "${doc.reference}"
                      extension: "${doc.extension}"
                      createDate: "${format.date(new Date(), 2)}"
                      wildcard: "${doc.wildcard}"
                    }`)}]
                  }
                }
                where: { id: "${id}" }
              }
            }
          }
          where: { id: "${idContract}" }
        ) {
          id
          paided
          invoiced
          amountDeductives
        }
      }
    `,
    refetchQueries : [{ query: GET_ORIGINAL_CONTRACT, variables: { id: idContract } }],
    update: (proxy, { data: { updateContract } }) => {
      const { first, skip } = JSON.parse(window.localStorage.getItem("pagination"));
      const query = proxy.readQuery({ query: QUERY_CONTRACTS, variables: { first, skip, id: idContractor } });
      const { invoiced, paided, amountDeductives } = updateContract;
      
      query.contracts.map(contract => {
        if (contract.id === idContract) {
          contract.paided = paided;
          contract.invoiced = invoiced;
          contract.amountDeductives = amountDeductives;
        }
        return contract
      })

      proxy.writeQuery({
        query: QUERY_CONTRACTS,
        data: query
      })
    }
  });
  return mutation;
}

export default CREATE_ORIGINAL_CONTRACT;
