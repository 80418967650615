import gql from "graphql-tag";

const requisitions = gql`
  query getRequisitions(
    $first: Int,
    $skip: Int,
    $searchBox: String,
    $date: DateTime,
    $date_lt: DateTime
    $status: Status) {
    requisitions(
      first: $first
      skip: $skip
      orderBy: wasUpdatedAt_DESC
      where: {
        isReq: true
        createdAt_gt: $date
        createdAt_lt: $date_lt
        status: $status
        OR: [
          { status_not: IS_PO_REVIEW }
          { status_not: IS_PO_APPROVED }
          { status_not: IS_PO_CANCEL }
        ]
        AND: {
          OR: [
            {
              project: {
                OR: [{ name_contains: $searchBox }, { cli_contains: $searchBox }]
              }
            }
            { vendor: { commercialName_contains: $searchBox } }
            { deliveryDate_contains: $searchBox }
            { deliveryWarehouse_contains: $searchBox }
          ]
        }
      }
    ) {
      id
      folio
      createdAt
      wasUpdatedAt
      applicant
      petitioner
      status
      revisedBy
      authorizedBy
      amount
      discountPercentage
      retentionPercentage
      project {
        id
        cli
        name
        address
        status  
        responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
          id,
          name
        }
        residents: users(where: { userRol_in: [TECHNICIAN] }){
          id,
          name
        }
      }
      count
      vendor {
        id
        commercialName
        contacts {
          name
        }
      }
      deliveryWarehouse
      deliveryDate
      notes
      currency
      paymentConditions
      subtotal
      iva
      total
      retention
      discount
      supplies {
        id
        name
        insID
        description
        units
        quantity
        purchasePrice
      }
      isReq
    }
    count: requisitions(
      where: {
        isReq: true
        createdAt_gt: $date
        createdAt_lt: $date_lt
        status: $status
        OR: [
          { status_not: IS_PO_REVIEW }
          { status_not: IS_PO_APPROVED }
          { status_not: IS_PO_CANCEL }
        ]
        AND: {
          OR: [
            {
              project: {
                OR: [{ name_contains: $searchBox }, { cli_contains: $searchBox }]
              }
            }
            { vendor: { commercialName_contains: $searchBox } }
            { deliveryDate_contains: $searchBox }
            { deliveryWarehouse_contains: $searchBox }
          ]
        }
      }
    ) {
      id
    }
  }
`;
export default requisitions;
