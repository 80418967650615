import React from "react";
import { Header, Grid } from "semantic-ui-react";
import ActionButtons from "../../formActionButtons";
import PoNavigation from "./PoNavigation";

export default class FormHeader extends React.Component {
  render() {
    return (
      <Header
        as="h3"
        dividing
        className="Modal__Header"
        style={{ paddingBottom: 20 }}
      >
        <Grid columns="equal">
          <Grid.Column width={4}>{this.props.text}</Grid.Column>
          <Grid.Column width={8}>
            <PoNavigation
              routes={this.props.routes}
              activeRoute={this.props.activeRoute.routeName}
              navigateTo={this.props.navigateTo}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <ActionButtons
              saveId={this.props.saveId}
              cancelId={this.props.cancelId}
              onSave={this.props.onSave}
              onCancel={this.props.onCancel}
              submitting={this.props.submitting}
            />
          </Grid.Column>
        </Grid>
      </Header>
    );
  }
}
