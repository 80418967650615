import React, { Component } from "react";
//import src from "../../../assets/logo.png";
import src from "../../../assets/img/logo.png";
import src1 from "../../../assets/img/group-30@3x.png";
import "./style.css";

export default class Logo extends Component {
  render() {
    return <img src={src} srcSet={src1} className="logo" />;
  }
}
