import React from "react";
import {
  Grid,
  Modal,
  GridRow,
  GridColumn,
  Header,
  Form,
  FormGroup,
  FormField,
  Input,
  TextArea,
  Image,
  Button,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import fetch from "node-fetch";
import FormHeader from "../../Molecules/formHeader";
import DynamicHolder from "../../Atoms/DynamicLoader";
import { Container } from "..";
import moment from "moment";

export default props => {
  const key = () => <p>{props.data !== null ? props.data.insID : ""}</p>;

  const name = () => <p>{props.data !== null ? props.data.name : ""}</p>;

  const units = () => <p>{props.data !== null ? props.data.units : ""}</p>;

  const description = () => (
    <p>{props.data !== null ? unescape(props.data.description) : ""}</p>
  );

  const quantity = () => (
    <p>{props.data !== null ? props.data.quantity : ""}</p>
  );

  const uploadFile = async e => {
    const files = e.target.files;
    const form = new FormData();
    if (files.length > 0) {
      form.append("file", files[0]);
      fetch(process.env.REACT_APP_S3, {
        method: "POST",
        body: form,
      }).then(response => {
        if (response.ok) {
          response.json().then(json => {
            props.downloadImage(json.imageUrl);
          });
        }
      });
    }
  };

  const save = () => {
    props.save();
  };

  const disabledDates = props.userRol === "TECHNICIAN";

  return (
    <Modal
      className="Modal__Form-SubModal"
      open={props.open}
      style={{ minHeight: "500px", width: "90%", padding: "15px" }}
      centered={false}
    >
      <FormHeader
        text="Recepción del insumo"
        onSave={save}
        onCancel={props.onClose}
      />
      {/*Main grid*/}
      <Container>
        <Grid>
          <GridRow divided>
            <GridColumn width={15}>
              <Form className="Modal__Form">
                <FormGroup widths="equal">
                  <FormField label="Clave" control={key} />
                  <FormField label="Nombre" control={name} />
                  <FormField label="Unidad de medida" control={units} />
                  <FormField
                    id="received"
                    name="received"
                    label="Total recibido"
                    control={Input}
                    min={0}
                    max={props.data !== null ? props.data.received : ""}
                    defaultValue={
                      props.data !== null ? props.data.received : ""
                    }
                    onChange={props.handleChange}
                    type="number"
                  />
                </FormGroup>
                <FormGroup widths="equal">
                  <FormField label="Cantidad del pedido" control={quantity} />
                  <FormField />
                  <FormField />
                </FormGroup>
                <FormGroup widths="equal">
                  <FormField label="Descripción" control={description} />
                </FormGroup>
              </Form>
            </GridColumn>
          </GridRow>
        </Grid>
        <Header dividing>Datos de recepción de almacenamiento</Header>
        <Grid>
          <GridRow columns={2} divided>
            <GridColumn width={11}>
              <Form className="Modal__Form">
                <Form.Group widths="equal">
                  <Form.Field
                    label="Número de guía"
                    name="trackingNumber"
                    value={props.data.trackingNumber}
                    control={Input}
                    onChange={props.handleChange}
                  />
                  <Form.Field
                    label="Empresa de paquetería"
                    name="shippingCompany"
                    value={props.data.shippingCompany}
                    control={Input}
                    onChange={props.handleChange}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    label="Fecha de envío esperada"
                    name="shippingDate"
                    className="Util-DatePicker"
                    control={SemanticDatepicker}
                    type="basic"
                    format="DD/MM/YYYY"
                    placeholder={
                      props.data === null || !props.data.shippingDate
                        ? "dd/mm/aaaa"
                        : moment(props.data.shippingDate)
                          .format("DD/MM/YYYY")
                    }
                    onDateChange={date =>
                      props.handleChange(null, {
                        name: "shippingDate",
                        value: date !== null ? moment(date) : "",
                      })
                    }
                    disabled={disabledDates}
                  />
                  <Form.Field
                    label="Fecha de entrega esperada"
                    name="deliveryDate"
                    className="Util-DatePicker"
                    control={SemanticDatepicker}
                    type="basic"
                    format="DD/MM/YYYY"
                    placeholder={
                      props.data === null || !props.data.deliveryDate
                        ? "dd/mm/aaaa"
                        : moment(props.data.deliveryDate)
                          .format("DD/MM/YYYY")
                    }
                    onDateChange={date =>
                      props.handleChange(null, {
                        name: "deliveryDate",
                        value: date !== null ? moment(date) : "",
                      })
                    }
                    disabled={disabledDates}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <FormField
                    label="ALMACÉN DE RECEPCIÓN"
                    value={props.warehouseName}
                    control={Input}
                  />
                  <FormField
                    className="Util-DatePicker"
                    control={SemanticDatepicker}
                    type="basic"
                    format="DD/MM/YYYY"
                    label="FECHA DE RECEPCIÓN"
                    name="receptionDate"
                    placeholder={
                      props.data === null || !props.data.receptionDate
                        ? "dd/mm/aaaa"
                        : moment(props.data.receptionDate)
                          .add(1, "day")
                          .format("DD/MM/YYYY")
                    }
                    onDateChange={date =>
                      props.handleChange(null, {
                        name: "receptionDate",
                        value:
                          date !== null
                            ? moment(date).format("YYYY-MM-DD")
                            : "",
                      })
                    }
                    readOnly={true}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <FormField
                    name="receivedBy"
                    onChange={props.handleChange}
                    defaultValue={
                      props.data !== null ? props.data.receivedBy : ""
                    }
                    label="RECIBIDO POR"
                    control={Input}
                    type="text"
                  />
                  <Form.Field />
                </Form.Group>
                <Form.Group widths="equal">
                  <FormField
                    label="NOTA"
                    name="notes"
                    control={TextArea}
                    defaultValue={
                      props.data !== null ? unescape(props.data.notes) : ""
                    }
                    onChange={props.handleChange}
                    style={{ height: "150px" }}
                  />
                </Form.Group>
              </Form>
            </GridColumn>
            <GridColumn width={4}>
              <Form>
                <FormGroup style={{ textAlign: "center" }} widths="equal">
                  <FormField
                    label="FOTO DE INSUMO"
                    control={
                      props.data !== null
                        ? () => (
                          <DynamicHolder
                            uploadFile={uploadFile}
                            image={props.img}
                            imageID={props.data.photoID}
                            imgShown={props.imgShown}
                            open={props.openImageModal}
                          />
                        )
                        : null
                    }
                  />
                </FormGroup>
              </Form>
            </GridColumn>
          </GridRow>
        </Grid>
        <Modal open={props.imageModal} size="large">
          <Image src={props.img} width={350} alt="" centered />
          <Modal.Actions>
            <Button onClick={props.openImageModal}>Cerrar</Button>
          </Modal.Actions>
        </Modal>
      </Container>
    </Modal>
  );
};
