import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import gql from "graphql-tag";

const getSupply = gql`
  query supplies($first: Int, $skip: Int) {
    supplies(first: $first, skip: $skip) {
      id
      insID
      name
      description
      units
      purchasePrice
      count
    }
  }
`;

export default props => (
  <Query query={getSupply} fetchPolicy={"network-only"}>
    {({ loading, error, data }) => {
      let options = [];
      if (loading) {
        return <p testid="loading-wrapper">Cargando...</p>;
      }
      if (error) {
        return <p>error</p>;
      }

      data.supplies.map(
        ({ id, description, name, insID, units, purchasePrice }) => {
          options.push({
            key: id,
            text: name,
            value: JSON.stringify({
              id,
              description,
              insID,
              name,
              units,
              purchasePrice,
            }),
            style: { textAlign: "center", color: "#999999" },
          });
        }
      );

      return (
        <Dropdown
          fluid
          name={props.name}
          icon="search"
          search
          selection
          onChange={props.supplyKeyChange}
          options={[
            {
              content: (
                <p
                  style={{
                    color: "#295896",
                    borderTop: "1px solid #dddddd",
                    textAlign: "center",
                  }}
                >
                  + Añadir nuevo insumo
                </p>
              ),
              key: "add",
              value: "add-new",
              onClick: props.supplyQuickCreate,
            },
            ...options,
          ]}
          error={props.vendorError}
        />
      );
    }}
  </Query>
);
