import gql from "graphql-tag";
import { client } from "../../apollo-config";

const updateContact = async ({
  name,
  email,
  phone,
  type
}, reference) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation{
        updateManyContractorContacts(
          data: {
            name: "${name}"
            email: "${email}"
            phone: "${phone}"
            type: ${type}
          },
          where: {email: "${reference}"}
        ){
          count
        }
      }
    `
  });
  return mutation;
};

export default updateContact;
