import React from "react";
import { Button } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye } from "react-icons/io";
import { useCurrentUser } from "../../../hooks";

export const ActionButtons = ({
  client,
  index,
  onUpdate,
  onDeleteClient,
  openDetails
}) => {
  const { currentUser: { user: userRol } } = useCurrentUser();

  return (
    <Button.Group basic>
      {userRol !== "PURCHASES_RESPONSIBLE" && (
        <>
          <Button
            id={`edit-client-btn-${client.commercialName}`}
            onClick={() =>
              onUpdate(true, index, client.id, client)
            }
            icon>
            <IoMdCreate />
          </Button>
          <Button
            id={`delete-client-btn-${client.commercialName}`}
            onClick={() => onDeleteClient(client.id)}
            icon>
            <IoIosTrash />
          </Button>
        </>
      )}
      <Button
        onClick={() => openDetails(client, index)}
        icon>
        <IoMdEye />
      </Button>
    </Button.Group>
  );
}
