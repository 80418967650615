export default async (name, email, comment, document) => {
  const isProduction = process.env.NODE_ENV === 'production'
  const newEmail = isProduction ? email : 'qamail@jaxitank.com'

  await fetch(process.env.REACT_APP_MAILER, {
    method: "post",
    body: JSON.stringify({
      sender: process.env.REACT_APP_EMAIL_SENDER,
      email: newEmail,
      subject: "Documento rechazado",
      body: process.env.REACT_APP_TEMPLATE_REJECTED_DOCUMENT_CONTRACTOR_URL,
      params: {
        name: name,
        comment: comment,
        document: document,
        url: 'http://localhost:3000/contratistas',
      },
      key: process.env.REACT_APP_MAILER_KEY,
      noReply: true,
    }),
    headers: { "Content-Type": "application/json" },
  });
};
