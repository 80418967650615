import gql from "graphql-tag";

const GetProjectsFull = gql`
  query getProjectsFull {
    projects(orderBy : name_ASC) {
      key: id
      text: name,
      value: id,
      id,
      cli,
      address,
      name,
      cli,
      address,
      status,
      responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
        id,
        name
      }
      residents: users(where: { userRol_in: [TECHNICIAN] }){
        id,
        name
      }
    }
  }
`;

export default GetProjectsFull;
