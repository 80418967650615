import React from "react";
import { Segment, Grid, Form, Input } from "semantic-ui-react";
import { ProjectProgressQuery } from "../../Molecules";
import { PROJECT_PROGRESS } from "../../../graphql/queries/projectProgress";
import { titleCase } from "../../../helpers"

export default class ProjectProgressList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: PROJECT_PROGRESS,
      filterVariables: {
        search: ""
      }
    };
  }

  debounce = (callback, delay) => {
    let timeOutId = null;
    return (...args) => {
      if (timeOutId) clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    }
  }


  searchNow = (e, { name, value }) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        search: value,
        searchUpper: value.toUpperCase(),
        searchLower: value.toLowerCase(),
        searchCaps: titleCase(value)
      }
    }))
  };

  onOrderChange = (header) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        orderBy: header.orderEnum
      }
    }));
  }

  render() {
    const { filter, filterVariables } = this.state

    return (
      <Segment>
        <Grid>
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column width={13}>
              <Form className="Wrapper__Body-Form Jax">
                <Form.Group inline>
                  <Form.Field inline>
                    <label>Buscar</label>
                    <Input
                      id="search"
                      name="search"
                      icon="search"
                      onChange={this.debounce(this.searchNow, 500)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ProjectProgressQuery
                filter={filter}
                filterVariables={filterVariables}
                onOrderChange={this.onOrderChange}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
