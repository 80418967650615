import gql from "graphql-tag";
import { client } from "../../apollo-config";
export default async id => {
  const mutation = await client.query({
    query: gql`
      {
        project(where: { id: "${id}" }) {
          name
          links{            
            index
            source
            target
            type
          }
          tasks {
            id
            index
            text
            start_date
            duration
            progress
            parent
            gallery {
              id
              timestamp
              url
              alt
            }
          }
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return mutation;
};
