import React from "react";
import { Form, Button, Grid, Segment, Input } from "semantic-ui-react";
import WerehousesListContainer from "./container";
import getWerehouses from "../../../graphql/queries/catalogs/getWerehouses";
import { Switch, Route } from "react-router-dom";
import AddWarehouse from "../addWarehouse";
import EditWarehouse from "../editWarehouse";
import theme from "../../_theme";
import { titleCase } from "../../../helpers"
import { PaginationBar } from "../../Molecules/PaginationBar";
import { withRouter } from "react-router-dom";

const INIT_STATE = {
  filter: getWerehouses,
  totalRecords: 0,
  perPageRecords: 0,
  perPageRecordsUpdated: true,
  searchBoxUpdated: false,
  totalRecordsUpdated: false,
  filterVariables: {
    search: ""
  }
}

class WerehousesList extends React.Component {
  state = INIT_STATE;

  onPageChange = (page) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        skip: prevState.perPageRecords * (page - 1)
      }
    }))
  };

  onPerPageChange = (perPage) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        first: perPage
      }
    }))
  };

  debounce = (callback, delay) => {
    let timeOutId = null;
    return (...args) => {
      if (timeOutId) clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    }
  }

  searchNow = (e, { name, value }) => {
    if(!value) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords: 0,
        perPageRecords: 10,
        perPageRecordsUpdated: true,
        searchBoxUpdated: false,
        totalRecordsUpdated: false,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        },
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: true,
        filter: getWerehouses,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        }
      }))
    }
  };

  onCompleteData = data => {
    const totalRecords = data.warehouses.length

    if (!this.state.totalRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords,
        totalRecordsUpdated: true,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }

    if (!this.state.perPageRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        perPageRecordsUpdated: true,
        perPageRecords: totalRecords
      }))
    }

    if (this.state.searchBoxUpdated) {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: false,
        totalRecords,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }
  };

  onOrderChange = (header) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        orderBy: header.orderEnum
      }
    }));
  }

  onReload = () => {
    this.setState(INIT_STATE);
  }

  render() {
    const { filter, filterVariables, totalRecords, perPageRecords } = this.state
    const { openDetailWarehouse } = this.props

    return (
      <Segment>
        <Switch>
          <Route exact path="/catalogos/agregar-almacen" render={props => (
            <AddWarehouse {...props} onReload={this.onReload}/>
          )}/>
          <Route exact path="/catalogos/editar-almacen/:id?" render={props => (
            <EditWarehouse {...props} onReload={this.onReload}/>
          )}/>
          <Route exact path="/catalogos" render={props => (
            <Grid>
              <Grid.Row className="Wrapper__Body-Content">
                <Grid.Column width={13}>
                  <Form className="Wrapper__Body-Form Jax">
                    <Form.Field inline>
                      <label>Buscar</label>
                      <Input
                        id="search"
                        name="search"
                        style={theme.styles.searchInput}
                        icon="search"
                        onChange={this.debounce(this.searchNow, 500)}
                      />
                    </Form.Field>
                  </Form>
                </Grid.Column>
                <Grid.Column textAlign="right" width={3}>
                  <Button
                    basic
                    icon="plus"
                    className="Wrapper__Body-Form-ButtonAdd"
                    content="Almacén"
                    onClick={() =>
                      this.props.history.push("/catalogos/agregar-almacen")
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column>
                  <WerehousesListContainer 
                    {...props} 
                    filter={filter}
                    filterVariables={filterVariables}
                    action={this.onCompleteData}
                    onReload={this.onReload}
                    onOrderChange={this.onOrderChange}
                    openDetails={openDetailWarehouse}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <PaginationBar
                  onPageChange={(p) => this.onPageChange(p)}
                  onPerPageChange={(p) => this.onPerPageChange(p)}
                  totalRecords={totalRecords}
                  perPageRecords={perPageRecords} />
              </Grid.Row>
            </Grid>
          )}/>
        </Switch> 
      </Segment>
    );
  }
}

export default withRouter(WerehousesList)