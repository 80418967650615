export default async (name, email, temp) => {
  await fetch(process.env.REACT_APP_MAILER, {
    method: "post",
    body: JSON.stringify({
      sender: process.env.REACT_APP_EMAIL_SENDER,
      email: email,
      subject: "Tu cuenta en Climas ha sido creada",
      body: process.env.REACT_APP_TEMPLATE_URL,
      params: {
        name: name,
        url: `${
          process.env.REACT_APP_EMAIL_CONFIRMATION_URL
        }/email=${email}temp=${temp}`,
      },
      key: process.env.REACT_APP_MAILER_KEY,
      noReply: true,
    }),
    headers: { "Content-Type": "application/json" },
  });
};
