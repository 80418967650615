import gql from "graphql-tag";

const getClients = gql`
  query getClients(
    $orderBy: ClientOrderByInput,
    $search: [String!] = [],
    $first: Int = 10,
    $skip: Int = 1) {
    clients(
      orderBy: $orderBy,
      where: {
        OR: [
          { commercialName_in: $search },
          {
            contacts_some: {
              name_in: $search,
              email_in: $search,
              phone_in: $search
            }
          },
          { city_in: $search },
          { colony_in: $search }
        ]
      },
      first: $first,
      skip: $skip
    ) {
      id
      rfc
      commercialName
      street
      number
      street
      number
      colony
      postalCode
      city
      state
      country
      razonSocial
      contacts {
        name
        email
        phone
        phone_office
      }
      count
    }
  }`;

export default getClients;
