import gql from "graphql-tag";
import { client } from "../../apollo-config";

const updateRequisition = async ({ id, supplyId, supply, amount, subtotal, total }) => {
  delete supply["id"];
  const _supply = supply;
  const mutation = await client.mutate({
    variables: {
      id,
      supplyId,
      supplyUpdate: _supply,
      amount,
      subtotal,
      total
    },
    mutation: gql`
      mutation(
        $id: ID!
        $supplyId: ID!
        $supplyUpdate: OrderSupplyUpdateDataInput!
        $amount: Float!
        $subtotal: Float!
        $total: Float!
      ) {
        updateRequisition(
          data: {
            amount: $amount
            subtotal: $subtotal
            total: $total
            supplies: {
              update: { where: { id: $supplyId }, data: $supplyUpdate }
            }
          }
          where: { id: $id }
        ) {
          id
        }
      }
    `,
  });
  return mutation;
};

export default updateRequisition;
