import gql from "graphql-tag";
import { client } from "../../apollo-config";

const DELETE_DOCUMENT_ADITIVE = async ({
  idContract,
  idAditive,
  idDocumentAditive,
  invoiced,
  paided,
  amountDeductives,
  contractAmountDeductives
}, { GET_ADITIVES_CONTRACT, QUERY_CONTRACTS }) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContract(
          data: {
            amountDeductives: ${parseFloat(contractAmountDeductives)}
            aditives: {
              update: {
                where: { id: "${idAditive}" }
                data: {
                  invoiced: ${parseFloat(invoiced)}
                  paided: ${parseFloat(paided)}
                  amountDeductives: ${parseFloat(amountDeductives)}
                  documentsAditivas: { delete: { id: "${idDocumentAditive}" } }
                }
              }
            }
          }
          where: { id: "${idContract}" }
        ) {
          id
        }
      }
    `,
    refetchQueries: [
      { query: GET_ADITIVES_CONTRACT, variables: { id: idContract } },
      { query: QUERY_CONTRACTS }
    ]
  });
  return mutation;
};

export default DELETE_DOCUMENT_ADITIVE;
