const validator = object => {
  let validation = [];
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const element = object[key];
      if (
        element === 0 ||
        element === "" ||
        element === undefined ||
        element === null ||
        element <= 0
      ) {
        validation = [...validation];
        validation.push({ key: key, element: element, isValid: false });
      } else {
        validation = [...validation];
        validation.push({ key: key, element: element, isValid: true });
      }
    }
  }
  return validation;
};

export default validator;
