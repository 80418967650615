import React from "react";
import "./style.css";
import Logo from "../../Atoms/logo/index";
export default class Sidebar extends React.Component {
  render() {
    return (
      <div className="sidebar-container">
        <Logo className="header" />
        {this.props.children}
      </div>
    );
  }
}
