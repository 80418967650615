import React from "react";
import { IoMdTrendingUp, IoMdCamera } from "react-icons/io";
import { Gantt } from "../../../Organisms";

export default [
  {
    icon: IoMdTrendingUp,
    item: props => <Gantt {...props} />,
    routeName: "gantt",
    text: "Gráfica",
  },
  // Not sure if this will be needed in the future.
  {
    icon: IoMdCamera,
    item: () => <p>gallery</p>,
    routeName: "gallery",
    text: "Galeria",
  },
];
