import React from "react";
import { Container, Grid, GridRow, GridColumn, Header } from "semantic-ui-react";
import { CreateClient, CreateProject, ClientDetail, ProjectDetail, DashboardLayout } from "../../Organisms";
import { PoNavigation } from "../../Molecules";
import { useCurrentUser } from "../../../hooks";
import routes from "./routes";

export default class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleContent: true,
      detailComponent: false,
      isUpdate: false,
      index: null,
      id: null,
      data: null
    };
  }

  componentWillMount() {
    const { currentUser: { user: userRol } } = useCurrentUser();
    const { item: ActiveRouteItem, routeName: activeRoute } = userRol === "ADMINISTRATIVE_COORDINATOR" ? routes[1] : routes[0];
    const addComponent = userRol === "ADMINISTRATIVE_COORDINATOR" ? "projects" : "client";

    if (userRol === "TECHNICIAN") {
      window.location = "/compras";
    }

    this.setState(prevState => ({
      ...prevState,
      userRol,
      activeRoute,
      ActiveRouteItem,
      addComponent
    }))
  }

  toggleContent = (isUpdate, index, id, _data) => {
    if (isUpdate) {
      this.setState(data => ({
        ...data,
        isUpdate: isUpdate,
        toggleContent: !this.state.toggleContent,
        index: index,
        id: id,
        data: _data,
      }));
    } else {
      this.setState(data => ({
        ...data,
        isUpdate: isUpdate,
        toggleContent: !this.state.toggleContent,
        index: null,
        id: null,
        data: null,
      }));
    }
  };

  toggleDetail = (project, index) => {
    this.setState(prevState => ({
      ...prevState,
      isUpdate: false,
      toggleContent: !this.state.toggleContent,
      detailComponent: !this.state.detailComponent,
      index: index,
      id: project.id,
      data: project,
    }));
  };

  navigateTo(route) {
    const { item, routeName } = route;
    this.setState({
      activeRoute: routeName,
      ActiveRouteItem: item,
      addComponent: routeName,
    });
  }

  renderActiveRouteItem() {
    const { ActiveRouteItem } = this.state;
    return ActiveRouteItem ? (
      <ActiveRouteItem
        openCreate={this.toggleContent}
        onUpdate={this.toggleContent}
        openDetails={this.toggleDetail}
      />
    ) : null;
  }

  renderAddComponent = () => {
    if (this.state.addComponent === "client") {
      if (!this.state.detailComponent) {
        return (
          <CreateClient
            toggleSave={this.toggleContent}
            onCancel={this.toggleContent}
            update={this.state.isUpdate}
            data={this.state.data}
            id={this.state.id}
          />
        );
      } else {
        return <ClientDetail onCancel={this.toggleDetail} data={this.state.data} />
      }
    }

    if (this.state.addComponent === "projects") {
      if (!this.state.detailComponent) {
        return (
          <CreateProject
            onCancel={this.toggleContent}
            update={this.state.isUpdate}
            toggleSave={this.toggleContent}
            id={this.state.id}
            data={this.state.data}
            user={this.state.userRol}
          />
        );
      } else {
        return <ProjectDetail onCancel={this.toggleDetail} id={this.state.id} />
      }
    }
  };

  render() {
    const { toggleContent, activeRoute, userRol } = this.state

    return (
      <DashboardLayout activeTab="clients">
        {toggleContent ? (
          <Container fluid>
            <Grid columns="equal">
              <GridRow className="Wrapper__Nav">
                <GridColumn floated="left">
                  <Header as="h1" className="Wrapper__Nav-Title">
                    {activeRoute === "client"
                      ? "Clientes"
                      : "Proyectos"}
                  </Header>
                </GridColumn>
                <GridColumn floated="right">
                  {userRol !== "ADMINISTRATIVE_COORDINATOR" && (
                    <PoNavigation
                      className="navigation"
                      routes={routes}
                      activeRoute={activeRoute}
                      navigateTo={route => this.navigateTo(route)}
                      style={{ float: "right" }}
                    />
                  )}
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>{this.renderActiveRouteItem()}</GridColumn>
              </GridRow>
            </Grid>
          </Container>
        ) : (
          <Container fluid className="Wrapper__Modal">
            {this.renderAddComponent()}
          </Container>
        )}
      </DashboardLayout>
    );
  }
}
