//  Contractor Page Cmponent
import React from "react";
import { Grid, Header, Container } from "semantic-ui-react";
import DashboardLayout from ".././../Organisms/DashboardLayout";
import { routesContractor, routesManager } from "./routes";
import { PoNavigation } from "../../Molecules";
import { CreateContractor, CreateContract, EditContract } from "../../Organisms";

// Active Route
const user = JSON.parse(window.localStorage.getItem("session")) ? JSON.parse(window.localStorage.getItem("session")).user : ""
const routes = user.userRol === "CONTRACTOR" ? routesContractor : routesManager;
const { item: ActiveRouteItem, routeName: activeRoute, text: title } = user.userRol === "CONTRACTOR" ? routesContractor[0] : routesManager[0];

class Contractors extends React.Component {
  state = {
    action: "",
    toggleContent: true,
    activeRoute,
    ActiveRouteItem,
    data: null,
    title
  };

  // set state values to create or update modal
  toggleContent = (action, data) => {
    if (action === "create") {
      this.setState({
        action,
        toggleContent: !this.state.toggleContent,
        data: null
      });
    } else if (action === "update") {
      this.setState({
        action,
        toggleContent: !this.state.toggleContent,
        data
      });
    } else if (action === "detail") {
      this.setState({
        action,
        toggleContent: !this.state.toggleContent,
        data
      });
    }
  };

  // set in the estate the route and componenent values
  navigateTo(route) {
    const { item, routeName, text } = route;
    this.setState({
      activeRoute: routeName,
      ActiveRouteItem: item,
      addComponent: routeName,
      title: text
    });
  }

  // render the active route in state
  renderActiveRouteItem() {
    const { ActiveRouteItem } = this.state;
    return ActiveRouteItem ? (
      <ActiveRouteItem
        openModal={this.toggleContent}
      />
    ) : null;
  }

  // set state to close modal component
  closeAddComponent = (reset = false) => {
    this.setState({
      action: "",
      toggleContent: true,
      data: null
    });
  }

  // render modal component, set data prop
  // and close function depending of activeRoute
  renderAddComponent = () => {
    const { data, action, activeRoute } = this.state;
    // stringget active route string
    if (activeRoute === "contractor") {
      return (<CreateContractor data={data} close={this.closeAddComponent} readOnly={action === "detail"}/>);
    } else if (activeRoute === "contract") {

      if (action === "update" || action === "detail" ) {
        return (<EditContract
          data={data}
          close={this.closeAddComponent}
          readOnly={action === "detail"}/>)
      }

      return (<CreateContract
        data={data}
        close={this.closeAddComponent}/>);
    }
  };

  render() {
    return (
      <DashboardLayout activeTab="contractors">
        {this.state.toggleContent ? (
          <Container fluid>
            <Grid columns="equal">
              <Grid.Row className="Wrapper__Nav">
                <Grid.Column floated="left">
                  <Header as="h1" className="Wrapper__Nav-Title">
                  {this.state.title === "Facturación" || this.state.title === "Documentos"
                    ? `${this.state.title} por aprobar`
                    : this.state.title
                  }
                  </Header>
                </Grid.Column>
                <Grid.Column floated="right">
                  <PoNavigation
                    className="navigation-right"
                    routes={routes}
                    activeRoute={this.state.activeRoute}
                    navigateTo={route => this.navigateTo(route)}
                    style={{ float: "right" }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>{this.renderActiveRouteItem()}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        ) : (
          <Container fluid className="Wrapper__Modal">
            {this.renderAddComponent()}
          </Container>
        )}
      </DashboardLayout>
    );
  }
}

export default Contractors
