import React from "react";
import { Table, ButtonGroup, Button } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye } from "react-icons/io";
import { format } from "../../../../src/helpers"

const RowsQueryAccountBalanceDocuments = ({ handleDeleteDocument, handleShowModal, cli, original }) => {
  const showButtonTrash = original.status !== "CREATED" ? false : true;
  const user = JSON.parse(window.localStorage.getItem("session")).user
  const isEstimation = original.type !== "ESTIMATION" ? true : false;
  const showAmortization = isEstimation ? "N/A" : format.currency(original.amountAmortization, original.currency);
  const showAmountRetained = isEstimation ? "N/A" : format.currency(original.amountGuaranteeFund, original.currency);
  const showInvoiceNumber = isEstimation ? "N/A" : original.invoiceNumber

  const showEditButton = () => {
    if (original.status !== "CANCELLED") {
      if (original.status === "ESTIMATED" && user.userRol !== "MANAGER") return false;
      if (original.status === "CREATED" && user.userRol !== "MANAGER" && original.type === "DEDUCTIVE") return false;

      return true;
    }
  }

  return (
    <Table.Row textAlign="center" key={original.id}>
      <Table.Cell>{format.typeDocumentsContract(original.type)}</Table.Cell>
      <Table.Cell>{showInvoiceNumber || "S/N"}</Table.Cell>
      <Table.Cell>{cli}</Table.Cell>
      <Table.Cell>{original.concept}</Table.Cell>
      <Table.Cell>{original.currency}</Table.Cell>
      <Table.Cell>{format.date(original.dateElaborated)}</Table.Cell>
      <Table.Cell>{format.currency(original.amount, original.currency)}</Table.Cell>
      <Table.Cell>{showAmortization}</Table.Cell>
      <Table.Cell>{showAmountRetained}</Table.Cell>
      <Table.Cell>{format.currency(original.total, original.currency)}</Table.Cell>
      <Table.Cell>{original.elaborated.name}</Table.Cell>
      <Table.Cell>{format.statusDocumentContract(original.status)}</Table.Cell>
      <Table.Cell textAlign="right">
        <ButtonGroup basic>
          {showEditButton() &&
            <Button  icon onClick={(e) => handleShowModal(e, { title: `Editar ${format.typeDocumentsContract(original.type).toLowerCase()}`,
              typedocument: original.type,
              data: original,
              refUpload: original.refUpload
            })}>
              <IoMdCreate />
            </Button>
          }
          {showButtonTrash &&
            <Button icon onClick={(e) => handleDeleteDocument(e, original)}>
              <IoIosTrash />
            </Button>
          }
          <Button
            icon
            onClick={(e) => handleShowModal(e, { title: `Detalle de ${format.typeDocumentsContract(original.type).toLowerCase()}`,
              typedocument: original.type,
              data: original,
              readOnly: true,
              refUpload: original.refUpload
            })}>
            <IoMdEye />
          </Button>
        </ButtonGroup>
      </Table.Cell>
    </Table.Row>
  )
}

export default RowsQueryAccountBalanceDocuments;
