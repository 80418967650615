import gql from "graphql-tag";

export const UPDATE_CONTRACT = gql`
  mutation(
    $id: ID!
    $data: ContractUpdateInput!
  ) {
    updateContract(
      where: { id: $id }
      data: $data
    ) {
      id
    }
  }
`;
