import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async (id, tasks, links) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateProject(
          where: { id: "${id}" }
          data: {
            tasks: {
              create: [${tasks.map(user => {
                const str = JSON.stringify(user);
                var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                return obj;
              })}]
            }
            links:{
                create:[${links.map(user => {
                  const str = JSON.stringify(user);
                  var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                  return obj;
                })}]
            }
          }
        ) {
          id
        }
      }
    `,
  });
  return mutation;
};
