import gql from "graphql-tag";

const getWerehouses = gql`
  query getWerehouses(
    $first: Int,
    $skip: Int,
    $orderBy: WarehouseOrderByInput
    $search: String
    $searchUpper: String
    $searchLower: String
    $searchCaps: String
  ) {
    warehouses(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: {
        OR: [
          { name_contains: $search }
          { name_contains: $searchUpper }
          { name_contains: $searchLower }
          { name_contains: $searchCaps }
          { city_contains: $search }
          { city_contains: $searchUpper }
          { city_contains: $searchLower }
          { city_contains: $searchCaps }
          { state_contains: $search }
          { state_contains: $searchUpper }
          { state_contains: $searchLower }
          { state_contains: $searchCaps }
          { country_contains: $search }
          { country_contains: $searchUpper }
          { country_contains: $searchLower }
          { country_contains: $searchCaps }
        ]
      }
    ) {
      id
      name
      city
      state
      country
      status
      orders {
        id
      }
    }
  }
`;

export default getWerehouses;