export default [
  {
    field: "name",
    validWhen: false,
    method: "isEmpty",
    message: "",
  },
  {
    field: "email",
    validWhen: true,
    method: "isEmail",
    message: "",
  },
  {
    field: "userRol",
    validWhen: false,
    method: "isEmpty",
    message: "",
  },
];
