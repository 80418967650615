import gql from "graphql-tag"

const getProjectsByClient = gql `
  query getProjectsByClient($id: ID) {
    projects(orderBy: name_ASC, where: { client: { id: $id } }) {
      key: id
      text: name,
      value: id
    }
  }
`;

export default getProjectsByClient;
