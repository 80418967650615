import React from "react";
import "./style.css";

export default class TableRow extends React.Component {
  render() {
    return (
      <tr id={this.props.id} className="tr">
        {this.props.children}
      </tr>
    );
  }
}
