import React, { Component } from 'react'
import { Modal, Table, Container, Button, Dimmer, Loader } from "semantic-ui-react";
import { ContractFormDocuments, ModalHeader } from "../../Molecules";
import { format } from "../../../helpers";
import { Query } from "react-apollo";
import ContractDocumentContext from "../../Organisms/OriginalContractList/contractDocumentContext";
import {
  GET_DOCUMENTS_CONTRACT_BY_CONTRACTOR,
  GET_DOCUMENTS_CONTRACT, AUTHORIZER_USER
} from "../../../graphql/queries/contractors";
import {
  UPDATE_ITEMS_ORIGINAL_CONTRACT,
  UPDATE_ITEMS_ADITIVE_CONTRACT,
  DELETE_ITEM_ADITIVE_CONTRACT,
  DELETE_DOCUMENT_CONTRACT
} from "../../../graphql/mutations/contractors";
import _ from "lodash";
import { bugsnagClient } from "../../../bugsnag";


class DocumentsContractQuery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: JSON.parse(window.localStorage.getItem("session")) ? JSON.parse(window.localStorage.getItem("session")).user : "",
      readOnlyAuthorized: true,
      readOnly: false,
      currentItem: {
        id: "",
        idContract: "",
        idAditive: "",
        typeContract: "",
        userRol: "",
        dateElaborated: "",
        status: "",
        concept: "",
        elaborated: "",
        amount: 0,
        iva: 0,
        amountIva: 0,
        total: 0,
        authorizer: "",
        refUpload: [],
        amountDeductives: 0,
        totalContrated: 0,
        totalInvoiced: 0,
        totalPaided: 0,
        contractAmountDeductives: 0
      },
      isLoading: false,
      showFileModal: false,
      showFiles: "false",
      modalTitle: "",
      documentStatus: [{
        key: "1",
        text: "Por autorizar",
        value: "BY_AUTHORIZING"
      }],
      typeDocument: {
        ESTIMATION: "estimación",
        DEDUCTIVE: "deductiva",
        ADVANCED_PAYMENT: "anticipo",
      }
    }
  }

  authSuccess = async (pass) => {
    const { currentItem: { type }, user } = this.state;
    const authorizer = await AUTHORIZER_USER(user.id, pass);

    if (authorizer) {
      if (type === "DEDUCTIVE")
        this.changeStatus("AUTHORIZED", true, type);
      else
        this.changeStatus("AUTHORIZED", true, type);

      return this.setState(data => ({
        ...data,
        currentItem: {
          ...data.currentItem,
          authorizer
        }
      }));
    }
    alert("Contraseña incorrecta");
  }

  closeModal = () => {
    const { currentItem } = this.state;

    const currentItemClean = {
      id: "",
      idContract: "",
      idAditive: "",
      typeContract: "",
      userRol: "",
      dateElaborated: "",
      status: "",
      concept: "",
      elaborated: "",
      amount: 0,
      iva: 0,
      amountIva: 0,
      total: 0,
      authorizer: "",
      refUpload: [],
      amountDeductives: 0,
      totalContrated: 0,
      totalInvoiced: 0,
      totalPaided: 0,
    };

    Object.assign(currentItem, {...currentItemClean})

    this.setState({
      showFileModal: false,
      modalTitle: "",
      showFiles: "false",
      isLoading: false,
      currentItem
    });
  }

  componentWillMount() {
    const { user: { userRol } } = this.state;
    this.setState({
      readOnly: userRol === "CONTRACTOR" ? true : false
    });
  }

  changeStatus = (status, cancelbutton = true, type = null) => {
    let { currentItem, readOnlyAuthorized, readOnly } = this.state;
    const { bill, payment } = this.getTotalType(currentItem);

    if (status === "BY_AUTHORIZING") {
      status = ["CANCELLED", "BY_AUTHORIZING"];
      readOnlyAuthorized = false;
    }

    if (status === "CANCELLED")
      status = ["CANCELLED"];

    if (status === "AUTHORIZED" && type === "DEDUCTIVE")
      status = ["CANCELLED", "AUTHORIZED"];

    if (status === "PAID_OUT")
      status = ["PAID_OUT"];

    if (status === "INVOICED" && payment)
      status = ["CANCELLED", "PAID_PARTIALLY"];
    else if (status === "INVOICED" && !bill && !cancelbutton)
      status = ["CANCELLED", "BY_BILL"];

    if (status === "INVOICED")
      status = ["CANCELLED", "INVOICED"];

    if (status === "AUTHORIZED")
      status = ["CANCELLED", "BY_BILL", "AUTHORIZED"];

    if (status === "PAID_PARTIALLY")
      status = ["CANCELLED", "PAID_PARTIALLY"];

    if (status === "BY_BILL" && payment)
      status = ["CANCELLED", "PAID_PARTIALLY"];
    else if (status === "BY_BILL")
      status = ["CANCELLED", "BY_BILL"];

    const documentStatus = format.addStatus(status);

    this.setState({
      documentStatus,
      readOnlyAuthorized,
      readOnly,
      cancelbutton,
      currentItem: { ...currentItem,
        status: _.last(documentStatus).value
      }
    });
  }

  openModal = (dataModal) => {
    const { modaltitle, showfiles } = dataModal.componentProps;
    const { currentItem: stateCurrentItem } = this.state;
    const { data } = dataModal;

    const currentItem = Object.assign(stateCurrentItem,{ ...data });
    this.changeStatus(currentItem.status);

    this.setState({
      showFileModal: true,
      modalTitle : modaltitle,
      showFiles: showfiles,
      currentItem
    });
  }

  addPayment = (amountType, value) => {
    const { currentItem } = this.state;
    const { bill, payment } = this.getTotalType(currentItem);

    const total = {
      totalInvoiced: value + (bill ? bill.total : 0),
      totalPaided: value + (payment ? payment.total : 0)
    }

    if (total[amountType] <= currentItem.amount) {
      const data = {
        name: [amountType],
        value: value + currentItem[amountType]
      }

      this.change(null, data);

      if (total[amountType] === currentItem.amount)
        return "paidout";
      return "next";
    } else {
      return "exceeded";
    }
  }

  subtractPayment = async (data) => {
    const { currentItem, idRef, reference, totalReference } = data;
    const subtract = currentItem.refUpload.find(item => item.reference === reference);
    const totalSubtracted = currentItem[totalReference] - parseFloat(subtract.wildcard);
    let props = {
      idContract: currentItem.idContract,
      idDocumentContract: currentItem.id,
      idRef,
      invoiced: currentItem.totalInvoiced,
      paided: currentItem.totalPaided
    }

    if (idRef) {
      try {
        if (subtract.extension === "xml") {
          if (totalReference === "totalInvoiced")
            props = {...props, invoiced: totalSubtracted};

          if (totalReference === "totalPaided")
            props = {...props, paided: totalSubtracted};
        }

        if (currentItem.typeContract === "ORIGINAL") {
          await DELETE_DOCUMENT_CONTRACT(props);
        } else {
          await DELETE_ITEM_ADITIVE_CONTRACT(props);
        }
      } catch (error) {
        bugsnagClient.notify(error.message);
      }
    }

    const items = currentItem.refUpload.filter(item => item.reference !== reference);

    await this.setState(data => ({
      currentItem: { ...data.currentItem,
        [totalReference] : totalSubtracted,
        refUpload: items
      }
    }));

    const { bill, payment } = this.getTotalType(currentItem);

    if(totalReference === "totalInvoiced") {
      const totalDocumentsUploads = bill.total - parseFloat(subtract.wildcard);

      if (totalDocumentsUploads === 0)
        this.changeStatus("BY_BILL", false);
    }

    if(totalReference === "totalPaided") {
      const totalDocumentsUploads = payment.total - parseFloat(subtract.wildcard);

      if (totalDocumentsUploads === 0)
      this.changeStatus("INVOICED", false);
    }
  }

  change = (event, data) => {
    const { name, value } = data;
    let currentItem = Object.assign({}, this.state.currentItem);

    // update amountIva, total
    if (name === "amount") {
      if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value)) return false;

      const amountIva = (+currentItem.iva / 100) * +value;
      const total = +value + amountIva;

      currentItem["amountIva"] = parseFloat(amountIva).toFixed(2);
      currentItem["total"] = parseFloat(total).toFixed(2);

    } else if (name === "iva") {
      if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value)) return false;

      const amountIva = (+value / 100) * +currentItem.amount;
      const total = +currentItem.amount + amountIva;

      currentItem["amountIva"] = parseFloat(amountIva).toFixed(2);
      currentItem["total"] = parseFloat(total).toFixed(2);
    }

    currentItem[name] = value;
    this.setState({ currentItem });
  }

  getTotalType = (documents) => {
    const result = _(documents.refUpload).filter(obj => obj.extension === "xml").groupBy("type")
    .map((obj, key) => ({
      "type": key,
      "total": _.sumBy(obj, o => parseFloat(o.wildcard))
    })).value();

    return {
      bill: result.find(total => total.type === "BILL" ? total : null),
      payment: result.find(total => total.type === "PAYMENT_COMPLEMENT" ? total: null)
    }
  }

  save = async () => {
    this.setState({ isLoading: true });
    const { currentItem, user } = this.state;
    const QUERY = user.userRol === "CONTRACTOR" ? GET_DOCUMENTS_CONTRACT_BY_CONTRACTOR : GET_DOCUMENTS_CONTRACT;

    try {
      if (currentItem.typeContract === "ORIGINAL") {
        if (currentItem.type === "DEDUCTIVE") {
          this.operationsResolveDeductive(currentItem)
        } else {
          this.operationsResolveEstimation(currentItem);
        }
        await UPDATE_ITEMS_ORIGINAL_CONTRACT(currentItem, QUERY, user);
      } else {
        if (currentItem.type === "DEDUCTIVE") {
          this.operationsResolveDeductive(currentItem);
        } else {
          this.operationsResolveEstimation(currentItem);
        }
        await UPDATE_ITEMS_ADITIVE_CONTRACT(currentItem, QUERY, user);
      }
      this.closeModal();
    } catch (error) {
      bugsnagClient.notify(error.message)
    }
  }

  operationsResolveDeductive = (currentItem) => {
    const { amount, status, totalContrated } = currentItem;

    if (status === "AUTHORIZED") {
      currentItem.amountDeductives += +amount;
      currentItem.contractAmountDeductives += +amount;
      currentItem.status = "PAID_OUT";
    }

    if (status === "CANCELLED") {
      currentItem.amountDeductives -= +amount;
      currentItem.contractAmountDeductives -= +amount;
    }
  }

  operationsResolveEstimation = (currentItem) => {
    const { status, totalInvoiced, totalPaided } = currentItem;
    const { bill, payment } = this.getTotalType(currentItem);

    if (status === "CANCELLED") {
      currentItem.totalInvoiced = bill ? (totalInvoiced - bill.total) : totalInvoiced;
      currentItem.totalPaided = payment ? (totalPaided - payment.total) : totalPaided;
    }
  }

  render() {
    const { id, userRol } = this.state.user
    return (
      <Query
        query={userRol === "CONTRACTOR" ? GET_DOCUMENTS_CONTRACT_BY_CONTRACTOR : GET_DOCUMENTS_CONTRACT}
        variables={{id: userRol === "CONTRACTOR" ? id : null}} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {

          if (loading) {
            return (
              <Container fluid className="Wrapper__Body-Loader">
                <Dimmer active inverted >
                  <Loader inverted content='Cargando...' />
                </Dimmer>
              </Container>
            );
          }


          if (data !== undefined || data.contracts.length !== 0) {
            let isDocuments = false;

            data.contracts.forEach(contract => {
              if (contract.documentsContract.length !== 0) {
                isDocuments = true;
              }
            })

            if (!isDocuments) {
              return (
                <Container fluid textAlign="center">
                  "No se encontraron resultados para la búsqueda"
                </Container>
              );
            }
          } else {
            return (
              <Container fluid textAlign="center">
                "No se encontraron resultados para la búsqueda"
              </Container>
            );
          }

          if (error) {
            return (
              <Container fluid className="Wrapper__Body-Loader">
                <Dimmer active inverted>
                  <Loader inverted content='Error' />
                </Dimmer>
              </Container>
            )
          }

          let rowDocumentsContract = []

          data.contracts.forEach(contract => {
            const {
              id: idContract,
              total: totalContrated,
              amountDeductives,
              invoiced: totalInvoiced,
              paided: totalPaided,
              currency,
              project: { name, cli },
              documentsContract,
              aditives
            } = contract

            const coreContract = { idContract, totalContrated, amountDeductives, totalInvoiced, totalPaided, currency, name, cli };

            documentsContract.forEach(docs => {
              rowDocumentsContract.push({...coreContract, ...docs, typeContract: "ORIGINAL" })
            })

            aditives.forEach(aditive => {
              const {
                id: idAditive,
                total: totalContrated,
                amountDeductives,
                invoiced: totalInvoiced,
                paided: totalPaided
              } = aditive;

              const coreAditivas = {
                idContract,
                idAditive,
                totalContrated,
                amountDeductives,
                totalInvoiced,
                totalPaided,
                currency,
                name,
                cli,
                contractAmountDeductives: contract.amountDeductives
              };

              aditive.documentsAditivas.forEach(docsAditives => {
                rowDocumentsContract.push({...coreAditivas, ...docsAditives, typeContract: "ADITIVE" })
              })
            })
          })

          const items = rowDocumentsContract.map(doc => {
            const prefijo = doc.type === "ADVANCED_PAYMENT" ? "Datos del" : "Datos de la";
            const modalTitle = `${prefijo} ${this.state.typeDocument[doc.type]}`;
            const showFiles = doc.type === "ESTIMATION" || doc.type === "ADVANCED_PAYMENT" ? "true" : "false";

            return (
              <Table.Row key={doc.id} textAlign="center">
                <Table.Cell>{doc.name}</Table.Cell>
                <Table.Cell>{doc.cli}</Table.Cell>
                <Table.Cell>{doc.concept}</Table.Cell>
                <Table.Cell>{doc.currency}</Table.Cell>
                <Table.Cell>{format.date(doc.dateElaborated)}</Table.Cell>
                <Table.Cell>{format.currency(doc.amount, doc.currency)}</Table.Cell>
                <Table.Cell>{format.currency(doc.total, doc.currency)}</Table.Cell>
                <Table.Cell>{doc.elaborated}</Table.Cell>
                <Table.Cell>{doc.authorizer}</Table.Cell>
                <Table.Cell>{format.statusDocumentContract(doc.status)}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    icon="eye"
                    content="Ver detalle"
                    className="Modal__Form-ButtonAdd Green Small"
                    documenttype={doc.type}
                    modaltitle={modalTitle}
                    showfiles={showFiles}
                    onClick={(event, componentProps) => {
                      this.openModal({
                        componentProps,
                        data: doc
                      });
                    }}/>
                </Table.Cell>
              </Table.Row>
            )
          })

          const contextValues = {
            currentItem: this.state.currentItem,
            documentStatus: this.state.documentStatus,
            authSuccess: this.authSuccess,
            changeStatus: this.changeStatus,
            addPayment: this.addPayment,
            subtractPayment: this.subtractPayment,
            change: this.change
          }

          const contractFormDocuments = <ContractDocumentContext.Provider value={contextValues}>
            <ContractFormDocuments
              showFiles={this.state.showFiles}
              readOnly={this.state.readOnly}
              readOnlyAuthorized={this.state.readOnlyAuthorized}
              userRol={userRol}
            />
          </ContractDocumentContext.Provider>;

          return (
            <Container fluid className="Wrapper__Body-Container-Table">
              <Table basic="very" compact="very" textAlign="center" sortable className="Wrapper__Body-Table" singleLine={true}>
                <Table.Header className="Wrapper__Body-Table-Header Small">
                  <Table.Row>
                    <Table.HeaderCell>
                      Nombre del proyecto
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Presupuesto CLI
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Concepto
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Moneda
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Fecha
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Importe
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Total
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Elaboró
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Autorizó
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Estatus
                    </Table.HeaderCell>
                    <Table.HeaderCell className="Wrapper__Body-Table-Header-NoHover"/>
                  </Table.Row>
                </Table.Header>
                <Table.Body className="Wrapper__Body-Table-Body Small">
                  {items}
                </Table.Body>
              </Table>

              <Modal
                className="Modal__Form-SubModal"
                centered={true}
                open={this.state.showFileModal}
                header={
                  <ModalHeader
                    component={false}
                    title={this.state.modalTitle}
                    save={this.save}
                    cancel={this.closeModal}
                    disabled={this.state.isLoading}
                    readOnly={false}
                    cancelbutton={true}
                  />
                }
                content={contractFormDocuments}
                size="large"
                dimmer="blurring"
              />
            </Container>
          )
        }}
      </Query>
    )
  }
}

export default DocumentsContractQuery
