import { IoMdCreate, IoLogoUsd, IoIosCopy } from "react-icons/io";
import { FaPercent } from "react-icons/fa";

import { DocumentsContractorList, DocumentsContractList, ContractorsList, ContractList } from "../../../Organisms";

export const routesContractor = [
  {
    icon: FaPercent,
    item: DocumentsContractList,
    routeName: "documentsContract",
    text: "Facturación"
  },
  {
    icon: IoLogoUsd,
    item: ContractList,
    routeName: "contract",
    text: "Contratos"
  }
]

export const routesManager = [
  {
    icon: IoIosCopy,
    item: DocumentsContractorList,
    routeName: "documentsContractor",
    text: "Documentos"
  },
  {
    icon: FaPercent,
    item: DocumentsContractList,
    routeName: "documentsContract",
    text: "Facturación"
  },
  {
    icon: IoMdCreate,
    item: ContractorsList,
    routeName: "contractor",
    text: "Contratistas"
  },
  {
    icon: IoLogoUsd,
    item: ContractList,
    routeName: "contract",
    text: "Contratos"
  }
];
