/*
  Take string date in format YYYY-MM-DD and return
  string in format DD/MM//YYYY
*/

import moment from "moment";

const parseDate = (value) => {
  const date = moment(value, "YYYY-MM-DD");
  return date.format("DD/MM/YYYY");
}

export default parseDate;