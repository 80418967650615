import { AbilityBuilder } from "@casl/ability";
import { auth } from "../../../auth";

const user = auth.authState()
  ? JSON.parse(auth.authState()).user.userRol
  : null;
const ability = AbilityBuilder.define(can => {
  can("read", "all");

  if (["ADMINISTRATOR", "MANAGER"].includes(user)) {
    can(["approve"], "Requisition");
    can(["convert"], "Requisition");
    can(["create", "edit", "delete"], "PurchaseOrder");
    can(["navigate"], "Catalogs");
    can([
      "download",
      "approveclose",
      "rejectDocument",
      "approvedocs",
      "addContractors",
      "editContractors",
    ], "ContractorDocuments");
    can(["create", "edit", "delete", "detail"], "Contracts");
    can(["create", "edit", "delete", "detail", "authorize"], "OriginalContract");
    can(["create", "edit", "delete", "detail"], "AditivesContract");
  }

  if (user === "PURCHASES_RESPONSIBLE") {
    can(["create", "edit", "delete"], "Warehouses");
    can(["revise"], "Requisition");
    can(["convert"], "Requisition");
    can(["create", "edit", "delete"], "PurchaseOrder");
    can(["navigate"], "Catalogs");
  }

  if (user === "TECHNICIAN") {
    can(["create", "edit"], "Requisition");
  }

  if (user === "CONTRACTOR") {
    can(["detail"], "Contracts");
    can(["detail"], "OriginalContract");
    can(["detail"], "AditivesContract");
    can(["upload"], "ContractorDocuments");
  }

  if (user === "CONTRACTOR_RESPONSIBLE") {
    can([
      "download",
      "approveclose",
      "rejectDocument",
      "approvedocs",
      "addContractors",
      "editContractors"
    ], "ContractorDocuments");
    can(["create", "edit", "delete", "detail"], "Contracts");
    can(["create", "edit", "delete", "detail"], "OriginalContract");
    can(["create", "edit", "delete", "detail"], "AditivesContract");
  }

  if (user === "ACCOUNT_BALANCE_RESPONSIBLE") {
    can(["download", "approveclose"], "ContractorDocuments");
    can(["create", "edit", "delete", "detail"], "Contracts");
    can(["create", "edit", "delete", "detail"], "OriginalContract");
    can(["create", "edit", "delete", "detail"], "AditivesContract");
    can(["create", "edit"], "Requisition");
  }

  if (user === "WAREHOUSE_RESPONSIBLE") {
    can(["create", "edit", "delete"], "Warehouses");
    can(["revise"], "Requisition");
    can(["convert"], "Requisition");
    can(["create", "edit", "delete"], "PurchaseOrder");
    can(["navigate"], "Catalogs");
  }

  if (user === "ADMINISTRATIVE_COORDINATOR") {
    can(["viewStatus"], "Requisition");
    can(["edit"], "PurchaseOrder");
    can(["download", "approveclose", "rejectDocument"], "ContractorDocuments");
    can(["detail"], "Contracts");
    can(["create", "edit", "delete", "detail", "authorize"], "OriginalContract");
    can(["create", "edit", "delete", "detail"], "AditivesContract");
  }

  if (user === "ZONE_COORDINATOR") {
    can(["create", "edit", "delete"], "Warehouses");
    can(["revise"], "Requisition");
    can(["convert"], "Requisition");
    can(["download"], "ContractorDocuments");
    can(["detail"], "Contracts");
    can(["create", "edit", "delete"], "PurchaseOrder");
    can(["navigate"], "Catalogs");
  }
});

ability.can("update", "Product", "price");

export default ability;
