import React from "react";
import { Button } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye } from "react-icons/io";
import { Can } from "../../hoc/permissions";
import { isInactive } from "../../../helpers";

export const ActionButtons = ({
  contract,
  editItem,
  detailItem,
  handleModal
}) => {
  const { project } = contract
  let isInactiveProject = isInactive(project.status)

  return (
    <Button.Group basic>
      <Can do="edit" on="Contracts">
        <Button
          disabled={isInactiveProject}
          id={`edit-client-btn-${contract.id}`}
          onClick={!isInactiveProject ? () => editItem(contract) : null}
          icon>
          <IoMdCreate />
        </Button>
        <Button
          disabled={isInactiveProject}
          id={`delete-client-btn-${contract.id}`}
          onClick={!isInactiveProject ? () => handleModal(contract.id) : null}
          icon>
          <IoIosTrash />
        </Button>
      </Can>
      <Button
        disabled={isInactiveProject}
        onClick={!isInactiveProject ? () => detailItem(contract) : null}
        icon>
        <IoMdEye />
      </Button>
    </Button.Group>
  );
}
