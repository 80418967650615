import gql from "graphql-tag";
import _ from "lodash"
import { client } from "../../apollo-config";

const updateProject = async ({
  id,
  name,
  cliId,
  cliName,
  cliBudget,
  startDate,
  finishDate,
  status,
  address,
  connectUsers,
  oldUsers
}) => {
  const disconnect = _.difference(oldUsers, connectUsers);
  const connect = _.difference(connectUsers, oldUsers);
  const usersDisconnect = disconnect.map(id => ({ id }))
  const usersConnect = connect.map(id => ({ id }))

  const getDate = () => {
    var today = new Date();

    return (
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)
    );
  };

  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateProject(
          where:{id: "${id}"}
          data: {
            name: "${name}"
            cli: "${cliBudget}"
            client: {
              connect: {
                id: "${cliId}"
              }
            }
            startDate: "${startDate}"
            finishDate: "${finishDate}"
            wasUpdatedAt: "${getDate()}"
            status: ${status}
            address: "${address}"
            amount: 0
            users: {
              disconnect: [${usersDisconnect.map(user => {
                const str = JSON.stringify(user);
                var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                return obj;
              })}]
              connect: [${usersConnect.map(user => {
                const str = JSON.stringify(user);
                var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                return obj;
              })}]
            }
          }
        ) {
          id
        }
      }
    `,
  });
  return mutation;
};

export default updateProject;
