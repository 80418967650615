import gql from "graphql-tag";

const getPurchaseOrder = gql`
  query($id: ID) {
    purchaseOrder(where: { id: $id }) {
      id
      folio
      data {
        folio
        notes
        revisedBy
        authorizedBy
        supplies {
          id
          name
          insID
          description
          units
          quantity
          purchasePrice
        }
        receivedSupplies {
          id
          insID
          name
          description
          purchasePrice
          quantity
          units
          received
          notes
          warehouse
          receptionDate
          receivedBy
          photoID
          deliveryDate
          shippingDate
          trackingNumber
          shippingCompany
        }
        retention
        discount
        retentionPercentage
        discountPercentage
        amount
        subtotal
        total
        iva
        shipmentDate
        deliveryDate
        arrivalDate
        currency
        createdAt
        status
        petitioner
        paymentConditions
        trackingNumber
        shippingCompany
        shippingNotes
        warehouse {
          name
        }
        vendor {
          commercialName
        }
        project {
          id
          cli
          name
          address
          responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
            id,
            name
          }
          residents: users(where: { userRol_in: [TECHNICIAN] }){
            id,
            name
          }
        }
      }
    }
  }
`;
export default getPurchaseOrder;
