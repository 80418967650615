import React from "react";
import { Button, Container } from "semantic-ui-react";
import theme from "../../_theme";

const ActionButtons = props => {
  return (
    <Container>
      {!!props.onSave && (
        <Button
          id={props.saveId}
          onClick={props.onSave}
          style={theme.styles.buttons.red}
          disabled={props.submitting}
        >
          {props.saveText ? props.saveText : "Guardar"}
        </Button>
      )}
      <Button
        id={props.cancelId}
        onClick={props.onCancel}
        disabled={props.submitting}
      >
        Cancelar
      </Button>
    </Container>
  );
};

export default ActionButtons;
