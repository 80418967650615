import React from "react";
import { Button, ButtonGroup } from "semantic-ui-react";
import { IoIosTrash, IoMdCreate, IoMdEye } from "react-icons/io";
import { Can } from "../../hoc/permissions";

export const ActionButtons = ({ contractor, editItem, handleModalOpen, detailItem }) => {
  return (
    <ButtonGroup basic>
      <Can do="editContractors" on="ContractorDocuments">
        <Button onClick={() => editItem(contractor)} icon>
          <IoMdCreate />
        </Button>
        <Button onClick={() => handleModalOpen(contractor) } icon>
          <IoIosTrash />
        </Button>
      </Can>
      <Button onClick={() => detailItem(contractor)} icon>
        <IoMdEye />
      </Button>
    </ButtonGroup>
  );
};