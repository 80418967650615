import React from "react";
import { Form, Header, Input, Select, Segment, TextArea, Container, Dimmer, Loader } from "semantic-ui-react";
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { CREATE_PROJECT, UPDATE_PROJECT } from "../../../graphql/mutations/projects";
import { bugsnagClient } from "../../../bugsnag";
import mixpanel from "../../../mixpanel";
import { FormHeader, SelectClient, SelectUserRol } from "../../Molecules";
import FormValidator from "../../../helpers/form-validator";
import validations from "./validations";
import { client } from "../../../graphql/apollo-config";
import getProject from "../../../graphql/queries/projects/getProject"
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

const initState = {
  id: "",
  name: "",
  cliId: "",
  cliBudget: "",
  startDate: "",
  finishDate: "",
  status: "",
  address: "",
  responsibles: [],
  residents: [],
  oldUsers: [],
  nameError: false,
  cliIdError: false,
  cliBudgetError: false,
  startDateError: false,
  finishDateError: false,
  statusError: false,
  addressError: false,
  responsiblesError: false,
  residentsError: false,
  isSubmitting: false,
  isLoading: true,
  canActiveOrInactiveProject: false
};

export default class CreateProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initState };
  }

  async componentDidMount () {
    const { id, update, user = {} } = this.props

    if (update) {
      const { data: { project } } = await client.query({ query: getProject, variables: { id }, fetchPolicy: "network-only" });
      const { name, client: { id: cliId }, cli: cliBudget, startDate, finishDate, status, address, responsibles, residents } = project
      const selectResponsibles = responsibles.map(responsible => responsible.id)
      const selectResidents = residents.map(resident => resident.id)
      const oldUsers = [ ...selectResponsibles, ...selectResidents]

      this.setState(prevState => ({
        ...prevState,
        id,
        name,
        cliId,
        cliBudget,
        startDate,
        finishDate,
        status,
        address,
        responsibles: selectResponsibles,
        residents: selectResidents,
        oldUsers,
        isLoading: false,
        canActiveOrInactiveProject: user.userRol !== "ADMINISTRATOR"
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        isLoading: false
      }))
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  handleSave = async () => {
    this.setState(prevState => ({ ...prevState, isSubmitting: true }));

    const { id, update, toggleSave } = this.props;
    const { name, cliId, cliBudget, startDate, finishDate, status, address, responsibles, residents, oldUsers } = this.state;
    const connectUsers = [...responsibles, ...residents]
    const project = { name, cliId, cliBudget, startDate, finishDate, status, address, connectUsers };

    // Validations of the form
    const validationProject = this.validate(project, validations["createProject"]);
    const validationResponsibles = responsibles.length > 0 ? true : false;
    const validationResidents = residents.length > 0 ? true : false;

    if (validationProject.isValid && validationResponsibles && validationResidents) {
      // Si no es UPDATE PROJECT
      if (!update) {
        try {
          await CREATE_PROJECT(project);
          mixpanel.track("Proyecto creado");
          toggleSave();
        } catch (err) {
          bugsnagClient.notify(err);
        }
      } else {
        try {
          await UPDATE_PROJECT({ id, ...project, oldUsers });
          mixpanel.track("Proyecto actualizado");
          toggleSave();
        } catch (err) {
          bugsnagClient.notify(err);
        }
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        isSubmitting: false,
        residentsError: true,
        responsiblesError: true,
      }));
    }
  }

  validate = (project, validationObject) => {
    const validator = new FormValidator(validationObject);
    const validation = validator.validate(project);
    const errors = [];
    Object.keys(validation).map(field => {
      const key = field;
      const errorKey = `${key}Error`;
      if (!field.isValid) {
        errors.push(key);
      }
      this.setState(data => ({
        ...data,
        [errorKey]: !field.isValid,
      }));
    });
    return validation;
  };

  render() {
    return (
      <Segment raised className="Modal">
        {this.state.isLoading
          ? (
            <Container fluid className="Wrapper__Body-Loader">
              <Dimmer active inverted >
                <Loader inverted content='Cargando...' />
              </Dimmer>
            </Container>
            )
          : (
            <>
              <FormHeader
                id={this.props.update}
                saveId="save-vendor-btn"
                cancelId="cancel-vendor-btn"
                text={this.props.update ? "Editar proyecto" : "Nuevo proyecto"}
                onSave={this.handleSave}
                submitting={this.state.isSubmitting}
                onCancel={this.props.onCancel}
              />
              <Form className="Modal__Form">
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="NOMBRE DEL PROYECTO"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    error={this.state.nameError}
                    autoComplete="off"
                    required
                  />
                  <Form.Field
                    control={SelectClient}
                    label="SELECCIONAR CLIENTE"
                    name="cliId"
                    value={this.state.cliId}
                    error={this.state.cliIdError}
                    onChange={this.handleChange}
                    required
                  />
                  <Form.Field
                    control={Input}
                    label="PRESUPUESTO CLI"
                    name="cliBudget"
                    value={this.state.cliBudget}
                    onChange={this.handleChange}
                    error={this.state.cliBudgetError}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    className="Util-DatePicker"
                    control={SemanticDatepicker}
                    type="basic"
                    format="DD/MM/YYYY"
                    label="FECHA INICIA"
                    name="startDate"
                    placeholder={!this.state.startDate ? "dd/mm/aaaa" : moment(this.state.startDate).add(1, "day").format("DD/MM/YYYY")}
                    onDateChange={
                      (date) => this.handleChange(null, { name: "startDate", value: (date !== null ? moment(date).format("YYYY-MM-DD") : "") })
                    }
                    error={this.state.startDateError}
                    autoComplete="off"
                    readOnly={true}
                    required
                  />
                  <Form.Field
                    className="Util-DatePicker"
                    control={SemanticDatepicker}
                    type="basic"
                    format="DD/MM/YYYY"
                    label="FECHA TERMINA"
                    name="finishDate"
                    placeholder={!this.state.finishDate ? "dd/mm/aaaa" : moment(this.state.finishDate).add(1, "day").format("DD/MM/YYYY")}
                    onDateChange={
                      (date) => this.handleChange(null, { name: "finishDate", value: (date !== null ? moment(date).format("YYYY-MM-DD") : "") })
                    }
                    error={this.state.finishDateError}
                    autoComplete="off"
                    readOnly={true}
                    required
                  />
                  <Form.Field
                    disabled={this.state.canActiveOrInactiveProject}
                    control={Select}
                    label="ESTATUS"
                    name="status"
                    options={[
                      { text: "Activa", value: "ACTIVE" },
                      { text: "Inactiva", value: "INACTIVE" },
                    ]}
                    defaultChecked={this.state.status}
                    value={this.state.status}
                    onChange={this.state.canActiveOrInactiveProject || this.handleChange}
                    error={this.state.statusError}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
                <Form.Field
                  label="DIRECCIÓN"
                  control={TextArea}
                  name="address"
                  value={this.state.address && this.state.address!=="undefined" ? this.state.address : ""}
                  onChange={this.handleChange}
                  error={this.state.addressError}
                  required
                />
              </Form>
              <Header as="h3" dividing className="Modal__Form-Header">
                Responsables
              </Header>
              <Form className="Modal__Form" width={5}>
              <Form.Group style={{ display: "flex", justifyContent: "center" }}>
                <Form.Field
                  control={SelectUserRol}
                  width={8}
                  rol={["MANAGER", "ADMINISTRATIVE_COORDINATOR"]}
                  label="SELECCIONA RESPONSABLE DEL PROYECTO"
                  name="responsibles"
                  error={this.state.responsiblesError}
                  value={this.state.responsibles}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              <Header as="h3" dividing className="Modal__Form-Header">
                Residentes
              </Header>
              <Form.Group style={{ display: "flex", justifyContent: "center" }}>
                <Form.Field
                  control={SelectUserRol}
                  width={8}
                  rol={["TECHNICIAN"]}
                  label="SELECCIONA RESIDENTE DEL PROYECTO"
                  name="residents"
                  error={this.state.residentsError}
                  value={this.state.residents}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
            </Form>
            </>
          )
        }
      </Segment>
    );
  }
}
