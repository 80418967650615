import React from "react";
import { Header, Grid } from "semantic-ui-react";
import { ActionButtons } from "../";

const FormHeader = props => {
  return (
    <Header as="h3" dividing className="Modal__Header">
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column textAlign="left" className="Modal__Header-Title">
            {props.text}
          </Grid.Column>
          <Grid.Column textAlign="right">
            <ActionButtons
              saveId={props.saveId}
              cancelId={props.cancelId}
              onSave={props.onSave}
              onCancel={props.onCancel}
              submitting={props.submitting}
              saveText={props.saveText}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Header>
  );
};

export default FormHeader;
