import React from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import { CreateVendor, AddSupply, VendorDetail, DetailWarehouse } from "../../Organisms";
import { PoNavigation } from "../../Molecules";
import { routes, warehouseRoute } from "./routes";
import DashboardLayout from ".././../Organisms/DashboardLayout";

export default class Catalogs extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(window.localStorage.getItem("session")).user;
    const { item: ActiveRouteItem, routeName: activeRoute } =
      user.userRol === "PURCHASES_RESPONSIBLE" ||
      user.userRol === "MANAGER" ||
      user.userRol === "WAREHOUSE_RESPONSIBLE" ||
      user.userRol === "ZONE_COORDINATOR"
        ? warehouseRoute[0]
        : routes[0];
    this.state = {
      toggleContent: true,
      isUpdate: false,
      activeRoute,
      ActiveRouteItem,
      index: null,
      id: null,
      data: null,
      vendors: [],
      currentVendor: null,
      renderAddButton: false,
      renderTitle: false,
      addComponent: "prov",
      detailComponent: false,
      user,
      text:"Proveedores"
    };
  }

  componentWillMount() {
    const session = JSON.parse(window.localStorage.getItem("session"));
    if (session.user.userRol === "TECHNICIAN") {
      window.location = "/compras";
    }
  }

  toggleContent = (isUpdate, index, id, _data) => {
    if (isUpdate) {
      this.setState(data => ({
        ...data,
        isUpdate: isUpdate,
        toggleContent: !this.state.toggleContent,
        index: index,
        id: id,
        data: _data,
      }));
    } else {
      this.setState(data => ({
        ...data,
        isUpdate: isUpdate,
        toggleContent: !this.state.toggleContent,
        index: null,
        id: null,
        data: null,
      }));
    }
  };

  toggleDetail = (vendor, index) => {
    this.setState(data => ({
      ...data,
      isUpdate: false,
      toggleContent: !this.state.toggleContent,
      detailComponent: !this.state.detailComponent,
      index: index,
      id: vendor.id,
      data: vendor,
      vendor: vendor,
    }));
  };

  toggleDetailWarehouse = (warehouse, index) => {
    this.setState(data => ({
      ...data,
      isUpdate: false,
      toggleContent: !this.state.toggleContent,
      detailComponent: !this.state.detailComponent,
      index: index,
      data: warehouse,
    }));
  };

  toggleDetailSupply = supply => {
    this.setState(data => ({
      ...data,
      isUpdate: false,
      toggleContent: !this.state.toggleContent,
      detailComponent: !this.state.detailComponent,
      supplyData: supply,
      id: supply.id,
    }));
  };

  toggleEditSupply = supply => {
    this.setState(data => ({
      ...data,
      isUpdate: !this.state.isUpdate,
      toggleContent: !this.state.toggleContent,
      detailComponent: !this.state.detailComponent,
      supplyData: supply,
      id: supply?supply.id:"",
      data: supply,
    }));
  };

  handleChange = (e, { name, value }) => {
    this.setState(data => ({
      ...data,
      supplyDate: {
        [name]: value,
      },
    }));
  };

  navigateTo(route) {
    const { item, routeName, text } = route;
    this.setState({
      activeRoute: routeName,
      ActiveRouteItem: item,
      addComponent: routeName,
      text,
    });
  }

  renderActiveRouteItem() {
    const { ActiveRouteItem } = this.state;
    return ActiveRouteItem ? (
      <ActiveRouteItem
        openCreate={this.toggleContent}
        onUpdate={this.toggleContent}
        openDetails={this.toggleDetail}
        openDetailWarehouse={this.toggleDetailWarehouse}
        toggleDetailSupply={this.toggleDetailSupply}
        toggleEditSupply={this.toggleEditSupply}
        toggleSave={this.toggleContent}
      />
    ) : null;
  }

  renderAddComponent = () => {
    if (this.state.addComponent === "prov" && !this.state.detailComponent) {
      return (
        <CreateVendor
          toggleSave={this.toggleContent}
          onCancel={this.toggleContent}
          update={this.state.isUpdate}
          id={this.state.id}
          data={this.state.data}
        />
      );
    }

    if (this.state.addComponent === "sup" && !this.state.detailComponent) {
      return (
        <AddSupply
          onCancel={this.toggleContent}
          update={this.state.isUpdate}
          toggleSave={this.toggleContent}
        />
      );
    }

    if (
      this.state.addComponent === "prov" &&
      this.state.detailComponent === true
    ) {
      return (
        <VendorDetail
          onCancel={this.toggleDetail}
          id={this.state.id}
          data={this.state.data}
        />
      );
    }
    if (
      this.state.addComponent === "sup" &&
      this.state.detailComponent === true
    ) {
      const data = this.state.supplyData;
      return (
        <div>
          <AddSupply
            onCancel={this.toggleEditSupply}
            isUpdate
            data={this.state.data}
            toggleSave={this.toggleEditSupply}
          />
        </div>
      );
    }
    if (
      this.state.addComponent === "warehouse" &&
      this.state.detailComponent === true
    ) {
      return (
        <div>
          <DetailWarehouse
            onCancel={this.toggleDetailWarehouse}
            isUpdate
            data={this.state.data}
            toggleSave={this.toggleDetailWarehouse}
          />
        </div>
      );
    }
  };

  renderWarehouseDetail = () => (
    <div>
      <DetailWarehouse
        onCancel={this.toggleDetailWarehouse}
        isUpdate
        data={this.state.data}
        toggleSave={this.toggleDetailWarehouse}
      />
    </div>
  );

  render() {
    return (
      <DashboardLayout activeTab="catalogs">
        {this.state.toggleContent ? (
          <Container fluid>
            <Grid columns="equal">
              <Grid.Row className="Wrapper__Nav">
                <Grid.Column floated="left">
                  <Header as="h1" className="Wrapper__Nav-Title">
                    {this.state.text}
                  </Header>
                </Grid.Column>
                <Grid.Column floated="right">
                  {this.state.user.userRol === "MANAGER" ||
                  this.state.user.userRol === "PURCHASES_RESPONSIBLE" ||
                  this.state.user.userRol === "WAREHOUSE_RESPONSIBLE" ? (
                    <PoNavigation
                      className="navigation-right"
                      routes={routes}
                      activeRoute={this.state.activeRoute}
                      navigateTo={route => this.navigateTo(route)}
                      style={{ float: "right" }}
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>{this.renderActiveRouteItem()}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        ) : (
          <Container fluid className="Wrapper__Modal">
            {this.renderAddComponent()}
          </Container>
        )}
      </DashboardLayout>
    );
  }
}
