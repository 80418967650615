import React from "react";
import { Button, Grid, Segment, Form, Input } from "semantic-ui-react";
import ContractorsQuery from "../../Molecules/ContractorsQuery";
import theme from "../../_theme";
import { Can } from "../../hoc/permissions";
import { contractors } from "../../../graphql/queries/contractors"
import { titleCase } from "../../../helpers";
import { PaginationBar } from "../../Molecules/PaginationBar";

class ContractorsList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      filter: contractors,
      totalRecords: 0,
      perPageRecords: 0,
      perPageRecordsUpdated: true,
      searchBoxUpdated: false,
      totalRecordsUpdated: false,
      filterVariables: {
        search: ""
      },
      idContract: "",
    };
  }

  onPageChange = (page) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        skip: prevState.perPageRecords * (page - 1)
      }
    }))
  };

  onPerPageChange = (perPage) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        first: perPage
      }
    }))
  };

  debounce = (callback, delay) => {
    let timeOutId = null;
    return (...args) => {
      if (timeOutId) clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    }
  }

  searchNow = (e, { name, value }) => {
    if(!value) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords: 0,
        perPageRecords: 10,
        perPageRecordsUpdated: true,
        searchBoxUpdated: false,
        totalRecordsUpdated: false,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        },
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: true,
        filter: contractors,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        }
      }))
    }
  };

  onCompleteData = data => {
    const totalRecords = data.contractors.length

    if (!this.state.totalRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords,
        totalRecordsUpdated: true,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }

    if (!this.state.perPageRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        perPageRecordsUpdated: true,
        perPageRecords: totalRecords
      }))
    }

    if (this.state.searchBoxUpdated) {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: false,
        totalRecords,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }
  };

  onOrderChange = (header) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        orderBy: header.orderEnum
      }
    }));
  }

  onReload = () => {
    this.setState(prevState =>({
      ...prevState,
    }))
  }

  render() {
    const { filter, filterVariables, totalRecords, perPageRecords } = this.state

    return (
      <Segment>
        <Grid columns="equal">
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column>
              <Form className="Wrapper__Body-Form Jax">
                <Form.Field inline>
                  <label>Buscar</label>
                  <Input
                    id="search2"
                    name="search2"
                    style={theme.styles.searchInput}
                    icon="search"
                    onChange={this.debounce(this.searchNow, 500)}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Can do="addContractors" on="ContractorDocuments">
                <Button
                  basic
                  onClick={() => this.props.openModal("create")}
                  icon="plus"
                  content="Contratista"
                  className="Wrapper__Body-Form-ButtonAdd"/>
              </Can>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column>
              <ContractorsQuery
                filter={filter}
                filterVariables={filterVariables}
                action={this.onCompleteData}
                onReload={this.onReload}
                onOrderChange={this.onOrderChange}
                openModal={this.props.openModal}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <PaginationBar
              onPageChange={(p) => this.onPageChange(p)}
              onPerPageChange={(p) => this.onPerPageChange(p)}
              totalRecords={totalRecords}
              perPageRecords={perPageRecords} />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default ContractorsList
