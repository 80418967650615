import React from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import { DashboardLayout, ProjectProgressList } from "../../Organisms";

export default class ProjectProgress extends React.Component {
  render() {
    return (
      <DashboardLayout activeTab="projectProgress">
        <Container fluid>
          <Grid columns="equal">
            <Grid.Row className="Wrapper__Nav">
              <Grid.Column floated="left">
                <Header as="h1" className="Wrapper__Nav-Title">
                  Avance de proyecto
                </Header>
              </Grid.Column>
              <Grid.Column floated="right" />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ProjectProgressList />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </DashboardLayout>
    );
  }
}
