import React from "react";
import { Grid, Form, Divider, Button, Modal } from "semantic-ui-react";
import { ModalHeader, AccountBalanceDocumentsQuery, OnLoading } from "../../Molecules";
import { client } from "../../../graphql/apollo-config";
import { GET_CONTRACT_ACCOUNT_BALANCE_BY_ID, GET_CONTRACT_ADITIVE_ACCOUNT_BALANCE_BY_ID } from "../../../graphql/queries/accountBalance";
import {
  CREATE_DOCUMENT_CONTRACT_AB,
  CREATE_DOCUMENT_CONTRACT_ADITIVE_AB,
  DELETE_DOCUMENT_CONTRACT_ACCOUNT_BALANCE,
  DELETE_DOCUMENT_ADITIVE_ACCOUNT_BALANCE,
  DELETE_DOCUMENT_UPLOADED_AB } from "../../../graphql/mutations/accountBalance";
import { EstimateFormAccountBalance, GenericDocsFormAccountBalance } from "../";
import { helper, sendApproveDocumentAccountBalance } from "../../../helpers";
import { bugsnagClient } from "../../../bugsnag";
import _ from "lodash";
import CurrencyInput from 'react-currency-input'

class AccountBalanceCurrencyForm extends React.Component {
  state = {
    isLoading: true,
    showModal: false,
    title: undefined,
    typeDocument: undefined,
    readOnly: this.props.readOnly,
    idContract: this.props.idContract,
    isOriginalContract: this.props.isOriginalContract,
    contractAccountBalance: {},
    documentData: {},
    errors: {},
    submitting: false,
    dataUpdate: false,
    disabledStatus: false,
    refUpload: []
  };

  componentWillMount() {
    this.props.currentTab(2);
  }

  async componentDidMount () {
    this.fetchData();
  }

  componentDidUpdate (prevProps) {
    if(prevProps.counterDocument !== this.props.counterDocument) {
      this.setState({ isLoading: true, showModal: false, submitting: false, contractAccountBalance: {} });
      this.fetchData();
    }
  }

  fetchData = async () => {
    if (this.state.isOriginalContract) {
      const { data: { contractAccountBalance } } = await client.query({ query: GET_CONTRACT_ACCOUNT_BALANCE_BY_ID, variables: { id: this.state.idContract }, fetchPolicy: "network-only"});
      this.setState({ contractAccountBalance, isLoading: false })
    } else {
      const { data: { aditiveAccountBalance } } = await client.query({ query: GET_CONTRACT_ADITIVE_ACCOUNT_BALANCE_BY_ID, variables: { id: this.state.idContract }, fetchPolicy: "network-only"});
      this.setState({ contractAccountBalance: aditiveAccountBalance, isLoading: false })
    }
  }

  cleanDocumentData() {
    return {
      concept: "",
      dateElaborated: "",
      amountAmortization: "",
      amountGuaranteeFund: "",
      status: "",
      amount: "",
      total: "",
    }
  }

  handleShowModal = (e, { title, typedocument, data, readOnly, refUpload }) => {
    const documentData = this.calcultateAmountAP(data, typedocument);

    this.setState({
      showModal: !this.state.showModal,
      title: title || undefined,
      typeDocument: typedocument || undefined,
      documentData,
      errors: {},
      readOnly: readOnly || false,
      dataUpdate: data ? data : false,
      submitting: false,
      disabledStatus: false,
      refUpload: refUpload || []
    });
  }

  calcultateAmountAP = (data, typedocument) => {
    const { amountContract, percentAmortization } = this.state.contractAccountBalance;
    const amountAP = (amountContract * percentAmortization / 100).toFixed(2);

    if (!data) {
      data = this.cleanDocumentData();
      if (typedocument === "ADVANCED_PAYMENT") return { ...data, amount: amountAP };

      return data
    }

    return data;
  }

  findDocument = (typedocument) => {
    const { contractAccountBalance: { documentsContractAccountBalance } } = this.state;
    return documentsContractAccountBalance.some(doc => (doc.type === typedocument && doc.status !== "CANCELLED"));
  }

  getSumAmounts = (id) => {
    const { contractAccountBalance: { documentsContractAccountBalance } } = this.state;
    const estimations = documentsContractAccountBalance.filter(doc => (doc.status !== "CANCELLED" && doc.type !== "DEDUCTIVE" && doc.id !== id));
    const deductives = documentsContractAccountBalance.filter(doc => (doc.status !== "CANCELLED" && doc.type === "DEDUCTIVE" && doc.id !== id));

    return {
      estimations: parseFloat(_.sumBy(estimations, "total").toFixed(2)),
      deductives: parseFloat(_.sumBy(deductives, "total").toFixed(2)),
    }
  }

  handleChange = (e, data) => {
    let { type, name, value } = data;
    let currentItem = Object.assign({}, this.state.documentData);
    let { contractAccountBalance : { percentAmortization, percentGuaranteeFund }, dataUpdate, disabledStatus, typeDocument } = this.state;

    if (!helper.validateValue(type, value)) return false;

    // textarea scape character
    if (name === "concept") {
      value = helper.cleaningData(value)
    }

    // Reset value currency´s
    if (name === "amount" || name === "amountAmortization" || name === "amountGuaranteeFund" || name === "total") {
      if (value === 0) value = ''
    }

    if (name === "amount") {
      if (typeDocument === "ESTIMATION") {
        const resultAmortization = +value * percentAmortization / 100;
        const resultGuaranteeFund = +value * percentGuaranteeFund / 100;
        const resutlTotal = +value - (resultAmortization + resultGuaranteeFund);
        currentItem["amountAmortization"] = resultAmortization > 0 ? parseFloat(resultAmortization).toFixed(2) : "";
        currentItem["amountGuaranteeFund"] = resultGuaranteeFund > 0 ? parseFloat(resultGuaranteeFund).toFixed(2) : "";
        currentItem["total"] = resutlTotal > 0 ? resutlTotal.toFixed(2) : "";
      }

      if (dataUpdate) {
        disabledStatus = dataUpdate.amount !== +value ? true : false;
      }
    }

    currentItem[name] = value;
    this.setState({ documentData: currentItem, disabledStatus });
  }

  handleOnSave = async () => {
    const { idContract, isOriginalContract, documentData, typeDocument, contractAccountBalance, refUpload } = this.state;
    let item = { idContract, ...documentData, type: typeDocument, currency: contractAccountBalance.currency, refUpload };

    if (this.handleValidator())
      return false;

    if (typeDocument === "ESTIMATION") {
      const dataAmounts = await this.handleEstimationDocumentAmounts(item, contractAccountBalance);

      if (typeof dataAmounts === "string")
        return alert(dataAmounts);

      item = { ...item, dataAmounts };
    } else {
      const dataAmounts = await this.handleGenericDocumentAmounts(item, contractAccountBalance, typeDocument);

      if (typeof dataAmounts === "string")
        return alert(dataAmounts);

      item = { ...item, dataAmounts };
    }

    this.setState({ submitting: true });

    try {
      if (isOriginalContract) {
        const data = await CREATE_DOCUMENT_CONTRACT_AB(item);
        const { type, status, elaborated: { name, email } } = data.documentsContractAccountBalance[0];
        if (status === "AUTHORIZED") await sendApproveDocumentAccountBalance({ type, name, email });
      } else {
        const data = await CREATE_DOCUMENT_CONTRACT_ADITIVE_AB(item);
        const { type, status, elaborated: { name, email } } = data.documentsContractAccountBalance[0];
        if (status === "AUTHORIZED") await sendApproveDocumentAccountBalance({ type, name, email });
      }
      this.fetchData();
      this.handleShowModal(null, {});
    } catch (error) {
      bugsnagClient.notify(error);
    }
  }

  handleEstimationDocumentAmounts = (item, contractAccountBalance) => {
    const { dataUpdate } = this.state;
    const alert1 = "El monto de amortización supera la cantidad amortizada.";
    const alert2 = "El monto fondo de garantía supera la cantidad retenida.";
    const alert3 = "El importe supera la cantidad que puede ser facturado/pagado.";
    const alert4 = "El importe supera la cantidad del total contratado";

    const msjEvent1 = `ha creado una nueva estimación no. ${item.invoiceNumber}`;
    const msjEvent2 = `ha actualizado el status de la estimación no. ${item.invoiceNumber} a ${helper.statusOptions(item.status)}`

    const {
      amountAmortization,
      amountByAmortization,
      amountTotalRetained,
      amountByRetained,
      amountBilled,
      amountByBilled,
      amountPaid,
      amountByPaid,
      amountDeductives,
      amountContractUpdate
    } = contractAccountBalance;

    if (dataUpdate) {
      if (dataUpdate.status === item.status) {
        if (dataUpdate.amount !== item.amount) {
          const rAmortization = (amountByAmortization + dataUpdate.amountAmortization) - +item.amountAmortization;
          const rGuaranteeFund = (amountByRetained + dataUpdate.amountGuaranteeFund) - +item.amountGuaranteeFund;
          const rAmountAmortization = (amountAmortization - dataUpdate.amountAmortization) + +item.amountAmortization;
          const rAmountTotalRetained = (amountTotalRetained - dataUpdate.amountGuaranteeFund) + +item.amountGuaranteeFund;

          if (rAmortization < 0) return alert1;
          if (rGuaranteeFund < 0) return alert2;
          if (rAmountAmortization < 0) return alert3;
          if (rAmountTotalRetained < 0) return alert4;

          return {
            resultAmortization: rAmortization,
            resultGuaranteeFund: rGuaranteeFund,
            resultBilled: amountByBilled,
            resultPaided: amountByPaid,
            amountAmortization: rAmountAmortization,
            amountTotalRetained: rAmountTotalRetained,
            amountBilled,
            amountPaid,
            amountDeductives,
            amountContractUpdate
          }
        }

        return {
          resultAmortization: amountByAmortization,
          resultGuaranteeFund: amountByRetained,
          resultBilled: amountByBilled,
          resultPaided: amountByPaid,
          amountAmortization,
          amountTotalRetained,
          amountBilled,
          amountPaid,
          amountDeductives,
          amountContractUpdate
        }
      }
    }

    const resultAmortization = amountByAmortization - +item.amountAmortization;
    const resultGuaranteeFund = amountByRetained - +item.amountGuaranteeFund;
    const resultBilled = amountByBilled - +item.total;
    const resultPaided = amountByPaid - +item.total;
    const { estimations } = this.getSumAmounts(dataUpdate.id);
    const sumAmountsDocuments = estimations + +item.total;

    if (!dataUpdate) {
      if (resultAmortization < 0) return alert1;
      if (resultGuaranteeFund < 0) return alert2;
      if (sumAmountsDocuments > amountContractUpdate || sumAmountsDocuments > amountContractUpdate) return alert3;
    }

    if (item.status === "CREATED") {
      return {
        resultAmortization,
        resultGuaranteeFund,
        resultBilled: amountByBilled,
        resultPaided: amountByPaid,
        amountAmortization: amountAmortization + +item.amountAmortization,
        amountTotalRetained: amountTotalRetained + +item.amountGuaranteeFund,
        amountBilled,
        amountPaid,
        amountDeductives,
        amountContractUpdate,
        elaboratedMsj: msjEvent1
      }
    }

    if (item.status === "ESTIMATED" || item.status === "AUTHORIZED") {
      return {
        resultAmortization: amountByAmortization,
        resultGuaranteeFund: amountByRetained,
        resultBilled: amountByBilled,
        resultPaided: amountByPaid,
        amountAmortization,
        amountTotalRetained,
        amountBilled,
        amountPaid,
        amountDeductives,
        amountContractUpdate,
        elaboratedMsj: msjEvent2
      }
    }

    if (item.status === "INVOICED") {
      return {
        resultAmortization: amountByAmortization,
        resultGuaranteeFund: amountByRetained,
        resultBilled,
        resultPaided: amountByPaid,
        amountAmortization,
        amountTotalRetained,
        amountBilled: amountBilled + +item.total,
        amountPaid,
        amountDeductives,
        amountContractUpdate,
        elaboratedMsj: msjEvent2
      }
    }

    if (item.status === "PAID_OUT") {
      return {
        resultAmortization: amountByAmortization,
        resultGuaranteeFund: amountByRetained,
        resultBilled: dataUpdate.status !== "AUTHORIZED" ? amountByBilled : resultBilled,
        resultPaided,
        amountAmortization,
        amountTotalRetained,
        amountBilled: dataUpdate.status !== "AUTHORIZED" ? amountBilled : (amountBilled + +item.total),
        amountPaid: amountPaid + +item.total,
        amountDeductives,
        amountContractUpdate,
        elaboratedMsj: msjEvent2
      }
    }

    if (item.status === "CANCELLED") {
      if (dataUpdate.status === "CREATED" || dataUpdate.status === "AUTHORIZED") {
        return {
          resultAmortization: amountByAmortization + +item.amountAmortization,
          resultGuaranteeFund: amountByRetained + +item.amountGuaranteeFund,
          resultBilled: amountByBilled,
          resultPaided: amountByPaid,
          amountAmortization: amountAmortization - +item.amountAmortization,
          amountTotalRetained: amountTotalRetained - +item.amountGuaranteeFund,
          amountBilled,
          amountPaid,
          amountDeductives,
          amountContractUpdate,
          elaboratedMsj: msjEvent2
        }
      }

      if (dataUpdate.status === "INVOICED") {
        return {
          resultAmortization: amountByAmortization + +item.amountAmortization,
          resultGuaranteeFund: amountByRetained + +item.amountGuaranteeFund,
          resultBilled: amountByBilled + +item.total,
          resultPaided: amountByPaid,
          amountAmortization: amountAmortization - +item.amountAmortization,
          amountTotalRetained: amountTotalRetained - +item.amountGuaranteeFund,
          amountBilled: amountBilled - +item.total,
          amountPaid,
          amountDeductives,
          amountContractUpdate,
          elaboratedMsj: msjEvent2
        }
      }

      if (dataUpdate.status === "PAID_OUT") {
        return {
          resultAmortization: amountByAmortization + +item.amountAmortization,
          resultGuaranteeFund: amountByRetained + +item.amountGuaranteeFund,
          resultBilled: amountByBilled + +item.total,
          resultPaided: amountByPaid + +item.total,
          amountAmortization: amountAmortization - +item.amountAmortization,
          amountTotalRetained: amountTotalRetained - +item.amountGuaranteeFund,
          amountBilled: amountBilled - +item.total,
          amountPaid: amountPaid - +item.total,
          amountDeductives,
          amountContractUpdate,
          elaboratedMsj: msjEvent2
        }
      }
    }
  }

  getMsj = (type, status = null) => {
    if (type === "DEDUCTIVE" && status === "CREATED") return "ha creado una nueva deductiva"
    if (type === "ADVANCED_PAYMENT" && status === "CREATED") return "ha creado un nuevo anticipo"
    if (type === "GUARANTEE_FUND" && status === "CREATED") return "ha creado un nuevo fondo de garantía"
    if (type === "DEDUCTIVE" && status !== "CREATED" && status !== "AUTHORIZED") return `ha cambiado el status de la deductiva a ${helper.statusOptions(status)}`
    if (type === "DEDUCTIVE" && status === "AUTHORIZED") return "ha autorizado la deductiva y su status ha cambiado a Deducido"
    if (type === "ADVANCED_PAYMENT" && status !== "CREATED") return `ha cambiado el status del anticipo a ${helper.statusOptions(status)}`
    if (type === "GUARANTEE_FUND" && status !== "CREATED") return `ha cambiado el status del fondo de garantía a ${helper.statusOptions(status)}`
  }

  handleGenericDocumentAmounts = (item, contractAccountBalance, typeDocument) => {
    const { dataUpdate } = this.state;
    const alert1 = "El importe supera la cantidad que puede ser deducida.";
    const alert2 = "El importe supera la cantidad que puede ser facturado/pagado.";
    const alert3 = "El importe supera la cantidad del total retenido.";

    const {
      amountAmortization,
      amountByAmortization,
      amountTotalRetained,
      amountByRetained,
      amountBilled,
      amountByBilled,
      amountPaid,
      amountByPaid,
      amountDeductives,
      amountContractUpdate,
      amountRetained
    } = contractAccountBalance;

    if (dataUpdate) {
      if (dataUpdate.status === item.status) {
        if (dataUpdate.amount !== item.amount) {
          const rAmountTotalRetained = (amountTotalRetained + dataUpdate.amount) - +item.amount;

          if (rAmountTotalRetained < 0) return alert3;

          return {
            resultAmortization: amountByAmortization,
            resultGuaranteeFund: amountByRetained,
            resultBilled: amountByBilled,
            resultPaided: amountByPaid,
            amountAmortization,
            amountTotalRetained: rAmountTotalRetained,
            amountBilled,
            amountPaid,
            amountDeductives,
            amountContractUpdate
          }
        }
        return {
          resultAmortization: amountByAmortization,
          resultGuaranteeFund: amountByRetained,
          resultBilled: amountByBilled,
          resultPaided: amountByPaid,
          amountAmortization,
          amountTotalRetained,
          amountBilled,
          amountPaid,
          amountDeductives,
          amountContractUpdate
        }
      }
    }

    const resultBilled = amountByBilled - +item.amount;
    const resultPaided = amountByPaid - +item.amount;
    const resultRetained = amountTotalRetained - +item.amount;
    const pushRetained = amountTotalRetained + +item.amount;
    const { estimations, deductives } = this.getSumAmounts(dataUpdate.id);
    const sumAmountsDocuments = typeDocument === "DEDUCTIVE" ? (estimations + deductives + +item.amount) : (estimations + deductives);

    if (!dataUpdate) {
      if (typeDocument === "GUARANTEE_FUND" && resultRetained < 0) return alert3;
      if (sumAmountsDocuments > amountContractUpdate) return typeDocument === "DEDUCTIVE" ? alert1 : alert2;
    }

    if ((item.status === "CREATED" || item.status === "ESTIMATED" || item.status === "AUTHORIZED") && typeDocument !== "DEDUCTIVE") {
      const statusChange = (item.status === "ESTIMATED" || item.status === "AUTHORIZED");
      return {
        resultAmortization: amountByAmortization,
        resultGuaranteeFund: amountByRetained,
        resultBilled: amountByBilled,
        resultPaided: amountByPaid,
        amountAmortization,
        amountTotalRetained: typeDocument === "GUARANTEE_FUND" && !statusChange ? resultRetained : amountTotalRetained,
        amountBilled,
        amountPaid,
        amountDeductives,
        amountContractUpdate,
        elaboratedMsj: this.getMsj(typeDocument, item.status)
      }
    }

    if (item.status === "CREATED" && typeDocument === "DEDUCTIVE")
    {
        return {
          resultAmortization: amountByAmortization,
          resultGuaranteeFund: amountByRetained,
          resultBilled: amountByBilled,
          resultPaided: amountByPaid,
          amountAmortization,
          amountTotalRetained,
          amountBilled,
          amountPaid,
          amountDeductives,
          amountContractUpdate,
          elaboratedMsj: this.getMsj(typeDocument, item.status)
        }
    }

    if (item.status === "AUTHORIZED" && typeDocument === "DEDUCTIVE") {
      return {
        resultAmortization: amountByAmortization,
        resultGuaranteeFund: amountByRetained,
        resultBilled: amountByBilled - +item.amount,
        resultPaided: amountByPaid - +item.amount,
        amountAmortization,
        amountTotalRetained,
        amountBilled,
        amountPaid,
        amountDeductives: amountDeductives + +item.amount,
        amountContractUpdate: amountContractUpdate - +item.amount,
        elaboratedMsj: this.getMsj(typeDocument, item.status)
      }
    }

    if (item.status === "INVOICED") {
      return {
        resultAmortization: amountByAmortization,
        resultGuaranteeFund: amountByRetained,
        resultBilled,
        resultPaided: amountByPaid,
        amountAmortization,
        amountTotalRetained,
        amountBilled: amountBilled + +item.amount,
        amountPaid,
        amountDeductives,
        amountContractUpdate,
        elaboratedMsj: this.getMsj(typeDocument, item.status)
      }
    }

    if (item.status === "PAID_OUT") {
      return {
        resultAmortization: amountByAmortization,
        resultGuaranteeFund: amountByRetained,
        resultBilled: dataUpdate.status !== "AUTHORIZED" ? amountByBilled : resultBilled,
        resultPaided,
        amountAmortization,
        amountTotalRetained,
        amountBilled: dataUpdate.status !== "AUTHORIZED" ? amountBilled : (amountBilled + +item.amount),
        amountPaid: amountPaid + +item.amount,
        amountDeductives,
        amountContractUpdate,
        elaboratedMsj: this.getMsj(typeDocument, item.status)
      }
    }

    if (item.status === "CANCELLED") {
      if ((dataUpdate.status === "CREATED" || dataUpdate.status === "DEDUCTED" || dataUpdate.status === "ESTIMATED") && typeDocument !== "DEDUCTIVE") {
        return {
          resultAmortization: amountByAmortization,
          resultGuaranteeFund: amountByRetained,
          resultBilled: amountByBilled,
          resultPaided: amountByPaid,
          amountAmortization,
          amountTotalRetained: typeDocument === "GUARANTEE_FUND" ? pushRetained : amountTotalRetained,
          amountBilled,
          amountPaid,
          amountDeductives,
          amountContractUpdate,
          elaboratedMsj: this.getMsj(typeDocument, item.status)
        }
      }

      if (dataUpdate.status === "CREATED" && typeDocument === "DEDUCTIVE") {
        return {
          resultAmortization: amountByAmortization,
          resultGuaranteeFund: amountByRetained,
          resultBilled: amountByBilled,
          resultPaided: amountByPaid,
          amountAmortization,
          amountTotalRetained,
          amountBilled,
          amountPaid,
          amountDeductives,
          amountContractUpdate,
          elaboratedMsj: this.getMsj(typeDocument, item.status)
        }
      }

      if (dataUpdate.status === "INVOICED") {
        return {
          resultAmortization: amountByAmortization,
          resultGuaranteeFund: amountByRetained,
          resultBilled: amountByBilled + +item.amount,
          resultPaided: amountByPaid,
          amountAmortization,
          amountTotalRetained: typeDocument === "GUARANTEE_FUND" ? pushRetained : amountTotalRetained,
          amountBilled: amountBilled - +item.amount,
          amountPaid,
          amountDeductives,
          amountContractUpdate,
          elaboratedMsj: this.getMsj(typeDocument, item.status)
        }
      }

      if (dataUpdate.status === "DEDUCTED") {
        return {
          resultAmortization: amountByAmortization,
          resultGuaranteeFund: amountByRetained,
          resultBilled: amountByBilled + +item.amount,
          resultPaided: amountByPaid + +item.amount,
          amountAmortization,
          amountTotalRetained,
          amountBilled,
          amountPaid,
          amountDeductives: amountDeductives - +item.amount,
          amountContractUpdate: amountContractUpdate + +item.amount,
          elaboratedMsj: this.getMsj(typeDocument, item.status)
        }
      }

      if (dataUpdate.status === "PAID_OUT") {
        return {
          resultAmortization: amountByAmortization,
          resultGuaranteeFund: amountByRetained,
          resultBilled: amountByBilled + +item.amount,
          resultPaided: amountByPaid + +item.amount,
          amountAmortization,
          amountTotalRetained: typeDocument === "GUARANTEE_FUND" ? pushRetained : amountTotalRetained,
          amountBilled: amountBilled - +item.amount,
          amountPaid: amountPaid - +item.amount,
          amountDeductives,
          amountContractUpdate,
          elaboratedMsj: this.getMsj(typeDocument, item.status)
        }
      }
    }
  }

  handleDeleteDocument = async (e, data) => {
    const { contractAccountBalance, isOriginalContract } = this.state;
    const item = { ...data, ...contractAccountBalance, idDocument: data.id };
    const { amountAmortization, amountByAmortization, amountTotalRetained, amountByRetained } = contractAccountBalance;

    try {
      if (data.type === "ESTIMATION") {
        item["amountAmortization"] = amountAmortization - data.amountAmortization;
        item["amountTotalRetained"] = amountTotalRetained - data.amountGuaranteeFund;
        item["amountByAmortization"] = amountByAmortization + data.amountAmortization;
        item["amountByRetained"] = amountByRetained + data.amountGuaranteeFund;
      } else if(data.type === "GUARANTEE_FUND") {
        item["amountTotalRetained"] = amountTotalRetained + data.amount;
      }

      if (isOriginalContract) {
        await DELETE_DOCUMENT_CONTRACT_ACCOUNT_BALANCE(item);
      } else {
        await DELETE_DOCUMENT_ADITIVE_ACCOUNT_BALANCE(item);
      }
      this.fetchData();
    } catch (err) {
      bugsnagClient.notify(err.message);
    }
  }

  handleValidator = () => {
    const { documentData, typeDocument } = this.state;
    if (typeDocument !== "ESTIMATION") {
      delete documentData.amountAmortization;
      delete documentData.amountGuaranteeFund;
      delete documentData.total;
    }
    delete documentData.invoiceNumber;
    const { errors, validator } = helper.validator({...documentData});
    this.setState({ errors });
    return Object.keys(errors).length === 0 ? true : validator;
  }

  onSelectFile = async (file, type) => {
    try {
      const { reference, extension } = await helper.uploadFile(file);
      const refUpload = [...this.state.refUpload, { type, reference, extension, new: true }];
      this.setState({ refUpload });
    } catch (error) {
      bugsnagClient.notify(error);
    }
  }

  onClickDownload = file => {
    window.open(file, "_blank");
  }

  deleteDocument = async (reference) => {
    const uploaded = this.state.refUpload.find(ref => ref.reference === reference);

    try {
      if (uploaded.hasOwnProperty("id")) {
        await DELETE_DOCUMENT_UPLOADED_AB(uploaded.id);
        this.fetchData();
      }

      const refUpload = this.state.refUpload.filter(ref => ref.reference !== reference);
      this.setState({ refUpload });
    } catch (error) {
      bugsnagClient.notify(error);
    }
  }

  render() {
    const { isOriginalContract, isLoading, readOnly, contractAccountBalance, typeDocument, documentData, errors, submitting, disabledStatus, dataUpdate, refUpload } = this.state;

    if (isLoading) {
      return <OnLoading loading={isLoading} error={null} />
    }

    /* Data core contract */
    const commercialName = !!contractAccountBalance.client ? contractAccountBalance.client.commercialName : contractAccountBalance.originalContract.client.commercialName;
    const name = !!contractAccountBalance.project ? contractAccountBalance.project.name : contractAccountBalance.originalContract.project.name;
    const cli = !!contractAccountBalance.project ? contractAccountBalance.project.cli : contractAccountBalance.originalContract.project.cli;


    /* Component Modal Document */
    let componentModal = null ;
    const dataContract = { ...contractAccountBalance, isOriginalContract, refUpload }
    if (typeDocument === "ESTIMATION") {
      componentModal = <EstimateFormAccountBalance
        handleChange={this.handleChange} {...dataContract}
        data={documentData}
        errors={errors}
        readOnly={readOnly}
        findDocument={this.findDocument}
        disabledStatus={disabledStatus}
        onSelectFile={this.onSelectFile}
        onClickDownload={this.onClickDownload}
        deleteDocument={this.deleteDocument}
      />;
    } else {
      componentModal = <GenericDocsFormAccountBalance
        handleChange={this.handleChange} {...dataContract}
        data={documentData}
        errors={errors}
        readOnly={readOnly}
        typeDocument={typeDocument}
        isUpdate={dataUpdate}
        disabledStatus={disabledStatus}
        onSelectFile={this.onSelectFile}
        onClickDownload={this.onClickDownload}
        deleteDocument={this.deleteDocument}
      />;
    }

    return(
      <React.Fragment>
        <Form className="Modal__Form">
          <Form.Group widths={16} className="Modal__ApprovalDocument-Flex-Center">
            <Form.Input
              readOnly
              width="4"
              label="CLIENTE"
              value={commercialName}/>
            <Form.Input
              readOnly
              width="4"
              label="NOMBRE DEL PROYECTO"
              value={name}/>
            <Form.Input
              readOnly
              width="4"
              label="PRESUPUESTO CLI"
              value={cli}/>
          </Form.Group>

          <Divider className="Divider"/>

          <Grid columns="equal" divided>
            <Grid.Row>
              <Grid.Column>
                <h5>Amortización Anticipo</h5>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>{`ANTICIPO ${contractAccountBalance.percentAmortization}%`}</label>
                  <CurrencyInput value={contractAccountBalance.amountAdvancePayment}/>
                </Form.Field>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>AMORTIZADO</label>
                  <CurrencyInput value={contractAccountBalance.amountAmortization}/>
                </Form.Field>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>POR AMORTIZAR</label>
                  <CurrencyInput value={contractAccountBalance.amountByAmortization}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <h5>Retención Fondo Garantía</h5>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>{`RETENIDO ${contractAccountBalance.percentGuaranteeFund}%`}</label>
                  <CurrencyInput value={contractAccountBalance.amountRetained}/>
                </Form.Field>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>TOTAL RETENIDO</label>
                  <CurrencyInput value={contractAccountBalance.amountTotalRetained}/>
                </Form.Field>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>POR RETENER</label>
                  <CurrencyInput value={contractAccountBalance.amountByRetained}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <h5>Total Contratado</h5>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>ORIGINAL</label>
                  <CurrencyInput value={contractAccountBalance.amountContract}/>
                </Form.Field>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>DEDUCTIVAS</label>
                  <CurrencyInput value={contractAccountBalance.amountDeductives}/>
                </Form.Field>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>ACTUAL</label>
                  <CurrencyInput value={contractAccountBalance.amountContractUpdate}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <h5>Facturación</h5>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>FACTURADO</label>
                  <CurrencyInput value={contractAccountBalance.amountBilled}/>
                </Form.Field>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>RESTANTE</label>
                  <CurrencyInput value={contractAccountBalance.amountByBilled}/>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <h5>Estimación</h5>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>ESTIMADO</label>
                  <CurrencyInput value={contractAccountBalance.amountPaid}/>
                </Form.Field>
                <Form.Field disabled width="16" className="Util-MaskCurrency">
                  <label>POR ESTIMAR</label>
                  <CurrencyInput value={contractAccountBalance.amountByPaid}/>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <AccountBalanceDocumentsQuery
            cli={cli}
            contractAccountBalance={contractAccountBalance}
            handleDeleteDocument={this.handleDeleteDocument}
            handleShowModal={this.handleShowModal}
          />

          { !readOnly &&
            <Grid>
              <Grid.Row textAlign="center" className="Modal__SubTabNav-Row-Align">
                <Grid.Column width="3">
                  <Button
                    typedocument="ESTIMATION"
                    title = "Nueva estimación"
                    type="button"
                    className="Modal__Form-ButtonAdd"
                    icon="plus"
                    content="Añadir estimación"
                    onClick={this.handleShowModal}/>
                </Grid.Column>
                <Grid.Column width="3">
                  <Button
                    typedocument="DEDUCTIVE"
                    title = "Nueva deductiva"
                    type="button"
                    className="Modal__Form-ButtonAdd"
                    icon="plus"
                    content="Añadir deductiva"
                    onClick={this.handleShowModal}/>
                </Grid.Column>
                <Grid.Column width="3">
                  <Button
                    typedocument="ADVANCED_PAYMENT"
                    title = "Nuevo anticipo"
                    type="button"
                    className="Modal__Form-ButtonAdd"
                    icon="plus"
                    content="Añadir anticipo"
                    disabled={this.findDocument("ADVANCED_PAYMENT")}
                    onClick={this.handleShowModal}/>
                </Grid.Column>
                <Grid.Column width="4">
                  <Button
                    typedocument="GUARANTEE_FUND"
                    title = "Nuevo fondo de garantía"
                    type="button"
                    className="Modal__Form-ButtonAdd"
                    icon="plus"
                    content="Añadir fondo de garantía"
                    disabled={contractAccountBalance.amountTotalRetained === 0}
                    onClick={this.handleShowModal}/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
        </Form>

        <Modal
          className="Modal__Form-SubModal"
          centered={true}
          open={this.state.showModal}
          header={
            <ModalHeader
              component={false}
              title={this.state.title}
              save={this.handleOnSave}
              cancel={this.handleShowModal}
              cancelbutton={true}
              disabled={submitting}
              readOnly={readOnly}
            />
          }
          content={componentModal}
          size="large"
          dimmer="blurring"/>
      </React.Fragment>
    )
  }
}

export default AccountBalanceCurrencyForm;
