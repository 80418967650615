import React from "react";
import { Modal, Form, Input, Select, TextArea, Button, Grid } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { client } from "../../../../../graphql/apollo-config";
import FormHeader from "../../../../Molecules/formHeader";
import { bugsnagClient } from "../../../../../bugsnag";
import { format } from "../../../../../helpers";
import { ViewDocumentSegment } from "../../../../Molecules";
import CreateInvoice from "../../../../../graphql/mutations/inventories/createInovice"

const paymentConditions = [
  { key: "CREDIT", text: "Crédito", value: "CREDIT" },
  { key: "CASH", text: "Contado", value: "CASH" }
];

export default class AddInvoice extends React.Component {
  state = {
    user: JSON.parse(window.localStorage.getItem("session"))
      ? JSON.parse(window.localStorage.getItem("session")).user
      : "",
    poId: "",
    invoiceNumber: "",
    receptionDate: "",
    paymentConditions: "",
    paymentDate: "",
    notes: "",
    contentFile: [
      { icon: "plus", content: "Agregar archivos" },
      { icon: "cloud upload", content: "Cargando archivo" }
    ],
    statusFile: { BILL: 0 },
    inputKeyFile: Date.now(),
    refUpload: []
  };

  componentDidMount() {
    this.setState({ poId: this.props.poId });
  }

  handleChange = (e, { name, value }) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  };

  handleUploapFile = async (e) => {
    const file = e.target.files[0];
    const [typeDoc, typeTotal] = e.target.name.split(",");
    const form = new FormData();

    if (e.target.files.length > 0) {
      if (!e.target.accept.includes(file.type))
        return alert("Solo se permiten archivos con extensión PDF");

      form.append("file", file);

      this.setState(prevState => ({
        ...prevState,
        statusFile: {
          [typeDoc]: 1
        }
      }));

      try {
        const res = await fetch(process.env.REACT_APP_S3, {
          method: "POST",
          body: form,
        });

        if (res.ok) {
          const uploaded = await res.json();

          const data = {
            reference: uploaded.imageUrl,
            extension: format.getExtesion(uploaded.imageUrl),
            userUpload: this.state.user.id,
            createDate: new Date().toISOString()
          };

          this.setState(prevState => ({
            ...prevState,
            inputKeyFile: Date.now(),
            statusFile: {
              [typeDoc]: 0
            },
            refUpload: [
              ...prevState.refUpload,
              data
            ]
          }));
        }
      } catch (err) {
        bugsnagClient.notify(err);
      }
    }
  }

  save = async () => {
    const { paymentConditions, refUpload } = this.state

    if (Object.keys(this.state).some((stateKey) => !this.state[stateKey])) {
      return alert("Por favor, rellena todos los campos.");
    }

    try {
      await client.mutate({
        variables: {
          poId: this.state.poId,
          invoiceNumber: this.state.invoiceNumber,
          receptionDate: this.state.receptionDate,
          paymentDate: this.state.paymentDate,
          notes: this.state.notes
        },
        mutation: CreateInvoice({ paymentConditions, refUpload }),
      }).then(() => {
        this.props.onClose();
        this.props.refetch();
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  handleDownloadFile = (file) => {
    window.open(file, "_blank");
  }

  handleRemoveInvoice = async ({ reference }) => {
    const { refUpload } = this.state
    const filter = refUpload.filter(item => item.reference !== reference)

    this.setState(prevState => ({
      ...prevState,
      refUpload: filter
    }))
  }

  render() {
    let fileInput = null;
    const { contentFile, statusFile } = this.state;

    return (
      <Modal
        className="Modal__Form-SubModal"
        size="tiny"
        centered={false}
        open={this.props.open}
      >
        <FormHeader
          text="Nueva factura"
          onSave={this.save}
          onCancel={this.props.onClose}
        />
        <Modal.Content>
          <Form>
            <Form.Field
              name="invoiceNumber"
              label="NÚMERO DE FACTURA"
              control={Input}
              onChange={this.handleChange}
              value={this.state.invoiceNumber}
            />
            <Form.Field
              name="receptionDate"
              label="FECHA DE RECEPCIÓN DE FACTURA"
              className="Util-DatePicker"
              control={SemanticDatepicker}
              type="basic"
              placeholder="Fecha de recepción de factura"
              onDateChange={date =>
                this.handleChange(null, {
                  name: "receptionDate",
                  value: date.toISOString(),
                })
              }
              selected={this.state.receptionDate && new Date(this.state.receptionDate)}
            />
            <Form.Field
              name="paymentConditions"
              label="CONDICIONES DE PAGO"
              placeholder="Condiciones de pago"
              control={Select}
              style={{ border: "1px solid #dddddd", height: "32px" }}
              options={paymentConditions}
              onChange={this.handleChange}
              value={this.state.paymentConditions}
            />
            <Form.Field
              label="FECHA DE PAGO"
              name="paymentDate"
              className="Util-DatePicker"
              control={SemanticDatepicker}
              type="basic"
              placeholder="Fecha de pago"
              onDateChange={date =>
                this.handleChange(null, {
                  name: "paymentDate",
                  value: date.toISOString(),
                })
              }
              selected={this.state.paymentDate && new Date(this.state.paymentDate)}
            />
            <Form.Field
              control={TextArea}
              label="NOTAS"
              rows="5"
              name="notes"
              value={this.state.notes}
              onChange={this.handleChange}
            />

            {this.state.refUpload.length > 0 &&
              <Grid.Row>
                {this.state.refUpload.map(upload => {
                  return (
                    <Grid.Column className="Modal__Form-SubModal-Form-Column-MB" width="2" key={upload.reference}>
                      <ViewDocumentSegment
                        title={format.getNameOriginal(upload.reference)}
                        reference={upload.reference}
                        onDownloadFile={this.handleDownloadFile}
                        uploadDocument={true}
                        readOnly={true}
                        removeItem={this.handleRemoveInvoice}
                        isContractor={false}
                      />
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            }

            <Form.Field style={{ textAlign: "center" }}>
              <Button
                basic content={contentFile[statusFile.BILL].content}
                icon={contentFile[statusFile.BILL].icon} className="Modal__Form-SubModal-Form-ButtonAddOutline"
                onClick={() => fileInput.inputRef.click()}
              />
              <Input
                type="file"
                name={["BILL", "totalInvoiced"]}
                key={this.state.inputKeyFile}
                hidden
                ref={element => { fileInput = element }}
                onChange={this.handleUploapFile}
                style={{ display: "none" }}
                accept="application/pdf"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
