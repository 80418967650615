import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import PropTypes from 'prop-types';
import { TabNavigation } from "../../Molecules";

class TabContainer extends Component {
  state = {
    currentTabName: this.props.active,
  };

  // map child components, return link array
  links =  React.Children.map(this.props.children, child => {
    const { name, text } = child.props;
    return { name, text }
  });

  changeComponent = (currentTabName) => {
    this.setState({
      currentTabName,
    });
  }

  render() {
    // find the current child component to render
    const currentComponent = React.Children.map(this.props.children, child => {
      if (child.props.name === this.state.currentTabName) {
        return child
      }
    });

    // render tab navigation componenet
    const tabNavigation = <TabNavigation
      type="secondary"
      position="center"
      active={this.props.active}
      links={this.links}
      onChange={this.changeComponent}
    />

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            {tabNavigation}
          </Grid.Column>
        </Grid.Row>
        {currentComponent}
      </Grid>
    );
  }
}

TabContainer.propTypes = {
  active: PropTypes.string.isRequired,
}

export default TabContainer;
