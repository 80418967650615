import React, { Component } from "react";
import "./style.css";

export default class Label extends Component {
  render() {
    return (
      <span fontSize={this.props.fontSize} className={this.props.className}>
        {this.props.text}
      </span>
    );
  }
}
