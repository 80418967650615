import React from "react";
import gql from "graphql-tag";
import { Modal, Form, Input, TextArea } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import { client } from "../../../../../graphql/apollo-config";
import FormHeader from "../../../../Molecules/formHeader";

const MUTATION = gql`
  mutation(
    $poId: ID!,
    $toCreate: [ReceivedSupplyCreateInput!],
    $idsToUpdate: [ID!],
    $toUpdate: ReceivedSupplyUpdateManyDataInput!
  ) {
    updatePurchaseOrder(
      where: { id: $poId }
      data: {
        data: {
          update: {
            receivedSupplies: {
              updateMany: {
                where: {
                  id_in: $idsToUpdate,
                }
                data: $toUpdate
              }
              create: $toCreate
            }
          }
        }
      }
    ) {
      id
    }
  }
`;

export default class BulkReception extends React.Component {
  state = {
    poId: null,
    data: [],
    ids: null,
    idsToUpdate: [],
    toUpdate: {},
    toCreate: [],
  };

  componentDidMount() {
    this.setState({ data: this.props.data, poId: this.props.poId });
  }

  isValidDate = date => {
    return date instanceof Date && !isNaN(date);
  }

  handleChange = (e, { name, value }) => {
    const data = this.state.data;

    if (name === "shippingDate" || name === "deliveryDate") {
      if (value === null) {
        if (data.length === 1) {
          if (data[0].item[name] === null) {
            return;
          }
        }
      } else {
        value = moment(value);
      }
    }

    data.map((i, index) => {
      i["item"][name] = value;
    });

    return this.setState({ data });
  };

  getReceived = () => {
    const idsToUpdate = this.state.idsToUpdate;
    const toUpdate = this.state.toUpdate;
    const toCreate = this.state.toCreate;
    const data = this.state.data;
    data.map(({ item }) => {
      item["shippingDate"] = moment(item["shippingDate"])
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      item["deliveryDate"] = moment(item["deliveryDate"])
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      if (item["notes"]) {
        item["notes"] = escape(item["notes"]);
      }

      if (item.type === "order") {
        const { id: originId, type, ...newItem } = item;
        toCreate.push({
          ...newItem,
          originId
        });
      } else {
        idsToUpdate.push(item.id);
        toUpdate["shippingCompany"] = item.shippingCompany;
        toUpdate["trackingNumber"] = item.trackingNumber;
        toUpdate["deliveryDate"] = item.deliveryDate;
        toUpdate["shippingDate"] = item.shippingDate;
        toUpdate["notes"] = item.notes;
      }
    });
    this.setState({ idsToUpdate, toUpdate, toCreate });
  };

  save = async () => {
    const emptyDeliveryDates = this.state.data.some(el => !el.item.deliveryDate || el.item.deliveryDate === null);
    const emptyShippingDates = this.state.data.some(el => !el.item.shippingDate || el.item.shippingDate === null);

    if (emptyDeliveryDates || emptyShippingDates) {
      return alert("Especifica las fechas.");
    }

    try {
      this.getReceived();
      await client.mutate({
        variables: {
          poId: this.state.poId,
          toCreate: this.state.toCreate,
          idsToUpdate: this.state.idsToUpdate,
          toUpdate: this.state.toUpdate
        },
        mutation: MUTATION,
      }).then(res => {
        this.setState({
          poId: null,
          data: [],
          ids: null,
          idsToUpdate: [],
          toUpdate: {},
          toCreate: [],
        });
      });
      this.props.onClose();
    } catch (error) {
      throw new Error(error);
    }
  };

  getSelectedDate = field => {
    if (
      this.state.data.length === 1 &&
      this.state.data[0].item[field] !== null &&
      this.isValidDate(new Date(this.state.data[0].item[field]))
    ) {
      return new Date(this.state.data[0].item[field]);
    }
  }

  render() {
    return (
      <Modal
        className="Modal__Form-SubModal"
        size="tiny"
        centered={false}
        open={this.props.open}
      >
        <FormHeader
          text="Datos de recepción y almacenamiento"
          onSave={this.save}
          onCancel={this.props.onClose}
        />
        <Modal.Content>
          <Form>
            <Form.Field
              name="shippingCompany"
              label="EMPRESA DE PAQUETERIA"
              control={Input}
              onChange={this.handleChange}
              value={this.state.data.length === 1 ? this.state.data[0].item.shippingCompany : undefined}
            />
            <Form.Field
              name="trackingNumber"
              label="NÚMERO DE GUÍA"
              control={Input}
              onChange={this.handleChange}
              value={this.state.data.length === 1 ? this.state.data[0].item.trackingNumber : undefined}
            />
            <Form.Field
              name="shippingDate"
              label="FECHA DE ENVÍO ESPERADA"
              className="Util-DatePicker"
              control={SemanticDatepicker}
              type="basic"
              placeholder="Fecha de envío"
              onDateChange={date =>
                this.handleChange(null, {
                  name: "shippingDate",
                  value: date,
                })
              }
              selected={this.getSelectedDate("shippingDate")}
            />
            <Form.Field
              label="FECHA DE ENTREGA ESPERADA"
              name="deliveryDate"
              className="Util-DatePicker"
              control={SemanticDatepicker}
              type="basic"
              placeholder="Fecha de entrega"
              onDateChange={date =>
                this.handleChange(null, {
                  name: "deliveryDate",
                  value: date,
                })
              }
              selected={this.getSelectedDate("deliveryDate")}
            />
            <Form.Field
              name="notes"
              label="NOTAS"
              control={TextArea}
              onChange={this.handleChange}
              value={this.state.data.length === 1 ? this.state.data[0].item.notes && unescape(this.state.data[0].item.notes) : undefined}
            />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
