import React from "react";
import SidebarItem from "./sidebarITem";
import { FaClipboard, FaChartLine } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";

export default props => {
  return (
    <React.Fragment>
      <SidebarItem
        {...props}
        name="accountBalance"
        to="/estado-de-cuenta"
        text="Estado de cuenta"
        icon={<FaClipboard />}
      />
      <SidebarItem
        {...props}
        name="projectProgress"
        text="Avance de proyectos"
        to="/avance-de-proyecto"
        icon={<FaChartLine />}
      />
    </React.Fragment>
  );
};
