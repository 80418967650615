import gql from "graphql-tag";

const getVendor = gql`
  query($id: ID!) {
    vendor(where: { id: $id }) {
      id
      commercialName
      supplies {
        id
        key
        name
        description
        purchasePrice
        units
      }
    }
  }
`;
export default getVendor;
