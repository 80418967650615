import gql from "graphql-tag";

const getVendors = gql`
  query getVendors(
    $first: Int,
    $skip: Int,
    $person: PersonType,
    $orderBy: VendorOrderByInput
    $search: String
    $searchUpper: String
    $searchLower: String
    $searchCaps: String
  ) {
    vendors(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: {
        personType: $person
        OR: [
          { commercialName_contains: $search }
          { commercialName_contains: $searchUpper }
          { commercialName_contains: $searchLower }
          { commercialName_contains: $searchCaps }
          { state_contains: $search }
          { state_contains: $searchUpper }
          { state_contains: $searchLower }
          { state_contains: $searchCaps }
          { contacts_some: { name_contains: $search } }
          { contacts_some: { email_contains: $search } }
          { contacts_some: { phone_contains: $search } }
          { contacts_some: { name_contains: $searchUpper } }
          { contacts_some: { email_contains: $searchUpper } }
          { contacts_some: { phone_contains: $searchUpper } }
          { contacts_some: { name_contains: $searchLower } }
          { contacts_some: { email_contains: $searchLower } }
          { contacts_some: { phone_contains: $searchLower } }
          { contacts_some: { name_contains: $searchCaps } }
          { contacts_some: { email_contains: $searchCaps } }
          { contacts_some: { phone_contains: $searchCaps } }
        ]
      }
    ) {
      count
      city
      colony
      commercialName
      country
      id
      iva
      number
      personType
      postalCode
      razonSocial
      rfc
      state
      street
      count
      contacts {
        name
        email
        phone
        phone_office
      }
    }
  }
`;

export default getVendors;
