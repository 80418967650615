import gql from "graphql-tag";
import { client } from "../../apollo-config";

const createProject = async ({
  name,
  cliId,
  cliName,
  cliBudget,
  startDate,
  finishDate,
  status,
  address,
  connectUsers
}) => {
  const users = connectUsers.map(id => ({ id }))

  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        createProject(
          data: {
            name: "${name}"
            cli: "${cliBudget}"
            client: {
              connect: {
                id: "${cliId}"
              }
            }
            startDate: "${startDate}"
            finishDate: "${finishDate}"
            wasUpdatedAt: "${startDate}"
            status: ${status}
            address: "${address}"
            amount: 0
            users: {
              connect: [${users.map(user => {
                const str = JSON.stringify(user);
                var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                return obj;
              })}]
            }
          }
        ) {
          id
        }
      }
    `,
  });
  return mutation;
};

export default createProject;
