import React from "react";
import { Container, Table, Dimmer, Loader } from "semantic-ui-react";

export const climasTemplate = function(props, dataTable) {
  this.renderHeaders = () => {
    return dataTable.headers.map((header, index) => {
      return (
        <Table.HeaderCell
          key={index}
          sorted={header.sorted}
          onClick={() => dataTable.onHeaderClick(header)}>
          {header.label}
        </Table.HeaderCell>
      );
    });
  };

  this.renderRow = (rowData, index) => {
    return (
      <Table.Row key={index} onClick={() => dataTable.onRowClick(rowData)}>
        { rowData.map((columnData, idx) => {
          return this.renderColumn(columnData, idx);
        }) }
      </Table.Row>
    );
  };

  this.renderColumn = (columnData, idx) => {
    return <Table.Cell key={`${idx}-${columnData.content}`}>{columnData.content}</Table.Cell>;
  };

  this.renderTable = data => {
    const { rows } = data;
    return (
      <Container Container fluid textAlign="center">
        {rows.length ? (
            <Table
              basic="very"
              compact
              textAlign="center"
              sortable
              className="Wrapper__Body-Table">
              <Table.Header className="Wrapper__Body-Table-Header Small Caps">
                <Table.Row>
                  {this.renderHeaders()}
                </Table.Row>
              </Table.Header>
              <Table.Body className="Wrapper__Body-Table-Body Small Caps">
                {data.rows.map((rowData, index) => {
                  return this.renderRow(rowData, index);
                })}
              </Table.Body>
            </Table>
        ) : "No se encontraron resultados para la búsqueda"
        }
      </Container>
    )
  };

  this.renderError = error => {
    return (
      <Container fluid className="Wrapper__Body-Loader">
        <Dimmer active inverted>
          <Loader testid="loading-wrapper" inverted content="Error" />
        </Dimmer>
      </Container>
    );
  };

  this.renderLoading = () => {
    return (
      <Container fluid className="Wrapper__Body-Loader">
        <Dimmer active inverted>
          <Loader testid="loading-wrapper" inverted content="Cargando..." />
        </Dimmer>
      </Container>
    );
  };

  return this;
};
