import React, { Component } from "react";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import _ from "lodash";
import { PROJECT_TASKS } from "../../../../../graphql/queries/projectProgress";
import {
  CREATE_TASK,
  EDIT_TASK,
  DELETE_TASK,
  UPDATE_TASK,
} from "../../../../../graphql/mutations/projectProgress";
import { Dropdown } from "semantic-ui-react";

export default class Gantt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: { data: [] },
      links: [],
      messages: [],
      currentZoom: "Days",
      currentText: null,
      zoomType: "day",
      columns: {
        all: [
          { name: "text", label: "Nombre de tarea", width: "*", tree: true },
          { name: "start_date", label: "Fecha de inicio", align: "center" },
          { name: "duration", label: "Duración", align: "center" },
          { name: "add", label: "", width: 44 },
        ],
        disabled: [
          { name: "text", label: "Nombre de tarea", width: "*", tree: true },
          { name: "start_date", label: "Fecha de inicio", align: "center" },
          { name: "duration", label: "Duración", align: "center" },
        ],
      },
      userRole: JSON.parse(window.localStorage.getItem("session")).user.userRol,
    };
  }
  // instance of gantt.dataProcessor
  dataProcessor = null;

  // Init gantt chart
  initGanttDataProcessor() {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    const onDataUpdated = this.props.onDataUpdated;
    this.dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      return new Promise((resolve, reject) => {
        if (onDataUpdated) {
          onDataUpdated(type, action, item, id);
        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        // resolve({id: databaseId});
        return resolve();
      });
    });
  }

  shouldComponentUpdate(nextProps) {
    return this.props.zoom !== nextProps.zoom;
  }

  // re-render gantt on every update
  componentDidUpdate() {
    gantt.render();
  }

  // Query project task and load them into the gantt
  async componentDidMount() {
    const result = await PROJECT_TASKS(window.location.pathname.split("e/")[1]);
    const tasks_result = result !== null ? result.data.project.tasks : [];
    const links_result = result !== null ? result.data.project.links : [];
    const _tasks = { data: [] };
    const _links = [];

    // map tasks to an object to be loaded in gantt
    tasks_result.map((task, index) => {
      return _tasks.data.push({
        id: task.index,
        text: task.text,
        start_date: task.start_date.split("T")[0],
        duration: task.duration,
        parent: parseInt(task.parent),
        progress: task.progress,
        taskID: task.id,
        color:
          parseInt(task.parent) === 0 || parseInt(task.parent) === 1
            ? "#c33b44"
            : "#295896",
      });
    });

    // map tasks links
    links_result.map(link => {
      return _links.push({
        id: link.index,
        source: link.source,
        target: link.target,
        type: link.type,
      });
    });
    this.setState(data => ({ ...data, tasks: _tasks, links: _links }));
    gantt.config.xml_date = "%Y-%m-%d %H:%i";

    let { tasks, links } = this.state;
    gantt.config.work_time = true; // removes non-working time from calculations
    gantt.config.skip_off_time = true; // hides non-working time in the chart

    // handle columns definition by user role
    const { userRole, columns } = this.state;

    gantt.config.columns = columns.all;

    gantt.config.open_tree_initially = true;

    // Set gantt as read only according to role
    gantt.config.readonly =
      userRole !== "ACCOUNT_BALANCE_RESPONSIBLE" ? false : true;

    gantt.init(this.ganttContainer);

    gantt.config.scale_unit = this.state.zoomType;
    gantt.config.date_scale = "%d %M, %D";
    gantt.templates.scale_cell_class = function(date) {
      if (date.getDay() === 0 || date.getDay() === 6) {
        return "weekend";
      }
    };

    if (tasks.data.length > 0) {
      this.initGanttDataProcessor();
      const _data = { data: tasks.data, links: links };
      gantt.parse(_data);
    }
  }

  // Stop gantt data processor on unmount
  componentWillUnmount() {
    if (this.dataProcessor) {
      this.dataProcessor.destructor();
      this.dataProcessor = null;
    }
  }

  // handle styles and language of add task modal
  styleGanttModal = () => {
    gantt.attachEvent("onLightbox", function(task_id) {
      //any custom logic here
      const textLabel = document.getElementsByClassName(
        "gantt_grid_head_cell gantt_grid_head_text  "
      )[0];
      const descLabel = document.getElementsByClassName(
        "gantt_cal_lsection"
      )[0];
      const periodLabel = document.getElementsByClassName(
        "gantt_cal_lsection"
      )[1];
      const saveBtn = document.getElementsByClassName(
        "gantt_btn_set gantt_left_btn_set gantt_save_btn_set"
      )[0];
      const cancelBtn = document.getElementsByClassName(
        "gantt_btn_set gantt_left_btn_set gantt_cancel_btn_set"
      )[0];
      const deleteBtn = document.getElementsByClassName(
        "gantt_btn_set gantt_left_btn_set gantt_delete_btn_set"
      )[0];
      const openModalBtn = document.getElementsByClassName(
        "gantt_grid_head_cell gantt_grid_head_add gantt_last_cell "
      )[0];

      const addTaskModalTimeSelector = document.getElementsByClassName(
        "gantt_cal_larea"
      )[0];
      const addTaskModal = document.getElementsByClassName(
        "gantt_cal_light"
      )[0];

      // Set classes
      const timeSelector = document.getElementsByClassName(
        "gantt_time_selects"
      )[0].children;

      // Set texts
      descLabel.innerHTML = "Descripción";
      periodLabel.innerHTML = "Periodo";
      saveBtn.innerHTML = `<div dhx_button="1" data-dhx-button="1" class="gantt_save_btn"></div><div>Guardar</div>`;
      cancelBtn.innerHTML = `<div dhx_button="1" data-dhx-button="1" class="gantt_cancel_btn"></div><div>Cancelar</div></div>`;
      deleteBtn.innerHTML = `<div dhx_button="1" data-dhx-button="1" class="gantt_delete_btn"></div><div>Eliminar</div>`;
    });
  };

  // Catch add task event to write data to DB
  addTask = () => {
    gantt.attachEvent("onLightbox", function(task_id) {
      const parent = gantt.getParent(task_id); // get the parent id if any
      const tasks = gantt.getTaskByTime(); //returns all tasks as an array of objects
      tasks.pop(); // remove last index which would be a meaningless timestamp
      const lastId = _.last(tasks) ? _.last(tasks).id + 1 : 1; // generate a new id
      gantt.attachEvent("onAfterTaskAdd", async function(id, item) {
        const newTask = await CREATE_TASK({
          id: window.location.pathname.split("e/")[1],
          index: parseInt(lastId) + 1,
          text: item.text,
          start_date: new Date(item.start_date).toISOString(),
          duration: item.duration,
          parent: parent,
        });
        if (newTask) {
          window.location.reload();
        }
      });
    });
  };

  deleteTask = () => {
    gantt.attachEvent("onBeforeTaskDelete", function(id, item) {
      const text = gantt.getTask(id).text;
      const children = gantt.getChildren(id); //->["t_1", "t_2"]
      if (children.length === 0) {
        gantt.attachEvent("onAfterTaskDelete", async function(id, item) {
          await DELETE_TASK(
            window.location.pathname.split("e/")[1],
            item.taskID
          );
          window.location.reload();
        });
      } else {
        alert("Esta tarea contiene sub-tareas");
        return false;
      }
    });
  };

  editTask = () => {
    gantt.attachEvent("onLightbox", function(task_id) {
      const text = gantt.getTask(task_id).text;
      const taskID = gantt.getTask(task_id).taskID;
      gantt.attachEvent("onAfterTaskUpdate", async function(id, item) {
        await EDIT_TASK({
          id: window.location.pathname.split("e/")[1],
          index: item.id,
          text: text,
          new_text: item.text,
          start_date: new Date(item.start_date).toISOString(),
          duration: item.duration,
          parent: item.parent,
          progress: item.progress,
          taskID: taskID,
        });
      });
    });
  };

  // Tasks was moved across the
  taskWasMoved = () => {
    // prevent moving to another sub-branch
    gantt.attachEvent("onAfterTaskUpdate", async function(id, item) {
      const taskID = gantt.getTask(id).taskID;
      //any custom logic here
      await EDIT_TASK({
        id: window.location.pathname.split("e/")[1],
        text: item.text,
        index: id,
        new_text: item.text,
        start_date: new Date(item.start_date).toISOString(),
        duration: item.duration,
        parent: item.parent,
        progress: item.progress,
        taskID: taskID,
      });
    });
  };

  taskWasDragged = () => {
    gantt.attachEvent("onAfterTaskDrag", async function(id, mode, e) {
      const task = gantt.getTask(id);
      await UPDATE_TASK(
        window.location.pathname.split("e/")[1],
        task.taskID,
        new Date(task.start_date).toISOString()
      );
    });
  };

  handleZoomChange = (e, { value }) => {
    this.setState({ zoomType: value });
    gantt.config.scale_unit = value;
    gantt.config.date_scale = "%d %M, %D";
    gantt.templates.scale_cell_class = function(date) {
      if (date.getDay() === 0 || date.getDay() === 6) {
        return "weekend";
      }
    };
    gantt.render();
  };

  render() {
    const { zoom } = this.props;
    this.addTask();
    this.editTask();
    this.deleteTask();
    this.taskWasDragged();
    this.taskWasMoved();
    this.styleGanttModal();

    return (
      <div>
        <div style={{ textAlign: "right" }}>
          <span>Mostrar por: </span>{" "}
          <Dropdown
            selection
            defaultValue="day"
            options={[
              { key: 0, text: "Días", value: "day" },
              { key: 1, text: "Semanas", value: "week" },
              { key: 2, text: "Meses", value: "month" },
              { key: 3, text: "Años", value: "year" },
            ]}
            onChange={this.handleZoomChange}
          />
        </div>
        <div
          ref={input => {
            this.ganttContainer = input;
          }}
          className="gantt-container"
        />
      </div>
    );
  }
}
