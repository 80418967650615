import { IoMdCreate } from "react-icons/io";
import { FaDollarSign } from "react-icons/fa";
import {
  ClientListTemplate,
  ObrasListTemplate
} from "../../../Templates/ClientsTemplate/templates";

export default [
  {
    icon: IoMdCreate,
    item: ClientListTemplate,
    routeName: "client",
    text: "Clientes"
  },
  {
    icon: FaDollarSign,
    item: ObrasListTemplate,
    routeName: "projects",
    text: "Proyectos"
  }
];
