import gql from "graphql-tag";
import { client as apollo } from "../../apollo-config";
import moment from "moment"

const updatePO = async ({
  client,
  id,
  status,
  authorizedBy,
  revisedBy,
  elaboratedEvent,
  currentStatus
}) => {
  if (status !== currentStatus) {
    const eventTypes = {
      IS_PO_REVIEW: null,
      IS_PO_REVISED: "PURCHASE_ORDER_REVISED",
      IS_PO_APPROVED: "PURCHASE_ORDER_APPROVED",
      IS_PO_CANCEL: "PURCHASE_ORDER_CANCELED"
    };

    const mutation = await apollo.mutate({
      mutation: gql`
        mutation {
          updatePurchaseOrder(
            data:{
              data:{
                update:{
                    status:${status}
                    authorizedBy:"${authorizedBy}"
                    revisedBy:"${revisedBy}"
                    elaboratedEvent:{
                      create:{
                        name:"${elaboratedEvent.name}"
                        date:"${moment().local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}"
                        type:${eventTypes[status]}
                      }
                    }
                }
              }
            }
            where: { id: "${id}" }
          ){
            id
          }
        }`,
    });
    return mutation;
  }
};

export default updatePO;
