import React from "react";
import { Container, Grid, Header, Segment, Form } from "semantic-ui-react";
import { DashboardLayout } from "../../Organisms";
import routes from "./routes";
import { PoNavigation } from "../../Molecules";
import { ImportMS } from "../../Atoms";

export default class ProjectProgressDetailView extends React.Component {
  constructor(props) {
    super(props);
    const { item: ActiveRouteItem, routeName: activeRoute } = routes[0];
    this.state = {
      activeRoute,
      ActiveRouteItem,
    };
  }

  navigateTo(route) {
    const { item, routeName } = route;
    this.setState({
      activeRoute: routeName,
      ActiveRouteItem: item,
      addComponent: routeName,
    });
  }

  renderActiveRouteItem() {
    const { ActiveRouteItem } = this.state;
    return ActiveRouteItem ? <ActiveRouteItem /> : null;
  }

  render() {
    return (
      <DashboardLayout activeTab="projectProgress">
        <Container fluid>
          <Grid columns="equal">
            <Grid.Row className="Wrapper__Nav">
              <Grid.Column floated="left">
                <Header as="h2" className="Wrapper__Nav-Title">
                  Avance de proyecto &nbsp;
                  <ImportMS />
                </Header>
              </Grid.Column>
              <Grid.Column floated="right">
                <PoNavigation
                  className="navigation"
                  routes={routes}
                  activeRoute={this.state.activeRoute}
                  navigateTo={() =>
                    (window.location = `/galeria/${
                      window.location.pathname.split("e/")[1]
                    }`)
                  }
                  style={{ float: "right" }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{this.renderActiveRouteItem()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </DashboardLayout>
    );
  }
}
