import gql from "graphql-tag";
import { client as clientApollo } from "../../apollo-config";
import { GET_CONTRACTS_ADITIVES, GET_CLIENTS, GET_CONTRACT_ACCOUNT_BALANCE_BY_ID } from "../../queries/accountBalance"

export const UPDATE_CONTRACT_AB = async ({
  id,
  IDFolio,
  client,
  project,
  currency,
  amountContract,
  percentAmortization,
  percentGuaranteeFund,
  startDate,
  finishDate,
  status,
  description
}) => {
  await clientApollo.mutate({
    mutation: gql`
      mutation {
        updateContractAccountBalance(data: {
          IDFolio: "${IDFolio}"
          client: {
            connect: {
              id: "${client.id}"
            }
          }
          project: {
            connect: {
              id: "${project.id}"
            }
          }
          currency: ${currency}
          amountContract: ${parseFloat(amountContract)}
          percentAmortization: ${percentAmortization}
          percentGuaranteeFund: ${percentGuaranteeFund}
          amountAdvancePayment: ${parseFloat(percentAmortization) * parseFloat(amountContract) / 100}
          amountByAmortization: ${parseFloat(percentAmortization) * parseFloat(amountContract) / 100}
          amountRetained: ${parseFloat(percentGuaranteeFund) * parseFloat(amountContract) / 100}
          amountByRetained: ${parseFloat(percentGuaranteeFund) * parseFloat(amountContract) / 100}
          amountContractUpdate: ${parseFloat(amountContract)}
          amountByBilled: ${parseFloat(amountContract)}
          amountByPaid: ${parseFloat(amountContract)}
          startDate: "${startDate}"
          finishDate: "${finishDate}"
          realFinishDate: "${finishDate}"
          status: ${status}
          description: "${description}"
        }
        where: { id: "${id}" }
        ) {
          id
        }
      }
    `,
    refetchQueries: [{
      query: GET_CONTRACTS_ADITIVES },
      { query: GET_CLIENTS },
      { query: GET_CONTRACT_ACCOUNT_BALANCE_BY_ID, variables: { id } }
    ]
  });
};

export default UPDATE_CONTRACT_AB;
