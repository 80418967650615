import React from "react";
import "./style.css";

export default class Container extends React.Component {
  render() {
    return (
      <div id={this.props.id} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}
