import gql from "graphql-tag";
import { client } from "../../apollo-config";

const updateClient = async ({
  id,
  commercialName,
  street,
  number,
  colony,
  postalCode,
  city,
  state,
  country,
  rfc,
  razonSocial,
  addedContacts,
  updatedContacts,
  deletedContacts
}) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateClient(
          where: { id: "${id}" },
          data:{
            commercialName: "${commercialName}"
            street: "${street}"
            number: "${number}"
            colony: "${colony}"
            postalCode: ${parseInt(postalCode)}
            city: "${city}"
            state: "${state}"
            country: "${country}"
            rfc: "${rfc}"
            razonSocial: "${razonSocial}"
            contacts: {
              create: ${JSON.stringify(addedContacts).replace(/"([^(")"]+)":/g, "$1:")}
              updateMany: ${JSON.stringify(updatedContacts).replace(/"([^(")"]+)":/g, "$1:")}
              deleteMany: ${JSON.stringify(deletedContacts).replace(/"([^(")"]+)":/g, "$1:")}
            }
          }
        ) {
          id
        }
      }
    `
  });
  return mutation;
};

export default updateClient;
