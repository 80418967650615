import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "../../../../assets/logo.png";

const styles = StyleSheet.create({
  header: {
    width: "100%",
    height: "50px",
    marginBottom: 40,
  },
  logo: {
    width: 100,
    borderBottomWidth: 2,
    marginLeft: 5,
    top: 10,
    border: "none",
  },
  date: {
    position: "absolute",
    right: 0,
    top: 10,
  },
  dateTextLabel: {
    fontSize: 8,
    right: 11,
    color: "#000000",
  },
  dateTextValue: {
    fontSize: 8,
    right: 10,
    top: 5,
    color: "#000000",
  },
  currencyTextLabel: {
    fontSize: 8,
    right: 10,
    top: 10,
  },
  currencyTextValue: {
    fontSize: 8,
    right: 10,
    top: 15,
    fontWeight: "bold",
  },
});

export default props => (
  <View fixed style={styles.header}>
    {/* Logo */}
    <Image style={styles.logo} src={logo} cache={false} alt="logo" fixed />

    {/* Date & Currency */}
    <View style={styles.date}>
      <Text style={{ ...styles.dateTextLabel, fontWeight: 700 }}>
        Fecha / Date
      </Text>
      <Text style={styles.dateTextValue}>{props.date}</Text>
    </View>
  </View>
);
