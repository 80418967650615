import React from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Image,
  Input,
  Segment,
  GridRow,
  GridColumn,
} from "semantic-ui-react";
import logo from "../../../assets/logo";
import { auth } from "../../../auth";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {
        email: null,
        password: null,
      },
      errors: {
        emailError: false,
        passwordError: false,
      },
      passwordInputType: "password",
    };
  }
  inputStyle = {
    backgroundColor: "#efefef",
    borderRadius: "15px",
    color: "#999999",
  };
  btnStyle = {
    color: "white",
    backgroundColor: "#b3363e",
    fontSize: "12px",
    borderRadius: "15px",
    width: "250px",
  };
  handleChange = (e, { name, value }) => {
    const userData = this.state.userData;
    userData[name] = value;
    this.setState(data => ({
      ...data,
      userData,
    }));
  };
  login = () => {
    // Removed in newer commits
    auth.loginWithEmailAndPassword(`{
      "email": "${this.state.userData.email}",
      "password": "${this.state.userData.password}"
    }`);
  };
  render() {
    return (
      <Container
        style={{
          position: "absolute",
          top: "0",
          height: "100%",
          width: "100%",
          background: "#fbfbfb",
        }}
      >
        <Segment
          style={{ margin: "0 auto", marginTop: "10%", width: "30%" }}
          padded="very"
          size="mini"
          raised
        >
          <Grid>
            <GridRow>
              <GridColumn>
                <Image size="mini" centered src={logo.image} />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <Form>
                  <Form.Field
                    control={Input}
                    name="email"
                    type="email"
                    placeholder="Email..."
                    style={this.inputStyle}
                    onChange={this.handleChange}
                    required
                  />
                  <Form.Field
                    control={Input}
                    name="password"
                    type={this.state.passwordInputType}
                    action={{
                      icon:
                        this.state.passwordInputType === "password"
                          ? "eye"
                          : "eye slash",
                      onClick: () => {
                        this.setState(data => ({
                          ...data,
                          passwordInputType:
                            this.state.passwordInputType === "password"
                              ? "text"
                              : "password",
                        }));
                      },
                      style: {
                        background: "white",
                        borderTop: "1px solid #dddddd",
                        borderRight: "1px solid #dddddd",
                        borderBottom: "1px solid #dddddd",
                      },
                    }}
                    placeholder="Password..."
                    style={this.inputStyle}
                    onChange={this.handleChange}
                  />
                </Form>
              </GridColumn>
            </GridRow>
            <GridRow textAlign="center">
              <GridColumn>
                <Button style={this.btnStyle} onClick={this.login} centered>
                  INICIA SESIÓN
                </Button>
              </GridColumn>
            </GridRow>
          </Grid>
        </Segment>
      </Container>
    );
  }
}
