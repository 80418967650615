
import React from 'react'
import { Modal, Form, Button, Message, Icon } from "semantic-ui-react";
import { UPDATE_USER } from "../../../graphql/mutations/users";

class ChangePassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showPassword: false,
      newPassword: "",
      confirmNewPassword: "",
      passError: false,
      passChange: false,
      message: ""
    }
  }

  handleShowPassword = () => {
    this.setState(prevState => ({
      ...prevState,
      showPassword: !prevState.showPassword
    }))
  }

  handleChangePassword = (e, { name, value }) => {
    this.setState(prevState => ({
      ...prevState,
      passError: false,
      [name]: value
    }))
  }

  handleSaveNewPassword = async () => {
    const { newPassword, confirmNewPassword } = this.state
    const id = this.props.id

    if (!newPassword.length || newPassword !== confirmNewPassword) {
      this.setState(prevState => ({
        ...prevState,
        passError: true,
        message: 'Las contraseñas no coinciden o no has ingresado una contraseña.'
      }))
    } else {
      try {
        await UPDATE_USER({ password: newPassword, emailConfirmed: true }, id)
        this.setState(prevState => ({
          ...prevState,
          passChange: true,
          message: 'La contraseña fue cambiada correctamente.'
        }))
        setTimeout(this.props.toggleOpenChangePassword, 2000)
      } catch (e) {
        this.setState(prevState => ({ ...prevState, passChange: false, message: 'Intente cambiar el password mas tarde.' }))
        throw new Error(e)
      }
    }
  }

  render () {
    const { showPassword, newPassword, confirmNewPassword, passChange, passError, message } = this.state
    const { toggleOpenChangePassword } = this.props

    return (
      <Modal
        open
        size="tiny"
      >
        <Modal.Header>Cambio de contraseña</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              label="Nueva contraseña"
              name="newPassword"
              placeholder="contraseña"
              type={showPassword ? "text" : "password"}
              action={{
                icon: showPassword ? "eye slash" : "eye",
                onClick: this.handleShowPassword
              }}
              value={newPassword}
              onChange={this.handleChangePassword}
              error={passError}
            />
            <Form.Input
              label='Confirma contraseña'
              name="confirmNewPassword"
              placeholder='contraseña'
              type={showPassword ? "text" : "password"}
              value={confirmNewPassword}
              onChange={this.handleChangePassword}
              error={passError}
            />
            {passError && (
              <Message negative icon size="tiny">
                <Icon name='warning sign'/>
                {message}
              </Message>
            )}
            {passChange && (
              <Message positive icon size="tiny">
                <Icon name='save'/>
                {message}
              </Message>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={toggleOpenChangePassword}>
            Cancelar
          </Button>
          <Button
            disabled={passChange}
            negative
            onClick={this.handleSaveNewPassword}>
            Guardar
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ChangePassword
