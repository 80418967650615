const TableSorter = {
  makeSortable(table) {
    // Store context of this in the object
    const _this = this;
    let th = table.tHead;
    let i;
    th && (th = th.rows[0]) && (th = th.cells);

    if (th) {
      i = th.length;
    } else {
      return; // if no `<thead>` then do nothing
    }

    // Loop through every <th> inside the header
    while (--i >= 0) {
      (function(i) {
        let dir = 1;

        // Append click listener to sort
        th[i].addEventListener("click", () => {
          _this._sort(table, i, (dir = 1 - dir));
        });
      })(i);
    }
  },
  _sort(table, col, reverse) {
    const tb = table.tBodies[0];
    // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows

    let tr = Array.prototype.slice.call(tb.rows, 0);
    // put rows into array

    let i;

    reverse = -(+reverse || -1);

    // Sort rows
    tr = tr.sort(
      (a, b) =>
        reverse *
        // Using `.textContent.trim()` for test
        a.cells[col].textContent
          .trim()
          .localeCompare(b.cells[col].textContent.trim())
    );

    for (i = 0; i < tr.length; ++i) {
      // Append rows in new order
      tb.appendChild(tr[i]);
    }
  }
};

const filter = inputID => {
  // Declare variables
  let input;
  let filter;
  let table;
  let tr;
  let td;
  let td1;
  let td2;
  let td3;
  let td4;
  let td5;
  let td6;
  let td7;
  let i;
  input = document.getElementById(inputID);
  filter = input.value.toUpperCase();
  table = document.getElementById("table");
  tr = table.getElementsByTagName("tr");

  // Loop through all table rows, and hide those who don't match the search query
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    if (td || td1 || td2 || td3 || td4 || td5 || td6 || td7) {
      if (
        td.innerHTML.toUpperCase().indexOf(filter) > -1 ||
        td1.innerHTML.toUpperCase().indexOf(filter) > -1 ||
        td2.innerHTML.toUpperCase().indexOf(filter) > -1 ||
        td3.innerHTML.toUpperCase().indexOf(filter) > -1 ||
        td4.innerHTML.toUpperCase().indexOf(filter) > -1 ||
        td5.innerHTML.toUpperCase().indexOf(filter) > -1 ||
        td6.innerHTML.toUpperCase().indexOf(filter) > -1 ||
        td7.innerHTML.toUpperCase().indexOf(filter) > -1
      ) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
};

const mountFieldGroup = (holder, fieldGroup) => {
  for (let i = 0; i < fieldGroup.length; i++) {
    // Create The Holder
    const holder_ = document.createElement("div");
    holder_.setAttribute("name", "filters");
    holder_.setAttribute("id", `dynamicHolder${[i]}`);
    holder_.style.cssText =
      "margin: 0 auto; width: 100%;height: 30px;  margin-top: 0px; margin-left:5px";
    holder.appendChild(holder_);

    // Create Table
    if (fieldGroup[i].type === "table") {
      const Table = document.createElement("table");
      Table.innerText = fieldGroup[i].label;
      Table.setAttribute("id", fieldGroup[i].id);
      Table.setAttribute("class", fieldGroup[i].class);
      holder_.appendChild(Table);
    }

    if (fieldGroup[i].type === "thead") {
      const Thead = document.createElement("thead");
      Thead.innerText = fieldGroup[i].label;
      Thead.setAttribute("name", fieldGroup[i].name);
      Thead.setAttribute("id", fieldGroup[i].id);
      Thead.setAttribute("class", fieldGroup[i].class);
      holder_.appendChild(Thead);
    }

    // Create Label - Input
    if (fieldGroup[i].type === "input") {
      const Label = document.createElement("span");
      Label.innerText = fieldGroup[i].label;
      Label.setAttribute("name", fieldGroup[i].name);

      const Input = document.createElement(fieldGroup[i].type);
      Input.setAttribute("id", fieldGroup[i].id);
      Input.setAttribute("name", fieldGroup[i].name);
      Input.setAttribute("type", fieldGroup[i].inputType);
      Input.setAttribute("placeholder", fieldGroup[i].placeholder);
      Input.style.cssText =
        "width:20%;padding:5px;border:1px solid #dddddd;border-radius:5px;";
      holder_.appendChild(Input);
      if (fieldGroup[i].value !== undefined) {
        Input.setAttribute("value", fieldGroup[i].value);
      }
      if (fieldGroup[i].id !== undefined) {
        //Input.setAttribute("id", "folioInp");
      }
    }
    if (fieldGroup[i].type === "select") {
      const Label = document.createElement("span");
      Label.innerText = fieldGroup[i].label;
      Label.style.cssText = "float: left";
      holder_.appendChild(Label);

      const Select = document.createElement(fieldGroup[i].type);
      Select.setAttribute("name", fieldGroup[i].name);
      Select.setAttribute("type", fieldGroup[i].inputType);
      Select.style.cssText = "float: right";
      holder_.appendChild(Select);

      const options = fieldGroup[i].options;
      for (let i = 0; i < options.length; i++) {
        const Option = document.createElement("option");
        Option.setAttribute("value", options[i]);
        Option.innerText = options[i];
        Select.appendChild(Option);
      }
    }
  }
};

const createJSON = json => {
    if( json !== "undefined") {
      var newJson = json.replace(/'/g, '"');
      const data = JSON.parse(newJson);
      return data;
    }
    return {}
};

const AddRowBefore = (rowId, children) => {
  var target = document.getElementById(rowId);
  var newElement = document.createElement("tr");
  target.parentNode.insertBefore(newElement, target);
  return newElement;
};

/**
 * converts array-like object to array
 * @param  collection the object to be converted
 * @return {Array} the converted object
 */
const arrayify = collection => {
  return Array.prototype.slice.call(collection);
};

/**
 * generates factory functions to convert table rows to objects,
 * based on the titles in the table's <thead>
 * @param  {Array[String]} headings the values of the table's <thead>
 * @return {Function}      a function that takes a table row and spits out an object
 */
const factory = headings => {
  return function(row) {
    return arrayify(row.cells).reduce(function(prev, curr, i) {
      prev[headings[i]] = curr.innerText;
      return prev;
    }, {});
  };
};

/**
 * given a table, generate an array of objects.
 * each object corresponds to a row in the table.
 * each object's key/value pairs correspond to a column's heading and the row's value for that column
 *
 * @param  {HTMLTableElement} table the table to convert
 * @return {Array[Object]}       array of objects representing each row in the table
 */
const parseTable = table => {
  var headings = arrayify(table.tHead.rows[0].cells).map(function(heading) {
    return heading.innerText;
  });
  return arrayify(table.tBodies[0].rows).map(factory(headings));
};

// This method will filter any table to show only the  filters with coincidences
const filterTable = (talbeId, inputId) => {
  // Handle the user input
  const userInput = document.getElementById(inputId);
};

/**
 * 2.0
 */
// Table To Json
const tableToJson = table => {
  var data = [];
  // first row needs to be headers
  var headers = [];
  if (table) {
    for (var i = 0; i < table.rows[0].cells.length; i++) {
      headers[i] = table.rows[0].cells[i].innerHTML
        //.toLowerCase()
        .replace(/ /gi, "_")
        .replace(/&nbsp;/gi, "-")
        .split("-")[0];
    }
    // go through cells
    for (var i_ = 1; i_ < table.rows.length; i_++) {
      var tableRow = table.rows[i_];
      var rowData = {};
      for (var j = 0; j < tableRow.cells.length; j++) {
        rowData[headers[j]] = tableRow.cells[j].innerHTML;
      }
      data.push(rowData);
    }
    return data;
  }
};
/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
const findOne = function(haystack, arr) {
  return arr.some(function(v) {
    return haystack.indexOf(v) >= 0;
  });
};

// Multi filter
const multiFilter = tableJSON => {
  let ids;
  let input;
  let filters = [];
  let obj = {};
  // Inputs Loop
  for (let i = 0; i < tableJSON.length; i++) {
    ids = Object.keys(tableJSON[i]);
  }
  // Get the user filters
  for (let index = 0; index < ids.length; index++) {
    input = document.getElementById(ids[index]);
    if (input !== null) {
      filters.push(input.value);
      obj[input.id] = input.value;
    }
  }
  // Find for a match
  for (let i = 0; i < tableJSON.length; i++) {
    const e = tableJSON[i];
    const eKeys = Object.keys(e);
    const match = findOne(eKeys, Object.keys(obj));
    if (match) {
      // lets search for the filters keys
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const element = obj[key];
          if (eKeys.includes(key)) {
            if (e[key] === element) {
              return { e, key };
            } else {
              return { error: 404 };
            }
          }
        }
      }
    } else {
      return;
    }
  }
};

// Create Groups
/**
 *
 * @param {holder} holder Root Element
 * @param {elements} elements Array containing elements props
 * @param {id} id Id of the element
 * @param {class} class Class of the element
 * @param {type} type Type of the element
 * @param {appendTo} appendTo The elment which will be the parent
 * @param {text} text The inner text of the element
 * @param {html} html The inner html of the element
 */
const createElements = (holder, elements) => {
  // Create Root Element and append it
  const root = document.createElement(elements[0].type);
  root.setAttribute("id", elements[0].id);
  root.setAttribute("class", elements[0].class);
  holder.appendChild(root);

  // Remove Root
  elements.shift();

  // Append Children
  for (let i = 0; i < elements.length; i++) {
    const children = document.createElement(elements[i].type);
    if (elements[i].id !== undefined)
      children.setAttribute("id", elements[i].id);
    if (elements[i].class !== undefined)
      children.setAttribute("class", elements[i].class);
    if (elements[i].name !== undefined)
      children.setAttribute("name", elements[i].name);
    if (elements[i].onClick !== undefined)
      children.onclick = elements[i].onClick;
    if (elements[i].data !== undefined)
      children.setAttribute(elements[i].dataName, elements[i].data);
    if (elements[i].text !== undefined) children.innerText = elements[i].text;
    if (elements[i].html !== undefined) children.innerHTML = elements[i].html;
    document.getElementById(elements[i].appendTo).appendChild(children);
  }
};

const replaceKeys = (keyMaps, array) => {
  const newArray = [];

  array.map(obj => {
    const newObj = {};

    keyMaps.map(map => {
      if (obj[map.oldKey] !== undefined) {
        newObj[map.newKey] = obj[map.oldKey];
        delete obj[map.oldKey];
      }
    });

    newArray.push(newObj);
  });

  return newArray;
};

const getData = el => {
  if (!el.value) {
    return el.getAttribute("data");
  } else {
    return el.value;
  }
};

/**
 * @param ids: ["my", "ids", "array"]
 */
const getElementsById = ids => {
  let result = [];
  ids.map(id => {
    let el = document.getElementById(id);
    result.push(el);
  });
  return result;
};

module.exports = {
  TableSorter,
  filter,
  mountFieldGroup,
  createJSON,
  AddRowBefore,
  parseTable,
  filterTable,
  tableToJson,
  multiFilter,
  findOne,
  createElements,
  replaceKeys,
  getData,
  getElementsById
};
