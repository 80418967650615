import React from "react";
import { ApolloProvider, Query } from "react-apollo";
import { Button, Container, Dropdown, Form, Header, Input, Modal, Segment, Table, TextArea, Select, Confirm } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash } from "react-icons/io";
import CurrencyInput from "react-currency-input";
import { client, QUERIES } from "../../../graphql/apollo-config";
import mixpanel from "../../../mixpanel";
import { createRequisition, connectReqToProject } from "../../../graphql/mutations/purchases";
import { CREATE_SUPPLY } from "../../../graphql/mutations/supplies";
import getSupplies from "../../../graphql/queries/purchases/getSupplies";
import { CreateVendor, PurchasesSupplies } from "../../Organisms";
import { FormHeader, SelectProject, SelectWarehouse } from "../../Molecules";
import { validator } from "../../../helpers";
import FormValidator from "../../../helpers/form-validator";
import validations from "./validations";
import { store } from "../../../store/store";
import SearchSupply from "../../Atoms/searchSupply";
import moment from "moment";
import OnLoading from "../../Molecules/OnLoading";
import { bugsnagClient } from "../../../bugsnag";
import { alertProjectInactive } from "../../../helpers/alert-project-inactive";

export default class CreateRequisition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectProject: "",
      selectWarehause: "",
      requisition: {
        folio: 1,
        folioReq: 1,
        paymentConditions: "CASH",
        currency: "MXN",
        iva: 16,
        subtotal: 0.0,
        total: 0.0,
        discountPercentage: 0.0,
        discount: 0.0,
        retentionPercentage: 0.0,
        retention: 0.0,
        status: "IS_REQ_REVISION",
        applicant: JSON.parse(window.localStorage.getItem("session"))
          ? JSON.parse(window.localStorage.getItem("session")).user.name
          : "",
      },
      supply: {
        insID: "",
        name: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
      supplies: [],
      residents: [],
      responsibles: [],
      warehouseId: null,
      errors: {
        applicantError: false,
        authorizedByError: false,
        cliError: false,
        currencyError: false,
        deliveryDateError: false,
        deliveryWarehouseError: false,
        descriptionError: false,
        formError: false,
        keyError: false,
        nameError: false,
        notesError: false,
        paymentConditionsError: false,
        purchasePriceError: false,
        quantityError: false,
        revisedByError: false,
        siteNameError: false,
        statusError: false,
        unitsError: false,
        vendorError: false,
        wasUpdatedAtError: false,
        petitionerError: false,
        ivaError: false,
        addressError: false,
      },
      toggles: {
        supplyQuickCreateToggle: true,
        openConfirm: false,
      },
      modals: {
        addSupplyModal: false,
        updateSupplyModal: false,
      },
      options: {
        currencyOptions: [
          { key: "mxn", text: "MXN", value: "MXN" },
          { key: "usd", text: "USD", value: "USD" },
        ],
        statusOptions: [
          {
            key: "revision",
            text: "Revisión",
            value: "IS_REQ_REVISION",
          },
        ],
        paymentConditionsOptions: [
          { key: "credit", text: "Crédito", value: "CREDIT" },
          { key: "one-time", text: "Contado", value: "CASH" },
        ],
      },
      quickCreate: false,
      isCreatingSupply: false,
      user: JSON.parse(window.localStorage.getItem("session"))
        ? JSON.parse(window.localStorage.getItem("session")).user
        : "",
      validatorObject: validations,
      isLoading: false
    };
  }

  componentWillMount() {
    const applicant = this.state.user.name;
    this.setState(data => ({
      ...data,
      requisition: {
        currency: "MXN",
        paymentConditions: "CASH",
        iva: 16,
        total: 0,
        subtotal: 0,
        applicant,
        wasUpdatedAt: this.getDate(),
      },
    }));
    return "data";
  }

  // Handle changes in requisition
  handleChange = ({ target }) => {
    const requisition = this.state.requisition;
    requisition[target.name] = this.cleaningData(target.value);
    this.setState(data => ({
      ...data,
      requisition,
    }));
  };

  // Handle iva field changes
  handleIVAChange = ({ target }) => {
    const requisition = this.state.requisition;
    requisition["iva"] = parseFloat(target.value);
    requisition["total"] = this.getTotal(this.state.supplies);
    this.setState(data => ({
      ...data,
      requisition,
    }));
  };

  handleChangeSelect = (e, { name, value }) => {
    const requisition = this.state.requisition;
    requisition[name] = value;
    this.setState(data => ({ ...data, requisition }));
  };

  // Handle changes in supply object
  handleChangeSupply = ({ target }) => {
    const supply = this.state.supply;
    const requisition = this.state.requisition;

    supply[target.name] = target.value;

    const decimalPercentage = this.calculateDiscountFromPercentage(
      requisition["discountPercentage"],
      this.getImporte(this.state.supplies)
    );

    requisition["discount"] = decimalPercentage.toFixed(2);

    this.setState(data => ({
      ...data,
      supply,
      requisition
    }));
  };

  // Handle change cli
  handleChangeDropdown = (e, { name, value }) => {
    const requisition = this.state.requisition;
    requisition["vendorId"] = value.id;
    requisition["iva"] = parseFloat(value.iva);
    requisition["total"] = this.getTotal(this.state.supplies);
    this.setState(data => ({ ...data, requisition }));
  };

  handleChangeCli = (e, { value, options }) => {
    const selected = options.find(i => i.key === value)
    const { responsibles, residents, status, cli, address, text } = selected

    const isProjectInactive = alertProjectInactive({ status, module: 'req', projectName: text });
    if (isProjectInactive) return

    const _responsibles = responsibles.map(responsible => responsible.name)
    const _residents = residents.map(resident => resident.name)

    this.setState(prevState => ({
      ...prevState,
      requisition: {
        ...prevState.requisition,
        projectId: value,
        name: cli,
        siteName: cli,
        cli,
        address
      },
      selectProject: value,
      responsibles: _responsibles,
      residents: _residents
    }))
  };

  handleChangeCurrency = (e, { name, value }) => {
    const requisition = this.state.requisition;

    requisition["currency"] = value;

    requisition["total"] = parseFloat(
      this.getTotal(this.state.supplies)
    ).toFixed(2);

    this.setState(data => ({
      ...data,
      requisition,
    }));
  };

  handleChangeWarehouse = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const _value = JSON.parse(value);
    //const warehouseName = _value.name;
    const warehouseId = _value.id;
    requisition[name] = value.replace(/([<>"()?])/g, "\\$1");
    this.setState(data => ({ ...data, requisition, warehouseId, selectWarehause: value }));
  };

  getDate = () => {
    var today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
    return date;
  };

  cleaningData = data => {
    //Replacing JSON forbidden characters
    let str = data.replace(/([$#"^{}¨!'?])/g, "");
    //Replacing not printable characters
    str = str.replace(/([\n\r\t?])/g, "");
    //Scaping special characters
    str = str.replace(/([<>"()?])/g, "\\$1");
    return str;
  };

  deleteSupply = index => {
    // Save the requisition object to
    const supplies = [...this.state.supplies];
    const requisition = this.state.requisition;

    supplies.splice(index, 1);
    requisition["subtotal"] = parseFloat(this.getSubtotal(supplies)).toFixed(2);
    requisition["total"] = parseFloat(this.getTotal(supplies)).toFixed(2);
    const decimalPercentage = this.calculateDiscountFromPercentage(
      requisition["discountPercentage"],
      this.getImporte(supplies)
    );

    requisition["discount"] = decimalPercentage.toFixed(2);
    this.setState(data => ({ ...data, supplies, requisition }));
  };

  // Save requisition to DB
  save = async () => {
    const requisition = { ...this.state.requisition };
    // set status
    requisition["status"] = "IS_REQ_REVISION";
    requisition["wasUpdatedAt"] = this.getDate();
    requisition["subtotal"] = this.getImporte(this.state.supplies);
    requisition["amount"] = this.getSubtotal(this.state.supplies);
    requisition["supplies"] = this.state.supplies;
    requisition["notes"] = escape(requisition["notes"]);
    requisition["elaboratedEvent"] = {
      name: requisition["applicant"],
      date: moment()
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      type: "REQUISITION_CREATED",
    };
    // Add supplies to object
    this.addSuppliesToRequisition();
    const validation = this.validateForm(requisition);
    if (validation) {
      try {
        this.setState(prevState => ({ ...prevState, isLoading: true }));
        const newReq = await this.saveRequisitionToDB(requisition);
        const reqId = newReq.data.createRequisition.id;
        await connectReqToProject(reqId, requisition.projectId);
        mixpanel.track("Requisición creada");
        this.props.toggleSave();
        if (this.props.refetch) {
          this.props.refetch();
        }
      } catch (e) {
        this.setState(prevState => ({ ...prevState, isLoading: false }));
        bugsnagClient.notify(e);
      }
    } else {
      const toggles = this.state.toggles;
      toggles["openConfirm"] = true;
      this.setState(data => ({ ...data }));
    }
  };

  // Validate required form fields
  validateForm = requisition => {
    const ivaIsZero = requisition["iva"] === 0;
    let validatorObj = ivaIsZero
      ? this.state.validatorObject.ivaZero
      : this.state.validatorObject.iva;
    const validator = new FormValidator(validatorObj);
    const validation = validator.validate(requisition);
    let errors = this.state.errors;
    Object.keys(validation).map(key => {
      const errorKey = `${key}Error`;
      errors[errorKey] = validation[key]["isInvalid"];
      return errors;
    });
    this.setState(data => ({ ...data, errors }));
    return validation.isValid;
  };

  saveRequisitionToDB = async requisition => {
    return await createRequisition(requisition);
  };

  closeConfirm = () => {
    const toggles = this.state.toggles;
    toggles.openConfirm = false;
    this.setState(data => ({ ...data, toggles }));
  };

  /***********************
   *  Supplies handling  *
   ***********************/

  saveSupply = () => {
    this.setState(data => ({ ...data, isCreatingSupply: true }));
    // Save the requisition object to
    const supplies = [...this.state.supplies];
    const requisition = this.state.requisition;
    const supply = this.validateSupply();

    let exists = false;
    supplies.map(_supply => {
      if (supply.data !== null && _supply.key === supply.data.key) {
        exists = true;
      }
      return exists;
    });

    if (supply.isValid) {
      supplies.push(this.state.supply);

      const decimalPercentage = this.calculateDiscountFromPercentage(
        requisition["discountPercentage"],
        this.getImporte(supplies)
      );

      requisition["discount"] = decimalPercentage.toFixed(2);

      requisition["subtotal"] = parseFloat(this.getImporte(supplies)).toFixed(
        2
      );

      requisition["amount"] =
        requisition["subtotal"] - decimalPercentage.toFixed(2);

      requisition["total"] = parseFloat(this.getTotal(supplies)).toFixed(2);

      this.setState({
        supplies,
        supply: {
          key: "",
          description: "",
          units: "",
          quantity: "",
          purchasePrice: "",
        },
        requisition,
        modals: {
          addSupplyModal: false,
        },
        isCreatingSupply: false,
      });
    } else {
      alert("Insumo ya existe");
      this.setState(data => ({ ...data, isCreatingSupply: false }));
    }
  };

  saveSupplyQuickCreate = () => {
    this.setState(data => ({ ...data, isCreatingSupply: true }));
    getSupplies().then(async res => {
      const existingSupplies = res.supplies;
      let keys = [];
      existingSupplies.map(item => {
        const key = item.insID;
        keys.push(key);
        return keys;
      });
      const supply = this.validateSupply();
      if (supply.data !== null) {
        supply["data"]["description"] = supply.data.description;
      }
      const supplies = [...this.state.supplies];

      if (supply.isValid) {
        supplies.push(supply.data);

        const requisition = this.state.requisition;

        const decimalPercentage = this.calculateDiscountFromPercentage(
          requisition["discountPercentage"],
          this.getImporte(supplies)
        );

        requisition["discount"] = decimalPercentage.toFixed(2);

        requisition["amount"] =
          requisition["subtotal"] - decimalPercentage.toFixed(2);

        requisition["subtotal"] = parseFloat(
          this.getSubtotal(supplies)
        ).toFixed(2);

        requisition["total"] = parseFloat(this.getTotal(supplies)).toFixed(2);

        try {
          const { data: { createSupply: { insID } } } = await CREATE_SUPPLY(supply.data);
          supplies[supplies.length - 1].insID = insID;

          this.setState({
            supplies,
            supply: {
              insID: "",
              name: "",
              description: "",
              units: "",
              quantity: "",
              purchasePrice: "",
            },
            requisition,
            toggles: {
              supplyQuickCreateToggle: true,
            },
            modals: {
              addSupplyModal: false,
            },
            isCreatingSupply: false,
          });

        } catch (e) {
          alert("Insumo ya existe");
          this.setState(data => ({ ...data, isCreatingSupply: false }));
        }
      } else {
        this.setState(data => ({ ...data, isCreatingSupply: false }));
      }
    });
  };

  supplyKeyChange = (e, { value }) => {
    const data = JSON.parse(value);
    data.quantity = 1;
    const { description, insID, name, units, purchasePrice, quantity } = data;
    this.setState(data => ({
      ...data,
      supply: { description, insID, name, units, purchasePrice, quantity },
    }));
  };

  updateSupply = () => {
    const supplies = [...this.state.supplies];
    const supply = this.state.supply;
    const requisition = this.state.requisition;

    requisition["subtotal"] = parseFloat(this.getSubtotal(supplies)).toFixed(2);
    requisition["total"] = parseFloat(this.getTotal(supplies)).toFixed(2);
    supplies[this.state.editSupply] = supply;
    this.setState(data => ({
      ...data,
      supplies,
      supply: {
        key: "",
        description: "",
        units: "",
        quantity: "",
        purchasePrice: "",
      },
      modals: {
        addSupplyModal: false,
      },
      requisition,
    }));
  };

  // Regex supplies object to be a valid JSON for GraphQL
  parseSupplies = () => {
    const str = JSON.stringify(this.state.supplies).replace(/([<>"()?])/g,"\\$1");
    return str;
  };

  // Connect supplies to requisiton object
  addSuppliesToRequisition = () => {
    const requisition = this.state.requisition;
    requisition["suppliesQty"] = this.state.supplies.length === 0 ? `[]` : this.parseSupplies();
    this.setState(data => ({ ...data, requisition }));
  };

  // Validate fields
  validateSuppliesFields = validationObject => {
    let errors = [];
    const _errors = this.state.errors;
    const validation = validator(validationObject);
    validation.map(field => {
      const key = field.key;
      const errorKey = `${key}Error`;
      if (!field.isValid) {
        errors.push(key);
      }

      const index = errors.indexOf("name");
      if (index !== -1) {
        errors.splice(index, 1);
      }

      _errors[errorKey] = !field.isValid;
      this.setState(data => ({
        ...data,
        errors: _errors,
      }));
      return errors;
    });
    if (errors.length > 0) {
      return { errors: errors, isValid: false };
    }
    if (errors.length === 0) {
      return { errors: errors, isValid: true };
    }
  };

  // Validates supply form fields
  validateSupply = () => {
    const {
      name,
      description,
      units,
      quantity,
      purchasePrice,
    } = this.state.supply;
    const supply = { name, description, units, quantity, purchasePrice };
    const validation = this.validateSuppliesFields(supply);
    if (validation.isValid) {
      return { data: supply, isValid: true };
    } else {
      return { data: null, isValid: false };
    }
  };

  vendorquickcreate = () => {
    let toggleValue = store.getState().navigationReducer.toggle_vendor;
    this.setState(data => ({ ...data, quickCreate: !this.state.quickCreate }));
    store.dispatch({
      type: "NAVIGATE_TO_CREATE_VENDOR",
      toggle_vendor: !toggleValue,
    });
  };

  // Supply quick create
  supplyQuickCreate = async () => {
    this.setState(data => ({
      ...data,
      toggles: {
        supplyQuickCreateToggle: !this.state.toggles.supplyQuickCreateToggle,
      },
      supply: {
        insID: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    }));
  };

  // Open the add supply modal
  openAddSupply = () => {
    this.setState({
      modals: { addSupplyModal: true },
      supply: {
        key: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    });
  };

  openUpdateSupply = index => {
    const supply = this.state.supplies[index];
    const currentQty = supply.quantity;
    this.setState({
      modals: { updateSupplyModal: true },
      supply,
      editSupply: index,
      currentQty: currentQty,
    });
  };

  // Close de add supply modal
  closeAddSupply = () => {
    this.setState({
      modals: { addSupplyModal: false },
      supply: {
        key: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    });
  };

  closeUpdateSupply = () => {
    const supplies = [...this.state.supplies];
    const current = this.state.supplies[this.state.editSupply];
    current["quantity"] = this.state.currentQty;
    supplies[this.state.editSupply] = current;
    this.setState({
      modals: { updateSupplyModal: false },
      supplies,
      supply: {
        key: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    });
  };

  handleChangeDiscountPercentage = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const decimalPercentage = this.calculateDiscountFromPercentage(
      value,
      this.getImporte(this.state.supplies)
    );

    requisition[name] = value;
    requisition["discount"] = !isNaN(parseFloat(decimalPercentage))
      ? parseFloat(decimalPercentage).toFixed(2)
      : 0;
    requisition["total"] = this.getTotal(this.state.supplies);
    requisition["subtotal"] = this.getSubtotal(this.state.supplies);
    this.setState({ ...requisition });
  };

  handleChangeDiscountDecimal = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const percentage = this.calculateDiscountFromDecimal(
      value,
      requisition["subtotal"]
    );
    requisition["discount"] = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    requisition["discountPercentage"] = !isNaN(percentage.toFixed(2))
      ? Math.floor(percentage).toFixed(2)
      : 0;
    requisition["subtotal"] = this.getSubtotal(this.state.supplies);
    requisition["total"] = this.getTotal(this.state.supplies);
    this.setState({ ...requisition });
  };

  handleChangeRetentionPercentage = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const retentionPercentage = this.calculateRetentionFromPercentage(
      value,
      this.getSubtotal(this.state.supplies)
    );
    requisition[name] = value;
    requisition["retention"] = !isNaN(retentionPercentage.toFixed(2))
      ? retentionPercentage.toFixed(2)
      : 0;
    requisition["total"] = this.getTotal(this.state.supplies);
    this.setState({ ...requisition });
  };

  handleChangeRetentionDecimal = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const retentionPercentage = this.calculateRetentionFromDecimal(
      value,
      this.getSubtotal(this.state.supplies)
    );

    requisition["retention"] = !isNaN(parseFloat(value))
      ? parseFloat(value)
      : 0;

    requisition["retentionPercentage"] = !isNaN(retentionPercentage.toFixed(2))
      ? retentionPercentage.toFixed(2)
      : 0;
    requisition["total"] = this.getTotal(this.state.supplies);
    this.setState({ ...requisition });
  };

  calculateDiscountFromPercentage = (discountPercentage, subtotal) => {
    return (discountPercentage / 100) * subtotal;
  };

  calculateDiscountFromDecimal = (discountPercentage, subtotal) => {
    return (discountPercentage / subtotal) * 100;
  };

  calculateRetentionFromPercentage = (discountPercentage, subtotal) => {
    return (parseFloat(discountPercentage) / 100) * parseFloat(subtotal);
  };

  calculateRetentionFromDecimal = (discountPercentage, subtotal) => {
    return (parseFloat(discountPercentage) / parseFloat(subtotal)) * 100;
  };

  // Get the total of a supply added
  getTotalSupply = (a, b) => {
    if (a && b) {
      return `$${(a * b).toFixed(2)}`;
    } else {
      return 0;
    }
  };

  getTotal = supplies => {
    const subtotal = this.getSubtotal(supplies);
    if (
      this.state.requisition.retention > 0
    ) {
      const iva = (subtotal / 100) * this.state.requisition.iva;
      const retention = (!isNaN(parseFloat(this.state.requisition["retention"]))
        ? parseFloat(this.state.requisition["retention"])
        : 0);
      return subtotal - retention + iva;
    } else {
      return (
        this.getSubtotal(supplies) +
        this.getSubtotal(supplies) * (this.state.requisition.iva / 100)
      );
    }
  };

  getSubtotal = supplies => {
    let subtotal;
    let totals = [];
    supplies.map(({ quantity, purchasePrice }) => {
      const math = parseFloat(quantity) * parseFloat(purchasePrice);
      totals.push(math);
      return totals;
    });
    subtotal =
      totals.reduce((a, b) => a + b, 0) -
      (!isNaN(parseFloat(this.state.requisition["discount"]))
        ? parseFloat(this.state.requisition["discount"])
        : 0);
    return subtotal;
  };

  getImporte = supplies => {
    let subtotal;
    let totals = [];
    supplies.map(({ quantity, purchasePrice }) => {
      const math = parseFloat(quantity) * parseFloat(purchasePrice);
      totals.push(math);
      return totals;
    });
    subtotal = totals.reduce((a, b) => a + b, 0);
    return subtotal;
  };

  toggleSupplyQuickCreate = () => {
    return <Button onClick={this.supplyQuickCreate}>X</Button>;
  };

  searchVendor = props => {
    const getVendors = QUERIES.getVendors;
    return (
      <Query query={getVendors} fetchPolicy={"network-only"}>
        {({ loading, error, data }) => {
          let options = [];
          if (loading) {
            return <Dropdown text="Cargando..." options={[]} loading />;
          }
          if (error) {
            return <Dropdown text="Error" options={[]} error />;
          }

          data.vendors.map(({ id, commercialName, iva }) => {
            options.push({
              key: id,
              text: commercialName,
              value: { id: id, iva: iva },
              style: { textAlign: "center", color: "#999999" },
            });
            return options;
          });

          return (
            <Dropdown
              fluid
              name="vendorId"
              icon="search"
              wrapSelection={false}
              search
              selection
              onChange={this.handleChangeDropdown}
              error={this.state.vendorIdError}
              readOnly={this.state.isReadOnly}
              options={[
                {
                  content: (
                    <p
                      style={{
                        color: "#295896",
                        borderTop: "1px solid #dddddd",
                        textAlign: "center",
                      }}
                    >
                      + Añadir nuevo proveedor
                    </p>
                  ),
                  key: "add",
                  value: "add-new",
                  onClick: this.vendorquickcreate,
                },
                ...options,
              ]}
            />
          );
        }}
      </Query>
    );
  };

  render() {
    const SearchVendor = this.searchVendor;
    const totalIsZero = this.state.supplies.length === 0 ? true : false;
    return (
      <ApolloProvider client={client}>
        {!this.state.quickCreate ? (
          <Segment raised className="Modal">
            <FormHeader
              saveId="save-req-btn"
              cancelId="cancel-req-btn"
              text="Crear requisición"
              onSave={this.save}
              onCancel={this.props.onCancel}
            />
            {this.state.isLoading
              ? <OnLoading loading={this.state.isLoading} />
              : (
                  <>
                    <Form className="Modal__Form">
                      <Form.Group widths="equal">
                        <Form.Field
                          label="CLI"
                          name="siteName"
                          control={Input}
                          value={this.state.requisition.siteName}
                          onChange={this.handleChange}
                          error={this.state.errors.siteNameError}
                          required
                          readOnly
                        />
                        <Form.Field
                          label="ELABORÓ"
                          name="applicant"
                          control={Input}
                          value={this.state.requisition.applicant}
                          error={this.state.errors.applicantError}
                          required
                          readOnly
                        />
                        <Form.Field
                          label="SOLICITANTE"
                          name="petitioner"
                          control={Input}
                          value={this.state.requisition.petitioner}
                          onChange={this.handleChange}
                          error={this.state.errors.petitionerError}
                          autoComplete="off"
                          required
                        />
                        <Form.Field
                          label="IMPORTE"
                          name="subtotal"
                          value={this.getImporte(this.state.supplies)}
                          control={CurrencyInput}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          label="ALMACÉN"
                          name="deliveryWarehouse"
                          width={10}
                          control={SelectWarehouse}
                          value={this.state.selectWarehause}
                          onChange={this.handleChangeWarehouse}
                          error={this.state.errors.deliveryWarehouseError}
                        />
                        <Form.Field
                          label="PROVEEDOR"
                          name="vendorId"
                          control={SearchVendor}
                          onChange={this.handleChangeDropdown}
                          vendorquickcreate={this.vendorquickcreate}
                          error={this.state.errors.vendorIdError}
                          width={10}
                        />
                        <Form.Field
                          label="AUTORIZÓ"
                          name="authorizedBy"
                          control={Input}
                          onChange={this.handleChange}
                          error={this.state.errors.authorizedByError}
                          width={10}
                          readOnly
                        />
                        <Form.Field
                          label="DESCUENTO"
                          name="discountPercentage"
                          type="number"
                          min={0}
                          control={Input}
                          placeholder="0.00%"
                          value={parseFloat(this.state.requisition.discountPercentage)}
                          onChange={this.handleChangeDiscountPercentage}
                          error={this.state.streetError}
                          width={5}
                          autoComplete="off"
                          readOnly={totalIsZero}
                        />
                        <Form.Field
                          label="&nbsp;"
                          name="discount"
                          min={0}
                          control={CurrencyInput}
                          placeholder="$0.00"
                          value={this.state.requisition.discount}
                          onChange={this.handleChangeDiscountDecimal}
                          error={this.state.numberError}
                          width={5}
                          autoComplete="off"
                          readOnly={totalIsZero}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          user={this.state.user}
                          label="NOMBRE DE PROYECTO"
                          name="cli"
                          control={SelectProject}
                          onChange={this.handleChangeCli}
                          value={this.state.selectProject}
                          error={this.state.errors.siteNameError}
                          required
                        />
                        <Form.Field
                          label="RESIDENTE"
                          name="resident"
                          control={Input}
                          value={this.state.residents}
                          error={this.state.errors.siteNameError}
                          required
                          readOnly
                        />
                        <Form.Field
                          label="REVISÓ"
                          name="revisedBy"
                          control={Input}
                          onChange={this.handleChange}
                          error={this.state.errors.revisedByError}
                          readOnly
                        />
                        <Form.Field
                          label="SUBTOTAL"
                          name="_subtotal"
                          value={this.getSubtotal(this.state.supplies).toFixed(2)}
                          control={CurrencyInput}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          label="RESPONSABLE DE PROYECTO"
                          name="responsible"
                          control={Input}
                          value={this.state.responsibles}
                          error={this.state.errors.siteNameError}
                          required
                          readOnly
                        />
                        <Form.Field
                          label="TIEMPO DE ENTREGA"
                          name="deliveryDate"
                          control={Input}
                          value={this.state.requisition.deliveryDate}
                          error={this.state.errors.deliveryDateError}
                          onChange={this.handleChange}
                          required
                        />
                        <Form.Field
                          label="MONEDA"
                          name="currency"
                          control={Select}
                          value={this.state.requisition.currency}
                          onChange={this.handleChangeCurrency}
                          options={this.state.options.currencyOptions}
                          error={this.state.errors.currencyError}
                        />
                        <Form.Field
                          label="IVA"
                          name="iva"
                          type="number"
                          min={0}
                          value={this.state.requisition.iva}
                          control={Input}
                          onChange={this.handleIVAChange}
                          action={{ basic: true, content: "%" }}
                          labelPosition="right corner"
                          error={this.state.errors.ivaError}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          label="FECHA DE CREACIÓN"
                          name="wasUpdatedAt"
                          type="date"
                          value={this.getDate()}
                          control={Input}
                          onChange={this.handleChange}
                          error={this.state.errors.wasUpdatedAtError}
                          readOnly
                          required
                          width={10}
                        />
                        <Form.Field
                          label="ESTATUS"
                          name="status"
                          control={Input}
                          onChange={this.handleChange}
                          value="Revisión"
                          readOnly
                          width={10}
                        />
                        <Form.Field
                          label="CONDICIONES DE PAGO"
                          name="paymentConditions"
                          control={Select}
                          value={this.state.requisition.paymentConditions}
                          onChange={this.handleChangeSelect}
                          options={this.state.options.paymentConditionsOptions}
                          error={this.state.errors.paymentConditionsError}
                          width={10}
                        />
                        <Form.Field
                          label="RETENCIÓN"
                          name="retentionPercentage"
                          type="number"
                          min={0}
                          control={Input}
                          placeholder="0.00%"
                          value={parseFloat(this.state.requisition.retentionPercentage)}
                          onChange={this.handleChangeRetentionPercentage}
                          error={this.state.streetError}
                          width={5}
                          autoComplete="off"
                          readOnly={totalIsZero}
                        />
                        <Form.Field
                          label="&nbsp;"
                          name="retention"
                          min={0}
                          control={CurrencyInput}
                          placeholder="$0.00"
                          value={this.state.requisition.retention}
                          onChange={this.handleChangeRetentionDecimal}
                          error={this.state.numberError}
                          width={5}
                          autoComplete="off"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Field width={10} />
                        <Form.Field width={10} />
                        <Form.Field width={10} />
                        <Form.Field
                          label="TOTAL"
                          name="total"
                          min={0}
                          value={
                            parseFloat(this.state.requisition.total) > 0
                              ? parseFloat(this.state.requisition.total).toFixed(2)
                              : () => {
                                const requisition = this.state.requisition;
                                requisition["total"] = 0;
                                this.setState({ ...requisition });
                              }
                          }
                          control={CurrencyInput}
                          onChange={this.handleChange}
                          readOnly
                          width={10}
                        />
                      </Form.Group>
                      <Form.Field
                        label="UBICACIÓN DEL PROYECTO"
                        name="address"
                        onChange={this.handleChange}
                        value={
                          this.state.requisition.address &&
                            this.state.requisition.address !== "undefined"
                            ? this.state.requisition.address
                            : ""
                        }
                        control={TextArea}
                        error={this.state.errors.addressError}
                        readOnly
                      />
                      <Form.Field
                        label="NOTAS"
                        name="notes"
                        onChange={this.handleChange}
                        control={TextArea}
                        error={this.state.errors.notesError}
                      />
                    </Form>
                    <Header as="h3" dividing className="Modal__Form-Header">
                      Insumos
                    </Header>
                    <Table
                      basic="very"
                      compact="very"
                      textAlign="center"
                      className="Modal__Form-Table"
                    >
                      <Table.Header className="Modal__Form-Table-Header">
                        <Table.Row>
                          <Table.HeaderCell>Clave del insumo</Table.HeaderCell>
                          <Table.HeaderCell>Nombre</Table.HeaderCell>
                          <Table.HeaderCell>Descripción</Table.HeaderCell>
                          <Table.HeaderCell>Unidad de medida</Table.HeaderCell>
                          <Table.HeaderCell>Precio unitario</Table.HeaderCell>
                          <Table.HeaderCell>Cantidad</Table.HeaderCell>
                          <Table.HeaderCell>Total</Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body className="Modal__Form-Table-Body">
                        {this.state.supplies.map((supply, index) => {
                          const {
                            insID,
                            name,
                            description,
                            quantity,
                            units,
                            purchasePrice,
                          } = supply;
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{insID}</Table.Cell>
                              <Table.Cell>{unescape(name)}</Table.Cell>
                              <Table.Cell
                                style={{
                                  maxWidth: "300px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {unescape(description)}
                              </Table.Cell>
                              <Table.Cell>{units}</Table.Cell>
                              <Table.Cell>
                                $
                                {parseFloat(purchasePrice)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Table.Cell>
                              <Table.Cell>{quantity}</Table.Cell>
                              <Table.Cell>
                                {this.getTotalSupply(quantity, purchasePrice)
                                  .toString()
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Table.Cell>
                              <Table.Cell>
                                <Button.Group basic>
                                  <Button
                                    icon
                                    onClick={() => this.openUpdateSupply(index)}
                                  >
                                    <IoMdCreate />
                                  </Button>
                                  <Button icon onClick={() => this.deleteSupply(index)}>
                                    <IoIosTrash />
                                  </Button>
                                </Button.Group>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                    <Container textAlign="center">
                      <Modal
                        className="Modal__Form-SubModal"
                        centered={false}
                        trigger={
                          <Button
                            onClick={this.openAddSupply}
                            className="Modal__Form-ButtonAdd"
                            icon="plus"
                            content="Añadir insumo"
                            size="large"
                          />
                        }
                        open={this.state.modals.addSupplyModal}
                        header={
                          <FormHeader
                            text="Añadir Insumo"
                            onCancel={this.closeAddSupply}
                            onSave={
                              this.state.toggles.supplyQuickCreateToggle
                                ? this.saveSupply
                                : this.state.isCreatingSupply
                                  ? null
                                  : this.saveSupplyQuickCreate
                            }
                          />
                        }
                        content={
                          <PurchasesSupplies
                            toggleSupplyQuickCreate={this.toggleSupplyQuickCreate}
                            data={this.state}
                            SearchSupply={SearchSupply}
                            supplyKeyChange={this.supplyKeyChange}
                            handleChangeSupply={this.handleChangeSupply}
                            handleSearchChange={this.handleSearchChange}
                            supplyQuickCreate={this.supplyQuickCreate}
                            getTotalSupply={this.getTotalSupply}
                          />
                        }
                        size="tiny"
                        dimmer="blurring"
                      />
                      <Modal
                        className="Modal__Form-SubModal"
                        centered={false}
                        open={this.state.modals.updateSupplyModal}
                        onClose={this.handleCloseModal}
                        header={
                          <FormHeader
                            text="Actualizar insumo"
                            onCancel={this.closeUpdateSupply}
                            onSave={this.updateSupply}
                          />
                        }
                        content={
                          <PurchasesSupplies
                            toggleSupplyQuickCreate={this.toggleSupplyQuickCreate}
                            data={this.state}
                            SearchSupply={SearchSupply}
                            supplyKeyChange={this.supplyKeyChange}
                            handleChangeSupply={this.handleChangeSupply}
                            handleSearchChange={this.handleSearchChange}
                            supplyQuickCreate={this.supplyQuickCreate}
                            getTotalSupply={this.getTotalSupply}
                            isEdit
                          />
                        }
                        size="tiny"
                        dimmer="blurring"
                      />
                      <Confirm
                        open={this.state.toggles.openConfirm}
                        cancelButton={null}
                        onConfirm={this.closeConfirm}
                        content="Campos faltantes"
                      />
                    </Container>
                  </>
                )
            }
          </Segment>
        ) : (
          <CreateVendor
            onCancel={this.vendorquickcreate}
            toggleSave={this.vendorquickcreate}
            isQuickCreate
          />
        )}
      </ApolloProvider>
    );
  }
}
