import React from "react";
import { Button, Form, Header, Grid } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import PropTypes from "prop-types";
import moment from "moment";
import ContractDocumentContext from "../../Organisms/OriginalContractList/contractDocumentContext";
import CurrencyInput from 'react-currency-input';
import { format } from "../../../helpers";

class AditiveForm extends React.Component {
  state = {
    isLoading: false,
    currencyOptions: [
      { key: "mxn", text: "MXN", value: "MXN" },
      { key: "usd", text: "USD", value: "USD" },
    ],
  };

  refUpload = contract => {
    const documents = contract.documentsContractor[0];
    const refUpload = documents ? documents.refUpload[0] : [];
    return { documents, refUpload };
  };

  downloadFile = file => {
    window.open(file, "_blank");
  };

  render() {
    const { currentItem, contract, errors, change } = this.context;
    const { refUpload } = this.refUpload(contract);
    const { isUpdate, readOnly } = this.props;

    return (
      <React.Fragment>
        <Form className="Modal__Form-SubModal-Form" autoComplete="off">
          <Grid textAlign="center">
            <Grid.Row>
              <Grid.Column width={12} textAlign="left">
                <Form.Group widths="equal">
                  <Form.Input
                    label="SELECCIONA EL PROYECTO"
                    defaultValue={contract.project.name}
                    readOnly
                  />
                  <Form.Input
                    label="PRESUPUESTO CLI"
                    placeholder="PRESUPUESTO CLI"
                    defaultValue={contract.project.cli}
                    readOnly
                  />
                  <Form.Input
                    label="SELECCIONA EL CONTRATISTA"
                    defaultValue={contract.contractor.name}
                    readOnly
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="Modal__Form-Utils-Row-Align">
              <Grid.Column computer="16" textAlign="left">
                <Header as="h3" dividing className="Modal__Form-Header">
                  Datos adicionales
                </Header>
              </Grid.Column>

              <Grid.Column width="7" textAlign="left">
                <label>NÚMERO DE SIROC DEL PROYECTO</label>
                <Form.Input
                  name="siroc"
                  defaultValue={contract.siroc}
                  className="Modal__Form-Utils-Input-MT"
                  readOnly
                />
              </Grid.Column>

              <Grid.Column width="7" textAlign="left">
                {refUpload.reference ? (
                  <React.Fragment>
                    <label>DOCUMENTO SIROC</label>
                    <Button
                      basic
                      icon="plus"
                      content="Ver documento"
                      className="Modal__Form-SubModal-Form-ButtonAddOutline Large Modal__Form-Utils-Input-MT"
                      fluid
                      onClick={() => this.downloadFile(refUpload.reference)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <label>DOCUMENTO SIROC</label>
                    <Button
                      basic
                      icon="delete"
                      content="Sin documento"
                      className="Modal__Form-SubModal-Form-ButtonAddOutline Large Modal__Form-Utils-Input-MT"
                      fluid
                    />
                  </React.Fragment>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="Modal__Form-Utils-Row-Align Util-NPT">
              <Grid.Column width="7" />
              <Grid.Column width="7" textAlign="left">
                <Form.Group>
                  <Form.Input
                    width="8"
                    label="ALTA IMSS"
                    icon="calendar"
                    name="startDateSiroc"
                    defaultValue={format.date(contract.startDateSiroc, 1)}
                    readOnly/>
                  <Form.Input
                    width="8"
                    label="BAJA IMSS"
                    icon="calendar"
                    name="finishDateSiroc"
                    defaultValue={format.date(contract.finishDateSiroc, 1)}
                    readOnly/>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="Modal__Form-Utils-Row-Align">
              <Grid.Column width="14" textAlign="left">
                <label>DIRECCIÓN DEL PROYECTO SIROC</label>
                <Form.Group
                  widths="equal"
                  className="Modal__Form-Utils-Input-MT"
                >
                  <Form.Input
                    name="street"
                    defaultValue={contract.street}
                    readOnly
                  />
                  <Form.Input
                    name="externalNumber"
                    defaultValue={contract.externalNumber}
                    readOnly
                  />
                  <Form.Input
                    name="colony"
                    defaultValue={contract.colony}
                    readOnly
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    name="city"
                    defaultValue={contract.city}
                    readOnly
                  />
                  <Form.Input
                    name="state"
                    defaultValue={contract.state}
                    readOnly
                  />
                  <Form.Input
                    name="country"
                    defaultValue={contract.country}
                    readOnly
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="Modal__Form-Utils-Row-Align">
              <Grid.Column computer="16" textAlign="left">
                <Header as="h3" dividing className="Modal__Form-Header">
                  Datos del contrato
                </Header>
              </Grid.Column>

              <Grid.Column computer="14" textAlign="left">
                <Form.Group widths="equal">
                  <Form.Field required error={errors.total} disabled={(readOnly || isUpdate)}>
                    <label>TOTAL DEL CONTRATO</label>
                    <CurrencyInput
                      value={currentItem.total || ""}
                      onChangeEvent={(event, maskedvalue, floatvalue) => change(null, { name: "total", value: floatvalue })}/>
                  </Form.Field>
                  <Form.Select
                    label="MONEDA"
                    placeholder="Seleccionar"
                    options={this.state.currencyOptions}
                    name="currency"
                    defaultValue={currentItem.currency}
                    error={errors.currency}
                    onChange={change}
                    disabled={(readOnly || isUpdate)}
                  />
                  <Form.Field className='Util-DatePicker'>
                    <SemanticDatepicker
                      required
                      name="startDate"
                      label="FECHA DE INICIO"
                      placeholder={!currentItem.startDate ? "dd/mm/aaaa" : moment(currentItem.startDate).add(1, 'day').format("DD/MM/YYYY")}
                      type="basic"
                      format="DD/MM/YYYY"
                      onDateChange={
                        (date) => {
                          change(null, { name: 'startDate', value: (date !== null && moment(date).format('YYYY-MM-DD')) })
                        }
                      }
                      readOnly={true}
                      error={errors.startDate}
                      disabled={(readOnly || isUpdate)}
                    />
                  </Form.Field>
                  <Form.Field className='Util-DatePicker'>
                    <SemanticDatepicker
                      required
                      name="finishDate"
                      label="FECHA DE TERMINO"
                      placeholder={!currentItem.finishDate ? "dd/mm/aaaa" : moment(currentItem.finishDate).add(1, 'day').format("DD/MM/YYYY")}
                      type="basic"
                      format="DD/MM/YYYY"
                      onDateChange={
                        (date) => {
                          change(null, { name: 'finishDate', value: (date !== null && moment(date).format('YYYY-MM-DD')) })
                        }
                      }
                      readOnly={true}
                      error={errors.finishDate}
                      disabled={(readOnly || isUpdate)}
                    />
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
              <Grid.Column computer="14" textAlign="left">
                <Form.Group widths="equal">
                  <Form.TextArea
                    required
                    label="DESCRIPCIÓN DE CONCEPTOS A CONTRATAR"
                    rows="5"
                    name="description"
                    value={currentItem.description}
                    error={errors.description}
                    onChange={change}
                    readOnly={readOnly}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </React.Fragment>
    );
  }
}

AditiveForm.propTypes = {
  readOnly: PropTypes.bool,
};

AditiveForm.defaultProps = {
  readOnly: false,
};

AditiveForm.contextType = ContractDocumentContext;

export default AditiveForm;
