import gql from "graphql-tag";
import { client } from "../../apollo-config";

const deleteContact = async email => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        deleteManyContractorContacts(where: { email: "${email}" }) {
          count
        }
      }
    `
  });
  return mutation;
};

export default deleteContact;
