import gql from "graphql-tag";
import { client } from "../../apollo-config";

const EMAIL_EXIST = async (email) => {
  const { data: { user } } = await client.query({
    query: gql`
      {
        user(where:{email: "${email}"}) {
          email
        }
      }`
  });

  return user !== null ? true : false;
};
export default EMAIL_EXIST;
