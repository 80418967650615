import gql from "graphql-tag";
import { client as clientApollo } from "../../apollo-config";
import { GET_CONTRACTS_ADITIVES } from "../../queries/accountBalance";
import { format } from "../../../helpers";

const CREATE_DOCUMENT_CONTRACT_AB = async ({
  idContract,
  type,
  concept,
  dateElaborated,
  currency,
  amountAmortization,
  amountGuaranteeFund,
  dataAmounts,
  invoiceNumber,
  status,
  amount,
  total,
  id,
  refUpload
}) => {
  const { data: { updateContractAccountBalance } } = await clientApollo.mutate({
    mutation: gql`
      mutation {
        updateContractAccountBalance(
          where: { id: "${idContract}" }
          data: {
            amountAmortization: ${parseFloat(dataAmounts.amountAmortization).toFixed(2)}
            amountByAmortization: ${parseFloat(dataAmounts.resultAmortization).toFixed(2)}
            amountTotalRetained: ${parseFloat(dataAmounts.amountTotalRetained).toFixed(2)}
            amountByRetained: ${parseFloat(dataAmounts.resultGuaranteeFund).toFixed(2)}
            amountBilled: ${parseFloat(dataAmounts.amountBilled).toFixed(2)}
            amountByBilled: ${parseFloat(dataAmounts.resultBilled).toFixed(2)}
            amountPaid: ${parseFloat(dataAmounts.amountPaid).toFixed(2)}
            amountByPaid: ${parseFloat(dataAmounts.resultPaided).toFixed(2)}
            amountContractUpdate: ${parseFloat(dataAmounts.amountContractUpdate).toFixed(2)}
            amountDeductives: ${parseFloat(dataAmounts.amountDeductives).toFixed(2)}
            elaboratedEvent: ${dataAmounts.elaboratedMsj ? `{
              create : {
                name: "${JSON.parse(window.localStorage.getItem('session')).user.name}"
                date: "${format.date(new Date(), 3)}"
                description: "${dataAmounts.elaboratedMsj}"
              }
              }` : `{}`
            }
            documentsContractAccountBalance: {
              upsert: {
                where: { id: "${id}" }
                update: {
                  type: ${type}
                  concept: "${concept}"
                  dateElaborated: "${format.date(dateElaborated, 2)}"
                  currency: ${currency}
                  amountAmortization: ${amountAmortization ? parseFloat(amountAmortization).toFixed(2) : 0}
                  amountGuaranteeFund: ${amountGuaranteeFund ? parseFloat(amountGuaranteeFund).toFixed(2) : 0}
                  invoiceNumber: "${invoiceNumber || ""}"
                  status: ${status}
                  iva: ${0}
                  amount: ${parseFloat(amount)}
                  amountIva: ${0}
                  total: ${parseFloat(total || amount)}
                  refUpload: {
                    create: [${refUpload.map(doc => {
                      return `${doc.hasOwnProperty("new") ? `{
                          type: ${doc.type}
                          reference: "${doc.reference}"
                          extension: "${doc.extension}"
                          createDate: "${format.date(new Date(), 2)}"
                      }` : []}`
                    })}]
                  }
                }
                create: {
                  type: ${type}
                  concept: "${concept}"
                  elaborated: {
                    connect : {
                      id: "${JSON.parse(window.localStorage.getItem("session")).user.id}"
                    }
                  }
                  dateElaborated: "${format.date(dateElaborated, 2)}"
                  currency: ${currency}
                  amountAmortization: ${amountAmortization ? parseFloat(amountAmortization).toFixed(2) : 0}
                  amountGuaranteeFund: ${amountGuaranteeFund ? parseFloat(amountGuaranteeFund).toFixed(2) : 0}
                  invoiceNumber: "${invoiceNumber || ""}"
                  status: ${status}
                  iva: ${0}
                  amount: ${parseFloat(amount)}
                  amountIva: ${0}
                  total: ${parseFloat(total || amount)}
                  refUpload: {
                    create: [${refUpload.map(doc => `{
                      type: ${doc.type}
                      reference: "${doc.reference}"
                      extension: "${doc.extension}"
                      createDate: "${format.date(new Date(), 2)}"
                    }`)}]
                  }
                }
              }
            }
          }
        ) {
          id
          documentsContractAccountBalance ${ id ? `(where: { id: "${id}" })` : "" } {
            elaborated {
              email
              name
            }
            status
            type
          }
        }
      }
    `,
    refetchQueries: [{ query: GET_CONTRACTS_ADITIVES }]
  });
  return updateContractAccountBalance;
};

export default CREATE_DOCUMENT_CONTRACT_AB;
