import gql from "graphql-tag";

const getUsers = gql`
  query getUsers(
    $first: Int,
    $skip: Int,
    $orderBy: UserOrderByInput
    $search: String
    $searchUpper: String
    $searchLower: String
    $searchCaps: String
  ) {
    users(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: {
        OR: [
          { name_contains: $search } 
          { name_contains: $searchUpper }
          { name_contains: $searchLower }
          { name_contains: $searchCaps }
          { email_contains: $search } 
          { email_contains: $searchUpper }
          { email_contains: $searchLower }
          { email_contains: $searchCaps }
        ]
      }
    ) {
      id
      name
      email
      userRol
      status
    }
  }
`;

export default getUsers;
