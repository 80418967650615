import gql from "graphql-tag";
import { client } from "../../apollo-config";

const receiveSupplies = async (id, receivedSupplies, supplyId) => {
  delete receivedSupplies.id;
  const supply = receivedSupplies
  const mutation = await client.mutate({
    mutation: gql`
        mutation($receivedSupplies:ReceivedSupplyCreateInput!, $update:ReceivedSupplyUpdateDataInput!) {
            updatePurchaseOrder(
                where: { id: "${id}" }
                data: { 
                  data: { 
                    update: { 
                      receivedSupplies: {
                        upsert:{
                          where:{
                            id:"${supplyId}"
                          }
                          update:$update
                          create:$receivedSupplies
                        }
                      }
                    } 
                } 
             
              }) {
                id
            }
        }`,
    variables: {
      receivedSupplies: supply,
      update: supply,
    },
  });
  return mutation;
};

export default receiveSupplies;
