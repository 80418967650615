import React from "react";
import { Dropdown } from "semantic-ui-react";
import { Query } from "react-apollo";
import { getWarehouseDropdown } from "../../../graphql/queries/purchases";
import { bugsnagClient } from "../../../bugsnag";

const SelectWarehouse = props => (
  <Query query={getWarehouseDropdown} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (loading) {
        return <p testid="loading-wrapper">loading...</p>;
      }
      if (error) {
        bugsnagClient.notify(error);
        return <p testid="error-wrapper">error</p>;
      }
      let options = [];
      const _data_ = data.warehouses;
      _data_.map(({ id, name }) => {
        options.push({
          text: name,
          value: JSON.stringify({ id: id, name: name }),
        });
      });
      return (
        <Dropdown
          name={props.name}
          value={props.value}
          options={options}
          onChange={props.onChange}
          selection
          error={props.error}
        />
      );
    }}
  </Query>
);

export default SelectWarehouse;
