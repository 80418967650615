import React from "react";
import { withRouter } from "react-router";
import { TabPanel } from "../../Atoms";
import { TabModal, ContractForm, ContractAccountBalance, ContractContractorDocuments } from "../";
import { validator, format, percentage } from "../../../helpers";
import ContractDocumentContext from "../OriginalContractList/contractDocumentContext";
import { bugsnagClient } from "../../../bugsnag";
import contractService from "../../../graphql/services/contract/create-contract";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";

class EditContract extends React.Component {
  state = {
    contract: this.props.data !== null ? Object.assign({}, this.props.data.contract) : {},
    isLoading: false,
    currentTab: "datos",
    errors: {
      name: false,
      project: false,
      contractor: false,
      siroc: false,
      street: false,
      colony: false,
      city: false,
      state: false,
      country: false,
      postalCode: false,
      externalNumber: false,
      total: false,
      currency: false,
      startDate: false,
      finishDate: false,
      description: false,
      contractType: false,
      guaranteeFund: false
    },
  }

  componentDidCatch () {
    window.location.reload();
  }

  // return object with values in the state to validate
  contractorValidateFields = () => {
    const { errors, contract } = this.state;
    let fieldsValue = {};

    for (const field in errors) fieldsValue[field] = contract[field];

    return fieldsValue;
  }

  validateFields = validationObject => {
    let errors = [];
    const validation = validator(validationObject);

    validation.forEach(field => {
      const key = field.key;
      if (!field.isValid) {
        errors.push(key);
      }
    });

    return errors;
  };

  // change contract state value
  change = (name, value, options) => {
    const { contract } = this.state;

    if (name === "project" || name === "contractor") {
      value = options.find(item => item.id===value);
    }

    if (["total", "guaranteeFund"].includes(name))
      if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value))
        contract[name] = '';

    if (name === "documentsContractor") {
      this.setState(prevState => ({
        contract: {
          ...prevState.contract,
          documentsContractor: value
        }
      }))
      return
    }

    contract[name] = value;
    this.setState({ contract });
  }

  // save Contractor
  save = async () => {
    const { errors, contract } = this.state;
    const recalculatedDocsPercentage = percentage.reCalculateDocsPercentage({
      ...contract,
      realFinishDate: contract.finishDate // we are updating the realFinishDate with the finishDate input
    })

    let { error, message } = await format.validStateContract(contract);

    if (error) {
      return alert(message);
    }

    // launch loading
    // Si todo OK validamos los inputs
    this.setState({ isLoading: true });

    // validate inputs
    // get contractor errors state object
    const validationErrors = Object.assign({}, errors);
    // get inputs to validate
    const contractValidateObj = this.contractorValidateFields();
    const contractErrors = this.validateFields(contractValidateObj);

    if (contractErrors.length > 0) {
      Object.keys(validationErrors).forEach(key => {
        validationErrors[key] = contractErrors.indexOf(key) !== -1;
      });

      this.setState(data => ({
        errors: validationErrors,
        isLoading: false,
      }));

      return false;
    }

    try {
      await contractService.updateContract({
        ...contract,
        ...recalculatedDocsPercentage
      });
      this.props.close(true);
    } catch (err) {
      bugsnagClient.notify(err)
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // close Add Contractor View
  cancel = () => {
    this.props.close();
  }

  render() {
    const { readOnly } = this.props;

    return (
      <EditContractContent
        save={this.save}
        state={this.state}
        readOnly={readOnly}
        cancel={this.cancel}
        change={this.change}
      />
    );
  }
}


const EditContractContent = ({
  save,
  state,
  cancel,
  change,
  readOnly,
}) => {
  const { contract, currentTab, isLoading, errors, contract: { documentsContractor } } = state;
  const { contractType } = contract;
  const isAdditive = contractType === 'ADDITIVE';
  const isBajaImss = documentsContractor.filter(document => document.type === "BAJA_DEL_IMSS");

  const contextValues = {
    currentItem: {},
    errors: {},
    documentStatus: [],
    contract: contract,
  }

  const featureFlags = useFeatureFlags()
  const session = JSON.parse(localStorage.getItem('session'))

  const allowAdminToEdit = featureFlags
    .contractors
    .contracts
    .admin
    .edit && session.user.userRol === 'ADMINISTRATOR';

  return (
    <TabModal
      active={currentTab}
      save={save}
      close={cancel}
      disabled={isLoading}
      readOnly={readOnly}
    >
      <TabPanel name="datos" text="Datos del contrato">
        <ContractForm
          readOnly={readOnly || allowAdminToEdit ? false : !!isBajaImss.length}
          change={change}
          contract={contract}
          errors={errors}
          isUpdate={allowAdminToEdit ? false : !!isBajaImss.length}
        />
      </TabPanel>

      <TabPanel name="balance" text="Balance de cuentas">
        <ContractDocumentContext.Provider value={contextValues}>
          <ContractAccountBalance
            id={contract.id}
            readOnly={readOnly || !!isBajaImss.length}
          />
        </ContractDocumentContext.Provider>
      </TabPanel>

      {!isAdditive && (
        <TabPanel name="documents" text="Documentos de contratista">
          <ContractContractorDocuments
            id={contract.id}
            text="Documentos de contratista"
            readOnly={readOnly}
          />
        </TabPanel>
      )
      }
    </TabModal>
  );
}

export default withRouter(EditContract);
