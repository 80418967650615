import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  Table,
  TableBody,
  TableHeader,
  TableCell,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import { Header, OrderData } from "./components";
import { format } from "../../../helpers/"

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    paddingBottom: 80,
    textAlign: "justify",
    color: "#999999",
  },
  footer: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    position: "absolute",
    height: "20px",
    bottom: 0,
  },
  dataTitle: {
    width: "100%",
    height: "50px",
    fontSize: 9,
    color: "#000000",
    left: 10,
  },
});

export default class PDF extends React.Component {
  dataTitle = () => (
    <View style={styles.dataTitle}>
      <Text>
        {this.props.type === "PO"
          ? "ORDEN DE COMPRA / PURCHASE ORDER"
          : "REQUISICIÓN / REQUISITION"
        }
      </Text>
    </View>
  );

  footer = () => (
    <View fixed style={styles.footer}>
      <Text style={{ fontSize: 6, left: 120 }}>
        Centro ejecutivo no. 5700 interior 501. Colonia sector 53. Chihuahua,
        Chihuahua. C.P 31115. +526144393999 contacto@climas.com
      </Text>
    </View>
  );

  render() {
    const DataTitle = this.dataTitle;
    const Footer = this.footer;
    const { folio, convertedAt } = this.props.data;
    const purchaseOrder = this.props.data.data || this.props.data;
    const {
      petitioner,
      project,
      vendor,
      deliveryWarehouse,
      deliveryDate,
      wasUpdatedAt,
      paymentConditions,
      currency,
      supplies,
      amount,
      discount,
      subtotal,
      iva,
      discountPercentage,
      retentionPercentage,
      retention,
      total,
      revisedBy,
      authorizedBy,
      notes,
    } = purchaseOrder;

    const createdAt = convertedAt ? new Date(parseInt(convertedAt)).toISOString() : wasUpdatedAt;

    return (
      <PDFViewer width="100%" height="100%">
        <Document title={this.props.title}>
          <Page size="A4" orientation="portrait" style={styles.page} wrap>
            <View style={{ width: "100%" }}>
              <Header
                logo="https://via.placeholder.com/150.png"
                date={format.date(createdAt)}
              />
              <DataTitle />
              <OrderData
                order={folio}
                provider={vendor.commercialName}
                to={vendor.contacts[0].name}
                petitioner={petitioner}
                warehouse={JSON.parse(deliveryWarehouse).name}
                project={project.name}
                address={project.address}
                cli={project.cli}
                paymentConditions={paymentConditions}
                currency={currency}
                notes={notes}
                deliveryTime={deliveryDate}
              />
              {/* Supplies table */}
              <Table data={supplies}>
                <TableHeader
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  textAlign="center"
                  fontSize={9}
                >
                  <TableCell styles={{ padding: "5px", border: "none" }}>
                    Cantidad
                  </TableCell>
                  <TableCell styles={{ padding: "5px", border: "none" }}>
                    Nombre
                  </TableCell>
                  <TableCell
                    styles={{ padding: "5px", border: "none" }}
                    weighting={0.4}
                  >
                    Descripción
                  </TableCell>
                  <TableCell styles={{ padding: "5px", border: "none" }}>
                    Precio
                  </TableCell>
                  <TableCell styles={{ padding: "5px", border: "none" }}>
                    Total
                  </TableCell>
                </TableHeader>

                <TableBody
                  textAlign="center"
                  includeBottomBorder={false}
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  fontSize={8}
                  wrap
                >
                  <DataTableCell
                    styles={{ padding: "5px", border: "none" }}
                    getContent={r => r.quantity.toString()}
                  />
                  <DataTableCell
                    styles={{ padding: "5px", border: "none" }}
                    getContent={r => r.name}
                  />
                  <DataTableCell
                    styles={{
                      padding: "5px",
                      border: "none",
                      textAlign: "left",
                    }}
                    weighting={0.4}
                    getContent={r => {
                      const desc = unescape(r.description)
                      return desc.substr(0, 5000 - 3) + "..."
                    }}
                  />
                  <DataTableCell
                    styles={{ padding: "5px", border: "none" }}
                    getContent={r => r.purchasePrice.toFixed(2).toString()}
                  />
                  <DataTableCell
                    styles={{ padding: "5px", border: "none" }}
                    getContent={r => {
                      return (r.quantity * r.purchasePrice)
                        .toFixed(2)
                        .toString();
                    }}
                  />
                </TableBody>
              </Table>

              {/* Totals */}
              <Table
                data={[
                  {
                    space1: "",
                    space2: "",
                    space3: "",
                    label: "Importe",
                    value: `$${subtotal.toFixed(2).toString()}`,
                  },
                  {
                    space1: "",
                    space2: "",
                    space3: "",
                    label: `Descuento ${discountPercentage}%`,
                    value: `$${discount.toFixed(2).toString()}`,
                  },
                  {
                    space1: "",
                    space2: "",
                    space3: "",
                    label: "Subtotal",
                    value: `$${amount.toFixed(2).toString()}`,
                  },
                  {
                    space1: "",
                    space2: "",
                    space3: "",
                    label: `IVA ${iva}%`,
                    value: `$${((parseFloat(iva) / 100) * parseFloat(amount))
                      .toFixed(2)
                      .toString()}`,
                  },
                  {
                    space1: "",
                    space2: "",
                    space3: "",
                    label: `Retención ${retentionPercentage}%`,
                    value: `$${retention.toFixed(2).toString()}`,
                  },
                  {
                    space1: "",
                    space2: "",
                    space3: "",
                    label: "Total",
                    value: `$${total.toFixed(2).toString()}`,
                  },
                ]}
              >
                <TableHeader
                  includeBottomBorder={false}
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  textAlign="center"
                  fontSize={9}
                >
                  <TableCell
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    styles={{
                      padding: "5px",
                      border: "none",
                      color: "#000000",
                    }}
                  />
                  <TableCell
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    styles={{ padding: "5px", border: "none" }}
                  />
                  <TableCell
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    styles={{ padding: "5px", border: "none" }}
                    weighting={0.4}
                  />
                  <TableCell
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    styles={{ padding: "5px", border: "none" }}
                  />
                  <TableCell
                    includeBottomBorder={false}
                    includeLeftBorder={false}
                    includeRightBorder={false}
                    styles={{ padding: "5px", border: "none" }}
                  />
                </TableHeader>
                <TableBody
                  includeBottomBorder={false}
                  includeLeftBorder={false}
                  includeRightBorder={false}
                  textAlign="right"
                  fontSize={9}
                >
                  <DataTableCell
                    styles={{
                      padding: "5px",
                      border: "none",
                      color: "#000000",
                    }}
                    getContent={r => r.space1}
                  />
                  <DataTableCell
                    styles={{
                      padding: "5px",
                      border: "none",
                      color: "#000000",
                    }}
                    getContent={r => r.space2}
                  />
                  <DataTableCell
                    styles={{
                      padding: "5px",
                      border: "none",
                      color: "#000000",
                    }}
                    weighting={0.4}
                    getContent={r => r.space3}
                  />

                  <DataTableCell
                    styles={{
                      padding: "5px",
                      border: "none",
                      color: "#000000",
                    }}
                    getContent={r => r.label}
                  />
                  <DataTableCell
                    styles={{
                      padding: "5px",
                      border: "none",
                      color: "#000000",
                    }}
                    getContent={r => r.value}
                  />
                </TableBody>
              </Table>
              <Text style={{ fontSize: 7, left: 10 }}>
                Revisado por: {revisedBy}
              </Text>
              <Text style={{ fontSize: 7, left: 10 }}>
                Autorizado por: {authorizedBy === "undefined" ? "" : authorizedBy}
              </Text>
            </View>
            <Footer />
          </Page>
        </Document>
      </PDFViewer>
    );
  }
}
