import React from "react";
import { Switch, Route } from "react-router-dom";
import { Container } from "semantic-ui-react";
import ContractAccountBalance from "./ContractAccountBalance";
import NewContractAccountBalance from "./NewContractAccountBalance";
import EditContractAccountBalance from "./EditContractAccountBalance";
import { DashboardLayout } from "../../Organisms";

class AccountBalance extends React.Component {
  render() {
    return (
      <DashboardLayout activeTab="accountBalance">
        <Container fluid>
          <Switch>
            <Route exact path="/estado-de-cuenta" component={ContractAccountBalance} />
            <Route exact path="/estado-de-cuenta/new" component={NewContractAccountBalance} />
            <Route exact path="/estado-de-cuenta/:id/new" component={NewContractAccountBalance} />
            <Route exact path="/estado-de-cuenta/:id/edit" component={EditContractAccountBalance} />
            <Route exact path="/estado-de-cuenta/:id/detail" component={EditContractAccountBalance} />
          </Switch>
        </Container>
      </DashboardLayout>
    )
  }
}

export default AccountBalance
