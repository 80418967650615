//@ts-check
import React, { useState, useEffect } from "react";
import { Grid, Pagination, Label, Dropdown } from "semantic-ui-react";
import theme from "../../_theme";

export const PaginationBar = ({
  totalRecords,
  onPerPageChange,
  onPageChange,
  perPage= 10,
  perPageRecords,
  restartCurrentPage = false
}) => {
  const [state, setState] = useState({
    currentPage: 1,
    perPage: perPage,
    totalPages: 0
  });

  const calculateTotalPages = (total, perPage) => {
    return Math.ceil(total / perPage);
  }

  useEffect(() => {
    if (restartCurrentPage) {
      setState({
        currentPage: 1,
        perPage: perPage,
        totalPages: 0
      });
    } else {
      setState({
        ...state,
        totalPages: calculateTotalPages(totalRecords, perPage)
      });
    }
  }, [totalRecords, perPage, restartCurrentPage]);

  const perPageOptions = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ];

  const _onPageChange = (e, v) => {
    setState({ ...state, currentPage: v.activePage });
    onPageChange(v.activePage);
  }

  const _onPerPageChange = (e, v) => {
    setState({
      ...state,
      perPage: v.value,
      totalPages: calculateTotalPages(totalRecords, v.value),
      currentPage: 1
    });
    onPerPageChange(v.value);
  }

  return (
    <Grid
      columns={3}
      divided="vertically"
      centered>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Label style={theme.styles.paginationLabel}>Ver</Label>
          <Dropdown
            id="recordsDropdown"
            name="recordsDropdown"
            options={perPageOptions}
            selection
            item
            compact
            text={state.perPage.toString()}
            onChange={(e, v) => _onPerPageChange(e, v)} />
          <Label style={theme.styles.paginationLabel}>Entradas</Label>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Label style={theme.styles.entriesLabel}>
            Viendo
          </Label>
          <Label style={theme.styles.entriesBoldLabel}>
            {perPageRecords}
          </Label>
          <Label style={theme.styles.entriesLabel}>de</Label>
          <Label style={theme.styles.entriesBoldLabel}>
            {totalRecords}
          </Label>
          <Label style={theme.styles.entriesLabel}>Entradas</Label>
        </Grid.Column>
        <Grid.Column textAlign="center">
          { (totalRecords > perPage) ?
            <Pagination
              className='Pagination-Fix'
              boundaryRange={1}
              siblingRange={0}
              size='mini'
              activePage={state.currentPage}
              totalPages={state.totalPages}
              onPageChange={(e, v) => _onPageChange(e, v)} />
          : null }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
