import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async (projectId, taskId, file) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateProject(
          where: { id: "${projectId}" }
          data: {
            tasks: {
              update: {
                where: { id: "${taskId}" }
                data: {
                  gallery: {
                    create: {
                      url: "${file.url}"
                      timestamp: "${file.timestamp}"
                      alt: "${file.alt}"
                    }
                  }
                }
              }
            }
          }
        ) {
          id
        }
      }
    `,
  });
  return mutation;
};
