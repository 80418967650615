import React from 'react'
import { climasTemplate } from "../../Organisms/DataTable/climasTemplate";
import DataTable from "../../Organisms/DataTable";
import { format } from "../../../helpers";
import { ActionButtons } from "./actionButtons";
import { Confirm } from "semantic-ui-react";
import { bugsnagClient } from "../../../bugsnag";
import { DELETE_CONTRACT } from "../../../graphql/mutations/contractors";

export default ({
  id,
  openModal,
  action,
  filter,
  filterVariables,
  onOrderChange,
  onReload,
}) => {
  const [state, setState] = React.useState({
    showConfirmModal: false,
    idForDelete: null
  })

  const editItem = (data) => {
    openModal("update", { contract: data });
  }

  const detailItem = (data) => {
    openModal("detail", { contract: data });
  }

  const handleModal = (idForDelete) => {
    setState({
      showConfirmModal: !state.showConfirmModal,
      idForDelete
    })
  }

  const deleteContract = async id => {
    try {
      await DELETE_CONTRACT(id);
      handleModal(null);
      onReload()
    } catch (error) {
      bugsnagClient.notify(error);
    }
  }

  const actionButtonsProps = {
    editItem,
    detailItem,
    handleModal
  };

  return (
    <>
      <DataTable
        onOrderChange={onOrderChange}
        onCompleted={action}
        template={climasTemplate}
        query={filter}
        variables={filterVariables}
        itemsKey="contracts">
        <DataTable.Column
          name="project"
          label="Proyecto"
          content={({ project }) => project.name } />
        <DataTable.Column
          name="contractType"
          label="Tipo de contrato"
          content={(data) => format.contractType(data.contractType) } />
        <DataTable.Column
          name="contractor"
          label="Contratista"
          content={({ contractor }) => contractor.name } />
        <DataTable.Column
          name="startDate"
          label="Inicio"
          content={data => format.date(data.startDate) }
          sortable />
        <DataTable.Column
          name="finishDate"
          label="Termino"
          content={({ finishDate }) => format.date(finishDate) }
          sortable />
        <DataTable.Column
          name="total"
          label="Monto del contrato"
          content={({ total, currency }) => format.currency(total, currency) }
          sortable />
        <DataTable.Column
          name="invoiced"
          label="Facturado"
          content={({ invoiced, currency }) => format.currency(invoiced, currency) }
          sortable />
        <DataTable.Column
          name="paided"
          label="Pagado"
          content={({ paided, currency }) => format.currency(paided, currency) }
          sortable />
        <DataTable.Column
          name="amountAditives"
          label="Aditiva"
          content={({ amountAditives, currency }) => format.currency(amountAditives, currency) }
          sortable />
        <DataTable.Column
          name="status"
          label="Estatus"
          content={({ status }) => format.status(status) }
          sortable />
        <DataTable.Column
          name="openPercentage"
          label="% Alta"
          content={({ openPercentage }) => `${openPercentage} %` }
          sortable />
        <DataTable.Column
          name="monthPercentage"
          label="% Mes"
          content={({ monthPercentage }) => `${monthPercentage} %` }
          sortable />
        <DataTable.Column
          name="closePercentage"
          label="% Cierre"
          content={({ closePercentage }) => `${closePercentage} %` }
          sortable />
        <DataTable.Column
          name="actions"
          label="Acciones"
          content={
            row =>
              <ActionButtons
                contract={row}
                { ...actionButtonsProps } />
          } />
      </DataTable>
      <Confirm
        open={state.showConfirmModal}
        cancelButton="Cancelar"
        confirmButton="Aceptar"
        onCancel={handleModal}
        onConfirm={() => deleteContract(state.idForDelete)}
        content={`¿Desea eliminar el registro? Esta acción no es reversible`}/>
    </>
  );
};
