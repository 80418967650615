import gql from "graphql-tag";

const getWarehouse = gql`
    query($input: WarehouseWhereUniqueInput!) {
        warehouse(
            where: $input
        ) {
            name
            street
            city
            state
            country
            postalCode
            contactName
            contactEmail
            contactPhone
            status
        }
    }
`

export default getWarehouse;
