import gql from "graphql-tag";

const getProject = gql`
  query project($id: ID!) {
    project(where: {id: $id}) {
      id
      cli
      client{
        id
        commercialName
      }
      name
      startDate
      finishDate
      wasUpdatedAt
      status
      address
      amount
      count
      responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
        id
        name
        userRol
      }
      residents: users(where: { userRol_in: [TECHNICIAN] }){
        id
        name
        userRol
      }
    }
  }
`;

export default getProject;
