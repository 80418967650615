import gql from "graphql-tag";
import { client } from "../../apollo-config";

const DELETE_CONTRACT = async (id) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        deleteContract(where: { id: "${id}" }) {
          id
        }
      }
    `,
  });
  return mutation;
};

export default DELETE_CONTRACT;
