import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async (data, id) => {
  id = data.id || id;

  return await client.mutate({
    mutation: gql`
      mutation UpdateUser($data: UserUpdateInput!, $id: ID!){
        updateUser(
          where: { id: $id }
          data: $data
        ){
          id
        }
      }
    `,
    variables: { data: data, id: id }
  });
};
