import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async id => {
  return await client.mutate({
    mutation: gql`
        mutation{
            deleteUser(
                where:{id:"${id}"}
            ){
                id
            }
        }
        `,
  });
};
