import React from "react";
import { bugsnagClient } from "../../../bugsnag";
import { DELETE_PROJECT } from "../../../graphql/mutations/projects";
import { climasTemplate } from "../../Organisms/DataTable/climasTemplate";
import DataTable from "../../Organisms/DataTable";
import moment from "moment";
import { ActionButtons } from "./actionButtons";
import { format } from "../../../helpers";

export default ({
  onUpdate,
  openDetails,
  action,
  filter,
  filterVariables,
  onOrderChange,
  onReload
}) => {
  const deleteProject = async (id) => {
    const prompt = window.confirm("¿Desea eliminar el proyecto?");

    if (!prompt) return;

    try {
      await DELETE_PROJECT(id);
      onReload();
    } catch (err) {
      bugsnagClient.notify(err);
    }
  };

  const parseDate = (column, data) => {
    const date = data[column.props.name];

    return moment(date).format("DD/MM/YYYY");
  };

  const actionButtonsProps = {
    onUpdate,
    deleteProject,
    openDetails
  }

  return (
    <DataTable
      onOrderChange={onOrderChange}
      onCompleted={action}
      template={climasTemplate}
      query={filter}
      variables={filterVariables}
      itemsKey="projects">
      <DataTable.Column
        name="name"
        label="Nombre del proyecto"
        sortable />
      <DataTable.Column
        name="client"
        label="Cliente asignado"
        sortable />
      <DataTable.Column
        name="cli"
        label="Presupuesto CLI"
        sortable />
      <DataTable.Column
        name="startDate"
        label="Fecha de inicio"
        content={(row, column, index) => parseDate(column, row)}
        sortable />
      <DataTable.Column
        name="finishDate"
        label="Fecha de término"
        content={(row, column, index) => parseDate(column, row)}
        sortable />
      <DataTable.Column
        name="status"
        label="Estatus"
        content={({ status }) => format.status(status)}
        sortable />
      <DataTable.Column
        name="actions"
        label=" "
        content={
          (row, column, index) =>
            <ActionButtons
              index={index}
              project={row}
              { ...actionButtonsProps } />
        } />
    </DataTable>
  );
};
