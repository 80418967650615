import gql from "graphql-tag";

const getVendors = gql`
  {
    vendors {
      city
      colony
      commercialName
      country
      id
      iva
      number
      personType
      postalCode
      razonSocial
      rfc
      state
      street
      count
      contacts {
        name
        email
        phone
        phone_office
      }
    }
  }
`;
export default getVendors;
