import React from "react";
import { Table, ButtonGroup, Button, Icon, Accordion } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye } from "react-icons/io";
import { MdLibraryAdd } from "react-icons/md";
import { format, isInactive } from "../../../../src/helpers";
import { Link } from "react-router-dom";

const RowsQueryAccountBalance = ({ original, aditive, activeIndex, handleClick, handleConfirmModal }) => {
  const item = { ...original, ...aditive, idContractOriginal: original.id, originalFinishDate: original.finishDate };
  let isInactiveProject = isInactive(item.project.status)

  return (
    aditive === null ? (
      <Accordion.Title as={Table.Row} textAlign="center" key={item.id} active={activeIndex === item.IDFolio} index={item.IDFolio} onClick={handleClick}>
        <Table.Cell>{item.aditives.length !== 0 && <Icon name='dropdown' />}</Table.Cell>
        <Table.Cell>Contrato</Table.Cell>
        <Table.Cell>{item.IDFolio}</Table.Cell>
        <Table.Cell>{item.project.name}</Table.Cell>
        <Table.Cell>{item.project.cli}</Table.Cell>
        <Table.Cell>{format.date(item.startDate)}</Table.Cell>
        <Table.Cell>{format.date(item.finishDate)}</Table.Cell>
        <Table.Cell>{item.currency}</Table.Cell>
        <Table.Cell>{format.currency(item.amountContract, item.currency)}</Table.Cell>
        <Table.Cell>{format.currency(item.amountBilled, item.currency)}</Table.Cell>
        <Table.Cell>{format.currency(item.amountPaid, item.currency)}</Table.Cell>
        <Table.Cell><Icon name='circle' color={`${item.status === 'ACTIVE' ? 'olive' : 'red' }`}/></Table.Cell>
        <Table.Cell textAlign="right">
          <ButtonGroup basic>
            <Button
              disabled={isInactiveProject}
              as={Link}
              to={isInactiveProject ? '/estado-de-cuenta/' : `/estado-de-cuenta/${item.id}/new`}
              icon>
              <MdLibraryAdd/>
            </Button>
            <Button
              disabled={isInactiveProject}
              as={Link}
              to={isInactiveProject ? '/estado-de-cuenta/' : {pathname: `/estado-de-cuenta/${item.id}/edit`, state:{ originalContract: true }}}
              icon>
              <IoMdCreate />
            </Button>
            <Button
              disabled={isInactiveProject}
              icon
              onClick={() => isInactiveProject ? null : handleConfirmModal(item, true)}>
              <IoIosTrash />
            </Button>
            <Button
              disabled={isInactiveProject}
              as={Link}
              to={isInactiveProject ? '/estado-de-cuenta/' : {pathname: `/estado-de-cuenta/${item.id}/detail`, state:{ originalContract: true }}}
              icon>
              <IoMdEye />
            </Button>
          </ButtonGroup>
        </Table.Cell>
      </Accordion.Title>
    ) : (
      <Accordion.Content as={Table.Row} textAlign="center" key={item.id} active={activeIndex === item.IDFolio}>
        <Table.Cell/>
        <Table.Cell>Aditiva</Table.Cell>
        <Table.Cell>{item.IDFolio}</Table.Cell>
        <Table.Cell>{item.project.name}</Table.Cell>
        <Table.Cell>{item.project.cli}</Table.Cell>
        <Table.Cell>{format.date(item.startDate)}</Table.Cell>
        <Table.Cell>{format.date(item.finishDate)}</Table.Cell>
        <Table.Cell>{item.currency}</Table.Cell>
        <Table.Cell>{format.currency(item.amountContract, item.currency)}</Table.Cell>
        <Table.Cell>$0.00</Table.Cell>
        <Table.Cell>$0.00</Table.Cell>
        <Table.Cell><Icon name='circle' color={`${item.status === 'ACTIVE' ? 'olive' : 'red' }`}/></Table.Cell>
        <Table.Cell textAlign="right">
          <ButtonGroup basic>
            <Button
              disabled={isInactiveProject}
              as={Link}
              to={isInactiveProject ? '/estado-de-cuenta/' : {pathname: `/estado-de-cuenta/${item.id}/edit`, state:{ originalContract: false }}}
              icon>
              <IoMdCreate />
            </Button>
            <Button
              disabled={isInactiveProject}
              icon
              onClick={() => isInactiveProject ? null : handleConfirmModal(item, false)}>
              <IoIosTrash />
            </Button>
            <Button
              disabled={isInactiveProject}
              as={Link}
              to={isInactiveProject ? '/estado-de-cuenta/' : {pathname: `/estado-de-cuenta/${item.id}/detail`, state: { originalContract: false }}}
              icon>
              <IoMdEye />
            </Button>
          </ButtonGroup>
        </Table.Cell>
      </Accordion.Content>
    )
  )
}

export default RowsQueryAccountBalance;
