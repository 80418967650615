import React from "react";
import {
  Grid,
  Form,
  Button,
  Container,
  Table,
  TableHeaderCell,
  Label,
  Dropdown,
  Pagination,
  Confirm,
} from "semantic-ui-react";
import _ from "lodash";
import tableHeaderFields from "./table-header-fields";
import ReceptionsQuery from "../../Molecules/receptionsQuery";
import SupplyListReceptionModal from "../../Organisms/SupplyListReceptionModal";
import updateReceptionStatus from "../../../graphql/mutations/inventories/updateReceptionStatus";
import updateReceptionPaymentStatus from "../../../graphql/mutations/inventories/updateReceptionPaymentStatus";
import theme from "../../_theme";

const entriesOptions = [
  { key: 1, text: "10", value: 10 },
  { key: 2, text: "20", value: 20 },
];

export default class ReceptionsListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      direction: "descending",
      toggleOrder: true,
      activePage: 1,
      entriesNumber: 10,
      totalRecords: 0,
      receptionModal: false,
      confirm: false,
      confirmText: "",
      status: null,
      id: null,
      type: null,
      refetch: null,
    };
  }

  // Sort columns on click
  handleSort = (clickedColumn) => {
    const { direction, column } = this.state;
    this.setState({
      column: clickedColumn,
      direction: clickedColumn !== column ? "ascending" : direction === "ascending" ? "descending" : "ascending"
    });
  };

  getCount = (totalRecords, first, activePage) => {
    this.setState({ totalRecords, first, activePage });
  }

  onChangeEntriesNumber = (e, { name, value }) =>
    this.setState({ entriesNumber: value });

  handleReceptionModal = id => {
    this.setState(data => ({
      ...data,
      receptionModal: !this.state.receptionModal,
      selectedItemId: id,
    }));
  };

  onPageChange = (e, { activePage }) =>
    this.setState({ activePage });

  onChangeEntriesNumber = (e, { name, value }) =>
    this.setState({ entriesNumber: value });

  openUpdateReceptionStatus = (id, status) => {
    this.setState(data => ({
      ...data,
      confirm: true,
      confirmText: this.parseStatus(status),
      status,
      id,
      type: 0,
    }));
  };

  openUpdateReceptionPaymentStatus = (id, status) => {
    this.setState(data => ({
      ...data,
      confirm: true,
      confirmText: this.parsePaymentStatus(status),
      status,
      id,
      type: 1,
    }));
  };

  updateReceptionStatus = () => {
    const { id, status } = this.state;
    updateReceptionStatus(id, status)
      .then(res => {
        this.setState({ confirm: false });
        window.location.reload();
      })
      .catch(e => console.log(e));
  };

  updateReceptionPaymentStatus = () => {
    const { id, status } = this.state;
    updateReceptionPaymentStatus(id, status)
      .then(res => {
        this.setState({ confirm: false });
        window.location.reload();
      })
      .catch(e => console.log(e));
  };

  parseStatus = status => {
    const STATUS_TYPE = {
      PENDING: "Pendiente",
      PARCIAL_INTERNATIONAL: "Parcialmente recibido internacional",
      COMPLETE_INTERNATIONAL: "Completamente recibido internacional",
      IMPORTED: "Importado",
      PARCIAL_NATIONAL: "Parcialmente recibido nacional",
      COMPLETE: "Completo",
      CANCELLED: "Cancelado",
    };
    return STATUS_TYPE[status];
  };

  parsePaymentStatus = status => {
    const STATUS_TYPE = {
      PAID: "Pagado",
      PARIALLY_PAID: "Parcialmente pagado",
      COMPLETLY_PAID: "Completamente pagado",
      INVOICED: "Facturado",
      PENDING_INVOICE: "Por facturar",
    };
    return STATUS_TYPE[status];
  };

  handleSearch = (e, { name, value }) =>
    this.setState({ search: value, activePage: 1 })

  render() {
    const { search, column, direction, activePage } = this.state;

    return (
      <Grid style={{ minHeight: "650px" }}>
        <Grid.Row className="Wrapper__Body-Content">
          <Grid.Column width="13">
            <Form className="Wrapper__Body-Form Jax">
              <Form.Field inline>
                <label>Buscar</label>
                <Form.Input
                  type="text"
                  icon="search"
                  onChange={this.handleSearch}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column>
            <Table
              basic="very"
              compact
              textAlign="center"
              sortable
              className="Wrapper__Body-Table"
              style={{ paddingBottom: "150px" }}
            >
              <Table.Header className="Wrapper__Body-Table-Header Small Caps">
                <Table.Row>
                  {Object.keys(tableHeaderFields).map((key) => (
                    <TableHeaderCell
                      sorted={column === key ? direction : "descending"}
                      onClick={() => this.handleSort(key)}
                      style={{
                        border: "none",
                        borderBottom: "1px solid #dddddd",
                      }}
                      key={key}
                    >
                      {tableHeaderFields[key]}
                    </TableHeaderCell>
                  ))}
                  <TableHeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body className="Wrapper__Body-Table-Body Small Caps">
                <ReceptionsQuery
                  sort={{ column, direction }}
                  search={search}
                  getCount={this.getCount}
                  first={this.state.entriesNumber}
                  activePage={activePage}
                  openReceptionModal={this.handleReceptionModal}
                  updateReceptionStatus={this.openUpdateReceptionStatus}
                  updateReceptionPaymentStatus={
                    this.openUpdateReceptionPaymentStatus
                  }
                />
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid columns={3} divided="vertically" centered>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Label style={theme.styles.paginationLabel}>Ver</Label>
                <Dropdown
                  id="recordsDropdown"
                  name="recordsDropdown"
                  text={String(this.state.entriesNumber)}
                  options={entriesOptions}
                  selection
                  item
                  compact
                  onChange={this.onChangeEntriesNumber}
                />
                <Label style={theme.styles.paginationLabel}>Entradas</Label>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Label style={theme.styles.entriesLabel}>Viendo</Label>
                <Label style={theme.styles.entriesBoldLabel}>
                  {this.state.first}
                </Label>
                <Label style={theme.styles.entriesLabel}>de</Label>
                <Label style={theme.styles.entriesBoldLabel}>
                  {this.state.totalRecords}
                </Label>
                <Label style={theme.styles.entriesLabel}>Entradas</Label>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Pagination
                  className='Pagination-Fix'
                  boundaryRange={1}
                  siblingRange={0}
                  size='mini'
                  activePage={activePage}
                  totalPages={Math.ceil(this.state.totalRecords / this.state.entriesNumber)}
                  onPageChange={this.onPageChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Row>
        <SupplyListReceptionModal
          open={this.state.receptionModal}
          onClose={this.handleReceptionModal}
          selectedItemId={this.state.selectedItemId}
        />
        <Confirm
          open={this.state.confirm}
          content={`Cambiar estatus a ${this.state.confirmText}`}
          onCancel={() => {
            this.setState({ confirm: false });
            window.location.reload();
          }}
          onConfirm={
            this.state.type === 0
              ? this.updateReceptionStatus
              : this.updateReceptionPaymentStatus
          }
        />
      </Grid>
    );
  }
}
