import gql from "graphql-tag";

const GET_CONTRACTS = gql`
  query queryContracts(
    $first: Int
    $skip: Int
    $orderBy: ContractOrderByInput
    $search: String
    $searchUpper: String
    $searchLower: String
    $searchCaps: String
    $date: DateTime
    $date_lt: DateTime
    $status: ContractStatus
  ) {
    contracts(
      first: $first 
      skip: $skip
      orderBy: $orderBy
      where: {
        startDate_gte: $date
        finishDate_lte: $date_lt
        status: $status  
        OR: [
          { project: { name_contains: $search } }
          { project: { name_contains: $searchUpper } }
          { project: { name_contains: $searchLower } }
          { project: { name_contains: $searchCaps } }
          { contractor: { name_contains: $search } }
          { contractor: { name_contains: $searchUpper } }
          { contractor: { name_contains: $searchLower } }
          { contractor: { name_contains: $searchCaps } }
        ]
      }
    ) {
      name
      consecutive
      contractType
      guaranteeFund
      project {
        name
        cli
        id
        status  
      }
      contractor {
        name
        id
      }
      id
      startDate
      finishDate
      startDateSiroc
      finishDateSiroc
      realFinishDate
      total
      invoiced
      paided
      amountAditives
      amountDeductives
      status
      openPercentage
      monthPercentage
      closePercentage
      siroc
      street
      colony
      city
      state
      country
      postalCode
      externalNumber
      currency
      description
      aditives {
        id
        finishDate
      }
      documentsContractor {
        id
        type
        status
        description
        elaborated
        dateElaborated
        status
        refUpload {
          id
          type
          reference
          extension
          createDate
          wildcard
        }
      }
    }
    count: contracts(
      where: {
        startDate_gte: $date
        finishDate_lte: $date_lt
        status: $status
        OR: [
          { project: { name_contains: $search } }
          { project: { name_contains: $searchUpper } }
          { project: { name_contains: $searchLower } }
          { project: { name_contains: $searchCaps } }
          { contractor: { name_contains: $search } }
          { contractor: { name_contains: $searchUpper } }
          { contractor: { name_contains: $searchLower } }
          { contractor: { name_contains: $searchCaps } }
        ]
      }
    ) {
      id
    }
  }
`;

export default GET_CONTRACTS;
