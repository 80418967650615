import React from "react";
import { Grid, Container, Header } from "semantic-ui-react";
import { DashboardLayout, UsersList } from "../../Organisms";

class Users extends React.Component {
  render () {
    return (
      <DashboardLayout activeTab="users">
        <Container fluid>
          <Grid columns="equal">
            <Grid.Row className="Wrapper__Nav">
              <Grid.Column floated="left">
                <Header as="h1" className="Wrapper__Nav-Title">
                  Usuarios
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <UsersList />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </DashboardLayout>
    )
  }
}

export default Users
