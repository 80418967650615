import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { isNullOrUndefined } from "util";

const updateRequisition = async ({
  id,
  folio,
  wasUpdatedAt,
  applicant,
  petitioner,
  status,
  revisedBy,
  authorizedBy,
  projectId,
  vendorId,
  deliveryWarehouse,
  deliveryDate,
  paymentConditions,
  currency,
  notes,
  subtotal,
  iva,
  total,
  retention,
  discount,
  discountPercentage,
  retentionPercentage,
  amount,
  elaboratedEvent,
}) => {
  const mutation = await client.mutate({
    mutation: !isNullOrUndefined(vendorId)
      ? gql`
      mutation {
        updateRequisition(
          data: {
            folio:"${folio}"
            wasUpdatedAt:"${wasUpdatedAt}"
            applicant:"${applicant}"
            petitioner:"${petitioner}"
            status:${status}
            revisedBy:"${revisedBy}"
            authorizedBy:"${authorizedBy}"
            project:{
                connect:{
                    id:"${projectId}"
                }
            }
            vendor:{
                connect:{
                    id:"${vendorId}"
                }
            }
            elaboratedEvent:{
              create:{
                name:"${elaboratedEvent.name}"
                date:"${elaboratedEvent.date}"
                type:${elaboratedEvent.type}
              }
            }
            deliveryWarehouse:"${deliveryWarehouse}"
            deliveryDate: "${deliveryDate}"
            paymentConditions:${paymentConditions}
            currency:${currency}
            notes:"${notes}"
            subtotal:${subtotal}
            iva:${iva}
            total:${total}
            retention:${retention ? retention : 0.0}
            discount:${discount ? discount : 0.0}
            retentionPercentage:${
              retentionPercentage ? retentionPercentage : 0.0
            }
            discountPercentage:${discountPercentage ? discountPercentage : 0.0}
            amount:${amount}
          }
          where: { id: "${id}" }
        ) {
          id
        }
      }
    `
      : gql`
      mutation {
        updateRequisition(
          data: {
            folio:"${folio}"
            wasUpdatedAt:"${wasUpdatedAt}"
            applicant:"${applicant}"
            petitioner:"${petitioner}"
            status:${status}
            revisedBy:"${revisedBy}"
            authorizedBy:"${authorizedBy}"
            project:{
                connect:{
                    id:"${projectId}"
                }
            }
            elaboratedEvent:{
              create:{
                name:"${elaboratedEvent.name}"
                date:"${elaboratedEvent.date}"
                type:${elaboratedEvent.type}
              }
            }
            deliveryWarehouse:"${deliveryWarehouse}"
            deliveryDate: "${deliveryDate}"
            paymentConditions:${paymentConditions}
            currency:${currency}
            notes:"${notes}"
            subtotal:${subtotal}
            iva:${iva}
            total:${total}
            retention:${retention ? retention : 0.0}
            discount:${discount ? discount : 0.0}
            retentionPercentage:${
              retentionPercentage ? retentionPercentage : 0.0
            }
            discountPercentage:${discountPercentage ? discountPercentage : 0.0}
            amount:${amount}
          }
          where: { id: "${id}" }
        ) {
          id
        }
      }
    `,
  });
  return mutation;
};

export default updateRequisition;
