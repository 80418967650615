import gql from "graphql-tag";

const getContractors = gql`
  query contractors(
    $first: Int,
    $skip: Int,
    $orderBy: ContractorOrderByInput
    $search: String
    $searchUpper: String
    $searchLower: String
    $searchCaps: String
){
  contractors(
    first: $first
    skip: $skip
    orderBy: $orderBy
    where: {
      OR: [
        { name_contains: $search }
        { name_contains: $searchUpper }
        { name_contains: $searchLower }
        { name_contains: $searchCaps }
      ]
    }
  ){
    id
    name
    commercialName
    street
    colony
    city
    rfc
    externalNumber
    postalCode
    personType
    internalNumber
    state
    country
    contacts {
      name
      email
      phone
      type
    }
    user {
      email
    }
    contracts{
      id
    }
  }
}`;

export default getContractors;
