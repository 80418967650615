import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async email => {
  return await client.query({
    query: gql`{
      user(where: { email: "${email}"}){
        email
      }   
    }`,
    fetchPolicy: "network-only"
  });
};