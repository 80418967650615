export default {
  createProject: [
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "cliId",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "cliBudget",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "startDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "finishDate",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "status",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "address",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ]
};
