import React from "react";
import AddWarehouseContainer from "./container";
import { Mutation } from "react-apollo";
import createWarehouse from "../../../graphql/mutations/createWarehouse";

export default class AddWarehouse extends React.Component {
    state = {
        fields: {
            name: "",
            street: "",
            city: "",
            country: "",
            postalCode: "",
            contactName: "",
            contactNumber: "",
            email: "",
            state: "",
            status: ""
        },
        errors: {
            name: false,
            street: false,
            city: false,
            country: false,
            postalCode: false,
            contactName: false,
            contactNumber: false,
            email: false,
            state: false,
            status: false
        }
    }

    validate = () => {
        let keys = Object.keys(this.state.fields);
        let errors = Object.assign({}, this.state.errors);
        let hasError = false;
        let fields = this.state.fields;

        keys.forEach(key => {
            if(!fields[key]) {
                errors[key] = true;
                hasError = true;
            }
        })

        return hasError ? errors : false;
    }

    onChange = (e) => {
        let { value, name } = e.target;
        let fields = {...this.state.fields, [name]: value}
        let errors = {...this.state.errors, [name]: false}

        this.setState({ fields, errors });
    }

    onChangeSelect = (e, { value, name }) => {
        let fields = {...this.state.fields, [name]: value}
        let errors = {...this.state.errors, [name]: false}
        this.setState({ fields, errors })
    }

    setErrors = (errors) => this.setState({errors});

    render() {
        return (
            <Mutation mutation={createWarehouse}>{mutate =>
                <div className="Modal">
                    <AddWarehouseContainer fields={this.state.fields}
                        errors={this.state.errors}
                        onChange={this.onChange}
                        onChangeSelect={this.onChangeSelect}
                        mutate={mutate}
                        setErrors={this.setErrors}
                        validate={this.validate}
                        onReload={this.props.onReload}
                    />
                </div>
            }</Mutation>
        );
    }
}
