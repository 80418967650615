import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async (id, taskID, start_date) => {
  const mutation = await client.mutate({
    mutation: gql`mutation{
      updateProject(
        where:{id:"${id}"}
        data:{
          tasks:{
            update:{
              where:{id:"${taskID}"}
              data:{
                start_date:"${start_date}"
              }
            }
          }
        }
      ){
        id
      }
    }
    `,
  });
  return mutation;
};
