import React from "react";
import {
  Container,
  Form,
  Input,
  Segment,
  Table,
  Label,
  Grid,
  Header,
  Button,
} from "semantic-ui-react";
import { ApolloProvider } from "react-apollo";
import { styles } from "../../theme";
import { client } from "../../../graphql/apollo-config";
import OnLoading from "../../Molecules/OnLoading";

export default class DetailVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      contacts: [],
      contactTable: [],
      city: "",
      colony: "",
      commercialName: "",
      country: "",
      iva: "",
      number: "",
      personType: "",
      postalCode: "",
      razonSocial: "",
      rfc: "",
      state: "",
      street: "",
      name: "",
      email: "",
      phone: "",
      phone_office: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      id,
      city,
      colony,
      commercialName,
      country,
      iva,
      number,
      personType,
      postalCode,
      razonSocial,
      rfc,
      state,
      street,
      contacts,
    } = this.props.data;
    this.setState(data => ({
      ...data,
      id,
      city,
      colony,
      commercialName,
      country,
      iva,
      number,
      personType,
      postalCode,
      razonSocial,
      rfc,
      state,
      street,
      contacts,
      isLoading: false,
      currentContacts: contacts,
      contactTable: contacts,
      personTypeOptions:
        personType === "EXTRANJERO"
          ? [{ key: "extranjero", value: "EXTRANJERO", text: "Extranjero" }]
          : [
              { key: "fisica", value: "FISICA", text: "Fisica" },
              { key: "moral", value: "MORAL", text: "Moral" },
            ],
    }));
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <Container
          style={{
            ...styles.segment_container,
            margin: "0 auto",
            width: "100%",
          }}
        >
          <Segment raised className="Modal">
            <Header as="h3" dividing className="Modal__Header">
              <Grid columns="equal">
                <Grid.Row>
                  <Grid.Column textAlign="left" className="Modal__Header-Title">
                    Detalle de proveedor
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <Button
                      id="cancel-vendor-detial-btn"
                      onClick={this.props.onCancel}
                    >
                      Cancelar
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
            {this.state.isLoading
              ? <OnLoading loading={this.state.isLoading} />
              : (
                  <>
                    <Form className="Modal__Form">
                      <Form.Group widths="equal">
                        <Form.Field
                          name="commercialName"
                          control={Input}
                          type="text"
                          label="NOMBRE COMERCIAL"
                          value={this.state.commercialName}
                          placeholder={this.state.commercialName}
                          readOnly={true}
                        />
                        <Form.Field
                          name="postalCode"
                          control={Input}
                          label="CÓDIGO POSTAL"
                          value={this.state.postalCode}
                          placeholder={this.state.postalCode}
                          readOnly={true}
                        />
                        <Form.Field
                          name="country"
                          control={Input}
                          label="PAÍS"
                          value={this.state.country}
                          readOnly={true}
                        />

                        <Form.Field
                          name="rfc"
                          control={Input}
                          type="text"
                          label="RFC"
                          value={this.state.rfc}
                          readOnly={true}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          label="DIRECCIÓN"
                          name="street"
                          control={Input}
                          placeholder="Calle"
                          value={this.state.street}
                          onChange={this.handleChange}
                          error={this.state.streetError}
                          readOnly={true}
                          width={5}
                        />
                        <Form.Field
                          label="&nbsp;"
                          name="number"
                          control={Input}
                          placeholder="Número"
                          value={this.state.number}
                          onChange={this.handleChange}
                          error={this.state.numberError}
                          readOnly={true}
                          width={5}
                        />
                        <Form.Field
                          control={Input}
                          label="CIUDAD"
                          name="city"
                          value={this.state.city}
                          readOnly={true}
                          width={10}
                        />
                        <Form.Field
                          control={Input}
                          label="TIPO DE PERSONA"
                          name="personType"
                          readOnly={true}
                          value={this.state.personType}
                          width={10}
                        />
                        <Form.Field
                          control={Input}
                          label="IVA"
                          name="iva"
                          readOnly={true}
                          value={this.state.iva}
                          width={10}
                        />
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Field
                          name="colonuy"
                          control={Input}
                          label="COLONIA"
                          value={this.state.colony}
                          readOnly={true}
                        />
                        <Form.Field
                          name="state"
                          control={Input}
                          label="ESTADO"
                          value={this.state.state}
                          readOnly={true}
                        />
                        <Form.Field
                          name="revisedBy"
                          control={Input}
                          label="RAZÓN SOCIAL"
                          value={this.state.razonSocial}
                          readOnly={true}
                        />
                        <Form.Field />
                      </Form.Group>
                    </Form>
                    <Header as="h3" dividing className="Modal__Form-Header">
                      Contacto
                    </Header>
                    <Table
                      basic="very"
                      compact="very"
                      textAlign="center"
                      className="Modal__Form-Table"
                    >
                      <Table.Header className="Modal__Form-Table-Header">
                        <Table.Row>
                          <Table.HeaderCell>Nombre del contacto</Table.HeaderCell>
                          <Table.HeaderCell>Correo electrónico</Table.HeaderCell>
                          <Table.HeaderCell>Teléfono celular</Table.HeaderCell>
                          <Table.HeaderCell>Telefono oficina</Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body className="Modal__Form-Table-Body">
                        {this.state.contacts.map(contact => {
                          return (
                            <Table.Row key={contact.id}>
                              <Table.Cell key={contact.name}>{contact.name}</Table.Cell>
                              <Table.Cell key={contact.email}>
                                {contact.email}
                              </Table.Cell>
                              <Table.Cell key={contact.phone}>
                                {contact.phone}
                              </Table.Cell>
                              <Table.Cell key={contact.phone_office}>
                                {contact.phone_office}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </>
                )
            }
          </Segment>
        </Container>
      </ApolloProvider>
    );
  }
}
