import React from "react";
import { Button } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye } from "react-icons/io";

export const ActionButtons = ({
  vendor,
  onUpdate,
  _deleteVendor,
  openDetails,
  index
}) => {

  return (
    <Button.Group basic>
      <Button
        id={`edit-vendor-btn-${vendor.id}`}
        onClick={() => onUpdate(true, index, vendor.id, vendor)}
        icon
      >
        <IoMdCreate />
      </Button>
      <Button
        id={`delete-vendor-btn-${vendor.id}`}
        onClick={() => _deleteVendor(vendor.id)}
        icon>
        <IoIosTrash />
      </Button>
      <Button
        onClick={() => openDetails(vendor, index)}
        icon>
        <IoMdEye />
      </Button>
    </Button.Group>
  );
};