import React, { useState } from "react";
import { Query } from "react-apollo";
import * as _ from "lodash";

const DataTable = ({
  onOrderChange,
  onRowClick,
  query,
  variables,
  children,
  template,
  itemsKey,
  defaultContent = "N/A",
  fetchPolicy="network-only",
  onCompleted,
  ...props
}) => {
  let self = {};
  self.columns = React.Children.toArray(children);
  template = new template(props, self);

  const [headers, setHeaders] = useState(
    Array.from(self.columns).map((column, index) => {
      const { name, label, sortable } = column.props
      return { name, label, sorted: null, index, sortable };
    })
  );

  self.headers = headers;
  self.setHeaders = setHeaders;

  self.onHeaderClick = header => {
    if(!header.sortable) return false;

    let sorted = "ascending";

    if(header.sorted) {
      sorted = header.sorted === "ascending" ? "descending" : "ascending";
    }

    self.headers = self.headers.map(h => ({ ...h, sorted: null }));
    self.headers[header.index] = { ...header, sorted: sorted };

    onOrderChange && onOrderChange({
      ...self.headers[header.index],
      orderEnum: self.getOrderEnum(header.name, sorted)
    });

    self.setHeaders(Array.from(self.headers));
  };

  self.getOrderEnum = (name, order) => {
    order = order === "ascending" ? "ASC" : "DESC";
    return [name, order].join("_");
  };

  const formatData = data => {
    const rows = data[itemsKey].map((row, index) => {
      return self.columns.map(column => {
        let content = defaultContent;

        if(column.props.content && typeof column.props.content === "function") {
          content = column.props.content(row, column, index);
        } else {
          content = _.get(
            row,
            column.props.content || column.props.name,
            column.props.defaultContent || defaultContent
          );
        }

        // Esto solo aplica para proyects obtener nombre del cliente
        if (column.props.name === "client" && typeof content === "object") {
          const { commercialName } = content;
          return { object: row, content: commercialName, index }
        } else {
          return { object: row, content, index };
        }
      });
    });

    return { rows };
  };

  self.onRowClick = row => onRowClick && onRowClick(row);

  return (
    <Query
      query={query}
      variables={variables}
      fetchPolicy={fetchPolicy}
      onCompleted={onCompleted}>
      { ({ loading, error, data, refetch }) => {
        refetch();
        if(loading)
          return (
            <>
              { template.renderLoading() }
            </>
          );

        if(error)
          return (
            <>
              { template.renderError(error) }
            </>
          );

        data = formatData(data);

        return (
          <>
            { template.renderTable(data) }
          </>
        );
      } }
    </Query>
  );
};

/* Empty component to use as data container */
DataTable.Column = ({
  name,
  content,
  label,
  defaultContent,
  sortable
}) => {
  return null;
};

export default DataTable;
