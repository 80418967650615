import React from "react"
import { Grid, Segment, Form } from "semantic-ui-react";
import DocumentsContractQuery from "../../Molecules/DocumentsContractQuery";

const DocumentsContractList = () => {
  return (
    <Segment>
      <Grid>
        {/* Section Filter and Add Button */}
        <Grid.Row className="Wrapper__Body-Content">
          <Grid.Column>
            <Form className="Wrapper__Body-Form Jax">
              <Form.Input placeholder="Buscar" icon="search" width="3"/>
            </Form>
          </Grid.Column>
        </Grid.Row>

        {/* Section Table List */}
        <Grid.Row centered>
          <Grid.Column>
            <DocumentsContractQuery />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default DocumentsContractList
