import React from "react";
import { Container, Feed, Icon } from "semantic-ui-react"
import { Query } from "react-apollo";
import { GET_TIME_LINE } from "../../../graphql/queries/accountBalance"
import { format } from "../../../helpers"

class TimeLineAccountBalance extends React.Component {
  render() {
    return (
      <Container>
        <Feed style={{ margin: "30px", borderLeft: "2px solid #1890FF" }}>
          <Query query={GET_TIME_LINE} variables={ { id: this.props.idContract } } fetchPolicy="network-only">
            {({ error, loading, data }) => {
              if (error) return <p>Error...</p>;

              if (loading) return <p>Cargando...</p>;

              return data.contractAccountBalance.elaboratedEvent.map(({ name, date, description }, index) => {
                return (
                  <Feed.Event>
                    <Feed.Label>
                      <Icon name="circle" color="blue" style={{ color: "#1890ff" }}/>
                    </Feed.Label>
                    <Feed.Content>
                      <Feed.Summary>
                        <span>{name} {description}</span>
                      </Feed.Summary>
                      <Feed.Date style={{ padding: "8px" }}>
                        <p>{format.date(date, 4)}</p>
                      </Feed.Date>
                    </Feed.Content>
                  </Feed.Event>
                )}
              )
            }}
          </Query>
        </Feed>
      </Container>
    )
  }
}

export default TimeLineAccountBalance