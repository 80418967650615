import React from 'react';
import { Form, Input } from "semantic-ui-react";

class ContractorContactModal extends React.Component {
  state = {
    ...this.props.data,
    errors: {
      name: false,
      email: false,
      phone: false,
      type: false,
    }
  }

  render() {
    return (
      <Form className="Modal__Form-SubModal-Form">
        <Form.Field required
          control={Input}
          label="NOMBRE"
          name="name"
          defaultValue={this.state.name}
          onChange={this.props.handler}
          error={this.props.errors.name}
        />
        <Form.Field required
          control={Input}
          label="CORREO ELECTRÓNICO"
          name="email"
          defaultValue={this.state.email}
          onChange={this.props.handler}
          type="email"
          error={this.props.errors.email}
        />
        <Form.Group widths="equal">
          <Form.Field required
            control={Input}
            label="TELÉFONO"
            name="phone"
            defaultValue={this.state.phone}
            onChange={this.props.handler}
            error={this.props.errors.phone}
          />
          <Form.Select required
            placeholder="Seleccionar"
            label="TIPO"
            name="type"
            options={this.props.phoneType}
            defaultValue={this.state.type}
            onChange={this.props.handler}
            error={this.props.errors.type}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default ContractorContactModal
