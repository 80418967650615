const generateIDFolio = (data, isAditive) => {
  if (isAditive) return data[0].IDFolio;
  const last = (data.length > 0 ? +data[0].IDFolio.slice(-4) : 0) + 1;
  return `CONT-${leftFillNum(last, 4)}`;
};

const leftFillNum = (num, width) => {
  return num.toString().padStart(width, 0);
};

const currencies = () => {
  return [
    { key: "1", text: "MXN", value: "MXN" },
    { key: "2", text: "USD", value: "USD" },
  ];
};

const status = () => {
  return [
    { key: "1", text: "Activo", value: "ACTIVE" },
    { key: "2", text: "Inactivo", value: "INACTIVE" },
  ];
};

const validator = object => {
  let errors = {};
  for (const key in object) {
    const element = object[key];
    if (
      element === "" ||
      element === undefined ||
      element === null ||
      element < 0
    ) {
      errors[key] = true;
    } else {
      errors[key] = false;
    }
  }

  const validator = Object.keys(errors).filter(key => errors[key] === true);

  return { errors, validator: validator.length > 0 ? true : false };
};

const validateValue = (type, value) => {
  if (type === "number") {
    if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value)) return false;
    return true;
  }
  return true;
};

const statusOptions = status => {
  const typeStatus = {
    INVOICED: "Facturado",
    PAID_OUT: "Pagado",
    CANCELLED: "Cancelado",
    BY_BILL: "Por facturar",
    CREATED: "Creado",
    AUTHORIZED: "Autorizado",
    ESTIMATED: "Estimado",
    DEDUCTED: "Deducido"
  };
  return typeStatus[status];
};

const addStatus = (status, typeDocument = null) => {
  const getStatus = currentStatus(status, typeDocument);

  return getStatus.map((value, key) => {
    return {
      key: key + 1,
      text: statusOptions(value),
      value,
    };
  });
};

const currentStatus = (status, typeDocument) => {
  switch (status) {
    case "CREATED":
      if (typeDocument === "DEDUCTIVE") return ["CREATED", "AUTHORIZED", "CANCELLED"]
      return ["CREATED", "ESTIMATED", "CANCELLED"];
    case "ESTIMATED":
      return ["ESTIMATED", "AUTHORIZED", "CANCELLED"];
    case "AUTHORIZED":
      if (typeDocument === "DEDUCTIVE") return ["AUTHORIZED", "CANCELLED"]
      return ["AUTHORIZED", "INVOICED", "PAID_OUT", "CANCELLED"];
    case "INVOICED":
      return ["INVOICED", "PAID_OUT", "CANCELLED"];
    case "PAID_OUT":
      return ["PAID_OUT", "CANCELLED"];
    case "CANCELLED":
      return ["CANCELLED"];
    case "DEDUCTED":
      return ["DEDUCTED", "CANCELLED"]
    default:
      // if (typeDocument === "DEDUCTIVE") return ["DEDUCTED", "CANCELLED"];
      return ["CREATED"];
  }
};

const getExtesion = filename => {
  return filename.split(".").pop();
};

const cleaningData = data => {
  //Replacing JSON forbidden characters
  let str = data.replace(/([$#"^{}¨!"?])/g, "");
  //Replacing not printable characters
  str = str.replace(/([\n\r\t?])/g, "");
  //Scaping special characters
  // str = str.replace(/([<>"()?])/g, "\\$1");
  return str;
};

const uploadFile = async file => {
  const form = new FormData();
  form.append("file", file);
  const res = await fetch(process.env.REACT_APP_S3, {
    method: "POST",
    body: form,
  });
  const { imageUrl } = await res.json();
  const extension = getExtesion(imageUrl);

  return { reference: imageUrl, extension };
};

const findObject = (array, item) => {
  const search = array.find(ref => ref.type === item);
  return search ? search.reference : null;
};

const acceptedFormats = acceptedFiles => {
  const types = acceptedFiles.map(item => {
    const indexOf = item.indexOf("/");
    return item.substr(indexOf + 1).toUpperCase();
  });

  return types.toString();
};

export default {
  generateIDFolio,
  currencies,
  status,
  validator,
  validateValue,
  addStatus,
  cleaningData,
  uploadFile,
  findObject,
  acceptedFormats,
  statusOptions
};
