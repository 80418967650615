import React from "react";
import SidebarItem from "./sidebarITem";
import { IoMdCart, IoIosFiling, IoIosArchive } from "react-icons/io";

export default props => {
  return (
    <React.Fragment>
      <SidebarItem
        {...props}
        name="catalogs"
        text="Catálogos"
        to="/catalogos"
        icon={<IoIosFiling />}
      />
      <SidebarItem
        {...props}
        text="Inventarios"
        name="inventories"
        to="/inventarios"
        icon={<IoIosArchive />}
      />
    </React.Fragment>
  );
};
