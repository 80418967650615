import React from "react";
import { Container, Grid, GridRow, GridColumn, Header } from "semantic-ui-react";
import routes from "./routes";
import { PoNavigation } from "../../Molecules";
import CreateReq from "../../Organisms/createReq";
import CreatePO from "../../Organisms/createPO";
import CreatePOReq from "../../Organisms/createPOFromReq";
import EditRequisition from "../../Organisms/editRequisition";
import DetailRequisition from "../../Organisms/detailRequisition";
import DetailPO from "../../Organisms/detailPO";
import EditPO from "../../Organisms/editPO";
import DashboardLayout from "../../Organisms/DashboardLayout";

export default class Purchases extends React.Component {
  constructor(props) {
    super(props);
    const { item: ActiveRouteItem, routeName: activeRoute } = routes[0];
    this.state = {
      toggleContent: true,
      isUpdate: false,
      activeRoute,
      ActiveRouteItem,
      index: null,
      id: null,
      vendors: [],
      currentVendor: null,
      renderAddButton: false,
      renderTitle: false,
      addComponent: "req",
      poFromReq: false,
      editReq: false,
      detailReq: false,
      createPO: false,
      createReq: false,
      detailPO: false,
      editPO: false,
      session: JSON.parse(window.localStorage.session).user
    };
  }

  toggleSave = (req, index, id) => {
    this.setState(data => ({
      ...data,
      toggleContent: true,
    }));
  };

  setReqRefetch = (refetch) =>
    this.setState({ reqRefetch: refetch });

  setPORefetch = (refetch) =>
    this.setState({ PORefetch: refetch });

  toggleCreateReq = (req, index, id) => {
    this.setState(data => ({
      ...data,
      createReq: !this.state.createReq,
      toggleContent: !this.state.toggleContent,
      index: null,
      id: null,
      reqConvert: req,
    }));
  };
  toggleCreatePO = (req, index, id) => {
    this.setState(data => ({
      ...data,
      createPO: !this.state.createPO,
      toggleContent: !this.state.toggleContent,
      index: null,
      id: null,
      reqConvert: req,
    }));
  };

  toggleContentPOFromReq = (req, index, id) => {
    this.setState(data => ({
      ...data,
      poFromReq: !this.state.poFromReq,
      toggleContent: !this.state.toggleContent,
      index: null,
      id: null,
      reqConvert: req,
    }));
  };

  toggleEditRequisirtion = requisition => {
    this.setState(data => ({
      ...data,
      editReq: !this.state.editReq,
      toggleContent: !this.state.toggleContent,
      index: null,
      id: null,
      reqEdit: requisition,
    }));
  };

  toggleDetailRequisirtion = requisition => {
    this.setState(data => ({
      ...data,
      detailReq: !this.state.detailReq,
      toggleContent: !this.state.toggleContent,
      index: null,
      id: null,
      reqEdit: requisition,
    }));
  };

  toggleDetailPO = requisition => {
    this.setState(data => ({
      ...data,
      detailPO: !this.state.detailPO,
      toggleContent: !this.state.toggleContent,
      index: null,
      id: null,
      reqEdit: requisition,
    }));
  };

  toggleEditPO = (requisition, refetch) => {
    this.setState(data => ({
      ...data,
      editPO: !this.state.editPO,
      toggleContent: !this.state.toggleContent,
      index: null,
      id: null,
      refetch,
      poToEdit: requisition,
    }));
  };

  navigateTo(route) {
    const { item, routeName } = route;
    this.setState({
      activeRoute: routeName,
      ActiveRouteItem: item,
      addComponent: routeName,
    });
  }

  renderActiveRouteItem() {
    const { ActiveRouteItem } = this.state;
    return ActiveRouteItem ? (
      <ActiveRouteItem
        toggleCreateReq={this.toggleCreateReq}
        onUpdate={this.toggleContent}
        activeRoute={this.state.activeRoute}
        openPOFromReq={this.toggleContentPOFromReq}
        toggleEditRequisirtion={this.toggleEditRequisirtion}
        toggleDetailRequisirtion={this.toggleDetailRequisirtion}
        toggleDetailPO={this.toggleDetailPO}
        toggleCreatePO={this.toggleCreatePO}
        toggleEditPO={this.toggleEditPO}
        setReqRefetch={this.setReqRefetch}
        setPORefetch={this.setPORefetch}
      />
    ) : null;
  }

  renderAddComponent = () => {
    if (this.state.editReq) {
      return (
        <EditRequisition
          refetch={this.state.reqRefetch}
          toggleSave={this.toggleEditRequisirtion}
          data={this.state.reqEdit}
          openCreate={this.toggleContent}
          onCancel={this.toggleEditRequisirtion}
          title="Añadir requisición"
        />
      );
    }
    if (this.state.detailReq) {
      return (
        <DetailRequisition data={this.state.reqEdit} onCancel={this.toggleDetailRequisirtion} />
      );
    }
    if (this.state.createReq) {
      return (
        <CreateReq
          refetch={this.state.reqRefetch}
          openCreate={this.toggleCreateReq}
          toggleSave={this.toggleCreateReq}
          onCancel={this.toggleCreateReq}
          title="Añadir requisición"
        />
      );
    }

    if (this.state.poFromReq) {
      return (
        <CreatePOReq
          refetch={this.state.reqRefetch}
          openCreate={this.toggleContentPOFromReq}
          onCancel={this.toggleContentPOFromReq}
          title="Añadir orden de compra"
          toggleSave={this.toggleCreatePO}
          data={this.state.reqConvert}
          fromReq
        />
      );
    }

    if (this.state.createPO) {
      return (
        <CreatePO
          toggleSave={this.toggleCreatePO}
          refetch={this.state.PORefetch}
          onCancel={this.toggleCreatePO}
          title="Añadir orden de compra"
        />
      );
    }

    if (this.state.detailPO) {
      return (
        <DetailPO onCancel={this.toggleDetailPO} data={this.state.reqEdit} />
      );
    }
    if (this.state.editPO) {
      return (
        <EditPO
          toggleSave={this.toggleEditPO}
          refetch={this.state.PORefetch}
          title="Editar orden de compra"
          onCancel={this.toggleEditPO}
          data={this.state.poToEdit}
        />
      );
    }
  };

  render() {
    return (
      <DashboardLayout activeTab="purchases">
        {!this.state.toggleContent && (
          <Container fluid className="Wrapper__Modal">
            {this.renderAddComponent()}
          </Container>
        )}
        <Container fluid style={{ display: !this.state.toggleContent && "none" }}>
          <Grid columns="equal">
            <GridRow className="Wrapper__Nav">
              <GridColumn floated="left">
                <Header as="h1" className="Wrapper__Nav-Title">
                  Compras
                </Header>
              </GridColumn>
              <GridColumn floated="right">
                <PoNavigation
                  className="navigation"
                  routes={routes}
                  activeRoute={this.state.activeRoute}
                  navigateTo={route => this.navigateTo(route)}
                  style={{ float: "right" }}
                />
              </GridColumn>
            </GridRow>
            <Grid.Row>
              <GridColumn>{this.renderActiveRouteItem()}</GridColumn>
            </Grid.Row>
          </Grid>
        </Container>
      </DashboardLayout>
    );
  }
}
