import gql from "graphql-tag";
import { client } from "../../apollo-config";

const DELETE_DOCUMENT_ADITIVE_ACCOUNT_BALANCE = async (item) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateAditiveAccountBalance(
          data: {
            amountAmortization: ${item.amountAmortization}
            amountByAmortization: ${item.amountByAmortization}
            amountTotalRetained: ${item.amountTotalRetained}
            amountByRetained: ${item.amountByRetained}
            documentsContractAccountBalance: { delete: { id: "${item.idDocument}" } }
          }
          where: { id: "${item.id}" }
        ) {
          id
        }
      }
    `
  });
  return mutation;
};

export default DELETE_DOCUMENT_ADITIVE_ACCOUNT_BALANCE;

