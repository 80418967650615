import React from "react";
import { IoIosTrash } from "react-icons/io";
import { Button, Container, Table, TableRow } from "semantic-ui-react";
import ContactsTableHeader from "../../Molecules/contactsTableHeader";
import AddContactModal from "../../Organisms/addContactModal";
import UpdateContactModal from "../../Organisms/updateContactModal";
import { styles } from "../../theme";
import { store as _store } from "../../../store/store";
import actions from "../../../store/actions";
import { createJSON } from "../../../helpers/utils";
import validator from "../../../helpers/validator";

// Table
class ContactsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Email: "",
      Phone: "",
      Phone_Office: "",
      rows: []
    };
  }

  onSave = () => {
    // Get the supplies data
    const suppliesData = this.getSuppliesData();
    let rows = [...this.state.rows];
    rows.push(suppliesData);
    this.setState(data => ({ ...data, rows }));
    _store.dispatch({ type: actions.ADD_VENDOR_CONTACT, payload: rows });
  };

  onUpdate = index => {
    const newValues = this.getSuppliesData();
    const validation = validator(newValues);
    let rows = [...this.state.rows];
    let isValid = false;
    // loop through validation array in order to ensuer everything is like should be
    validation.map((field, index) => {
      if (!field.isValid) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    if (isValid) {
      rows.splice(index, 1);
      rows.push(newValues);
      this.setState(data => ({ ...data, rows }));
      _store.dispatch({
        type: actions.ADD_VENDOR_CONTACT,
        payload: rows
      });
    } else {
      alert("Todos los campos son obligatorios");
    }
  };

  deleteSupply = index => {
    const suppliesData = this.state.rows;
    suppliesData.splice(index, 1);
    this.setState(data => ({ ...data, suppliesData }));
  };

  getSuppliesData = () => {
    let newSuppliesData = {
      Name: this.state.Name,
      Email: this.state.Email,
      Phone: this.state.Phone,
      Phone_Office: this.state.Phone_Office
    };

    return newSuppliesData;
  };

  handleChange = (e, { name, value }) => {
    this.setState(data => ({
      ...data,
      [name]: value
    }));
  };

  componentDidMount() {
    const data = this.props.contacts;
    if (data) {
      const contacts = createJSON(data.contacts);
      let rows = [...this.state.rows];
      contacts.map(contact => {
        rows.push(contact);
      });
      this.setState(data => ({ ...data, rows }));
      _store.dispatch({
        type: actions.ADD_VENDOR_CONTACT,
        payload: rows
      });
    }
  }

  render() {
    const rows = this.state.rows;
    return (
      <Container>
        <div style={{ borderBottom: "1px solid #ddd", margin: "30px auto" }}>
          <h3>Añadir contacto</h3>
          <br />
        </div>
        <Table
          basic="very"
          style={{ ...styles.table, textAlign: "center" }}
          collapsing
        >
          <ContactsTableHeader />
          <Table.Body>
            {rows.map((row, index) => {
              return (
                <TableRow key={index}>
                  <Table.Cell>{row.Name}</Table.Cell>
                  <Table.Cell>{row.Email}</Table.Cell>
                  <Table.Cell>{row.Phone}</Table.Cell>
                  <Table.Cell>{row.Phone_Office}</Table.Cell>
                  <Table.Cell>
                    <Button.Group basic>
                      <UpdateContactModal
                        data={this.state.rows[index]}
                        onUpdate={() => this.onUpdate(index)}
                        handleChange={this.handleChange}
                      />
                      <Button
                        style={styles.button_group}
                        onClick={() => this.deleteSupply(index)}
                      >
                        <IoIosTrash />
                      </Button>
                    </Button.Group>
                  </Table.Cell>
                </TableRow>
              );
            })}
          </Table.Body>
        </Table>
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <AddContactModal
            data={this.state}
            onSave={this.onSave}
            handleChange={this.handleChange}
          />
        </div>
      </Container>
    );
  }
}

export default ContactsTable;
