import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async ({
  id,
  taskID,
  new_text,
  duration,
  parent,
  progress,
  start_date,
  index,
}) => {
  const mutation = await client.mutate({
    mutation: gql`mutation{
      updateProject(
        where:{id:"${id}"}
        data:{
          tasks:{
            update:{
              where:{id:"${taskID}"}
              data:{
                start_date:"${start_date}"
                text:"${new_text}"
                parent:${parent ? parent : 0}
                progress:${progress}
                duration:${duration}
              }
            }
          }
        }
      ){
        id
      }
    }
    `,
  });
  return mutation;
};
