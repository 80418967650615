import gql from "graphql-tag";
import { client } from "../../apollo-config";

const deleteContractor = async id => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        deleteContractor(where: { id: "${id}" }) {
          id
        }
      }
    `
  });
  return mutation;
};

export default deleteContractor;
