import React from "react";
import { Form, Input, Grid, Icon, Header, Button, Modal, GridColumn } from "semantic-ui-react";
import PropTypes from "prop-types";
import ContractDocumentContext from "../../Organisms/OriginalContractList/contractDocumentContext";
import { ViewDocumentSegment } from "../../Molecules";
import { format } from "../../../helpers";
import { xml2js } from "xml-js";
import CurrencyInput from 'react-currency-input';
import { Can } from "../../hoc/permissions";
import { bugsnagClient } from "../../../bugsnag";

class ContractFormDocuments extends React.Component {
  state = {
    authModal: false,
    authorizerPassword: "",
    contentFile: [
      { icon: "plus", content: "Agregar archivos"},
      { icon: "cloud upload", content: "Cargando archivo"}
    ],
    inputKeyFile: Date.now(),
    statusFile: {
      BILL: 0,
      PAYMENT_VOUCHER: 0,
      PAYMENT_COMPLEMENT: 0
    },
    acceptedFiles: ["application/pdf", "text/xml"],
    acceptedPDF: ["application/pdf"]
  }

  toggleAuthModal = () => {
    this.setState({
      authModal: !this.state.authModal,
    });
  }

  handlePassword = (e, data) => this.setState({ authorizerPassword: data.value});

  auth = (pass) => {
    this.context.authSuccess(pass);
    this.toggleAuthModal();
  }

  handleDownloadFile = (file) => {
    window.open(file, "_blank");
  }

  uploapFile = async e => {
    const { currentItem, change, changeStatus, addPayment } = this.context;
    const file = e.target.files[0];
    const [typeDoc, typeTotal] = e.target.name.split(",");
    const form = new FormData();
    let totalxml = 0;
    this.setState({ inputKeyFile: Date.now() });

    if (e.target.files.length > 0) {
      if (!e.target.accept.includes(file.type)) return false;

      if (file.type === "text/xml" && typeDoc !== "PAYMENT_VOUCHER") {
        totalxml = await this.totalXML(file);
        if (typeof totalxml === "string") return alert(totalxml);

        const msg = addPayment(typeTotal, totalxml);

        if (msg === "next" && typeDoc === "BILL" || msg === "paidout" && typeDoc === "BILL")
          changeStatus(["INVOICED"]);
        if (msg === "next" && typeDoc === "PAYMENT_COMPLEMENT")
          changeStatus(["PAID_PARTIALLY"]);
        if (msg === "paidout"  && typeDoc === "PAYMENT_COMPLEMENT")
          changeStatus(["PAID_OUT"]);
        if (msg === "exceeded")
          return alert("El monto a cargar excede el importe del anticipo/estimación");
      }

      form.append("file", file);
      this.setState(data => ({ statusFile: {...data.statusFile, [typeDoc] : 1 } }));

      try {
        const res = await fetch(process.env.REACT_APP_S3, {
          method: "POST",
          body: form,
        });
        if (res.ok) {
          const uploaded = await res.json();
          const data = {
            name: "refUpload",
            value: [...currentItem.refUpload,
              {
                type : typeDoc,
                reference: uploaded.imageUrl,
                extension: format.getExtesion(uploaded.imageUrl),
                wildcard: totalxml,
                new: true
              }
            ]
          };

          this.setState(data => ({ statusFile: {...data.statusFile, [typeDoc] : 0 } }));
          change(null, data);
        }
      } catch (err) {
        bugsnagClient.notify(err);
      }
    }
  }

  totalXML = async (file) => {
    const xmlText = await this.readUploadedFileAsText(file);
    const totalxml = xml2js(xmlText, {compact: true,  ignoreComment: true });
    const msg = "El documento que intentas subir es invalido."
    const cfdi = Object.keys(totalxml).length > 0 ? totalxml["cfdi:Comprobante"] : {};

    if (cfdi.hasOwnProperty("_attributes")) {
      const { _attributes } = cfdi;
      return _attributes.hasOwnProperty("SubTotal") ? parseFloat(_attributes.SubTotal) : msg;
    }

    return msg;
  }

  readUploadedFileAsText = (inputFile) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsText(inputFile);
    });
  }

  removeItem = async (doc) => {
    const { idRef, reference, totalReference } = doc;
    const { currentItem, subtractPayment } = this.context;

    await subtractPayment({ currentItem, idRef, reference, totalReference });
  }

  render() {
    const {currentItem, documentStatus, change } = this.context;
    const { contentFile, statusFile } = this.state;
    const { readOnly, readOnlyAuthorized, showFiles, userRol } = this.props;
    const isContractor = userRol === "CONTRACTOR" ? true : false;
    const show = ["PAID_OUT"].includes(currentItem.status);
    const acceptedFiles = currentItem.status === "PAID_OUT" ? this.state.acceptedPDF : this.state.acceptedFiles;

    // Tipos de documentos
    const bills = currentItem.refUpload.filter(item => item.type === "BILL");
    const vouchers = currentItem.refUpload.filter(item => item.type === "PAYMENT_VOUCHER");
    const complements = currentItem.refUpload.filter(item => item.type === "PAYMENT_COMPLEMENT");
    let fileInput1, fileInput2, fileInput3 = null;

    return (
      <React.Fragment>
        <Form className="Modal__Form-SubModal-Form" autoComplete="off">
          <Grid>
            <Grid.Row>
              <Grid.Column floated="left" width="4">
                <Form.Input required
                  label="FECHA"
                  name="dateElaborated"
                  value={format.date(currentItem.dateElaborated, 2)}
                  type="date"
                  icon="calendar"
                  readOnly={readOnly || readOnlyAuthorized}
                  onChange={change}
                  />
              </Grid.Column>
              <Grid.Column floated="right" width="4">
                <Form.Select
                  label="ESTATUS"
                  name="status"
                  value={currentItem.status}
                  options={documentStatus}
                  disabled={readOnly || show}
                  onChange={change}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.TextArea required
                  label="CONCEPTO"
                  name="concept"
                  value={currentItem.concept}
                  rows="5"
                  readOnly={readOnly || readOnlyAuthorized}
                  onChange={change}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column floated="left" width="6" verticalAlign="middle">
                <Form.Group widths="equal">
                  <Form.Input
                    label="ELABORÓ"
                    name="elaborated"
                    value={currentItem.elaborated}
                    fluid
                    type="text"
                    readOnly
                    onChange={change}
                  />
                  <Can do="authorize" on="OriginalContract">
                    <Form.Field>
                      <label>CLAVE DE AUTORIZACIÓN</label>
                      {!currentItem.authorizer ? (
                      <Button
                        basic
                        fluid
                        content="Autorizar"
                        className="Modal__Form-SubModal-Form-ButtonAddOutline Large"
                        onClick={this.toggleAuthModal}
                        onChange={change}
                      />) : (
                      <Form.Input
                        name="authorizer"
                        value={currentItem.authorizer}
                        fluid
                        type="text"
                      />)}
                    </Form.Field>
                  </Can>
                </Form.Group>
              </Grid.Column>

              <Grid.Column floated="right" width="4">
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="6">
                    </Grid.Column>
                    <Grid.Column width="10">
                      <Form.Field required disabled={readOnly || readOnlyAuthorized} className="Util-MaskCurrency">
                        <label>IMPORTE</label>
                        <CurrencyInput
                          value={currentItem.amount || ""}
                          onChangeEvent={(event, maskedvalue, floatvalue) => change(null, { name: "amount", value: floatvalue })}/>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="6">
                      <Form.Field required disabled={readOnly || readOnlyAuthorized} className="Util-MaskCurrency">
                        <label>%</label>
                        <CurrencyInput
                          value={currentItem.iva || ""}
                          precision="0"
                          onChangeEvent={(event, maskedvalue, floatvalue) => change(null, { name: "iva", value: floatvalue })}/>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width="10">
                      <Form.Field required disabled className="Util-MaskCurrency">
                        <label>IVA</label>
                        <CurrencyInput
                          value={currentItem.amountIva || ""}
                          onChangeEvent={(event, maskedvalue, floatvalue) => change(null, { name: "amountIva", value: floatvalue })}/>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="6">
                    </Grid.Column>
                    <Grid.Column width="10">
                      <Form.Field required disabled className="Util-MaskCurrency">
                        <label>TOTAL</label>
                        <CurrencyInput
                          value={currentItem.total || ""}
                          onChangeEvent={(event, maskedvalue, floatvalue) => change(null, { name: "total", value: floatvalue })}/>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            {showFiles === "true" && currentItem.authorizer && (
              <React.Fragment>
                <Grid.Row>
                  <GridColumn>
                    <Header as="h3" className="Modal__Form-SubModal-Form-Header">Facturación</Header>
                  </GridColumn>
                </Grid.Row>

                {/* title row */}
                <Grid.Row>
                  <Grid.Column width="4">
                    <Form.Field>
                      <label>FACTURAS</label>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width="12">
                    <Form.Field>
                      <Button
                        basic content={contentFile[statusFile.BILL].content}
                        icon={contentFile[statusFile.BILL].icon} className="Modal__Form-SubModal-Form-ButtonAddOutline"
                        onClick={() => fileInput1.inputRef.click()}
                      />
                      <Input
                        type="file"
                        name={["BILL", "totalInvoiced"]}
                        key={this.state.inputKeyFile}
                        hidden
                        ref={element => { fileInput1 = element }}
                        onChange={this.uploapFile}
                        style={{ display: "none" }}
                        accept={acceptedFiles.toString()}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {/* files list row */}
                {bills.length > 0 &&
                  <Grid.Row>
                    {bills.map(bill => {
                      return (
                        <Grid.Column className="Modal__Form-SubModal-Form-Column-MB" width="4" key={bill.reference}>
                          <ViewDocumentSegment
                            title={format.getNameOriginal(bill.reference)}
                            reference={bill.reference}
                            id={bill.id || null}
                            onDownloadFile={this.handleDownloadFile}
                            uploadDocument={true}
                            readOnly={!readOnly || show}
                            removeItem={this.removeItem}
                            totalReference="totalInvoiced"
                            isContractor={isContractor}
                          />
                        </Grid.Column>
                      )
                    })}
                  </Grid.Row>
                }

                <Grid.Row>
                  <Grid.Column width="4">
                    <Form.Field>
                      <label>COMPROBANTE DE PAGO</label>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width="12">
                  {!isContractor &&
                    <Form.Field>
                      <Button
                        basic content={contentFile[statusFile.PAYMENT_VOUCHER].content}
                        icon={contentFile[statusFile.PAYMENT_VOUCHER].icon} className="Modal__Form-SubModal-Form-ButtonAddOutline"
                        onClick={() => fileInput2.inputRef.click()}
                      />
                      <Input
                        type="file"
                        name={["PAYMENT_VOUCHER"]}
                        key={this.state.inputKeyFile}
                        hidden
                        ref={element => { fileInput2 = element }}
                        onChange={this.uploapFile}
                        style={{ display: "none" }}
                        accept={this.state.acceptedPDF.toString()}
                      />
                    </Form.Field>
                  }
                  </Grid.Column>
                </Grid.Row>

                {/* files list row */}
                {vouchers.length > 0 &&
                    <Grid.Row>
                      {vouchers.map(vaucher => {
                        return (
                          <Grid.Column className="Modal__Form-SubModal-Form-Column-MB" width="4" key={vaucher.reference}>
                            <ViewDocumentSegment
                              title={format.getNameOriginal(vaucher.reference)}
                              reference={vaucher.reference}
                              id={vaucher.id || null}
                              onDownloadFile={this.handleDownloadFile}
                              uploadDocument={true}
                              readOnly={!readOnly || show}
                              removeItem={this.removeItem}
                              isContractor={isContractor}
                            />
                          </Grid.Column>
                        )
                      })}
                    </Grid.Row>
                  }

                <Grid.Row>
                  <Grid.Column width="4">
                    <Form.Field>
                      <label>COMPLEMENTO DE PAGO</label>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width="12">
                    <Form.Field>
                      <Button
                        basic content={contentFile[statusFile.PAYMENT_COMPLEMENT].content}
                        icon={contentFile[statusFile.PAYMENT_COMPLEMENT].icon} className="Modal__Form-SubModal-Form-ButtonAddOutline"
                        onClick={() => fileInput3.inputRef.click()}
                      />
                      <Input
                        type="file"
                        name={["PAYMENT_COMPLEMENT", "totalPaided"]}
                        key={this.state.inputKeyFile}
                        hidden
                        ref={element => { fileInput3 = element }}
                        onChange={this.uploapFile}
                        style={{ display: "none" }}
                        accept={acceptedFiles.toString()}
                        />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>

                {/* files list row */}
                {complements.length > 0 &&
                  <Grid.Row>
                    {complements.map(complement => {
                      return (
                        <Grid.Column className="Modal__Form-SubModal-Form-Column-MB" width="4" key={complement.reference}>
                          <ViewDocumentSegment
                            title={format.getNameOriginal(complement.reference)}
                            reference={complement.reference}
                            id={complement.id || null}
                            onDownloadFile={this.handleDownloadFile}
                            uploadDocument={true}
                            readOnly={!readOnly || show}
                            removeItem={this.removeItem}
                            totalReference="totalPaided"
                            isContractor={isContractor}
                          />
                        </Grid.Column>
                      )
                    })}
                  </Grid.Row>
                }
              </React.Fragment>
            )}
          </Grid>
        </Form>

        <Modal open={this.state.authModal} size="mini" dimmer="inverted" centered closeOnDimmerClick={false}>
          <Modal.Header className="Modal__Form-SubModal-ModalHeader">
            <label>Clave de autorización</label>
            <Icon name="close" onClick={this.toggleAuthModal} link/>
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form className="Modal__Form-SubModal-Form">
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width="10">
                      <Form.Input label="Introduce la clave" name="auth" type="password" onChange={this.handlePassword}/>
                      <Button
                        onClick={() => this.auth(this.state.authorizerPassword)}
                        fluid
                        className="Modal__Form-SubModal-Form-ButtonRed"
                      >
                        Autorizar
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}

ContractFormDocuments.propTypes = {
  readOnly: PropTypes.bool,
  showFiles: PropTypes.string,
}

ContractFormDocuments.defaultProps = {
  readOnly: false,
  showFiles: "false",
}

ContractFormDocuments.contextType = ContractDocumentContext;

export default ContractFormDocuments;
