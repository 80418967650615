import React from "react";
import { Container, Grid, Header, Segment } from "semantic-ui-react";
import { DashboardLayout, TasksAccordion } from "../../Organisms";
import { PoNavigation } from "../../Molecules";
import routes from "./routes";

export default class ProjectTasksGallery extends React.Component {
  constructor(props) {
    super(props);
    const { item: ActiveRouteItem, routeName: activeRoute } = routes[1];
    this.state = {
      activeRoute,
      ActiveRouteItem,
    };
  }
  renderActiveRouteItem() {
    const { ActiveRouteItem } = this.state;
    return ActiveRouteItem ? <ActiveRouteItem /> : null;
  }
  render() {
    return (
      <DashboardLayout activeTab="projectProgress">
        <Container fluid>
          <Grid columns="equal">
            <Grid.Row className="Wrapper__Nav">
              <Grid.Column floated="left">
                <Header as="h2" className="Wrapper__Nav-Title">
                  Galería
                </Header>
              </Grid.Column>
              <Grid.Column floated="right">
                <PoNavigation
                  className="navigation"
                  routes={routes}
                  activeRoute={this.state.activeRoute}
                  navigateTo={() =>
                    (window.location = `/avance/${
                      window.location.pathname.split("a/")[1]
                    }`)
                  }
                  style={{ float: "right" }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Segment raised>
                  <TasksAccordion />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </DashboardLayout>
    );
  }
}
