import React from 'react';
import { Form, Input, Select } from 'semantic-ui-react';
import { FormHeader } from '../../Molecules';
import { withRouter } from "react-router-dom";
import OnLoading from "../../Molecules/OnLoading"

export default withRouter(
class EditWarehouseContainer extends React.Component {
    constructor(props) {
        super(props);

        this.props.setFields(props.warehouse);
    }

    state = {
        submitting: false,
        isLoading: false
    }

    selectOptions = [{key: 0, text: "Activo", value: "ACTIVE"},{key: 1, text: "Inactivo", value: "INACTIVE"}]
    countryOptions = [{key: 0, text: "México", value: "México"},{key: 1, text: "USA", value: "USA"}]

    onCancel = () => this.props.history.push("/catalogos");
    onSave = () => {
        if(this.state.submitting) return;

        let errors = this.props.validate();

        if(errors) return this.props.setErrors(errors);
        this.setState({submitting: true, isLoading: true });

        let variables = {
            data: {
                name: this.props.fields.name,
                street: this.props.fields.street,
                city: this.props.fields.city,
                state: this.props.fields.state,
                country: this.props.fields.country,
                postalCode: Number(this.props.fields.postalCode),
                contactName: this.props.fields.contactName,
                contactEmail: this.props.fields.email,
                contactPhone: this.props.fields.contactNumber,
                status: this.props.fields.status
            },
            where: {id: this.props.id}
        }

        this.props.mutate({ variables }).then((response) => {
            this.setState({submitting: false, isLoading: false });
            this.props.history.push("/catalogos");
            this.props.onReload();
        });
    }

    render() {
        return (
            this.state.isLoading
                ? <OnLoading loading={this.state.isLoading} />
                : (
                    <>
                        <FormHeader text="Editar almacén"
                            onCancel={this.onCancel}
                            onSave={this.onSave}
                            submitting={this.props.submitting}
                        />
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field control={Input}
                                    label="NOMBRE DE ALMACÉN"
                                    name="name"
                                    value={this.props.fields.name}
                                    onChange={this.props.onChange}
                                    error={this.props.errors.name}
                                    required
                                />
                                <Form.Field control={Input}
                                    label="CALLE"
                                    name="street"
                                    value={this.props.fields.street}
                                    onChange={this.props.onChange}
                                    error={this.props.errors.street}
                                    required
                                />
                                <Form.Field control={Input}
                                    label="CIUDAD"
                                    name="city"
                                    value={this.props.fields.city}
                                    onChange={this.props.onChange}
                                    error={this.props.errors.city}
                                    required
                                />
                                <Form.Field control={Select}
                                    label="PAÍS"
                                    name="country"
                                    options={this.countryOptions}
                                    value={this.props.fields.country}
                                    onChange={this.props.onChangeSelect}
                                    error={this.props.errors.country}
                                    required
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field control={Input}
                                    label="ESTADO"
                                    name="state"
                                    value={this.props.fields.state}
                                    onChange={this.props.onChange}
                                    error={this.props.errors.state}
                                    required
                                />
                                <Form.Field control={Input}
                                    label="NOMBRE DE CONTACTO"
                                    name="contactName"
                                    value={this.props.fields.contactName}
                                    onChange={this.props.onChange}
                                    error={this.props.errors.contactName}
                                    required
                                />
                                <Form.Field control={Input}
                                    label="NÚMERO DE TELÉFONO"
                                    name="contactNumber"
                                    value={this.props.fields.contactNumber}
                                    onChange={this.props.onChange}
                                    error={this.props.errors.contactNumber}
                                    required
                                />
                                <Form.Field control={Input}
                                    label="CORREO ELECTRÓNICO"
                                    name="email"
                                    value={this.props.fields.email}
                                    onChange={this.props.onChange}
                                    error={this.props.errors.email}
                                    required
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Field control={Input}
                                    label="CÓDIGO POSTAL"
                                    name="postalCode"
                                    type="number"
                                    width="4"
                                    value={this.props.fields.postalCode}
                                    onChange={this.props.onChange}
                                    error={this.props.errors.postalCode}
                                    required
                                />
                                <Form.Field control={Select}
                                    label="ESTATUS"
                                    name="status"
                                    width="4"
                                    options={this.selectOptions}
                                    onChange={this.props.onChangeSelect}
                                    value={this.props.fields.status}
                                    error={this.props.errors.status}
                                    required
                                />
                            </Form.Group>
                        </Form>
                    </>
                )
        );
    }
})
