const actions = {
  ADD_REQUISITION_SUPPLIES: "ADD_REQUISITION_SUPPLIES",
  ADD_REQUISITION_DATA: "ADD_REQUISITION_DATA",
  REQUISITIONS_LIST: "REQUISITIONS_LIST",
  ADD_VENDOR_DATA: "ADD_VENDOR_DATA",
  ADD_VENDOR_CONTACT: "ADD_VENDOR_CONTACT",
  CONTACTS_LIST: "CONTACTS_LIST",
  NAVIGATE_TO_CREATE_VENDOR: "NAVIGATE_TO_CREATE_VENDOR",
  NAVIGATE_TO_CREATE_SUPPLY: "NAVIGATE_TO_CREATE_SUPPLY",
  GET_ROUTE: "GET_ROUTE"
};

export default actions;
