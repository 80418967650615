import gql from "graphql-tag";

const GET_COMPLETE_DOCUMENT_CONTRACTOR = gql`
  query GetCompletedocumentContractor($id: ID!) {
    contract(where: {id: $id}){
      documentsContractor(where: { type: BAJA_DEL_IMSS }) {
        id
      }
    }
  }
`

export default GET_COMPLETE_DOCUMENT_CONTRACTOR
