import React from "react";
import {
  Grid,
  Button,
  Table,
  Dimmer,
  Loader,
  Container,
  Modal,
  ButtonGroup,
  Confirm,
  Header
} from "semantic-ui-react";
import {
  IoMdCreate,
  IoIosTrash,
  IoMdEye
} from "react-icons/io";
import _ from "lodash";
import {
  NumericBox,
  ModalHeader,
  ContractDocumentForm,
} from "../../Molecules";
import { validator } from "../../../helpers";
import parseDate from "../../../helpers/parse-date";
import ContractDocumentContext from "./contractDocumentContext";
import { Query } from "react-apollo";
import {
  GET_ORIGINAL_CONTRACT,
  QUERY_CONTRACTS,
  GET_CONTRACT_BY_CONTRACTOR,
  AUTHORIZER_USER
} from "../../../graphql/queries/contractors";
import {
  CREATE_ORIGINAL_CONTRACT,
  DELETE_ORIGINAL_CONTRACT,
  DELETE_DOCUMENT_CONTRACT
} from "../../../graphql/mutations/contractors";
import { bugsnagClient } from "../../../bugsnag";
import { format, helper, listMonthlyDocumentsContractor, monthDocumentsContractor } from "../../../helpers";
import { client } from "../../../graphql/apollo-config";
import { Can } from "../../hoc/permissions";

const refethQueries = {
  QUERY_CONTRACTS,
  GET_ORIGINAL_CONTRACT
}

class OriginalContractList extends React.Component {
  state = {
    user: JSON.parse(window.localStorage.getItem("session")) ? JSON.parse(window.localStorage.getItem("session")).user : "",
    isLoading: false,
    readOnly: false,
    readOnlyAuthorized: true,
    showFileModal: false,
    modalTitle: "",
    showFiles: "false",
    column: null,
    direction: "descending",
    summarySumEstimationsPayments: 0,
    summarySumDeductives: 0,
    cancelbutton: true,
    currentItem: {
      id: "",
      idContract: "",
      dateElaborated: new Date(),
      status: "BY_AUTHORIZING",
      concept: "",
      elaborated: JSON.parse(window.localStorage.getItem("session")).user.name,
      amount: 0,
      iva: 0,
      amountIva: 0,
      total: 0,
      amountDeductives: 0,
      type: "",
      currency: "",
      authorizer: "",
      totalContrated: 0,
      totalInvoiced: 0,
      totalPaided: 0,
      refUpload: []
    },
    errors: {
      dateElaborated: false,
      status: false,
      concept: false,
      elaborated: false,
      amount: false,
      iva: false,
      amountIva: false,
      total: false,
    },
    documentStatus: [{
      key: "1",
      text: "Por autorizar",
      value: "BY_AUTHORIZING"
    }],
    statusOptions: [{
      key: "1",
      text: "Por autorizar",
      value: "BY_AUTHORIZING"
    }, {
      key: "2",
      text: "Por facturar",
      value: "BY_BILL"
    },
      {
        key: "3",
        text: "Facturado",
        value: "INVOICED"
      },
      {
        key: "4",
        text: "Pagado",
        value: "PAID_OUT"
      }, {
        key: "5",
        text: "Cancelado",
        value: "CANCELLED"
      },{
        key: "6",
        text: "NA",
        value: "NA"
      },{
        key: "7",
        text: "Autorizado",
        value: "AUTHORIZED"
      },{
        key: "8",
        text: "Pagado parcialmente",
        value: "PAID_PARTIALLY"
      }],
    typeDocument: {
      ESTIMATION: "estimación",
      DEDUCTIVE: "deductiva",
      ADVANCED_PAYMENT: "anticipo",
    },
    openConfirmDelete: false,
    item2Delete: null,
    contractOriginal: null
  }

  async componentDidMount () {
    const { id } = this.props;
    const { user: { id: idUser, userRol } } = this.state;
    const QUERY = userRol === "CONTRACTOR" ? GET_CONTRACT_BY_CONTRACTOR : QUERY_CONTRACTS;
    const VARIABLES = userRol === "CONTRACTOR" ? idUser : null;
    const { data: { contracts } } = await client.query({ query: QUERY, variables: { id: VARIABLES} })
    const contractOriginal = contracts.find(contract => contract.id === id);
    this.setState(prevState => ({
      ...prevState,
      isLoading: true,
      contractOriginal
    }))
  }

  handleSort = (updateQuery, clickedColumn) => {
    const { direction } = this.state;

    this.setState({
      column: clickedColumn,
      direction: direction === "ascending" ? "descending": "ascending"
    })

    updateQuery((previousResult) => ({
      ...previousResult,
      contract: {
        currency: previousResult.contract.currency,
        total: previousResult.contract.total,
        invoiced: previousResult.contract.invoiced,
        paided: previousResult.contract.paided,
        project: previousResult.contract.project,
        documentsContract: _.orderBy(previousResult.contract.documentsContract, [clickedColumn], [direction.replace("ending", "")])
      }
    }));
  }

  checkDocumentsContractor = (contractOriginal) => {
    const { documentsContractor, startDate, realFinishDate } = contractOriginal
    const documentsMonthly = documentsContractor.filter(item => monthDocumentsContractor.indexOf(item.type) !== -1);

    // Null o Vacio
    if (documentsMonthly.length === 0) return false;

    let months = 0;

    // Docuentos total a cumplir
    const monthsRealDocs = format.howDocumentMonthly(startDate, realFinishDate);

    // Total de documentos al Mes Actual
    const nowMonth = format.howDocumentMonthly(startDate, new Date());

    if (nowMonth < monthsRealDocs) {
      months = nowMonth;
    } else {
      months = monthsRealDocs
    }

    const filterDocumentMonthly = documentsMonthly.filter(document => document.status === "APPROVED");
    return filterDocumentMonthly.length === (months * listMonthlyDocumentsContractor.length)
  }

  openModal = (dataModal) => {
    const { modaltitle, showfiles, documenttype } = dataModal.componentProps;
    const { currentItem: stateCurrentItem } = this.state;
    const { data, documentsContract, readOnly, contractOriginal } = dataModal;

    /* Si el modal no es EDIT o DETAIL de los documentos */
    if (!!contractOriginal) {
      /* Revisamos si se han cargado los documentos mensuales para poder subir documentacion al contrato */
      const checkDocuments = this.checkDocumentsContractor(contractOriginal);

      if(!checkDocuments) {
        return alert(`No puedes subir documentos al contrato, hasta que subas la documentación mensual completa y sea aprobada`);
      }
    }

    const { id, invoiced, paided, currency, total, amountDeductives } = this.state.contractOriginal;
    const ssd = this.summarySumDocuments(documentsContract, data.id || null);

    const currentItem = Object.assign(stateCurrentItem,
      { ...data,
        idContract: id,
        currency: currency,
        totalInvoiced: invoiced,
        totalPaided: paided,
        totalContrated: total,
        amountDeductives,
        type: documenttype
      });

    if (data) this.addStatus([currentItem.status]);

    this.setState({
      showFileModal: true,
      modalTitle : modaltitle,
      showFiles: showfiles,
      currentItem,
      readOnly,
      cancelbutton: true,
      ...ssd
    });
  }

  summarySumDocuments = (documentsContract, excludeId) => {
    const estimationsPayments = documentsContract.filter(doc => {
      return (doc.type === "ESTIMATION" || doc.type === "ADVANCED_PAYMENT") &&
        doc.status !== "CANCELLED" &&
        doc.id !== excludeId;
    });
    const deductives = documentsContract.filter(doc => {
      return doc.type === "DEDUCTIVE" &&
        doc.status !== "CANCELLED" &&
        doc.id !== excludeId;
    });

    return {
      summarySumEstimationsPayments:  _.sumBy(estimationsPayments, "amount"),
      summarySumDeductives: _.sumBy(deductives, "amount"),
    }
  }

  // reset state and close view
  closeModal = () => {
    const { currentItem } = this.state;

    const currentItemClean = {
      id: "",
      status: "BY_AUTHORIZING",
      concept: "",
      amount: 0,
      iva: 0,
      amountIva: 0,
      total: 0,
      currency: "",
      authorizer: "",
      type: "",
      amountDeductives: 0,
      totalContrated: 0,
      totalInvoiced: 0,
      totalPaided: 0,
      refUpload: []
    };

    Object.assign(currentItem, {...currentItemClean})

    const errors =  {
      dateElaborated: false,
      status: false,
      concept: false,
      elaborated: false,
      amount: false,
      iva: false,
      amountIva: false,
      total: false,
    };

    this.setState({
      showFileModal: false,
      modalTitle: "",
      showFiles: "false",
      isLoading: true,
      documentStatus: [this.state.statusOptions[0]],
      currentItem,
      errors,
      summarySumEstimationsPayments: 0,
      summarySumDeductives: 0,
      cancelbutton: true,
      readOnlyAuthorized: true
    });
  }

  addStatus = (status, cancelbutton = true, type = null) => {
    let { statusOptions, currentItem, readOnlyAuthorized } = this.state;
    const { bill, payment } = this.getTotalType(currentItem);
    const documentStatus = [];
    readOnlyAuthorized = true;

    if (status[0] === "BY_AUTHORIZING")
      readOnlyAuthorized = false

    if (status[0] === "CANCELLED")
      status = ["CANCELLED"];

    if (status[0] === "AUTHORIZED" && type === "DEDUCTIVE")
      status = ["CANCELLED", "AUTHORIZED"];

    if (status[0] === "PAID_OUT")
      status = ["CANCELLED", "PAID_OUT"];

    if (status[0] === "INVOICED" && payment)
      status = ["CANCELLED", "PAID_PARTIALLY"];
    else if (status[0] === "INVOICED" && !bill && !cancelbutton)
      status = ["CANCELLED", "BY_BILL"];

    if (status[0] === "INVOICED")
      status = ["CANCELLED", "INVOICED"];

    if (status[0] === "AUTHORIZED") {
      status = ["CANCELLED", "BY_BILL", "AUTHORIZED"];
    }

    if (status[0] === "PAID_PARTIALLY")
      status = ["CANCELLED", "PAID_PARTIALLY"];

    if (status[0] === "PAID_OUT")
      status = ["CANCELLED", "PAID_OUT"];

    if (status[0] === "BY_BILL" && payment)
      status = ["CANCELLED", "PAID_PARTIALLY"];
    else if (status[0] === "BY_BILL")
      status = ["CANCELLED", "BY_BILL"];

    status.forEach(status => {
      statusOptions.forEach(option => {
        if (status === option.value) {
          if (!documentStatus.find(doc => doc.value === status)) {
            documentStatus.push(option)
          }
        }
      })
    });

    this.setState(data => ({ ...data,
      documentStatus,
      readOnlyAuthorized,
      cancelbutton,
      currentItem: { ...data.currentItem,
        status: _.last(documentStatus).value
      }
    }));
  }

  authSuccess = async (pass) => {
    const { currentItem: { type }, user } = this.state;
    const authorizer = await AUTHORIZER_USER(user.id, pass);

    if (authorizer) {
      if (type === "DEDUCTIVE")
        this.addStatus(["AUTHORIZED"], true, type);
      else
        this.addStatus(["AUTHORIZED"], true, type);

      return this.setState(data => ({
        ...data,
        currentItem: { ...data.currentItem,
          authorizer
        }
      }));
    }
    alert("Contraseña incorrecta");
  }

  // change state value
  change = (event, data) => {
    let { name, value } = data;
    let currentItem = Object.assign({}, this.state.currentItem);

    /* Escape textarea input clean */
    if (name === "concept") {
      value = helper.cleaningData(value);
    }

    // update amountIva, total
    if (name === "amount") {
      if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value)) {
        currentItem['amount'] = '';
      }

      const amountIva = (+currentItem.iva / 100) * +value;
      const total = +value + amountIva;

      currentItem["amountIva"] = parseFloat(amountIva).toFixed(2);
      currentItem["total"] = parseFloat(total).toFixed(2);

    } else if (name === "iva") {
      if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value)) {
        currentItem['iva'] = '';
      }

      const amountIva = (+value / 100) * +currentItem.amount;
      const total = +currentItem.amount + amountIva;

      currentItem["amountIva"] = parseFloat(amountIva).toFixed(2);
      currentItem["total"] = parseFloat(total).toFixed(2);
    }

    currentItem[name] = value;
    this.setState({ currentItem });
  }

  // return object with values in the state to validate
  contractorValidateFields = () => {
    const { errors, currentItem } = this.state;
    let fieldsValue = {};

    for (const field in errors) fieldsValue[field] = currentItem[field];

    return fieldsValue;
  }

  validateFields = validationObject => {
    let errors = [];
    const validation = validator(validationObject);

    validation.forEach(field => {
      const key = field.key;
      if (!field.isValid) {
        errors.push(key);
      }
    });

    return errors;
  };

  // set errors values in state to false
  cleanErrors = () => {
    const errors = Object.assign({}, this.state.errors);

    for(const prop in errors) errors[prop] = false;

    return errors;
  }

  // save value of currentItem
  save = async () => {
    this.setState({ isLoading: true });
    const { errors, currentItem } = this.state;

    // validate inputs
    // get contractor errors state object
    const validationErrors = Object.assign({}, errors);
    // get inputs to validate
    const contractValidateObj = this.contractorValidateFields();
    const contractErrors = this.validateFields(contractValidateObj);

    if (contractErrors.length > 0) {
      Object.keys(validationErrors).forEach((key, index) => {
        validationErrors[key] = contractErrors.indexOf(key) !== -1;
      });

      this.setState(data => ({
        errors: validationErrors,
        isLoading: true,
      }));

      return false;
    }

    try {
      if (currentItem.type === "DEDUCTIVE") {
        if (!this.operationsResolveDeductive(currentItem)) {
          this.setState({ isLoading: false });
          return alert("El importe de la deductiva excede el total contratado");
        }
      } else {
        if (!this.operationsResolveEstimation(currentItem)) {
          this.setState({ isLoading: false });
          return alert("El importe del anticipo/estimación excede el total contratado");
        }
      }
      await CREATE_ORIGINAL_CONTRACT(currentItem, refethQueries);
      this.closeModal();
    } catch (error) {
      bugsnagClient.notify(error.message)
    }
  }

  operationsResolveDeductive = (currentItem) => {
    const { amount, status, totalContrated } = currentItem;
    const { summarySumDeductives } = this.state;

    if ((summarySumDeductives + +amount) > totalContrated)
      return false;

    if (status === "AUTHORIZED") {
      currentItem.amountDeductives += +amount;
      currentItem.contractAmountDeductives += +amount;
      currentItem.status = "PAID_OUT";
    }

    if (status === "CANCELLED") {
      currentItem.amountDeductives -= +amount;
      currentItem.contractAmountDeductives -= +amount;
    }

    return true
  }

  operationsResolveEstimation = (currentItem) => {
    const { totalContrated, amount, status, totalInvoiced, totalPaided } = currentItem;
    const { summarySumEstimationsPayments, summarySumDeductives } = this.state;
    const { bill, payment } = this.getTotalType(currentItem);

    if ((summarySumEstimationsPayments + +amount) > (totalContrated - summarySumDeductives))
      return false;

    if (status === "CANCELLED") {
      currentItem.totalInvoiced = bill ? (totalInvoiced - bill.total) : totalInvoiced;
      currentItem.totalPaided = payment ? (totalPaided - payment.total) : totalPaided;
    }

    return true;
  }

  addPayment = (amountType, value) => {
    const { currentItem } = this.state;
    const { bill, payment } = this.getTotalType(currentItem);

    const total = {
      totalInvoiced: value + (bill ? bill.total : 0),
      totalPaided: value + (payment ? payment.total : 0)
    }

    if (total[amountType] <= currentItem.amount) {
      const data = {
        name : amountType,
        value: value + currentItem[amountType]
      }
      this.change(null, data);

      if (total[amountType] === currentItem.amount)
        return "paidout";
      return "next";
    } else {
      return "exceeded";
    }
  }

  subtractPayment = async (data) => {
    const { currentItem, idRef, reference, totalReference } = data;
    const subtract = currentItem.refUpload.find(item => item.reference === reference);
    const totalSubtracted = currentItem[totalReference] - parseFloat(subtract.wildcard);
    let props = {
      idContract: currentItem.idContract,
      idDocumentContract: currentItem.id,
      idRef,
      invoiced: currentItem.totalInvoiced,
      paided: currentItem.totalPaided
    }

    if (idRef) {
      try {
        if (subtract.extension === "xml") {
          if (totalReference === "totalInvoiced")
            props = {...props, invoiced: totalSubtracted};

          if (totalReference === "totalPaided")
            props = {...props, paided: totalSubtracted};
        }
        await DELETE_DOCUMENT_CONTRACT(props, refethQueries);
      } catch (error) {
        bugsnagClient.notify(error.message);
      }
    }

    const items = currentItem.refUpload.filter(item => item.reference !== reference);

    await this.setState(data => ({
      currentItem: { ...data.currentItem,
        [totalReference] : totalSubtracted,
        refUpload: items
      }
    }));

    const { bill, payment } = this.getTotalType(currentItem);

    if(totalReference === "totalInvoiced") {
      const totalDocumentsUploads = bill.total - parseFloat(subtract.wildcard);

      if (totalDocumentsUploads === 0)
        this.addStatus(["BY_BILL"], false);
    }

    if(totalReference === "totalPaided") {
      const totalDocumentsUploads = payment.total - parseFloat(subtract.wildcard);

      if (totalDocumentsUploads === 0)
        this.addStatus(["INVOICED"], false);
    }
  }

  showConfirmDelete = (item2Delete = null) => {
    this.setState({
      openConfirmDelete: !this.state.openConfirmDelete,
      item2Delete
    });
  }

  getTotalType = (item2Delete) => {
    const result = _(item2Delete.refUpload).filter(obj => obj.extension === "xml").groupBy("type")
      .map((obj, key) => ({
        "type": key,
        "total": _.sumBy(obj, o => parseFloat(o.wildcard))
      })).value();

    return {
      bill: result.find(total => total.type === "BILL" ? total : null),
      payment: result.find(total => total.type === "PAYMENT_COMPLEMENT" ? total : null)
    }
  }

  deleteOriginalContract = async () => {
    const { item2Delete, item2Delete: { type, amount } } = this.state;
    const { id, invoiced, paided, amountDeductives } = this.state.contractOriginal;
    let props = {
      idContract: id,
      idOriginalContract: item2Delete.id,
      invoiced: invoiced,
      paided: paided,
      amountDeductives: amountDeductives
    }

    if (item2Delete.status !== "CANCELLED") {
      const { bill, payment } = this.getTotalType(item2Delete);

      props = { ...props,
        invoiced: bill ? (invoiced - bill.total) : invoiced,
        paided: payment ? (paided - payment.total) : paided,
        amountDeductives: type === "DEDUCTIVE" ? (amountDeductives - amount) : amountDeductives
      }
    }

    try {
      await DELETE_ORIGINAL_CONTRACT(props, refethQueries);
      this.showConfirmDelete();
    } catch (error) {
      bugsnagClient.notify(error.message)
    }
  }

  render() {
    const { id } = this.props;
    const { column, direction, isLoading, contractOriginal } = this.state;
    if (!isLoading) {
      return (
        <Container fluid className="Wrapper__Body-Loader">
          <Dimmer active inverted >
            <Loader inverted content='Cargando...' />
          </Dimmer>
        </Container>
      )
    }

    const { total, invoiced, paided, currency } = contractOriginal;

    return (
      <Query query={GET_ORIGINAL_CONTRACT} variables={{ id }}>
        {({ loading, error, data, updateQuery }) => {
          if (loading) {
            return (
              <Container fluid className="Wrapper__Body-Loader">
                <Dimmer active inverted >
                  <Loader inverted content='Cargando...' />
                </Dimmer>
              </Container>
            );
          }

          if (data === undefined) {
            return (
              <Container fluid textAlign="center">
                "No se encontraron resultados para la búsqueda "
              </Container>
            );
          }

          if (error) {
            return (
              <Container fluid className="Wrapper__Body-Loader">
                <Dimmer active inverted>
                  <Loader inverted content='Error' />
                </Dimmer>
              </Container>
            )
          }

          const { project: { cli }, documentsContract} = data.contract

          const items = documentsContract.map((item) => {
            const modalTitle = `Datos de la ${this.state.typeDocument[item.type]}`;
            const showFiles = item.type === "ESTIMATION" || item.type === "ADVANCED_PAYMENT" ? "true" : "false";

            return (
              <Table.Row key={item.id} textAlign="center">
                <Table.Cell>{format.typeDocumentsContract(item.type)}</Table.Cell>
                <Table.Cell>{cli}</Table.Cell>
                <Table.Cell>{item.concept}</Table.Cell>
                <Table.Cell>{currency}</Table.Cell>
                <Table.Cell>{parseDate(item.dateElaborated)}</Table.Cell>
                <Table.Cell>{format.currency(item.amount, currency)}</Table.Cell>
                <Table.Cell>{format.currency(item.total, currency)}</Table.Cell>
                <Table.Cell>{item.elaborated}</Table.Cell>
                <Table.Cell>{item.authorizer}</Table.Cell>
                <Table.Cell>{format.statusDocumentContract(item.status)}</Table.Cell>
                <Table.Cell textAlign="right">
                  <ButtonGroup basic>
                    {!this.props.readonly &&
                    <React.Fragment>
                      <Can do="edit" on="OriginalContract">
                        <Button
                          documenttype={item.type}
                          modaltitle={modalTitle}
                          showfiles={showFiles}
                          onClick={(event, componentProps) => {
                            this.openModal({
                              componentProps,
                              data: item,
                              readOnly: null,
                              documentsContract
                            });
                          }}
                          icon
                        >
                          <IoMdCreate />
                        </Button>
                      </Can>
                      <Can do="delete" on="OriginalContract">
                        <Button
                          icon
                          onClick={() => this.showConfirmDelete(item)}
                        >
                          <IoIosTrash />
                        </Button>
                      </Can>
                    </React.Fragment>
                    }
                    <Can do="detail" on="OriginalContract">
                      <Button
                        documenttype={item.type}
                        modaltitle={modalTitle}
                        showfiles={showFiles}
                        onClick={(event, componentProps) => {
                          this.openModal({
                            componentProps,
                            data: item,
                            readOnly: true,
                            documentsContract
                          });
                        }}
                        icon
                      >
                        <IoMdEye />
                      </Button>
                    </Can>
                  </ButtonGroup>
                </Table.Cell>
              </Table.Row>
            );
          });

          const contextValues = {
            currentItem: this.state.currentItem,
            addStatus: this.addStatus,
            documentStatus: this.state.documentStatus,
            errors: this.state.errors,
            change: this.change,
            authSuccess: this.authSuccess,
            addPayment: this.addPayment,
            subtractPayment: this.subtractPayment
          }

          const contractDocumentForm = <ContractDocumentContext.Provider value={contextValues}>
            <ContractDocumentForm
              showFiles={this.state.showFiles}
              readOnly={this.state.readOnly}
              readOnlyAuthorized={this.state.readOnlyAuthorized}
            />
          </ContractDocumentContext.Provider>;

          return (
            <React.Fragment>
              <Grid.Row className="Modal__SubTabNav-Row-Align">
                <Grid.Column width="8">
                  {/* Numeric Box */}
                  <NumericBox
                    totalContracted={format.currency(total, currency)}
                    totalCharged={format.currency(invoiced, currency)}
                    totalPaid={format.currency(paided, currency)}
                  />
                </Grid.Column>
              </Grid.Row>

              {/* list */}
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3" dividing className="Modal__Form-Header">
                    Documentos
                  </Header>
                  <Table basic="very" compact="very" textAlign="center" className="Modal__Form-Table" sortable>
                    <Table.Header className="Modal__Form-Table-Header Small">
                      <Table.Row>
                        <Table.HeaderCell
                          sorted={column === "type" ? direction : "descending"}
                          onClick={() => this.handleSort(updateQuery, "type")}>
                          Tipo
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Presupuesto Cli
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "concept" ? direction : "descending"}
                          onClick={() => this.handleSort(updateQuery, "concept")}>
                          Concepto
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Moneda
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "dateElaborated" ? direction : "descending"}
                          onClick={() => this.handleSort(updateQuery, "dateElaborated")}>
                          Fecha
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "amount" ? direction : "descending"}
                          onClick={() => this.handleSort(updateQuery, "amount")}>
                          Importe
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "amountIva" ? direction : "descending"}
                          onClick={() => this.handleSort(updateQuery, "amountIva")}>
                          Total
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "elaborated" ? direction : "descending"}
                          onClick={() => this.handleSort(updateQuery, "elaborated")}>
                          Elaboró
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "authorizer" ? direction : "descending"}
                          onClick={() => this.handleSort(updateQuery, "authorizer")}>
                          Autorizó
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "status" ? direction : "descending"}
                          onClick={() => this.handleSort(updateQuery, "status")}>
                          Estatus
                        </Table.HeaderCell>
                        <Table.HeaderCell className="Modal__Form-Table-Header-NoHover"/>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body className="Modal__Form-Table-Body Small">
                      {items}
                    </Table.Body>
                  </Table>
                  <Confirm
                    open={this.state.openConfirmDelete}
                    cancelButton="Cancelar"
                    confirmButton="Aceptar"
                    onCancel={this.showConfirmDelete}
                    onConfirm={this.deleteOriginalContract}
                    content={`¿Desea eliminar el registro? Esta acción no es reversible`}
                  />
                </Grid.Column>
              </Grid.Row>

              <Can do="create" on="OriginalContract">
                {!this.props.readonly &&
                <Grid.Row textAlign="center" className="Modal__SubTabNav-Row-Align">
                  <Grid.Column width="3">
                    {/* estimation modal */}
                    <Button
                      documenttype="ESTIMATION"
                      modaltitle="Nueva estimación"
                      onClick={(event, componentProps) => {
                        this.openModal({
                          componentProps,
                          data: {},
                          readOnly: null,
                          documentsContract,
                          contractOriginal
                        });
                      }}
                      className="Modal__Form-ButtonAdd"
                      icon="plus"
                      content="Añadir estimación"
                      showfiles="true"/>
                  </Grid.Column>
                  <Grid.Column width="3">
                    {/* deductive modal */}
                    <Button
                      documenttype="DEDUCTIVE"
                      modaltitle="Nueva deductiva"
                      onClick={(event, componentProps) => {
                        this.openModal({
                          componentProps,
                          data: {},
                          readOnly: null,
                          documentsContract,
                          contractOriginal
                        })
                      }}
                      className="Modal__Form-ButtonAdd"
                      icon="plus"
                      content="Añadir deductiva"
                      showfiles="false"/>
                  </Grid.Column>
                  <Grid.Column width="3">
                    {/* advance modal */}
                    <Button
                      documenttype="ADVANCED_PAYMENT"
                      modaltitle="Nuevo anticipo"
                      onClick={(event, componentProps) => {
                        this.openModal({
                          componentProps,
                          data: {},
                          readOnly: null,
                          documentsContract,
                          contractOriginal
                        })
                      }}
                      className="Modal__Form-ButtonAdd"
                      icon="plus"
                      content="Añadir anticipo"
                      showfiles="true"/>
                  </Grid.Column>
                </Grid.Row>
                }
              </Can>
              <Modal
                className="Modal__Form-SubModal"
                centered={true}
                open={this.state.showFileModal}
                header={
                  <ModalHeader
                    component={false}
                    title={this.state.modalTitle}
                    save={this.save}
                    cancel={this.closeModal}
                    cancelbutton={this.state.cancelbutton}
                    disabled={!this.state.isLoading}
                    readOnly={this.state.readOnly}
                  />
                }
                content={contractDocumentForm}
                size="large"
                dimmer="blurring"
              />
            </React.Fragment>
          );
        }}
      </Query>
    )
  }
}

export default OriginalContractList;
