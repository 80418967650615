import { IoMdCreate, IoIosBook } from "react-icons/io";
import { RequisitionsList, PurchaseOrdersList } from "../../../Organisms";

export default [
  {
    icon: IoIosBook,
    item: RequisitionsList,
    routeName: "req",
    text: "Requisiciones"
  },
  {
    icon: IoMdCreate,
    item: PurchaseOrdersList,
    routeName: "pos",
    text: "Compras"
  }
];
