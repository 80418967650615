import React from "react";
import "./style.css";

export default class TableBody extends React.Component {
  render() {
    return (
      <tbody id={this.props.id} className="tbody">
        {this.props.children}
      </tbody>
    );
  }
}
