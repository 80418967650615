const theme = {
  colors: {
    red: "#b3363e",
    blue1: "#295896"
  }
};

export default {
  styles: {
    addForms: {
      standard: {
        margin: "10px auto",
        width: "95%"
      }
    },
    body_container: {
      background: "#fbfbfb"
    },
    buttons: {
      red: {
        background: theme.colors.red,
        color: "white",
        marginLeft: "10px",
        marginRight: "10px"
      },
      blue1: {
        background: theme.colors.blue1,
        color: "white",
        marginLeft: "10px",
        marginRight: "10px"
      }
    },
    container: {
      margin: "0 auto",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0"
    },
    container_row: {
      margin: "0 auto",
      paddingTop: "0",
      paddingBottom: "0"
    },
    container_top_bar: {
      height: "65px",
      background: "white",
      borderBottom: "1px solid #dddddd"
    },
    inputs: {
      short: {
        width: "60%"
      }
    },
    sidebar: {
      background: "white",
      borderRight: "1px solid #f2f2f2"
    },
    action_btn_outline: {
      margin: "10px",
      padding: "8px",
      width: "150px",
      background: "rgba(0, 0, 0, 0)",
      border: "1px solid #295896",
      color: "#295896",
      cursor: "pointer",
      borderRadius: "5px",
      fontSize: "10px",
      fontWeight: "bold"
    },
    clientLabel: {
      backgroundColor: "#ffffff",
      color: "#c0c0c0",
      borderRadius: "0px",
      marginLeft: "10px",
      size: "0.9em",
      float: "left",
      fontSize: "0.79em"
    },
    filterLabel: {
      backgroundColor: "#ffffff",
      color: "#c0c0c0",
      borderLeft: "1px solid #c0c0c0",
      borderRadius: "0px",
      marginLeft: "10px",
      fontSize: "0.79em",
      float: "left"
    },
    searchInput: {
      borderRadius: "20px",
      width: "200px",
      float: "left",
      height: "30px !important",
      maxHeight: "30px !important",
      lineHeight: "30px",
      fontSize: "0.79em"
    },
    filerByClient: {
      borderRadius: "20px !important",
      width: "100px",
      float: "left",
      fontSize: "0.79em"
    },
    filerByDate: {
      borderRadius: "20px",
      width: "180px",
      float: "left",
      fontSize: "0.79em"
    },
    filerByStatus: {
      borderRadius: "5px",
      width: "120px",
      float: "left",
      height: "30px !important",
      maxHeight: "30px !important",
      minHeight: "30px !important",
      fontSize: "0.79em"
    },
    filters: {
      width: "100%",
      float: "left",
      paddingTop: "22px"
    },
    filtersContainer: {
      width: "100%",
      borderBottom: "1px solid rgb(221,221,221)",
      marginLeft: "10px",
      marginRight: "10px"
    },
    personLabel: {
      backgroundColor: "#ffffff",
      color: "#c0c0c0",
      borderRadius: "0px",
      marginLeft: "10px",
      size: "0.9em",
      float: "left",
      fontSize: "0.79em"
    },
    searchContainer: {
      width: "100% !important",
      paddingBottom: "20px"
    },
    supplyLabel: {
      backgroundColor: "#ffffff",
      color: "#c0c0c0",
      borderRadius: "0px",
      marginLeft: "10px",
      size: "0.9em",
      float: "left",
      fontSize: "0.79em"
    },
    sideBarForms: {
      paddingLeft: "10px",
      borderLeft: "2px solid #f2f2f2"
    },
    entriesLabel:{
        backgroundColor: "#ffffff",
        lineHeight: "30px"
    },
    entriesBoldLabel:{
        backgroundColor: "#ffffff",
        fontWeiight:"700"

    },
    paginationLabel:{
        backgroundColor: "#ffffff",
        lineHeight: "20px"
    },

    pickerStyle: {    
        backgroundColor: "white !important", 
        borderCollapse: "separate",
        borderSpacing: "0",                
    }  

  }
};
