import { client } from "../../apollo-config";
const gql = require("graphql-tag");
const connectReqToUser = async (userID, id) => {
  const mutation = await client.mutate({
    mutation: gql`
        mutation {
            updateUser(
                where:{
                    id:"${userID}"
                }
                data:{
                    purchaseOrders:{
                        connect:{
                            id:"${id}"
                        }
                    }
                }
            ){
                id
            }
        }
    `,
  });
  return mutation;
};
export default connectReqToUser;
