import React from "react";
import { Segment } from "semantic-ui-react";
import { FormHeader } from "../../Molecules";
import { ContractFormAccountBalance } from "../../Organisms";
import { CREATE_CONTRACT_AB, CREATE_ADITIVE_AB } from "../../../graphql/mutations/accountBalance";
import { GET_CONTRACT_AB } from "../../../graphql/queries/accountBalance";
import { bugsnagClient } from "../../../bugsnag";
import { helper } from "../../../helpers";
import moment from "moment";
import { alertProjectInactive } from "../../../helpers/alert-project-inactive";

class NewContractAccountBalance extends React.Component {
  state = {
    title: this.props.match.params.id ? "Nueva aditiva" : "Nuevo contrato",
    contract: {
      id: "",
      IDFolio: "",
      client: {
        id: ""
      },
      project: {
        id: "",
        cli: "",
        key: ""
      },
      currency: "",
      amountContract: "",
      percentAmortization: "",
      percentGuaranteeFund: "",
      startDate: "",
      finishDate: "",
      status: "",
      description: ""
    },
    errors : {},
    isAditive: this.props.match.params.id,
    submitting: false
  };

  componentDidMount() {
    this.getContract();
  }

  getContract = async () => {
    if (this.state.isAditive) {
      const { contractAccountBalance } = await GET_CONTRACT_AB(this.props.match.params.id);
      this.setState(data => ({
        contract: {
          ...data.contract,
          ...contractAccountBalance
        }
      }))
    }
  }

  onCancel = () => {
    this.props.history.push({
      pathname: '/estado-de-cuenta',
      state: { myRoute: "contract" }
    })
  }

  handleOnChange = (e, data) => {
    let { name, value, options } = data;
    let isProjectInactive = false

    if (name === "project") {
      value = options.find(option => option.id === value);
      const { status, text } = value
      isProjectInactive = alertProjectInactive({ status, module: 'contract', projectName: text });
    }

    if (name === "amountContract") {
      if (value === 0) value = ''
    }

    if (isProjectInactive) {
      this.setState(data => ({contract: {...data.contract, project: { cli: '' } }}));
      return
    }

    this.setState(data => ({contract: {...data.contract, [name]: value }}));
  }

  onSave = async () => {
    const { contract, contract: { startDate, finishDate, realFinishDate}, isAditive } = this.state;

    if (this.handleValidator())
      return false;

    this.setState({ submitting: true });

    try {
      if (!isAditive) {
        await CREATE_CONTRACT_AB(contract);
      } else {
        const newRealFinishDate = moment(realFinishDate, "YYYY-MM-DD");
        const newStartDate =  moment(startDate, "YYYY-MM-DD");
        const newFinishDate = moment(finishDate, "YYYY-MM-DD");

        if (newStartDate.diff(newRealFinishDate) <= 0 || newFinishDate.diff(newRealFinishDate) <= 0) {
          this.setState({ submitting: false });
          return alert("No puedes crear la aditiva, la fecha debe ser mayor a la termino del contrato");
        } else {
          await CREATE_ADITIVE_AB(contract);
        }
      }
      this.props.history.push("/estado-de-cuenta");
    } catch (error) {
      bugsnagClient.notify(error);
    }
  }

  handleValidator = () => {
    const { contract, contract: { client, project }, isAditive } = this.state;

    /* Si es contrato original no valido el elemento ID */
    if(!isAditive) delete contract.id;

    const { errors, validator } = helper.validator({...contract, client: client.id, project: project.id });
    this.setState({ errors });
    return validator;
  }

  render() {
    const { contract, errors, isAditive, submitting } = this.state;
    return (
      <Segment raised className="Modal Wrapper__Modal">
        <FormHeader text={this.state.title} onSave={this.onSave} onCancel={this.onCancel} submitting={submitting}/>
        <ContractFormAccountBalance
          data={contract}
          handleOnChange={this.handleOnChange}
          errors={errors}
          isAditive={isAditive}
        />
      </Segment>
    )
  }
}

export default NewContractAccountBalance;
