import React, { Component } from "react";
import "./style.css";

export default class Title extends Component {
  render() {
    return (
      <p id={this.props.id} className={this.props.className}>
        {this.props.title}
      </p>
    );
  }
}
