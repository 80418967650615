import React from "react";
import { Form, Input, TextArea } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import moment from "moment";

export default class ShipmentForm extends React.Component {
  render() {
    return (
      <Form className="Modal__Form">
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            label="NÚMERO DE GUÍA"
            name="trackingNumber"
            value={this.props.fields.trackingNumber}
            onChange={this.props.onChange}
          />
          <Form.Field
            className='Util-DatePicker'
            control={SemanticDatepicker}
            type="basic"
            format="DD/MM/YYYY"
            label="FECHA DE ENVIO ESPERADA"
            name="arrivalDate"
            placeholder={(!this.props.fields.arrivalDate || this.props.fields.arrivalDate === null) ? "dd/mm/aaaa" : moment(this.props.fields.arrivalDate.split("T")[0]).format("DD/MM/YYYY")}
            onDateChange={
              (date) => this.props.onChange({target: { name: 'arrivalDate', value: (date !== null ? moment(date).format('YYYY-MM-DD') : "")}})
            }
            readOnly={true}
          />
          <Form.Field
            className='Util-DatePicker'
            control={SemanticDatepicker}
            type="basic"
            format="DD/MM/YYYY"
            label="FECHA DE ENTREGA ESPERADA"
            name="shipmentDate"
            placeholder={(!this.props.fields.shipmentDate || this.props.fields.shipmentDate === null) ? "dd/mm/aaaa" : moment(this.props.fields.shipmentDate).format("DD/MM/YYYY")}
            onDateChange={
              (date) => this.props.onChange({target: { name: 'shipmentDate', value: (date !== null ? moment(date).format('YYYY-MM-DD') : "")}})
            }
            readOnly={true}
          />
          <Form.Field
            control={Input}
            label="EMPRESA DE PAQUETERÍA"
            name="shippingCompany"
            value={this.props.fields.shippingCompany}
            onChange={this.props.onChange}
          />
        </Form.Group>
        <Form.Field
          control={TextArea}
          label="NOTAS"
          rows="5"
          name="shippingNotes"
          value={this.props.fields.shippingNotes}
          onChange={this.props.onChange}
        />
      </Form>
    );
  }
}
