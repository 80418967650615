import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import getClientsSelect from "../../../graphql/queries/clients/getClientsSelect"

const SelectClient = props => {
  const {
    label,
    name,
    error,
    value,
    onChange,
    disabled = false
  } = props

  return (
    <Query query={getClientsSelect} fetchPolicy={"network-only"}>
      {({ loading, error, data }) => {
        if (loading) {
          return <p testid="loading-wrapper">loading...</p>;
        }
        if (error) {
          return <p testid="error-wrapper">error</p>;
        }

        const { clients } = data

        return (
          <Dropdown
            fluid
            selection
            options={clients}
            label={label}
            name={name}
            error={error}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        );
      }}
    </Query>
  )
}

export default SelectClient;
