import React from "react"
import { Message, Icon } from "semantic-ui-react";

const MessageNotifyDocuments = (props) => {
  return (
    <Message icon>
      <Icon name='circle notched' loading />
      <Message.Content>
        <Message.Header>{props.title}</Message.Header>
        {props.subtitle}
      </Message.Content>
    </Message>
  )
}

export default MessageNotifyDocuments;
