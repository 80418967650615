import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import {
  Purchases,
  Catalogs,
  AccountBalance,
  Contractors,
  Login,
  Clients,
  Inventories,
  ProjectProgress,
  ProjectProgressDetail,
  ProjectProgressGallery,
  Error404,
  Users,
  CreatePassword,
  PurchaseOrderPdf
} from "./components/Pages";
import { client } from "./graphql/apollo-config";
import { ErrorBoundary } from "./bugsnag";
import PrivateRoute from "./components/hoc/privateRoutes";
import FullStory, { FullStoryAPI } from "react-fullstory";

export default class App extends React.Component {
  state = {
    user: JSON.parse(window.localStorage.getItem("session"))
      ? JSON.parse(window.localStorage.getItem("session")).user
      : "",
  };

  componentDidMount() {
    const { user } = this.state;
    if (user) {
      FullStoryAPI("identify", user.id, {
        displayName: user.name,
        email: user.email,
        role: user.userRol
      });
    }
  }

  render() {
    const {
      user: { userRol, emailConfirmed },
    } = this.state;

    return (
      <ErrorBoundary>
        <FullStory org={process.env.REACT_APP_FS_ORG_ID} />
        <ApolloProvider client={client}>
          <BrowserRouter>
            <div id="app">
              <Switch>
                <Redirect exact from='/' to='/auth/login' />
                {userRol === "CONTRACTOR" ? (
                  <React.Fragment>
                    <PrivateRoute
                      exact
                      path="/contratistas"
                      component={Contractors}
                      rol={userRol}
                      confirmed={emailConfirmed}
                    />
                    <Route exact path="/auth/login" component={Login} />
                    <Route exact path="/404" component={Error404} />
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                     <PrivateRoute
                        path="/catalogos"
                        component={Catalogs}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        exact
                        path="/compras"
                        component={Purchases}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        path="/estado-de-cuenta"
                        component={AccountBalance}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        exact
                        path="/contratistas"
                        component={Contractors}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        exact
                        path="/clientes"
                        component={Clients}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        path="/inventarios"
                        component={Inventories}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        path="/avance-de-proyecto"
                        component={ProjectProgress}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        path="/avance/:id"
                        component={ProjectProgressDetail}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        path="/galeria/:id"
                        component={ProjectProgressGallery}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <PrivateRoute
                        path="/usuarios"
                        component={Users}
                        rol={userRol}
                        confirmed={emailConfirmed}
                      />
                      <Route
                        exact
                        path="/crear-password/:tmp"
                        component={CreatePassword}
                      />
                      <Route
                        exact
                        path="/pdf/:id"
                        component={PurchaseOrderPdf}
                      />
                      <Route exact path="/auth/login" component={Login} />
                      <Route component={Error404} />
                    </React.Fragment>
                  )}
              </Switch>
            </div>
          </BrowserRouter>
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}
