import React from "react";
import ReceptionsListContainer from "./container";
import { Switch, Route } from "react-router-dom";
import EditReception from "../../Molecules/EditReception";
import { Segment } from "semantic-ui-react";

export default class ReceptionsList extends React.Component {
  render() {
    return (
      <Segment>
        <Switch>
          <Route path="/inventarios/edit/:id" component={EditReception} />
          <Route path="/inventarios" component={ReceptionsListContainer} />
        </Switch>
      </Segment>
    );
  }
}
