import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async (status, id, isReq) => {
  const mutation = await client.mutate({
    mutation: gql`
     mutation {
        updatePurchaseOrder(
          data:{
            data:{
              update:{
                status:${status}
              }
            }
          },
          where:{
            id:"${id}"
          }
        ){
          id
        }
      }
    `
  });
  return mutation;
};
