import React from "react";
import { Query } from "react-apollo";
import { Button, Container, Dropdown, Form, Header, Input, Modal, Segment, Table, TextArea, Select, Icon } from "semantic-ui-react";
import CurrencyInput from "react-currency-input";
import { IoMdCreate, IoIosTrash } from "react-icons/io";
import { client, QUERIES } from "../../../graphql/apollo-config";
import { updateRequisitionSupply, updatePOToProject, updateReqNewSupply } from "../../../graphql/mutations/purchases";
import { CREATE_SUPPLY } from "../../../graphql/mutations/supplies";
import getSupplies from "../../../graphql/queries/purchases/getSupplies";
import updatePurchaseOrder from "../../../graphql/mutations/purchases/updatePO";
import { CreateVendor, PurchasesSupplies } from "../../Organisms";
import { FormHeader, SelectWarehouse, SelectProject } from "../../Molecules";
import { validator } from "../../../helpers";
import FormValidator from "../../../helpers/form-validator";
import { store } from "../../../store/store";
import SearchSupply from "../../Atoms/searchSupply";
import validations from "./validations";
import moment from "moment";
import { bugsnagClient } from "../../../bugsnag";
import OnLoading from "../../Molecules/OnLoading";

const revisionIva = validations.revisionIva;
const revisionIvaZero = validations.revisionIvaZero;
const revisedIva = validations.revisedIva;
const revisedIvaZero = validations.revisedIvaZero;

export default class EditPurchaseOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isParsed: false,
      requisition: {
        iva: 16,
        discount: 0.0,
        discountDecimal: 0,
        retention: 0.0,
        retentionDecimal: 0,
        amount: 0.0,
      },
      supply: {
        id: "",
        insID: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
        project: {
          cli: "",
        },
      },
      supplies: [],
      newSupplies: [],
      suppliesToDelete: [],
      tableSupplies: [],
      errors: {
        applicantError: false,
        authorizedByError: false,
        cliError: false,
        currencyError: false,
        deliveryDateError: false,
        deliveryWarehouseError: false,
        descriptionError: false,
        formError: false,
        keyError: false,
        notesError: false,
        paymentConditionsError: false,
        purchasePriceError: false,
        quantityError: false,
        residentError: false,
        responsibleError: false,
        revisedByError: false,
        siteNameError: false,
        statusError: false,
        unitsError: false,
        vendorIdError: false,
        wasUpdatedAtError: false,
        peitiotnerError: false,
        ivaError: false,
      },
      toggles: {
        supplyQuickCreateToggle: true,
        editCli: false,
        editVendor: false,
      },
      modals: {
        addSupplyModal: false,
        updateSupplyModal: false,
      },
      options: {
        currencyOptions: [
          { key: "mxn", text: "MXN", value: "MXN" },
          { key: "usd", text: "USD", value: "USD" },
        ],
        statusOptionsManager: [
          {
            key: "revision",
            text: "Revisión",
            value: "IS_PO_REVIEW",
          },
          {
            key: "revisada",
            text: "Revisada",
            value: "IS_PO_REVISED",
          },
          {
            key: "cancelada",
            text: "Cancelada",
            value: "IS_PO_CANCEL",
          },
        ],
        statusPROptions: [
          {
            key: "revision",
            text: "Revisión",
            value: "IS_PO_REVIEW",
          },
          {
            key: "revisada",
            text: "Revisada",
            value: "IS_PO_REVISED",
          },
          {
            key: "cancelada",
            text: "Cancelada",
            value: "IS_PO_CANCEL",
          },
        ],
        paymentConditionsOptions: [
          { key: "credit", text: "Crédito", value: "CREDIT" },
          { key: "one-time", text: "Contado", value: "CASH" },
        ],
      },
      quickCreate: false,
      isCreatingSupply: false,
      session: JSON.parse(window.localStorage.getItem("session")).user,
      isLoading: true
    };
  }

  componentWillMount() {
    const requisition = this.state.requisition;
    let supplies = this.state.supplies;
    const _requisition = this.props.data.po;
    supplies = _requisition.supplies;

    requisition["resident"] = _requisition.project.residents.map(user => user.name);
    requisition["responsible"] = _requisition.project.responsibles.map(user => user.name);
    requisition["folio"] = this.props.data.po.folio;
    requisition["folioReq"] = _requisition.data ? _requisition.data.folio : "";
    requisition["notes"] = _requisition.notes;
    requisition["applicant"] = _requisition.applicant;
    requisition["petitioner"] = _requisition.petitioner;
    requisition["subtotal"] = _requisition.subtotal;
    requisition["project"] = _requisition.project;
    requisition["vendor"] = _requisition.vendor;
    requisition["authorizedBy"] = _requisition.authorizedBy;
    requisition["iva"] = _requisition.iva;
    requisition["discount"] = _requisition.discount;
    requisition["discountPercentage"] = _requisition.discountPercentage;
    requisition["retention"] = _requisition.retention;
    requisition["retentionPercentage"] = _requisition.retentionPercentage;
    requisition["amount"] = _requisition.amount;
    requisition["total"] = _requisition.total;
    requisition["deliveryDate"] = _requisition.deliveryDate;
    requisition["deliveryWarehouse"] = _requisition.deliveryWarehouse;
    requisition["status"] = _requisition.status;
    requisition["revisedBy"] = _requisition.revisedBy;
    requisition["wasUpdatedAt"] = _requisition.wasUpdatedAt;
    requisition["currency"] = _requisition.currency;
    requisition["paymentConditions"] = _requisition.paymentConditions;
    requisition["address"] = _requisition.project.address;
    requisition["vendorId"] = _requisition.vendor ? _requisition.vendor.id : "";
    requisition["projectIdOld"] = _requisition.project.id;
    this.setState(data => ({
      ...data,
      requisition,
      supplies,
      tableSupplies: supplies,
      isLoading: false
    }));
  }

  // Handle changes in requisition
  handleChange = ({ target }) => {
    const requisition = this.state.requisition;
    requisition[target.name] = target.value;
    this.setState(data => ({
      ...data,
      requisition,
    }));
  };

  handleIVAChange = ({ target }) => {
    const requisition = this.state.requisition;
    requisition["iva"] = parseFloat(target.value);
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState(data => ({
      ...data,
      requisition,
    }));
  };

  handleChangeSelect = (e, { name, value }) => {
    const requisition = this.state.requisition;
    requisition[name] = value;
    this.setState(data => ({ ...data, requisition }));
  };

  handleChangeSelectStatus = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const userName = JSON.parse(window.localStorage.getItem("session")).user
      .name;
    requisition[name] = value;
    requisition["revisedBy"] =
      value === "IS_PO_REVISED" || value === "IS_PO_APPROVED" ? userName : "";
    requisition["authorizedBy"] = value === "IS_PO_APPROVED" ? userName : "";
    this.setState(data => ({ ...data, requisition }));
  };

  // Handle changes in supply object
  handleChangeSupply = ({ target }) => {
    const supply = { ...this.state.supply };
    supply[target.name] = target.value;

    this.setState(data => ({
      ...data,
      supply
    }));
  };

  // Handle change cli
  handleChangeDropdown = (e, { name, value }) => {
    const requisition = this.state.requisition;
    requisition["vendorId"] = value.id;
    requisition["iva"] = parseFloat(value.iva);
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState(data => ({ ...data, requisition }));
  };

  handleChangeCli = (e, { name, value, options }) => {
    const selectedProject = options.find(i => i.key === value)
    const { responsibles, residents, name: nameProject, address } = selectedProject
    const _responsibles = responsibles.map(responsible => responsible.name)
    const _residents = residents.map(resident => resident.name)

    this.setState(prevState => ({
      ...prevState,
      requisition: {
        ...prevState.requisition,
        project: selectedProject,
        projectId: value,
        projectIdOld: prevState.requisition.project.id,
        siteName: nameProject,
        resident: _residents,
        responsible: _responsibles,
        address
      },
      toggles:{
        ...prevState.toggles,
        editProject: false
      }
    }))
  };

  handleChangeCurrency = (e, { name, value }) => {
    const requisition = this.state.requisition;

    requisition["currency"] = value;

    requisition["total"] = parseFloat(
      this.getTotal(this.state.supplies)
    ).toFixed(2);

    this.setState(data => ({
      ...data,
      requisition,
    }));
  };

  handleChangeWarehouse = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const _value = JSON.parse(value);
    const warehouseName = _value.name;
    const warehouseId = _value.id;
    requisition[name] = value;
    this.setState(data => ({ ...data, requisition, warehouseId }));
  };

  getDate = () => {
    var today = new Date();

    return (
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)
    );
  };

  deleteSupply = async index => {
    const supplies = [...this.state.supplies];
    const tableSupplies = [...this.state.tableSupplies];
    const suppliesToDelete = [];
    const requisition = this.state.requisition;

    // Only delete supplies that have already been savedroni
    if (supplies[index].id) {
      suppliesToDelete.push({ id: supplies[index].id });
    }

    supplies.splice(index, 1);
    tableSupplies.splice(index, 1);
    const decimalPercentage = this.calculateDiscountFromPercentage(
      requisition["discountPercentage"],
      this.getImporte(tableSupplies)
    );
    requisition["subtotal"] = parseFloat(this.getSubtotal(tableSupplies)).toFixed(2);
    requisition["amount"] = requisition["subtotal"] - decimalPercentage.toFixed(2);
    requisition["total"] = parseFloat(this.getTotal(tableSupplies)).toFixed(2);
    requisition["discount"] = decimalPercentage.toFixed(2);

    await updateReqNewSupply({
      id: this.props.data.po.data.id,
      suppliesToDelete: suppliesToDelete,
      amount: +requisition["amount"],
      subtotal: +requisition["subtotal"],
      total: +requisition["total"]
    })

    this.props.refetch();

    this.setState(data => ({
      ...data,
      supplies,
      requisition,
      tableSupplies,
    }));
  };

  save = async () => {
    this.setState(data => ({ ...data, isParsed: true }));
    const requisition = this.state.requisition;
    requisition["projectId"] = requisition.project.id;
    requisition["siteName"] = requisition.project.name;
    requisition["cli"] = requisition.project.cli;
    requisition["vendorId"] = requisition.vendorId ? requisition.vendorId : "";
    requisition["client"] = client;
    requisition["id"] = this.props.data.id;
    requisition["subtotal"] = this.getImporte(this.state.tableSupplies);
    requisition["amount"] = this.getSubtotal(this.state.tableSupplies);
    requisition["supplies"] = this.state.newSupplies;
    requisition["suppliesToDelete"] = this.state.suppliesToDelete;
    requisition["notes"] = escape(unescape(requisition["notes"]));

    const { id, projectId, projectIdOld } = requisition;
    let validation;

    if (!this.isRevised()) {
      validation = this.validateRevision(requisition);
    } else {
      validation = this.validateRevised(requisition);
    }

    if (validation && !this.isRevised()) {
      this.setState(prevState => ({ ...prevState, isLoading: true }))
      requisition["elaboratedEvent"] = {
        name: "null",
        type: "null",
        date: moment()
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      requisition["deliveryWarehouse"] = requisition["deliveryWarehouse"].replace(/([<>"()?])/g, "\\$1");

      updatePurchaseOrder(requisition)
        .then(async () => {

          if (projectId !== projectIdOld) {
            try {
              await updatePOToProject(id, projectId, projectIdOld);
            } catch (e) {
              this.setState(prevState => ({ ...prevState, isLoading: false }))
              bugsnagClient.notify(e);
            }
          }

          const requisition = this.state.requisition;
          requisition["suppliesQty"] = this.state.supplies;
          this.props.toggleSave(requisition);

          if (this.props.refetch) {
            this.props.refetch();
          }
        })
        .catch(e => {
          this.setState(prevState => ({ ...prevState, isLoading: false }))
          bugsnagClient.notify(e)
        });
    }

    if (validation && this.state.tableSupplies.length > 0 && this.isRevised()) {
      this.setState(prevState => ({ ...prevState, isLoading: true }))
      requisition["elaboratedEvent"] = {
        name: requisition["applicant"],
        type: "PURCHASE_ORDER_REVISED",
        date: moment()
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      requisition["deliveryWarehouse"] = requisition["deliveryWarehouse"].replace(/([<>"()?])/g, "\\$1");

      updatePurchaseOrder(requisition)
        .then(async () => {

          if (projectId !== projectIdOld) {
            try {
              await updatePOToProject(id, projectId, projectIdOld);
            } catch (e) {
              this.setState(prevState => ({ ...prevState, isLoading: false }))
              bugsnagClient.notify(e);
            }
          }

          const requisition = this.state.requisition;
          requisition["suppliesQty"] = this.state.supplies;
          this.setState(data => ({ ...data, isParsed: false, isLoading: false }));

          this.props.toggleSave(requisition);
          if (this.props.refetch) {
            this.props.refetch();
          }
        })
        .catch(e => {
          this.setState(prevState => ({ ...prevState, isLoading: false }))
          bugsnagClient.notify(e)
        });
    }
  };

  // Validate required form fields
  validateRevision = requisition => {
    const ivaIsZero = requisition["iva"] === 0;
    const validationObj = ivaIsZero ? revisionIvaZero : revisionIva;
    const validator = new FormValidator(validationObj);
    const validation = validator.validate(requisition);
    let errors = this.state.errors;
    Object.keys(validation).map(key => {
      const errorKey = `${key}Error`;
      errors[errorKey] = validation[key]["isInvalid"];
      return errors;
    });
    this.setState(data => ({ ...data, errors }));
    return validation.isValid;
  };

  validateRevised = requisition => {
    this.cleanUndefinedFields();
    const ivaIsZero = requisition["iva"] === 0;
    const validationObj = ivaIsZero ? revisedIvaZero : revisedIva;
    const validator = new FormValidator(validationObj);
    const validation = validator.validate(requisition);
    let errors = this.state.errors;
    Object.keys(validation).map(key => {
      const errorKey = `${key}Error`;
      errors[errorKey] = validation[key]["isInvalid"];
      return errors;
    });
    this.setState(data => ({ ...data, errors }));
    return validation.isValid;
  };

  isRevised = () => {
    const STATUS = ["IS_PO_REVIEW", "IS_PO_REVISED"];
    return STATUS[1] === this.state.requisition.status ? true : false;
  };

  cleanUndefinedFields = () => {
    const requisition = this.state.requisition;
    Object.keys(requisition).map(field => {
      if (requisition[field] === "undefined" || requisition[field] === "null") {
        requisition[field] = "";
      }
    });
    this.setState(data => ({ ...data, requisition }));
  };

  // Save supply to stack
  saveSupply = async () => {
    try {
      const errors = this.state.errors;
      errors["quantityError"] = false;
      this.setState(data => ({ ...data, isCreatingSupply: true, errors }));
      // Save the requisition object to
      const supplies = [...this.state.supplies];
      const newSupplies = [];
      const tableSupplies = [...this.state.tableSupplies];
      const requisition = this.state.requisition;
      const supply = this.validateSupply();
      let exists = false;

      if (supply.isValid && supply.data.quantity) {
        newSupplies.push(this.state.supply);
        tableSupplies.push(this.state.supply);
        supplies.push(this.state.supply);

        const decimalPercentage = this.calculateDiscountFromPercentage(
          requisition["discountPercentage"],
          this.getImporte(tableSupplies)
        );

        requisition["discount"] = decimalPercentage.toFixed(2);
        requisition["subtotal"] = parseFloat(this.getImporte(tableSupplies)).toFixed(2);
        requisition["amount"] = requisition["subtotal"] - decimalPercentage.toFixed(2);
        requisition["total"] = parseFloat(this.getTotal(tableSupplies)).toFixed(2);

        const { data } = await updateReqNewSupply({
          id: this.props.data.po.data.id,
          supply: newSupplies,
          amount: +requisition["amount"],
          subtotal: +requisition["subtotal"],
          total: +requisition["total"]
        })

        this.props.refetch();

        this.setState({
          supplies: data.updateRequisition.supplies,
          tableSupplies,
          supply: {
            key: "",
            description: "",
            units: "",
            quantity: "",
            purchasePrice: "",
          },
          requisition,
          modals: {
            addSupplyModal: false,
          },
          isCreatingSupply: false,
        });
      } else {
        if (exists) {
          alert("El insumo ya existe");
        } else {
          alert("Por favor llena los campos faltantes");
        }
        const errors = this.state.errors;
        errors["quantityError"] = true;
        this.setState(data => ({ ...data, isCreatingSupply: false, errors }));
      }
    } catch (e) {
      bugsnagClient.notify(e);
    }
  };

  saveSupplyQuickCreate = () => {
    const errors = this.state.errors;
    errors["quantityError"] = false;
    this.setState(data => ({ ...data, isCreatingSupply: true, errors }));
    getSupplies().then(async res => {
      const existingSupplies = res.supplies;
      let keys = [];
      existingSupplies.map(item => {
        const key = item.insID;
        keys.push(key);
      });
      const supply = this.validateSupply();
      if (supply.data !== null) {
        supply["data"]["description"] = supply.data.description;
      }
      let supplies = [...this.state.supplies];
      let newSupplies = [];
      let tableSupplies = [...this.state.tableSupplies];
      let exists = false;
      let existsInCatalog = false;
      if (supply.data) {
        supplies.map(_supply => {
          if (_supply.key === supply.data.insID) {
            exists = true;
          }
        });
        keys.map(key => {
          if (key === supply.data.insID) {
            existsInCatalog = true;
          }
        });
      }
      if (supply.isValid) {
        const requisition = this.state.requisition;

        supplies.push(supply.data);
        tableSupplies.push(supply.data);
        newSupplies.push(supply.data);

        const decimalPercentage = this.calculateDiscountFromPercentage(
          requisition["discountPercentage"],
          this.getImporte(tableSupplies)
        );

        requisition["discount"] = decimalPercentage.toFixed(2);
        requisition["amount"] = requisition["subtotal"] - decimalPercentage.toFixed(2);
        requisition["subtotal"] = parseFloat(this.getSubtotal(tableSupplies)).toFixed(2);
        requisition["total"] = parseFloat(this.getTotal(tableSupplies)).toFixed(2);

        try {
          const { data: { createSupply: { insID } } } = await CREATE_SUPPLY(supply.data);
          supplies[supplies.length - 1].insID = insID;

          await updateReqNewSupply({
            id: this.props.data.po.data.id,
            supply: newSupplies,
            amount: +requisition["amount"],
            subtotal: +requisition["subtotal"],
            total: +requisition["total"]
          })

          this.props.refetch();

          this.setState({
            supplies,
            requisition,
            newSupplies,
            tableSupplies,
            supply: {
              insID: "",
              description: "",
              units: "",
              quantity: "",
              purchasePrice: "",
            },
            toggles: {
              supplyQuickCreateToggle: true,
            },
            modals: {
              addSupplyModal: false,
            },
            isCreatingSupply: false,
          });
        } catch (err) {
          if (err.message.indexOf("unique constraint") !== -1) {
            alert("El insumo ya existe");

            /* TODO: Refactor so the supply is not added to the list in the UI
            /* unless is accepted in the server */
            supplies = supplies.filter(s => s.name !== supply.data.name);
            tableSupplies = tableSupplies.filter(s => s.name !== supply.data.name);
            newSupplies = newSupplies.filter(s => s.name !== supply.data.name);
          }

          this.setState({
            ...this.state,
            supplies,
            requisition,
            newSupplies,
            tableSupplies,
            isCreatingSupply: false
          });
        }
      } else {
        if(exists) {
          alert("El insumo ya existe");
        }
        else {
          alert("Por favor llena los campos faltantes");
        }
        const errors = this.state.errors;
        errors["quantityError"] = true;
        this.setState(data => ({ ...data, isCreatingSupply: false, errors }));
      }
    });
  };

  supplyKeyChange = (e, { value }) => {
    const data = JSON.parse(value);
    const { description, insID, name, units, purchasePrice } = data;
    this.setState(data => ({
      ...data,
      supply: { description, insID, name, units, purchasePrice, quantity: 1 },
    }));
  };

  updateSupply = async () => {
    const supplies = [...this.state.supplies];
    const tableSupplies = [...this.state.tableSupplies];
    const supply = { ...this.state.supply };
    const requisition = { ...this.state.requisition };

    supplies[this.state.editSupply] = supply;
    tableSupplies[this.state.editSupply] = supply;

    requisition["amount"] = parseFloat(this.getSubtotal(tableSupplies) ).toFixed(2);
    requisition["subtotal"] = parseFloat(this.getSubtotal(tableSupplies)).toFixed(2);
    requisition["total"] = parseFloat(this.getTotal(tableSupplies)).toFixed(2);

    if (supply.hasOwnProperty("id")) {
      await updateRequisitionSupply({
        id: this.props.data.po.data.id,
        supplyId: supply.id,
        supply,
        amount: +requisition["amount"],
        subtotal: +requisition["subtotal"],
        total: +requisition["total"]
      });
      this.props.refetch();
    }

    this.setState(data => ({
      ...data,
      supplies,
      supply,
      requisition,
      tableSupplies,
      modals: {
        addSupplyModal: false,
      },
    }));
  };

  // Validate fields
  validateFields = validationObject => {
    let errors = [];
    const _errors = this.state.errors;
    const validation = validator(validationObject);
    validation.map(field => {
      const key = field.key;
      const errorKey = `${key}Error`;
      if (!field.isValid) {
        errors.push(key);
      }

      const index = errors.indexOf("name");
      if (index !== -1) {
        errors.splice(index, 1);
      }

      _errors[errorKey] = !field.isValid;
      this.setState(data => ({
        ...data,
        errors: _errors,
      }));
    });
    if (errors.length > 0) {
      return { errors: errors, isValid: false };
    }
    if (errors.length === 0) {
      return { errors: errors, isValid: true };
    }
  };

  // Validates supply form fields
  validateSupply = () => {
    // ...
    const {
      name,
      description,
      units,
      quantity,
      purchasePrice,
    } = this.state.supply;
    const supply = { name, description, units, quantity, purchasePrice };
    const validation = this.validateFields(supply);
    if (validation.isValid) {
      return { data: supply, isValid: true };
    } else {
      return { data: null, isValid: false };
    }
  };

  vendorquickcreate = () => {
    let toggleValue = store.getState().navigationReducer.toggle_vendor;
    this.setState(data => ({ ...data, quickCreate: !this.state.quickCreate }));
    store.dispatch({
      type: "NAVIGATE_TO_CREATE_VENDOR",
      toggle_vendor: !toggleValue,
    });
  };

  // Supply quick create
  supplyQuickCreate = async () => {
    this.setState(data => ({
      ...data,
      toggles: {
        supplyQuickCreateToggle: !this.state.toggles.supplyQuickCreateToggle,
      },
      supply: {
        insID: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    }));
  };

  // Open the add supply modal
  openAddSupply = () => {
    this.setState({ supply: {}, modals: { addSupplyModal: true } });
  };

  openUpdateSupply = index => {
    const supply = this.state.supplies[index];
    const currentQty = supply.quantity;
    this.setState({
      modals: { updateSupplyModal: true },
      supply,
      editSupply: index,
      currentQty: currentQty,
    });
  };

  // Close de add supply modal
  closeAddSupply = () => {
    this.setState({
      modals: { addSupplyModal: false },
      supply: {
        key: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    });
  };

  closeUpdateSupply = () => {
    const supplies = [...this.state.supplies];
    const current = this.state.supplies[this.state.editSupply];
    current["quantity"] = this.state.currentQty;
    supplies[this.state.editSupply] = current;
    this.setState({
      modals: { updateSupplyModal: false },
      supplies,
      supply: {
        key: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    });
  };

  handleChangeDiscountPercentage = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const decimalPercentage = this.calculateDiscountFromPercentage(
      value,
      this.getImporte(this.state.tableSupplies)
    );
    requisition["discountPercentage"] = value;
    requisition["discount"] = !isNaN(parseFloat(decimalPercentage))
      ? parseFloat(decimalPercentage).toFixed(2)
      : 0
        ? decimalPercentage.toFixed(2)
        : 0;
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState({ ...requisition });
  };

  handleChangeDiscountDecimal = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const percentage = this.calculateDiscountFromDecimal(
      value,
      requisition["subtotal"]
    );
    requisition["discount"] = !isNaN(parseFloat(value))
      ? parseFloat(value)
      : 0
        ? 0
        : parseFloat(value);
    requisition["discountPercentage"] = !isNaN(percentage.toFixed(2))
      ? percentage.toFixed(2)
      : 0;
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState({ ...requisition });
  };

  handleChangeRetentionPercentage = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const retentionPercentage = this.calculateRetentionFromPercentage(
      value,
      this.getSubtotal(this.state.tableSupplies)
    );
    requisition["retentionPercentage"] = value;
    requisition["retention"] = !isNaN(retentionPercentage.toFixed(2))
      ? retentionPercentage.toFixed(2)
      : 0;
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState({ ...requisition });
  };

  handleChangeRetentionDecimal = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const retention = this.calculateRetentionFromDecimal(
      value,
      this.getSubtotal(this.state.tableSupplies)
    );

    requisition["retention"] = !isNaN(parseFloat(value))
      ? parseFloat(value)
      : 0;

    requisition["retentionPercentage"] = !isNaN(retention.toFixed(2))
      ? retention.toFixed(2)
      : 0;
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState({ ...requisition });
  };

  calculateDiscountFromPercentage = (discountPercentage, subtotal) => {
    return (
      (parseFloat(discountPercentage).toFixed(2) / 100) *
      parseFloat(subtotal).toFixed(2)
    );
  };

  calculateDiscountFromDecimal = (discount, subtotal) => {
    return (parseFloat(discount) / parseFloat(subtotal)) * 100;
  };

  calculateRetentionFromPercentage = (discountPercentage, subtotal) => {
    return (parseFloat(discountPercentage) / 100) * parseFloat(subtotal);
  };

  calculateRetentionFromDecimal = (discount, subtotal) => {
    return (parseFloat(discount) / parseFloat(subtotal)) * 100;
  };

  // Get the total of a supply added
  getTotalSupply = (a, b) => {
    if (a && b) {
      return `$${(a * b).toFixed(2)}`;
    } else {
      return 0;
    }
  };

  getTotal = supplies => {
    const subtotal = this.getSubtotal(supplies);
    if (
      this.state.requisition.retention > 0
    ) {
      const iva = (subtotal / 100) * this.state.requisition.iva;
      const retention = (!isNaN(parseFloat(this.state.requisition["retention"]))
        ? parseFloat(this.state.requisition["retention"])
        : 0);
      return subtotal - retention + iva;
    } else {
      return (
        this.getSubtotal(supplies) +
        this.getSubtotal(supplies) * (this.state.requisition.iva / 100)
      );
    }
  };

  getSubtotal = supplies => {
    let subtotal;
    let totals = [];
    supplies.map(({ quantity, purchasePrice }) => {
      const math = parseFloat(quantity) * parseFloat(purchasePrice);
      totals.push(math);
      return totals;
    });
    subtotal =
      totals.reduce((a, b) => a + b, 0) -
      (!isNaN(parseFloat(this.state.requisition["discount"]))
        ? parseFloat(this.state.requisition["discount"])
        : 0);
    return subtotal;
  };

  getImporte = supplies => {
    let subtotal;
    let totals = [];
    supplies.map(({ quantity, purchasePrice }) => {
      const math = parseFloat(quantity) * parseFloat(purchasePrice);
      totals.push(math);
      return totals;
    });
    subtotal = totals.reduce((a, b) => a + b, 0);
    return subtotal.toFixed(2);
  };

  toggleSupplyQuickCreate = () => {
    return <Button onClick={this.supplyQuickCreate}>X</Button>;
  };

  searchVendor = props => {
    const getVendors = QUERIES.getVendors;
    return (
      <Query query={getVendors} fetchPolicy={"network-only"}>
        {({ loading, error, data }) => {
          let options = [];
          if (loading) {
            return <Dropdown text="Cargando..." options={[]} loading />;
          }
          if (error) {
            return <Dropdown text="Error" options={[]} error />;
          }

          data.vendors.map(({ id, commercialName, iva }) => {
            options.push({
              key: id,
              text: commercialName,
              value: { id: id, iva: iva },
              style: { textAlign: "center", color: "#999999" },
            });
          });

          return (
            <Dropdown
              fluid
              name="vendorId"
              icon="search"
              wrapSelection={false}
              search
              selection
              onChange={this.handleChangeDropdown}
              vendorquickcreate={this.vendorquickcreate}
              error={this.state.errors.vendorIdError}
              readOnly={this.state.isReadOnly}
              autoComplete="new-password"
              options={[
                {
                  content: (
                    <p
                      style={{
                        color: "#295896",
                        borderTop: "1px solid #dddddd",
                        textAlign: "center",
                      }}
                    >
                      + Añadir nuevo proveedor
                    </p>
                  ),
                  key: "add",
                  value: "add-new",
                  onClick: this.vendorquickcreate,
                },
                ...options,
              ]}
            />
          );
        }}
      </Query>
    );
  };

  render() {
    const SearchVendor = this.searchVendor;
    const reqFolio = isNaN(parseInt(this.state.requisition.folioReq));
    const totalIsZero = this.state.supplies && this.state.supplies.length === 0 ? true : false;

    return (
      !this.state.quickCreate ? (
        <Segment className="Modal" raised>
          <FormHeader
            saveId="save-edit-po-btn"
            cancelId="cancel-edit-po-btn"
            text="Editar orden de compra"
            onSave={this.save}
            onCancel={this.props.onCancel}
          />
          {this.state.isLoading
            ? <OnLoading loading={this.state.isLoading} />
            : (
                <>
                  <Form className="Modal__Form">
                    <Form.Group widths="equal">
                      <Form.Field
                        label="FOLIO DE ORDEN"
                        name="folio"
                        control={Input}
                        value={this.state.requisition.folio}
                        onChange={this.handleChange}
                        readOnly
                        autoComplete="new-password"
                        required
                      />
                      <Form.Field
                        label="FECHA DE CREACIÓN"
                        name="wasUpdatedAt"
                        type="text"
                        value={
                          this.state.requisition.wasUpdatedAt
                            ? moment(
                              this.state.requisition.wasUpdatedAt.split("T")[0]
                            ).format("DD/MM/YYYY")
                            : ""
                        }
                        control={Input}
                        onChange={this.handleChange}
                        error={this.state.errors.wasUpdatedAtError}
                        readOnly
                        autoComplete="new-password"
                        required
                      />
                      <Form.Field
                        label="ESTATUS"
                        name="status"
                        control={Dropdown}
                        selection
                        onChange={this.handleChangeSelectStatus}
                        value={this.state.requisition.status}
                        options={
                          JSON.parse(window.localStorage.session).user.userRol ===
                            "TECHNICIAN"
                            ? [
                              {
                                key: "IS_PO_REVIEW",
                                value: "IS_PO_REVIEW",
                                text: "Revisión",
                              },
                            ]
                            : JSON.parse(window.localStorage.session).user
                              .userRol === "MANAGER"
                              ? this.state.options.statusOptionsManager
                              : this.state.options.statusPROptions
                        }
                        error={this.state.errors.statusError}
                        autoComplete="new-password"
                        required
                      />
                      <Form.Field
                        label="IMPORTE"
                        name="_subtotal"
                        value={this.getImporte(this.state.tableSupplies)}
                        control={CurrencyInput}
                        readOnly
                        autoComplete="off"
                        required
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        label="FOLIO DE REQUISICIÓN"
                        name="folio"
                        control={Input}
                        value={!reqFolio ? this.state.requisition.folioReq : ""}
                        onChange={this.handleChange}
                        readOnly
                        autoComplete="new-password"
                        width={10}
                        required
                        style={reqFolio ? { opacity: "0.35" } : null}
                      />
                      <Form.Field
                        label="PROVEEDOR"
                        name="vendorId"
                        control={
                          !this.state.toggles.editVendor ? Input : SearchVendor
                        }
                        icon={!this.state.toggles.editVendor ? "search" : null}
                        onClick={
                          !this.state.toggles.editVendor
                            ? () => {
                              const toggles = this.state.toggles;
                              toggles["editVendor"] = true;
                              this.setState(data => ({ ...data, toggles }));
                            }
                            : null
                        }
                        value={
                          this.state.requisition.vendor
                            ? this.state.requisition.vendor.commercialName
                            : ""
                        }
                        style={
                          !this.state.toggles.editVendor ? null : { height: "28px" }
                        }
                        onChange={this.handleChangeDropdown}
                        vendorquickcreate={this.vendorquickcreate}
                        error={this.state.errors.vendorIdError}
                        autoComplete="new-password"
                        width={10}
                        required
                      />
                      <Form.Field
                        label="ELABORÓ"
                        name="applicant"
                        control={Input}
                        value={
                          this.state.requisition.applicant === "undefined"
                            ? ""
                            : this.state.requisition.applicant
                        }
                        onChange={this.handleChange}
                        error={this.state.errors.applicantError}
                        autoComplete="new-password"
                        width={10}
                        required
                      />
                      <Form.Field
                        label="DESCUENTO"
                        name="discount"
                        type="number"
                        min={0}
                        control={Input}
                        placeholder="0%"
                        value={parseFloat(
                          this.state.requisition.discountPercentage
                        )}
                        onChange={this.handleChangeDiscountPercentage}
                        error={this.state.streetError}
                        width={5}
                        autoComplete="off"
                        readOnly={totalIsZero}
                      />
                      <Form.Field
                        label="&nbsp;"
                        name="discountDecimal"
                        min={0}
                        control={CurrencyInput}
                        placeholder="$0.00"
                        value={parseFloat(this.state.requisition.discount)}
                        onChange={this.handleChangeDiscountDecimal}
                        error={this.state.numberError}
                        width={5}
                        autoComplete="off"
                        readOnly={totalIsZero}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        label="CLI"
                        name="cli"
                        control={Input}
                        value={
                          this.state.requisition.project
                            ? this.state.requisition.project.cli
                            : ""
                        }
                        autoComplete="new-password"
                        readOnly
                        required
                      />
                      <Form.Field
                        label="ALMACÉN"
                        name="deliveryWarehouse"
                        value={
                          this.state.requisition.deliveryWarehouse !== "undefined"
                            ? this.state.requisition.deliveryWarehouse
                            : ""
                        }
                        control={SelectWarehouse}
                        onChange={this.handleChangeWarehouse}
                        error={
                          this.state.requisition.deliveryWarehouse ||
                            this.state.requisition.deliveryWarehouse !== "undefined"
                            ? this.state.errors.deliveryWarehouseError
                            : true
                        }
                        autoComplete="new-password"
                        required={
                          this.state.requisition.status === "IS_PO_REVIEW" ||
                            this.state.requisition.status === "IS_PO_CANCEL"
                            ? false
                            : true
                        }
                      />
                      <Form.Field
                        label="AUTORIZÓ"
                        name="authorizedBy"
                        control={Input}
                        value={
                          this.state.requisition.authorizedBy !== "undefined"
                            ? this.state.requisition.authorizedBy
                            : ""
                        }
                        onChange={this.handleChange}
                        error={this.state.errors.authorizedByError}
                        readOnly
                        autoComplete="new-password"
                      />
                      {/**subtotal */}
                      <Form.Field
                        label="SUBTOTAL"
                        name="subtotal"
                        value={this.getSubtotal(this.state.tableSupplies).toFixed(
                          2
                        )}
                        control={CurrencyInput}
                        readOnly
                        autoComplete="new-password"
                        required
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        user={this.state.session}
                        label="NOMBRE DE PROYECTO"
                        name="siteName"
                        control={SelectProject}
                        value={this.state.requisition.project.id}
                        onChange={this.handleChangeCli}
                        autoComplete="off"
                        width={10}
                        error={this.state.errors.siteNameError}
                        required
                        readOnly={this.state.requisition.status !== "IS_PO_REVIEW"}
                      />
                      <Form.Field
                        control={Input}
                        type="text"
                        label="TIEMPO DE ENTREGA"
                        name="deliveryDate"
                        onChange={this.handleChange}
                        width={10}
                        error={this.state.errors.deliveryDateError}
                        value={
                          this.state.requisition.deliveryDate !== "undefined"
                            ? this.state.requisition.deliveryDate
                            : ""
                        }
                        autoComplete="new-password"
                        required
                      />
                      <Form.Field
                        label="RESIDENTE"
                        name="resident"
                        control={Input}
                        value={
                          this.state.requisition.project
                            ? this.state.requisition.resident
                            : ""
                        }
                        onChange={this.handleChange}
                        error={this.state.errors.residentError}
                        width={10}
                        required
                        readOnly
                      />
                      {/**iva */}
                      <Form.Field
                        label="IVA"
                        name="iva"
                        type="number"
                        min={0}
                        width={10}
                        value={this.state.requisition.iva}
                        control={Input}
                        onChange={this.handleIVAChange}
                        autoComplete="new-password"
                        action={{ basic: true, content: "%" }}
                        labelPosition="right-corner"
                        required
                        error={this.state.errors.ivaError}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        label="RESPONSABLE DE PROYECTO"
                        name="responsible"
                        control={Input}
                        value={
                          this.state.requisition.project
                            ? this.state.requisition.responsible
                            : ""
                        }
                        onChange={this.handleChange}
                        error={this.state.errors.responsibleError}
                        autoComplete="new-password"
                        required
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        label="MONEDA"
                        name="currency"
                        control={Select}
                        value={this.state.requisition.currency}
                        onChange={this.handleChangeCurrency}
                        options={this.state.options.currencyOptions}
                        error={this.state.errors.currencyError}
                        autoComplete="new-password"
                        width={10}
                        required
                      />
                      <Form.Field
                        label="SOLICITANTE"
                        name="petitioner"
                        control={Input}
                        value={this.state.requisition.petitioner}
                        onChange={this.handleChange}
                        error={this.state.errors.petitionerError}
                        autoComplete="off"
                        width={10}
                        required
                      />
                      {/**retencion */}
                      <Form.Field
                        label="RETENCIÓN"
                        name="retention"
                        type="number"
                        min={0}
                        control={Input}
                        placeholder="0.00%"
                        value={this.state.requisition.retentionPercentage}
                        onChange={this.handleChangeRetentionPercentage}
                        error={this.state.streetError}
                        width={5}
                        autoComplete="off"
                        readOnly={totalIsZero}
                      />
                      <Form.Field
                        label="&nbsp;"
                        name="retentionDecimal"
                        min={0}
                        control={CurrencyInput}
                        placeholder="$0.00"
                        value={parseFloat(this.state.requisition.retention).toFixed(
                          2
                        )}
                        onChange={this.handleChangeRetentionDecimal}
                        error={this.state.numberError}
                        width={5}
                        autoComplete="off"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        label="REVISÓ"
                        name="revisedBy"
                        control={Input}
                        value={
                          this.state.requisition.revisedBy !== "undefined"
                            ? this.state.requisition.revisedBy
                            : ""
                        }
                        onChange={this.handleChange}
                        error={this.state.errors.revisedByError}
                        readOnly
                        autoComplete="new-password"
                        required={
                          this.state.requisition.status === "IS_PO_REVIEW" ||
                            this.state.requisition.status === "IS_PO_CANCEL"
                            ? false
                            : true
                        }
                      />
                      <Form.Field
                        label="CONDICIONES DE PAGO"
                        name="paymentConditions"
                        control={Select}
                        value={this.state.requisition.paymentConditions}
                        onChange={this.handleChangeSelect}
                        options={this.state.options.paymentConditionsOptions}
                        error={this.state.errors.paymentConditionsError}
                        autoComplete="new-password"
                        required
                      />
                      <Form.Field />
                      <Form.Field
                        label="TOTAL"
                        min={0}
                        name="total"
                        value={
                          parseFloat(this.state.requisition.total) > 0
                            ? parseFloat(this.state.requisition.total).toFixed(2)
                            : () => {
                              const requisition = this.state.requisition;
                              requisition["total"] = 0;
                              this.setState({ ...requisition });
                            }
                        }
                        control={CurrencyInput}
                        onChange={this.handleChange}
                        readOnly
                        required
                      />
                    </Form.Group>
                    <Form.Field
                      label="UBICACIÓN DEL PROYECTO"
                      name="address"
                      onChange={this.handleChange}
                      value={
                        this.state.requisition.address &&
                          this.state.requisition.address !== ""
                          ? this.state.requisition.address
                          : ""
                      }
                      control={TextArea}
                      error={this.state.errors.notesError}
                      autoComplete="new-password"
                      readOnly
                    />
                    <Form.Field
                      label="NOTAS"
                      name="notes"
                      control={TextArea}
                      value={
                        this.state.requisition.notes === "undefined"
                          ? ""
                          : unescape(this.state.requisition.notes)
                      }
                      onChange={this.handleChange}
                      error={this.state.errors.notesError}
                      autoComplete="new-password"
                    />
                  </Form>
                  <Header as="h3" dividing className="Modal__Form-Header">
                    Insumos
                  </Header>
                  <Table
                    basic="very"
                    compact="very"
                    textAlign="center"
                    className="Modal__Form-Table"
                  >
                    <Table.Header className="Modal__Form-Table-Header">
                      <Table.Row>
                        <Table.HeaderCell>Clave del insumo</Table.HeaderCell>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell>Descripción</Table.HeaderCell>
                        <Table.HeaderCell>Unidad de medida</Table.HeaderCell>
                        <Table.HeaderCell>Precio unitario</Table.HeaderCell>
                        <Table.HeaderCell>Cantidad</Table.HeaderCell>
                        <Table.HeaderCell>Total</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body className="Modal__Form-Table-Body">
                      {this.state.tableSupplies.map((supply, index) => {
                        const {
                          insID,
                          description,
                          name,
                          quantity,
                          units,
                          purchasePrice,
                        } = supply;
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>{insID}</Table.Cell>
                            <Table.Cell>{unescape(name)}</Table.Cell>
                            <Table.Cell
                              style={{
                                maxWidth: "300px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {unescape(description)}
                            </Table.Cell>
                            <Table.Cell>{units}</Table.Cell>
                            <Table.Cell>
                              $
                              {parseFloat(purchasePrice)
                                .toFixed(2)
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Table.Cell>
                            <Table.Cell>{quantity}</Table.Cell>
                            <Table.Cell>
                              {this.getTotalSupply(quantity, purchasePrice)
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Table.Cell>
                            <Table.Cell>
                              <Button.Group basic>
                                <Button
                                  icon
                                  onClick={() => this.openUpdateSupply(index)}
                                >
                                  <IoMdCreate />
                                </Button>
                                <Button
                                  icon
                                  onClick={() => this.deleteSupply(index)}
                                >
                                  <IoIosTrash />
                                </Button>
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <Container textAlign="center">
                    <Modal
                      className="Modal__Form-SubModal"
                      centered={false}
                      trigger={
                        <Button
                          onClick={this.openAddSupply}
                          className="Modal__Form-ButtonAdd"
                          icon="plus"
                          content="Añadir insumo"
                          size="large"
                        />
                      }
                      open={this.state.modals.addSupplyModal}
                      header={
                        <FormHeader
                          saveId="save-supply-to-po-btn"
                          cancelId="cancel-po-supply-btn"
                          text="Añadir Insumo"
                          onCancel={this.closeAddSupply}
                          onSave={
                            this.state.toggles.supplyQuickCreateToggle
                              ? this.saveSupply
                              : this.state.isCreatingSupply
                                ? null
                                : this.saveSupplyQuickCreate
                          }
                        />
                      }
                      content={
                        <PurchasesSupplies
                          toggleSupplyQuickCreate={this.toggleSupplyQuickCreate}
                          data={this.state}
                          SearchSupply={SearchSupply}
                          supplyKeyChange={this.supplyKeyChange}
                          handleChangeSupply={this.handleChangeSupply}
                          handleSearchChange={this.handleSearchChange}
                          supplyQuickCreate={this.supplyQuickCreate}
                          getTotalSupply={this.getTotalSupply}
                        />
                      }
                      size="tiny"
                      dimmer="blurring"
                    />
                    <Modal
                      className="Modal__Form-SubModal"
                      centered={false}
                      open={this.state.modals.updateSupplyModal}
                      onClose={this.handleCloseModal}
                      header={
                        <FormHeader
                          saveId="update-po-supply-btn"
                          cancelId="cancel-po-supply-update-btn"
                          text="Actualizar insumo"
                          onCancel={this.closeUpdateSupply}
                          onSave={this.updateSupply}
                        />
                      }
                      content={
                        <PurchasesSupplies
                          toggleSupplyQuickCreate={this.toggleSupplyQuickCreate}
                          data={this.state}
                          SearchSupply={SearchSupply}
                          supplyKeyChange={this.supplyKeyChange}
                          handleChangeSupply={this.handleChangeSupply}
                          handleSearchChange={this.handleSearchChange}
                          supplyQuickCreate={this.supplyQuickCreate}
                          getTotalSupply={this.getTotalSupply}
                          isEdit
                        />
                      }
                      size="tiny"
                      dimmer="blurring"
                    />
                  </Container>
                </>
              )
          }
        </Segment>
      ) : (
        <CreateVendor
          onCancel={this.vendorquickcreate}
          toggleSave={this.vendorquickcreate}
          isQuickCreate
        />
      )
    );
  }
}
