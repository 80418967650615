import React from "react";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { Button, Container, Dropdown, Form, Header, Input, Modal, Segment, Table, TextArea, Select } from "semantic-ui-react";
import CurrencyInput from "react-currency-input";
import { IoMdCreate, IoIosTrash } from "react-icons/io";
import getSupplies from "../../../graphql/queries/purchases/getSupplies";
import { client, QUERIES } from "../../../graphql/apollo-config";
import { updateRequisitionSupply, updateReqToProject, updateReqNewSupply } from "../../../graphql/mutations/purchases";
import { CREATE_SUPPLY } from "../../../graphql/mutations/supplies";
import updateRequisition from "../../../graphql/mutations/purchases/updateReq";
import { CreateVendor, PurchasesSupplies } from "../../Organisms";
import { FormHeader, SelectWarehouse, SelectProject } from "../../Molecules";
import { validator } from "../../../helpers";
import FormValidator from "../../../helpers/form-validator";
import { store } from "../../../store/store";
import SearchSupply from "../../Atoms/searchSupply";
import { Can } from "../../hoc/permissions";
import validations from "./validations";
import moment from "moment";
import { bugsnagClient } from "../../../bugsnag";
import OnLoading from "../../Molecules/OnLoading";

const revisionIva = validations.revisionIva;
const revisionIvaZero = validations.revisionIvaZero;
const revisedIva = validations.revisedIva;
const revisedIvaZero = validations.revisedIvaZero;

export default class EditRequisition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isParsed: false,
      requisition: {
        id: "",
        notes: "",
        discount: 0.0,
        discountDecimal: 0,
        retention: 0.0,
        retentionDecimal: 0,
        amount: 0.0,
        deliveryDate: "",
        project:{
          id: "",
          cli: ""
        }
      },
      supply: {
        insID: "",
        name: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
      project: {
        cli: "",
      },
      supplies: [],
      newSupplies: [],
      suppliesToUpdate: [],
      tableSupplies: [],
      errors: {
        applicantError: false,
        authorizedByError: false,
        cliError: false,
        currencyError: false,
        deliveryDateError: false,
        deliveryWarehouseError: false,
        descriptionError: false,
        formError: false,
        keyError: false,
        notesError: false,
        paymentConditionsError: false,
        purchasePriceError: false,
        quantityError: false,
        residentError: false,
        responsibleError: false,
        revisedByError: false,
        siteNameError: false,
        unitsError: false,
        statusError: false,
        vendorIdError: false,
        wasUpdatedAtError: false,
        petitionerError: false,
        ivaError: false,
      },
      toggles: {
        supplyQuickCreateToggle: true,
        editProject: false,
        editCli: false,
        editVendor: false,
      },
      modals: {
        addSupplyModal: false,
        updateSupplyModal: false,
      },
      options: {
        currencyOptions: [
          { key: "mxn", text: "MXN", value: "MXN" },
          { key: "usd", text: "USD", value: "USD" },
        ],
        statusOptionsPurchasesResponsible: [
          {
            key: "revision",
            text: "Revisión",
            value: "IS_REQ_REVISION",
          },
          {
            key: "revisada",
            text: "Revisada",
            value: "IS_REQ_REVISED",
          },
          {
            key: "cancelada",
            text: "Cancelada",
            value: "IS_REQ_CANCEL",
          },
        ],
        statusOptionsManagerRevise: [
          {
            key: "revision",
            text: "Revisión",
            value: "IS_REQ_REVISION",
          },
          {
            key: "revisada",
            text: "Revisada",
            value: "IS_REQ_REVISED",
          },
          {
            key: "cancelada",
            text: "Cancelada",
            value: "IS_REQ_CANCEL",
          },
        ],
        paymentConditionsOptions: [
          { key: "credit", text: "Crédito", value: "CREDIT" },
          { key: "one-time", text: "Contado", value: "CASH" },
        ],
      },
      quickCreate: false,
      isCreatingSupply: false,
      session: JSON.parse(window.localStorage.getItem("session")).user,
      isLoading: true
    };
  }

  componentWillMount() {
    this.getRequisition(this.props.data.id)
      .then(res => {
        const requisition = res.requisition;
        requisition["petitioner"] = requisition.petitioner;
        requisition["vendorId"] = requisition.vendor
          ? requisition.vendor.id
          : null;
        let supplies = this.state.supplies;
        supplies = requisition["supplies"];

        requisition["resident"] = requisition.project.residents.map(user => user.name);
        requisition["responsible"] = requisition.project.responsibles.map(user => user.name);
        requisition["address"] = requisition.project.address;
        requisition["project"] = requisition.project;
        requisition["projectIdOld"] = requisition.project.id;

        this.setState(prevState => ({
          ...prevState,
          requisition,
          supplies,
          currentSupplies: supplies,
          tableSupplies: supplies,
          isLoading: false
        }));
      })
      .catch(e => console.log(e));
  }

  getRequisition = async id => {
    const { data } = await client.query({
      query: gql`
        {
          requisition(
            where:{
              id:"${id}"
            }
          ){
            folio
            wasUpdatedAt
            applicant
            status
            revisedBy
            petitioner
            authorizedBy
            project {
              id
              cli
              name
              address
              responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
                id,
                name
              }
              residents: users(where: { userRol_in: [TECHNICIAN] }){
                id,
                name
              }
            }
            vendor {
              id
              commercialName
            }
            deliveryWarehouse
            deliveryDate
            paymentConditions
            currency
            notes
            supplies {
              id
              name
              insID
              description
              units
              quantity
              purchasePrice
            }
            iva
            retention
            discount
            retentionPercentage
            discountPercentage
            amount
            subtotal
            total
          }
        }
      `,
      fetchPolicy: "no-cache",
    });
    return data;
  };

  // Handle changes in requisition
  handleChange = ({ target }) => {
    const requisition = this.state.requisition;
    requisition[target.name] = target.value;
    this.setState(data => ({
      ...data,
      requisition,
    }));
  };

  handleIVAChange = ({ target }) => {
    const requisition = this.state.requisition;
    requisition["iva"] = parseFloat(target.value);
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState(data => ({
      ...data,
      requisition
    }));
  };

  handleChangeSelect = (e, { name, value }) => {
    const requisition = this.state.requisition;
    requisition[name] = value;
    this.setState(data => ({ ...data, requisition }));
  };

  handleChangeSelectStatus = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const userName = JSON.parse(window.localStorage.getItem("session")).user
      .name;
    requisition[name] = value;
    requisition["revisedBy"] =
      value === "IS_REQ_REVISED" || value === "IS_REQ_APPROVED" ? userName : "";
    requisition["authorizedBy"] = value === "IS_REQ_APPROVED" ? userName : "";
    this.setState(data => ({ ...data, requisition }));
  };

  // Handle changes in supply object
  handleChangeSupply = ({ target }) => {
    const supply = { ...this.state.supply };
    supply[target.name] = target.value;

    this.setState(data => ({
      ...data,
      supply
    }));
  };

  // Handle change cli
  handleChangeDropdown = (e, { name, value }) => {
    const requisition = this.state.requisition;
    requisition["vendorId"] = value.id;
    requisition["iva"] = parseFloat(value.iva);
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState(data => ({ ...data, requisition }));
  };

  handleChangeCli = (e, { name, value, options }) => {
    const selectedProject = options.find(i => i.key === value)
    const { responsibles, residents, name: nameProject, address } = selectedProject
    const _responsibles = responsibles.map(responsible => responsible.name)
    const _residents = residents.map(resident => resident.name)

    this.setState(prevState => ({
      ...prevState,
      requisition: {
        ...prevState.requisition,
        project: selectedProject,
        projectId: value,
        projectIdOld: prevState.requisition.project.id,
        siteName: nameProject,
        resident: _residents,
        responsible: _responsibles,
        address
      },
      toggles: {
        ...prevState.toggles,
        editProject: false
      }
    }))
  };

  handleChangeCurrency = (e, { name, value }) => {
    const requisition = this.state.requisition;

    requisition["currency"] = value;

    requisition["total"] = parseFloat(
      this.getTotal(this.state.supplies)
    ).toFixed(2);

    this.setState(data => ({
      ...data,
      requisition,
    }));
  };

  handleChangeWarehouse = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const _value = JSON.parse(value);
    const warehouseId = _value.id;
    requisition[name] = value;
    this.setState(data => ({ ...data, requisition, warehouseId }));
  };

  deleteSupply = async index => {
    // Save the requisition object to
    const supplies = [...this.state.supplies];
    const tableSupplies = [...this.state.tableSupplies];
    const suppliesToDelete = [];
    const requisition = this.state.requisition;

    // Only delete supplies that have already been savedroni
    if (supplies[index].id) {
      suppliesToDelete.push({ id: supplies[index].id });
    }

    supplies.splice(index, 1);
    tableSupplies.splice(index, 1);
    const decimalPercentage = this.calculateDiscountFromPercentage(
      requisition["discountPercentage"],
      this.getImporte(tableSupplies)
    );
    requisition["subtotal"] = parseFloat(this.getSubtotal(tableSupplies)).toFixed(2);
    requisition["amount"] = requisition["subtotal"] - decimalPercentage.toFixed(2);
    requisition["total"] = parseFloat(this.getTotal(tableSupplies)).toFixed(2);
    requisition["discount"] = decimalPercentage.toFixed(2);

    await updateReqNewSupply({
      id: this.props.data.id,
      suppliesToDelete: suppliesToDelete,
      amount: +requisition["amount"],
      subtotal: +requisition["subtotal"],
      total: +requisition["total"]
    })

    this.props.refetch();

    this.setState(data => ({
      ...data,
      supplies,
      requisition,
      tableSupplies,
    }));
  };

  // Save requisition to DB
  save = async () => {
    this.setState(data => ({ ...data, isParsed: true }));
    const requisition = this.state.requisition;
    requisition["projectId"] = requisition.project.id;
    requisition["siteName"] = requisition.project.name;
    requisition["cli"] = requisition.project.cli;
    requisition["client"] = client;
    requisition["id"] = this.props.data.id;
    requisition["subtotal"] = this.getImporte(this.state.tableSupplies);
    requisition["amount"] = this.getSubtotal(this.state.tableSupplies);
    requisition["supplies"] = this.state.newSupplies.map(supply => {
      delete supply.tempId;
      return supply;
    });
    if (!this.state.isParsed)
      requisition["deliveryWarehouse"] = requisition[
        "deliveryWarehouse"
      ].replace(/([<>"()?])/g, "\\$1");

    const { id, projectId, projectIdOld } = requisition;
    let validation;

    if (!this.isRevised()) {
      validation = this.validateRevision(requisition);
    } else {
      validation = this.validateRevised(requisition);
    }

    if (validation && !this.isRevised()) {
      this.setState(prevState => ({ ...prevState, isLoading: true }))
      requisition["notes"] = escape(unescape(requisition["notes"]));
      requisition["elaboratedEvent"] = {
        name: "null",
        type: "null",
        date: moment()
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };

      updateRequisition(requisition)
        .then(async res => {
          this.props.toggleSave(requisition);

          if (projectId !== projectIdOld) {
            try {
              await updateReqToProject(id, projectId, projectIdOld);
            } catch (e) {
              this.setState(prevState => ({ ...prevState, isLoading: false }))
              bugsnagClient.notify(e);
            }
          }

          if (this.props.refetch) {
            this.props.refetch();
          }
          this.setState(data => ({ ...data, isParsed: false, isLoading: false }));
        })
        .catch(e => {
          this.setState(prevState => ({ ...prevState, isLoading: false }))
          bugsnagClient.notify(e);
        });
    }

    if (validation && this.state.tableSupplies.length > 0 && this.isRevised()) {
      this.setState(prevState => ({ ...prevState, isLoading: true }))
      requisition["elaboratedEvent"] = {
        name: requisition["revisedBy"],
        type: "REQUISITION_REVISED",
        date: moment()
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      requisition["notes"] = escape(unescape(requisition["notes"]));
      updateRequisition(requisition)
        .then(async res => {

          if (projectId !== projectIdOld) {
            try {
              await updateReqToProject(id, projectId, projectIdOld);
            } catch (e) {
              this.setState(prevState => ({ ...prevState, isLoading: false }))
              bugsnagClient.notify(e);
            }
          }

          this.props.toggleSave(requisition);
          if (this.props.refetch) {
            this.props.refetch();
          }
        })
        .catch(e => {
          this.setState(prevState => ({ ...prevState, isLoading: false }))
          bugsnagClient.notify(e);
        });
    }
  };

  // Validate required form fields
  validateRevision = requisition => {
    const ivaIsZero = requisition["iva"] === 0;
    const validationObj = ivaIsZero ? revisionIvaZero : revisionIva;
    const validator = new FormValidator(validationObj);
    const validation = validator.validate(requisition);
    let errors = this.state.errors;
    Object.keys(validation).map(key => {
      const errorKey = `${key}Error`;
      errors[errorKey] = validation[key]["isInvalid"];
      return errors;
    });
    this.setState(data => ({ ...data, errors }));
    return validation.isValid;
  };

  validateRevised = requisition => {
    this.cleanUndefinedFields();
    const ivaIsZero = requisition["iva"] === 0;
    const validationObj = ivaIsZero ? revisedIvaZero : revisedIva;
    const validator = new FormValidator(validationObj);
    const validation = validator.validate(requisition);
    let errors = this.state.errors;
    Object.keys(validation).map(key => {
      const errorKey = `${key}Error`;
      errors[errorKey] = validation[key]["isInvalid"];
      return errors;
    });
    this.setState(data => ({ ...data, errors }));
    return validation.isValid;
  };

  isRevised = () => {
    const STATUS = ["IS_REQ_REVISION", "IS_REQ_REVISED"];
    return STATUS[1] === this.state.requisition.status ? true : false;
  };

  cleanUndefinedFields = () => {
    const requisition = this.state.requisition;
    Object.keys(requisition).map(field => {
      if (requisition[field] === "undefined" || requisition[field] === "null") {
        requisition[field] = "";
      }
    });
    this.setState(data => ({ ...data, requisition }));
  };

  // Save supply to stack
  saveSupply = async () => {
    try {
      const errors = this.state.errors;
      errors["quantityError"] = false;
      this.setState(data => ({ ...data, isCreatingSupply: true, errors }));
      // Save the requisition object to
      const supplies = [...this.state.supplies];
      const newSupplies = [];
      const tableSupplies = [...this.state.tableSupplies];
      const requisition = this.state.requisition;
      const supply = this.validateSupply();
      let exists = false;

      if (supply.isValid && supply.data.quantity) {
        supplies.push({ ...this.state.supply });
        newSupplies.push({ ...this.state.supply });
        tableSupplies.push(this.state.supply);

        const decimalPercentage = this.calculateDiscountFromPercentage(
          requisition["discountPercentage"],
          this.getImporte(tableSupplies)
        );

        requisition["subtotal"] = parseFloat(this.getImporte(tableSupplies)).toFixed(2);
        requisition["amount"] = requisition["subtotal"] - decimalPercentage.toFixed(2);
        requisition["discount"] = decimalPercentage.toFixed(2);
        requisition["total"] = parseFloat(this.getTotal(tableSupplies)).toFixed(2);

        const { data } = await updateReqNewSupply({
          id: this.props.data.id,
          supply: newSupplies,
          amount: +requisition["amount"],
          subtotal: +requisition["subtotal"],
          total: +requisition["total"]
        })

        this.props.refetch();

        this.setState({
          supplies: data.updateRequisition.supplies,
          tableSupplies,
          supply: {
            key: "",
            description: "",
            units: "",
            quantity: "",
            purchasePrice: "",
          },
          requisition,
          modals: {
            addSupplyModal: false,
          },
          isCreatingSupply: false,
        });
      } else {
        if (exists) {
          alert("El insumo ya existe");
        } else {
          alert("Por favor llena los campos faltantes");
        }
        const errors = this.state.errors;
        this.setState(data => ({ ...data, isCreatingSupply: false, errors }));
      }
    } catch (e) {
      bugsnagClient.notify(e);
    }
  };

  saveSupplyQuickCreate = () => {
    const errors = this.state.errors;
    errors["quantityError"] = false;
    this.setState(data => ({ ...data, isCreatingSupply: true, errors }));
    getSupplies().then(async res => {
      const existingSupplies = res.supplies;
      let keys = [];
      existingSupplies.map(item => {
        const key = item.insID;
        keys.push(key);
        return keys;
      });
      const supply = this.validateSupply();
      if (supply.data !== null)
        supply["data"]["description"] = supply.data.description;
      const supplies = [...this.state.supplies];
      let exists = false;
      let existsInCatalog = false;
      if (supply.data) {
        supplies.map(_supply => {
          if (supply.data !== null && _supply.insID === supply.data.insID) {
            exists = true;
          }
          return exists;
        });
        keys.map(key => {
          if (supply.data !== null && key === supply.data.insID) {
            existsInCatalog = true;
          }
          return existsInCatalog;
        });
      }
      if (
        supply.isValid &&
        !exists &&
        !existsInCatalog &&
        supply.data.quantity
      ) {
        const requisition = this.state.requisition;
        const newSupplies = [];
        const tableSupplies = [...this.state.tableSupplies];

        supplies.push(supply.data);
        newSupplies.push(supply.data);
        tableSupplies.push(supply.data);

        const decimalPercentage = this.calculateDiscountFromPercentage(
          requisition["discountPercentage"],
          this.getImporte(tableSupplies)
        );

        requisition["discount"] = decimalPercentage.toFixed(2);
        requisition["amount"] = requisition["subtotal"] - decimalPercentage.toFixed(2);
        requisition["subtotal"] = parseFloat(this.getSubtotal(tableSupplies)).toFixed(2);
        requisition["total"] = parseFloat(this.getTotal(tableSupplies)).toFixed(2);

        try {
          const { data: { createSupply: { insID } } } = await CREATE_SUPPLY(supply.data);
          supplies[supplies.length - 1].insID = insID;

          await updateReqNewSupply({
            id: this.props.data.id,
            supply: newSupplies,
            amount: +requisition["amount"],
            subtotal: +requisition["subtotal"],
            total: +requisition["total"]
          })

          this.props.refetch();

          this.setState({
            supplies,
            tableSupplies,
            requisition,
            supply: {
              insID: "",
              description: "",
              units: "",
              quantity: "",
              purchasePrice: "",
            },
            toggles: {
              supplyQuickCreateToggle: true,
            },
            modals: {
              addSupplyModal: false,
            },
            isCreatingSupply: false,
          });
        } catch (e) {
          alert("El insumo ya existe");
        }
      } else {
        if(exists) {
          alert("El insumo ya existe");
        }
        else {
          alert("Por favor llena los campos faltantes");
        }
        const errors = this.state.errors;
        errors["quantityError"] = true;
        this.setState(data => ({ ...data, isCreatingSupply: false, errors }));
      }
    });
  };

  supplyKeyChange = (e, { value }) => {
    const data = JSON.parse(value);
    const { description, insID, name, units, purchasePrice } = data;
    this.setState(data => ({
      ...data,
      supply: { description, insID, name, units, purchasePrice, quantity: 1 }
    }));
  };

  updateSupply = async () => {
    const supplies = [...this.state.supplies];
    const tableSupplies = [...this.state.tableSupplies];
    const supply = { ...this.state.supply };
    const requisition = { ...this.state.requisition };

    supplies[this.state.editSupply] = supply;
    tableSupplies[this.state.editSupply] = supply;

    requisition["amount"] = parseFloat(this.getSubtotal(tableSupplies) ).toFixed(2);
    requisition["subtotal"] = parseFloat(this.getSubtotal(tableSupplies)).toFixed(2);
    requisition["total"] = parseFloat(this.getTotal(tableSupplies)).toFixed(2);

    if (supply.id) {
      await updateRequisitionSupply({
        id: this.props.data.id,
        supplyId: supply.id,
        supply,
        amount: +requisition["amount"],
        subtotal: +requisition["subtotal"],
        total: +requisition["total"]
      });
      this.props.refetch();
    }

    this.setState(data => ({
      ...data,
      supplies,
      supply,
      requisition,
      tableSupplies,
      modals: {
        addSupplyModal: false,
      },
    }));
  };

  // Validate fields
  validateFields = validationObject => {
    let errors = [];
    const _errors = this.state.errors;
    const validation = validator(validationObject);
    validation.map(field => {
      const key = field.key;
      const errorKey = `${key}Error`;
      if (!field.isValid) {
        errors.push(key);
      }

      const index = errors.indexOf("name");
      if (index !== -1) {
        errors.splice(index, 1);
      }

      _errors[errorKey] = !field.isValid;
      this.setState(data => ({
        ...data,
        errors: _errors,
      }));
      return errors;
    });
    if (errors.length > 0) {
      return { errors: errors, isValid: false };
    }
    if (errors.length === 0) {
      return { errors: errors, isValid: true };
    }
  };

  // Validates supply form fields
  validateSupply = () => {
    const {
      name,
      description,
      units,
      quantity,
      purchasePrice,
    } = this.state.supply;
    const supply = { name, description, units, quantity, purchasePrice };
    const validation = this.validateFields(supply);
    if (validation.isValid) {
      return { data: supply, isValid: true };
    } else {
      return { data: null, isValid: false };
    }
  };

  vendorquickcreate = () => {
    let toggleValue = store.getState().navigationReducer.toggle_vendor;
    this.setState(data => ({ ...data, quickCreate: !this.state.quickCreate }));
    store.dispatch({
      type: "NAVIGATE_TO_CREATE_VENDOR",
      toggle_vendor: !toggleValue,
    });
  };

  // Supply quick create
  supplyQuickCreate = async () => {
    this.setState(data => ({
      ...data,
      toggles: {
        supplyQuickCreateToggle: !this.state.toggles.supplyQuickCreateToggle,
      },
      supply: {
        insID: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    }));
  };

  // Open the add supply modal
  openAddSupply = () => {
    this.setState({ supply: {}, modals: { addSupplyModal: true } });
  };

  openUpdateSupply = index => {
    const supply = this.state.supplies[index];
    const currentQty = supply.quantity;
    this.setState({
      modals: { updateSupplyModal: true },
      supply,
      editSupply: index,
      currentQty: currentQty,
    });
  };

  // Close de add supply modal
  closeAddSupply = () => {
    this.setState({
      supply: {
        key: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
      modals: { addSupplyModal: false },
    });
  };

  closeUpdateSupply = () => {
    const supplies = [...this.state.supplies];
    const current = this.state.supplies[this.state.editSupply];
    current["quantity"] = this.state.currentQty;
    supplies[this.state.editSupply] = current;
    this.setState({
      modals: { updateSupplyModal: false },
      supplies,
      supply: {
        key: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
    });
  };

  handleChangeDiscountPercentage = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const decimalPercentage = this.calculateDiscountFromPercentage(
      value,
      this.getImporte(this.state.tableSupplies)
    );
    requisition["discountPercentage"] = value;
    requisition["discount"] = !isNaN(parseFloat(decimalPercentage))
      ? parseFloat(decimalPercentage).toFixed(2)
      : 0
        ? decimalPercentage.toFixed(2)
        : 0;
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    requisition["amount"] = this.getSubtotal(this.state.tableSupplies);
    this.setState(prevState => ({
      ...prevState,
      requisition: {
        ...prevState.requisition,
        requisition
      }
    }));
  };

  handleChangeDiscountDecimal = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const percentage = this.calculateDiscountFromDecimal(
      value,
      requisition["subtotal"]
    );
    requisition["discount"] = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    requisition["discountPercentage"] = !isNaN(percentage.toFixed(2))
      ? Math.floor(percentage).toFixed(2)
      : 0;
    requisition["amount"] = this.getSubtotal(this.state.tableSupplies);
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState({ ...requisition });
  };

  handleChangeRetentionPercentage = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const retentionPercentage = this.calculateRetentionFromPercentage(
      value,
      this.getSubtotal(this.state.tableSupplies)
    );
    requisition["retentionPercentage"] = value;
    requisition["retention"] = !isNaN(retentionPercentage.toFixed(2))
      ? retentionPercentage.toFixed(2)
      : 0;
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState({ ...requisition });
  };

  handleChangeRetentionDecimal = (e, { name, value }) => {
    const requisition = this.state.requisition;
    const retention = this.calculateRetentionFromDecimal(
      value,
      this.getSubtotal(this.state.tableSupplies)
    );

    requisition["retention"] = !isNaN(parseFloat(value))
      ? parseFloat(value)
      : 0;

    requisition["retentionPercentage"] = !isNaN(retention.toFixed(2))
      ? retention.toFixed(2)
      : 0;
    requisition["total"] = this.getTotal(this.state.tableSupplies);
    this.setState({ ...requisition });
  };

  calculateDiscountFromPercentage = (discountPercentage, subtotal) => {
    return (
      (parseFloat(discountPercentage).toFixed(2) / 100) *
      parseFloat(subtotal).toFixed(2)
    );
  };

  calculateDiscountFromDecimal = (discount, subtotal) => {
    return (discount / subtotal) * 100;
  };

  calculateRetentionFromPercentage = (discountPercentage, subtotal) => {
    return (parseFloat(discountPercentage) / 100) * parseFloat(subtotal);
  };

  calculateRetentionFromDecimal = (discount, subtotal) => {
    return (parseFloat(discount) / parseFloat(subtotal)) * 100;
  };

  // Get the total of a supply added
  getTotalSupply = (a, b) => {
    if (a && b) {
      return `$${(a * b).toFixed(2)}`;
    } else {
      return 0;
    }
  };

  getTotal = supplies => {
    const subtotal = this.getSubtotal(supplies);
    if (
      this.state.requisition.retention > 0
    ) {
      const iva = (subtotal / 100) * this.state.requisition.iva;
      const retention = (!isNaN(parseFloat(this.state.requisition["retention"]))
        ? parseFloat(this.state.requisition["retention"])
        : 0);
      return subtotal - retention + iva;
    } else {
      return (
        this.getSubtotal(supplies) +
        this.getSubtotal(supplies) * (this.state.requisition.iva / 100)
      );
    }
  };

  getSubtotal = supplies => {
    let subtotal;
    let totals = [];
    supplies.map(({ quantity, purchasePrice }) => {
      const math = parseFloat(quantity) * parseFloat(purchasePrice);
      totals.push(math);
      return totals;
    });
    subtotal =
      totals.reduce((a, b) => a + b, 0) -
      (!isNaN(parseFloat(this.state.requisition["discount"]))
        ? parseFloat(this.state.requisition["discount"])
        : 0);
    return subtotal;
  };

  getImporte = supplies => {
    let subtotal;
    let totals = [];
    supplies.map(({ quantity, purchasePrice }) => {
      const math = parseFloat(quantity) * parseFloat(purchasePrice);
      totals.push(math);
      return totals;
    });
    subtotal = totals.reduce((a, b) => a + b, 0);
    return subtotal.toFixed(2);
  };

  toggleSupplyQuickCreate = () => {
    return <Button onClick={this.supplyQuickCreate}>X</Button>;
  };

  searchVendor = props => {
    const getVendors = QUERIES.getVendors;
    return (
      <Query query={getVendors} fetchPolicy={"network-only"}>
        {({ loading, error, data }) => {
          let options = [];
          if (loading) {
            return <Dropdown text="Cargando..." options={[]} loading />;
          }
          if (error) {
            return <Dropdown text="Error" options={[]} error />;
          }

          data.vendors.map(({ id, commercialName, iva }) => {
            options.push({
              key: id,
              text: commercialName,
              value: { id: id, iva: iva },
              style: { textAlign: "center", color: "#999999" },
            });
            return options;
          });

          return (
            <Dropdown
              fluid
              name="vendorId"
              icon="search"
              wrapSelection={false}
              search
              selection
              onChange={this.handleChangeDropdown}
              vendorquickcreate={this.vendorquickcreate}
              error={this.state.errors.vendorIdError}
              readOnly={this.state.isReadOnly}
              autoComplete="off"
              options={[
                {
                  content: (
                    <p
                      style={{
                        color: "#295896",
                        borderTop: "1px solid #dddddd",
                        textAlign: "center",
                      }}
                    >
                      + Añadir nuevo proveedor
                    </p>
                  ),
                  key: "add",
                  value: "add-new",
                  onClick: this.vendorquickcreate,
                },
                ...options,
              ]}
            />
          );
        }}
      </Query>
    );
  };

  render() {
    const SearchVendor = this.searchVendor;
    const totalIsZero = this.state.supplies.length === 0 ? true : false;

    return (
      <ApolloProvider client={client}>
        {!this.state.quickCreate ? (
          <Segment raised className="Modal">
            <FormHeader
              saveId="save-edit-req-btn"
              cancelId="cancel-edit-req-btn"
              text="Editar requisición"
              onSave={this.save}
              onCancel={this.props.onCancel}
            />
            {this.state.isLoading
              ? <OnLoading loading={this.state.isLoading}/>
              : (
                  <>
                    <Form className="Modal__Form">
                      <Form.Group widths="equal">
                        <Form.Field
                          label="FOLIO DE REQUISICIÓN"
                          name="folio"
                          control={Input}
                          value={this.state.requisition.folio}
                          onChange={this.handleChange}
                          readOnly
                          autoComplete="off"
                          required
                        />
                        <Form.Field
                          label="ELABORÓ"
                          name="applicant"
                          control={Input}
                          value={
                            this.state.requisition.applicant === "undefined"
                              ? ""
                              : this.state.requisition.applicant
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.applicantError}
                          autoComplete="off"
                          required
                          readOnly
                        />
                        <Form.Field
                          label="SOLICITANTE"
                          name="petitioner"
                          control={Input}
                          value={this.state.requisition.petitioner}
                          onChange={this.handleChange}
                          error={this.state.errors.petitionerError}
                          autoComplete="off"
                          required
                        />
                        <Form.Field
                          label="IMPORTE"
                          name="_subtotal"
                          value={this.getImporte(this.state.tableSupplies)}
                          control={CurrencyInput}
                          readOnly
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          label="CLI"
                          name="cli"
                          control={Input}
                          value={
                            this.state.requisition.project
                              ? this.state.requisition.project.cli
                              : ""
                          }
                          width={10}
                          autoComplete="off"
                          readOnly
                          required
                        />
                        <Form.Field
                          label="PROVEEDOR"
                          name="vendorId"
                          control={
                            !this.state.toggles.editVendor ? Input : SearchVendor
                          }
                          icon={!this.state.toggles.editVendor ? "search" : null}
                          onClick={
                            !this.state.toggles.editVendor
                              ? () => {
                                const toggles = this.state.toggles;
                                toggles["editVendor"] = true;
                                this.setState(data => ({ ...data, toggles }));
                              }
                              : null
                          }
                          value={
                            this.state.requisition.vendor
                              ? !this.state.toggles.editVendor
                                ? this.state.requisition.vendor.commercialName
                                : this.state.requisition.vendor.id
                              : ""
                          }
                          style={
                            !this.state.toggles.editVendor ? null : { height: "28px" }
                          }
                          onChange={
                            !this.state.toggles.editVendor
                              ? null
                              : this.handleChangeDropdown
                          }
                          vendorquickcreate={this.vendorquickcreate}
                          error={this.state.errors.vendorIdError}
                          autoComplete="off"
                          width={10}
                          required={
                            this.state.requisition.status === "IS_REQ_REVISION" ||
                              this.state.requisition.status === "IS_REQ_CANCEL"
                              ? false
                              : true
                          }
                        />
                        <Form.Field
                          label="AUTORIZÓ"
                          name="authorizedBy"
                          control={Input}
                          value={
                            this.state.requisition.authorizedBy !== "undefined"
                              ? this.state.requisition.authorizedBy
                              : ""
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.authorizedByError}
                          width={10}
                          readOnly
                          autoComplete="off"
                        />
                        <Form.Field
                          label="DESCUENTO"
                          name="discountPercentage"
                          type="number"
                          min={0}
                          control={Input}
                          placeholder="0%"
                          value={parseFloat(this.state.requisition.discountPercentage)}
                          onChange={this.handleChangeDiscountPercentage}
                          error={this.state.streetError}
                          width={5}
                          autoComplete="off"
                          readOnly={totalIsZero}
                        />
                        <Form.Field
                          label="&nbsp;"
                          name="discount"
                          min={0}
                          control={CurrencyInput}
                          placeholder="$0.00"
                          value={parseFloat(this.state.requisition.discount)}
                          onChange={this.handleChangeDiscountDecimal}
                          error={this.state.numberError}
                          width={5}
                          autoComplete="off"
                          readOnly={totalIsZero}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          user={this.state.session}
                          label="NOMBRE DE PROYECTO"
                          name="siteName"
                          control={SelectProject}
                          value={this.state.requisition.project.id}
                          onChange={this.handleChangeCli}
                          error={this.state.errors.siteNameError}
                          required
                          readOnly={this.state.requisition.status !== "IS_REQ_REVISION"}
                        />
                        <Form.Field
                          label="RESIDENTE"
                          name="resident"
                          control={Input}
                          value={
                            this.state.requisition.project
                              ? this.state.requisition.resident
                              : ""
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.residentError}
                          autoComplete="off"
                          required
                          readOnly
                        />
                        <Form.Field
                          label="REVISÓ"
                          name="revisedBy"
                          control={Input}
                          value={
                            this.state.requisition.revisedBy !== "undefined"
                              ? this.state.requisition.revisedBy
                              : ""
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.revisedByError}
                          readOnly
                          autoComplete="off"
                          required={
                            this.state.requisition.status === "IS_REQ_REVISION" ||
                              this.state.requisition.status === "IS_REQ_CANCEL"
                              ? false
                              : true
                          }
                        />
                        {/**subtotal */}
                        <Form.Field
                          label="SUBTOTAL"
                          name="amount"
                          value={this.getSubtotal(this.state.tableSupplies).toFixed(2)}
                          control={CurrencyInput}
                          readOnly
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          label="RESPONSABLE DEL PROYECTO"
                          name="responsible"
                          control={Input}
                          value={
                            this.state.requisition.project
                              ? this.state.requisition.responsible
                              : ""
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.responsibleError}
                          autoComplete="off"
                          required
                          readOnly
                        />
                        <Form.Field
                          control={Input}
                          label="TIEMPO DE ENTREGA"
                          name="deliveryDate"
                          value={
                            this.state.requisition.deliveryDate !== "undefined"
                              ? this.state.requisition.deliveryDate
                              : ""
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.deliveryDateError}
                          autoComplete="off"
                          required
                        />
                        <Form.Field
                          label="MONEDA"
                          name="currency"
                          control={Select}
                          value={this.state.requisition.currency}
                          onChange={this.handleChangeCurrency}
                          options={this.state.options.currencyOptions}
                          error={this.state.errors.currencyError}
                          autoComplete="off"
                          required={
                            this.state.requisition.status === "IS_REQ_REVISION" ||
                              this.state.requisition.status === "IS_REQ_CANCEL"
                              ? false
                              : true
                          }
                        />
                        <Form.Field
                          label="IVA"
                          name="iva"
                          type="number"
                          min={0}
                          minlength="1"
                          value={this.state.requisition.iva}
                          control={Input}
                          onChange={this.handleIVAChange}
                          autoComplete="new-password"
                          action={{ basic: true, content: "%" }}
                          labelPosition="right-corner"
                          required
                          error={this.state.errors.ivaError}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          label="FECHA DE CREACIÓN"
                          name="wasUpdatedAt"
                          type="text"
                          value={
                            this.state.requisition.wasUpdatedAt
                              ? moment(this.state.requisition.wasUpdatedAt)
                                .add(1, "day")
                                .format("DD/MM/YYYY")
                              : ""
                          }
                          control={Input}
                          onChange={this.handleChange}
                          error={this.state.errors.wasUpdatedAtError}
                          width={10}
                          readOnly
                          autoComplete="off"
                          required
                        />
                        <Can do="revise" on="Requisition">
                          <Form.Field
                            label="ESTATUS"
                            name="status"
                            control={Dropdown}
                            selection
                            onChange={this.handleChangeSelectStatus}
                            value={this.state.requisition.status}
                            options={
                              this.state.options.statusOptionsPurchasesResponsible
                            }
                            error={this.state.errors.statusError}
                            width={10}
                            autoComplete="off"
                          />
                        </Can>
                        <Can do="approve" on="Requisition">
                          <Form.Field
                            label="ESTATUS"
                            name="status"
                            control={Dropdown}
                            selection
                            onChange={this.handleChangeSelectStatus}
                            value={this.state.requisition.status}
                            width={10}
                            options={this.state.options.statusOptionsManagerRevise}
                            error={this.state.errors.statusError}
                            autoComplete="off"
                          />
                        </Can>
                        <Can do="create" on="Requisition">
                          <Form.Field
                            label="ESTATUS"
                            name="status"
                            control={Input}
                            value={
                              this.state.requisition.status === "IS_REQ_REVISION"
                                ? "Revisión"
                                : this.state.requisition.status === "IS_REQ_REVISED"
                                  ? "Revisada"
                                  : this.state.requisition.status === "IS_REQ_CANCEL"
                                    ? "Cancelada"
                                    : this.state.requisition.status === "IS_REQ_APPROVED"
                                      ? "Aprobada"
                                      : ""
                            }
                            width={10}
                            error={this.state.errors.statusError}
                            autoComplete="off"
                            readOnly
                          />
                        </Can>
                        <Form.Field
                          label="CONDICIONES DE PAGO"
                          name="paymentConditions"
                          control={Select}
                          value={this.state.requisition.paymentConditions}
                          onChange={this.handleChangeSelect}
                          options={this.state.options.paymentConditionsOptions}
                          error={this.state.errors.paymentConditionsError}
                          width={10}
                          autoComplete="off"
                          required={
                            this.state.requisition.status === "IS_REQ_REVISION" ||
                              this.state.requisition.status === "IS_REQ_CANCEL"
                              ? false
                              : true
                          }
                        />
                        {/* retencion */}
                        <Form.Field
                          label="RETENCIÓN"
                          name="retention"
                          min={0}
                          control={CurrencyInput}
                          placeholder="0.00%"
                          value={parseFloat(this.state.requisition.retentionPercentage)}
                          onChange={this.handleChangeRetentionPercentage}
                          error={this.state.streetError}
                          width={5}
                          autoComplete="off"
                          readOnly={totalIsZero}
                        />
                        <Form.Field
                          label="&nbsp;"
                          name="retentionDecimal"
                          type="number"
                          min={0}
                          control={Input}
                          placeholder="$0.00"
                          value={parseFloat(this.state.requisition.retention)}
                          onChange={this.handleChangeRetentionDecimal}
                          error={this.state.numberError}
                          width={5}
                          autoComplete="off"
                          readOnly={totalIsZero}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Field
                          label="ALMACÉN"
                          width={10}
                          control={SelectWarehouse}
                          name="deliveryWarehouse"
                          value={this.state.requisition.deliveryWarehouse}
                          onChange={this.handleChangeWarehouse}
                          error={this.state.errors.deliveryWarehouseError}
                          autoComplete="off"
                          required={
                            this.state.requisition.status === "IS_REQ_REVISION" ||
                              this.state.requisition.status === "IS_REQ_CANCEL"
                              ? false
                              : true
                          }
                        />
                        <Form.Field width={10} />
                        <Form.Field width={10} />
                        <Form.Field
                          label="TOTAL"
                          min={0}
                          name="total"
                          value={
                            parseFloat(this.state.requisition.total) > 0
                              ? parseFloat(this.state.requisition.total).toFixed(2)
                              : () => {
                                const requisition = this.state.requisition;
                                requisition["total"] = 0;
                                this.setState({ ...requisition });
                              }
                          }
                          control={CurrencyInput}
                          onChange={this.handleChange}
                          width={10}
                          readOnly
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                      <Form.Field
                        label="UBICACIÓN DEL PROYECTO"
                        name="address"
                        onChange={this.handleChange}
                        value={
                          this.state.requisition.address &&
                            this.state.requisition.address !== ""
                            ? this.state.requisition.address
                            : ""
                        }
                        control={TextArea}
                        error={this.state.errors.notesError}
                        autoComplete="new-password"
                        readOnly
                      />
                      <Form.Field
                        label="NOTAS"
                        name="notes"
                        control={TextArea}
                        value={
                          this.state.requisition.notes === "undefined"
                            ? ""
                            : unescape(this.state.requisition.notes)
                        }
                        onChange={this.handleChange}
                        error={this.state.errors.notesError}
                        autoComplete="off"
                      />
                    </Form>
                    <Header as="h3" dividing className="Modal__Form-Header">
                      Insumos
                    </Header>
                    <Table
                      basic="very"
                      compact="very"
                      textAlign="center"
                      className="Modal__Form-Table"
                    >
                      <Table.Header className="Modal__Form-Table-Header">
                        <Table.Row>
                          <Table.HeaderCell>Clave del insumo</Table.HeaderCell>
                          <Table.HeaderCell>Nombre</Table.HeaderCell>
                          <Table.HeaderCell>Descripción</Table.HeaderCell>
                          <Table.HeaderCell>Unidad de medida</Table.HeaderCell>
                          <Table.HeaderCell>Precio unitario</Table.HeaderCell>
                          <Table.HeaderCell>Cantidad</Table.HeaderCell>
                          <Table.HeaderCell>Total</Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body className="Modal__Form-Table-Body">
                        {this.state.tableSupplies.map((supply, index) => {
                          const {
                            insID,
                            name,
                            description,
                            quantity,
                            units,
                            purchasePrice,
                          } = supply;
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{insID}</Table.Cell>
                              <Table.Cell>{unescape(name)}</Table.Cell>
                              <Table.Cell
                                style={{
                                  maxWidth: "300px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {unescape(description)}
                              </Table.Cell>
                              <Table.Cell>{units}</Table.Cell>
                              <Table.Cell>
                                $
                                {parseFloat(purchasePrice)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Table.Cell>
                              <Table.Cell>{quantity}</Table.Cell>
                              <Table.Cell>
                                {this.getTotalSupply(quantity, purchasePrice)
                                  .toString()
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Table.Cell>
                              <Table.Cell>
                                <Button.Group basic>
                                  <Button
                                    icon
                                    onClick={() => this.openUpdateSupply(index)}
                                  >
                                    <IoMdCreate />
                                  </Button>
                                  <Button icon onClick={() => this.deleteSupply(index)}>
                                    <IoIosTrash />
                                  </Button>
                                </Button.Group>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                    <Container textAlign="center">
                    <Modal
                      className="Modal__Form-SubModal"
                      centered={false}
                      trigger={
                        <Button
                          onClick={this.openAddSupply}
                          className="Modal__Form-ButtonAdd"
                          icon="plus"
                          content="Añadir insumo"
                          size="large"
                        />
                      }
                      open={this.state.modals.addSupplyModal}
                      header={
                        <FormHeader
                          saveId="save-edit-req-supply-btn"
                          cancelId="cancel-edit-req-supply-btn"
                          text="Añadir Insumo"
                          onCancel={this.closeAddSupply}
                          onSave={
                            this.state.toggles.supplyQuickCreateToggle
                              ? this.saveSupply
                              : this.state.isCreatingSupply
                                ? null
                                : this.saveSupplyQuickCreate
                          }
                        />
                      }
                      content={
                        <PurchasesSupplies
                          toggleSupplyQuickCreate={this.toggleSupplyQuickCreate}
                          data={this.state}
                          SearchSupply={SearchSupply}
                          supplyKeyChange={this.supplyKeyChange}
                          handleChangeSupply={this.handleChangeSupply}
                          handleSearchChange={this.handleSearchChange}
                          supplyQuickCreate={this.supplyQuickCreate}
                          getTotalSupply={this.getTotalSupply}
                        />
                      }
                      size="tiny"
                      dimmer="blurring"
                    />
                    <Modal
                      className="Modal__Form-SubModal"
                      centered={false}
                      open={this.state.modals.updateSupplyModal}
                      onClose={this.handleCloseModal}
                      header={
                        <FormHeader
                          saveId="save-edit-supply-to-req-btn"
                          cancelId="cancel-edit-supply-to-req-btn"
                          text="Actualizar insumo"
                          onCancel={this.closeUpdateSupply}
                          onSave={this.updateSupply}
                        />
                      }
                      content={
                        <PurchasesSupplies
                          toggleSupplyQuickCreate={this.toggleSupplyQuickCreate}
                          data={this.state}
                          SearchSupply={SearchSupply}
                          supplyKeyChange={this.supplyKeyChange}
                          handleChangeSupply={this.handleChangeSupply}
                          handleSearchChange={this.handleSearchChange}
                          supplyQuickCreate={this.supplyQuickCreate}
                          getTotalSupply={this.getTotalSupply}
                          isEdit
                        />
                      }
                      size="tiny"
                      dimmer="blurring"
                    />
                  </Container>
                  </>
                )
            }
          </Segment>
        ) : (
            <CreateVendor
              onCancel={this.vendorquickcreate}
              toggleSave={this.vendorquickcreate}
              isQuickCreate
            />
          )}
      </ApolloProvider>
    );
  }
}
