import React from "react";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { TabPanel } from "../../Atoms";
import TabContainer from "../TabContainer";
import ContractOpenDocuments from "../ContractOpenDocuments";
import ContractMonthlyDocuments from "../ContractMonthlyDocuments";
import ContractCloseDocuments from "../ContractCloseDocuments";
import { bugsnagClient } from "../../../bugsnag";
import { GET_DOCUMENTS_CONTRACTOR_ID } from "../../../graphql/queries/contractors";
import { DELETE_DOCUMENT_CONTRACTOR, UPDATE_DOCUMENTS_CONTRACTOR, DISAPPROVE_DOCUMENT_CONTRACTOR } from "../../../graphql/mutations/contractors";
import { client } from "../../../graphql/apollo-config";
import { percentage } from "../../../helpers"
import { MessageNotifyDocuments } from "../../Organisms";
import {
  format,
  openDocumentsContractor,
  monthDocumentsContractor,
  closeDocumentsContractor,
  listMonthlyDocumentsContractor,
  limitDateDocuments,
  contractWithNewDocuments
} from "../../../helpers";
import moment from "moment";
import 'moment/locale/es';
moment.locale('es');

class ContractContractorDocuments extends React.Component {
  state = {
    loading: true,
    contract: null,
    openAndCloseDocuments: openDocumentsContractor.length,
    montlhyDocuments: listMonthlyDocumentsContractor.length
  }

  componentDidMount() {
    this.fetchDocuments();
  }

  fetchDocuments = async () => {
    try {
      const { data: { contract }} = await client.query({ query: GET_DOCUMENTS_CONTRACTOR_ID, variables: {id: this.props.id }, fetchPolicy: "network-only"})

      /* Init Approach support document old contract */
      const { startDate } = contract

      if (startDate) {
        const permitDate = moment(limitDateDocuments)
        const initDate = moment(startDate)

        if (initDate < permitDate) {
          if (!contractWithNewDocuments.includes(this.props.id)) {
            return this.setState({
              contract,
              openAndCloseDocuments: 1,
              montlhyDocuments: 9
            });
          }
        }
      }
      /* Fin Approach support document old contract */

      return this.setState({ contract });
    } catch (e) {
      bugsnagClient.notify(e.message);
    }
  }

  getDocumentByType = (contract, type) => {
    const findedDocuments = contract.documentsContractor.filter(item => type.indexOf(item.type) !== -1);
    return findedDocuments.length > 0 ? findedDocuments : null;
  }

  saveContractDocument =  async (documents) => {
    const { contract } = this.state;
    const newDocuments = documents.filter(document => !document.hasOwnProperty('id'));
    const percentageDocuments = percentage.savePercentage(contract, newDocuments);

    try {
      const { data: { updateContract, updateContract: { documentsContractor }}} = await UPDATE_DOCUMENTS_CONTRACTOR(contract.id, contract.contractor, newDocuments, percentageDocuments, GET_DOCUMENTS_CONTRACTOR_ID);
      this.setState({
        contract: {
          ...updateContract,
          documentsContractor: documentsContractor
        }
      })
    } catch (e) {
      bugsnagClient.notify(e.message);
    }
  }

  deleteContractDocument = async (idDocument, documentType) => {
    try {
      const { contract, contract: { id: idContract } } = this.state;
      const percentageDocuments = percentage.deletePercentage(documentType, contract);
      const { data: { updateContract, updateContract: { documentsContractor }}} = await DELETE_DOCUMENT_CONTRACTOR(idContract, idDocument, percentageDocuments);
      this.setState({
        contract: {
          ...updateContract,
          documentsContractor: documentsContractor
        }
      })
    } catch (error) {
      bugsnagClient.notify(error.message);
    }
  }

  rejectDocumentContractor = async (idDocument) => {
    try {
      const { contract: { id: idContract } } = this.state;
      const { data: { updateContract, updateContract: { documentsContractor }}} = await DISAPPROVE_DOCUMENT_CONTRACTOR(idContract, idDocument);
      this.setState({
        contract: {
          ...updateContract,
          documentsContractor: documentsContractor
        }
      })
    } catch (error) {
      bugsnagClient.notify(error.message);
    }
  }

  getApprovalDocument = (documents, typeDocument) => {
    // Null o Vacio
    if (!documents || documents.length === 0) return false;

    const { openAndCloseDocuments, montlhyDocuments } = this.state

    if (typeDocument === "ALTA_DEL_IMSS") {
      const open = documents.filter(document => {
        return (openAndCloseDocuments === 1)
          ? (document.status === "APPROVED" && document.type === typeDocument)
          : (document.status === "APPROVED")
      })
      return open.length === openAndCloseDocuments
    } else {
      const { contract: { startDate, realFinishDate} } = this.state;

      const rangeMonths = format.howDocumentMonthly(startDate, realFinishDate);
      const filterDocumentMonthly = documents.filter(document => document.status === "APPROVED");
      return filterDocumentMonthly.length >= (rangeMonths * montlhyDocuments)
    }
  }

  render() {
    const { loading, contract, openAndCloseDocuments, montlhyDocuments } = this.state;
    const { readOnly } = this.props;

    if (loading && contract === null) {
      return (
        <Container fluid className="Wrapper__Body-Loader">
          <Dimmer active inverted >
            <Loader inverted content='Cargando...' />
          </Dimmer>
        </Container>
      )
    }

    /* Validación de inicio de contrato que permite subir documentos */
    const dateNow = moment();
    let title = undefined, newStartDate = undefined, humanStartDate = undefined, isBajaImss = undefined;
    let titleMonthly = undefined, isMonthlyDocument = undefined;

    if (contract.startDateSiroc === null) {
      title = 'El contrato aún no tiene registrado la fecha del alta del SIROC';
    } else {
      /* Obtenemos la fecha de inicio del contrato mandado por la fecha SIROC */
      newStartDate = moment(contract.startDateSiroc, "YYYY-MM-DD");
      humanStartDate = newStartDate.format("LL");
      title = `El contrato inicia el ${humanStartDate}`;
    }

    /* Obtenemos el porcentaje general de los documentos cargados actualmente */
    const percentageTotal = +((contract.openPercentage + contract.monthPercentage + contract.closePercentage) / 3).toFixed(2);

    /*
      Validación de la carga consecutivamente de los documentos
    */

    // Verificar el documento de alta que este cargado y aprovado
    const documentsOpen = this.getDocumentByType(contract, openDocumentsContractor);
    const OpenDocumentContract = this.getApprovalDocument(documentsOpen, "ALTA_DEL_IMSS");

    // Si esta cargado el documento de alta, verificamos el inicio de carga del primer mes
    if (OpenDocumentContract) {
      const startDateContract = moment(contract.startDate, "YYYY-MM-DD").format("YYYYMM");
      const today = dateNow.format("YYYYMM");
      isMonthlyDocument = +startDateContract <= +today;

      if (!isMonthlyDocument) {
        const humanUploadDateMonth = moment(contract.startDate).format("LL");
        titleMonthly = `No puedes cargar documentos mensuales, hasta el ${humanUploadDateMonth}.`;
        isMonthlyDocument = false;
      } else {
        isMonthlyDocument = true;
      }
    } else {
      titleMonthly = "No puedes cargar documentos mensuales, el documento de ALTA IMSS no ha sido cargado ó aprobado.";
      isMonthlyDocument = false;
    }

    /* Validamos la carga del baja del imss,
      deben de cumplir con los documentos posteriores
      y la fecha actual sea mayor a la de la baja siroc
    */

    // Verificar si estan cargados y aprovados todos los documentos mensuales para poder cargar la baja del IMSS
    const documentsMonthly = this.getDocumentByType(contract, monthDocumentsContractor);
    const MonthlyDocumentContract = this.getApprovalDocument(documentsMonthly);

    if (contract.finishDateSiroc === null) {
      isBajaImss = false;
    } else {
      const newFinishDateSiroc = moment(contract.finishDateSiroc, "YYYY-MM-DD");
      isBajaImss = newFinishDateSiroc.diff(dateNow, "days", true) <= 0;
    }

    /* Render Principal */
    if (contract.startDateSiroc === null || dateNow.diff(newStartDate, "days", true) < 0) {
      return (
        <MessageNotifyDocuments
          title={title}
          subtitle={"No se pueden cargar documentos, si el contrato aún no ha iniciado."}
        />
      )
    } else {
      return (
        <TabContainer active="open">
          <TabPanel name="open" text="Documentos de alta">
            <ContractOpenDocuments
              openAndCloseDocuments={openAndCloseDocuments}
              documents={documentsOpen}
              saveContractDocument={this.saveContractDocument}
              deleteContractDocument={this.deleteContractDocument}
              rejectDocumentContractor={this.rejectDocumentContractor}
              percentageTotal={percentageTotal <= 0.99 ? 0 : percentageTotal}
              readOnly={readOnly}
            />
          </TabPanel>
          <TabPanel name="monthly" text="Documentos mensuales">
            {isMonthlyDocument ? (
              <ContractMonthlyDocuments
                montlhyDocuments={montlhyDocuments}
                documents={documentsMonthly}
                deleteContractDocument={this.deleteContractDocument}
                saveContractDocument={this.saveContractDocument}
                startDate={contract.startDate}
                finishDate={contract.realFinishDate || contract.finishDate}
                rejectDocumentContractor={this.rejectDocumentContractor}
                readOnly={readOnly}
              />
              ):(
                <MessageNotifyDocuments
                  title={"Documentos mensuales"}
                  subtitle={titleMonthly}
                />
              )
            }
          </TabPanel>
          <TabPanel name="close" text="Documentos de cierre">
            {OpenDocumentContract && MonthlyDocumentContract && isBajaImss ? (
              <ContractCloseDocuments
                openAndCloseDocuments={openAndCloseDocuments}
                documents={this.getDocumentByType(contract, closeDocumentsContractor)}
                saveContractDocument={this.saveContractDocument}
                deleteContractDocument={this.deleteContractDocument}
                readOnly={readOnly}
                rejectDocumentContractor={this.rejectDocumentContractor}
              />
              ):(
                <MessageNotifyDocuments
                  title={"Documento de cierre"}
                  subtitle={"No puedes cargar la baja del IMSS, tienes que completar el 100% de los documentos mensuales y que la fecha baja SIROC allá sido asignada."}
                />
              )
            }
          </TabPanel>
        </TabContainer>
      )
    }

  }
}

export default ContractContractorDocuments;
