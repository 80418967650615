import gql from "graphql-tag";

const GET_DOCUMENTS_CONTRACTOR_ID = gql`
  query($id: ID){
    contract (where: {id: $id}) {
      id
      startDate
      finishDate
      realFinishDate
      startDateSiroc
      finishDateSiroc
      openPercentage
      monthPercentage
      closePercentage
      contractor {
        id
        name
      }
      documentsContractor (
        where: {
          type_in: [
            ALTA_DEL_IMSS,
            RELACION_MENSUAL_DE_TRABAJADORES,
            REPORTE_BIMESTRAL,
            RESUMEN_SUA,
            PAGO_SUA,
            BAJA_DEL_IMSS,
            RESUMEN_SIROC_INICIAL,
            RESUMEN_SIROC_FINAL,
            INDENTIFICACION_PATRONAL,
            OPINION_CUMPLIMIENTO,
            ARCHIVO_SUA,
            NOMINAS,
            ISR_SALARIOS,
            ISR_DECLARACION,
            ISR_RECIBO,
            IVA_DECLARACION,
            IVA_ACUSE,
            IVA_PAGO,
            ISR_SALARIOS_SUBSIDIO,
            ICSOE,
            SISUB,
            SITUACION_FISCAL,
          ]
        }
      ) {
        id
        description
        type
        dateElaborated
        status
        refUpload {
          id
          type
          reference
          extension
          wildcard
        }
      }
    }
  }
`

export default GET_DOCUMENTS_CONTRACTOR_ID;
