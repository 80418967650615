import React from "react";
import SidebarItem from "./sidebarITem";
import { IoMdCart, IoIosFiling, IoIosArchive, IoIosAlbums } from "react-icons/io";

export default props => {
  return (
    <React.Fragment>
      <SidebarItem
        {...props}
        name="purchases"
        text="Compras"
        to="/compras"
        icon={<IoMdCart />}
      />
      <SidebarItem
        {...props}
        name="catalogs"
        text="Catálogos"
        to="/catalogos"
        icon={<IoIosFiling />}
      />
      <SidebarItem
        {...props}
        name="contractors"
        text="Contratistas"
        to="/contratistas"
        icon={<IoIosAlbums />}
      />
      <SidebarItem
        {...props}
        text="Inventarios"
        name="inventories"
        to="/inventarios"
        icon={<IoIosArchive />}
      />
    </React.Fragment>
  );
};
