import { client } from "../../apollo-config";
const gql = require("graphql-tag");
const createRequisition = async ({
  folio,
  wasUpdatedAt,
  applicant,
  petitioner,
  status,
  revisedBy,
  authorizedBy,
  projectId,
  vendorId,
  deliveryWarehouse,
  deliveryDate,
  paymentConditions,
  currency,
  notes,
  subtotal,
  iva,
  total,
  suppliesQty,
  retention,
  discount,
  retentionPercentage,
  discountPercentage,
  amount,
  supplies,
  elaboratedEvent,
}) => {
  const mutation = await client.mutate({
    mutation: vendorId
      ? gql`
      mutation {
        createRequisition(
            data:{
            folio:"null"
            wasUpdatedAt:"${wasUpdatedAt}"
            applicant:"${applicant}"
            status:${status}
            revisedBy:"${revisedBy}"
            authorizedBy:"${authorizedBy}"
            project:{
                connect:{
                    id:"${projectId}"
                }
            }
            vendor:{
                connect:{
                    id:"${vendorId}"
                }
            }
            elaboratedEvent:{
              create:{
                name:"${elaboratedEvent.name}"
                date:"${elaboratedEvent.date}"
                type:${elaboratedEvent.type}
              }
            }
            deliveryWarehouse:"${deliveryWarehouse}"
            deliveryDate: "${deliveryDate}"
            paymentConditions:${paymentConditions}
            currency:${currency}
            notes:"${notes}"
            subtotal:${subtotal}
            retentionPercentage:${
              retentionPercentage ? retentionPercentage : 0.0
            }
            discountPercentage:${discountPercentage ? discountPercentage : 0.0}
            amount:${amount}
            iva:${iva}
            retention:${retention ? retention : 0.0}
            discount:${discount && !isNaN(discount) ? discount : 0.0}
            total:${total}
            isReq:true
            supplies:{
              create:[${supplies.map(supply => {
                supply["purchasePrice"] = parseFloat(supply["purchasePrice"]);
                supply["quantity"] = parseFloat(supply["quantity"]);
                const str = JSON.stringify(supply);
                var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                return obj;
              })}]
            }
            petitioner:"${petitioner}"
          }
        ){
            id
        }
     }
    `
      : gql`
      mutation {
        createRequisition(
            data:{
            folio:"null"
            wasUpdatedAt:"${wasUpdatedAt}"
            applicant:"${applicant}"
            petitioner:"${petitioner}"
            status:${status}
            revisedBy:"${revisedBy}"
            authorizedBy:"${authorizedBy}"
            project:{
                connect:{
                    id:"${projectId}"
                }
            }
            elaboratedEvent:{
              create:{
                name:"${elaboratedEvent.name}"
                date:"${elaboratedEvent.date}"
                type:${elaboratedEvent.type}
              }
            }
            deliveryWarehouse:"${deliveryWarehouse}"
            deliveryDate: "${deliveryDate}"
            paymentConditions:${paymentConditions}
            retentionPercentage:${
              retentionPercentage ? retentionPercentage : 0.0
            }
            discountPercentage:${discountPercentage ? discountPercentage : 0.0}
            amount:${amount}
            currency:${currency}
            notes:"${notes}"
            subtotal:${subtotal}
            iva:${iva}
            retention:${retention ? retention : 0.0}
            discount:${discount && !isNaN(discount) ? discount : 0.0}
            total:${total}
            isReq:true

            supplies:{
              create:[${supplies.map(supply => {
                supply["purchasePrice"] = parseFloat(supply["purchasePrice"]);
                supply["quantity"] = parseFloat(supply["quantity"]);
                const str = JSON.stringify(supply);
                var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                return obj;
              })}]
            }
          }
        ){
            id
        }
     }
    `,
  });
  return mutation;
};
export default createRequisition;
