export function staff({ userRole }) {
  const staffRoles = [
    "TECHNICIAN",
    "STAFF",
    "CONTRACTOR",
    "CONTRACTOR_RESPONSIBLE",
  ]

  return staffRoles.indexOf(userRole) !== -1
}
