import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { Logo } from "../../Atoms";
import { IoIosAlbums } from "react-icons/io";
import { Link } from "react-router-dom";
import {
  AccountBalance,
  Manager,
  PR,
  Technician,
  Contractor,
  WR,
  AdministrativeCoordinator,
  ZoneCoordinator,
  Administrator
} from "./components";

const styles = {
  activeSidebarColor: { color: "#b3363e" },
  activeSidebarBorder: {
    borderLeft: "3px solid #b3363e",
  },
  inactiveSidebarColor: { color: "#dddddd" },
};

function SidebarItem(props) {
  let { activeTab, name, icon, to } = props;

  let modifiedIcon = React.cloneElement(icon, {
    style:
      activeTab === name
        ? styles.activeSidebarColor
        : styles.inactiveSidebarColor,
  });

  return (
    <Grid.Row
      style={activeTab === name ? styles.activeSidebarBorder : undefined}
      centered
    >
      <Link to={to}>{modifiedIcon}</Link>
    </Grid.Row>
  );
}

function Logout() {
  window.localStorage.removeItem("session");
  window.location = "/auth/login";
}

const BarItem = props => {
  const { rol } = props;
  const ITEM = {
    ADMINISTRATOR: <Administrator {...props} />,
    MANAGER: <Manager {...props} />,
    PURCHASES_RESPONSIBLE: <PR {...props} />,
    WAREHOUSE_RESPONSIBLE: <WR {...props} />,
    TECHNICIAN: <Technician {...props} />,
    CONTRACTOR_RESPONSIBLE: <Contractor {...props} />,
    ACCOUNT_BALANCE_RESPONSIBLE: <AccountBalance {...props} />,
    ADMINISTRATIVE_COORDINATOR: <AdministrativeCoordinator {...props} />,
    ZONE_COORDINATOR: <ZoneCoordinator {...props} />
  };
  return ITEM[rol];
};

class DashboardLayout extends React.Component {
  state = {
    user: JSON.parse(window.localStorage.getItem("session"))
      ? JSON.parse(window.localStorage.getItem("session")).user
      : "",
  };

  render() {
    const {
      user: { userRol, name },
    } = this.state;

    return (
      <Grid className="Wrapper">
        <Grid.Row className="Wrapper__Row">
          <Grid.Column width={1} className="Wrapper__SideBar">
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Logo />
                </Grid.Column>
              </Grid.Row>

              {userRol === "CONTRACTOR" ? (
                <SidebarItem
                  {...this.props}
                  name="contractors"
                  to="/contratistas"
                  icon={<IoIosAlbums />}
                  text="Contratistas"
                />
              ) : (
                  <BarItem {...this.props} rol={userRol} />
                )}
            </Grid>
          </Grid.Column>

          <Grid.Column width={15} className="Wrapper__Body">
            <Grid>
              <Grid.Row className="Wrapper__TopBar">
                <Grid.Column floated="left" />
                <Grid.Column floated="left" />
                <Grid.Column width={10} floated="right" textAlign="right">
                  {name}&nbsp;
                  <Icon
                    onClick={() => Logout()}
                    name="sign-out"
                    style={{ margin: "12px" }}
                  />
                </Grid.Column>
              </Grid.Row>
              {this.props.children}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default DashboardLayout;
