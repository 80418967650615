import React from "react";
import { Form, Header, Container, Dimmer, Loader } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import moment from "moment";
import CurrencyInput from 'react-currency-input'
import { helper } from "../../../helpers";
import { GET_CLIENTS } from "../../../graphql/queries/accountBalance";
import { client } from "../../../graphql/apollo-config";

class ContractFormAccountBalance extends React.Component {
  state = {
    loading: true,
    clients: [],
    projects: []
  }

  componentWillMount() {
    this.props.currentTab(1);
  }

  componentDidMount() {
    this.getClients();
  }

  getClients = async () => {
    const { data: { clients } } = await client.query({ query: GET_CLIENTS });
    this.setState({ clients, loading: false });
  }

  handleGetProjects = (event, data) => {
    const { value, options } = data;
    const client = options.find(client => client.id === value);
    this.props.handleOnChange(null, { name: "client", value: client });
    if (client.projects.length === 0)
      this.props.handleOnChange(null, { name: "project", value: { id: "", cli: "", key: "" }, options: [] });
    this.setState({ projects: client.projects });
  }

  render() {
    const { loading, clients, projects } = this.state;
    const { readOnly, readOnlyByDocuments, data, handleOnChange, errors, isOriginalContract } = this.props;
    const selfProjects = projects.length === 0 ? [data.project] : projects;

    if (loading) {
      return (
        <Container fluid className="Wrapper__Body-Loader">
          <Dimmer active inverted >
            <Loader inverted content='Cargando...' />
          </Dimmer>
        </Container>
      )
    }

    return(
      <Form className="Modal__Form">
        <Form.Group widths={16}>
          <Form.Input
            required
            readOnly
            width="4"
            label="ID"
            name="IDFolio"
            error={errors.IDFolio}
            value={data.IDFolio}
          />
          <Form.Select
            required
            width="4"
            label="CLIENTE"
            disabled={!isOriginalContract || readOnly || readOnlyByDocuments}
            placeholder="Seleccionar"
            name="client"
            error={errors.client}
            options={clients}
            value={data.client.id}
            onChange={this.handleGetProjects}
          />
        </Form.Group>
        <Form.Group widths={16}>
          <Form.Select
            required
            width="4"
            label="NOMBRE DEL PROYECTO"
            disabled={!isOriginalContract || readOnly || readOnlyByDocuments}
            placeholder="Seleccionar"
            name="project"
            error={errors.project}
            options={selfProjects}
            value={data.project.id}
            onChange={handleOnChange}
          />
          <Form.Input
            required
            readOnly
            width="4"
            label="NUM PRESUPUESTO CLI"
            name="cli"
            error={errors.project}
            value={data.project.cli}
          />
          <Form.Select
            required
            width="4"
            label="ESTATUS"
            disabled={readOnly || readOnlyByDocuments}
            placeholder="Seleccionar"
            name="status"
            error={errors.status}
            options={helper.status()}
            value={data.status}
            onChange={handleOnChange}
          />
        </Form.Group>
        <Header as="h3" dividing className="Modal__Form-Header">
          Datos adicionales
        </Header>
        <Form.Group widths={16}>
          <Form.Select
            required
            width="4"
            label="MONEDA"
            disabled={readOnly || readOnlyByDocuments}
            placeholder="Seleccionar"
            name="currency"
            error={errors.currency}
            options={helper.currencies()}
            value={data.currency}
            onChange={handleOnChange}
          />
          <Form.Field required error={errors.amountContract} disabled={readOnly || readOnlyByDocuments} width="4">
            <label>TOTAL DEL CONTRATO</label>
            <CurrencyInput
              value={data.amountContract}
              onChangeEvent={(event, maskedvalue, floatvalue) => handleOnChange(null, { name: "amountContract", value: floatvalue })}/>
          </Form.Field>
        </Form.Group>
        <Form.Group widths={16}>
          <Form.Input
            required
            width="4"
            type="number"
            label="% DE AMORTIZACIÓN ANTICIPO"
            readOnly={readOnly || readOnlyByDocuments}
            placeholder=""
            name="percentAmortization"
            error={errors.percentAmortization}
            value={data.percentAmortization}
            onChange={handleOnChange}
          />
          <Form.Input
            required
            width="4"
            type="number"
            label="% DE FONDO DE GARANTÍA"
            readOnly={readOnly || readOnlyByDocuments}
            placeholder=""
            name="percentGuaranteeFund"
            error={errors.percentGuaranteeFund}
            value={data.percentGuaranteeFund}
            onChange={handleOnChange}
          />
          <Form.Field className='Util-DatePicker' width="4">
            <SemanticDatepicker
              required
              name="startDate"
              label="FECHA DE INICIO"
              placeholder={!data.startDate ? "dd/mm/aaaa" : moment(data.startDate).add(1, 'day').format("DD/MM/YYYY")}
              type="basic"
              format="DD/MM/YYYY"
              onDateChange={
                (date) => handleOnChange(null, { name: 'startDate', value: (date !== null && moment(date).format('YYYY-MM-DD')) })
              }
              error={errors.startDate}
              readOnly={true}
              disabled={readOnly || readOnlyByDocuments}
            />
          </Form.Field>
          <Form.Field className='Util-DatePicker' width="4">
            <SemanticDatepicker
              required
              name="finishDate"
              label="FECHA DE TÉRMINO"
              placeholder={!data.finishDate ? "dd/mm/aaaa" : moment(data.finishDate).add(1, 'day').format("DD/MM/YYYY")}
              type="basic"
              format="DD/MM/YYYY"
              onDateChange={
                (date) => handleOnChange(null, { name: 'finishDate', value: (date !== null && moment(date).format('YYYY-MM-DD')) })
              }
              error={errors.finishDate}
              readOnly={true}
              disabled={readOnly || readOnlyByDocuments}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={16}>
          <Form.TextArea
            required
            width="16"
            label="DESCRIPCIÓN DE CONCEPTOS A CONTRATAR"
            readOnly={readOnly || readOnlyByDocuments}
            rows="5"
            name="description"
            error={errors.description}
            value={data.description}
            onChange={handleOnChange}
          />
        </Form.Group>
      </Form>
    )
  }
}

export default ContractFormAccountBalance;
