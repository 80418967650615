import React, { useState } from "react";
import DataTable from "../../Organisms/DataTable";
import { climasTemplate } from "../../Organisms/DataTable/climasTemplate";
import { ActionButtons } from "./actionButtons";
import { Button, Confirm, Modal } from "semantic-ui-react";
import { deleteContractor } from "../../../graphql/mutations/contractors";

export default ({
  action,
  filter,
  filterVariables,
  onOrderChange,
  onReload,
  openModal
}) => {
  const INIT_STATE = {
    contractor: null,
    confirmOpen: false,
    contractorName : '',
    contractorID: null,
    modalErrorOpen: false
  }

  const [state, setState] = useState(INIT_STATE)

  const editItem = (data) => {
    openModal("update", { contractor: data });
  }

  const detailItem = (data) => {
    openModal("detail", { contractor: data });
  }

  const handleModalOpen = (contractor) => {
    setState({
      contractor,
      confirmOpen: true,
      contractorName : contractor.name,
      contractorID: contractor.id,
      modalErrorOpen: false
    })
  }

  const handleCloseModal = () => {
    setState(INIT_STATE)
  }

  const handleClose = () => {
    setState(INIT_STATE)
  }

  const onDeleteContractor = async id => {
    const { contractor } = state;
    const hascontract = !!(contractor.contracts || []).length

    if (!hascontract) {
      await deleteContractor(id);
      onReload()
    }

    setState(INIT_STATE)
  }

  const actionButtonsProps = {
    editItem,
    handleModalOpen,
    detailItem
  }

  return (
    <>
      <DataTable
        onOrderChange={onOrderChange}
        onCompleted={action}
        template={climasTemplate}
        query={filter}
        variables={filterVariables}
        itemsKey="contractors">
        <DataTable.Column
          name="name"
          label="Nombre del contratista"
          sortable />
        <DataTable.Column
          name="city"
          label="Ciudad"
          sortable/>
        <DataTable.Column
          name="state"
          label="Estado"
          sortable/>
        <DataTable.Column
          name="rfc"
          label="RFC"
          sortable/>
        <DataTable.Column
          name="actions"
          label=" "
          content={
            (row, column, index) =>
              <ActionButtons
                index={index}
                contractor={row}
                { ...actionButtonsProps } />
          } />
      </DataTable>

      <Modal size="small" open={state.modalErrorOpen}>
        <Modal.Content>
          <p>Primero debes eliminar los contratos asignados al Contratista</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Actions>
      </Modal>

      <Confirm
        open={state.confirmOpen}
        cancelButton="Cancelar"
        confirmButton="Aceptar"
        onCancel={handleCloseModal}
        onConfirm={() => onDeleteContractor(state.contractorID) }
        content={`¿Desea eliminar el registro ${state.contractorName }? Esta acción no es reversible`}/>
    </>
  );
};
