import { ApolloClient, QUERIES, MUTATIONS } from "apollo-service";
import { auth } from "../auth";
require("dotenv");
const token = auth.session() ? JSON.parse(auth.session()).token : {};
/**
 * @note I will keep the localhost here since this issue is blocking a feature
 * that has nothing to do with env vars, instead I will open a pull request
 * to solve this issue only
 */
const apollo = new ApolloClient(
  token,
  process.env.REACT_APP_DB_URL,
  process.env.REACT_APP_SERVER_WS
);

const client = apollo.getClient();
const store = apollo.getState();

export { client, store, QUERIES, MUTATIONS };
