import gql from "graphql-tag";
import { client } from "../../apollo-config";

const updateReqToProject = async (reqID, newProjectId, oldProjectId) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
      discon: updateProject(where: { id: "${oldProjectId}" }, data: {
        requisitions: {
          disconnect: {id: "${reqID}"}
        }
      }) {
        id
      }

      connect: updateProject(where: { id: "${newProjectId}" }, data: {
        requisitions: {
          connect: { id: "${reqID}"}
        }
      }) {
        id
      }
    }
    `,
  });
  return mutation;
};

export default updateReqToProject;
