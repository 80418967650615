import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async ({
  id,
  text,
  start_date,
  duration,
  parent,
  index
}) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateProject(
          where: { id: "${id}" }
          data: {
            tasks: {
              create: {
                text: "${text}"
                index: ${index}
                start_date: "${new Date(start_date).toISOString()}"
                duration: ${duration}
                progress: ${0}
                parent:${parent ? parent : 0}          
                manuallyCreated:true      
              }
            }
          }
        ) {
          id
          tasks{
            parent
          }
        }
      }
    `,
  });
  return mutation;
};
