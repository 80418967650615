import React from "react";
import PropTypes from 'prop-types';
import angle_down from "../../../assets/angle_down.svg";

const SelectInput = props => (
  <div>
    <select      
      name={props.name}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      style={{
        backgroundColor: "#ffffff",
        border: "1px solid #dddddd",
        WebkitAppearance: "none",
        MozAppearance: "none",
        height: "35px",
        background: `url(${angle_down}) no-repeat right`,
        backgroundPositionX: "98%",
        cursor: "pointer"
      }}
    >
      {props.blankOption &&
        <option           
          value={props.blankOptionValue}
          style={{
            WebkitAppearance: "none",
            MozAppearance: "none",
            backgroundColor: "#ffffff"
          }}
        >
        {props.blankOption}
        </option>
      }
      {props.options && props.options.map((item) => (
        <option         
          key={item.value}
          value={item.value}
          style={{
            WebkitAppearance: "none",
            MozAppearance: "none",
            backgroundColor: "#ffffff"
          }}
        >
        {item.text}
        </option>
      ))}      
    </select>
  </div>
);

SelectInput.propTypes = {
  blankOption: PropTypes.string, 
  blankOptionValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired
};

SelectInput.defaultProps = {
  blankOption: null,
  blankOptionValue: "",
  options: [],
};

export default SelectInput;
