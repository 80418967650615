import actions from "../../actions";

const initialState = {
  toggle_vendor: true,
  toggle_supply: true
};

const navigationReducer = (state = initialState, action) => {
  if (action.type === actions.NAVIGATE_TO_CREATE_VENDOR) {
    return {
      ...state,
      toggle_vendor: action.toggle_vendor
    };
  }
  if (action.type === actions.NAVIGATE_TO_CREATE_SUPPLY) {
    return {
      ...state,
      toggle_supply: action.toggle_supply
    };
  }
  if (action.type === actions.GET_ROUTE) {
    return {
      ...state,
      get_route: action.payload
    };
  }
  return state;
};

export default navigationReducer;
