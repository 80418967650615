import React from "react";
import {
  Form,
  Header,
  Grid,
} from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { client } from "../../../graphql/apollo-config";
import { DELETE_DOCUMENT_CONTRACTOR } from "../../../graphql/mutations/contractors";
import gql from "graphql-tag";
import { format, helper } from "../../../helpers";
import { CONTRACT_TYPES } from "../../../helpers/constants";
import moment from "moment";
import CurrencyInput from 'react-currency-input';
import { bugsnagClient } from "../../../bugsnag";
import UploadButton from "./UploadButton";

class ContractForm extends React.Component {
  constructor (props) {
    super(props);

    this.refUploadSiroc = React.createRef(null);
    this.refUploadContract = React.createRef(null);

    this.state = {
      isLoading: false,
      data: {
        projects: [
          {
            key: "",
            text: "",
            value: "",
          },
        ],
        contractors: [
          {
            key: "",
            text: "",
            value: "",
          },
        ],
      },
      refUpload: {
        siroc: null,
        contract: null
      },
      currencyOptions: [
        { key: "mxn", text: "MXN", value: "MXN" },
        { key: "usd", text: "USD", value: "USD" },
      ],
      statusFile: 0, //0.- Upload document/ 1.- Chaged document/ 2.- Uploading document
    };
  }

  async componentDidMount() {
    const data = await this.QUERY_PROJECTS_CONTRACTORS();
    this.setState({ data });
  }

  change = (event, data) => {
    const { name, value, options } = data;
    const cleanValue = helper.cleaningData(value);
    this.props.change(name, cleanValue, options);
  };

  uploadChangeSiroc = (event, data) => {
    const { name, value, options } = data;
    this.props.change(name, value, options);
  }

  QUERY_PROJECTS_CONTRACTORS = async () => {
    const { data } = await client.query({
      query: gql`
        {
          projects(orderBy: name_ASC) {
            id
            name
            key: id
            text: name
            value: id
            cli
            status  
          }
          contractors(orderBy: name_ASC) {
            id
            name
            key: id
            text: name
            value: id
          }
        }
      `,
    });
    return data;
  };

  uploadFile = async (e, params) => {
    const { contract } = this.props;
    const { documentsContractor } = contract || []

    const files = e.target.files;
    const form = new FormData();

    if (files.length > 0) {
      this.setState({ statusFile: 1 });

      form.append("file", files[0]);

      try {
        const res = await fetch(process.env.REACT_APP_S3, {
          method: "POST",
          body: form,
        });

        if (res.ok) {
          const file = await res.json();
          const data = {
            name: "documentsContractor",
            value: [
              {
                type: params.type,
                description: params.description,
                status: "APPROVED",
                refUpload: [
                  {
                    type: "OTHERS",
                    reference: file.imageUrl,
                    extension: format.getExtesion(file.imageUrl),
                  },
                ],
              },
            ],
          };

          if (documentsContractor.length > 0) {
            data.value.push(...documentsContractor)
          }

          this.setState({ statusFile: 0 });
          this.uploadChangeSiroc(null, data);
        }
      } catch (err) {
        bugsnagClient.notify(err);
      }
    }
  };

  deleteFile = async (document = {}) => {
    try {
      const { id, type } = document
      const { contract } = this.props;
      const { documentsContractor } = contract || []
      const updatedDocumentContractor = documentsContractor.filter(document => document.type !== type)

      const data = {
        name: "documentsContractor",
        value: updatedDocumentContractor
      }

      if (id) {
        const percentageDocuments = {
          openPercentage: contract.openPercentage,
          monthPercentage: contract.monthPercentage,
          closePercentage: contract.closePercentage,
        };
        await DELETE_DOCUMENT_CONTRACTOR(
          contract.id,
          id,
          percentageDocuments
        );
      }
      this.uploadChangeSiroc(null, data);
    } catch (error) {
      bugsnagClient.notify(error);
    }
  };

  refUpload = ({ documentsContractor = [] }) => {
    const { siroc, contract } = documentsContractor.reduce((acc, document) => {
      if (document.type === "SIROC") {
        acc.siroc = document
      }

      if (document.type === "CONTRATO") {
        acc.contract = document
      }

      return { ...acc }
    }, { siroc: {}, contract: {} })

    return {
      documents: { siroc, contract },
      refUpload: { siroc: (siroc.refUpload || [])[0], contract: (contract.refUpload || [])[0] }
    };

  };

  render() {
    const { isUpdate, contract, errors, readOnly } = this.props;
    const { statusFile } = this.state
    const { projects, contractors } = this.state.data;
    const { documents, refUpload } = this.refUpload(contract);

    return (
      <Form className="Modal__Form">
        {contract.id && (
          <Header as="h3" dividing className="Modal__Form-Header">
            Estado de cuenta
          </Header>
        )}
        <Grid textAlign="center">
          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <Form.Group widths="equal">
                <Form.Select
                  required
                  placeholder="Seleccionar"
                  label="PROYECTO"
                  name="project"
                  options={projects}
                  defaultValue={contract.project.id}
                  error={errors.project}
                  onChange={this.change}
                  disabled={(readOnly || isUpdate)}
                />
                <Form.Input
                  required
                  label="PRESUPUESTO CLI"
                  placeholder="Seleccionar"
                  value={contract.project.cli}
                  readOnly={(readOnly || isUpdate)}
                  error={errors.project}
                />
                <Form.Select
                  required
                  placeholder="Seleccionar"
                  label="CONTRATISTA"
                  options={contractors}
                  name="contractor"
                  defaultValue={contract.contractor.id}
                  error={errors.contractor}
                  onChange={this.change}
                  disabled={(readOnly || isUpdate)}
                />
                <Form.Select
                  required
                  placeholder="Seleccionar"
                  label="TIPO DE CONTRATO"
                  options={CONTRACT_TYPES}
                  name="contractType"
                  defaultValue={contract.contractType}
                  error={errors.contractType}
                  onChange={this.change}
                  disabled={(readOnly || isUpdate || contract.id)}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <Form.Input
                required
                name="name"
                placeholder="Ingresa el nombre del contrato"
                label="NOMBRE DEL CONTRATO"
                value={contract.name}
                error={errors.name}
                onChange={this.change}
                readOnly={(readOnly || isUpdate)}
                className="Modal__Form-Utils-Input-MT"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column
              width={16}
              textAlign="left"
              style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Header as="h3" style={{ margin: 0 }}>
                Datos del contrato (PDF)
              </Header>
              <UploadButton
                key='contract'
                readOnly={readOnly}
                refUpload={refUpload.contract}
                documents={documents.contract}
                buttonRef={this.refUploadContract}
                uploadFile={(e) =>
                  this.uploadFile(e, { type: "CONTRATO", description: "Documento del contrato" })
                }
                statusFile={statusFile}
                deleteFile={this.deleteFile}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <Form.Group widths="equal">
                <Form.Field required error={errors.total} disabled={readOnly}>
                  <label>MONTO CONTRATADO</label>
                  <CurrencyInput
                    value={contract.total}
                    onChangeEvent={(event, maskedvalue, floatvalue) => this.props.change("total", floatvalue, undefined)}/>
                </Form.Field>
                <Form.Field required error={errors.guaranteeFund} disabled={readOnly}>
                  <label>FONDO DE GARANTÍA</label>
                  <CurrencyInput
                    value={contract.guaranteeFund}
                    onChangeEvent={(event, maskedvalue, floatvalue) => this.props.change("guaranteeFund", floatvalue, undefined)}/>
                </Form.Field>
                <Form.Field className='Util-DatePicker'>
                  <SemanticDatepicker
                    required
                    name="startDate"
                    label="ALTA SIROC"
                    placeholder={!contract.startDate ? "dd/mm/aaaa" : moment(contract.startDate).add(1, 'day').format("DD/MM/YYYY")}
                    type="basic"
                    format="DD/MM/YYYY"
                    error={errors.startDate}
                    onDateChange={
                      (date) => this.props.change('startDate', (date !== null && moment(date).format('YYYY-MM-DD')), undefined)
                    }
                    readOnly={true}
                    disabled={(readOnly || isUpdate)}
                  />
                </Form.Field>
                <Form.Field className='Util-DatePicker'>
                  <SemanticDatepicker
                    required
                    name="finishDate"
                    label="BAJA SIROC"
                    placeholder={!contract.finishDate ? "dd/mm/aaaa" : moment(contract.finishDate).add(1, 'day').format("DD/MM/YYYY")}
                    type="basic"
                    format="DD/MM/YYYY"
                    error={errors.finishDate}
                    onDateChange={
                      (date) => this.props.change('finishDate', (date !== null && moment(date).format('YYYY-MM-DD')), undefined)
                    }
                    readOnly={true}
                    disabled={(readOnly)}
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <Form.TextArea
                required
                label="DESCRIPCIÓN DEL CONCEPTO"
                rows="4"
                name="description"
                value={contract.description}
                error={errors.description}
                onChange={this.change}
                readOnly={(readOnly || isUpdate)}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <Header as="h3" >
                Datos SIROC
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <Form.Group widths="equal">
                <Form.Input
                  name="siroc"
                  label="NÚMERO SIROC"
                  value={contract.siroc}
                  error={errors.siroc}
                  onChange={this.change}
                  readOnly={(readOnly || isUpdate)}
                />
                <Form.Field className='Util-DatePicker'>
                  <SemanticDatepicker
                    name="startDateSiroc"
                    label="FECHA DE INICIO"
                    placeholder={!contract.startDateSiroc ? "dd/mm/aaaa" : moment(contract.startDateSiroc).add(1, 'day').format("DD/MM/YYYY")}
                    type="basic"
                    format="DD/MM/YYYY"
                    onDateChange={
                      (date) => this.props.change('startDateSiroc', (date !== null && moment(date).format('YYYY-MM-DD')), undefined)
                    }
                    readOnly={true}
                    disabled={(readOnly || isUpdate)}
                  />
                </Form.Field>
                <Form.Field className='Util-DatePicker'>
                  <SemanticDatepicker
                    name="finishDateSiroc"
                    label="FECHA DE TERMINO"
                    placeholder={!contract.finishDateSiroc ? "dd/mm/aaaa" : moment(contract.finishDateSiroc).add(1, 'day').format("DD/MM/YYYY")}
                    type="basic"
                    format="DD/MM/YYYY"
                    onDateChange={
                      (date) => this.props.change('finishDateSiroc', (date !== null && moment(date).format('YYYY-MM-DD')), undefined)
                    }
                    readOnly={true}
                    disabled={readOnly}
                  />
                </Form.Field>
                <Form.Field style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                  <UploadButton
                    key='siroc'
                    readOnly={readOnly}
                    refUpload={refUpload.siroc}
                    documents={documents.siroc}
                    buttonRef={this.refUploadSiroc}
                    uploadFile={(e) =>
                      this.uploadFile(e, { type: "SIROC", description: "Acuse de recibo registro de obra de construcción" })
                    }
                    statusFile={statusFile}
                    deleteFile={this.deleteFile}
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <Form.Group widths="equal">
                <Form.Input
                  required
                  placeholder="Calle"
                  label="CALLE"
                  name="street"
                  value={contract.street}
                  error={errors.street}
                  onChange={this.change}
                  readOnly={(readOnly || isUpdate)}
                />
                <Form.Input
                  required
                  placeholder="Número"
                  label="NÚMERO"
                  name="externalNumber"
                  value={contract.externalNumber}
                  error={errors.externalNumber}
                  onChange={this.change}
                  readOnly={(readOnly || isUpdate)}
                />
                <Form.Input
                  required
                  placeholder="Colonia"
                  label="COLONIA"
                  name="colony"
                  value={contract.colony}
                  error={errors.colony}
                  onChange={this.change}
                  readOnly={(readOnly || isUpdate)}
                />
                <Form.Input
                  required
                  placeholder="Ciudad"
                  label="CIUDAD"
                  name="city"
                  value={contract.city}
                  error={errors.city}
                  onChange={this.change}
                  readOnly={(readOnly || isUpdate)}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <Form.Group widths="equal">
                <Form.Input
                  required
                  placeholder="Estado"
                  label="ESTADO"
                  name="state"
                  value={contract.state}
                  error={errors.state}
                  onChange={this.change}
                  readOnly={(readOnly || isUpdate)}
                />
                <Form.Input
                  required
                  placeholder="País"
                  label="PAÍS"
                  name="country"
                  value={contract.country}
                  error={errors.country}
                  onChange={this.change}
                  readOnly={(readOnly || isUpdate)}
                />
                <Form.Input
                  required
                  type="number"
                  placeholder="Código Postal"
                  label="CP"
                  name="postalCode"
                  value={contract.postalCode}
                  error={errors.postalCode}
                  onChange={this.change}
                  readOnly={(readOnly || isUpdate)}
                />
                <Form.Select
                  required
                  placeholder="Seleccionar"
                  options={this.state.currencyOptions}
                  name="currency"
                  defaultValue='MXN'
                  onChange={this.change}
                  value='MXN'
                  hidden
                  style={{ display: 'none' }}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default ContractForm;
