import React from "react";
import EditWarehouseContainer from "./container";
import { Mutation, Query } from "react-apollo";
import updateWarehouse from "../../../graphql/mutations/updateWarehouse";
import getWarehouse from "../../../graphql/queries/catalogs/getWarehouse";
import OnLoading from "../../Molecules/OnLoading";

export default class EditWarehouse extends React.Component {
    state = {
        fields: {
            name: "",
            street: "",
            city: "",
            country: "",
            postalCode: "",
            contactName: "",
            contactNumber: "",
            email: "",
            state: "",
            status: ""
        },
        errors: {
            name: false,
            street: false,
            city: false,
            country: false,
            postalCode: false,
            contactName: false,
            contactNumber: false,
            email: false,
            state: false,
            status: false
        }
    }

    setFields = (warehouse) => {
        let fields = Object.assign({}, this.state.fields);

        fields.name = warehouse.name;
        fields.city = warehouse.city;
        fields.street = warehouse.street;
        fields.country = warehouse.country;
        fields.postalCode = warehouse.postalCode;
        fields.contactName = warehouse.contactName;
        fields.contactNumber = warehouse.contactPhone;
        fields.email = warehouse.contactEmail;
        fields.state = warehouse.state;
        fields.status = warehouse.status;

        this.setState({ fields });
    }

    validate = () => {
        let keys = Object.keys(this.state.fields);
        let errors = Object.assign({}, this.state.errors);
        let hasError = false;
        let fields = this.state.fields;

        keys.forEach(key => {
            if(!fields[key]) {
                errors[key] = true;
                hasError = true;
            }
        })

        return hasError ? errors : false;
    }

    onChange = (e) => {
        let { value, name } = e.target;
        let fields = {...this.state.fields, [name]: value}
        let errors = {...this.state.errors, [name]: false}

        this.setState({ fields, errors });
    }

    onChangeSelect = (e, { value, name }) => {
        let fields = {...this.state.fields, [name]: value}
        let errors = {...this.state.errors, [name]: false}
        this.setState({ fields, errors })
    }

    setErrors = (errors) => this.setState({errors});

    render() {
        let { id } = this.props.match.params

        return (
            <Query variables={{input: {id}}} query={getWarehouse} fetchPolicy="network-only">
                {({loading, error, data }) => {
                    if (loading || error || !data) {
                        return <OnLoading loading={loading} error={error} />
                    }

                    let {warehouse} = data;

                    return (
                        <Mutation mutation={updateWarehouse}>{mutate =>
                            <div className="Modal">
                                <EditWarehouseContainer fields={this.state.fields}
                                    errors={this.state.errors}
                                    onChange={this.onChange}
                                    onChangeSelect={this.onChangeSelect}
                                    mutate={mutate}
                                    setErrors={this.setErrors}
                                    validate={this.validate}
                                    warehouse={warehouse}
                                    setFields={this.setFields}
                                    id={id}
                                    onReload={this.props.onReload}
                                />
                            </div>
                        }</Mutation>
                    );
                }}
            </Query>
        );
    }
}
