import React from "react";
import { Link } from "react-router-dom";
import { Grid, Segment, Form, Icon, Pagination, Label, Dropdown} from "semantic-ui-react";
import { AccountBalanceQuery } from "../../Molecules";
import theme from "../../_theme";

class ListContractAccountBalance extends React.Component {
  state = {
    search: undefined,
    totalRecords: 0,
    activePage: 1,
    first: 20,
    entrieSelected: 20,
    entriesOptions: [
      { key: 1, text: "10", value: 10 },
      { key: 2, text: "20", value: 20 },
    ]
  }

  getCount = (totalRecords, first, activePage) => {
    this.setState({ totalRecords, first, activePage });
  }

  onPageChange = (e, { activePage }) => this.setState({ activePage });

  handleEntries = (e, { value }) => this.setState({ entrieSelected: value, activePage: 1 });

  render() {
    const { activePage, first, totalRecords, entriesOptions, entrieSelected } = this.state;

    return(
      <Segment>
        <Grid columns="equal">
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column>
              <Form className="Wrapper__Body-Form">
                <Form.Input
                  placeholder="Buscar"
                  icon="search"
                  width="6"
                  onChange={(e, { value }) => this.setState({ search: value })}
                  defaultValue={this.state.search}
                />
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Link to="/estado-de-cuenta/new" className="Wrapper__Body-Form-ButtonAdd">
                <Icon name='plus' />
                Agregar nuevo
              </Link>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column>
              <AccountBalanceQuery
                search={this.state.search}
                getCount={this.getCount}
                first={entrieSelected}
                activePage={activePage}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid columns={3} divided="vertically" centered>
              <Grid.Column textAlign="center">
                <Label style={theme.styles.paginationLabel}>Ver</Label>
                <Dropdown
                  id="recordsDropdown"
                  name="recordsDropdown"
                  selection
                  text={`${entrieSelected}`}
                  options={entriesOptions}
                  item
                  onChange={this.handleEntries}
                  compact/>
                <Label style={theme.styles.paginationLabel}>Entradas</Label>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Label style={theme.styles.entriesLabel}>Viendo</Label>
                <Label style={theme.styles.entriesBoldLabel}>{first}</Label>
                <Label style={theme.styles.entriesLabel}>de</Label>
                <Label style={theme.styles.entriesBoldLabel}>{totalRecords}</Label>
                <Label style={theme.styles.entriesLabel}>Entradas</Label>
              </Grid.Column>
              <Grid.Column textAlign="center">
                {totalRecords !== 0 &&
                  <Pagination
                    className='Pagination-Fix'
                    boundaryRange={1}
                    siblingRange={0}
                    size='mini'
                    activePage={activePage}
                    totalPages={Math.ceil(totalRecords / entrieSelected)}
                    onPageChange={this.onPageChange}
                  />
                }
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }
}

export default ListContractAccountBalance;

