import gql from "graphql-tag"

const getContractAccountBalanceByProject = gql `
  query getContractAccountBalanceByProject($id: ID) {
    contractAccountBalances(orderBy: IDFolio_ASC, where: { project: { id: $id}}) {
      key: id
      text: IDFolio
      value: id
      id
      IDFolio
      currency
      amountAmortization
      amountRetained
      amountDeductives
      amountBilled
      amountPaid
      amountContract
      percentAmortization
      percentGuaranteeFund
      startDate
      finishDate
      status
      description
      amountAdvancePayment
      amountByAmortization
      amountByRetained
      amountTotalRetained
      amountContractUpdate
      amountByBilled
      amountByPaid
      project{
        id
        cli
      }
      documentsContractAccountBalance {
        id
        type
        concept
        elaborated {
          id
          name
        }
        dateElaborated
        currency
        amountAmortization
        amountGuaranteeFund
        invoiceNumber
        status
        iva
        amount
        amountIva
        total
        refUpload {
          id
          type
          reference
        }
      }
    }
  }
`;

export default getContractAccountBalanceByProject;