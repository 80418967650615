import React from "react";
import PropTypes from "prop-types";
import { IoMdCreate } from "react-icons/io";

import { Button, Form, Input, Modal } from "semantic-ui-react";
import { styles } from "../../theme";

const Total = ({ supplyPrice, quantity }) => {
  const mul = supplyPrice * quantity;
  if (mul >= 0) {
    return (
      <b>
        TOTAL <br /> ${mul}
        MXN
      </b>
    );
  } else {
    return (
      <b>
        TOTAL <br /> 0 MXN
      </b>
    );
  }
};

class UpdateSupplyModal extends React.Component {
  constructor(props) {
    super(props);
    const {
      supplyKey,
      supplyName,
      supplyDescription,
      quantity,
      supplyUnits,
      supplyPrice,
      total
    } = this.props.data.supply;
    this.state = {
      modalOpen: false,
      supplyKey,
      supplyName,
      supplyDescription,
      quantity,
      supplyUnits,
      supplyPrice,
      total
    };
  }

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  save = index => {
    this.props.onUpdate({ data: this.state, index: index });
    this.setState({ modalOpen: false });
  };
  componentDidMount() {
    const _data = this.props.data;
    this.setState(data => ({ ...data, _data }));
  }

  handleChange = (e, { name, value }) => {
    this.setState(data => ({
      ...data,
      [name]: value
    }));
  };

  componentDidMount() {
    const supply = this.props.data.supply;
    this.setState(data => ({ ...data, supply }));
  }

  render() {
    return (
      <Modal
        dimmer="blurring"
        style={styles.modal_small}
        trigger={
          <Button onClick={this.handleOpen} style={styles.button_action}>
            <IoMdCreate />
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="tiny"
      >
        <Modal.Header
          style={{
            height: "60px",
            width: "100%",
            marginBottom: "20px",
            textAlign: "left"
          }}
        >
          Editar Insumno
        </Modal.Header>
        <Modal.Content style={styles.modal_content}>
          <Modal.Description>
            <Form style={styles.form_group}>
              <Form.Field
                control={Input}
                label="Clave de Insumo"
                name="supplyKey"
                placeholder={this.state.supplyKey}
                onChange={this.handleChange}
                size="mini"
              />
              <Form.Field
                control={Input}
                label="Nombre de Insumo"
                name="supplyName"
                placeholder={this.state.supplyName}
                onChange={this.handleChange}
                size="mini"
              />
              <Form.Field
                control={Input}
                label="Descripción"
                name="supplyDescription"
                placeholder={this.state.supplyDescription}
                onChange={this.handleChange}
                size="mini"
              />
              <Form.Field
                control={Input}
                label="Cantidad"
                type="number"
                name="quantity"
                placeholder={this.state.quantity}
                onChange={this.handleChange}
                size="mini"
              />
              <Form.Field
                control={Input}
                label="Unidad de Medida"
                name="supplyUnits"
                placeholder={this.state.supplyUnits}
                onChange={this.handleChange}
                size="mini"
              />
              <Form.Field
                control={Input}
                label="Precio Unitario"
                name="supplyPrice"
                type="number"
                placeholder={this.state.supplyPrice}
                onChange={this.handleChange}
                size="mini"
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div style={styles.modal_button_holder}>
            <div>
              <p>
                <b>
                  <Total
                    supplyPrice={this.props.supplyPrice}
                    quantity={this.props.quantity}
                  />
                </b>
              </p>
              <p>{this.props.total}</p>
              <br />
              <Button
                name="update-supply-btn"
                style={styles.button_save}
                onClick={() => this.save(this.props.index)}
              >
                Guardar
              </Button>
              <Button onClick={this.handleClose} style={styles.button_cancel}>
                Cancelar
              </Button>
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

UpdateSupplyModal.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  handleChange: PropTypes.func,
  index: PropTypes.any
};

export default UpdateSupplyModal;
