import React from "react";
import { Form, Button, Grid, Segment, Input, Label, Pagination, Dropdown } from "semantic-ui-react";
import SuppliesQuery from "../../Molecules/suppliesQuery";
import theme from "../../_theme";

export default class SuppliesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: undefined,
      first: 20,
      entrieSelected: 20,
      entriesOptions: [
        { key: 1, text: "10", value: 10 },
        { key: 2, text: "20", value: 20 },
      ],
      id: null,
      activePage: 1,
      totalRecords: 0
    };
  }

  getCount = (totalRecords, first, activePage) => {
    this.setState({ totalRecords, first, activePage });
  }

  onPageChange = (e, { activePage }) => this.setState({ activePage });

  handleEntries = (e, { value }) => this.setState({ entrieSelected: value, activePage: 1 });

  render() {
    const { activePage, first, totalRecords, entriesOptions, entrieSelected } = this.state;

    return (
      <Segment>
        <Grid centered>
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column width="13">
              <Form className="Wrapper__Body-Form Jax">
                <Form.Input
                  placeholder="Buscar"
                  icon="search"
                  width="6"
                  onChange={(e, { value }) => this.setState({ search: value })}
                  defaultValue={this.state.search}
                />
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right" width={3}>
              <Button
                basic
                icon="plus"
                className="Wrapper__Body-Form-ButtonAdd Jax"
                id="add-supply"
                content="Insumo"
                onClick={() => this.props.openCreate(false)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column>
              <SuppliesQuery
                search={this.state.search}
                getCount={this.getCount}
                first={entrieSelected}
                activePage={activePage}
                toggleEditSupply={this.props.toggleEditSupply}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid columns={3} divided="vertically" centered>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Label style={theme.styles.paginationLabel}>Ver</Label>
                  <Dropdown
                    id="recordsDropdown"
                    name="recordsDropdown"
                    selection
                    text={`${entrieSelected}`}
                    options={entriesOptions}
                    item
                    onChange={this.handleEntries}
                    compact />
                  <Label style={theme.styles.paginationLabel}>Entradas</Label>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Label style={theme.styles.entriesLabel}>Viendo</Label>
                  <Label style={theme.styles.entriesBoldLabel}>
                    {first > totalRecords
                      ? totalRecords
                      : first}
                  </Label>
                  <Label style={theme.styles.entriesLabel}>de</Label>
                  <Label style={theme.styles.entriesBoldLabel}>
                    {totalRecords}
                  </Label>
                  <Label style={theme.styles.entriesLabel}>Entradas</Label>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  {totalRecords !== 0 &&
                    <Pagination
                      className='Pagination-Fix'
                      boundaryRange={1}
                      siblingRange={0}
                      size='mini'
                      activePage={activePage}
                      totalPages={Math.ceil(totalRecords / entrieSelected)}
                      onPageChange={this.onPageChange}
                    />
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
