import React from "react";
import "./style.css";
import Link from "../../Atoms/link";

export default class PONavigation extends React.Component {
  renderLink = route => {
    const { activeRoute, navigateTo } = this.props;
    const { icon, routeName, text, name } = route;
    const linkClass = routeName === activeRoute ? "link link-active" : "link";

    return (
      <Link
        onClick={() => navigateTo(route)}
        key={routeName}
        className={linkClass}
        icon={icon}
        iconName={name}
        text={text}
      />
    );
  };

  render() {
    const { className, routes } = this.props;
    const links = routes.map(this.renderLink);

    return <div className={`navigation ${className}`}>{links}</div>;
  }
}
