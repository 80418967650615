import React from "react";
import ModalContainer from "../ModalContainer";

class AddContractEstimation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    }
  }

  save = () => {
    console.log('save');
  }

  render() {
    const title = this.props.id ? "Nueva estimación" : "Datos de estimación";
    return (
      <ModalContainer
        title={title}
        save={this.save}
        close={this.props.close} 
        disabled={this.state.isLoading}
      >
        <p>Formulario estimación</p>
      </ModalContainer>
    );
  }
}

export default AddContractEstimation;
