import gql from "graphql-tag";
import { client as clientApollo } from "../../apollo-config";
import { GET_CONTRACTS_ADITIVES, GET_CLIENTS } from "../../queries/accountBalance"
import { format } from "../../../helpers"

export const CREATE_ADITIVE_AB = async ({
  id,
  currency,
  amountContract,
  percentAmortization,
  percentGuaranteeFund,
  startDate,
  finishDate,
  status,
  description
}) => {
  const user = JSON.parse(window.localStorage.getItem("session")).user;
  const eventDescription = "creó una nueva aditiva";

  await clientApollo.mutate({
    mutation: gql`
      mutation {
        updateContractAccountBalance(
          where: { id: "${id}" }
          data: {
            realFinishDate: "${finishDate}"
            aditives: {
              create: {
                currency: ${currency}
                amountContract: ${parseFloat(amountContract).toFixed(2)}
                percentAmortization: ${percentAmortization}
                percentGuaranteeFund: ${percentGuaranteeFund}
                amountAdvancePayment: ${(parseFloat(percentAmortization) * parseFloat(amountContract) / 100).toFixed(2)}
                amountByAmortization: ${(parseFloat(percentAmortization) * parseFloat(amountContract) / 100).toFixed(2)}
                amountRetained: ${(parseFloat(percentGuaranteeFund) * parseFloat(amountContract) / 100).toFixed(2)}
                amountByRetained: ${(parseFloat(percentGuaranteeFund) * parseFloat(amountContract) / 100).toFixed(2)}
                amountContractUpdate: ${parseFloat(amountContract).toFixed(2)}
                amountByBilled: ${parseFloat(amountContract).toFixed(2)}
                amountByPaid: ${parseFloat(amountContract).toFixed(2)}
                startDate: "${startDate}"
                finishDate: "${finishDate}"
                status: ${status}
                description: "${description}"
                elaboratedEvent: {
                  create: {
                    name: "${user.name}"
                    date: "${format.date(new Date(), 3)}"
                    description: "${eventDescription}"
                  }
                }
              }
            }
          }
        ) {
          id
        }
      }
    `,
    refetchQueries: [{ query: GET_CONTRACTS_ADITIVES }, { query: GET_CLIENTS }]
  });
};

export default CREATE_ADITIVE_AB;
