import React from "react";
import PropTypes from "prop-types";

const TabPanel = props => {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

TabPanel.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default TabPanel;

