import gql from "graphql-tag";
import { client as apollo } from "../../apollo-config";
import moment from "moment"

const approvePO = async ({
  client,
  id,
  elaboratedEvent,
  status,
  authorizedBy,
  revisedBy,
  warehouseId,
  currentStatus
}) => {
  if(status!==currentStatus){
    const mutation = await apollo.mutate({
      mutation: gql`
        mutation {
          updatePurchaseOrder(
            data:{
              data:{
                update:{
                    status:${status}
                    authorizedBy:"${authorizedBy}"
                    revisedBy:"${revisedBy}"
                    deliveryStatus: PENDING
                    paymentStatus:PENDING_INVOICE
                    warehouse:{connect:{id:"${warehouseId}"}}
                    elaboratedEvent:{
                      create:{
                        name:"${elaboratedEvent.name}"
                        date:"${moment().local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}"
                        type:${"PURCHASE_ORDER_APPROVED"}
                      }
                    }

                }
              }
            }
            where: { id: "${id}" }
          ){
            id
          }
        }`,
    });
    const connect = await client.mutate({
      mutation: gql`
        mutation {
          updateWarehouse(
            data:{
              orders:{connect:{id:"${id}"}}
            }
            where: { id: "${warehouseId}" }
          ){
            id
          }
        }`,
    });
    return { mutation, connect };
  }
};

export default approvePO;
