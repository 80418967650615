import React, { Component } from "react";
import { Modal, Form, Input, Select, TextArea, Grid } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import FormHeader from "../../../../Molecules/formHeader";
import { ViewDocumentSegment } from "../../../../Molecules";
import { format } from "../../../../../helpers";
import moment from 'moment'

const conditions = [
  { key: "CREDIT", text: "Crédito", value: "CREDIT" },
  { key: "CASH", text: "Contado", value: "CASH" }
];

export default class DetailInvoice extends React.Component {

  handleDownloadFile = (file) => {
    window.open(file, "_blank");
  }

  render() {
    const { invoice: { number, receptionDate, paymentConditions, paymentDate, notes, refUpload }} = this.props
    return (
      <Modal
        className="Modal__Form-SubModal"
        size="tiny"
        centered={false}
        open={this.props.open}
      >
        <FormHeader
          text="Detalle factura"
          onCancel={this.props.onClose}
        />
        <Modal.Content>
          <Form>
            <Form.Field
              name="invoiceNumber"
              label="NÚMERO DE FACTURA"
              control={Input}
              value={number}
              readOnly
            />
            <Form.Field
              name="receptionDate"
              label="FECHA DE RECEPCIÓN DE FACTURA"
              control={Input}
              value={!receptionDate ? "dd/mm/aaaa" : moment(receptionDate).add(1, 'day').format("DD/MM/YYYY")}
              readOnly
            />
            <Form.Field
              name="paymentConditions"
              label="CONDICIONES DE PAGO"
              placeholder="Condiciones de pago"
              control={Select}
              style={{ border: "1px solid #dddddd", height: "32px" }}
              options={conditions}
              value={paymentConditions}
              disabled
            />
            <Form.Field
              label="FECHA DE PAGO"
              name="paymentDate"
              control={Input}
              value={!paymentDate ? "dd/mm/aaaa" : moment(paymentDate).add(1, 'day').format("DD/MM/YYYY")}
              readOnly
            />
            <Form.Field
              control={TextArea}
              label="NOTAS"
              rows="5"
              name="notes"
              value={notes}
              readOnly
            />

            {refUpload.length > 0 &&
              <Grid.Row>
                {refUpload.map(upload => {
                  return (
                    <Grid.Column className="Modal__Form-SubModal-Form-Column-MB" width="2" key={upload.reference}>
                      <ViewDocumentSegment
                        title={format.getNameOriginal(upload.reference)}
                        reference={upload.reference}
                        onDownloadFile={this.handleDownloadFile}
                        uploadDocument={false}
                      />
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            }
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
