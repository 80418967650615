import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { format } from "../../../helpers";

const CREATE_DOCUMENT_ADITIVE = async({
  id,
  idContract,
  idAditive,
  type,
  concept,
  dateElaborated,
  status,
  elaborated,
  authorizer,
  iva,
  amount,
  amountIva,
  total,
  amountDeductives,
  totalContrated,
  totalInvoiced,
  totalPaided,
  currency,
  refUpload,
  contractAmountDeductives
}, { GET_ADITIVES_CONTRACT, QUERY_CONTRACTS }) => {
  const mutation = await client.mutate({
    mutation: gql `
      mutation {
        updateContract(
          where: { id: "${idContract}" }
          data: {
            amountDeductives: ${parseFloat(contractAmountDeductives)}
            aditives: {
              update: {
                where: { id: "${idAditive}" }
                data: {
                  total: ${parseFloat(totalContrated)}
                  amountDeductives: ${parseFloat(amountDeductives)}
                  invoiced: ${parseFloat(totalInvoiced)}
                  paided: ${parseFloat(totalPaided)}
                  currency: ${currency}
                  documentsAditivas: {
                    upsert: {
                      where: { id: "${id}" }
                      update: {
                        type: ${type}
                        concept: "${concept}"
                        elaborated: "${elaborated}"
                        dateElaborated: "${format.date(dateElaborated, 2)}"
                        authorizer: "${authorizer}"
                        status: ${status}
                        iva: ${parseFloat(iva)}
                        amount: ${parseFloat(amount)}
                        amountIva: ${parseFloat(amountIva)}
                        total: ${parseFloat(total)}
                        refUpload: {
                          create: [${refUpload.map(doc => {
                            return `${doc.hasOwnProperty("new") ? `{
                                type: ${doc.type}
                                reference: "${doc.reference}"
                                extension: "${doc.extension}"
                                createDate: "${format.date(new Date(), 2)}"
                                wildcard: "${doc.wildcard}"
                            }` : []}`
                          })}]
                        }
                      }
                      create: {
                        type: ${type}
                        concept: "${concept}"
                        elaborated: "${elaborated}"
                        dateElaborated: "${format.date(dateElaborated, 2)}"
                        authorizer: "${authorizer}"
                        status: ${status}
                        iva: ${parseFloat(iva)}
                        amount: ${parseFloat(amount)}
                        amountIva: ${parseFloat(amountIva)}
                        total: ${parseFloat(total)}
                        refUpload: {
                          create: [${refUpload.map(doc => `{
                            type: ${doc.type}
                            reference: "${doc.reference}"
                            extension: "${doc.extension}"
                            createDate: "${format.date(new Date(), 2)}"
                            wildcard: "${doc.wildcard}"
                          }`)}]
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        ) {
          id
        }
      }
    `,
    refetchQueries: [
      { query: GET_ADITIVES_CONTRACT, variables: { id: idContract } },
      { query: QUERY_CONTRACTS }
    ]
  });
  return mutation;
}

export default CREATE_DOCUMENT_ADITIVE;
