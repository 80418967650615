import React from "react";
import { Modal, Button, Confirm } from "semantic-ui-react";
import DataTable from "../../Organisms/DataTable";
import { climasTemplate } from "../../Organisms/DataTable/climasTemplate";
import { ActionButtons } from "./actionButtons";
import { withRouter } from "react-router-dom";
import deleteWarehouse from "../../../graphql/mutations/deleteWarehouse";

class WerehousesListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      orders: [],
    };
  }

  openModalDelete = (modal, warehouse, orders) => {
    this.setState(prevState => ({ ...prevState, modal, warehouse, orders }));
  };
  
  closeModalDelete = () => this.setState(prevState => ({ ...prevState, modal: false }));

  openEdit = id => this.props.history.push(`/catalogos/editar-almacen/${id}`);

  _deleteWarehouse = async () => {
    const { warehouse: { id } } = this.state
    await deleteWarehouse(id)
    this.closeModalDelete();
  };

  render () {
    const { modal, orders } = this.state
    const { openDetails, action, filter, filterVariables, onOrderChange, onReload } = this.props
    const actionButtonsProps = { openDetails, openEdit: this.openEdit, openModalDelete: this.openModalDelete }

    return (
      <>
        <DataTable
          onOrderChange={onOrderChange}
          onCompleted={action}
          template={climasTemplate}
          query={filter}
          variables={filterVariables}
          itemsKey="warehouses">
          <DataTable.Column
            name="name"
            label="Nombre"
            sortable />
          <DataTable.Column
            name="city"
            label="Ciudad"
            sortable/>
          <DataTable.Column
            name="state"
            label="Estado"
            sortable />
          <DataTable.Column
            name="country"
            label="Pais"
            sortable />
          <DataTable.Column
            name="status"
            label="Estatus"
            sortable />
          <DataTable.Column
            name="actions"
            label=" "
            content={
              (row, column, index) =>
                <ActionButtons
                  index={index}
                  warehouse={row}
                  { ...actionButtonsProps } />
            } />
        </DataTable>
        {orders.length === 0 ? <Confirm
          onConfirm={
            orders.length > 0 ? null : this._deleteWarehouse
          }
          open={modal}
          header="Alerta"
          onCancel={this.closeModalDelete}
          content={
            orders.length > 0
              ? "No se puede eliminar este catálogo porque tiene ordenes de compra asociadas"
              : "¿Está seguro que desea eliminar el elemento de la lista?"
          }
        />:
        <Modal open={modal}>
          <Modal.Header>
            Alerta
          </Modal.Header>
          <Modal.Content>
            No se puede eliminar este catálogo porque tiene ordenes de compra asociadas
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModalDelete}>Ok</Button>
          </Modal.Actions>
        </Modal>}
      </>
    )
  } 
}

export default withRouter(WerehousesListContainer)
