import React from "react";
import DashboardLayout from "../../Organisms/DashboardLayout";
import { Grid, Header, Container, Confirm } from "semantic-ui-react";
import ReceptionsList from "../../Organisms/ReceptionsList";

export default class Inventories extends React.Component {
  state = { modal: "", title: true };

  openModal = modal => this.setState({ modal });
  closeModal = () => this.setState({ modal: "" });

  submit = () => {
    alert("not implemented yet");
    this.closeModal();
  };

  render() {
    return (
      <DashboardLayout activeTab="inventories">
        <Container fluid>
          <Grid columns="equal">
            {window.location.pathname === "/inventarios" ? (
              <Grid.Row className="Wrapper__Nav">
                <Grid.Column floated="left">
                  <Header as="h1" className="Wrapper__Nav-Title">
                    Inventarios
                  </Header>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Column>
                <ReceptionsList openConfirm={() => this.openModal("confirm")} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Confirm
          header="Alerta"
          open={this.state.modal === "confirm"}
          content="¿Está seguro que desea eliminar el elemento de la lista?"
          onCancel={this.closeModal}
          onConfirm={this.submit}
        />
      </DashboardLayout>
    );
  }
}
