import React from "react";
import { IoIosDownload, IoMdEye } from "react-icons/io";
import CurrencyInput from "react-currency-input";
import { Button, Form, Header, Input, Segment, Table, TextArea, Grid, Icon, Modal } from "semantic-ui-react";
import { PurchasesSupplies, Timeline } from "../../Organisms";
import moment from "moment";
import OnLoading from "../../Molecules/OnLoading";
import { FormHeader } from "../../Molecules";

export default class DetailPO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      user: JSON.parse(window.localStorage.getItem("session")).user,
      isLoading: true,
      modalSupply: false,
      errors: {},
      toggles: {
        supplyQuickCreateToggle: false
      }
    };
  }

  componentWillMount() {
    const { id, folio, convertedAt, data } = this.props.data;
    const _data = data;

    _data["resident"] = _data.project.residents.map(user => user.name);
    _data["responsible"] = _data.project.responsibles.map(user => user.name);
    _data["folioReq"] = _data.folio;

    const supplies = data.supplies;
    const suppliesQty = data.suppliesQty;
    const folioPO = this.props.data.folio;
    const reqFolio = isNaN(parseInt(_data.folio));

    this.setState(data => ({
      ...data,
      id,
      folio,
      convertedAt,
      folioPO,
      reqFolio,
      notes: data.notes,
      suppliesQty,
      supplies,
      data: _data,
      applicant: data.applicant,
      status: data.status,
      isLoading: false
    }));
  }

  openSupply = index => {
    const supply = this.state.supplies[index];
    const currentQty = supply.quantity;
    this.setState(prevState => ({
      ...prevState,
      modalSupply: true,
      supply,
      currentQty,
    }))
  };

  closeSupply = () => {
    this.setState(prevState => ({
      ...prevState,
      modalSupply: false
    }))
  }

  getTotalSupply = (a, b) => {
    if (a && b) {
      return `$${(a * b).toFixed(2)}`;
    } else {
      return 0;
    }
  };

  render() {
    return (
      <Segment className="Modal" raised>
        <Header as="h3" dividing>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column
                textAlign="left"
                className="Modal__Header-Title"
              >
                <Grid.Column className="Modal__Header-Title">
                  {this.state.showForm
                    ? "Detalle de la orden de compra"
                    : "Historial de la orden de compra"}
                </Grid.Column>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Button.Group icon>
                  <Button
                    onClick={() =>
                      this.setState(data => ({ ...data, showForm: true }))
                    }
                    active={this.state.showForm ? true : false}
                  >
                    <Icon name="list" />
                  </Button>
                  <Button
                    onClick={() =>
                      this.setState(data => ({
                        ...data,
                        showForm: false,
                      }))
                    }
                    active={this.state.showForm ? false : true}
                  >
                    <Icon name="history" />
                  </Button>
                </Button.Group>
                {this.state.data.status === "IS_PO_APPROVED" ||
                  this.state.data.status === "IS_PO_REVISED" ? (
                    <Button
                      className="Modal__Form-ButtonAdd"
                      id="download-pdf-btn"
                      onClick={() => window.open(`/pdf/${this.state.id}`, "_blank")}
                      style={{ marginLeft: "10px" }}
                    >
                      <IoIosDownload /> &nbsp; Descargar
                    </Button>
                  ) : null}
                <Button
                  id="cancel-detailpo-btn"
                  onClick={this.props.onCancel}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>

        {this.state.isLoading
          ? <OnLoading loading={this.state.isLoading} />
          : (
              this.state.showForm ? (
                <>
                  <Form className="Modal__Form">
                    <Form.Group widths="equal">
                      <Form.Field
                        name="folio"
                        control={Input}
                        type="number"
                        label="FOLIO DE ORDEN"
                        value={this.state.folio}
                        placeholder={this.state.folio}
                        readOnly
                      />
                      <Form.Field
                        name="wasUpdatedAt"
                        control={Input}
                        type="text"
                        label="FECHA DE CREACIÓN"
                        value={this.state.convertedAt
                          ? moment(new Date(parseInt(this.state.convertedAt))).format("DD/MM/YYYY")
                          : moment(
                            this.state.data.wasUpdatedAt.split("T")[0]
                          ).format("DD/MM/YYYY")}
                        readOnly
                      />
                      <Form.Field
                        name="status"
                        control={Input}
                        label="ESTATUS"
                        value={
                          this.state.data.status === "IS_PO_REVIEW"
                            ? "Revisión"
                            : this.state.data.status === "IS_PO_REVISED"
                              ? "Revisada"
                              : this.state.data.status === "IS_PO_CANCEL"
                                ? "Cancelada"
                                : this.state.data.status === "IS_PO_APPROVED"
                                  ? "Aprobada"
                                  : ""
                        }
                        readOnly
                      />
                      <Form.Field
                        name="subtotal"
                        control={CurrencyInput}
                        label="IMPORTE"
                        min={0}
                        value={parseFloat(this.state.data.subtotal).toFixed(2)}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        label="FOLIO DE REQUISICIÓN"
                        name="folio"
                        control={Input}
                        value={this.state.data.folioReq}
                        readOnly
                        width={10}
                      />
                      <Form.Field
                        name="vendor"
                        control={Input}
                        label="PROVEEDOR"
                        value={
                          this.state.data.vendor
                            ? this.state.data.vendor.commercialName
                            : ""
                        }
                        options={this.state.vendorOptions}
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        name="applicant"
                        control={Input}
                        label="ELABORÓ"
                        value={
                          this.state.data.applicant === "undefined"
                            ? ""
                            : this.state.data.applicant
                        }
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        label="DESCUENTO"
                        name="discount"
                        type="number"
                        min={0}
                        control={Input}
                        placeholder="0%"
                        value={this.state.data.discountPercentage.toFixed(2)}
                        width={5}
                        readOnly
                      />
                      <Form.Field
                        label="&nbsp;"
                        name="discountDecimal"
                        min={0}
                        control={CurrencyInput}
                        placeholder="$0.00"
                        value={this.state.data.discount.toFixed(2)}
                        width={5}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        name="cli"
                        control={Input}
                        label="NÚM. DE PRESUPUESTO (CLI)"
                        value={this.state.data.project.cli}
                        readOnly
                      />
                      <Form.Field
                        name="deliveryWarehouse"
                        control={Input}
                        label="ALMACÉN"
                        value={
                          this.state.data.deliveryWarehouse !== "undefined"
                            ? JSON.parse(this.state.data.deliveryWarehouse).name
                            : ""
                        }
                        readOnly
                      />
                      <Form.Field
                        name="authorizedBy"
                        control={Input}
                        label="AUTORIZÓ"
                        value={
                          this.state.data.authorizedBy === "undefined"
                            ? ""
                            : this.state.data.authorizedBy
                        }
                        readOnly
                      />
                      {/**subtotal */}
                      <Form.Field
                        name="subtotal"
                        control={Input}
                        type="number"
                        label="SUBTOTAL"
                        min={0}
                        value={parseFloat(this.state.data.amount).toFixed(2)}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        name="siteName"
                        control={Input}
                        label="NOMBRE DE PROYECTO"
                        value={this.state.data.project.name}
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        name="deliveryDate"
                        control={Input}
                        type="text"
                        label="TIEMPO DE ENTREGA"
                        value={
                          this.state.data.deliveryDate !== "undefined"
                            ? this.state.data.deliveryDate
                            : ""
                        }
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        name="resident"
                        control={Input}
                        label="RESIDENTE DE PROYECTO"
                        value={this.state.data.resident}
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        label="IVA"
                        name="iva"
                        type="number"
                        min={0}
                        width={10}
                        value={this.state.data.iva}
                        control={Input}
                        action={{ basic: true, content: "%" }}
                        labelPosition="right corner"
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        name="responsible"
                        control={Input}
                        label="RESPONSABLE DE PROYECTO"
                        value={this.state.data.responsible}
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        name="currency"
                        control={Input}
                        value={this.state.data.currency}
                        label="MONEDA"
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        label="SOLICITANTE"
                        name="petitioner"
                        control={Input}
                        value={this.state.data.petitioner}
                        width={10}
                        readOnly
                      />
                      {/**retention */}
                      <Form.Field
                        label="RETENCIÓN"
                        name="retention"
                        type="number"
                        min={0}
                        control={Input}
                        placeholder="0.00%"
                        value={this.state.data.retentionPercentage.toFixed(2)}
                        width={5}
                        readOnly
                      />
                      <Form.Field
                        label="&nbsp;"
                        name="retentionDecimal"
                        min={0}
                        control={CurrencyInput}
                        placeholder="$0.00"
                        value={this.state.data.retention.toFixed(2)}
                        width={5}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        name="revisedBy"
                        control={Input}
                        label="REVISÓ"
                        value={
                          this.state.data.revisedBy === "undefined"
                            ? ""
                            : this.state.data.revisedBy
                        }
                        readOnly
                      />
                      <Form.Field
                        name="paymentConditions"
                        control={Input}
                        label="CONDICIONES DE PAGO"
                        value={
                          this.state.data.paymentConditions === "CASH"
                            ? "Contado"
                            : "Crédito"
                        }
                        readOnly
                      />
                      <Form.Field />
                      <Form.Field
                        name="total"
                        control={CurrencyInput}
                        label="TOTAL"
                        value={parseFloat(this.state.data.total).toFixed(2)}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Field
                      label="UBICACIÓN DEL PROYECTO"
                      name="address"
                      value={
                        this.state.data.project.address &&
                          this.state.data.project.address !== "undefined"
                          ? this.state.data.project.address
                          : ""
                      }
                      control={TextArea}
                      readOnly
                    />
                    <Form.Field
                      name="notes"
                      control={TextArea}
                      label="NOTAS"
                      value={
                        this.state.data.notes !== "undefined"
                          ? unescape(this.state.data.notes)
                          : ""
                      }
                      readOnly
                    />
                  </Form>
                  <Header as="h3" dividing className="Modal__Form-Header">
                    Insumos
                  </Header>
                  <Table
                    basic="very"
                    compact="very"
                    textAlign="center"
                    className="Modal__Form-Table"
                  >
                    <Table.Header className="Modal__Form-Table-Header">
                      <Table.Row>
                        <Table.HeaderCell>Clave del insumo</Table.HeaderCell>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell>Descripción</Table.HeaderCell>
                        <Table.HeaderCell>Unidad de medida</Table.HeaderCell>
                        <Table.HeaderCell>Precio unitario</Table.HeaderCell>
                        <Table.HeaderCell>Cantidad</Table.HeaderCell>
                        <Table.HeaderCell>Total</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body className="Modal__Form-Table-Body">
                      {this.state.supplies.map((supply, index) => {
                        const {
                          insID,
                          name,
                          description,
                          quantity,
                          units,
                          purchasePrice,
                        } = supply;
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>{insID}</Table.Cell>
                            <Table.Cell>{unescape(name)}</Table.Cell>
                            <Table.Cell
                              style={{
                                maxWidth: "300px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {unescape(description)}
                            </Table.Cell>
                            <Table.Cell>{units}</Table.Cell>
                            <Table.Cell>
                              $
                              {parseFloat(purchasePrice)
                                .toFixed(2)
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Table.Cell>
                            <Table.Cell>{quantity}</Table.Cell>
                            <Table.Cell>
                              {this.getTotalSupply(quantity, purchasePrice)
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Table.Cell>
                            <Table.Cell>
                              <Button.Group basic>
                                <Button
                                  icon
                                  onClick={() => this.openSupply(index)}
                                >
                                  <IoMdEye />
                                </Button>
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <Modal
                    className="Modal__Form-SubModal"
                    centered={false}
                    open={this.state.modalSupply}
                    onClose={this.closeSupply}
                    header={
                      <FormHeader
                        saveId="save-edit-req-supply-btn"
                        cancelId="cancel-edit-req-supply-btn"
                        text="Detalle del insumo"
                        onCancel={this.closeSupply}
                        onSave={null}
                      />
                    }
                    content={
                      <PurchasesSupplies
                        data={this.state}
                        getTotalSupply={this.getTotalSupply}
                        noEditPrice={true}
                        handleChangeSupply={()=> null}
                      />
                    }
                    size="tiny"
                    dimmer="blurring"
                  />
                </>
              ) : <Timeline reqId={this.state.data.id} />
            )
        }
      </Segment>
    );
  }
}
