import React from "react";
import OnLoading from "../OnLoading";
import _ from "lodash";
import { Table, Container, Button, ButtonGroup, Select, Modal, Confirm } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye } from "react-icons/io";
import { getPurchaseOrders, GetPurchasesByUser } from "../../../graphql/queries/purchases";
import { updatePurchaseOrder, approvePO } from "../../../graphql/mutations/purchases";
import { bugsnagClient } from "../../../bugsnag";
import theme from "../../_theme";
import { client, MUTATIONS } from "../../../graphql/apollo-config";
import { Can } from "../../hoc/permissions";
import mixpanel from "../../../mixpanel";
import { format, isInactive, titleCase } from "../../../helpers";

const deletePO = MUTATIONS.deletePO;

const statusDict = {
  IS_PO_REVIEW: "Revisión",
  IS_PO_REVISED: "Revisada",
  IS_PO_APPROVED: "Aprobada",
  IS_PO_CANCEL: "Cancelada",
};

export default class PurchaseOrdersQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      _data: null,
      data: null,
      loading: true,
      error: null,
      direction: "descending",
      modalErrorOpen: false,
      modalOpen: false,
      outside: false,
      optionsManager: [
        { key: "IS_PO_REVIEW", value: "IS_PO_REVIEW", text: "Revisión" },
        { key: "IS_PO_REVISED", value: "IS_PO_REVISED", text: "Revisada" },
        { key: "IS_PO_APPROVED", value: "IS_PO_APPROVED", text: "Aprobada" },
        { key: "IS_PO_CANCEL", value: "IS_PO_CANCEL", text: "Cancelada" },
      ],
      optionsManagerApproved: [
        { key: "IS_PO_APPROVED", value: "IS_PO_APPROVED", text: "Aprobada" },
        { key: "IS_PO_CANCEL", value: "IS_PO_CANCEL", text: "Cancelada" },
      ],
      optionsPurchasesResponsible: [
        { key: "IS_PO_APPROVED", value: "IS_PO_APPROVED", text: "Aprobada" },
        { key: "IS_PO_CANCEL", value: "IS_PO_CANCEL", text: "Cancelada" },
      ],
      session: window.localStorage.session
        ? JSON.parse(window.localStorage.session)
        : null
    };
  }

  componentWillMount() {
    this.getPurchaseOrders(this.props.first, this.props.first * (this.props.activePage - 1));

    this.setState(data => ({
      ...data,
      activeRoute: this.props.activeRoute
    }));
  }

  componentDidMount() {
    const { setPORefetch, first, skip } = this.props;
    setPORefetch(() => this.getPurchaseOrders(first, skip));
  }

  componentDidUpdate(prevProps) {
    const { activePage, first, search } = this.props;

    if (prevProps.activePage !== activePage || prevProps.first !== first) {
      this.getPurchaseOrders(first, first * (activePage - 1), search);
    }

    if (prevProps.search !== search) {
      this.getPurchaseOrders(first, first * (activePage - 1), search);
    }
  }

  getPurchaseOrders = async (first, skip = 0, search = this.props.search) => {
    try {
      const { session: { user: { id: userId, userRol } } } = this.state;
      const { activePage } = this.props;
      search = format.dateGraphql(search);

      const query = [
        "ADMINISTRATOR",
        "MANAGER",
        "PURCHASES_RESPONSIBLE",
        "ADMINISTRATIVE_COORDINATOR",
        "ZONE_COORDINATOR"
      ].includes(userRol) ? getPurchaseOrders : GetPurchasesByUser

      const getQuery = {
        query,
        variables: {
          userId,
          first,
          skip: first * (activePage - 1),
          ...search,
          searchBoxUpper: search.searchBox && search.searchBox.toUpperCase(),
          searchBoxLower: search.searchBox && search.searchBox.toLowerCase(),
          searchBoxCap: search.searchBox && titleCase(search.searchBox)
        },
        fetchPolicy: "network-only"
      }

      const { loading, data } = await client.query(getQuery);
      const po = data;
      const count = data.count.length;

      this.props.getCount(count, po.purchaseOrders.length, activePage);
      const newPurchaseOrders = [];
      po.purchaseOrders.forEach(purchaseOrder => {
        purchaseOrder.folio = parseInt(purchaseOrder.folio);
        newPurchaseOrders.push(purchaseOrder);
      });

      this.setState({ loading, data: { ...po, purchaseOrders: newPurchaseOrders, userRol } });
    } catch (error) {
      this.setState({ error });
      bugsnagClient.notify(error);
    }
  }

  isReq = status => {
    if (status === "IS_REQ_APPROVED") {
      return { isReq: true, status: "IS_REQ_APPROVED" };
    } else {
      return { isReq: false, status: status };
    }
  };

  _deletePO = async (id, status) => {
    if (status === "IS_PO_CANCEL" || status === "IS_REQ_CANCEL") {
      let prompt = window.confirm("Eliminar orden de compra");
      if (!prompt) return;

      try {
        await deletePO(id, client);
        this.setState(prevState => ({ ...prevState, loading: true }));
        this.getPurchaseOrders(this.props.first);
      } catch (err) {
        bugsnagClient.notify(err);
      }
    } else {
      this.setState(prevState => ({ ...prevState, modalErrorOpen: true }));
    }
  };

  handleClose = () => this.setState({ modalErrorOpen: false });

  handleCloseModal = () => {
    this.setState(data => ({ ...data, modalOpen: false, status: "" }));
  }

  handleSort = clickedColumn => () => {
    const { direction, column, data: { purchaseOrders } } = this.state;
    purchaseOrders.forEach(item => item.status = format.optionsPurchaseOrders(item.status));
    const sortBy = clickedColumn !== column ?
      "descending" : direction !== "ascending" ? "descending" : "ascending";
    const sorting = _.orderBy(purchaseOrders, [clickedColumn], [sortBy.replace("ending", "")]);

    purchaseOrders.forEach(item => item.status = format.optionsPurchaseOrders(item.status));

    this.setState({
      column: clickedColumn,
      data: { purchaseOrders: sorting },
      direction: clickedColumn !== column ? "ascending" : direction === "ascending" ? "descending" : "ascending"
    });
  }

  saveStatus = () => {
    const status = this.isReq(this.state.status).status;
    const obj = {
      id: this.state.id,
      client: client,
      status: status,
    };
    if (status === "IS_PO_REVISED") {
      obj["revisedBy"] = this.state.session.user.name;
      obj["elaboratedEvent"] = { name: this.state.session.user.name };
      obj["currentStatus"] = this.state.currentStatus;
      updatePurchaseOrder(obj)
        .then(res => {
          mixpanel.track("Actualización de estado de orden de compra", {
            estado: "Revisada",
          });
          this.setState({ modalOpen: false });
          this.getPurchaseOrders(this.props.first, this.props.skip);
        })
        .catch(e => bugsnagClient.notify(e));
    } else if (status === "IS_PO_APPROVED") {
      obj["revisedBy"] = this.state.revisedBy;
      obj["authorizedBy"] = this.state.session.user.name;
      obj["warehouseId"] = this.state.warehouseId;
      obj["elaboratedEvent"] = { name: this.state.session.user.name };
      obj["currentStatus"] = this.state.currentStatus;
      approvePO(obj)
        .then(res => {
          mixpanel.track("Actualización de estado de orden de compra", {
            estado: "Aprobada",
          });
          this.setState({ modalOpen: false });
          this.getPurchaseOrders(this.props.first, this.props.skip);
        })
        .catch(e => bugsnagClient.notify(e));
    } else if (status === "IS_PO_CANCEL") {
      obj["elaboratedEvent"] = { name: this.state.session.user.name };
      obj["currentStatus"] = this.state.currentStatus;
      updatePurchaseOrder(obj)
        .then(res => {
          mixpanel.track("Actualización de estado de orden de compra", {
            estado: "Cancelada"
          });
          this.setState({ modalOpen: false });
          this.getPurchaseOrders(this.props.first, this.props.skip);
        })
        .catch(e => bugsnagClient.notify(e));
    } else {
      obj["revisedBy"] = "";
      obj["authorizedBy"] = "";
      obj["elaboratedEvent"] = { name: "null" };
      obj["currentStatus"] = this.state.currentStatus;
      updatePurchaseOrder(obj)
        .then(res => {
          mixpanel.track("Actualización de estado de orden de compra", {
            estado: "Revisión",
          });
          this.setState({ modalOpen: false });
          this.getPurchaseOrders(this.props.first, this.props.skip);
        })
        .catch(e => bugsnagClient.notify(e));
    }
  }

  onChangeSelect = (e, { value, data }) => {
    const { userRol } = this.state

    if (typeof e._dispatchListeners === "function") {
      return false;
    }

    const revisedBy = data.revisedBy;
    const authorizedBy = data.authorizedBy;
    const warehouseId = data.warehouseId;
    const currentStatus = data.currentStatus;
    const validation = this.validateFields(data._data.data);
    const status = value;
    const supplies = data._data.data.supplies;

    if (userRol === "ZONE_COORDINATOR" && status === "IS_REQ_APPROVED") {
      alert("No tienes permisos para aprobar la requisición");
      return false;
    }

    if (status === "IS_PO_REVISED") {
      validation.errors.map((error, index) => {
        if (error === "revisedBy") {
          validation.errors.splice(index, 1);
        }
      });
      validation.errors.map((error, index) => {
        if (error === "notes") {
          validation.errors.splice(index, 1);
        }
      });
      validation.errors.map((error, index) => {
        if (error === "authorizedBy") {
          validation.errors.splice(index, 1);
        }
      });
      validation.errors.map((error, index) => {
        if (error === "count") {
          validation.errors.splice(index, 1);
        }
      });
      if (validation.errors.length === 0 && supplies.length > 0) {
        const id = data.id;
        const text = e.currentTarget.innerText;
        this.setState(data => ({
          ...data,
          id: id,
          statusText: text,
          status: status,
          modalOpen: true,
          currentId: id,
          revisedBy: revisedBy,
          authorizedBy: authorizedBy,
          currentStatus
        }));
      } else {
        alert("Termina de llenar los campos de la orden de compra");
        const _data = data._data;
        const po = {};
        po["folio"] = _data.folio;
        po["convertedAt"] = _data.convertedAt;
        po["notes"] = _data.data.notes;
        po["applicant"] = _data.data.applicant;
        po["subtotal"] = _data.data.subtotal;
        po["project"] = _data.data.project;
        po["vendor"] = _data.data.vendor;
        po["authorizedBy"] = _data.data.authorizedBy;
        po["total"] = _data.data.total;
        po["deliveryDate"] = _data.data.deliveryDate;
        po["deliveryWarehouse"] = _data.data.deliveryWarehouse;
        po["status"] = _data.data.status;
        po["petitioner"] = _data.data.petitioner;
        po["revisedBy"] = _data.data.revisedBy;
        po["wasUpdatedAt"] = _data.data.wasUpdatedAt;
        po["currency"] = _data.data.currency;
        po["paymentConditions"] = _data.data.paymentConditions;
        po["supplies"] = _data.data.supplies;
        po["iva"] = _data.data.iva;
        po["data"] = _data.data;
        this.props.toggleEditPO({
          id: _data.id,
          supplies: po.supplies,
          po,
        });
      }
    }

    if (status === "IS_PO_APPROVED") {
      validation.errors.map((error, index) => {
        if (error === "authorizedBy") {
          validation.errors.splice(index, 1);
        }
      });
      validation.errors.map((error, index) => {
        if (error === "count") {
          validation.errors.splice(index, 1);
        }
      });
      validation.errors.map((error, index) => {
        if (error === "notes") {
          validation.errors.splice(index, 1);
        }
      });
      if (validation.errors.length === 0 && supplies.length > 0) {
        const id = data.id;
        const text = e.currentTarget.innerText;

        this.setState(data => ({
          ...data,
          id: id,
          statusText: text,
          status: status,
          modalOpen: true,
          currentId: id,
          revisedBy: revisedBy,
          authorizedBy: authorizedBy,
          warehouseId: warehouseId,
          currentStatus
        }));
      } else {
        alert("La orden de compra no ha sido revisada");
        const _data = data._data;
        const po = {};
        po["folio"] = _data.folio;
        po["convertedAt"] = _data.convertedAt;
        po["notes"] = _data.data.notes;
        po["applicant"] = _data.data.applicant;
        po["subtotal"] = _data.data.subtotal;
        po["project"] = _data.data.project;
        po["vendor"] = _data.data.vendor;
        po["authorizedBy"] = _data.data.authorizedBy;
        po["iva"] = _data.data.iva;
        po["total"] = _data.data.total;
        po["deliveryDate"] = _data.data.deliveryDate;
        po["deliveryWarehouse"] = _data.data.deliveryWarehouse;
        po["status"] = _data.data.status;
        po["petitioner"] = _data.data.petitioner;
        po["revisedBy"] = _data.data.revisedBy;
        po["wasUpdatedAt"] = _data.data.wasUpdatedAt;
        po["currency"] = _data.data.currency;
        po["paymentConditions"] = _data.data.paymentConditions;
        po["supplies"] = _data.data.supplies;
        po["data"] = _data.data;
        this.props.toggleEditPO({
          id: _data.id,
          supplies: po.supplies,
          po,
        });
      }
    }

    if (status === "IS_PO_CANCEL") {
      const id = data.id;
      const text = e.currentTarget.innerText;
      this.setState(data => ({
        ...data,
        id: id,
        statusText: text,
        status: status,
        modalOpen: true,
        currentStatus
      }));
    }
    if (status === "IS_PO_REVIEW") {
      const id = data.id;
      const text = e.currentTarget.innerText;
      this.setState(data => ({
        ...data,
        id: id,
        statusText: text,
        status: status,
        modalOpen: true,
        currentStatus
      }));
    }
  }

  validateFields = validationObject => {
    let isValid = true;
    const errors = [];
    for (const key in validationObject) {
      if (validationObject.hasOwnProperty(key)) {
        const element = validationObject[key];
        if (
          element === "null" ||
          element === null ||
          element === "" ||
          element === "undefined" ||
          element === undefined
        ) {
          isValid = false;
          errors.push(key);
        }
      }
    }
    return { isValid: isValid, errors: errors };
  }

  render() {
    const { data, loading, error, column, direction } = this.state;

    if (loading || error || !data || !data.purchaseOrders.length) {
      return <OnLoading loading={loading} error={error} />
    }

    const _data_ = data.purchaseOrders;

    return (
      <Container fluid>
        <Table
          basic="very"
          compact="very"
          textAlign="center"
          sortable
          size="small"
        >
          <Table.Header className="Wrapper__Body-Table-Header Small Caps">
            <Table.Row>
              <Table.HeaderCell
                sorted={column === "folio" ? direction : "descending"}
                onClick={this.handleSort("folio")}
              >
                Folio OC
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "data.folio" ? direction : "descending"}
                onClick={this.handleSort("data.folio")}
              >
                Folio REQ
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "project.name" ? direction : "descending"}
                onClick={this.handleSort("project.name")}
              >
                Nombre del proyecto
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "wasUpdatedAt" ? direction : "descending"}
                onClick={this.handleSort("wasUpdatedAt")}
              >
                Fecha de creación
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "project.cli" ? direction : "descending"}
                onClick={this.handleSort("project.cli")}
              >
                Num de presupuesto CLI
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "deliveryDate" ? direction : "descending"}
                onClick={this.handleSort("deliveryDate")}
              >
                Fecha de entrega
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "vendor.commercialName" ? direction : "descending"}
                onClick={this.handleSort("vendor.commercialName")}
              >
                Proveedor
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "subtotal" ? direction : "descending"}
                onClick={this.handleSort("subtotal")}
              >
                Subtotal
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "total" ? direction : "descending"}
                onClick={this.handleSort("total")}
              >
                Monto
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "currency" ? direction : "descending"}
                onClick={this.handleSort("currency")}
              >
                Moneda
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "status" ? direction : "descending"}
                onClick={this.handleSort("status")}
              >
                Estatus
              </Table.HeaderCell>
              <Table.HeaderCell className="Wrapper__Body-Table-Header-NoHover" />
            </Table.Row>
          </Table.Header>
          <Table.Body className="Wrapper__Body-Table-Body Small Caps">
            {_data_.map((requisition, index) => {
              const po = requisition;
              const isInactiveProject = isInactive(requisition.data.project.status);

              requisition["folio"] = po.folio;
              requisition["notes"] = po.data.notes;
              requisition["petitioner"] = po.data.petitioner;
              requisition["applicant"] = po.data.applicant;
              requisition["subtotal"] = po.data.subtotal;
              requisition["project"] = po.data.project;
              requisition["vendor"] = po.data.vendor;
              requisition["authorizedBy"] = po.data.authorizedBy;
              requisition["iva"] = po.data.iva;
              requisition["amount"] = po.data.amount;
              requisition["retention"] = po.data.retention;
              requisition["retentionPercentage"] =
                po.data.retentionPercentage;
              requisition["discount"] = po.data.discount;
              requisition["discountPercentage"] =
                po.data.discountPercentage;
              requisition["total"] = po.data.total;
              requisition["deliveryDate"] = po.data.deliveryDate;
              requisition["deliveryWarehouse"] =
                po.data.deliveryWarehouse;
              requisition["status"] = po.data.status;
              requisition["revisedBy"] = po.data.revisedBy;
              requisition["wasUpdatedAt"] = po.convertedAt ? new Date(parseInt(po.convertedAt)).toISOString() : po.data.wasUpdatedAt;
              requisition["currency"] = po.data.currency;
              requisition["supplies"] = po.data.supplies;
              requisition["paymentConditions"] =
                po.data.paymentConditions;
              const warehouse = requisition.data.deliveryWarehouse.replace(
                "\\/g",
                ""
              );
              return (
                <Table.Row key={requisition.id} textAlign="center">
                  <Table.Cell>{requisition.folio}</Table.Cell>
                  <Table.Cell>{requisition.data.folio}</Table.Cell>
                  <Table.Cell>{requisition.data.project.name}</Table.Cell>
                  <Table.Cell>
                    {format.date(requisition.wasUpdatedAt)}
                  </Table.Cell>
                  <Table.Cell>{requisition.data.project.cli}</Table.Cell>
                  <Table.Cell>
                    {requisition.data.deliveryDate !== "undefined"
                      ? requisition.data.deliveryDate
                      : ""}{" "}
                  </Table.Cell>
                  <Table.Cell>
                    {requisition.data.vendor
                      ? requisition.data.vendor.commercialName
                      : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {
                      new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" })
                        .format(requisition.data.subtotal)
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {
                      new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" })
                        .format(requisition.data.total)
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {requisition.currency !== "undefined"
                      ? requisition.currency
                      : ""}
                  </Table.Cell>
                  <Table.Cell>
                    <Can do="approve" on="Requisition">
                      <Select
                        disabled={isInactiveProject}
                        id={`select-status-${requisition.id}`}
                        name="status"
                        data={{
                          isReq: requisition.data.isReq,
                          id: requisition.id,
                          _data: requisition,
                          currentStatus: requisition.status,
                          revisedBy: requisition.revisedBy,
                          authorizedBy: requisition.authorizedBy,
                          warehouseId:
                            warehouse !== "undefined"
                              ? JSON.parse(warehouse).id
                              : warehouse,
                        }}
                        value={requisition.data.status}
                        text={format.optionsPurchaseOrders(requisition.data.status)}
                        options={
                          requisition.data.status === "IS_PO_APPROVED"
                            ? this.state.optionsManagerApproved
                            : this.state.optionsManager
                        }
                        style={{ border: "none" }}
                        onChange={!isInactiveProject && this.onChangeSelect}
                      />
                    </Can>
                    <Can do="revise" on="Requisition">
                      {requisition.status === "IS_PO_APPROVED" ? (
                        <Select
                          disabled={isInactiveProject}
                          id={`select-status-${requisition.id}`}
                          name="status"
                          data={{
                            isReq: requisition.data.isReq,
                            id: requisition.id,
                            _data: requisition,
                            revisedBy: requisition.revisedBy,
                            authorizedBy: requisition.authorizedBy,
                          }}
                          value={requisition.data.status}
                          text={format.optionsPurchaseOrders(requisition.data.status)}
                          options={this.state.optionsPurchasesResponsible}
                          style={{ border: "none" }}
                          onChange={!isInactiveProject && this.onChangeSelect}
                        />
                      ) : (
                          <Select
                            disabled={isInactiveProject}
                            name="status"
                            id={`select-status-${requisition.id}`}
                            data={{
                              isReq: requisition.data.isReq,
                              id: requisition.id,
                              _data: requisition,
                              revisedBy: requisition.revisedBy,
                              authorizedBy: requisition.authorizedBy,
                            }}
                            value={requisition.data.status}
                            text={format.optionsPurchaseOrders(requisition.data.status)}
                            options={[
                              {
                                key: "IS_PO_REVIEW",
                                value: "IS_PO_REVIEW",
                                text: "Revisión",
                              },
                              {
                                key: "IS_PO_REVISED",
                                value: "IS_PO_REVISED",
                                text: "Revisada",
                              },
                              {
                                key: "IS_PO_CANCEL",
                                value: "IS_PO_CANCEL",
                                text: "Cancelado",
                              },
                            ]}
                            style={{ border: "none" }}
                            onChange={!isInactiveProject && this.onChangeSelect}
                          />
                        )}
                    </Can>
                    <Can do="create" on="Requisition">
                      <Select
                        disabled={isInactiveProject}
                        id={`select-status-${requisition.id}`}
                        name="status"
                        data={{
                          isReq: requisition.isReq,
                          id: requisition.id,
                          _data: requisition,
                        }}
                        value={po.status}
                        text={format.optionsPurchaseOrders(po.status)}
                        options={[
                          {
                            key: "IS_PO_REVIEW",
                            value: "IS_PO_REVIEW",
                            text: "Revisión",
                          },
                          {
                            key: "IS_PO_REVISED",
                            value: "IS_PO_REVISED",
                            text: "Revisada",
                          },
                          {
                            key: "IS_PO_APPROVED",
                            value: "IS_PO_APPROVED",
                            text: "Aprobada",
                          },
                          {
                            key: "IS_PO_CANCEL",
                            value: "IS_PO_CANCEL",
                            text: "Cancelado",
                          },
                        ]}
                        style={{ border: "none" }}
                        onChange={
                          requisition.status === "IS_PO_APPROVED"
                            ? !isInactiveProject && this.onSelectPrint
                            : !isInactiveProject && this.onChangeSelect
                        }
                      />
                    </Can>
                    <Can do="viewStatus" on="Requisition">
                      {statusDict[po.status]}
                    </Can>
                  </Table.Cell>
                  <Table.Cell>
                    <ButtonGroup basic>
                      <Can do="edit" on="PurchaseOrder">
                        <Button
                          disabled={isInactiveProject}
                          id={`edit-po-btn-${requisition.data.project.cli}`}
                          onClick={
                            requisition.data.status === "IS_PO_APPROVED" ||
                              requisition.data.status === "IS_PO_CANCEL" ||
                              requisition.data.status === "IS_PO_REVISED" ||
                              this.state.session.user.userRol ===
                              "TECHNICIAN" ||
                              this.state.session.user.userRol ===
                              "ACCOUNT_BALANCE_RESPONSIBLE"
                              ? null
                              : () => {
                                !isInactiveProject && this.props.toggleEditPO({
                                  id: requisition.id,
                                  supplies: requisition.data.supplies,
                                  po,
                                });
                              }
                          }
                          icon
                        >
                          <IoMdCreate
                            style={
                              requisition.data.status ===
                                "IS_PO_APPROVED" ||
                                requisition.data.status === "IS_PO_CANCEL" ||
                                requisition.data.status === "IS_PO_REVISED" ||
                                this.state.session.user.userRol ===
                                "ACCOUNT_BALANCE_RESPONSIBLE" ||
                                this.state.session.user.userRol ===
                                "TECHNICIAN"
                                ? { color: "#dddddd" }
                                : null
                            }
                          />
                        </Button>
                      </Can>
                      <Can do="delete" on="PurchaseOrder">
                        <Button
                          disabled={isInactiveProject}
                          id={`delete-po-btn-${
                            requisition.data.project.cli
                            }`}
                          onClick={
                            this.state.session.user.userRol ===
                              "ACCOUNT_BALANCE_RESPONSIBLE" ||
                              this.state.session.user.userRol !== "TECHNICIAN"
                              ? () =>
                                !isInactiveProject &&
                                this._deletePO(
                                  requisition.id,
                                  requisition.data.status,
                                  requisition.data.id
                                )
                              : null
                          }
                          icon
                        >
                          <IoIosTrash
                            style={
                              this.state.session.user.userRol ===
                                "ACCOUNT_BALANCE_RESPONSIBLE" ||
                                this.state.session.user.userRol ===
                                "TECHNICIAN"
                                ? { color: "#dddddd" }
                                : null
                            }
                          />
                        </Button>
                      </Can>
                      <Button
                        disabled={isInactiveProject}
                        id={`detail-po-btn-${
                          requisition.data.project.cli
                          }`}
                        onClick={() => !isInactiveProject && this.props.toggleDetailPO(requisition)}
                        icon
                      >
                        <IoMdEye />
                      </Button>
                    </ButtonGroup>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Modal size="small" open={this.state.modalErrorOpen}>
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <p>Primero debes cancelar la requisición</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleClose}
              style={theme.styles.buttons.red}
            >
              Aceptar
            </Button>
          </Modal.Actions>
        </Modal>
        <Confirm
          open={this.state.modalOpen}
          cancelButton="Cancelar"
          confirmButton="Aceptar"
          onCancel={() => this.handleCloseModal()}
          onConfirm={() => this.saveStatus()}
          content={() => {
            return (
              <p style={{ textAlign: "center", margin: "20px" }}>
                ¿Deseas cambiar el estado de la orden de compra a{" "}
                <b>{this.state.statusText}</b>?
              </p>
            );
          }}
        />
      </Container>
    );
  }
}
