import React from "react";
import DataTable from "../../Organisms/DataTable";
import { climasTemplate } from "../../Organisms/DataTable/climasTemplate";
import { ActionButtons } from "./actionButtons";
import { bugsnagClient } from "../../../bugsnag";
import { DELETE_USER } from "../../../graphql/mutations/users";
import { client } from "../../../graphql/apollo-config";
import { getProjectsByUser } from "../../../graphql/queries/projects";

export default ({
  action,
  filter,
  filterVariables,
  onOrderChange,
  onReload,
  toggleOpenChangePassword,
  toggleOpenCreate
}) => {

  const ROLES = {
    ADMINISTRATOR: "Administrador",
    MANAGER: "Gerente",
    PURCHASES_RESPONSIBLE: "Encargado de compras",
    WAREHOUSE_RESPONSIBLE: "Encargado de inventarios",
    CONTRACTOR_RESPONSIBLE: "Encargado de contratistas",
    CONTRACTOR: "Contratista",
    TECHNICIAN: "Residente",
    ACCOUNT_BALANCE_RESPONSIBLE: "Encargado de balance de cuentas",
    ADMINISTRATIVE_COORDINATOR: "Coordinador administrativo de obra.",
    ZONE_COORDINATOR: "Coordinador de zona"
  };

  const STATUS = {
    ACTIVE: "Activo",
    INACTIVE: "Inactivo",
  };

  const _deleteUsers = async id => {
    const { data: { projects } } = await client.query({
      query: getProjectsByUser,
      variables: { idUser: id },
      fetchPolicy: "network-only"
    });

    if (!!projects.length) return window.alert("No puedes eliminar un usuario que tiene proyectos");

    let prompt = window.confirm("Eliminar Usuario");
    if (!prompt) return;

    try {
      await DELETE_USER(id)
      onReload();
    } catch (err) {
      bugsnagClient.notify(err);
    }
  };

  const actionButtonsProps = {
    toggleOpenChangePassword,
    _deleteUsers,
    toggleOpenCreate
  }

  return (
    <DataTable
      onOrderChange={onOrderChange}
      onCompleted={action}
      template={climasTemplate}
      query={filter}
      variables={filterVariables}
      itemsKey="users">
      <DataTable.Column
        name="name"
        label="Nombre del contacto"
        sortable />
      <DataTable.Column
        name="email"
        label="Correo electrónico"
        sortable/>
      <DataTable.Column
        name="userRol"
        label="Rol"
        content={(row) => ROLES[row.userRol] }
        sortable/>
      <DataTable.Column
        name="status"
        label="Estatus"
        content={(row) => STATUS[row.status] }
        sortable/>
      <DataTable.Column
        name="actions"
        label=" "
        content={
          (row, column, index) =>
            <ActionButtons
              index={index}
              user={row}
              { ...actionButtonsProps } />
        } />
    </DataTable>
  );
};
