import React from "react";
import { Button } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye } from "react-icons/io";
import { useCurrentUser } from "../../../hooks";
import { isInactive } from "../../../helpers";

export const ActionButtons = ({
  project,
  onUpdate,
  deleteProject,
  openDetails,
  index
}) => {
  const { currentUser: { user: { userRol } = {} } } = useCurrentUser() || {};
  let isInactiveProject = userRol === "ADMINISTRATOR" ? false : isInactive(project.status)

  return (
    <Button.Group basic>
      <Button
        disabled={isInactiveProject}
        id={`edit-client-btn-${project.id}`}
        onClick={!isInactiveProject ? () => onUpdate(true, index, project.id, project) : null }
        icon>
        <IoMdCreate />
      </Button>
      <Button
        disabled={isInactiveProject}
        id={`delete-client-btn-${project.id}`}
        onClick={!isInactiveProject ? () => deleteProject(project.id) : null }
        icon>
        <IoIosTrash />
      </Button>
      <Button
        disabled={isInactiveProject}
        onClick={!isInactiveProject ? () => openDetails(project, index) :  null}
        icon>
        <IoMdEye />
      </Button>
    </Button.Group>
  );
};
