import React from "react";
import { IoMdCreate, IoIosBook } from "react-icons/io";
import SummaryContractAccountBalance from "../SummaryContractAccountBalance"
import ListContractAccountBalance from "../ListContractAccountBalance"

export const routes = [
  {
    icon: IoIosBook,
    item: SummaryContractAccountBalance,
    routeName: "summary",
    text: "Resumen",
  },
  {
    icon: IoMdCreate,
    item: ListContractAccountBalance,
    routeName: "contract",
    text: "Contratos",
  }
];