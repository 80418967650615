import React from "react";
import "./style.css";

export default class Table extends React.Component {
  render() {
    return (
      <table
        id={this.props.id}
        data={this.props.data}
        className={this.props.className}
      >
        {this.props.children}
      </table>
    );
  }
}
