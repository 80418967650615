import React from "react";
import { ApolloProvider } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import mixpanel from "../../../mixpanel";
import CurrencyInput from "react-currency-input";
import { Form, Header, Input, Segment, Table, TextArea } from "semantic-ui-react";
import { client } from "../../../graphql/apollo-config";
import { createPoFromReq, connectPOToProject } from "../../../graphql/mutations/purchases";
import { FormHeader } from "../../Molecules";

export default class CreatePurchaseOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveBtn: true,
      requisition: {},
      supply: {
        key: "",
        description: "",
        units: "",
        purchasePrice: "",
        quantity: "",
      },
      supplies: [],
      options: {
        currencyOptions: [
          { key: "mxn", text: "MXN", value: "MXN" },
          { key: "usd", text: "USD", value: "USD" },
        ],
        statusOptions: [
          {
            key: "revision",
            text: "Revisión",
            value: "IS_PO_REVIEW",
          },
        ],
        paymentConditionsOptions: [
          { key: "credit", text: "Crédito", value: "CREDIT" },
          { key: "one-time", text: "Contado", value: "CASH" },
        ],
      },
      user: JSON.parse(window.localStorage.getItem("session")).user,
    };
  }

  componentWillMount() {
    this.getPurchaseOrders()
      .then(res => {
        const lastFolio = res.purchaseOrders[res.purchaseOrders.length - 1];
        this.getRequisitions()
          .then(result => {
            const _responsible = result.requisition.project.residents.map(user => user.name);
            const _resident = result.requisition.project.responsibles.map(user => user.name);

            this.setState(data => ({
              ...data,
              requisition: {
                folio: lastFolio ? parseInt(lastFolio.folio) + 1 : 1,
                folioReq: result.requisition.folio,
                currency: result.requisition.currency,
                paymentConditions: result.requisition.paymentConditions,
                petitioner: result.requisition.petitioner,
                iva: result.requisition.iva,
                discount: result.requisition.discount,
                amount: result.requisition.amount,
                discountPercentage: result.requisition.discountPercentage,
                retention: result.requisition.retention,
                retentionPercentage: result.requisition.retentionPercentage,
                total: result.requisition.total,
                subtotal: result.requisition.subtotal,
                deliveryDate: result.requisition.deliveryDate,
                deliveryWarehouse:
                  result.requisition.deliveryWarehouse !== "undefined"
                    ? JSON.parse(result.requisition.deliveryWarehouse).name
                    : "",
                authorizedBy: result.requisition.authorizedBy,
                revisedBy: result.requisition.revisedBy,
                applicant: result.requisition.applicant,
                project: result.requisition.project,
                resident: _resident,
                responsible: _responsible,
                vendor: result.requisition.vendor,
                vendorId: result.requisition.vendor.id,
                supplies: result.requisition.supplies,
                notes: result.requisition.notes,
                status: result.requisition.status,
                address: result.requisition.project.address,
                wasUpdatedAt: result.requisition.wasUpdatedAt.split("T")[0],
                discountDecimal: this.calculateDiscountFromPercentage(
                  result.requisition.discount,
                  result.requisition.subtotal
                ),
                retentionDecimal: this.calculateRetentionFromPercentage(
                  result.requisition.retention,
                  result.requisition.subtotal
                ),
              },
              supplies: result.requisition.supplies,
              residents: _resident,
              responsibles: _responsible,
            }));
          })
          .catch(e => console.log(e));
      })
      .catch(e => console.log(e));
  }

  calculateDiscountFromPercentage = (discountPercentage, subtotal) => {
    return (parseFloat(discountPercentage) / 100) * parseFloat(subtotal);
  };

  calculateRetentionFromPercentage = (discountPercentage, subtotal) => {
    return (parseFloat(discountPercentage) / 100) * parseFloat(subtotal);
  };

  getPurchaseOrders = async () => {
    const { data } = await client.query({
      query: gql`
        {
          purchaseOrders {
            folio
          }
        }
      `,
    });
    return data;
  };

  getRequisitions = async () => {
    const { data } = await client.query({
      query: gql`
        {
          requisition(
            where:{id:"${this.props.data.id}"}
          ) {
            id
            suppliesQty
              folio
              currency
              deliveryDate
              deliveryWarehouse
              petitioner
              authorizedBy
              wasUpdatedAt
              revisedBy
              applicant
              iva
              notes
              project {
                id
                cli
                name
                address
                responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
                  id,
                  name
                }
                residents: users(where: { userRol_in: [TECHNICIAN] }){
                  id,
                  name
                }
              }
              vendor{
                id
                commercialName
                contacts{
                  name
                }
              }
              supplies {
                id
                name
                insID
                description
                units
                quantity
                purchasePrice
              }
              paymentConditions
              subtotal
              total
              status
              retention
              discount
              amount
              retentionPercentage
              discountPercentage
          }
        }
      `,
    });
    return data;
  };

  updateReqStatus = async () => {
    const mutation = await client.mutate({
      mutation: gql`
        mutation {
          updateRequisition(
            data:{
              status:IS_PO_REVIEW
              isReq:false
              elaboratedEvent: {
                create:{
                  name:"${this.state.user.name}"
                  date:"${moment()
                    .local()
                    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}"
                  type:PURCHASE_ORDER_CREATED
                }
              }
            }
            where:{
              id:"${this.props.data.id}"
            }
          ) {
            id
          }
        }
      `,
    });
    return mutation;
  };

  save = async () => {
    this.setState(data => ({ ...data, saveBtn: false }));
    const requisition = this.state.requisition;
    const newPO = await createPoFromReq("null", this.props.data.id);
    if (newPO) {
      const poId = newPO.data.createPurchaseOrder.id;
      const updateStatus = await this.updateReqStatus();
      await connectPOToProject(poId, requisition.project.id);
      mixpanel.track("Orden de compra creada", { metodo: "requisición" });
      this.setState(data => ({ ...data, saveBtn: true }));

      if (this.props.refetch) {
        this.props.refetch();
      }

      // Close modal
      this.props.onCancel();
    }
  };

  getTotalSupply = (a, b) => {
    if (a && b) {
      return `$${(a * b).toFixed(2)}`;
    } else {
      return 0;
    }
  };

  render() {
    return (
      <ApolloProvider client={client}>
        <Segment raised className="Modal">
          <FormHeader
            saveId="convert-po-btn"
            cancelId="cancel-convert-po-btn"
            text="Crear orden de compra"
            onSave={this.state.saveBtn ? this.save : null}
            onCancel={this.props.onCancel}
          />
          <Form className="Modal__Form">
            <Form.Group widths="equal">
              <Form.Field
                label="FOLIO DE ORDEN"
                name="folio"
                control={Input}
                value={this.state.requisition.folio}
                readOnly
              />
              <Form.Field
                label="FECHA DE CREACIÓN"
                name="wasUpdatedAt"
                value={this.state.requisition.wasUpdatedAt}
                control={Input}
                readOnly
              />
              <Form.Field
                label="ESTATUS"
                name="status"
                control={Input}
                value={
                  this.state.requisition.status === "IS_REQ_REVISION"
                    ? "Revisión"
                    : this.state.requisition.status === "IS_REQ_REVISED"
                    ? "Revisada"
                    : this.state.requisition.status === "IS_REQ_CANCEL"
                    ? "Cancelada"
                    : this.state.requisition.status === "IS_REQ_APPROVED"
                    ? "Aprobada"
                    : ""
                }
                options={this.state.options.statusOptions}
                readOnly
              />
              <Form.Field
                label="IMPORTE"
                name="subtotal"
                value={this.state.requisition.subtotal}
                control={CurrencyInput}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                label="FOLIO DE REQUISICIÓN"
                name="folio"
                control={Input}
                value={this.state.requisition.folioReq}
                width={10}
                readOnly
              />
              <Form.Field
                label="PROVEEDOR"
                name="vendorId"
                control={Input}
                value={
                  this.state.requisition.vendor
                    ? this.state.requisition.vendor.commercialName
                    : null
                }
                width={10}
                readOnly
              />
              <Form.Field
                label="ELABORÓ"
                name="applicant"
                control={Input}
                value={this.state.requisition.applicant}
                width={10}
                readOnly
              />
              <Form.Field
                label="DESCUENTO"
                name="discount"
                type="number"
                min={0}
                control={Input}
                placeholder="0%"
                value={this.state.requisition.discountPercentage}
                width={5}
                readOnly
              />
              <Form.Field
                label="&nbsp;"
                name="discountDecimal"
                min={0}
                control={CurrencyInput}
                placeholder="$0.00"
                value={this.state.requisition.discount}
                width={5}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                label="CLI"
                name="cli"
                control={Input}
                value={
                  this.state.requisition.project
                    ? this.state.requisition.project.cli
                    : null
                }
                readOnly
              />
              <Form.Field
                label="ALMACÉN"
                name="deliveryWarehouse"
                value={this.state.requisition.deliveryWarehouse}
                control={Input}
                readOnly
              />
              <Form.Field
                label="AUTORIZÓ"
                name="authorizedBy"
                control={Input}
                value={this.state.requisition.authorizedBy}
                readOnly
              />
              <Form.Field
                label="SUBTOTAL"
                name="subtotal"
                value={this.state.requisition.amount}
                control={CurrencyInput}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                label="NOMBRE DE PROYECTO"
                name="siteName"
                control={Input}
                value={
                  this.state.requisition.project
                    ? this.state.requisition.project.name
                    : null
                }
                width={10}
                readOnly
              />
              <Form.Field
                label="TIEMPO DE ENTREGA"
                name="deliveryDate"
                control={Input}
                value={this.state.requisition.deliveryDate}
                width={10}
                readOnly
              />
              <Form.Field
                label="RESIDENTE"
                name="resident"
                control={Input}
                value={this.state.requisition.resident}
                width={10}
                readOnly
              />
              {/**iva */}
              <Form.Field
                label="IVA"
                name="iva"
                type="number"
                min={0}
                value={this.state.requisition.iva}
                control={Input}
                width={10}
                action={{ basic: true, content: "%" }}
                labelPosition="right corner"
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                label="RESPONSABLE DE PROYECTO"
                name="responsible"
                control={Input}
                value={
                  this.state.requisition.project
                    ? this.state.requisition.responsible
                    : null
                }
                width={10}
                readOnly
              />
              <Form.Field
                label="MONEDA"
                name="currency"
                control={Input}
                value={this.state.requisition.currency}
                options={this.state.options.currencyOptions}
                width={10}
                readOnly
              />
              <Form.Field
                label="SOLICITANTE"
                name="petitioner"
                control={Input}
                value={this.state.requisition.petitioner}
                width={10}
                readOnly
              />
              <Form.Field
                label="RETENCIÓN"
                name="retention"
                type="number"
                min={0}
                control={Input}
                placeholder="0.00%"
                value={this.state.requisition.retentionPercentage}
                width={5}
                readOnly
              />
              <Form.Field
                label="&nbsp;"
                name="retentionDecimal"
                min={0}
                control={CurrencyInput}
                placeholder="$0.00"
                value={this.state.requisition.retention}
                width={5}
                readOnly
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                label="REVISÓ"
                name="revisedBy"
                value={this.state.requisition.revisedBy}
                control={Input}
                readOnly
              />
              <Form.Field
                label="CONDICIONES DE PAGO"
                name="paymentConditions"
                control={Input}
                value={
                  this.state.requisition.paymentConditions === "CASH"
                    ? "Contado"
                    : "Credito"
                }
                options={this.state.options.paymentConditionsOptions}
                readOnly
              />
              <Form.Field />
              <Form.Field
                label="TOTAL"
                name="total"
                value={this.state.requisition.total}
                control={CurrencyInput}
                readOnly
              />
            </Form.Group>
            <Form.Field
              label="UBICACIÓN DEL PROYECTO"
              name="address"
              value={
                this.state.requisition.address &&
                this.state.requisition.address !== "undefined"
                  ? this.state.requisition.address
                  : ""
              }
              control={TextArea}
              readOnly
            />
            <Form.Field
              label="NOTAS"
              name="notes"
              control={TextArea}
              value={unescape(this.state.requisition.notes)}
              readOnly
            />
          </Form>
          <Header as="h3" dividing className="Modal__Form-Header">
            Insumos
          </Header>
          <Table
            basic="very"
            compact="very"
            textAlign="center"
            className="Modal__Form-Table"
          >
            <Table.Header className="Modal__Form-Table-Header">
              <Table.Row>
                <Table.HeaderCell>Clave del insumo</Table.HeaderCell>
                <Table.HeaderCell>Nombre</Table.HeaderCell>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell>Unidad de medida</Table.HeaderCell>
                <Table.HeaderCell>Precio unitario</Table.HeaderCell>
                <Table.HeaderCell>Cantidad</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className="Modal__Form-Table-Body">
              {this.state.supplies.map((supply, index) => {
                const {
                  insID,
                  name,
                  description,
                  quantity,
                  units,
                  purchasePrice,
                } = supply;
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{insID}</Table.Cell>
                    <Table.Cell>{unescape(name)}</Table.Cell>
                    <Table.Cell
                      style={{
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {unescape(description)}
                    </Table.Cell>
                    <Table.Cell>{units}</Table.Cell>
                    <Table.Cell>
                      $
                      {parseFloat(purchasePrice)
                        .toFixed(2)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    </Table.Cell>
                    <Table.Cell>{quantity}</Table.Cell>
                    <Table.Cell>
                      {this.getTotalSupply(quantity, purchasePrice)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </ApolloProvider>
    );
  }
}
