import jwtDecode from "jwt-decode";
import fetch from "node-fetch";
import login from "../graphql/mutations/auth";
import mixpanel from "../mixpanel";
require("dotenv");

const auth = {
  authState: session => {
    session = auth.session();
    if (session !== "") {
      return session;
    } else {
      return null;
    }
  },
  session: () => {
    const session = window.localStorage.getItem("session");
    if (session !== "") {
      return session;
    } else {
      return undefined;
    }
  },
  decodeToken: token => {
    const decoded = jwtDecode(token);
    return decoded;
  },
  loginWithEmailAndPassword: async body => {
    const { email, password } = JSON.parse(body);
    const mutation = await login(email, password);
    const session = mutation.data.createLogin.session;
    const sessionSuccess = session.code === 202;
    const userDoesNotExits = session.code === 404;
    const wrongPassword = session.code === 400;
    const token = session.token;
    if (sessionSuccess) {
      const { email, name, rol, id, emailConfirmed } = auth.decodeToken(token);
      const session = {
        token,
        user: { email, name, emailConfirmed, userRol: rol, id },
      };
      localStorage.setItem("session", JSON.stringify(session));
      mixpanel.people.set({
        Rol: session.user.userRol,
        Name: session.user.name,
      });
      mixpanel.identify(session.user.email);
      const pathInit = auth.getRedirectPath(rol);
      window.location = pathInit;
    }
    if (userDoesNotExits) {
      alert("El usuario no existe");
    }
    if (wrongPassword) {
      alert("Contraseña incorrecta");
    }
  },
  getRedirectPath: rol => {
    const PATH = {
      CONTRACTOR: "/contratistas",
      CONTRACTOR_RESPONSIBLE: "/contratistas",
      MANAGER: "/clientes",
      ADMINISTRATOR: "/clientes",
      PURCHASES_RESPONSIBLE: "/compras",
      WAREHOUSE_RESPONSIBLE: "/inventarios",
      TECHNICIAN: "/compras",
      ACCOUNT_BALANCE_RESPONSIBLE: "/estado-de-cuenta",
      ADMINISTRATIVE_COORDINATOR: "/clientes",
      ZONE_COORDINATOR: "/compras"
    };
    return PATH[rol];
  },
  refresh: () => {
    const init = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: `refresh_token=${
        window.localStorage.session.token.refresh
        }&grant_type=refresh_token`,
    };
    return fetch(process.env.REACT_APP_AUTH_URL_REFRESH, init)
      .then(res => res.json())
      .then(({ token_type, access_token, expires_in, refresh_token }) => {
        localStorage.access_token = access_token;
        localStorage.refresh_token = refresh_token;
        return access_token;
      });
  },
  onAthorize: () => { },
  onDeauthorize: () => { },
};

export default auth;
