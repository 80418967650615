import React from "react";
import "./style.css";

export default class Body extends React.Component {
  render() {
    return (
      <div id={this.props.id} className="body-conatiner">
        {this.props.children}
      </div>
    );
  }
}
