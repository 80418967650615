import React from "react";
import PropTypes from "prop-types";
import { IoMdCreate } from "react-icons/io";

import { Button, Form, Input, Modal } from "semantic-ui-react";
import { styles } from "../../theme";

class UpdateContactModal extends React.Component {
  state = {
    modalOpen: false
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  save = () => {
    this.props.onUpdate();
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <Modal
        dimmer="blurring"
        style={styles.modal_small}
        trigger={
          <Button onClick={this.handleOpen} style={styles.button_action}>
            <IoMdCreate />
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="tiny"
      >
        <Modal.Header
          style={{
            height: "60px",
            width: "100%",
            marginBottom: "20px",
            textAlign: "left"
          }}
        >
          Editar Contacto
        </Modal.Header>
        <Modal.Content style={styles.modal_content}>
          <Modal.Description>
            <Form style={styles.form_group}>
              <Form.Field
                control={Input}
                label="Nombre y Apellido"
                name="Name"
                placeholder={this.props.data.Name}
                onChange={this.props.handleChange}
                size="mini"
              />

              <Form.Field
                control={Input}
                label="Correo electrónico"
                name="Email"
                placeholder={this.props.data.Email}
                onChange={this.props.handleChange}
                size="mini"
              />
              <Form.Field
                control={Input}
                label="Telefono celular"
                name="Phone"
                placeholder={this.props.data.Phone}
                onChange={this.props.handleChange}
                size="mini"
              />
              <Form.Field
                control={Input}
                label="Telefono de oficina"
                name="Phone_Office"
                placeholder={this.props.data.Phone_Office}
                onChange={this.props.handleChange}
                size="mini"
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div style={styles.modal_button_holder}>
            <div>
              <Button
                name="update-supply-btn"
                style={styles.button_save}
                onClick={this.save}
              >
                Guardar
              </Button>
              <Button onClick={this.handleClose} style={styles.button_cancel}>
                Cancelar
              </Button>
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

UpdateContactModal.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func
};

export default UpdateContactModal;
