import gql from "graphql-tag";
import { client } from "../../apollo-config";

const updateReceptionStatus = async (id, status) => {
  // Fix cli wrong map
  const mutation = await client.mutate({
    mutation: gql`
 
        mutation {
            updatePurchaseOrder(
                where: { id: "${id}" }
                data: { data: { update: { paymentStatus:${status} } } }
            ) {
                id
            }
        }
        
    `,
  });
  return mutation;
};

export default updateReceptionStatus;
