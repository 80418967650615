import gql from "graphql-tag";

const getRequisitionById = gql`
  query getRequisitionById($id: ID!) {
    requisition(where: { id: $id }) {
      folio
      convertedAt: wasUpdatedAt
      suppliesQty
      folio
      currency
      wasUpdatedAt
      deliveryWarehouse
      deliveryDate
      authorizedBy
      revisedBy
      petitioner
      notes
      iva
      project {
        id
        cli
        name
        address
        responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
          id,
          name
        }
        residents: users(where: { userRol_in: [TECHNICIAN] }){
          id,
          name
        }
      }
      vendor {
        id
        commercialName
        contacts {
          name
        }
      }
      supplies {
        id
        name
        insID
        description
        units
        quantity
        purchasePrice
      }
      paymentConditions
      subtotal
      total
      retention
      discount
      retentionPercentage
      discountPercentage
      amount
      currency
    }
  } 
`;
export default getRequisitionById;
