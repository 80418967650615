import React from "react";
import { ApolloProvider } from "react-apollo";
import { Button, Form, Header, Input, Segment, Table, TextArea, Grid, Icon, Modal } from "semantic-ui-react";
import CurrencyInput from "react-currency-input";
import moment from "moment";
import { client } from "../../../graphql/apollo-config";
import { store as _store } from "../../../store/store";
import { PurchasesSupplies, Timeline } from "../../Organisms";
import OnLoading from "../../Molecules/OnLoading";
import { IoIosDownload, IoMdEye } from "react-icons/io";
import { FormHeader } from "../../Molecules";

export default class DetailReq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subtotal: 0.0,
      total: 0.0,
      authorizedBy: "",
      revisedBy: "",
      applicant: "",
      notes: "",
      cli: "",
      vendor: "",
      status: "IS_REQ_REVISION",
      supplies: [],
      errors: {},
      currencyOptions: [
        { key: "mxn", text: "MXN", value: "MXN" },
        { key: "usd", text: "USD", value: "USD" },
      ],
      paymentConditionsOptions: [
        { key: "credit", text: "Crédito", value: "CREDIT" },
        { key: "one-time", text: "Contado", value: "CASH" },
      ],
      statusOptions: [
        {
          key: "revision",
          text: "Revisión",
          value: "IS_REQ_REVISION",
        },
        { key: "revised", text: "Revisado", value: "IS_REQ_REVISED" },
        {
          key: "authorized",
          text: "Autorizado",
          value: "IS_REQ_AUTHORIZED",
        },
        {
          key: "cancelled",
          text: "Cancelado",
          value: "IS_REQ_CANCEL",
        },
      ],
      showForm: true,
      isLoading: true,
      modalSupply: false,
      toggles: {
        supplyQuickCreateToggle: false
      }
    };
  }

  componentWillMount() {
    const { isReq, isEdit, onCancel, data } = this.props;

    const {
      folio,
      notes,
      wasUpdatedAt,
      applicant,
      subtotal,
      total,
      project,
      vendor,
      iva,
      currency,
      paymentConditions,
      createdAt,
      deliveryDate,
      deliveryWarehouse,
      status,
      supplies,
      authorizedBy,
      revisedBy,
      petitioner,
      retention,
      discount,
      discountPercentage,
      retentionPercentage,
      amount,
      id,
    } = data;

    const _responsible = data.project.residents.map(user => user.name);
    const _resident = data.project.responsibles.map(user => user.name);

    this.setState(prevState => ({
      ...prevState,
      isReq,
      isEdit,
      onCancel,
      folio,
      notes,
      applicant,
      subtotal,
      project,
      resident: _resident,
      responsible: _responsible,
      address: project.address,
      vendor,
      supplies,
      currency,
      paymentConditions,
      deliveryDate,
      createdAt,
      deliveryWarehouse:
        deliveryWarehouse !== "undefined"
          ? JSON.parse(deliveryWarehouse).name
          : "",
      status,
      total,
      iva,
      wasUpdatedAt,
      authorizedBy,
      revisedBy,
      petitioner,
      retention,
      discount,
      discountPercentage,
      retentionPercentage,
      amount,
      id,
      isLoading: false
    }));
  }

  getStore = () => {
    return _store.subscribe(() => {
      let _quickCreate = _store.getState().navigationReducer.toggle;
      this.setState(data => ({ ...data, quickCreate: _quickCreate }));
    });
  };

  getTotalSupply = (a, b) => {
    if (a && b) {
      const result = parseFloat(a) * parseFloat(b);
      return `$${result.toFixed(2)}`;
    } else {
      return 0;
    }
  };

  openSupply = index => {
    const supply = this.state.supplies[index];
    const currentQty = supply.quantity;
    this.setState(prevState => ({
      ...prevState,
      modalSupply: true,
      supply,
      currentQty,
    }))
  };

  closeSupply = () => {
    this.setState(prevState => ({
      ...prevState,
      modalSupply: false
    }))
  }

  getTotalSupply = (a, b) => {
    if (a && b) {
      return `$${(a * b).toFixed(2)}`;
    } else {
      return 0;
    }
  };

  render() {
    this.getStore();
    return (
      <ApolloProvider client={client}>
        <Segment raised className="Modal">
          <Header as="h3" dividing className="Modal__Header">
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column className="Modal__Header-Title">
                  {this.state.showForm
                    ? "Detalle de requisición"
                    : "Historial de requisición"}
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button.Group icon>
                    <Button
                      onClick={() =>
                        this.setState(data => ({ ...data, showForm: true }))
                      }
                      active={this.state.showForm ? true : false}
                    >
                      <Icon name="list" />
                    </Button>
                    <Button
                      onClick={() =>
                        this.setState(data => ({ ...data, showForm: false }))
                      }
                      active={this.state.showForm ? false : true}
                    >
                      <Icon name="history" />
                    </Button>
                  </Button.Group>
                  {(this.state.status === "IS_REQ_APPROVED" || this.state.status === "IS_REQ_REVISED") && (
                    <Button
                      className="Modal__Form-ButtonAdd"
                      id="download-pdf-btn"
                      onClick={() => window.open(`/pdf/${this.state.id}`, "_blank")}
                      style={{ marginLeft: "10px" }}
                    >
                      <IoIosDownload /> &nbsp; Descargar
                    </Button>
                  )}

                  <Button
                    id="cancel-req-detail-btn"
                    onClick={this.props.onCancel}
                    style={{ marginLeft: "10px" }}
                  >
                    Cancelar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>

          {this.state.isLoading
            ? <OnLoading loading={this.state.isLoading} />
            : this.state.showForm ? (
                <>
                  <Form className="Modal__Form">
                    <Form.Group widths="equal">
                      <Form.Field
                        name="folio"
                        control={Input}
                        type="number"
                        label="FOLIO DE REQUISICIÓN"
                        value={this.state.folio}
                        placeholder={this.state.folio}
                        readOnly
                      />
                      <Form.Field
                        name="applicant"
                        control={Input}
                        label="ELABORÓ"
                        value={
                          this.state.applicant === "undefined"
                            ? ""
                            : this.state.applicant
                        }
                        readOnly
                      />
                      <Form.Field
                        label="SOLICITANTE"
                        name="petitioner"
                        control={Input}
                        value={this.state.petitioner}
                        readOnly
                      />
                      <Form.Field
                        name="subtotal"
                        control={CurrencyInput}
                        label="IMPORTE"
                        min={0}
                        value={parseFloat(this.state.subtotal).toFixed(2)}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        name="cli"
                        control={Input}
                        label="NÚM. DE PRESUPUESTO (CLI)"
                        value={this.state.project.cli}
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        name="vendor"
                        control={Input}
                        label="PROVEEDOR"
                        value={
                          this.state.vendor ? this.state.vendor.commercialName : ""
                        }
                        options={this.state.vendorOptions}
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        name="authorizedBy"
                        control={Input}
                        label="AUTORIZÓ"
                        value={
                          this.state.authorizedBy === "undefined"
                            ? ""
                            : this.state.authorizedBy
                        }
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        label="DESCUENTO"
                        name="discount"
                        type="number"
                        min={0}
                        control={Input}
                        placeholder="0%"
                        value={this.state.discountPercentage.toFixed(2)}
                        width={5}
                        readOnly
                      />
                      <Form.Field
                        label="&nbsp;"
                        name="discountDecimal"
                        min={0}
                        control={CurrencyInput}
                        placeholder="$0.00"
                        value={this.state.discount.toFixed(2)}
                        readOnly
                        width={5}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        name="siteName"
                        control={Input}
                        label="NOMBRE DE PROYECTO"
                        value={this.state.project.name}
                        readOnly
                      />
                      <Form.Field
                        name="resident"
                        control={Input}
                        label="RESIDENTE"
                        value={this.state.resident}
                        readOnly
                      />
                      <Form.Field
                        name="revisedBy"
                        control={Input}
                        label="REVISÓ"
                        value={
                          this.state.revisedBy === "undefined"
                            ? ""
                            : this.state.revisedBy
                        }
                        readOnly
                      />
                      <Form.Field
                        name="subtotal"
                        control={CurrencyInput}
                        label="SUBTOTAL"
                        min={0}
                        value={parseFloat(this.state.amount).toFixed(2)}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        name="responsible"
                        control={Input}
                        label="RESPONSABLE DEL PROYECTO"
                        value={this.state.responsible}
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        name="deliveryDate"
                        control={Input}
                        type="text"
                        label="TIEMPO DE ENTREGA"
                        width={10}
                        value={
                          this.state.deliveryDate === "undefined"
                            ? ""
                            : this.state.deliveryDate
                        }
                        readOnly
                      />
                      <Form.Field
                        name="currency"
                        control={Input}
                        value={this.state.currency}
                        label="MONEDA"
                        readOnly
                        width={10}
                      />
                      {/**iva */}
                      <Form.Field
                        label="IVA"
                        name="iva"
                        type="number"
                        min={0}
                        value={this.state.iva}
                        control={Input}
                        action={{ basic: true, content: "%" }}
                        labelPosition="right corner"
                        readOnly
                        width={10}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        name="wasUpdatedAt"
                        control={Input}
                        type="text"
                        label="FECHA DE CREACIÓN"
                        value={moment(this.state.wasUpdatedAt)
                          .add(1, "day")
                          .format("DD/MM/YYYY")}
                        readOnly
                        width={10}
                      />
                      <Form.Field
                        name="status"
                        control={Input}
                        label="ESTATUS"
                        value={
                          this.state.status === "IS_REQ_REVISION"
                            ? "Revisión"
                            : this.state.status === "IS_REQ_REVISED"
                            ? "Revisada"
                            : this.state.status === "IS_REQ_CANCEL"
                            ? "Cancelada"
                            : this.state.status === "IS_REQ_APPROVED"
                            ? "Aprobada"
                            : ""
                        }
                        options={this.state.statusOptions}
                        readOnly
                        width={10}
                      />
                      <Form.Field
                        name="paymentConditions"
                        control={Input}
                        label="CONDICIONES DE PAGO"
                        options={this.state.paymentConditionsOptions}
                        value={
                          this.state.paymentConditions === "CASH"
                            ? "Contado"
                            : "Crédito"
                        }
                        width={10}
                        readOnly
                      />
                      <Form.Field
                        label="RETENCIÓN"
                        name="retention"
                        type="number"
                        min={0}
                        control={Input}
                        placeholder="0.00%"
                        value={this.state.retentionPercentage.toFixed(2)}
                        readOnly
                        width={5}
                        autoComplete="off"
                      />
                      <Form.Field
                        label="&nbsp;"
                        name="retentionDecimal"
                        min={0}
                        control={CurrencyInput}
                        placeholder="$0.00"
                        value={this.state.retention.toFixed(2)}
                        width={5}
                        autoComplete="off"
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        name="deliveryWarehouse"
                        control={Input}
                        label="ALMACÉN"
                        width={10}
                        value={
                          this.state.deliveryWarehouse === "undefined"
                            ? ""
                            : this.state.deliveryWarehouse
                        }
                        readOnly
                      />
                      <Form.Field width={10} />
                      <Form.Field width={10} />
                      <Form.Field
                        name="total"
                        control={CurrencyInput}
                        label="TOTAL"
                        value={parseFloat(this.state.total).toFixed(2)}
                        width={10}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Field
                      label="UBICACIÓN DEL PROYECTO"
                      name="address"
                      value={
                        this.state.address !== "undefined" ? this.state.address : ""
                      }
                      control={TextArea}
                      readOnly
                    />
                    <Form.Field
                      name="notes"
                      control={TextArea}
                      label="NOTAS"
                      value={
                        this.state.notes === "undefined"
                          ? ""
                          : unescape(this.state.notes)
                      }
                      readOnly
                    />
                  </Form>
                  <Header as="h3" dividing className="Modal__Form-Header">
                    Insumos
                  </Header>
                  <Table
                    basic="very"
                    compact="very"
                    textAlign="center"
                    className="Modal__Form-Table"
                  >
                    <Table.Header className="Modal__Form-Table-Header">
                      <Table.Row>
                        <Table.HeaderCell>Clave del insumo</Table.HeaderCell>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell>Descripción</Table.HeaderCell>
                        <Table.HeaderCell>Unidad de medida</Table.HeaderCell>
                        <Table.HeaderCell>Precio unitario</Table.HeaderCell>
                        <Table.HeaderCell>Cantidad</Table.HeaderCell>
                        <Table.HeaderCell>Total</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body className="Modal__Form-Table-Body">
                      {this.state.supplies.map((supply, index) => {
                        const {
                          insID,
                          name,
                          description,
                          quantity,
                          units,
                          purchasePrice,
                        } = supply;
                        return (
                          <Table.Row key={index}>
                            <Table.Cell>{insID}</Table.Cell>
                            <Table.Cell>{unescape(name)}</Table.Cell>
                            <Table.Cell
                              style={{
                                maxWidth: "300px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {unescape(description)}
                            </Table.Cell>
                            <Table.Cell>{units}</Table.Cell>
                            <Table.Cell>
                              $
                              {parseFloat(purchasePrice)
                                .toFixed(2)
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Table.Cell>
                            <Table.Cell>{quantity}</Table.Cell>
                            <Table.Cell>
                              {this.getTotalSupply(quantity, purchasePrice)
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Table.Cell>
                            <Table.Cell>
                              <Button.Group basic>
                                <Button
                                  icon
                                  onClick={() => this.openSupply(index)}
                                >
                                  <IoMdEye />
                                </Button>
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <Modal
                    className="Modal__Form-SubModal"
                    centered={false}
                    open={this.state.modalSupply}
                    onClose={this.closeSupply}
                    header={
                      <FormHeader
                        saveId="save-edit-req-supply-btn"
                        cancelId="cancel-edit-req-supply-btn"
                        text="Detalle del Insumo"
                        onCancel={this.closeSupply}
                        onSave={null}
                      />
                    }
                    content={
                      <PurchasesSupplies
                        data={this.state}
                        getTotalSupply={this.getTotalSupply}
                        noEditPrice={true}
                        handleChangeSupply={()=> null}
                      />
                    }
                    size="tiny"
                    dimmer="blurring"
                  />
                </>
              ) : <Timeline reqId={this.state.id} />
          }
        </Segment>
      </ApolloProvider>
    );
  }
}
