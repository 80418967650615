import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Table } from "semantic-ui-react";
import { styles } from "../../theme";

const ContactsTableHeader = () => (
  <Table.Header style={styles.table_header}>
    <Table.Row>
      <Table.HeaderCell style={styles.table_header_text}>
        Nombre del contacto&nbsp;
        <IoIosArrowDown />
      </Table.HeaderCell>
      <Table.HeaderCell style={styles.table_header_text}>
        Correo electrónico&nbsp;
        <IoIosArrowDown />
      </Table.HeaderCell>
      <Table.HeaderCell style={styles.table_header_text}>
        Teléfono celular&nbsp;
        <IoIosArrowDown />
      </Table.HeaderCell>
      <Table.HeaderCell style={styles.table_header_text}>
        Telefono oficina&nbsp;
        <IoIosArrowDown />
      </Table.HeaderCell>
      <Table.HeaderCell />
    </Table.Row>
  </Table.Header>
);

export default ContactsTableHeader;
