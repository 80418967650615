import React from "react";
import { Query } from "react-apollo";
import { Form, Input, Grid, Header, Segment, Button } from "semantic-ui-react";
import GET_WAREHOUSE from "../../../graphql/queries/catalogs/getWarehousDetail";
import { withRouter } from "react-router-dom";
import OnLoading from "../../Molecules/OnLoading";

export default withRouter(
  class DetailWarehouseContainer extends React.Component {
    onCancel = () => this.props.onCancel();

    render() {
      return (
        <Segment raised className="Modal">
          <Header as="h3" dividing className="Modal__Header">
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column
                  textAlign="left"
                  className="Modal__Header-Title"
                >
                  Detalle de almacén
                        </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button
                    id="cancel-vendor-detial-btn"
                    onClick={this.props.onCancel}
                  >
                    Cancelar
                          </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>{" "}
          <Query
            query={GET_WAREHOUSE}
            variables={{ id: this.props.data.id }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, refetch, networkStatus }) => {

              if (loading || error || !data) {
                return <OnLoading loading={loading} error={error} />
              }

              return (
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="NOMBRE DE ALMACÉN"
                      name="name"
                      value={data.warehouse.name}
                      readOnly
                    />
                    <Form.Field
                      control={Input}
                      label="CALLE"
                      name="street"
                      value={data.warehouse.street}
                    />
                    <Form.Field
                      control={Input}
                      label="CIUDAD"
                      name="city"
                      value={data.warehouse.city}
                    />
                    <Form.Field
                      control={Input}
                      label="PAÍS"
                      name="country"
                      value={data.warehouse.country}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="ESTADO"
                      name="state"
                      value={data.warehouse.state}
                    />
                    <Form.Field
                      control={Input}
                      label="NOMBRE DE CONTACTO"
                      name="contactName"
                      value={data.warehouse.contactName}
                      readOnly
                    />
                    <Form.Field
                      control={Input}
                      label="NÚMERO DE CONTACTO"
                      name="contactNumber"
                      value={data.warehouse.contactPhone}
                      readOnly
                    />
                    <Form.Field
                      control={Input}
                      label="CORREO ELECTRÓNICO"
                      name="email"
                      value={data.warehouse.contactEmail}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      label="CÓDIGO POSTAL"
                      name="postalCode"
                      type="number"
                      width="4"
                      value={data.warehouse.postalCode}
                      readOnly
                    />
                    <Form.Field
                      control={Input}
                      label="ESTATUS"
                      width="4"
                      value={
                        data.warehouse.status === "ACTIVE"
                          ? "Activo"
                          : "Inactivo"
                      }
                      readOnly
                    />
                  </Form.Group>
                </Form>
              );
            }}
          </Query>
        </Segment>
      );
    }
  }
);
