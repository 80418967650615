import gql from "graphql-tag";

const createWarehouse = gql`
    mutation CreateWarehouse($data: WarehouseCreateInput!){
        createWarehouse(data: $data) {
            id
            name
            street
            city
            state
            country
            postalCode
            contactName
            contactEmail
            contactPhone
            status
        }
    }
`

export default createWarehouse;