import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { format } from "../../../helpers";

const CREATE_ADITIVE_CONTRACT = async({
  id,
  idContract,
  consecutive,
  total,
  invoiced,
  paided,
  currency,
  startDate,
  finishDate,
  description,
  amountAditives,
}, { GET_ADITIVES_CONTRACT, QUERY_CONTRACTS }) => {
  const mutation = await client.mutate({
    mutation: gql `
      mutation {
        updateContract(
          where: { id: "${idContract}" }
          data: {
            amountAditives: ${parseFloat(amountAditives)}
            aditives: {
              upsert: {
                where: { id: "${id}" }
                update: {
                  consecutive: "${consecutive}"
                  total: ${parseFloat(total)}
                  invoiced: ${parseFloat(invoiced)}
                  paided: ${parseFloat(paided)}
                  currency: ${currency}
                  startDate: "${format.date(startDate, 2)}"
                  finishDate: "${format.date(finishDate, 2)}"
                  description: "${description}"
                }
                create: {
                  consecutive: "${consecutive}"
                  total: ${parseFloat(total)}
                  invoiced: ${parseFloat(invoiced)}
                  paided: ${parseFloat(paided)}
                  currency: ${currency}
                  startDate: "${format.date(startDate, 2)}"
                  finishDate: "${format.date(finishDate, 2)}"
                  description: "${description}"
                }
              }
            }
          }
        ) {
          id
          aditives {
            id
            finishDate
          }
        }
      }
    `,
    refetchQueries: [
      { query: GET_ADITIVES_CONTRACT, variables: { id: idContract } },
      { query: QUERY_CONTRACTS }
    ]
  });
  return mutation;
}

export default CREATE_ADITIVE_CONTRACT;
