import React from "react";
import { Button, ButtonGroup } from "semantic-ui-react";
import DataTable from "../../Organisms/DataTable";
import { climasTemplate } from "../../Organisms/DataTable/climasTemplate";
import moment from "moment";
import { IoMdEye } from "react-icons/io";

const ProjectProgressQuery = (props) => {
  const { filter, filterVariables, onOrderChange } = props;

  const parseDate = (column, data) => {
    const date = data[column.props.name];

    return moment(date).format("DD/MM/YYYY");
  };

  const tasksExists = tasks => {
    return tasks ? (tasks.length > 0) : 0;
  };

  const getAllTaks = tasks => {
    return tasks.length;
  };

  const getCompletedTasks = tasks => {
    if (tasksExists(tasks)) {
      const completed = [];
      tasks.map(({ progress }) => {
        const condition = progress === 1;
        if (condition) {
          return completed.push(progress);
        }
      });
      return completed.length;
    } else {
      return "-";
    }
  };

  const getUncompleteTasks = tasks => {
    if (tasksExists(tasks)) {
      const completed = [];
      tasks.map(({ progress }) => {
        const condition = progress !== 1;
        if (condition) {
          return completed.push(progress);
        }
      });
      return completed.length;
    } else {
      return "-";
    }
  };

  const getTotalProgress = tasks => {
    if (tasksExists(tasks)) {
      const completed = getCompletedTasks(tasks);
      const target = getAllTaks(tasks);
      const progress = Math.ceil((completed / target) * 100);
      return `${progress}%`;
    } else {
      return "-";
    }
  };

  return (
    <DataTable
      onOrderChange={onOrderChange}
      variables={filterVariables}
      template={climasTemplate}
      query={filter}
      itemsKey="projects">
      <DataTable.Column
        name="name"
        label="Nombre del proyecto"
        sortable />
      <DataTable.Column
        name="cli"
        label="Presupuesto CLI"
        sortable />
      <DataTable.Column
        name="startDate"
        label="Fecha de inicio"
        content={(row, column, index) => parseDate(column, row)}
        sortable />
      <DataTable.Column
        name="finishDate"
        label="Fecha de término"
        content={(row, column, index) => parseDate(column, row)}
        sortable />
      <DataTable.Column
        name="task"
        label="Tareas completadas"
        content={({ tasks }) => getCompletedTasks(tasks)}/>
      <DataTable.Column
        name="task"
        label="Tareas restantes"
        content={({ tasks }) => getUncompleteTasks(tasks)}/>
      <DataTable.Column
        name="task"
        label="Avance Total"
        content={({ tasks }) => getTotalProgress(tasks)}/>
      <DataTable.Column
        name="actions"
        label=" "
        content={
          ({ id }) =>
            <ButtonGroup basic>
              <Button
                onClick={() => {
                  window.location = `/avance/${id}`;
                }}
                icon
              >
                <IoMdEye />
              </Button>
            </ButtonGroup>
        } />
    </DataTable>
  )
}

export default ProjectProgressQuery;
