import gql from "graphql-tag";
import { client } from "../../apollo-config";

const GET_CONTRACT_AB = async id => {
  const { data } = await client.query({
    query: gql`
      {
        contractAccountBalance(where: { id: "${id}" }) {
          id
          IDFolio
          realFinishDate
          client {
            id
          }
          currency
          project {
            id
            key: id
            text: name
            value: id
            cli
          }
        }
      }`
  });

  return data;
};
export default GET_CONTRACT_AB;
