import gql from "graphql-tag";
import { client } from "../../apollo-config";

const connectReqToProject = async (requisitionOrderID, projectID) => {
const mutation = await client.mutate({
  mutation: gql`
    mutation {
      updateProject(data: {
        requisitions:{
          connect:{
            id:"${requisitionOrderID}"
          }
        }
      } where:{id:"${projectID}"}) {
        id
      }
    }
  `,
});
  return mutation;
};

export default connectReqToProject;
