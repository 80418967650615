import { is } from "./is"

export function useCurrentUser() {
  const sessionJSON = window.localStorage.getItem("session")

  const session = JSON.parse(sessionJSON)

  const currentUser = {
    ...session,
    is: is(session.user)
  }

  return { currentUser }
}
