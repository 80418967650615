import React from "react";
import {
  Accordion,
  Image,
  Icon,
  Grid,
  Form,
  Button,
  Input,
  Loader
} from "semantic-ui-react";
import { PROJECT_TASKS } from "../../../graphql/queries/projectProgress";
import { DELETE_TASK_IMAGE, CREATE_IMAGE } from "../../../graphql/mutations/projectProgress";
import ImageDetail from "./components/detail";
import OnLoading from "../../Molecules/OnLoading";
import { bugsnagClient } from "../../../bugsnag";
import "./styles.css";

export default class TasksGalleryAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: window.location.pathname.split("a/")[1],
      tasks: [],
      gallery: [],
      project: { name: "", tasks: [], firstLevelTasks: [], nested: {} },
      loading: true,
      showDetail: false,
      openTask: { images: [], taskName: "", activeIndex: 0 },
      loadingImg: false
    };
  }

  componentDidMount() {
    this.getTasksGallery();
  }

  getTasksGallery = async () => {
    const result = await PROJECT_TASKS(this.state.projectId);
    const tasks = result.data.project.tasks;
    const rootTasks = this.getRootTasks(tasks); // Get all root tasks (parent = 0)
    const firstLevelTasks = this.getFirstLevelTasks(tasks, true); // parent = 1 | if task has children we need to be able to create an accordion

    const project = {
      name: rootTasks[0] ? rootTasks[0].title : "",
      tasks: tasks,
      firstLevelTasks,
    };

    this.setState(prevState => ({ ...prevState, tasks, project, loading: false }));
  };

  rootPanels = title => {
    return [
      {
        key: title,
        title: title,
        content: {
          content: this.ProjectRoot(this.state.project.firstLevelTasks),
        },
      },
    ];
  };

  ProjectRoot = firstLevelTasks => {
    return (
      <div>
        <Accordion.Accordion defaultActiveIndex={0} panels={firstLevelTasks} />
      </div>
    );
  };

  deleteImage = async (e, imageId, taskId) => {
    e.stopPropagation();

    try {
      await DELETE_TASK_IMAGE(
        this.state.projectId,
        taskId,
        imageId
      );
      this.setState(prevState => ({ ...prevState, loading: true }))
      this.getTasksGallery();
    } catch (err) {
      bugsnagClient.notify(err);
    }
  };

  renderInputFile = (taskId) => {
    let fileInput = null;

    return (
      <Form.Field style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          basic 
          content={!this.state.loadingImg ? "Agregar archivos" : "Cargando archivo"}
          icon={!this.state.loadingImg ? "plus" : "cloud upload"}
          className="Modal__Form-SubModal-Form-ButtonAddOutline"
          onClick={() => fileInput.inputRef.click()}
        />
        <Input
          type="file"
          key={Date.now()}
          hidden
          ref={element => { fileInput = element }}
          onChange={e => this.handleUploapFile(e, taskId)}
          style={{ display: "none" }}
          accept={["image/png","image/jpeg"]}
        />
      </Form.Field>
    )
  }

  NestedAccordion = (panes, images, taskId, taskName) => {
    return images.length > 0 ? (
      <div>
        <Grid columns="equal">
          {images.map(({ url, timestamp, id }, index) => {            
            return (
              <Grid.Column width={2} key={index} textAlign="center">
                <Image
                  src={url}
                  fluid
                  style={{ 
                    cursor: "pointer", 
                    height: "120px", 
                    maxHeight: "120px",
                    overflow: "hidden",
                    marginBottom: "10px"
                  }}
                  onClick={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      showDetail: true,
                      openTask: { images, taskName, activeIndex: index },
                    }))
                  }
                  label={{
                    content: (
                      <Icon
                        name="close"
                        color="grey"
                        onClick={(e) => this.deleteImage(e, id, taskId)}
                        style={{ cursor: "pointer" }}
                      />
                    ),
                    corner: "right",
                  }}
                  rounded
                />
                <p>
                  {timestamp
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("/")}
                </p>
              </Grid.Column>
            );
          })}
        </Grid>
        {this.renderInputFile(taskId)}
        <Accordion.Accordion panels={panes} />
      </div>
    ) : (
      <div>
        <p>Aun no hay evidencia fotográfica</p>
        {this.renderInputFile(taskId)}
        <Accordion.Accordion panels={panes} />
      </div>
    );
  };

  // Get tasks with parent 0
  getRootTasks = tasks => {
    const rootTasks = [];
    tasks.map((task, index) => {
      if (task.parent === 0) {
        rootTasks.push({
          key: index,
          title: task.text,
        });
      }
      return rootTasks;
    });
    return rootTasks;
  };

  // Get the first level tasks (parent = 1)
  getFirstLevelTasks = (tasks, children) => {
    const firstLevelTasks = [];
    tasks.map(task => {
      if (task.parent === 1) {
        firstLevelTasks.push({
          key: task.index,
          title: task.text,
          content: {
            content: this.NestedAccordion(
              this.getMyChildren(tasks, task.index),
              task.gallery,
              task.id,
              task.text
            ),
          },
          gallery: task.gallery,
          taskId: task.id,
        });
      }
      return firstLevelTasks;
    });
    return firstLevelTasks;
  };

  // Look for childrens
  getMyChildren = (tasks, parent) => {
    const childrenTasks = [];
    tasks.map(task => {
      if (task.parent !== 1 && task.parent !== 0 && parent === task.parent) {
        childrenTasks.push({
          key: task.index,
          title: task.text,
          content: {
            content: this.NestedAccordion(
              this.getMyChildren(tasks, task.index),
              task.gallery,
              task.id,
              task.text
            ),
          },
          gallery: task.gallery,
          taskId: task.id,
        });
        return childrenTasks;
      }
    });
    return childrenTasks;
  };

  handleUploapFile = async (e, taskId) => {
    const file = e.target.files[0];
    const form = new FormData();

    if (e.target.files.length > 0) {
      if (!e.target.accept.includes(file.type))
        return alert("Solo se permiten archivos con extensión JPG y PNG");

        try {
          this.setState(prevState => ({ ...prevState, loadingImg: true }))
          this.getTasksGallery();
          form.append("file", file);

          const res = await fetch(process.env.REACT_APP_S3, {
            method: "POST",
            body: form,
          });
  
          if (res.ok) {
            const uploaded = await res.json();
            const name = file.name.slice(0, -4);

            const data = {
              url: uploaded.imageUrl,
              timestamp: new Date().toISOString(),
              alt: name
            };
            
            await CREATE_IMAGE(this.state.projectId, taskId, data);
            this.setState(prevState => ({ ...prevState, loadingImg: false, loading: true }))
            this.getTasksGallery();
          }
        } catch (err) {
          this.setState(prevState => ({ ...prevState, loadingImg: false, loading: true }))
          this.getTasksGallery();
          bugsnagClient.notify(err);
        }
    }
  }

  render() {
    const { loading } = this.state;
    
    return !loading ? (
      <div>
        <Accordion
          defaultActiveIndex={0}
          panels={this.rootPanels(this.state.project.name)}
          styled
        />
        <ImageDetail
          open={this.state.showDetail}
          task={this.state.openTask}
          closeModal={() =>
            this.setState(prevState => ({
              ...prevState,
              showDetail: false,
              openTask: { images: [], taskName: "", activeIndex: 0 },
            }))
          }
        />
      </div>
    ) : (
      <OnLoading loading={loading} />
    );
  }
}
