export default {
  revised: [
    {
      field: "id",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "applicant",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "createdAt",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "currency",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryWarehouse",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "folio",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "paymentConditions",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "petitioner",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "project",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "status",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "subtotal",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "total",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "vendor",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "wasUpdatedAt",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
  approve: [
    {
      field: "id",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "applicant",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "createdAt",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "currency",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "deliveryWarehouse",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "folio",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "paymentConditions",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "petitioner",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "project",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "status",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "subtotal",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "total",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "vendor",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "wasUpdatedAt",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
    {
      field: "revisedBy",
      method: "isEmpty",
      validWhen: false,
      message: "",
    },
  ],
};
