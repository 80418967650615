import React, { Component } from "react";
import "./style.css";

export default class Button extends Component {
  render() {
    return (
      <button
        id={this.props.id}
        data-btn={this.props.dataBtn}
        onClick={this.props.onClick}
        className={this.props.className}
        data={this.props.data}
        testid={this.props.testid}
      >
        {this.props.text}
      </button>
    );
  }
}
