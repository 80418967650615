import React from "react";
import { Header, Grid } from "semantic-ui-react";
import { PoNavigation } from "../../Molecules";
import { routes } from "./routes";

class ContractAccountBalance extends React.Component {
  constructor(props) {
    super(props);
    const { item: ActiveRouteItem, routeName: activeRoute } = routes[0]
    this.state = {
      myRoute: undefined,
      activeRoute,
      ActiveRouteItem
    }
  }

  navigateTo(route) {
    const { item, routeName } = route;
    this.setState({
      activeRoute: routeName,
      ActiveRouteItem: item
    });
  }

  renderActiveRouteItem() {
    const { state } = this.props.location || undefined

    if (state) {
      const { item, routeName } = routes.find(route => route.routeName === state.myRoute)
      this.setState({ activeRoute: routeName, ActiveRouteItem: item });
      this.props.history.replace({ ...this.props.location, state: undefined })
    }

    const { ActiveRouteItem } = this.state;
    return ActiveRouteItem && <ActiveRouteItem />
  }

  render() {
    return(
      <Grid columns="equal">
        <Grid.Row className="Wrapper__Nav">
          <Grid.Column floated="left">
            <Header as="h1" className="Wrapper__Nav-Title">
              Estado de Cuenta
            </Header>
          </Grid.Column>
          <Grid.Column floated="right">
            <PoNavigation
              className="navigation-right"
              routes={routes}
              activeRoute={this.state.activeRoute}
              navigateTo={route => this.navigateTo(route)}
              style={{ float: "right" }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{this.renderActiveRouteItem()}</Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default ContractAccountBalance;

