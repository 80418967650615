import gql from "graphql-tag";

const getOrderSupplies = gql`
  query($id: ID) {
    purchaseOrder(where: { id: $id }) {
      id
      data {
        warehouse {
          name
        }
        vendor {
          id
        }
        project {
          id
        }
        supplies {
          id
          name
          insID
          description
          units
          quantity
          purchasePrice
        }
        receivedSupplies {
          id
          originId
          insID
          name
          description
          purchasePrice
          quantity
          units
          received
          notes
          warehouse
          receptionDate
          receivedBy
          photoID
          shippingDate
          deliveryDate
          shippingCompany
          trackingNumber
        }
      }
    }
  }
`;
export default getOrderSupplies;
