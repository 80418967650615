import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { GET_DOCUMENTS_CONTRACTOR } from "../../queries/contractors";

const DISAPPROVE_DOCUMENT_CONTRACTOR = async (idContract, idDocument, percentageDocuments) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContract(
          data: {
            documentsContractor:{update: {where: { id: "${idDocument}"} data: {
              status: BY_APPROVED
            }}}
          }
          where: { id: "${idContract}" }
        ) {
          id
          openPercentage
          monthPercentage
          closePercentage
          startDate
          finishDate
          finishDateSiroc
          realFinishDate
          contractor {
            id
            name
          }
          documentsContractor {
            id
            type
            description
            status
            refUpload{
              id
              type
              reference
              extension
              wildcard
            }
          }
        }
      }
    `,
    refetchQueries: [{ query: GET_DOCUMENTS_CONTRACTOR }]
  });
  return mutation;
};

export default DISAPPROVE_DOCUMENT_CONTRACTOR;
