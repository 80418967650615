import React from "react"
import { Form, Grid } from "semantic-ui-react";
import CurrencyInput from "react-currency-input";
import { AccountBalanceDocumentsQuery } from "../../Molecules";

export default class AccountBalanceSummary extends React.Component {
  state = {}

  componentDidMount () {
    const { data = [] , currency } = this.props
    const filter = data.find(contract => contract.currency === currency)
    this.setState(filter)
  }

  render() {
    return (
      <Grid columns="equal" divided>
        <Grid.Row>
          <Grid.Column>
            <h5>Amortización Anticipo</h5>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>{`ANTICIPO ${this.state.percentAmortization}%`}</label>
              <CurrencyInput value={this.state.amountAdvancePayment}/>
            </Form.Field>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>AMORTIZADO</label>
              <CurrencyInput value={this.state.amountAmortization}/>
            </Form.Field>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>POR AMORTIZAR</label>
              <CurrencyInput value={this.state.amountByAmortization}/>
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <h5>Retención Fondo Garantía</h5>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>{`RETENIDO ${this.state.percentGuaranteeFund}%`}</label>
              <CurrencyInput value={this.state.amountRetained}/>
            </Form.Field>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>TOTAL RETENIDO</label>
              <CurrencyInput value={this.state.amountTotalRetained}/>
            </Form.Field>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>POR RETENER</label>
              <CurrencyInput value={this.state.amountByRetained}/>
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <h5>Total Contratado</h5>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>ORIGINAL</label>
              <CurrencyInput value={this.state.amountContract}/>
            </Form.Field>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>DEDUCTIVAS</label>
              <CurrencyInput value={this.state.amountDeductives}/>
            </Form.Field>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>ACTUAL</label>
              <CurrencyInput value={this.state.amountContractUpdate}/>
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <h5>Facturación</h5>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>FACTURADO</label>
              <CurrencyInput value={this.state.amountBilled}/>
            </Form.Field>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>RESTANTE</label>
              <CurrencyInput value={this.state.amountByBilled}/>
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <h5>Estimación</h5>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>ESTIMADO</label>
              <CurrencyInput value={this.state.amountPaid}/>
            </Form.Field>
            <Form.Field disabled width="16" className="Util-MaskCurrency">
              <label>POR ESTIMAR</label>
              <CurrencyInput value={this.state.amountByPaid}/>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <AccountBalanceDocumentsQuery
            handleDeleteDocument={null}
            handleShowModal={null}
            cli={this.state.project ? this.state.project.cli : ""}
            contractAccountBalance={this.state}
          />
        </Grid.Row>
      </Grid>
    )
  }
}
