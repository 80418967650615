import gql from "graphql-tag";
import { client } from "../../../graphql/apollo-config";

const getUserRolSelect = gql`
  query GetUserRolSelect($rol: [UserRol!]) {
    users(where: { userRol_in: $rol }) {
      key: id
      text: name,
      value: id
    }
  }
`;

export default getUserRolSelect;
