import React from "react";
import { Query } from "react-apollo";
import { QUERIES } from "../../../graphql/apollo-config";
import angle_down from "../../../assets/angle_down.svg";

const getVendors = QUERIES.getVendors;

const SelectVendor = props => (
  <Query query={getVendors}>
    {({ loading, error, data }) => {
      if (loading) {
        return <p testid="loading-wrapper">loading...</p>;
      }
      if (error) {
        return <p testid="error-wrapper">error</p>;
      }

      return (
        <div>
          <select
            style={{
              backgroundColor: "white",
              border: "1px solid #dddddd",
              WebkitAppearance: "none",
              MozAppearance: "none",
              height: "35px",
              background: `url(${angle_down}) no-repeat right`,
              backgroundPositionX: "98%",
              cursor: "pointer"
            }}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
          >
            <option
              value={null}
              style={{
                WebkitAppearance: "none",
                MozAppearance: "none",
                backgroundColor: "white"
              }}
            >
              Proveedores
            </option>
            {data.vendors.map(option => (
              <option
                key={option.id}
                data={option.country}
                value={JSON.stringify({
                  commercialName: option.commercialName,
                  id: option.id,
                  country: option.country
                })}
                style={{
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                  backgroundColor: "white"
                }}
              >
                {option.commercialName}
              </option>
            ))}
          </select>
        </div>
      );
    }}
  </Query>
);

export default SelectVendor;
