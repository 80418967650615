import React from "react";
import { Modal, Form, Input, Dropdown } from "semantic-ui-react";
import { FormHeader } from "../../Molecules";
import validator from "validator";
import { CREATE_USER, UPDATE_USER } from "../../../graphql/mutations/users";
import { sendConfirmationEmail, format } from "../../../helpers/";
import { bugsnagClient } from "../../../bugsnag";
import FormValidator from "../../../helpers/form-validator";
import validations from "./validations";
import { GET_USER, GET_USER_EMAIL } from "../../../graphql/queries/users";

export default class CreateUser extends React.Component {
  state = {
    isUpdate: false,
    oldEmail: "",
    data: {
      userRol: "",
      name: "",
      email: "",
      password: "",
    },
    errors: {
      userRol: false,
      name: false,
      email: false,
    },
    roles: [
      { key: 1, value: "MANAGER", text: "Gerente" },
      { key: 2, value: "PURCHASES_RESPONSIBLE", text: "Encargado de compras" },
      { key: 3, value: "WAREHOUSE_RESPONSIBLE", text: "Encargado de inventarios" },
      { key: 4, value: "ACCOUNT_BALANCE_RESPONSIBLE", text: "Encargado de balance de cuentas" },
      { key: 5, value: "CONTRACTOR_RESPONSIBLE", text: "Encargado de contratistas" },
      { key: 6, value: "CONTRACTOR", text: "Contratista" },
      { key: 7, value: "TECHNICIAN", text: "Residente" },
      { key: 8, value: "ADMINISTRATIVE_COORDINATOR", text: "Coordinador administrativo de obra." },
      { key: 9, value: "ZONE_COORDINATOR", text: "Coordinador de zona" },
      { key: 10, value: "ADMINISTRATOR", text: "Administrador" },
    ]
  };

  formStyle = {
    margin: "0 auto",
    width: "50%",
  };

  async componentWillMount() {
    const { idUser } = this.props;

    if (idUser) {
      await this.fetchUser(idUser);
    }
  }

  fetchUser = async id => {
    try {
      const { data: { user: { name, email, userRol } } } = await GET_USER(id);
      this.setState(prevState => ({
        ...prevState,
        isUpdate: true,
        oldEmail: email,
        data: {
          userRol,
          name,
          email
        }
      }))
    } catch (e) {
      bugsnagClient.notify(e)
    }
  };

  handleChange = (e, { name, value }) => {
    const { data } = this.state;
    data[name] = value;
    this.setState({ ...data });
  };

  emailExists = async () => {
    const oldEmail = this.state.oldEmail.toLowerCase();
    const newEmail = this.state.data.email.toLowerCase();;

    if (oldEmail === newEmail) return false;

    const { data: { user } } = await GET_USER_EMAIL(newEmail);
    return !!user;
  };

  validateFields = () => {
    const errors =  this.state.errors;
    const validator = new FormValidator(validations);
    const validation = validator.validate(this.state.data);
    Object.keys(errors).map(error => {
      errors[error] = validation[error]["isInvalid"];
    });
    this.setState({ ...errors });
    return validation;
  };

  save = async () => {
    const { isUpdate, data } = this.state;
    const validation = this.validateFields();
    const emailExists = await this.emailExists();

    if (emailExists) return alert("Este correo electrónico ya se enctuentra en uso");
    if (validation.email.isInvalid) return alert("Este correo electrónico no es válido");

    try {
      if (!isUpdate) {
        data["password"] = format.generatePassword(60);

        await CREATE_USER(data);
        await sendConfirmationEmail(
          data["name"],
          data["email"],
          data["password"]
        );
      } else {
        const { idUser } = this.props;
        const { name, email, userRol } = data
        await UPDATE_USER({
          name,
          email: email.toLowerCase(),
          userRol
        }, idUser)
      }
      this.props.onClose();
    } catch (error) {
      bugsnagClient.notify(error);
    }
  };

  render() {
    const { data, roles, errors } = this.state;
    const { open, onClose } = this.props

    return (
      <Modal size="tiny" open={open}>
        <FormHeader
          saveId="save-user-btn"
          cancelId="cancel-user-btn"
          text="Añadir nuevo usuario"
          onSave={this.save}
          onCancel={onClose}
        />
        <Modal.Content>
          <Form className="Modal__Form" style={this.state.formStyle}>
            <Form.Field
              label="ROL DEL USUARIO"
              name="userRol"
              control={Dropdown}
              options={roles}
              value={data["userRol"]}
              onChange={this.handleChange}
              error={data["userRol"] !== "" ? false : errors["userRol"]}
              selection
              required
            />
            <Form.Field
              label="NOMBRE DEL CONTACTO"
              name="name"
              control={Input}
              value={data["name"]}
              onChange={this.handleChange}
              error={data["name"] !== "" ? false : errors["name"]}
              required
            />
            <Form.Field
              label="CORREO ELECTRÓNICO"
              name="email"
              control={Input}
              value={data["email"]}
              onChange={this.handleChange}
              error={
                data["email"] !== "" && validator.isEmail(data["email"])
                  ? false
                  : errors["email"]
              }
              required
            />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
