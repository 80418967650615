import gql from "graphql-tag";

const getWarehouse = gql`
  query($id: ID!) {
    warehouse(where: { id: $id }) {
      name
      street
      city
      state
      country
      postalCode
      contactName
      contactEmail
      contactPhone
      status
    }
  }
`;

export default getWarehouse;
