import React from "react";
import { IoMdCreate, IoIosBook } from "react-icons/io";
import { Icon } from "semantic-ui-react";
import { VendorsList, SuppliesList } from "../../../Organisms";
import WerehousesList from "../../../Organisms/WerehousesList";

export const routes = [
  {
    icon: IoIosBook,
    item: VendorsList,
    routeName: "prov",
    text: "Proveedores",
  },
  {
    icon: IoMdCreate,
    item: SuppliesList,
    routeName: "sup",
    text: "Insumos",
  },
  {
    icon: Icon,
    name: "warehouse",
    item: WerehousesList,
    routeName: "warehouse",
    text: "Almacén",
  },
];

export const warehouseRoute = [
  {
    icon: IoIosBook,
    item: VendorsList,
    routeName: "prov",
    text: "Proveedores",
  },
  {
    icon: IoMdCreate,
    item: SuppliesList,
    routeName: "sup",
    text: "Insumos",
  },
  {
    icon: Icon,
    name: "warehouse",
    item: WerehousesList,
    routeName: "warehouse",
    text: "Almacén",
  },
];
