import React, { Component } from "react";
import { Segment, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { ModalHeader } from "../../Molecules";
import { TabNavigation } from "../../Molecules";

class TabModal extends Component {
  state = {
    currentTabName: this.props.active,
  };

  changeComponent = (currentTabName) => {
    this.setState({
      currentTabName,
    });
  }

  render() {
    const childrens = this.props.children.filter(children => children)

    // map child components, return link array
    const links = React.Children.map(childrens, child => {
      const { name, text } = child.props;
      return {
        name,
        text,
      }
    });

    // find the current child component to render
    const currentComponent = React.Children.map(childrens, child => {
      if (child.props.name === this.state.currentTabName) {
        return child
      }
    });

    // render tab navigation componenet
    const tabNavigation = <TabNavigation
      active={this.props.active}
      links={links}
      onChange={this.changeComponent}
    />
    return (
      <Segment raised className="Modal">
        <ModalHeader
          component={true}
          title={tabNavigation}
          save={this.props.save}
          cancel={this.props.close}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
        />
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              {currentComponent}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

TabModal.propTypes = {
  active: PropTypes.string.isRequired,
  save: PropTypes.func,
  close: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

TabModal.defaultProps = {
  readOnly: false,
  disabled: false,
}

export default TabModal;
