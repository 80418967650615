import gql from "graphql-tag";
import { client } from "../../apollo-config";
import bcrypt from "bcryptjs"

const AUTHORIZER_USER = async (id, password) => {
  const { data: { user } } = await client.query({
    query: gql`
      {
        user(where: { id: "${id}" }) {
          password
          name
        }
      }`
  });

  const compare = bcrypt.compareSync(password, user.password)

  return compare ? user.name : null;
};
export default AUTHORIZER_USER;
