import React from "react";
import { Button, Icon, Segment } from "semantic-ui-react";
import { format } from "../../../helpers";

class UploadButton extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      contentFile: [
        { icon: "plus", content: "Cargar documentos" },
        { icon: "cloud upload", content: "Cargando documento..." },
      ]
    }
  }

  downloadFile = file => {
    window.open(file, "_blank");
  };

  render() {
    const { contentFile } = this.state
    const {
      readOnly,
      refUpload = {},
      documents = {},
      buttonRef,
      uploadFile,
      statusFile,
      deleteFile
    } = this.props

    return (
      !readOnly ? (
        <React.Fragment>
          {refUpload.reference ? (
            <Segment
              className="Modal__SubTabNav-Documents-Segment Siroc" style={{ width: 'auto' }}>
              {`${documents.type}.${format.getExtesion(
                refUpload.reference
              )}`}
              <div style={{ marginLeft: '1rem' }}>
                <Icon
                  name="eye"
                  size="large"
                  link
                  onClick={() => this.downloadFile(refUpload.reference)}
                />
                <Icon
                  name="trash alternate outline"
                  size="large"
                  link
                  onClick={() => deleteFile(documents)}
                />
              </div>
            </Segment>
          ) : (
            <div style={{ display: "flex", alignItems: "flex-end", height: "100%" }}>
              <Button
                basic
                icon={contentFile[statusFile].icon}
                content={contentFile[statusFile].content}
                className="Modal__Form-SubModal-Form-ButtonAddOutline"
                fluid
                onClick={() => buttonRef.current.click()}
              />
              <input
                type='file'
                hidden
                accept="application/pdf"
                onChange={uploadFile}
                style={{ display: "none" }}
                ref={buttonRef}
              />
            </div>
          )}
        </React.Fragment>
      ) : refUpload.reference ? (
        <React.Fragment>
          <Button
            basic
            icon="download"
            content="Ver documento"
            className="Modal__Form-SubModal-Form-ButtonAddOutline Large Modal__Form-Utils-Input-MT"
            onClick={() => this.downloadFile(refUpload.reference)}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Button
            basic
            icon="delete"
            content="Sin documento"
            className="Modal__Form-SubModal-Form-ButtonAddOutline Large Modal__Form-Utils-Input-MT"
          />
        </React.Fragment>
      )
    )
  }
}

export default UploadButton;
