import GeneralForm from "./components/GeneralForm";
import InsumosForm from "./components/InsumosForm";
import InvoicesForm from "./components/InvoicesForm";
import ShipmentForm from "./components/ShipmentForm";

export const routes = [
  {
    routeName: "gen",
    item: GeneralForm,
    text: "General",
  },
  {
    routeName: "inv",
    item: InvoicesForm,
    text: "Facturación",
  },
  {
    routeName: "ins",
    item: InsumosForm,
    text: "Insumos",
  },
];
