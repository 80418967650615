import gql from "graphql-tag"

const GET_TIME_LINE = gql `
  query timeLine($id: ID) {
    contractAccountBalance(where: { id: $id }) {
      elaboratedEvent {
        name
        date
        description
      }
    }
  }
`;

export default GET_TIME_LINE;
