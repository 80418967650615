import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { GET_DOCUMENTS_CONTRACTOR } from "../../queries/contractors";

const DELETE_DOCUMENT_CONTRACTOR = async (idContract, idDocument, percentageDocuments) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContract(
          data: {
            openPercentage: ${percentageDocuments.openPercentage || 0}
            monthPercentage: ${percentageDocuments.monthPercentage || 0}
            closePercentage: ${percentageDocuments.closePercentage || 0}
            documentsContractor: { delete: { id: "${idDocument}" } }
          }
          where: { id: "${idContract}" }
        ) {
          id
          openPercentage
          monthPercentage
          closePercentage
          startDate
          finishDate
          finishDateSiroc
          realFinishDate
          contractor {
            id
            name
          }
          documentsContractor {
            id
            type
            description
            status
            refUpload{
              id
              type
              reference
              extension
              wildcard
            }
          }
        }
      }
    `,
    refetchQueries: [{ query: GET_DOCUMENTS_CONTRACTOR }]
  });
  return mutation;
};

export default DELETE_DOCUMENT_CONTRACTOR;
