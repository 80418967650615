import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { format } from "../../../helpers";
import bcrypt from "bcryptjs";

const createContractor = async ({
  name,
  commercialName,
  street,
  colony,
  city,
  rfc,
  externalNumber,
  postalCode,
  personType,
  internalNumber,
  state,
  country,
  contacts,
  email,
  password,
}) => {
  const salt = bcrypt.genSaltSync(10);
  const hash = bcrypt.hashSync(password, salt);

  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        createContractor(
          data: {
            name: "${name}"
            commercialName: "${commercialName}"
            street: "${street}"
            colony: "${colony}"
            city: "${city}"
            rfc: "${rfc}"
            externalNumber: "${externalNumber}"
            postalCode: ${parseInt(postalCode)}
            personType: ${personType}
            internalNumber: "${internalNumber}"
            state: "${state}"
            country: "${country}"
            contacts: {
              create: [${contacts.map(contact => {
                delete contact.new;
                const str = JSON.stringify(contact);
                var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                obj = obj.replace(/\,type:"([^(\")"]+)"/g, ",type:$1");
                return obj;
              })}]
            }
            user: {
              create :{
                email: "${email}"
                password: "${hash}"
                name: "${name}"
                joinedAt: "${format.date(new Date(), 2)}"
                userRol: CONTRACTOR
                status: ACTIVE
              }
            }
          }
        ) {
          id
        }
      }
    `,
  });
  return mutation;
};

export default createContractor;
