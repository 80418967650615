import React, { Component } from "react";
import "./style.css";

export default class Link extends Component {
  render() {
    const { className, href, icon: Icon, onClick, text } = this.props;

    return (
      <a
        id={`link-${text}`}
        href={href}
        className={className}
        onClick={onClick}
      >
        {Icon && <Icon name={this.props.iconName} />}
        {Icon && "    "}
        {text}
      </a>
    );
  }
}
