import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async (variables) => {
  return await client.mutate({
    mutation: gql`
      mutation CreateSupply(
        $name: String,
        $description: String!,
        $units: String!,
        $purchasePrice: Float!
      ) {
        createSupply(
          data: {
            name: $name
            description: $description
            units: $units
            purchasePrice: $purchasePrice
          }
        ){
          id
          insID
        }
      }
    `,
    variables: variables
  });
};
