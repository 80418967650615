import React from "react";
import {
  Container,
  Form,
  Input,
  Segment,
  Table,
  Label,
  Grid,
  Header,
  Button
} from "semantic-ui-react";
import { ApolloProvider } from "react-apollo";
import ContactsTableHeader from "../../Molecules/contactsTableHeader";
import { styles } from "../../theme";
import { client, store } from "../../../graphql/apollo-config";
import { store as _store } from "../../../store/store";

export default class DetailVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplies: []
    };
  }
  LabelInput = () => {
    return (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid #dddddd",
          borderRadius: "4px",
          WebkitAppearance: "none",
          MozAppearance: "none",
          height: "32px",
          cursor: "pointer",
          textAlign: "right",
          padding: "5px"
        }}
      >
        <Label text={this.state.vendor} />
      </div>
    );
  };
  render() {
    const vendor = this.props.data;
    const contacts = this.props.data.contacts;
    return (
      <ApolloProvider client={client}>
        <Segment raised className="Modal">
          <Header as="h3" dividing className="Modal__Header">
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column textAlign="left" className="Modal__Header-Title">
                  Detalle de cliente
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Container>
                    <Button
                      id="cancel-vendor-detial-btn"
                      onClick={this.props.onCancel}
                    >
                      Cancelar
                    </Button>
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <Form className="Modal__Form">
            <Form.Group widths="equal">
              <Form.Field
                name="commercialName"
                control={Input}
                type="text"
                label="NOMBRE COMERCIAL"
                value={vendor.commercialName}
                placeholder={this.state.commercialName}
                readOnly={true}
              />
              <Form.Field
                name="postalCode"
                control={Input}
                label="CÓDIGO POSTAL"
                value={vendor.postalCode}
                placeholder={this.state.postalCode}
                readOnly={true}
              />
              <Form.Field
                name="country"
                control={Input}
                label="PAÍS"
                value={vendor.country}
                readOnly={true}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                label="DIRECCIÓN"
                name="street"
                control={Input}
                placeholder="Calle"
                value={vendor.street}
                onChange={this.handleChange}
                error={vendor.streetError}
                readOnly={true}
                width={6}
              />
              <Form.Field
                label="&nbsp;"
                name="number"
                control={Input}
                placeholder="Número"
                value={vendor.number}
                onChange={this.handleChange}
                error={this.state.numberError}
                readOnly={true}
                width={6}
              />
              <Form.Field
                control={Input}
                label="CIUDAD"
                name="city"
                value={vendor.city}
                readOnly={true}
                width={12}
              />
              <Form.Field
                name="rfc"
                control={Input}
                type="text"
                label="RFC"
                value={vendor.rfc}
                readOnly={true}
                width={12}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field
                name="colonuy"
                control={Input}
                label="COLONIA"
                value={vendor.colony}
                readOnly={true}
              />
              <Form.Field
                name="state"
                control={Input}
                label="ESTADO"
                value={vendor.state}
                readOnly={true}
              />
              <Form.Field
                name="revisedBy"
                control={Input}
                label="RAZÓN SOCIAL"
                value={vendor.razonSocial}
                readOnly={true}
              />
            </Form.Group>
          </Form>
          <Header as="h3" dividing className="Modal__Form-Header">
            Contacto
          </Header>
          <Table
            basic="very"
            compact="very"
            textAlign="center"
            className="Modal__Form-Table"
          >
            <Table.Header className="Modal__Form-Table-Header">
              <Table.Row>
                <Table.HeaderCell>Nombre del contacto</Table.HeaderCell>
                <Table.HeaderCell>Correo electrónico</Table.HeaderCell>
                <Table.HeaderCell>Teléfono</Table.HeaderCell>
                <Table.HeaderCell>Teléfono oficina</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body className="Modal__Form-Table-Body">
              {contacts.map(contact => {
                return (
                  <Table.Row key={contact.id}>
                    <Table.Cell key={contact.name}>{contact.name}</Table.Cell>
                    <Table.Cell key={contact.email}>{contact.email}</Table.Cell>
                    <Table.Cell key={contact.phone}>{contact.phone}</Table.Cell>
                    <Table.Cell key={contact.phone_office}>
                      {contact.phone_office}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </ApolloProvider>
    );
  }
}
