import gql from "graphql-tag"

const GET_SUPPLIES = gql`
  query supplies($first: Int, $skip: Int, $search: String, $floatSearch: Float) {
    supplies(
      first: $first,
      skip: $skip,
      orderBy: createdAt_DESC,
      where: {
        OR: [
          { insID_contains: $search }
          { name_contains: $search }
          { description_contains: $search }
          { units_contains: $search }
          { purchasePrice: $floatSearch }
        ]
      }
    ) {
      id
      insID
      name
      description
      units
      purchasePrice
      count
    }
    count: supplies(
      where: {
        OR: [
          { insID_contains: $search }
          { name_contains: $search }
          { description_contains: $search }
          { units_contains: $search }
          { purchasePrice: $floatSearch }
        ]
      }
    ) {
      id
    }
  }
`;

export default GET_SUPPLIES;
