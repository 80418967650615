import gql from "graphql-tag";

const CreateInvoice = ({ paymentConditions, refUpload }) => gql`
  mutation(
    $poId: ID!,
    $invoiceNumber: String!,
    $receptionDate: DateTime!,
    $paymentDate: DateTime!,
    $notes: String
  ) {
    updatePurchaseOrder(
      where: { id: $poId }
      data: {
        invoices: {
          create: {
            number: $invoiceNumber
            receptionDate: $receptionDate
            paymentConditions: ${paymentConditions}
            paymentDate: $paymentDate
            notes: $notes
            refUpload: {
              create: [${refUpload.map(invoiceFile => {
                const str = JSON.stringify(invoiceFile);
                let obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                obj = obj.replace(/\,type:"([^(\")"]+)"/g, ",type:$1");
                return obj;
              })}]
            }
          }
        }
      }
    ) {
      id
    }
  }
`;

export default CreateInvoice;
