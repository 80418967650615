import React from "react";
import { Button, Container, Form, Header, Input, Modal, Segment, Table, Grid, Icon, Message } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash } from "react-icons/io";
import _ from "lodash";
import { createContractor, updateContractor, deleteContact, updateContact } from "../../../graphql/mutations/contractors";
import { EMAIL_EXIST } from "../../../graphql/queries/contractors";
import { sendConfirmationEmail } from "../../../helpers";
import { FormHeader, ContractorContactModal } from "../../Molecules";
import { validator, format, helper } from "../../../helpers";
import { bugsnagClient } from "../../../bugsnag";

class CreateContractor extends React.Component {
  state = {
    contractor:{
      id: "",
      name: "",
      commercialName: "",
      rfc: "",
      personType: "",
      street: "",
      externalNumber: "",
      internalNumber: "",
      colony: "",
      postalCode: "",
      state: "",
      city: "",
      country: "",
      email: "",
      password: ""
    },
    email: null,
    personTypeOptions: [
      { key: "fisica", text: "FÍSICA", value: "FISICA" },
      { key: "moral", text: "MORAL", value: "MORAL" },
      { key: "extenjero", text: "EXTRANJERO", value: "EXTRANJERO" }
    ],
    currentEmail: "",
    contact: {
      name: "",
      email: "",
      phone: "",
      type: "",
      new: true
    },
    contacts: [],
    errors: {
      name: false,
      commercialName: false,
      rfc: false,
      personType: false,
      street: false,
      externalNumber: false,
      colony: false,
      postalCode: false,
      state: false,
      city: false,
      country: false,
      email: false,
    },
    contactErrors: {
      name: false,
      email: false,
      phone: false,
      type: false,
    },
    modals: {
      showContactModal: false,
      isUpdateContact: false,
      currentContactIndex: null,
    },
    isLoading: false,
    column: null,
    direction: "descending",
    isUpdate: false
  };

  componentDidMount() {
    // list of options
    this.phoneType = [
      {"value": "CELLPHONE", "text": "Celular"},
      {"value": "OFFICEPHONE", "text": "Oficina"},
      {"value": "HOMEPHONE", "text": "Casa"},
    ]

    // if this.props.data is not null get contractor data
    if(this.props.data !== null) {
      const contractor = Object.assign({}, this.props.data.contractor);
      const { contacts, user } = contractor;
      contacts.forEach(contact => { contact.new = false })
      // save in state
      this.setState({
        contractor: {
          ...contractor,
          email: user.email
        },
        contacts,
        isUpdate: true,
        email: user.email
      })
    }
  }

  handleSort = (clickedColumn) => {
    const { direction, contacts } = this.state;
    this.setState({
      column: clickedColumn,
      direction: direction === "ascending" ? "descending": "ascending",
      contacts: _.orderBy(contacts, [clickedColumn], [direction.replace("ending", "")])
    });
  };

  getPhoneTypeDescription(type) {
    const findedObj = this.phoneType.find(element => (
      element.value === type
    ));

    return findedObj.text;
  }

  validateFields = validationObject => {
    let errors = [];
    const validation = validator(validationObject);

    validation.forEach(field => {
      const key = field.key;
      if (!field.isValid) {
        errors.push(key);
      }
    });

    return errors;
  };

  handleChange = (event, data) => {
    const { name, value } = data;
    const contractor = this.state.contractor;
    contractor[name] = helper.cleaningData(value);
    this.setState(data => ({
      ...data,
      contractor
    }));
  };

  // show contact modal
  openAddContact = () => {
    const { modals }  = this.state;
    modals.showContactModal = true;

    this.setState({
      modals
    });
  };

  // clean state values of contact
  // and hide contact modal
  closeAddContact = () => {
    const { modals }  = this.state;
    modals.showContactModal = false;
    modals.currentContactIndex = null;
    modals.isUpdateContact = false;

    const contactErrors = this.cleanContactErrors();

    this.setState({
      modals,
      contact: {
        name: "",
        email: "",
        phone: "",
        type: "",
      },
      contactErrors
    });
  };

  // set contact state from value input
  // of the ContractorContactModal
  changeContactState = (e, data) => {
    const { name, value } = data;
    const { contact } = this.state;

    contact[name] = value;
    this.setState({ contact });
  }

  cleanContactErrors = () => {
    const contactErrors = {
      name: false,
      email: false,
      phone: false,
      type: false,
    };

    return contactErrors;
  }

  // append contact state to contact list
  saveContact = (event) => {
    let { contact, modals, contacts, contactErrors, currentEmail} = this.state;
    // get contact errors state object
    const validationErrors = Object.assign({}, contactErrors);

    // validate contact object
    const errors = this.validateFields(contact);
    if (errors.length > 0) {
      Object.keys(validationErrors).forEach((key, index) => {
        validationErrors[key] = errors.indexOf(key) !== -1;
      });

      this.setState({
        contactErrors: validationErrors,
      });

      return false;
    }

    if (modals.isUpdateContact && modals.currentContactIndex !== null) {
      contacts[modals.currentContactIndex] = contact;
      modals.currentContactIndex = null;
      modals.isUpdateContact = false;
      updateContact(contact, currentEmail)
      .then()
      .catch(e => bugsnagClient.notify(e))
    } else {
      // push contact object to contacts array
      const exist = _.some(contacts, contact);
      if (exist) return alert("El contacto ya existe");
      contacts.push(contact);
    }

    modals.showContactModal = false;
    // reset contact object
    contact = {
      name: "",
      email: "",
      phone: "",
      type: "",
      new: true
    }

    this.setState({
      contact,
      modals,
      contacts,
      contactErrors: this.cleanContactErrors()
    });
  }

  // delete contact fron contacts list
  deleteContact = (index, email) => {
    const { contacts } = this.state;

    deleteContact(email)
      .then(res => {
        // delete item of array by index
        contacts.splice(index, 1);
        this.setState({ contacts });
      })
      .catch(e => bugsnagClient.notify(e))
  }

  editContact = (index, email) => {
    const contactReference = this.state.contacts[index];

    if (typeof contactReference === "undefined") {
      return false
    }

    const contact = Object.assign({}, contactReference);
    const modals = this.state.modals;
    modals.currentContactIndex = index;
    modals.isUpdateContact = true;

    this.setState({
      contact,
      modals,
      currentEmail: email
    }, this.openAddContact);
  }

  contractorValidateFields = () => {
    const {
      name,
      commercialName,
      rfc,
      personType,
      street,
      externalNumber,
      colony,
      postalCode,
      state,
      city,
      country,
      email,
    } = this.state.contractor;

    const data = {
      name,
      commercialName,
      rfc,
      personType,
      street,
      externalNumber,
      colony,
      postalCode,
      state,
      city,
      country,
      email
    }

    return data;
  }

  cleanContractorErrors = () => {
    const errors = {
      name: false,
      commercialName: false,
      rfc: false,
      personType: false,
      street: false,
      externalNumber: false,
      colony: false,
      postalCode: false,
      state: false,
      city: false,
      country: false,
      email: false,
    };

    return errors;
  }

  // save Contractor
  save = async () => {
    // launch loading
    this.setState(data => ({ ...data, isLoading: true }));

    const { errors } = this.state;

    // validate inputs
    // get contractor errors state object
    const validationErrors = Object.assign({}, errors);
    // get inputs to validate
    const contractorValidateObj = this.contractorValidateFields()
    const contractorErrors = this.validateFields(contractorValidateObj);
    const { contractor } = this.state;
    contractor.contacts = this.state.contacts;

    if (contractorErrors.length > 0) {
      Object.keys(validationErrors).forEach((key) => {
        validationErrors[key] = contractorErrors.indexOf(key) !== -1;
      });

      this.setState(data => ({
        ...data,
        errors: validationErrors,
        isLoading: false,
      }));

      return;
    }

    const cleanErrors = this.cleanContractorErrors()

    if (this.state.contacts.length === 0) {
      alert("No ha añadido ningún contacto...");

      this.setState(data => ({
        ...data,
        isLoading: false,
        errors: cleanErrors
      }));

      return;
    }

    const emailExist = await EMAIL_EXIST(contractor.email);
    if (emailExist) {
      if (this.state.email !== contractor.email) {
        this.setState({ isLoading: false, errors: cleanErrors });
        return alert("Ya existe un usuario creado con este correo, por favor intenta con uno diferente.");
      }
    }

    if (this.props.data) {
      try {
        await updateContractor(contractor);
        this.setState({ isLoading: false, errors: cleanErrors });
        this.props.close(true);
      } catch (error) {
        bugsnagClient.notify(error);
      }
    } else {
      try {
        const password = format.generatePassword(60);
        contractor["password"] = password;
        await createContractor(contractor);
        await sendConfirmationEmail(
          contractor["name"],
          contractor["email"],
          password
        );
        this.setState({ isLoading: false, errors: cleanErrors });
        this.props.close(true);
      } catch (error) {
        bugsnagClient.notify(error);
      }
    }
  }

  // close Add Contractor View
  cancel = () => {
    this.props.close();
  }

  render() {
    // set modal title
    const modalTitle = this.props.data ? "Datos del contratista" : "Nuevo contratista";
    const titleContact = this.props.data ? "Contactos" : "Añadir contactos";
    // disabled save function if isLoading true
    const saveFunction = !this.state.isLoading ? this.save : null;

    return (
      // replace div with ApolloProvider component
      <Segment raised className="Modal">
        {this.props.readOnly ? (
          <Header as="h3" dividing className="Modal__Header">
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column textAlign="left" className="Modal__Header-Title">
                  Detalle del contratista
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Container>
                    <Button onClick={this.cancel}>Cancelar</Button>
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
        ) : (
          <FormHeader text={modalTitle} onSave={saveFunction} onCancel={this.cancel}/>
        )}

        <Form className="Modal__Form">
          <Form.Group widths="equal">
            <Form.Field required
              label="NOMBRE DEL CONTRATISTA"
              name="name"
              control={Input}
              value={this.state.contractor.name}
              onChange={this.handleChange}
              error={this.state.errors.name}
              readOnly={this.props.readOnly}/>

            <Form.Field required
              label="NOMBRE COMERCIAL"
              name="commercialName"
              control={Input}
              value={this.state.contractor.commercialName}
              onChange={this.handleChange}
              error={this.state.errors.commercialName}
              readOnly={this.props.readOnly}/>

            <Form.Field required
              label="RFC"
              name="rfc"
              control={Input}
              value={this.state.contractor.rfc}
              onChange={this.handleChange}
              error={this.state.errors.rfc}
              readOnly={this.props.readOnly}/>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select required
              name="personType"
              options={this.state.personTypeOptions}
              placeholder="Seleccionar"
              label="RAZÓN SOCIAL"
              value={this.state.contractor.personType}
              onChange={this.handleChange}
              error={this.state.errors.personType}
              readOnly={this.props.readOnly}/>

            <Form.Field required
              label="CALLE"
              name="street"
              control={Input}
              value={this.state.contractor.street}
              onChange={this.handleChange}
              error={this.state.errors.street}
              readOnly={this.props.readOnly}/>

            <Form.Field required
              label="NO. EXTERIOR"
              name="externalNumber"
              control={Input}
              value={this.state.contractor.externalNumber}
              onChange={this.handleChange}
              error={this.state.errors.externalNumber}
              readOnly={this.props.readOnly}/>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field
              label="NO. INTERIOR"
              name="internalNumber"
              control={Input}
              value={this.state.contractor.internalNumber}
              onChange={this.handleChange}
              readOnly={this.props.readOnly}/>

            <Form.Field required
              label="COLONIA"
              name="colony"
              control={Input}
              value={this.state.contractor.colony}
              onChange={this.handleChange}
              error={this.state.errors.colony}
              readOnly={this.props.readOnly}/>

            <Form.Field required
              label="CÓDIGO POSTAL"
              name="postalCode"
              control={Input}
              type="number"
              value={this.state.contractor.postalCode}
              onChange={this.handleChange}
              error={this.state.errors.postalCode}
              readOnly={this.props.readOnly}/>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field required
              label="ESTADO"
              name="state"
              control={Input}
              value={this.state.contractor.state}
              onChange={this.handleChange}
              error={this.state.errors.state}
              readOnly={this.props.readOnly}/>

            <Form.Field required
              label="CIUDAD"
              name="city"
              control={Input}
              value={this.state.contractor.city}
              onChange={this.handleChange}
              error={this.state.errors.city}
              readOnly={this.props.readOnly}/>

            <Form.Field required
              label="PAÍS"
              name="country"
              control={Input}
              value={this.state.contractor.country}
              onChange={this.handleChange}
              error={this.state.errors.country}
              readOnly={this.props.readOnly}/>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field required
              className="InputIconReset"
              label="CORREO ELECTRONICO"
              icon="at"
              name="email"
              value={this.state.contractor.email}
              control={Input}
              onChange={this.handleChange}
              error={this.state.errors.email}
              readOnly={this.props.readOnly}/>
              <Form.Field required />
              <Form.Field required />
          </Form.Group>
        </Form>

        <Header as="h3" dividing className="Modal__Form-Header">
          {titleContact}
        </Header>

        <Table basic='very' compact="very" textAlign="center" className="Modal__Form-Table" sortable>
          <Table.Header className="Modal__Form-Table-Header">
            <Table.Row>
              <Table.HeaderCell
                sorted={this.state.column === "name" ? this.state.direction : "descending"}
                onClick={() => this.handleSort("name")}>
                Nombre del contacto
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={this.state.column === "email" ? this.state.direction : "descending"}
                onClick={() => this.handleSort("email")}>
                Correo electrónico
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={this.state.column === "phone" ? this.state.direction : "descending"}
                onClick={() => this.handleSort("phone")}>
                Teléfono
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={this.state.column === "type" ? this.state.direction : "descending"}
                onClick={() => this.handleSort("type")}>
                Tipo
              </Table.HeaderCell>
              <Table.HeaderCell className="Modal__Form-Table-Header-NoHover"/>
            </Table.Row>
          </Table.Header>
          <Table.Body className="Modal__Form-Table-Body">
            {this.state.contacts.map((contact, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    {unescape(contact.name)}
                  </Table.Cell>
                  <Table.Cell>{contact.email}</Table.Cell>
                  <Table.Cell>{contact.phone}</Table.Cell>
                  <Table.Cell>{this.getPhoneTypeDescription(contact.type)}</Table.Cell>
                  <Table.Cell textAlign="right">
                  {!this.props.readOnly &&
                    <Button.Group basic>
                      <Button icon onClick={() => this.editContact(index, contact.email)}>
                        <IoMdCreate />
                      </Button>
                      <Button icon onClick={() => this.deleteContact(index, contact.email)}>
                        <IoIosTrash />
                      </Button>
                    </Button.Group>
                  }
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        {!this.props.readOnly &&
          <Container textAlign="center">
            <Modal
              className="Modal__Form-SubModal"
              centered={false}
              trigger={
                <Button onClick={this.openAddContact} className="Modal__Form-ButtonAdd" icon="plus" content="Añadir contacto" size="large"/>
              }
              open={this.state.modals.showContactModal}
              header={
                <FormHeader
                  text={this.state.contact.name ? "Datos de contacto" : "Añadir nuevo contacto"}
                  onCancel={this.closeAddContact}
                  onSave={this.saveContact}
                />
              }
              content={<ContractorContactModal
                data={this.state.contact}
                phoneType={this.phoneType}
                errors={this.state.contactErrors}
                handler={this.changeContactState}
              />}
              size="tiny"
              dimmer="blurring"/>
          </Container>
        }
      </Segment>
    );
  }
}

export default CreateContractor
