const tsToDate = timestamp => {
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  var date = new Date(timestamp * 1000);
  var days = date.getDay();
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  var formattedTime = `${days} : ${hours} : ${minutes.substr(
    -2
  )} : ${seconds.substr(-2)}`;

  return formattedTime;
};

export default tsToDate;
