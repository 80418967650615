import React, { Component } from "react";
import {  
  Segment,  
  Grid,
} from "semantic-ui-react";
import PropTypes from 'prop-types';
import { ModalHeader } from "../../Molecules";

import theme from "../../_theme";

class ModalContainer extends Component {
  constructor(props) {
    super(props);        

    this.state = {
      component: "",
    };
  }
  
  render() {
    return (
      <Segment style={theme.styles.addForms.standard} raised>
        <ModalHeader
          title={this.props.title}
          titleAlign="right"
          save={this.props.save}
          cancel={this.props.close}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
        />
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              {this.props.children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

ModalContainer.propTypes = {
  title: PropTypes.string,
  save: PropTypes.func,  
  close: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

ModalContainer.defaultProps = {
  save: null,
  title: "",
  readOnly: false,
  disabled: false,
}

export default ModalContainer;
