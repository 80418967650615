import gql from "graphql-tag";
import { client } from "../apollo-config";

const deleteWarehouse = async id => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        deleteWarehouse(where: { id: "${id}" }) {
          id
        }
      }
    `
  });
  return mutation;
};

export default deleteWarehouse;