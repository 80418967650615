import React from "react";
import {
  Grid,
  Button,
  Table,
  ButtonGroup,
  Dimmer,
  Loader,
  Container,
  Modal,
  Header,
  Confirm
} from "semantic-ui-react";
import {
  IoMdCreate,
  IoIosTrash,
  IoMdEye
} from "react-icons/io";
import _ from "lodash";
import {
  NumericBox,
  ModalHeader,
  ContractDocumentForm,
  AditiveForm,
} from "../../Molecules";
import { validator } from "../../../helpers";
import parseDate from "../../../helpers/parse-date";
import { Query } from "react-apollo";
import ContractDocumentContext from "../OriginalContractList/contractDocumentContext";
import { GET_ADITIVES_CONTRACT, QUERY_CONTRACTS, AUTHORIZER_USER } from "../../../graphql/queries/contractors";
import {
  CREATE_ADITIVE_CONTRACT,
  DELETE_ADITIVE_CONTRACT,
  DELETE_DOCUMENT_ADITIVE,
  CREATE_DOCUMENT_ADITIVE,
  DELETE_DOCUMENT_ADITIVE_ITEM } from "../../../graphql/mutations/contractors";
import { bugsnagClient } from "../../../bugsnag";
import { format, helper } from "../../../helpers";
import moment from "moment";
import { Can } from "../../hoc/permissions";

const refethQueries = {
  QUERY_CONTRACTS,
  GET_ADITIVES_CONTRACT
}

class ContractAditivesList extends React.Component {
  state = {
    user: JSON.parse(window.localStorage.getItem("session")) ? JSON.parse(window.localStorage.getItem("session")).user : "",
    isLoading: false,
    readOnly: false,
    readOnlyAuthorized: true,
    showFileModal: false,
    modalTitle: "",
    showFiles: "false",
    column: null,
    direction: "descending",
    documentType: "",
    summarySumEstimations: 0,
    summarySumDeductives: 0,
    cancelbutton: true,
    currentItem: {
      id: "",
      idContract: "",
      consecutive: "",
      total: 0,
      invoiced: 0,
      paided: 0,
      currency: "",
      startDate: "",
      finishDate: "",
      description: "",
      amountAditives: 0
    },
    errors: {
      startDate: false,
      finishDate: false,
      total: false,
      currency: false,
      description: false
    },
    currentDocumentItem: {
      idAditive: "",
      idContract: "",
      id:"",
      dateElaborated: new Date(),
      status: "BY_AUTHORIZING",
      concept: "",
      elaborated: JSON.parse(window.localStorage.getItem("session")).user.name,
      amount: 0,
      iva: 0,
      amountIva: 0,
      total: 0,
      type: "",
      currency: "",
      authorizer: "",
      totalContrated: 0,
      totalInvoiced: 0,
      totalPaided: 0,
      amountDeductives: 0,
      contractAmountDeductives: 0,
      refUpload: []
    },
    errorsDocument: {
      dateElaborated: false,
      status: false,
      concept: false,
      elaborated: false,
      amount: false,
      iva: false,
      amountIva: false,
      total: false,
    },
    documentStatus: [{
      key: "1",
      text: "Por autorizar",
      value: "BY_AUTHORIZING"
    }],
    statusOptions: [{
      key: "1",
      text: "Por autorizar",
      value: "BY_AUTHORIZING"
    }, {
      key: "2",
      text: "Por facturar",
      value: "BY_BILL"
    },
    {
      key: "3",
      text: "Facturado",
      value: "INVOICED"
    },
    {
      key: "4",
      text: "Pagado",
      value: "PAID_OUT"
    }, {
      key: "5",
      text: "Cancelado",
      value: "CANCELLED"
    },{
      key: "6",
      text: "NA",
      value: "NA"
    },{
      key: "7",
      text: "Autorizado",
      value: "AUTHORIZED"
    },{
      key: "8",
      text: "Pagado parcialmente",
      value: "PAID_PARTIALLY"
    }],
    typeDocument: {
      ESTIMATION: "estimación",
      DEDUCTIVE: "deductiva"
    },
    openConfirmDelete: false,
    aditive4Delete: null,
    document4Delete: null,
    isUpdate: false,
    originalContract: {
      finishDate: this.context.contract.finishDate,
      realFinishDate: this.context.contract.realFinishDate,
      aditives: this.context.contract.aditives
    }
  }

  openModal = (dataModal) => {
    const { componentProps, data, aditives, readOnly, isUpdate, idAditive, amountAditives } = dataModal;
    const { modaltitle, showfiles, documenttype } = componentProps;
    const { id: idContract } = this.props;

    if (documenttype === "ADITIVE") {
      const {currentItem: stateCurrentItem } = this.state;

      const currentItem = Object.assign(stateCurrentItem,
        { ...data,
          idContract,
          consecutive: `Aditiva ${aditives.length + 1}`,
          amountAditives
        })

      return this.setState({
        showFileModal: true,
        modalTitle: modaltitle,
        currentItem,
        readOnly,
        isUpdate,
        documentType: documenttype
      });

    } else {
      if (aditives.length > 0 ) {
        const { currentDocumentItem: stateCurrentDocumentItem } = this.state;
        const aditive = data ? aditives.find(aditive => aditive.id === idAditive) : Object.assign([], aditives).pop();
        const summarySumDocuments = this.summarySumDocuments(aditive, data ? data.id : null);

        const currentDocumentItem = Object.assign(stateCurrentDocumentItem,
          { ...data,
            idContract,
            currency: aditive.currency,
            idAditive: aditive.id,
            totalContrated: aditive.total,
            totalInvoiced: aditive.invoiced,
            totalPaided: aditive.paided,
            amountDeductives: aditive.amountDeductives,
            contractAmountDeductives: dataModal.contractAmountDeductives,
            type: documenttype
          })

        this.addStatus([currentDocumentItem.status]);

        this.setState({
          ...summarySumDocuments,
          showFileModal: true,
          modalTitle: modaltitle,
          showFiles: showfiles,
          currentDocumentItem,
          readOnly,
          documentType: documenttype
        });
      } else {
        return alert("Este documento no puede ser creado sin una previa estimación");
      }
    }
  }

  summarySumDocuments = (aditive, excludeId) => {
    const estimations = aditive.documentsAditivas.filter(aditive => {
      return aditive.type === "ESTIMATION" &&
      aditive.status !== "CANCELLED" &&
      aditive.id !== excludeId;
    });
    const deductives = aditive.documentsAditivas.filter(aditive => {
      return aditive.type === "DEDUCTIVE" &&
      aditive.status !== "CANCELLED" &&
      aditive.id !== excludeId;
    });

    return {
      summarySumEstimations:  _.sumBy(estimations, "amount"),
      summarySumDeductives: _.sumBy(deductives, "amount"),
    }
  }

  // reset state and close view
  closeModal = () => {
    const { currentItem, currentDocumentItem } = this.state;

    const currentItemClean = {
      id: "",
      consecutive: "",
      total: 0,
      invoiced: 0,
      paided: 0,
      currency: "",
      startDate: "",
      finishDate: "",
      description: "",
      amountAditives: 0,
    };

    Object.assign(currentItem, {...currentItemClean});

    const errors = {
      startDate: false,
      finishDate: false,
      total: false,
      currency: false,
      description: false,
    }

    const currentDocumentItemClean = {
      id: "",
      idContract: "",
      idAditive: "",
      concept: "",
      status: "BY_AUTHORIZING",
      amount: 0,
      iva: 0,
      amountIva: 0,
      total: 0,
      type: "",
      currency: "",
      authorizer: "",
      totalContrated: 0,
      totalInvoiced: 0,
      totalPaided: 0,
      amountDeductives: 0,
      refUpload: []
    };

    Object.assign(currentDocumentItem, {...currentDocumentItemClean})

    const errorsDocument = {
      dateElaborated: false,
      status: false,
      concept: false,
      elaborated: false,
      amount: false,
      iva: false,
      amountIva: false,
      total: false,
    };

    this.setState({
      showFileModal: false,
      modalTitle: "",
      showFiles: "false",
      isLoading: false,
      documentType: "",
      documentStatus: [this.state.statusOptions[0]],
      currentItem,
      errors,
      currentDocumentItem,
      errorsDocument,
      summarySumEstimations: 0,
      summarySumDeductives: 0,
      cancelbutton: true,
      readOnlyAuthorized: true
    });
  }

  addStatus = (status, cancelbutton = true, type = null) => {
    let { statusOptions, currentDocumentItem, readOnlyAuthorized } = this.state;
    const { bill, payment } = this.getTotalType(currentDocumentItem);
    const documentStatus = [];

    if (status[0] === "BY_AUTHORIZING")
      readOnlyAuthorized = false;

    if (status[0] === "CANCELLED")
      status = ["CANCELLED"];

    if (status[0] === "AUTHORIZED" && type === "DEDUCTIVE")
      status = ["CANCELLED", "AUTHORIZED"];

    if (status[0] === "PAID_OUT")
      status = ["CANCELLED", "PAID_OUT"];

    if (status[0] === "INVOICED" && payment)
      status = ["CANCELLED", "PAID_PARTIALLY"];
    else if (status[0] === "INVOICED" && !bill && !cancelbutton)
      status = ["CANCELLED", "BY_BILL"];

    if (status[0] === "INVOICED")
      status = ["CANCELLED", "INVOICED"];

    if (status[0] === "AUTHORIZED")
      status = ["CANCELLED", "BY_BILL", "AUTHORIZED"];

    if (status[0] === "PAID_PARTIALLY")
      status = ["CANCELLED", "PAID_PARTIALLY"];

    if (status[0] === "PAID_OUT")
      status = ["CANCELLED", "PAID_OUT"];

    if (status[0] === "BY_BILL" && payment)
      status = ["CANCELLED", "PAID_PARTIALLY"];
    else if (status[0] === "BY_BILL")
      status = ["CANCELLED", "BY_BILL"];

    status.forEach(status => {
      statusOptions.forEach(option => {
        if (status === option.value) {
          if (!documentStatus.find(doc => doc.value === status)) {
            documentStatus.push(option)
          }
        }
      })
    });

    this.setState(data => ({ ...data,
      documentStatus,
      cancelbutton,
      readOnlyAuthorized,
      currentDocumentItem: { ...data.currentDocumentItem,
        status: _.last(documentStatus).value
      }
    }));
  }

  authSuccess = async (pass) => {
    const { currentDocumentItem: { type }, user } = this.state;
    const authorizer = await AUTHORIZER_USER(user.id, pass);

    if (authorizer) {
      if (type === "DEDUCTIVE")
        this.addStatus(["AUTHORIZED"], true, type);
      else
        this.addStatus(["AUTHORIZED"], true, type);

      return this.setState(data => ({
        ...data,
        currentDocumentItem: { ...data.currentDocumentItem,
          authorizer
        }
      }));
    }

    alert("Contraseña incorrecta");
  }

  getForm = (documentType) => {
    if(documentType === "DEDUCTIVE" || documentType === "ESTIMATION") {
      const contextValues = {
        currentItem: this.state.currentDocumentItem,
        documentStatus: this.state.documentStatus,
        errors: this.state.errorsDocument,
        change: this.change,
        authSuccess: this.authSuccess,
        addPayment: this.addPayment,
        addStatus: this.addStatus,
        subtractPayment: this.subtractPayment
      }

      return <ContractDocumentContext.Provider value={contextValues}>
        <ContractDocumentForm
          showFiles={this.state.showFiles}
          readOnly={this.state.readOnly}
          readOnlyAuthorized={this.state.readOnlyAuthorized}
        />
      </ContractDocumentContext.Provider>;

    } else if (documentType === "ADITIVE") {

      const contextValues = {
        currentItem: this.state.currentItem,
        errors: this.state.errors,
        change: this.change,
        contract: this.context.contract,
      }

      return <ContractDocumentContext.Provider value={contextValues}>
        <AditiveForm
          isUpdate={this.state.isUpdate}
          readOnly={this.state.readOnly}
        />
      </ContractDocumentContext.Provider>;
    }

    return null;
  }

  // change state value
  change = (event, data) => {
    let { name, value } = data;

    /* Escape textarea input clean */
    if (name === "description" || name === "concept") {
      value = helper.cleaningData(value);
    }

    if (this.state.documentType === "ADITIVE") {
      const currentItem = Object.assign({}, this.state.currentItem);

      if (name === "total")
        if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value))
          currentItem["total"] = '';

      currentItem[name] = value;
      return this.setState({ currentItem });
    }

    const currentDocumentItem = Object.assign({}, this.state.currentDocumentItem);

    // update amountIva, total
    if (name === "amount") {
      if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value)) {
        currentDocumentItem['amount'] = '';
      }

      const amountIva = (+currentDocumentItem.iva / 100) * +value;
      const total = +value + amountIva;

      currentDocumentItem["amountIva"] = parseFloat(amountIva).toFixed(2);
      currentDocumentItem["total"] = parseFloat(total).toFixed(2);

    } else if (name === "iva") {
      if (!/^[1-9]*$|^[1-9]+[0-9]?/.test(value)) {
        currentDocumentItem['iva'] = '';
      }

      const amountIva = (+value / 100) * +currentDocumentItem.amount;
      const total = +currentDocumentItem.amount + amountIva;

      currentDocumentItem["amountIva"] = parseFloat(amountIva).toFixed(2);
      currentDocumentItem["total"] = parseFloat(total).toFixed(2);
    }

    currentDocumentItem[name] = value;
    this.setState({ currentDocumentItem });
  }

  // return object with values in the state to validate
  contractorValidateFields = (errors, currentItem) => {
    let fieldsValue = {};

    for (const field in errors) fieldsValue[field] = currentItem[field];

    return fieldsValue;
  }

  validateFields = validationObject => {
    let errors = [];
    const validation = validator(validationObject);

    validation.forEach(field => {
      const key = field.key;
      if (!field.isValid) {
        errors.push(key);
      }
    });

    return errors;
  };

  // set errors values in state to false
  cleanErrors = () => {
    const errors = Object.assign({}, this.state.errors);

    for(const prop in errors) errors[prop] = false;

    return errors;
  }

  // save value of currentItem
  save = async () => {
    // launch loading
    this.setState({ isLoading: true });

    let { originalContract: { realFinishDate }, documentType } = this.state;
    const currentItem = documentType === "ADITIVE" ? this.state.currentItem : this.state.currentDocumentItem;
    const errors = documentType === "ADITIVE" ? this.state.errors : this.state.errorsDocument;
    const errorKey = documentType === "ADITIVE" ? "errors" : "errorsDocument";

    // validate inputs
    // get contractor errors state object
    const validationErrors = Object.assign({}, errors);
    // get inputs to validate
    const contractValidateObj = this.contractorValidateFields(errors, currentItem);
    const contractErrors = this.validateFields(contractValidateObj);

    if (contractErrors.length > 0) {
      Object.keys(validationErrors).forEach((key) => {
        validationErrors[key] = contractErrors.indexOf(key) !== -1;
      });

      this.setState(data => ({
        [errorKey]: validationErrors,
        isLoading: false,
      }));

      return false;
    }

    try {
      if (documentType === "ADITIVE") {
        const { amountAditives, total, startDate, finishDate } = currentItem;
        currentItem.amountAditives = +amountAditives + +total;

        const newRealFinishDate = moment(realFinishDate, "YYYY-MM-DD");
        const newStartDate =  moment(startDate, "YYYY-MM-DD");
        const newFinishDate = moment(finishDate, "YYYY-MM-DD");

        if (newStartDate.diff(newRealFinishDate) < 0 || newFinishDate.diff(newRealFinishDate) < 0) {
          this.setState({ isLoading: false });
          return alert("No puedes crear la aditiva, la fecha debe ser mayor a la termino del contrato");
        } else {
          const { data: { updateContract: { aditives } } } = await CREATE_ADITIVE_CONTRACT(currentItem, refethQueries);
          this.setState(prevState => ({
            originalContract: {
              ...prevState.originalContract,
              realFinishDate: finishDate,
              aditives
            }
          }))
        }
      } else {
        if (documentType === "DEDUCTIVE") {
          if (!this.operationsResolveDeductive(currentItem)) {
            this.setState({ isLoading: false });
            return alert("El importe de la deductiva excede el total de la aditiva");
          }
        } else {
          if (!this.operationsResolveEstimation(currentItem)) {
            this.setState({ isLoading: false });
            return alert("El importe de la estimación excede el total de la aditiva")
          }
        }
        await CREATE_DOCUMENT_ADITIVE(currentItem, refethQueries);
      }
      this.closeModal();
    } catch (error) {
      bugsnagClient.notify(error.message);
    }
  }

  operationsResolveDeductive = (currentItem) => {
    const { amount, status, totalContrated } = currentItem;
    const total = parseFloat(amount);
    const { summarySumDeductives } = this.state;

    if ((summarySumDeductives + total) > totalContrated)
      return false;

    if (status === "AUTHORIZED") {
      currentItem.amountDeductives += +amount;
      currentItem.contractAmountDeductives += +amount;
      currentItem.status = "PAID_OUT";
    }

    if (status === "CANCELLED") {
      currentItem.amountDeductives -= +amount;
      currentItem.contractAmountDeductives -= +amount;
    }

    return true
  }

  operationsResolveEstimation = (currentItem) => {
    const { totalContrated, amount, status, totalInvoiced, totalPaided } = currentItem;
    const total = parseFloat(amount);
    const { summarySumEstimations, summarySumDeductives } = this.state;
    const { bill, payment } = this.getTotalType(currentItem);

    if ((summarySumEstimations + total) > (totalContrated - summarySumDeductives))
      return false;

    if (status === "CANCELLED") {
      currentItem.totalInvoiced = bill ? (totalInvoiced - bill.total) : totalInvoiced;
      currentItem.totalPaided = payment ? (totalPaided - payment.total) : totalPaided;
    }

    return true;
  }

  addPayment = (amountType, value) => {
    const { currentDocumentItem } = this.state;
    const { bill, payment } = this.getTotalType(currentDocumentItem);

    const total = {
      totalInvoiced: value + (bill ? bill.total : 0),
      totalPaided: value + (payment ? payment.total : 0)
    }

    if (total[amountType] <= currentDocumentItem.amount) {
      const data = {
        name : amountType,
        value: value + currentDocumentItem[amountType]
      }
      this.change(null, data);

      if (total[amountType] === currentDocumentItem.amount)
        return "paidout";
      return "next";
    } else {
      return "exceeded";
    }
  }

  subtractPayment = async (data) => {
    const { currentItem, idRef, reference, totalReference } = data;
    const subtract = currentItem.refUpload.find(item => item.reference === reference);
    const totalSubtracted = currentItem[totalReference] - parseFloat(subtract.wildcard);
    let props = {
      idContract: currentItem.idContract,
      idAditive: currentItem.idAditive,
      idDocumentAditive: currentItem.id,
      idRef,
      invoiced: currentItem.totalInvoiced,
      paided: currentItem.totalPaided,
      amountDeductives: currentItem.amountDeductives
    }

    if (idRef) {
      try {
        if (subtract.extension === "xml") {
          if (totalReference === "totalInvoiced")
            props = {...props, invoiced: totalSubtracted};

          if (totalReference === "totalPaided")
            props = {...props, paided: totalSubtracted};
        }
        await DELETE_DOCUMENT_ADITIVE_ITEM(props, refethQueries);
      } catch (error) {
        bugsnagClient.notify(error);
      }
    }

    const items = currentItem.refUpload.filter(item => item.reference !== reference);

    await this.setState(data => ({
      currentDocumentItem: { ...data.currentDocumentItem,
        [totalReference] : totalSubtracted,
        refUpload: items
      }
    }));

    if(totalSubtracted === 0 && totalReference === "totalInvoiced")
      this.addStatus(["BY_BILL"], false);

    if(totalSubtracted === 0 && totalReference === "totalPaided")
      this.addStatus(["INVOICED"], false);
  }

  showConfirmDelete = (aditive4Delete = null, document4Delete = null) => {
    this.setState({
      openConfirmDelete: !this.state.openConfirmDelete,
      aditive4Delete,
      document4Delete
    });
  }

  getTotalType = (item2Delete) => {
    const result = _(item2Delete.refUpload)
      .filter(obj => obj.extension === "xml").groupBy("type")
      .map((obj, key) => ({ "type": key, "total": _.sumBy(obj, o => parseFloat(o.wildcard))
    })).value();

    return {
      bill: result.find(total => total.type === "BILL"),
      payment: result.find(total => total.type === "PAYMENT_COMPLEMENT")
    }
  }

  checkConsecAditiveDelete = (aditives, originalFinishDate, id) => {
    const indexOf = aditives.findIndex(element => element.id === id);

    if (indexOf !== 0 || aditives.length === 1) {
      const filter = aditives.filter(element => element.id !== id);
      if (filter.length > 0) {
        return filter[filter.length - 1].finishDate;
      } else {
        return originalFinishDate;
      }
    } else {
      alert('Las aditivas deben ser borradas en orden ascendentes');
      this.showConfirmDelete();
    }
  }


  deleteAditiveContract = async () => {
    const { id: idContract } = this.props;
    const { aditive4Delete: aditive, document4Delete: doc, originalContract: { finishDate, aditives } } = this.state;
    try {
      if (doc) {
        const { bill, payment } = this.getTotalType(doc);

        let props = {
          idContract,
          idAditive: aditive.id,
          idDocumentAditive: doc.id,
          invoiced: aditive.invoiced,
          paided: aditive.paided,
          amountDeductives: aditive.amountDeductives,
          contractAmountDeductives: aditive.contractAmountDeductives
        }

        if (doc.status !== "CANCELLED") {
          props = { ...props,
            invoiced: bill ? (aditive.invoiced - bill.total) : aditive.invoiced,
            paided: payment ? (aditive.paided - payment.total) : aditive.paided,
            amountDeductives: doc.type === "DEDUCTIVE" ?
              (aditive.amountDeductives - doc.amount) : aditive.amountDeductives,
            contractAmountDeductives: doc.type === "DEDUCTIVE" ?
              (aditive.contractAmountDeductives - doc.amount) : aditive.contractAmountDeductives
          }
        }

        await DELETE_DOCUMENT_ADITIVE(props, refethQueries);
        this.showConfirmDelete();
      } else {
        const newFinishDate = this.checkConsecAditiveDelete(aditives, finishDate, aditive.id);
        const newAditives = aditives.filter(adv => adv.id !== aditive.id)

        const props = {
          idContract,
          idAditive: aditive.id,
          amountAditives: (aditive.amountAditives - aditive.total),
          newFinishDate
        }

        await DELETE_ADITIVE_CONTRACT(props, refethQueries);

        this.setState(prevState => ({
          originalContract: {
            ...prevState.originalContract,
            realFinishDate: newFinishDate,
            aditives: newAditives
          }
        }))

        this.showConfirmDelete();
      }
    } catch (error) {
      bugsnagClient.notify(error.message);
    }
  }

  render() {
    const { id } = this.props;

    return (
      <Query query={GET_ADITIVES_CONTRACT} variables={{ id }}>
        {({ loading, error, data, updateQuery }) => {
          if (loading) {
            return (
              <Container fluid className="Wrapper__Body-Loader">
                <Dimmer active inverted >
                  <Loader inverted content='Cargando...' />
                </Dimmer>
              </Container>
            );
          }

          if (data === undefined) {
            return (
              <Container fluid textAlign="center">
                "No se encontraron resultados para la búsqueda"
              </Container>
            );
          }

          if (error) {
            return (
              <Container fluid className="Wrapper__Body-Loader">
                <Dimmer active inverted>
                  <Loader inverted content='Error' />
                </Dimmer>
              </Container>
            )
          }

          const { project: { cli },
            currency,
            aditives,
            amountDeductives,
            amountAditives
          } = data.contract;

          let total = 0;
          let invoiced = 0;
          let paided = 0;
          let rowDocs = []

          const rowAditives = aditives.map((item) => {
            total += item.total
            invoiced += item.invoiced
            paided += item.paided

            return (
              <Table.Row key={item.id} textAlign="center">
                <Table.Cell>{item.consecutive}</Table.Cell>
                <Table.Cell>{format.currency(item.total, currency)}</Table.Cell>
                <Table.Cell>{currency}</Table.Cell>
                <Table.Cell>{parseDate(item.startDate)}</Table.Cell>
                <Table.Cell>{parseDate(item.finishDate)}</Table.Cell>
                <Table.Cell textAlign="right">
                  <ButtonGroup basic>
                    {!this.props.readonly &&
                      <React.Fragment>
                        <Can do="edit" on="AditivesContract">
                          <Button icon
                            documenttype="ADITIVE"
                            modaltitle="Datos de la aditiva"
                            onClick={(event, componentProps) => {
                              this.openModal({
                                componentProps,
                                data: item,
                                aditives,
                                readOnly: null,
                                isUpdate: true,
                                amountAditives: 0
                              })
                            }}>
                            <IoMdCreate />
                          </Button>
                        </Can>
                        <Can do="delete" on="AditivesContract">
                          <Button icon onClick={() =>
                            this.showConfirmDelete({...item, amountAditives }, null)}>
                            <IoIosTrash />
                          </Button>
                        </Can>
                      </React.Fragment>
                    }
                    <Can do="detail" on="AditivesContract">
                      <Button icon
                        documenttype="ADITIVE"
                        modaltitle="Datos de la aditiva"
                        onClick={(event, componentProps) => {
                          this.openModal({
                            componentProps,
                            data: item,
                            aditives,
                            readOnly: true
                          });
                        }}>
                        <IoMdEye />
                      </Button>
                    </Can>
                  </ButtonGroup>
                </Table.Cell>
              </Table.Row>
            );
          });

          aditives.forEach((item) => {
            const idAditive = item.id;
            rowDocs.push(item.documentsAditivas.map((doc) => {
              const modalTitle = `Datos de la ${this.state.typeDocument[doc.type]}`;
              const showFiles = doc.type === "ESTIMATION" || doc.type === "ADVANCED_PAYMENT" ? "true" : "false";

              return (
                <Table.Row key={doc.id} textAlign="center">
                  <Table.Cell>{format.typeDocumentsContract(doc.type)}</Table.Cell>
                  <Table.Cell>{cli}</Table.Cell>
                  <Table.Cell>{`${doc.concept} - ${item.consecutive}`}</Table.Cell>
                  <Table.Cell>{currency}</Table.Cell>
                  <Table.Cell>{parseDate(doc.dateElaborated)}</Table.Cell>
                  <Table.Cell>{format.currency(doc.amount, currency)}</Table.Cell>
                  <Table.Cell>{format.currency(doc.total, currency)}</Table.Cell>
                  <Table.Cell>{doc.elaborated}</Table.Cell>
                  <Table.Cell>{doc.authorizer}</Table.Cell>
                  <Table.Cell>{format.statusDocumentContract(doc.status)}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <ButtonGroup basic>
                      {!this.props.readonly &&
                        <React.Fragment>
                          <Can do="edit" on="AditivesContract">
                            <Button
                              documenttype={doc.type}
                              modaltitle={modalTitle}
                              showfiles={showFiles}
                              onClick={(event, componentProps) => {
                                this.openModal({
                                  componentProps,
                                  data: doc,
                                  aditives,
                                  idAditive,
                                  readOnly: null,
                                  contractAmountDeductives: amountDeductives
                                })
                              }}
                              icon>
                              <IoMdCreate />
                            </Button>
                          </Can>
                          <Can do="delete" on="AditivesContract">
                            <Button
                              icon
                              onClick={() => this.showConfirmDelete({
                                ...item,
                                contractAmountDeductives: amountDeductives
                              }, doc)}>
                              <IoIosTrash />
                            </Button>
                          </Can>
                        </React.Fragment>
                      }
                      <Can do="detail" on="AditivesContract">
                        <Button
                          documenttype={doc.type}
                          modaltitle={modalTitle}
                          showfiles={showFiles}
                          onClick={(event, componentProps) => {
                            this.openModal({
                              componentProps,
                              data: doc,
                              aditives,
                              idAditive,
                              readOnly: true,
                            });
                          }}
                          icon>
                          <IoMdEye />
                        </Button>
                      </Can>
                    </ButtonGroup>
                  </Table.Cell>
                </Table.Row>
              )
            }))
          })

          return (
            <React.Fragment>
              <Grid.Row className="Modal__SubTabNav-Row-Align">
                <Grid.Column width="8">
                  {/* Numeric Box */}
                  <NumericBox
                    totalContracted={format.currency(total, currency)}
                    totalCharged={format.currency(invoiced, currency)}
                    totalPaid={format.currency(paided, currency)}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Header as="h3" dividing className="Modal__Form-Header">
                    Aditivas
                  </Header>
                  <Table basic="very" compact="very" textAlign="center" className="Modal__Form-Table" sortable>
                    <Table.Header className="Modal__Form-Table-Header Small">
                      <Table.Row>
                        <Table.HeaderCell>
                          Tipo
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Monto de la aditiva
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Moneda
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Inicio
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Termino
                        </Table.HeaderCell>
                        <Table.HeaderCell className="Modal__Form-Table-Header-NoHover"/>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body className="Modal__Form-Table-Body Small">
                      {rowAditives}
                    </Table.Body>
                  </Table>
                  <Confirm
                    open={this.state.openConfirmDelete}
                    cancelButton="Cancelar"
                    confirmButton="Aceptar"
                    onCancel={this.showConfirmDelete}
                    onConfirm={this.deleteAditiveContract}
                    content={`¿Desea eliminar el registro? Esta acción no es reversible`}
                  />
                </Grid.Column>
              </Grid.Row>

              <Can do="create" on="AditivesContract">
                {!this.props.readonly &&
                  <Grid.Row textAlign="center" className="Modal__SubTabNav-Row-Align">
                    <Grid.Column>
                      {/* advance modal */}
                      <Button
                        documenttype="ADITIVE"
                        modaltitle="Nueva aditiva"
                        onClick={(event, componentProps) => {
                          this.openModal({
                            componentProps,
                            data: null,
                            readOnly: null,
                            aditives,
                            amountAditives
                          })
                        }}
                        className="Modal__Form-ButtonAdd"
                        icon="plus"
                        content="Añadir aditiva"/>
                    </Grid.Column>
                  </Grid.Row>
                }
              </Can>
              {/* list */}
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3" dividing className="Modal__Form-Header">
                    Documentos
                  </Header>
                  <Table basic="very" compact="very" textAlign="center" className="Modal__Form-Table" sortable>
                    <Table.Header className="Modal__Form-Table-Header Small">
                      <Table.Row>
                        <Table.HeaderCell>
                          Tipo
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Presupuesto Cli
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Concepto
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Moneda
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Fecha
                          </Table.HeaderCell>
                        <Table.HeaderCell>
                          Importe
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Total
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Elaboró
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Autorizó
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Estatus
                        </Table.HeaderCell>
                        <Table.HeaderCell className="Modal__Form-Table-Header-NoHover"/>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body className="Modal__Form-Table-Body Small">
                      {rowDocs}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>

              <Can do="create" on="AditivesContract">
                {!this.props.readonly &&
                  <Grid.Row textAlign="center" className="Modal__SubTabNav-Row-Align">
                    <Grid.Column width="3">
                      {/* deductive modal */}
                      <Button
                        documenttype="DEDUCTIVE"
                        modaltitle="Nueva deductiva"
                        onClick={(event, componentProps) => {
                          this.openModal({
                            componentProps,
                            data: null,
                            aditives,
                            readOnly: null,
                            contractAmountDeductives: amountDeductives
                          });
                        }}
                        className="Modal__Form-ButtonAdd"
                        icon="plus"
                        content="Añadir deductiva"
                        showfiles="false"/>
                    </Grid.Column>
                    <Grid.Column width="3">
                      {/* estimation modal */}
                      <Button
                        documenttype="ESTIMATION"
                        modaltitle="Nueva estimación"
                        onClick={(event, componentProps) => {
                          this.openModal({
                            componentProps,
                            data: null,
                            aditives,
                            readOnly: null,
                            contractAmountDeductives: amountDeductives
                          });
                        }}
                        className="Modal__Form-ButtonAdd"
                        icon="plus"
                        content="Añadir estimación"
                        showfiles="true"/>
                    </Grid.Column>
                  </Grid.Row>
                }
              </Can>

              <Modal
                className="Modal__Form-SubModal"
                centered={true}
                open={this.state.showFileModal}
                header={
                  <ModalHeader
                    component={false}
                    title={this.state.modalTitle}
                    save={this.save}
                    cancel={this.closeModal}
                    cancelbutton={this.state.cancelbutton}
                    disabled={this.state.isLoading}
                    readOnly={this.state.readOnly}
                  />
                }
                content={this.getForm(this.state.documentType)}
                size="large"
                dimmer="blurring"
              />
            </React.Fragment>
          );
        }}
      </Query>
    )
  }
}

ContractAditivesList.contextType = ContractDocumentContext;

export default ContractAditivesList;
