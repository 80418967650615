/**
 * @todo Implement connector injection to allow to use any feature flags service
 * to avoid dependence of the current Split version and allow us to update it
 * or even change our Third Party Service Provider
 */
// eslint-disable-next-line no-unused-vars
export const useFeatureFlags = featureFlagsService => {
  // @todo this hook logic should be moved to an SplitFeatureFlagsService collaborator

  return {
    contractors: {
      contracts: {
        admin: {
          edit: true,
        },
      },
    },
  };
};
