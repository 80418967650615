import gql from "graphql-tag"

const GET_CONTRACTS_ADITIVES = gql `
  query contractsAC($first: Int, $skip: Int) {
    contractAccountBalances(first: $first, skip: $skip, orderBy: createdAt_DESC) {
      id
      IDFolio
      project {
        name
        cli
        status  
      }
      startDate
      finishDate
      currency
      amountContract
      amountBilled
      amountPaid
      status
      aditives {
        id
        startDate
        finishDate
        amountContract
        amountBilled
        amountPaid
        status
      }
    }
    count: contractAccountBalances {
      IDFolio
    }
  }
`;

export default GET_CONTRACTS_ADITIVES;
