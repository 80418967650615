import React from "react";
import FormHeader from "./components/FormHeader";
import { routes } from "./routes";
import { withRouter } from "react-router-dom";

export default withRouter(
  class EditReceptionContainer extends React.Component {
    constructor(props) {
      super(props);
      let shipmentDate = props.requisition.shipmentDate
        ? props.requisition.shipmentDate
        : false;
      let arrivalDate = props.requisition.arrivalDate
        ? props.requisition.arrivalDate
        : false;

      if (shipmentDate) {
        shipmentDate = this.dateToString(shipmentDate);
      }

      if (arrivalDate) {
        arrivalDate = this.dateToString(arrivalDate);
      }

      this.state = {
        activeRoute: routes[0],
        fields: {
          shipmentDate: shipmentDate ? shipmentDate : "",
          trackingNumber: props.requisition.trackingNumber
            ? props.requisition.trackingNumber
            : "",
          shippingCompany: props.requisition.shippingCompany
            ? props.requisition.shippingCompany
            : "",
          shippingNotes: props.requisition.shippingNotes
            ? props.requisition.shippingNotes
            : "",
          arrivalDate: props.requisition.arrivalDate
            ? props.requisition.arrivalDate
            : null,
        },
      };
    }

    dateToString = date => {
      const split = date.split("-");
      const year = split[0],
        month = split[1],
        day = split[2].slice(0, 2);
      return `${year}-${month}-${day}`;
    };

    navigateTo = route => {
      this.setState({ activeRoute: route });
    };

    onCancel = () => this.props.history.push("/inventarios");

    onSave = () => {
      const variables = {
        data: {
          data: {
            update: {
              trackingNumber: this.state.fields.trackingNumber,
              shippingCompany: this.state.fields.shippingCompany,
              shippingNotes: this.state.fields.shippingNotes,
              arrivalDate: this.state.fields.arrivalDate
                ? this.state.fields.arrivalDate
                : null,
            },
          },
        },
        where: {
          id: this.props.id,
        },
      };

      if (this.state.fields.shipmentDate)
        variables.data.data.update["shipmentDate"] = new Date(
          this.state.fields.shipmentDate
        );
      else variables.data.data.update["shipmentDate"] = null;

      this.props.updatePurchaseOrder({ variables }).then(() => {
        this.props.history.push("/inventarios");
      });
    };

    onChangeField = e => {
      let { value, name } = e.target;
      let fields = Object.assign({}, this.state.fields);
      fields[name] = value;
      this.setState({ fields });
    };

    render() {
      const { item: Item } = this.state.activeRoute;

      return (
        <React.Fragment>
          <FormHeader
            text="Recepción"
            onCancel={this.onCancel}
            onSave={this.onSave}
            activeRoute={this.state.activeRoute}
            navigateTo={this.navigateTo}
            routes={routes}
            submitting={this.props.submitting}
          />
          {Item && (
            <Item
              {...this.props}
              fields={this.state.fields}
              onChange={this.onChangeField}
            />
          )}
        </React.Fragment>
      );
    }
  }
);
