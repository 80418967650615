import React from "react";
import { Query } from "react-apollo";
import { Container, Dimmer, Loader, Button, Form, Header, Input, Grid, Segment, TextArea } from "semantic-ui-react";
import moment from "moment";
import { SelectClient, SelectUserRol } from "../../Molecules";
import getProject from "../../../graphql/queries/projects/getProject"

export default class DetailProject extends React.Component {
  render() {
    const { id, onCancel } = this.props;

    return (
      <Segment raised className="Modal">
        <Header as="h3" dividing>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column textAlign="left" className="Modal__Header-Title">
                Detalle de proyecto
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Button id="cancel-detailpo-btn" onClick={onCancel}>
                  Cancelar
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <Query query={getProject} variables={{ id }} fetchPolicy={"network-only"}>
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Container fluid className="Wrapper__Body-Loader">
                  <Dimmer active inverted >
                    <Loader inverted content='Cargando...' />
                  </Dimmer>
                </Container>
              )
            }
            if (error) {
              return (
                <Container fluid className="Wrapper__Body-Loader">
                  <Dimmer active inverted>
                    <Loader inverted content='Error' />
                  </Dimmer>
                </Container>
              )
            }

            const { name, client: { id: cliId }, cli, startDate, finishDate, status, address, responsibles, residents } = data.project
            const selectResponsibles = responsibles.map(responsible => responsible.id)
            const selectResidents = residents.map(resident => resident.id)

            return (
              <>
                <Form className="Modal__Form">
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="NOMBRE DEL PROYECTO"
                      name="name"
                      value={name}
                      readOnly
                    />
                    <Form.Field
                      control={SelectClient}
                      label="SELECCIONAR CLIENTE"
                      name="cliId"
                      value={cliId}
                      disabled={true}
                    />
                    <Form.Field
                      control={Input}
                      label="PRESUPUESTO CLI"
                      name="cli"
                      value={cli}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      label="FECHA INICIA"
                      name="startDate"
                      value={moment(startDate).add(1, 'day').format("DD/MM/YYYY")}
                      readOnly
                    />
                    <Form.Field
                      control={Input}
                      type="text"
                      label="FECHA TERMINA"
                      name="finishDate"
                      value={moment(finishDate).add(1, 'day').format("DD/MM/YYYY")}
                      readOnly
                    />
                    <Form.Field
                      control={Input}
                      label="ESTATUS"
                      name="status"
                      value={status === "ACTIVE" ? "Active" : "Inactivo"}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Field
                    label="DIRECCIÓN"
                    control={TextArea}
                    name="address"
                    readOnly
                    value={address}
                  />
                </Form>
                <Header as="h3" dividing className="Modal__Form-Header">
                  Responsables
                </Header>
                <Form className="Modal__Form" width={5}>
                  <Form.Group style={{ display: "flex", justifyContent: "center" }}>
                    <Form.Field
                      control={SelectUserRol}
                      width={8}
                      rol={["MANAGER", "ADMINISTRATIVE_COORDINATOR"]}
                      label="SELECCIONA RESPONSABLE DEL PROYECTO"
                      name="responsibles"
                      value={selectResponsibles}
                      disabled={true}
                    />
                  </Form.Group>
                  <Header as="h3" dividing className="Modal__Form-Header">
                    Residentes
                  </Header>
                  <Form.Group style={{ display: "flex", justifyContent: "center" }}>
                    <Form.Field
                      control={SelectUserRol}
                      width={8}
                      rol={["TECHNICIAN"]}
                      label="SELECCIONA RESIDENTE DEL PROYECTO"
                      name="residents"
                      value={selectResidents}
                      disabled={true}
                    />
                  </Form.Group>
                </Form>
              </>
            )
          }}
        </Query>
      </Segment>
    );
  }
}
