import React from "react";
import { Icon, Grid, Segment, Header, Button } from "semantic-ui-react";
import moment from "moment";
import { Can } from "../../hoc/permissions";
import { ViewDocumentSegment, ViewDocumentLink } from "../../Molecules";
import { format, listMonthlyDocumentsContractor } from "../../../helpers";
import { bugsnagClient } from "../../../bugsnag";
import lodash from "lodash";

class ContractMonthlyDocuments extends React.Component {
  state = {
    months: [],
    currentMonthIndex: 0,
    currentMonthLabel: "",
    contractorDocuments: null,
    disabledSave: true,
    references: null,
  }

  componentDidMount() {
    this.setMonthTabs();
    this.updateContract();
  }

  componentDidUpdate(prevProps) {
    const { documents } = this.props;

    // documents created
    if ((prevProps.documents === null && documents)) {
      this.updateContract();
    } else if (prevProps.documents !== null && documents) {
      if (prevProps.documents.length !== documents.length) {
        this.updateContract();
      }
    }
  }

  setMonthTabs = () => {
    const { startDate, finishDate } = this.props;
    const months = [];
    const start = moment(startDate, "YYYY-MM-DD");
    const rangeMonths = format.howDocumentMonthly(startDate, finishDate);

    for (let i = 1; i <= rangeMonths; i++) {
      const newDate = moment(start.add(i === 1 ? 0 : 1, 'months').format("YYYY-MM-DD"));
      const title = newDate.format("MMM, YYYY");
      const sublabel = newDate.format("[MONTH_]MMYYYY");
      const normalLabel = newDate.format("YYYYMM");

      months.push({
        title,
        sublabel,
        normalLabel
      });
    }

    this.setState({
      months,
      currentMonthLabel: lodash.get(months, "[0].sublabel") || ""
    });
  }

  updateContract = () => {
    const { documents } = this.props;

    if (documents) {
      const contractorDocuments =  JSON.parse(JSON.stringify(documents));
      const references = [];

      contractorDocuments.forEach((element) => {
        references.push({
          id: element.refUpload[0].id,
          reference: element.refUpload[0].reference,
          wildcard: element.refUpload[0].wildcard,
          statusDocument: element.status
        });
      });

      this.setState({
        contractorDocuments,
        references,
      });
    }
  }

  changeMonth = (index) => {
    const { months } = this.state;
    const currentMonthLabel = months[index].sublabel;

    this.setState({
      currentMonthIndex: index,
      currentMonthLabel,
    });
  }

  onSelectFile = async (files, documentType, title, loadFinished, properties) => {
    const form = new FormData();

    if (files.length > 0) {
      form.append("file", files[0]);

      try {
        const res = await fetch(process.env.REACT_APP_S3, {
          method: "POST",
          body: form,
        });
        const file = await res.json();
        if (res.ok) {
          let { contractorDocuments } = this.state;
          const extension = format.getExtesion(file.imageUrl);
          const wilcard = properties.documentSubType;

          // add reference to contractorDocument object
          if (contractorDocuments) {
            const documentIndex = contractorDocuments.findIndex((element) => {
              return element.refUpload[0].wildcard === wilcard;
            });

            if (documentIndex !== -1) {
              contractorDocuments[documentIndex].description = title;
              contractorDocuments[documentIndex].refUpload.reference = file.imageUrl;
              contractorDocuments[documentIndex].refUpload.extension = extension;
            } else {
              contractorDocuments.push({
                type: documentType,
                description: title,
                refUpload: [
                  {
                    reference: file.imageUrl,
                    extension: extension,
                    wildcard: wilcard,
                  }
                ],
              });
            }
          } else {
            contractorDocuments = [{
              type: documentType,
              description: title,
              refUpload: [
                {
                  reference: file.imageUrl,
                  extension: extension,
                  wildcard: wilcard,
                }
              ],
            }];
          }

          this.setState({ contractorDocuments, disabledSave: false }, () => {
            loadFinished();
          });
        }
      } catch (err) {
        bugsnagClient.notify(err);
      }
    }
  }

  downloadHandler = (file) => {
    window.open(file, "_blank");
  }

  getDocumentRef = (wildcard) => {
    const { references } = this.state;

    if(references) {
      const findedReference = references.find((element) => {
        return element.wildcard === wildcard;
      });

      return typeof findedReference !== "undefined" ? findedReference.reference : null;
    }
    return null;
  }

  getStatusDocument = (wildcard) => {
    const { references } = this.state;

    if(references) {
      const findedReference = references.find((element) => {
        return element.wildcard === wildcard;
      });

      return typeof findedReference !== "undefined" ? findedReference.statusDocument : null;
    }
    return null;
  }

  saveDocuments = () => {
    const { contractorDocuments } = this.state;
    this.setState({
      disabledSave: true
    }, () => {
      this.props.saveContractDocument(contractorDocuments)
        .then(() => {
          this.setState({ disabledSave: false })
        });
    });
  }

  deleteDocument = (documentType, documentReference) => {
    const { references, contractorDocuments } = this.state
    const newReferences = references.filter(oneRef => oneRef.reference !== documentReference);
    const newContractorDocuments = contractorDocuments.filter(oneContractorDocument => oneContractorDocument.refUpload[0].reference === documentReference);
    const idDocument = newContractorDocuments[0].id;

    this.setState({
      disabledSave: true,
      contractorDocuments: null,
      references: newReferences
    }, () => {
      this.props.deleteContractDocument(idDocument, documentType)
        .then(() => this.setState({disabledSave: false }));
    });
  }

  disapproveDocumentHandler = (documentReference) => {
    const { references, contractorDocuments } = this.state
    const newReferences = references.map(oneRef => {
      if(oneRef.reference === documentReference) {
        return Object.assign(oneRef, {
          statusDocument: "BY_APPROVED"
        })
      }
      return oneRef
    });

    const newContractorDocuments = contractorDocuments.filter(oneContractorDocument => oneContractorDocument.refUpload[0].reference === documentReference);
    const idDocument = newContractorDocuments[0].id;

    this.setState({
      disabledSave: true,
      references: newReferences
    }, () => {
      this.props.rejectDocumentContractor(idDocument)
        .then(() => this.setState({disabledSave: false }))
    });
  }

  counterDocuments = (index) => {
    let { months, references  } = this.state;
    const { montlhyDocuments } = this.props;
    references = !references ? [] : references;

    if (index !== -1) {
      const length = references.filter(ref => {
        return ref.statusDocument === "APPROVED" && ref.wildcard.endsWith(months[index].sublabel)
      }).length;

      /* Changed to unlock an account with duplicated documents
      /* count giving more than 9 (old approach) or 22 */
      return length >= montlhyDocuments
    } else {
      return true
    }
  }

  counterPercentageDocuments = (index) => {
    let { months, references  } = this.state;
    const { montlhyDocuments } = this.props;
    references = !references ? [] : references;

    if (index !== -1) {
      const length = references.filter(ref => {
        return ref.wildcard.endsWith(months[index].sublabel)
      }).length;

      /* Changed to unlock an account with duplicated documents
       * percent was more than 100% */
      const percentage = +((length * 100) / montlhyDocuments).toFixed(2)

      return percentage >= 100 ? 100 : percentage;
    } else {
      return 0
    }
  }

  render() {
    const { months, currentMonthIndex, currentMonthLabel } = this.state;
    const { readOnly, montlhyDocuments } = this.props
    const systemDate = moment(new Date()).format("YYYYMM");

    if (months.length > 0) {
      return (
        <React.Fragment>
          <Grid.Row>
            <Grid.Column width="8">
              <Grid>
                <Grid.Row className="Modal__SubTabNav-Row-Align">
                {months.map((item, index) => {
                  const active = index === currentMonthIndex ? "Active" : "";
                  const percetageDoc = this.counterPercentageDocuments(index);
                  const activePercentage = percetageDoc === 100 ? "UtilComplete" : "UtilNoComplete";
                  return (
                    <Grid.Column key={`monthSelector${index}`} width="8">
                      <Segment className={`Modal__SubTabNav-SegmentFlex ${active} Modal__Form-SubModal-Form-Column-MB`}>
                        <Header as="h4" className="Modal__SubTabNav-SegmentFlex-Title">
                          Mes {`${index + 1}`} <span>- {item.title}</span>
                        </Header>
                        <div>
                          <span className={activePercentage}>{percetageDoc} %</span>
                          {(Number(item.normalLabel) <= Number(systemDate) && this.counterDocuments(index - 1)) &&
                            <Icon name="eye" size="large" link onClick={() => {this.changeMonth(index)}}/>
                          }
                        </div>
                      </Segment>
                    </Grid.Column>
                  )
                })}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width="8">
              <Grid>
                <Grid.Row className="Modal__SubTabNav-Row-Align">
                  <Grid.Column width="16">
                    <Can do="upload" on="ContractorDocuments">
                      {listMonthlyDocumentsContractor.map((monthlyDocument, index) => {
                        if (index >= montlhyDocuments) return null

                        const count = index + 1
                        return (
                          <ViewDocumentLink
                            key={monthlyDocument.name}
                            title={monthlyDocument.name}
                            onClickDownload={this.downloadHandler}
                            onClickDelete={this.deleteDocument}
                            onSelectFile={this.onSelectFile}
                            refDownload={this.getDocumentRef(`DOCUMENT_${count}_${currentMonthLabel}`)}
                            statusDocument={this.getStatusDocument(`DOCUMENT_${count}_${currentMonthLabel}`)}
                            documentType={monthlyDocument.type}
                            documentSubType={`DOCUMENT_${count}_${currentMonthLabel}`}
                            icon
                          />
                        )
                      })}
                    </Can>
                    <Can do="download" on="ContractorDocuments">
                      {listMonthlyDocumentsContractor.map((monthlyDocument, index) => {
                        if (index >= montlhyDocuments) return null

                        const count = index + 1
                        return (
                          <ViewDocumentSegment
                            key={monthlyDocument.name}
                            title={monthlyDocument.name}
                            reference={this.getDocumentRef(`DOCUMENT_${count}_${currentMonthLabel}`)}
                            onDownloadFile={this.downloadHandler}
                            uploadDocument={true}
                            readOnly={readOnly}
                            isContractor={true}
                            id={true}
                            onDisapproveDocument={this.disapproveDocumentHandler}
                            docsMonthly={true}
                            statusDocument={this.getStatusDocument(`DOCUMENT_${count}_${currentMonthLabel}`)}
                            icon
                          />
                        )
                      })}
                    </Can>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>

          <Can do="upload" on="ContractorDocuments">
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  content="Guardar"
                  className="Modal__Form-ButtonAdd"
                  onClick={this.saveDocuments}
                  disabled={this.state.disabledSave}
                />
              </Grid.Column>
            </Grid.Row>
          </Can>
        </React.Fragment>
      );
    }

    return (<p></p>);
  }
}

export default ContractMonthlyDocuments;
