import gql from "graphql-tag";

const GET_DOCUMENTS_CONTRACTOR = gql`
  query contracts($first: Int, $skip: Int){
    contracts(where: { status: ACTIVE, documentsContractor_some: {} }) {
      id
      currency
      startDate
      finishDate
      realFinishDate
      openPercentage
      closePercentage
      monthPercentage
      total
      contractor{
        name
        user {
          email
        }
      }
      project {
        name
        cli
      }
      totalDocumentsContractor: documentsContractor {
        id
        type
        status
      }
      documentsContractor(first: $first, skip: $skip, where: { status: BY_APPROVED }) {
        id
        type
        description
        refUpload{
          id
          reference
          wildcard
        }
      }
    }
    count: contracts(where: { status: ACTIVE }) {
      documentsContractor(where: { status: BY_APPROVED }) {
        id
      }
    }
  }
`;

export default GET_DOCUMENTS_CONTRACTOR;
