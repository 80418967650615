import React from "react";
import { Icon, Grid, Segment, Header, Button, Input, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import { helper } from "../../../helpers";

class ViewDocumentLinkAccountBalance extends React.Component {
  constructor(props) {
    super(props);
    const status = this.props.refDownload ? "download" : "upload";

    this.state = {
      statusValue: {
        upload: {
          icon: "plus",
          description: "Cargar documento",
          style: "Outline",
        },
        loading: {
          icon: "cloud upload",
          description: "Cargando documento...",
          style: "Outline"
        },
        loaded: {
          icon: "check",
          description: "Documento cargado",
          style: "Outline"
        }
      },
      errorFile: true,
      status
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.refDownload !== prevProps.refDownload) {
      const status = this.props.refDownload ? "download" : "upload";
      this.setState({
        status
      });
    }
  }

  onChange = event => {
    const files = event.target.files[0];
    const { onSelectFile, documentType, acceptedFiles } = this.props;

    if (!acceptedFiles.includes(files.type)) return this.setState({ errorFile: false });

    this.setState({ status: "loading", errorFile: true });

    if (typeof onSelectFile === "function") onSelectFile(files, documentType);
  }

  onClick = event => {
    if (typeof this.props.onClickDownload === "function" && this.props.refDownload !== null) this.props.onClickDownload(this.props.refDownload);
  }

  handleDelete = event => {
    this.props.onClickDelete(this.props.refDownload);
  }

  render() {
    const { status, statusValue } = this.state;
    const acceptedFormats = helper.acceptedFormats(this.props.acceptedFiles);
    let fileInput = null;

    const clickHandler = status === "upload" ? (e) => { e.preventDefault(); fileInput.inputRef.click() } : this.onClick;

    return (
      <Segment className="Modal__SubTabNav-Documents-Segment">
        <Grid>
          <Grid.Column width="2" textAlign="center">
              <Icon circular name="clipboard" size="large"/>
          </Grid.Column>
          <Grid.Column width="6" verticalAlign="middle">
            <Header sub className="Modal__SubTabNav-Documents-AsSubTitle">{this.props.title}</Header>
          </Grid.Column>
          <Grid.Column width="8" textAlign="center" verticalAlign="middle">
            {status !== "download" ? (
              <React.Fragment>
                <Button
                  icon={statusValue[status].icon}
                  content={statusValue[status].description}
                  className={`Modal__Form-ButtonAdd ${statusValue[status].style} Small`}
                  onClick={clickHandler}
                />
                <Input
                  type="file"
                  hidden
                  key={Date.now()}
                  ref={element => {fileInput = element}}
                  onChange={this.onChange}
                  style={{ display: "none" }}
                  accept={this.props.acceptedFiles}
                />
              </React.Fragment>
            ) : (
              <div className="Modal__SubTabNav-Documents-Segment-TextRight">
                <Icon name="eye" size="large" link onClick={clickHandler} />
              { !this.props.readOnly && (
                <Icon name="trash alternate outline" size="large" link onClick={this.handleDelete}/> )
              }
              </div>
            )}
          </Grid.Column>
          </Grid>
          <Message size="mini" color="red" hidden={this.state.errorFile}>Error: solo son aceptados documentos de tipo {acceptedFormats}.</Message>
      </Segment>
    );
  }
}

ViewDocumentLinkAccountBalance.propTypes = {
  title: PropTypes.string.isRequired,
  onClickDownload: PropTypes.func,
  onSelectFile: PropTypes.func,
  refDownload: PropTypes.string
};

export default ViewDocumentLinkAccountBalance;
