import React from "react";
import { Table, TableHeaderCell, Container, Button, ButtonGroup } from "semantic-ui-react";
import OnLoading from "../OnLoading";
import { IoMdCreate, IoIosTrash } from "react-icons/io";
import { client, MUTATIONS } from "../../../graphql/apollo-config";
import { GET_SUPPLIES } from "../../../graphql/queries/catalogs";
import { bugsnagClient } from "../../../bugsnag";
import _ from "lodash";

const deleteSupply = MUTATIONS.deleteSupply;

class SuppliesQuery extends React.Component {
  state = {
    loading: true,
    error: false,
    data: null,
    column: null,
    direction: "descending"
  }

  componentWillMount() {
    this.getSupplies(this.props.first, this.props.first * (this.props.activePage - 1), this.props.search);
  }

  componentDidUpdate(prevProps) {
    const { activePage, first, search } = this.props;

    if (prevProps.activePage !== activePage || prevProps.first !== first) {
      this.getSupplies(first, first * (activePage - 1));
    }

    if (prevProps.search !== search) {
      this.getSupplies(this.props.first, 0, search);
    }
  }

  handleSort = (clickedColumn) => {
    const { direction, column, data: { supplies } } = this.state;
    const sortBy = clickedColumn !== column ? "descending" : direction !== "ascending" ? "descending" : "ascending";
    const sorting = _.orderBy(supplies, [clickedColumn], [sortBy.replace("ending", "")]);

    this.setState({
      column: clickedColumn,
      data: { supplies: sorting },
      direction: clickedColumn !== column ? "ascending" : direction === "ascending" ? "descending" : "ascending"
    });
  }

  getSupplies = async (first, skip = 0, search) => {
    try {
      const floatSearch = !isNaN(parseFloat(search)) ? parseFloat(search) : undefined;
      const { loading, data } = await client.query({ query: GET_SUPPLIES, variables: { first, skip, search, floatSearch }, fetchPolicy: "network-only" });
      const activePage = skip === 0 ? 1 : this.props.activePage;

      this.props.getCount(data.count.length, data.supplies.length, activePage);
      this.setState({ loading, data });
    } catch (error) {
      this.setState({ error });
    }
  }

  deleteSupply = async id => {
    let prompt = window.confirm("Eliminar insumo");
    if (!prompt) return;

    try {
      this.setState(prevState => ({ ...prevState, loading: true }))
      await deleteSupply(id, client)
      this.getSupplies(this.props.first, this.props.first * (this.props.activePage - 1));
    } catch (err) {
      this.setState(prevState => ({ ...prevState, loading: false }))
      bugsnagClient.notify(err);
    }
  };

  render() {
    const { loading, error, data, column, direction } = this.state;

    if (loading || error || !data || !data.supplies.length) {
      return <OnLoading loading={loading} error={error} />
    }

    return (
      <Container fluid>
        <Table
          basic="very"
          compact
          textAlign="center"
          sortable
          className="Wrapper__Body-Table"
        >
          <Table.Header className="Wrapper__Body-Table-Header">
            <Table.Row>
              <Table.HeaderCell
                sorted={column === "insID" ? direction : "descending"}
                onClick={() => this.handleSort("insID")}>
                Clave del insumo
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "name" ? direction : "descending"}
                onClick={() => this.handleSort("name")}>
                Nombre
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "description" ? direction : "descending"}
                onClick={() => this.handleSort("description")}>
                Descripción
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "units" ? direction : "descending"}
                onClick={() => this.handleSort("units")}>
                Unidades de medida
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "purchasePrice" ? direction : "descending"}
                onClick={() => this.handleSort("purchasePrice")}>
                Precio de compra
              </Table.HeaderCell>
              <TableHeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body className="Wrapper__Body-Table-Body">
            {data.supplies.map((supply, index) => {
              return (
                <Table.Row key={index} textAlign="center">
                  <Table.Cell>{unescape(supply.insID)}</Table.Cell>
                  <Table.Cell>{unescape(supply.name)}</Table.Cell>
                  <Table.Cell
                    style={{
                      maxWidth: "300px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {unescape(unescape(supply.description))}
                  </Table.Cell>
                  <Table.Cell>{unescape(supply.units)}</Table.Cell>
                  <Table.Cell>
                    $
                      {parseFloat(supply.purchasePrice)
                      .toFixed(2)
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </Table.Cell>
                  <Table.Cell>
                    <ButtonGroup basic>
                      <Button
                        id={`edit-supply-${supply.key}`}
                        onClick={() => this.props.toggleEditSupply(supply)}
                        icon
                      >
                        <IoMdCreate />
                      </Button>
                      <Button
                        id={`delete-supply-${supply.key}`}
                        onClick={() => this.deleteSupply(supply.id)}
                        icon
                      >
                        <IoIosTrash />
                      </Button>
                    </ButtonGroup>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    );
  }
};

export default SuppliesQuery;
