import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async ({ name, email, userRol, password }) => {
  const statusCondition =
    userRol === "MANAGER" ||
    userRol === "PURCHASES_RESPONSBILE" ||
    userRol === "WAREHOUSE_RESPONSBILE" ||
    userRol === "CONTRACTOR_RESPONSBILE" ||
    userRol === "CONTRACTOR";

  const status = statusCondition ? "ACTIVE" : "INACTIVE";

  return await client.mutate({
    mutation: gql`
      mutation {
        createUser(
          data: {
            name: "${name}"
            email: "${email}"
            joinedAt: "${new Date(Date.now()).toISOString()}"
            userRol: ${userRol}
            password: "${password}"
            status:${status}
          }
        ) {
          id
        }
      }
    `,
  });
};
