import React from "react";
import { Grid, Segment, Form, Container } from "semantic-ui-react";
import { TabModal, AccountBalanceSummary } from "../../Organisms";
import { TabPanel } from "../../Atoms";
import { SelectClient, SelectProjectByClient } from "../../Molecules";
import { GET_CONTRACT_ACCOUNT_BALANCE_BY_PROJECT } from "../../../graphql/queries/accountBalance";
import { client } from "../../../graphql/apollo-config";
import OnLoading from "../../Molecules/OnLoading";

class SummaryContractAccountBalance extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      error: false,
      isVisible: false,
      client: "",
      project: "",
    }
  }

  async componentDidUpdate (prevProps, prevState) {
    if (prevState.project !== this.state.project) {
      await this.getContractsAccountBalance()
    }
  }

  handleChangeSelects = (e, { name, value }) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  getContractsAccountBalance = async () => {
    try {
      this.setState({ isVisible: true, loading: true })

      const { loading, error, data, } = await client.query({
        query: GET_CONTRACT_ACCOUNT_BALANCE_BY_PROJECT,
        variables: { id: this.state.project },
        fetchPolicy: "network-only"
      });

      this.setState({ loading, data, error, visible: false });
    } catch (error) {
      this.setState({ error });
    }
  }

  render() {
    const {
      client,
      project,
      isVisible,
      loading,
      error,
      data,
      data: { contractAccountBalances }} = this.state
    return (
      <Segment style={{minHeight: "200px"}}>
        <Grid centered columns={2}>
          <Grid.Column>
            <Form className="Modal__Form">
              <Form.Group widths='equal'>
                <Form.Field
                  control={SelectClient}
                  label="CLIENTE"
                  name="client"
                  value={client}
                  onChange={this.handleChangeSelects}
                />
                <Form.Field
                  id={client}
                  control={SelectProjectByClient}
                  label="PROYECTO"
                  name="project"
                  value={project}
                  onChange={this.handleChangeSelects}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
        <Grid>
          { isVisible ?
            loading || !data || !contractAccountBalances.length
            ? <OnLoading loading={loading} error={error} />
            : (
              <Container fluid className="Wrapper__Modal Summary">
                <TabModal active="balancemxn" readOnly={true} cancel={null}>
                  <TabPanel name="balancemxn" text="Estado de cuenta MXN">
                    <Form className="Modal__Form">
                      <AccountBalanceSummary data={contractAccountBalances} currency="MXN"/>
                    </Form>
                  </TabPanel>
                  <TabPanel name="balancedls" text="Estado de cuenta USD">
                    <Form className="Modal__Form">
                      <AccountBalanceSummary data={contractAccountBalances} currency="USD"/>
                    </Form>
                  </TabPanel>
                </TabModal>
              </Container>
            ) : null
          }
        </Grid>
      </Segment>
    )
  }
}

export default SummaryContractAccountBalance
