import gql from "graphql-tag";

const getClientsSelect = gql`
  {
    clients(orderBy: commercialName_ASC) {
      key: id
      text: commercialName,
      value: id
    }
  }
`;

export default getClientsSelect;
