import React from "react";
import { Form, Input, TextArea } from "semantic-ui-react";
import CurrencyInput from "react-currency-input";

export default props => {
  const {
    toggleSupplyQuickCreate,
    data,
    SearchSupply,
    supplyKeyChange,
    handleChangeSupply,
    handleSearchChange,
    supplyQuickCreate,
    getTotalSupply,
    noEditPrice = false,
  } = props;

  return (
    <Form className="Modal__Form-SubModal-Form">
      {props.isEdit ? (
        <Form.Field
          control={Input}
          action={props.isEdit ? null : toggleSupplyQuickCreate}
          vendorId={data.vendorId}
          label="NOMBRE DE INSUMO"
          name="name"
          value={data.supply.name}
          onChange={handleChangeSupply}
          size="mini"
          error={data.errors.nameError}
          autoComplete="new-password"
        />
      ) : data.toggles.supplyQuickCreateToggle ? (
        <Form.Field
          control={SearchSupply}
          label="NOMBRE DE INSUMO"
          name="name"
          value={data.supply.name}
          supplyKeyChange={supplyKeyChange}
          handleSearchChange={handleSearchChange}
          supplyQuickCreate={supplyQuickCreate}
          size="mini"
          error={data.errors.nameError}
          autoComplete="new-password"
        />
      ) : (
        <Form.Field
          control={Input}
          action={toggleSupplyQuickCreate}
          vendorId={data.vendorId}
          label="NOMBRE DE INSUMO"
          name="name"
          value={data.supply.name}
          onChange={handleChangeSupply}
          size="mini"
          error={data.errors.nameError}
          autoComplete="new-password"
        />
      )}
      {data.toggles.supplyQuickCreateToggle ? (
        <Form.Field
          control={Input}
          label="CLAVE DE INSUMO"
          name="insID"
          value={
            unescape(data.supply.insID) !== "undefined"
              ? unescape(data.supply.insID)
              : ""
          }
          onChange={handleChangeSupply}
          size="mini"
          error={data.errors.keyError}
          autoComplete="new-password"
          readOnly
        />
      ) : null}
      <Form.Field
        control={TextArea}
        label="DESCRIPCIÓN"
        name="description"
        value={unescape(data.supply.description || "")}
        onChange={handleChangeSupply}
        size="mini"
        error={data.errors.descriptionError}
        autoComplete="new-password"
      />
      <Form.Field
        control={Input}
        label="CANTIDAD"
        type="number"
        min={0}
        name="quantity"
        value={data.supply.quantity}
        onChange={handleChangeSupply}
        size="mini"
        error={data.errors.quantityError}
        autoComplete="new-password"
      />
      <Form.Field
        control={Input}
        label="UNIDAD DE MEDIDA"
        name="units"
        value={data.supply.units}
        onChange={handleChangeSupply}
        size="mini"
        error={data.errors.unitsError}
        autoComplete="new-password"
      />
      <Form.Field
        control={CurrencyInput}
        label="PRECIO UNITARIO"
        name="units"
        value={data.supply.purchasePrice || 0}
        onChangeEvent={(event, maskedvalue, floatvalue) =>
          handleChangeSupply({
            target: { name: "purchasePrice", value: floatvalue },
          })
        }
        size="mini"
        error={data.errors.purchasePriceError}
        readOnly={noEditPrice}
      />
      <div style={{ textAlign: "center" }}>
        <span style={{ color: "#999999" }}>
          <big>
            <b>Total</b>
          </big>
        </span>
        <h3 style={{ textAlign: "center" }}>
          {getTotalSupply(data.supply.quantity, data.supply.purchasePrice)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </h3>
      </div>
    </Form>
  );
};
