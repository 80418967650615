import gql from "graphql-tag";

const GetPurchasesByUser = gql`
  query getPurchasesByUser(
    $userId: ID!,
    $first: Int,
    $skip: Int,
    $searchBox: String,
    $searchBoxUpper: String,
    $searchBoxLower: String,
    $searchBoxCap: String,
    $date: DateTime,
    $date_lt: DateTime,
    $status: Status){
    purchaseOrders(
      first: $first,
      skip: $skip,
      where: {
        data: {
          project: { users_some: { id: $userId } }
          createdAt_gt: $date
          createdAt_lt: $date_lt
          status: $status
        }
        OR: [
          {
            folio_contains: $searchBox
          },
          {
            data: {
              OR: [
                {
                  project: {
                    OR: [
                      { name_contains: $searchBox },
                      { name_contains: $searchBoxUpper },
                      { name_contains: $searchBoxLower },
                      { name_contains: $searchBoxCap },
                      { cli_contains: $searchBox },
                      { cli_contains: $searchBoxUpper },
                      { cli_contains: $searchBoxLower },
                      { cli_contains: $searchBoxCap }
                    ]
                  }
                }
                {
                  vendor: {
                    OR: [
                      { commercialName_contains: $searchBox },
                      { commercialName_contains: $searchBoxUpper },
                      { commercialName_contains: $searchBoxLower },
                      { commercialName_contains: $searchBoxCap },
                    ]
                  }
                }
                { deliveryDate_contains: $searchBox }
                { deliveryDate_contains: $searchBoxUpper }
                { deliveryDate_contains: $searchBoxLower }
                { deliveryDate_contains: $searchBoxCap }
                { deliveryWarehouse_contains: $searchBox }
              ]
            }
          }
        ]
      }) {
      id
      folio
      convertedAt
      data {
        folio
        petitioner
        id
        createdAt
        wasUpdatedAt
        applicant
        status
        revisedBy
        authorizedBy
        project {
          id
          cli
          name
          address
          status  
          responsibles: users(where: { userRol_in: [MANAGER, ADMINISTRATIVE_COORDINATOR] }){
            id,
            name
          }
          residents: users(where: { userRol_in: [TECHNICIAN] }){
            id,
            name
          }
        }
        vendor {
          id
          commercialName
          contacts {
            name
          }
        }
        deliveryWarehouse
        deliveryDate
        notes
        currency
        paymentConditions
        subtotal
        iva
        total
        retention
        discount
        amount
        retentionPercentage
        discountPercentage
        supplies {
          id
          name
          insID
          description
          units
          quantity
          purchasePrice
        }
        isReq
      }
    }
    count: purchaseOrders(where: {
        data: {
          project: { users_some: { id: $userId } }
          createdAt_gt: $date
          createdAt_lt: $date_lt
          status: $status
        },
        OR: [
          {
            data: {
              OR: [
                {
                  project: {
                    OR: [
                      { name_contains: $searchBox },
                      { name_contains: $searchBoxUpper },
                      { name_contains: $searchBoxLower },
                      { name_contains: $searchBoxCap },
                      { cli_contains: $searchBox },
                      { cli_contains: $searchBoxUpper },
                      { cli_contains: $searchBoxLower },
                      { cli_contains: $searchBoxCap }
                    ]
                  }
                }
                {
                  vendor: {
                    OR: [
                      { commercialName_contains: $searchBox },
                      { commercialName_contains: $searchBoxUpper },
                      { commercialName_contains: $searchBoxLower },
                      { commercialName_contains: $searchBoxCap },
                    ]
                  }
                }
                { deliveryDate_contains: $searchBox }
                { deliveryDate_contains: $searchBoxUpper }
                { deliveryDate_contains: $searchBoxLower }
                { deliveryDate_contains: $searchBoxCap }
                { deliveryWarehouse_contains: $searchBox }
              ]
            }
          }
        ]
      }) {
      id
    }
  }
`;

export default GetPurchasesByUser;
