import React from "react";
import { Loader } from "semantic-ui-react";
import { client } from "../../../graphql/apollo-config";
import { GetPurchaseOrderById, GetRequisitionById } from "../../../graphql/queries/purchases";
import PDF from "./pdf";

export default class Pdf extends React.Component {
  state = {
    id: window.location.pathname.split("f/")[1],
    loading: true,
    error: false,
    data: false
  }

  async componentDidMount () {
    let data = await this.getPurchaseOrder();
    if (!data.purchaseOrder) {
      data = await this.getRequisition();
      this.setState({
        data: data.requisition,
        loading: data.loading,
        error: data.error,
        type: "REQ"
      });
    } else {
      this.setState({
        data: data.purchaseOrder,
        loading: data.loading,
        error: data.error,
        type: "PO"
      });
    }
  }

  getPurchaseOrder = async () => {
    const { loading, error, data: { purchaseOrder } } = await client.query({
      query: GetPurchaseOrderById,
      variables: { id: this.state.id }
    });
    return { loading, error, purchaseOrder };
  }

  getRequisition = async () => {
    const { loading, error, data: { requisition } } = await client.query({
      query: GetRequisitionById,
      variables: { id: this.state.id }
    });
    return { loading, error, requisition };
  };

  render() {
    if (this.state.error || this.state.data === null) window.location = "/404"

    return (
      <div
        style={{
          width: "100%",
          height: `${window.innerHeight}px`,
          overflow: "hidden",
        }}
      >
        {this.state.loading
          ? (
            <div>
              <Loader />
            </div>
          ) : (
            <PDF data={this.state.data} type={this.state.type} />
          )
        }
      </div>
    );
  }
}
