import React from "react";
import DetailWarehouseContainer from "./container";
import { Mutation } from "react-apollo";
import createWarehouse from "../../../graphql/mutations/createWarehouse";

export default class DetailWarehouse extends React.Component {
  render() {
    return (
      <div className="Modal">
        <DetailWarehouseContainer
          onCancel={this.props.toggleSave}
          data={this.props.data}
        />
      </div>
    );
  }
}
