import React from "react";
import _ from "lodash";
import {
  Button,
  ButtonGroup,
  Container,
  Dimmer,
  Loader,
  Table,
} from "semantic-ui-react";
import { IoMdEye, IoMdCreate, IoMdTrash } from "react-icons/io";
import { PROJECT_TASKS } from "../../../../graphql/queries/projectProgress";

const Loading = props => (
  <Container fluid className="Wrapper__Body-Loader">
    <Dimmer active inverted>
      <Loader testid="loading-wrapper" inverted content={props.message} />
    </Dimmer>
  </Container>
);

export default class ProjectProgressQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: [],
      direction: null,
      id: window.location.pathname.split("e/")[1],
    };
  }

  styles = {
    header: { fontWeight: "bold" },
  };

  async componentWillMount() {
    const data = await this.getProjects();
    this.setState({ data });
  }

  async getProjects() {
    const result = await PROJECT_TASKS(this.state.id);
    const data = result.data.project.tasks;
    return data;
  }

  handleSort = clickedColumn => () => {
    const { column, direction, data } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  getAllPredecessors = data => {
    return data.length;
  };

  handleDate = date => {
    const d = date
      ? date
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/")
      : "";
    return d;
  };

  mapStatus = status => {
    const STATUS = {
      PENDING: "Pendiente",
      IN_PROGRESS: "En progreso",
      COMPLETE: "Completada",
    };
    return STATUS[status]
  };

  render() {
    const { header } = this.styles;
    const { column, data, direction } = this.state;
    return (
      <Table
        basic="very"
        compact
        textAlign="center"
        sortable
        className="Wrapper__Body-Table"
      >
        <Table.Header className="Wrapper__Body-Table-Header">
          <Table.Row>
            <Table.HeaderCell
              sorted={column === "name" ? direction : null}
              onClick={this.handleSort("name")}
              style={header}
            >
              Nombre de tarea
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "duration" ? direction : null}
              onClick={this.handleSort("cli")}
              style={header}
            >
              Duración
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "startDate" ? direction : null}
              onClick={this.handleSort("startDate")}
              style={header}
            >
              Fecha de inicio
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "progress" ? direction : null}
              onClick={this.handleSort("progress")}
              style={header}
            >
              Progreso
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body textAlign="center">
          {data.map((item, index) => {
            const {
                id,
                text,
                start_date,
                duration,
                progress
            } =
              item !== null
                ? item
                : {
                    id: "",
                    index: "",
                    text: "",
                    start_date: "",
                    duration:"",
                    progress: "",
                  };
            return (
              <Table.Row key={index}>
                <Table.Cell>{text}</Table.Cell>
                <Table.Cell>{duration}</Table.Cell>
                <Table.Cell>{this.handleDate(start_date)}</Table.Cell>
                <Table.Cell>{`${progress * 100}%`}</Table.Cell>
                <Table.Cell>
                  <ButtonGroup basic>
                    <Button onClick={null} icon>
                      <IoMdCreate />
                    </Button>
                    <Button onClick={null} icon>
                      <IoMdTrash />
                    </Button>
                    <Button onClick={null} icon>
                      <IoMdEye />
                    </Button>
                  </ButtonGroup>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}
