import React from "react";
import SidebarItem from "./sidebarITem";
import { IoMdCart, IoIosArchive } from "react-icons/io";

export default props => {
  return (
    <React.Fragment>
      <SidebarItem
        {...props}
        name="purchases"
        text="Compras"
        to="/compras"
        icon={<IoMdCart />}
      />
      <SidebarItem
        {...props}
        text="Inventarios"
        name="inventories"
        to="/inventarios"
        icon={<IoIosArchive />}
      />
    </React.Fragment>
  );
};
