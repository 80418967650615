import React from "react";
import { Header, Grid, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import theme from "../../_theme";

const ModalHeader = props => {
  return (
    <Header as="h3" dividing className="Modal__Header">
      <Grid columns="equal">
        { props.component
          ? (
            <React.Fragment>
              <Grid.Row>
                <Grid.Column textAlign="right">
                  {!props.readOnly &&
                    <Button onClick={props.save} disabled={props.disabled} style={theme.styles.buttons.red} content="Guardar"/>
                  }
                  <Button onClick={props.cancel} disabled={props.disabled}>
                    Cancelar
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="Modal__SubTabNav-Row-Align">
                {props.title}
              </Grid.Row>
            </React.Fragment>
          ) : (
            <Grid.Row>
              <Grid.Column textAlign="left" className="Modal__Header-Title">
                {props.title}
              </Grid.Column>
              <Grid.Column textAlign="right">
                {!props.readOnly &&
                  <Button onClick={props.save} disabled={props.disabled} style={theme.styles.buttons.red} content="Guardar"/>
                }
                <Button onClick={props.cancel} disabled={props.disabled || !props.cancelbutton}>
                  Cancelar
                </Button>
              </Grid.Column>
            </Grid.Row>
          )
        }
      </Grid>
    </Header>
  );
}

ModalHeader.propTypes = {
  save: PropTypes.func,
  cancel: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

ModalHeader.defaultProps = {
  title: "",
  save: null,
  cancel: null,
  readOnly: false,
  disabled: false,
}

export default ModalHeader;
