import React from "react";
import {
  Button,
  Container,
  Form,
  Header,
  Input,
  Modal,
  Select,
  Segment,
  Table
} from "semantic-ui-react";
import _ from "lodash";
import { client, MUTATIONS } from "../../../graphql/apollo-config";
import { store as _store } from "../../../store/store";
import { IoMdCreate, IoIosTrash } from "react-icons/io";
import { FormHeader } from "../../Molecules";
import { validator, Stack } from "../../../helpers";
import { bugsnagClient } from "../../../bugsnag";
import joi from "joi";
import updateVendor from "../../../graphql/mutations/_updateVendor";
import OnLoading from "../../Molecules/OnLoading";

export default class CreateVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      contacts: [],
      contactTable: [],
      city: "",
      colony: "",
      commercialName: "",
      country: "",
      iva: "",
      number: "",
      personType: "",
      postalCode: "",
      razonSocial: "",
      rfc: "",
      state: "",
      street: "",
      name: "",
      email: "",
      phone: "",
      phone_office: "",
      modalOpen: false,
      modalUpdateOpen: false,
      cityError: false,
      colonyError: false,
      commercialNameError: false,
      countryError: false,
      ivaError: false,
      numberError: false,
      personTypeError: false,
      postalCodeError: false,
      razonSocialError: false,
      rfcError: false,
      stateError: false,
      streetError: false,
      nameError: false,
      emailError: false,
      phoneError: false,
      phone_officeError: false,
      formIsValid: false,
      errors: [],
      isForeign: true,
      isLoading: false,
      addedContacts: [],
      updatedContacts: [],
      deletedContacts: []
    };
  }

  _contacts = new Stack();

  onSave = async () => {
    const createVendor = MUTATIONS.createVendor;
    const vendor = this.getVendorFields();
    vendor["rfc"] = this.state.country === "MX" ? vendor["rfc"] : "N/A";
    vendor["iva"] = this.state.country === "MX" ? vendor["iva"] : 0;
    let _contacts = [];
    this.state.contactTable.map(contact => {
      _contacts.push({
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        phone_office: contact.phone_office,
      });
    });
    const validation = this.validateObjects(vendor);
    vendor.id = this.state.id;
    vendor.client = client;
    vendor["contacts"] = _contacts;
    if (validation.error === null) {
      try {
        this.setState(prevState => ({ ...prevState, isLoading: true }));
        await createVendor(vendor);
        this.props.toggleSave();
      } catch (err) {
        this.setState(prevState => ({ ...prevState, isLoading: false }));
        bugsnagClient.notify(err);
      }
    } else {
      alert("Completa los campos faltantes");
    }
  };

  onUpdate = async () => {
    const vendor = this.getVendorFields();
    vendor["rfc"] = this.state.country === "MX" ? vendor["rfc"] : "N/A";
    vendor["iva"] = this.state.country === "MX" ? vendor["iva"] : 0;
    let _contacts = [];
    this.state.contactTable.map(contact => {
      _contacts.push({
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        phone_office: contact.phone_office,
      });
    });
    const validation = this.validateObjects(vendor);
    vendor.id = this.state.id;
    vendor.client = client;
    const c = _contacts.filter(model => {
      return !this.state.currentContacts.some(
        item => model.email === item.email
      );
    });

    vendor.addedContacts = this.state.addedContacts;
    vendor.updatedContacts = this.state.updatedContacts;
    vendor.deletedContacts = this.state.deletedContacts;

    if (validation.error === null) {
      try {
        this.setState(prevState => ({ ...prevState, isLoading: true }));
        await updateVendor(vendor);
        this.props.toggleSave();
      } catch (err) {
        this.setState(prevState => ({ ...prevState, isLoading: false }));
        bugsnagClient.notify(err);
      }
    } else {
      alert("Completa los campos faltantes");
    }
  };

  validateObjects = object => {
    const schema = {
      city: joi.string().required(),
      colony: joi.string().required(),
      commercialName: joi.string().required(),
      country: joi.string().required(),
      number: joi.string().required(),
      postalCode: joi.number().required(),
      razonSocial: joi.string().required(),
      iva: joi.number().required(),
      personType: joi.string().required(),
      rfc: joi.string().required(),
      state: joi.string().required(),
      street: joi.string().required(),
      contacts: joi
        .array()
        .min(1)
        .required(),
    };
    const { error, value } = joi.validate(object, schema);
    this.validateFields(value);
    return { value, error };
  };

  getContactFields = () => {
    const { name, email, phone, phone_office } = this.state;
    return { name, email, phone, phone_office };
  };

  getVendorFields = () => {
    const {
      city,
      colony,
      commercialName,
      country,
      iva,
      number,
      personType,
      postalCode,
      razonSocial,
      rfc,
      state,
      street,
      contacts,
    } = this.state;
    let _contacts = [];
    contacts.map(contact => {
      _contacts.push({
        email: contact.email,
        name: contact.email,
        phone: contact.phone,
        phone_office: contact.phone_office,
      });
    });
    return {
      city,
      colony,
      commercialName,
      country,
      iva,
      number,
      personType,
      postalCode,
      razonSocial,
      rfc,
      state,
      street,
      contacts: _contacts,
    };
  };

  validateFields = validationObject => {
    let errors = [];
    const validation = validator(validationObject);

    validation.map(field => {
      const key = field.key;
      const errorKey = `${key}Error`;
      if (!field.isValid) {
        errors.push(key);
      }
      this.setState(data => ({
        ...data,
        [errorKey]: !field.isValid,
      }));
    });
    return errors;
  };

  // Save contact to state
  onSaveContact = () => {
    const contactObject = this.getContactFields();
    const isValid = this.validateFields(contactObject);
    if (isValid.length === 0) {
      const exists = _.some(this.state.contacts, contactObject);
      if (!exists) {
        const contacts = [...this.state.contacts];
        contacts.push(contactObject);
        this.setState(data => ({
          ...data,
          contacts: contacts,
          contactTable: contacts,
          addedContacts: [...data.addedContacts, contactObject],
          modalOpen: false,
          name: "",
          email: "",
          phone: "",
          phone_office: "",
        }));
      } else {
        alert("El contacto ya existe");
      }
    }
  };

  // Update contact
  onUpdateContact = () => {
    const index = this.state.openContact;
    // Get stack
    const contactStack = [...this.state.contacts];
    const contactTable = [...this.state.contactTable];
    const newContactObject = this.getContactFields();
    const isValid = this.validateFields(newContactObject);

    if (isValid.length === 0) {
      contactStack[index] = newContactObject;
      contactTable[index] = newContactObject;

      const addedContacts = [...this.state.addedContacts];
      const addedContact = addedContacts.findIndex((contact) => contact.email === newContactObject.email);

      if (addedContact > -1) {
        addedContacts[addedContact] = newContactObject;
        return this.setState((state) => ({
          ...state,
          contacts: contactStack,
          modalUpdateOpen: false,
          name: "",
          email: "",
          currentEmail: "",
          phone: "",
          phone_office: "",
          addedContacts,
          contactTable
        }));
      }

      const updateObject = {
        where: { email: this.state.contacts[index].email },
        data: newContactObject
      };

      return this.setState((state) => ({
        ...state,
        contacts: contactStack,
        modalUpdateOpen: false,
        name: "",
        email: "",
        currentEmail: "",
        phone: "",
        phone_office: "",
        updatedContacts: [...state.updatedContacts, updateObject],
        contactTable
      }));
    }
    return;
  };

  // Remove contact from table
  deleteContact = index => {
    const contactStack = [...this.state.contacts];
    const contactTable = [...this.state.contactTable];
    const newContactStack = contactStack.filter((contact, idx) => idx !== index);
    const newContactTable = contactTable.filter((contact, idx) => idx !== index);
    const deletedContact = contactStack.find((contact, idx) => idx === index);

    const addedContacts = [...this.state.addedContacts];
    const addedContact = addedContacts.findIndex((contact) => contact.email === deletedContact.email);

    if (addedContact > -1) {
      const newAddedContacts = addedContacts.filter((contact, idx) => idx !== addedContact);
      return this.setState((state) => ({
        ...state,
        contacts: newContactStack,
        contactTable: newContactTable,
        modalUpdateOpen: false,
        name: "",
        email: "",
        currentEmail: "",
        phone: "",
        phone_office: "",
        addedContacts: newAddedContacts
      }));
    }

    return this.setState((state) => ({
      ...state,
      contacts: newContactStack,
      contactTable: newContactTable,
      deletedContacts: [...state.deletedContacts, deletedContact]
    }));
  };

  // inputs onChange
  handleChange = (e, { name, value }) => {
    this.setState(data => ({
      ...data,
      [name]: value,
    }));
  };

  handleChangeCountry = (e, { name, value }) => {
    if (value === "MX") {
      this.setState(data => ({
        ...data,
        [name]: value,
        isForeign: false,
        personTypeOptions: [
          { key: "moral", value: "MORAL", text: "Moral" },
          { key: "fisica", value: "FISICA", text: "Física" },
        ],
        personType: "FISICA",
        iva: 16,
        rfc: "",
      }));
    } else {
      this.setState(data => ({
        ...data,
        [name]: value,
        isForeign: true,
        personTypeOptions: [
          { key: "extranjero", value: "EXTRANJERO", text: "Extranjero" },
        ],
        personType: "EXTRANJERO",
        rfc: "",
        iva: "N>/A",
      }));
    }
  };

  // open add contact modal
  handleOpen = () => this.setState({ modalOpen: true });

  // open update contact modal
  handleOpenUpdate = index => {
    const { name, email, phone, phone_office } = this.state.contactTable[index];
    this.setState({
      openContact: index,
      modalUpdateOpen: true,
      name,
      email,
      currentEmail: email,
      phone,
      phone_office,
    });
    this._contacts.splice(index);
  };

  handleCloseModal = () => {
    this.setState({
      modalOpen: false,
      modalUpdateOpen: false,
      name: "",
      email: "",
      phone: "",
      phone_office: "",
    });
  };

  modalForm = () => {
    return (
      <Form className="Modal__Form-SubModal-Form">
        <Form.Field
          label="NOMBRE Y APELLIDO"
          name="name"
          control={Input}
          onChange={this.handleChange}
          value={this.state.name}
          error={this.state.nameError}
          autoComplete="off"
        />
        <Form.Field
          label="CORREO ELECTRÓNICO"
          name="email"
          control={Input}
          value={this.state.email}
          onChange={this.handleChange}
          error={this.state.emailError}
          autoComplete="off"
        />
        <Form.Field
          label="TELÉFONO"
          name="phone"
          control={Input}
          value={this.state.phone}
          onChange={this.handleChange}
          error={this.state.phoneError}
          autoComplete="off"
        />
        <Form.Field
          label="TELÉFONO DE OFICINA"
          name="phone_office"
          control={Input}
          value={this.state.phone_office}
          onChange={this.handleChange}
          error={this.state.phone_officeError}
          autoComplete="off"
        />
      </Form>
    );
  };

  componentWillMount = () => {
    if (this.props.update) {
      const {
        id,
        city,
        colony,
        commercialName,
        country,
        iva,
        number,
        personType,
        postalCode,
        razonSocial,
        rfc,
        state,
        street,
        contacts,
      } = this.props.data;
      this.setState(data => ({
        ...data,
        id,
        city,
        colony,
        commercialName,
        country,
        iva,
        number,
        personType,
        postalCode,
        razonSocial,
        rfc,
        state,
        street,
        contacts,
        currentContacts: contacts,
        contactTable: contacts,
        personTypeOptions:
          personType === "EXTRANJERO"
            ? [{ key: "extranjero", value: "EXTRANJERO", text: "Extranjero" }]
            : [
                { key: "fisica", value: "FISICA", text: "Fisica" },
                { key: "moral", value: "MORAL", text: "Moral" },
              ],
      }));
    }
  };

  close = () => {
    if (this.state.contacts.length > 0) {
      this.props.onCancel();
    } else {
      alert(
        "Haz eleminado los contactos de este cliente, añade un nuevo contacto"
      );
    }
  };

  render() {
    const ModalForm = this.modalForm;
    return (
      <Segment raised className="Modal">
        <FormHeader
          id={this.props.update}
          saveId="save-vendor-btn"
          cancelId="cancel-vendor-btn"
          text={this.props.update ? "Editar proveedor" : "Nuevo proveedor"}
          onSave={this.props.update ? this.onUpdate : this.onSave}
          onCancel={this.props.update ? this.close : this.props.onCancel}
          submitting={this.state.isLoading}
        />

        {this.state.isLoading
          ? <OnLoading loading={this.state.isLoading} />
          : (
              <>
                <Form className="Modal__Form">
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="NOMBRE COMERCIAL"
                      name="commercialName"
                      value={this.state.commercialName}
                      onChange={this.handleChange}
                      error={this.state.commercialNameError}
                      autoComplete="off"
                    />
                    <Form.Field
                      control={Input}
                      label="CÓDIGO POSTAL"
                      type="number"
                      min="0"
                      name="postalCode"
                      value={this.state.postalCode}
                      onChange={this.handleChange}
                      error={this.state.postalCodeError}
                      autoComplete="off"
                    />
                    <Form.Field
                      control={Select}
                      label="PAIS"
                      name="country"
                      options={[
                        { text: "Brasil", value: "BR" },
                        { text: "Estados Unidos", value: "EU" },
                        { text: "México", value: "MX" },
                      ]}
                      defaultChecked={this.state.country}
                      value={this.state.country}
                      onChange={this.handleChangeCountry}
                      error={this.state.countryError}
                      autoComplete="off"
                    />
                    <Form.Field
                      control={Input}
                      label="RFC"
                      name="rfc"
                      value={this.state.rfc}
                      onChange={this.handleChange}
                      error={this.state.isForeign ? false : this.state.rfcError}
                      readOnly={this.state.country === "MX" ? false : true}
                      style={this.state.country === "MX" ? null : { opacity: "0.3" }}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      label="DIRECCIÓN"
                      name="street"
                      control={Input}
                      placeholder="Calle"
                      value={this.state.street}
                      onChange={this.handleChange}
                      error={this.state.streetError}
                      width={5}
                      autoComplete="off"
                    />
                    <Form.Field
                      label="&nbsp;"
                      name="number"
                      control={Input}
                      placeholder="Número"
                      value={this.state.number}
                      onChange={this.handleChange}
                      error={this.state.numberError}
                      width={5}
                      autoComplete="off"
                    />
                    <Form.Field
                      control={Input}
                      label="CIUDAD"
                      name="city"
                      value={this.state.city}
                      onChange={this.handleChange}
                      error={this.state.cityError}
                      width={10}
                      autoComplete="off"
                    />
                    <Form.Field
                      control={Select}
                      label="TIPO DE PERSONA"
                      name="personType"
                      value={this.state.personType}
                      options={this.state.personTypeOptions}
                      onChange={this.handleChange}
                      error={this.state.personTypeError}
                      width={10}
                      autoComplete="off"
                    />
                    <Form.Field
                      control={Input}
                      label="IVA"
                      type="number"
                      min="0"
                      name="iva"
                      value={this.state.iva}
                      onChange={this.handleChange}
                      error={this.state.isForeign ? false : this.state.ivaError}
                      width={10}
                      readOnly={this.state.country === "MX" ? false : true}
                      style={this.state.country === "MX" ? null : { opacity: "0.3" }}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="COLONIA"
                      name="colony"
                      value={this.state.colony}
                      onChange={this.handleChange}
                      error={this.state.colonyError}
                      autoComplete="off"
                    />
                    <Form.Field
                      control={Input}
                      label="ESTADO"
                      name="state"
                      value={this.state.state}
                      onChange={this.handleChange}
                      error={this.state.stateError}
                      autoComplete="off"
                    />
                    <Form.Field
                      control={Input}
                      label="RAZÓN SOCIAL"
                      name="razonSocial"
                      value={this.state.razonSocial}
                      onChange={this.handleChange}
                      error={this.state.razonSocialError}
                      autoComplete="off"
                    />
                    <Form.Field />
                  </Form.Group>
                </Form>
                <Header as="h3" dividing className="Modal__Form-Header">
                  Contactos
                </Header>
                <Table
                  basic="very"
                  compact="very"
                  textAlign="center"
                  className="Modal__Form-Table"
                >
                  <Table.Header className="Modal__Form-Table-Header">
                    <Table.Row>
                      <Table.HeaderCell>Nombre</Table.HeaderCell>
                      <Table.HeaderCell>Correo electrónico</Table.HeaderCell>
                      <Table.HeaderCell>Teléfono</Table.HeaderCell>
                      <Table.HeaderCell>Teléfono de oficina</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body className="Modal__Form-Table-Body">
                    {this.state.contactTable.map((contact, index) => {
                      const { name, email, phone, phone_office, id } = contact;
                      return (
                        <Table.Row key={name}>
                          <Table.Cell>{name}</Table.Cell>
                          <Table.Cell>{email}</Table.Cell>
                          <Table.Cell>{phone}</Table.Cell>
                          <Table.Cell>{phone_office}</Table.Cell>
                          <Table.Cell>
                            <Button.Group basic>
                              <Button icon onClick={() => this.handleOpenUpdate(index)}>
                                <IoMdCreate />
                              </Button>
                              <Button icon onClick={() => this.deleteContact(index)}>
                                <IoIosTrash />
                              </Button>
                            </Button.Group>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
                <Container textAlign="center">
                  <Modal
                    className="Modal__Form-SubModal"
                    centered={false}
                    trigger={
                      <Button
                        onClick={this.handleOpen}
                        className="Modal__Form-ButtonAdd"
                        icon="plus"
                        content="Añadir Contacto"
                        size="large"
                      />
                    }
                    open={this.state.modalOpen}
                    header={
                      <FormHeader
                        saveId="save-contact-btn"
                        cancelId="cancel-contact-btn"
                        text="Contacto de proveedor"
                        onCancel={this.handleCloseModal}
                        onSave={this.onSaveContact}
                      />
                    }
                    content={<ModalForm />}
                    size="tiny"
                    dimmer="blurring"
                  />
                  <Modal
                    className="Modal__Form-SubModal"
                    centered={false}
                    open={this.state.modalUpdateOpen}
                    onClose={this.handleCloseModal}
                    header={
                      <FormHeader
                        saveId="update-contact-btn"
                        cancelId="cancel-update-contact-btn"
                        text="Contacto de proveedor"
                        onCancel={this.handleCloseModal}
                        onSave={this.onUpdateContact}
                      />
                    }
                    content={<ModalForm />}
                    size="tiny"
                    dimmer="blurring"
                  />
                </Container>
              </>
          )
        }
      </Segment>
    );
  }
}
