import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";

const styles = {
  activeSidebarColor: { color: "#b3363e" },
  activeSidebarBorder: {
    borderLeft: "3px solid #b3363e",
  },
  inactiveSidebarColor: { color: "#dddddd" },
  activeText: { fontSize: "10px", color: "#b3363e" },
  inactiveText: { fontSize: "10px", color: "#dddddd" },
};

export default props => {
  let { activeTab, name, icon, to, text } = props;

  let modifiedIcon = React.cloneElement(icon, {
    style:
      activeTab === name
        ? styles.activeSidebarColor
        : styles.inactiveSidebarColor,
  });

  return (
    <Grid.Row
      style={activeTab === name ? styles.activeSidebarBorder : undefined}
      centered
    >
      <Link to={to}>
        {modifiedIcon}
        <br />
        <span
          style={activeTab === name ? styles.activeText : styles.inactiveText}
        >
          {text}
        </span>
      </Link>
    </Grid.Row>
  );
};
