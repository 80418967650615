import React from "react";
import "./style.css";

export default class TableHeader extends React.Component {
  render() {
    return (
      <thead className="thead">
        <tr>{this.props.children}</tr>
      </thead>
    );
  }
}
