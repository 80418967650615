import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async () => {
  return await client.query({
    query: gql`
      {
        users {
          id
          name
          email
          userRol
          status
        }
      }
    `,
    fetchPolicy: "network-only",
  });
};
