import React from "react";
import SidebarItem from "./sidebarITem";
import clientsIcon from "../../../../assets/clientsDisabled.svg";
import { IoIosAlbums } from "react-icons/io";
import { FaClipboard, FaUserLock } from "react-icons/fa";

const styles = {
  activeSidebarColor: { color: "#b3363e" },
  activeSidebarBorder: {
    borderLeft: "3px solid #b3363e",
  },
  inactiveSidebarColor: { color: "#dddddd" },
};

export default props => {
  return (
    <React.Fragment>
      <SidebarItem
        {...props}
        name="clients"
        to="/clientes"
        text="Clientes"
        icon={
          <img
            src={clientsIcon}
            stroke={
              props.activeTab === "clients"
                ? styles.activeSidebarColor
                : styles.inactiveSidebarColor
            }
            width="20"
            alt="Icono menu clientes"
          />
        }
      />
      <SidebarItem
        {...props}
        name="accountBalance"
        to="/estado-de-cuenta"
        text="Estado de cuenta"
        icon={<FaClipboard />}
      />
      <SidebarItem
        {...props}
        name="contractors"
        text="Contratistas"
        to="/contratistas"
        icon={<IoIosAlbums />}
      />
      <SidebarItem
        {...props}
        name="users"
        text="Usuarios"
        to="/usuarios"
        icon={<FaUserLock />}
      />
    </React.Fragment>
  );
};
