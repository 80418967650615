import gql from "graphql-tag";
import { client } from "../../apollo-config";
import { format } from "../../../helpers";

const UPDATE_DOCUMENTS_CONTRACTOR = async(
  id,
  contractor,
  documentsContractor,
  percentageDocuments,
  GET_DOCUMENTS_CONTRACTOR_ID
) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContract(
          data: {
            openPercentage: ${percentageDocuments.openPercentage || 0}
            monthPercentage: ${percentageDocuments.monthPercentage || 0}
            closePercentage: ${percentageDocuments.closePercentage || 0}
            documentsContractor: {
              create:[${documentsContractor.map((element) => {
                return `{
                  type: ${element.type}
                  description: "${element.description}"
                  elaborated: "${contractor.id}"
                  dateElaborated: "${format.date(new Date(), 2)}"
                  refUpload: {
                    create: [{
                      createDate: "${format.date(new Date(), 2)}"
                      reference: "${element.refUpload[0].reference}"
                      extension: "${element.refUpload[0].extension}"
                      wildcard: "${element.refUpload[0].wildcard}"
                    }]
                  }
                }`
              })}]
            },
          },
          where: { id: "${id}" }
        ){
          id
          openPercentage
          monthPercentage
          closePercentage
          startDate
          finishDate
          finishDateSiroc
          realFinishDate
          contractor {
            id
            name
          }
          documentsContractor {
            id
            type
            description
            status
            refUpload{
              id
              type
              reference
              extension
              wildcard
            }
          }
        }
      }
    `,
    refetchQueries: [{ query: GET_DOCUMENTS_CONTRACTOR_ID, variables: { id } }]
  });
  return mutation;
}

export default UPDATE_DOCUMENTS_CONTRACTOR;
