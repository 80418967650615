import React from "react";
import { Icon, Grid, Segment, Header } from "semantic-ui-react";
import { Can } from "../../hoc/permissions";
import PropTypes from "prop-types";

const ViewDocumentSegment = props => {
  return (
    <Segment className="Modal__SubTabNav-Documents-Segment">
      <Grid>
        {props.icon &&
          <Grid.Column width="2" textAlign="center">
            <Icon circular name="clipboard" size="large"/>
          </Grid.Column>
        }
        {props.uploadDocument ? (
          <React.Fragment>
            <Grid.Column width={props.readOnly ? "10" : "9"}>
              <Header sub className="Modal__SubTabNav-Documents-AsSubTitle">{props.title}</Header>
              {props.subtitle && <span>{props.subtitle}</span>}
            </Grid.Column>
            <Grid.Column width={!props.readOnly ? "4" : "2"} textAlign="center" verticalAlign="middle" style={{textAlign: "right"}}>
              {props.reference &&
                <React.Fragment>
                  <Icon name="eye" size="large" link onClick={() => props.onDownloadFile(props.reference)}/>
                  {props.statusDocument !== "BY_APPROVED" && !props.readOnly && (
                    <Can do="rejectDocument" on="ContractorDocuments">
                      <Icon
                        name="undo alternate"
                        size="large"
                        link
                        style={{marginLeft: "10px"}}
                        onClick={() => props.onDisapproveDocument(props.reference)}/>
                    </Can>
                  )}
                </React.Fragment>
              }
            </Grid.Column>
            {(!props.isContractor || (props.isContractor && !props.id)) &&
              <Grid.Column width="2" textAlign="center" verticalAlign="middle">
                <Icon name="trash alternate outline" size="large" link onClick={() => props.removeItem({
                  idRef: props.id,
                  reference: props.reference,
                  totalReference : props.totalReference
                })}/>
              </Grid.Column>
            }
          </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid.Column width="12">
                <Header sub className="Modal__SubTabNav-Documents-AsSubTitle">{props.title}</Header>
                <span>{props.subtitle}</span>
              </Grid.Column>
              <Grid.Column width="2" textAlign="center" verticalAlign="middle">
                <Icon name="eye" size="large" link onClick={() => props.onDownloadFile(props.reference)}/>
              </Grid.Column>
            </React.Fragment>
          )
        }
      </Grid>
    </Segment>
  );
}

ViewDocumentSegment.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
};

export default ViewDocumentSegment;
