import React from "react";
import EditReceptionContainer from "./container";
import { Query, Mutation } from "react-apollo";
import { Loader } from "semantic-ui-react";
import getPurchaseOrder from "../../../graphql/queries/inventories/getPurchaseOrder";
import updatePurchaseOrder from "../../../graphql/mutations/inventories/updatePurchaseOrder";
import OnLoading from "../OnLoading";

export default class EditReception extends React.Component {
  render() {
    let { id } = this.props.match.params;

    return (
      <Mutation mutation={updatePurchaseOrder}>
        {mutate => (
          <Query
            query={getPurchaseOrder}
            variables={{ id }}
            fetchPolicy="network-only"
            notifyOnNetworkStatusChange
          >
            {({ loading, error, data, refetch }) => {

              if (loading || error || !data) {
                return <OnLoading loading={loading} error={error} />
              }

              const folio = data.purchaseOrder.folio;
              const requisition = data.purchaseOrder.data;
              const warehouse = data.purchaseOrder.data.warehouse;
              const project = data.purchaseOrder.data.project;
              const supplies = data.purchaseOrder.data.supplies;
              const vendor = data.purchaseOrder.data.vendor;

              return (
                <div className="Modal">
                  <EditReceptionContainer
                    {...this.props}
                    updatePurchaseOrder={mutate}
                    supplies={supplies}
                    warehouse={warehouse}
                    project={project}
                    requisition={requisition}
                    vendor={vendor}
                    id={id}
                    folio={folio}
                  />
                </div>
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}
