import React from "react";
import gql from "graphql-tag";
import OnLoading from "../OnLoading";
import moment from "moment";
import _ from "lodash";
import { Table, TableHeaderCell, Container, Button, ButtonGroup, Select, Modal, Confirm } from "semantic-ui-react";
import { IoMdCreate, IoIosTrash, IoMdEye, IoLogoUsd } from "react-icons/io";
import { client, MUTATIONS } from "../../../graphql/apollo-config";
import { getRequisitions, GetRequisitionByUser } from "../../../graphql/queries/purchases";
import { bugsnagClient } from "../../../bugsnag";
import { Can } from "../../hoc/permissions";
import theme from "../../_theme";
import mixpanel from "../../../mixpanel";
import FormValidator from "../../../helpers/form-validator";
import validations from "./validations";
import { format, isInactive } from "../../../helpers";

const deleteReq = MUTATIONS.deleteReq;

export default class RequisitionsQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      data: null,
      loading: true,
      error: null,
      direction: "descending",
      modalErrorOpen: false,
      modalOpen: false,
      outside: false,
      currentId: "",
      status: "",
      session: window.localStorage.session
        ? JSON.parse(window.localStorage.session)
        : null,
      elaboratedEvent: {
        name: "",
        date: "",
        type: "",
      },
      optionsStatus: [
        {
          key: "IS_REQ_REVISION",
          value: "IS_REQ_REVISION",
          text: "Revisión",
        },
        {
          key: "IS_REQ_REVISED",
          value: "IS_REQ_REVISED",
          text: "Revisada",
        },
        {
          key: "IS_REQ_APPROVED",
          value: "IS_REQ_APPROVED",
          text: "Aprobada",
        },
        {
          key: "IS_REQ_CANCEL",
          value: "IS_REQ_CANCEL",
          text: "Cancelado",
        },
      ]
    };
  }

  async componentWillMount() {
    await this.getRequisitions(this.props.first, this.props.first * (this.props.activePage - 1));
    this.setState(prevState => ({
      ...prevState,
      activeRoute: this.props.activeRoute
    }));
  }

  componentDidMount() {
    const { setReqRefetch, first, skip } = this.props;
    setReqRefetch(() => this.getRequisitions(first, skip));
  }

  handleCloseModal = () => {
    this.setState(data => ({ ...data, modalOpen: false }));
  };

  componentDidUpdate(prevProps) {
    const { activePage, first, search } = this.props;

    if (prevProps.activePage !== activePage || prevProps.first !== first) {
      this.getRequisitions(first, first * (activePage - 1), search);
    }

    if (prevProps.search !== search) {
      this.getRequisitions(first, first * (activePage - 1), search);
    }
  }

  getRequisitions = async (first, skip = 0, search) => {
    try {
      const { session: { user: { id: userId, userRol } } } = this.state;
      const { activePage } = this.props;
      search = format.dateGraphql(search);

      const query = [
        "ADMINISTRATOR",
        "MANAGER",
        "PURCHASES_RESPONSIBLE",
        "ADMINISTRATIVE_COORDINATOR",
        "ZONE_COORDINATOR"
      ].includes(userRol) ? getRequisitions : GetRequisitionByUser

      const getQuery = {
        query,
        variables: {
          userId,
          first,
          skip: first * (activePage - 1),
          ...search
        },
        fetchPolicy: "network-only"
      }

      const { loading, data } = await client.query(getQuery);
      const reqs = data;
      const count = data.count.length;

      this.props.getCount(count, reqs.requisitions.length, activePage);
      this.setState({ loading, data: reqs, userRol });
    } catch (error) {
      this.setState({ error });
      bugsnagClient.notify(error);
    }
  }

  // This is here due to lack of time, moving to right folder later
  updateRequisition = async ({ id, status }) => {
    const mutation = await client.mutate({
      mutation: gql`
        mutation {
          updateRequisition(
            data: {
              status:${status}
            }
            where: { id: "${id}" }
          ){
            id
          }
        }`,
    });
    return mutation;
  };

  _deletePO = async (id, status) => {
    if (status === "IS_PO_CANCEL" || status === "IS_REQ_CANCEL") {
      let prompt = window.confirm("Eliminar requisición");
      if (!prompt) return;

      try {
        await deleteReq(id, client);
        this.setState(prevState => ({ ...prevState, loading: true }));
        this.getRequisitions(this.props.first);
      } catch (err) {
        bugsnagClient.notify(err);
      }
    } else {
      this.setState(prevState => ({ ...prevState, modalErrorOpen: true }));
    }
  };

  // This is here due to lack of time, moving to right folder later
  approve = async (id, user, elaboratedEvent) => {
    if (this.state.status !== this.state.currentStatus) {
      const mutation = await client.mutate({
        mutation:
          elaboratedEvent !== null
            ? gql`
          mutation {
            updateRequisition(
              data: {
                authorizedBy:"${user}"
                elaboratedEvent:{
                  create:{
                    name:"${user}"
                    date:"${elaboratedEvent.date}"
                    type:${elaboratedEvent.type}
                  }
                }
              }
              where: { id: "${id}" }
            ){
              id
            }
          }`
            : gql`
          mutation {
            updateRequisition(
              data: {
                authorizedBy:"${user}"
              }
              where: { id: "${id}" }
            ){
              id
            }
          }`,
      });
      return mutation;
    }
  };

  // This is here due to lack of time, moving to right folder later
  revise = async (id, user, elaboratedEvent, currentStatus) => {
    if (this.state.status !== this.state.currentStatus) {
      let mutation;
      if (elaboratedEvent !== null) {
        mutation = await client.mutate({
          mutation: gql`
            mutation {
              updateRequisition(
                data: {
                  revisedBy:"${user}"
                  elaboratedEvent:{
                    create:{
                      name:"${user}"
                      date:"${elaboratedEvent.date}"
                      type:${elaboratedEvent.type}
                    }
                  }
                }
                where: { id: "${id}" }
              ){
                id
              }
            }`,
        });
      } else {
        mutation = await client.mutate({
          mutation: gql`
            mutation {
              updateRequisition(
                data: {
                  revisedBy:"${user}"
                }
                 where: { id: "${id}" }
              ){
                id
              }
            }`,
        });
      }

      return mutation;
    }
  };

  // This is here due to lack of time, moving to right folder later
  cancel = async (id, user, elaboratedEvent, currentStatus) => {
    if (this.state.status !== this.state.currentStatus) {
      let mutation;
      if (elaboratedEvent !== null) {
        mutation = await client.mutate({
          mutation: gql`
            mutation {
              updateRequisition(
                data: {
                  elaboratedEvent:{
                    create:{
                      name: "${user}"
                      date: "${moment().local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}"
                      type: REQUISITION_CANCELED
                    }
                  }
                }
                where: { id: "${id}" }
              ){
                id
              }
            }`,
        });
      }

      return mutation;
    }
  };

  isReq = status => {
    if (status === "IS_REQ_APPROVED") {
      return { isReq: true, status: "IS_REQ_APPROVED" };
    } else {
      return { isReq: true, status: status };
    }
  };

  handleClose = () => this.setState({ modalErrorOpen: false });

  onChangeSelect = (e, { value, data }) => {
    const { userRol } = this.state

    if (typeof e._dispatchListeners === "function") {
      return false;
    }

    const status = value;
    const supplies = data._data.supplies;
    const revisedBy = data.revisedBy;
    const authorizedBy = data.authorizedBy;
    const currentStatus = data.currentStatus;

    const validationRevised = new FormValidator(validations.revised);
    const validationApproved = new FormValidator(validations.approve);
    const revised = validationRevised.validate(data._data);
    const approve = validationApproved.validate(data._data);
    const validSupplies = supplies.length > 0;

    if (status === "IS_REQ_REVISION") {
      const id = data.id;
      const text = e.currentTarget.innerText;
      this.setState(data => ({
        ...data,
        id: id,
        statusText: text,
        status: status,
        modalOpen: true,
        currentId: id,
        currentStatus,
      }));
    } else if (status === "IS_REQ_CANCEL") {
      const id = data.id;
      const text = e.currentTarget.innerText;
      this.setState(data => ({
        ...data,
        id: id,
        statusText: text,
        status: status,
        modalOpen: true,
        currentId: id,
        currentStatus,
      }));
    } else if (
      status === "IS_REQ_REVISED" &&
      revised.isValid &&
      validSupplies
    ) {
      const id = data.id;
      const text = e.currentTarget.innerText;
      this.setState(data => ({
        ...data,
        id: id,
        statusText: text,
        status: status,
        modalOpen: true,
        currentId: id,
        revisedBy: revisedBy,
        authorizedBy: authorizedBy,
        currentStatus,
        elaboratedEvent: {
          name: revisedBy,
          date: moment()
            .local()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          type: "REQUISITION_REVISED",
        },
      }));
    } else if (
      status === "IS_REQ_APPROVED" &&
      approve.isValid &&
      validSupplies
    ) {
      const id = data.id;
      const text = e.currentTarget.innerText;
      this.setState(data => ({
        ...data,
        id: id,
        statusText: text,
        status: status,
        modalOpen: true,
        currentStatus,
        currentId: id,
        revisedBy: revisedBy,
        authorizedBy: authorizedBy,
        elaboratedEvent: {
          name: revisedBy,
          date: moment()
            .local()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          type: "REQUISITION_APPROVED",
        },
      }));
    } else {
      if (status === "IS_REQ_REVISED") {
        alert("Termina de llenar los campos de la requisición");
        this.props.toggleEditRequisirtion(data._data);
      }
      if (status === "IS_REQ_APPROVED") {
        alert("La requisición no está revisada");
        this.props.toggleEditRequisirtion(data._data);
      }
    }
  };

  saveStatus = () => {
    const obj = {
      id: this.state.id,
      client: client,
      status: this.isReq(this.state.status).status,
    };

    if (
      this.state.currentId !== "" &&
      this.state.status === "IS_REQ_REVISED"
    ) {
      this.revise(
        this.state.currentId,
        this.state.session.user.name,
        this.state.elaboratedEvent,
        this.state.currentStatus
      )
        .then(res => {
          this.updateRequisition(obj)
            .then(res => {
              this.getRequisitions(this.props.first);
              mixpanel.track("Actualización de estado requisición", {
                estado: "Revisada",
              });
              this.setState({ modalOpen: false, currentId: "" });
            })
            .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
    } else if (
      this.state.currentId !== "" &&
      this.state.status === "IS_REQ_APPROVED"
    ) {
      this.approve(
        this.state.currentId,
        this.state.session.user.name,
        this.state.elaboratedEvent
      )
        .then(res => {
          this.updateRequisition(obj)
            .then(res => {
              this.getRequisitions(this.props.first);
              mixpanel.track("Actualización de estado requisición", {
                estado: "Aprobada",
              });
              this.setState({ modalOpen: false, currentId: "" });
            })
            .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
    } else if (
      this.state.currentId !== "" &&
      this.state.status === "IS_REQ_CANCEL"
    ) {
      this.cancel(
        this.state.currentId,
        this.state.session.user.name,
        this.state.elaboratedEvent
      )
        .then(res => {
          this.updateRequisition(obj)
            .then(res => {
              this.getRequisitions(this.props.first);
              mixpanel.track("Actualización de estado requisición", {
                estado: "Cancelada",
              });
              this.setState({ modalOpen: false, currentId: "" });
            })
            .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
    } else {
      this.revise(
        this.state.currentId,
        "",
        null,
        this.state.currentStatus
      )
        .then(res => {
          this.approve(this.state.currentId, "", null)
            .then(res => {
              this.updateRequisition(obj)
                .then(res => {
                  this.getRequisitions(this.props.first);
                  mixpanel.track("Actualización de estado requisición", {
                    estado: "Revisión"
                  });
                  this.setState({ modalOpen: false, currentId: "" });
                })
                .catch(e => console.log(e));
            })
            .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
    }
  };

  handleSort = clickedColumn => () => {
    const { direction, column, data: { requisitions } } = this.state;
    requisitions.forEach(item => item.status = format.optionsPurchaseOrders(item.status));

    const sortBy = clickedColumn !== column ?
      "descending" : direction !== "ascending" ? "descending" : "ascending";
    const sorting = _.orderBy(requisitions, [clickedColumn], [sortBy.replace("ending", "")]);

    requisitions.forEach(item => item.status = format.optionsPurchaseOrders(item.status));

    this.setState({
      column: clickedColumn,
      data: { requisitions: sorting },
      direction: clickedColumn !== column ? "ascending" : direction === "ascending" ? "descending" : "ascending"
    });
  }

  render() {
    const { data, loading, error, column, direction } = this.state;

    if (loading || error || !data || !data.requisitions.length) {
      return <OnLoading loading={loading} error={error} />
    }

    return (
      <Container fluid>
        <Table
          basic="very"
          compact
          textAlign="center"
          sortable
          className="Wrapper__Body-Table"
        >
          <Table.Header className="Wrapper__Body-Table-Header Small Caps">
            <Table.Row>
              <TableHeaderCell
                style={{
                  border: "none",
                  borderBottom: "1px solid #dddddd",
                }}
                sorted={column === "folio" ? direction : "descending"}
                onClick={this.handleSort("folio")}
              >
                Folio
              </TableHeaderCell>
              <TableHeaderCell
                style={{
                  border: "none",
                  borderBottom: "1px solid #dddddd",
                }}
                sorted={column === "project.name" ? direction : "descending"}
                onClick={this.handleSort("project.name")}
              >
                Nombre del proyecto
              </TableHeaderCell>
              <TableHeaderCell
                style={{
                  border: "none",
                  borderBottom: "1px solid #dddddd",
                }}
                sorted={column === "createdAt" ? direction : "descending"}
                onClick={this.handleSort("createdAt")}
              >
                Fecha de creación
              </TableHeaderCell>
              <TableHeaderCell
                style={{
                  border: "none",
                  borderBottom: "1px solid #dddddd",
                }}
                sorted={column === "project.cli" ? direction : "descending"}
                onClick={this.handleSort("project.cli")}
              >
                Num de presupuesto CLI
              </TableHeaderCell>
              <TableHeaderCell
                style={{
                  border: "none",
                  borderBottom: "1px solid #dddddd",
                }}
                sorted={column === "deliveryDate" ? direction : "descending"}
                onClick={this.handleSort("deliveryDate")}
              >
                Fecha de entrega
              </TableHeaderCell>
              <TableHeaderCell
                style={{
                  border: "none",
                  borderBottom: "1px solid #dddddd",
                }}
                sorted={column === "deliveryWarehouse" ? direction : "descending"}
                onClick={this.handleSort("deliveryWarehouse")}
              >
                Almacén
              </TableHeaderCell>
              <TableHeaderCell
                style={{
                  border: "none",
                  borderBottom: "1px solid #dddddd",
                }}
                sorted={column === "vendor.commercialName" ? direction : "descending"}
                onClick={this.handleSort("vendor.commercialName")}
              >
                Proveedor
              </TableHeaderCell>
              <TableHeaderCell
                style={{
                  border: "none",
                  borderBottom: "1px solid #dddddd",
                }}
                sorted={column === "status" ? direction : "descending"}
                onClick={this.handleSort("status")}
              >
                Estatus
              </TableHeaderCell>
              <TableHeaderCell className="Wrapper__Body-Table-Header-NoHover" />
            </Table.Row>
          </Table.Header>
          <Table.Body className="Wrapper__Body-Table-Body Small Caps">
            {data.requisitions.map((requisition) => {
              const isInactiveProject = isInactive(requisition.project.status);
              const warehouse = requisition.deliveryWarehouse.replace(
                "\\/g",
                ""
              );
              if (this.props.activeRoute === "req") {
                return (
                  <Table.Row key={requisition.id} textAlign="center">
                    <Table.Cell>{requisition.folio}</Table.Cell>
                    <Table.Cell>{requisition.project.name}</Table.Cell>
                    <Table.Cell>
                      {format.date(requisition.wasUpdatedAt)}
                    </Table.Cell>
                    <Table.Cell>{requisition.project.cli}</Table.Cell>
                    <Table.Cell>
                      {requisition.deliveryDate !== "undefined"
                        ? requisition.deliveryDate
                        : ""}{" "}
                    </Table.Cell>
                    <Table.Cell>
                      {requisition.deliveryWarehouse === "null" ||
                        requisition.deliveryWarehouse === "undefined" ||
                        requisition.deliveryWarehouse === undefined
                        ? ""
                        : JSON.parse(warehouse).name}
                    </Table.Cell>
                    <Table.Cell>
                      {requisition.vendor !== null
                        ? requisition.vendor.commercialName
                        : ""}
                    </Table.Cell>
                    <Table.Cell>
                      <Select
                        disabled={isInactiveProject}
                        id={`select-status-${requisition.project.cli}`}
                        name="status"
                        data={{
                          isReq: requisition.isReq,
                          id: requisition.id,
                          _data: requisition,
                          revisedBy: requisition.revisedBy,
                          authorizedBy: requisition.authorizedBy,
                        }}
                        value={requisition.status}
                        text={format.optionsPurchaseOrders(requisition.status)}
                        options={this.state.optionsStatus}
                        style={{ border: "none" }}
                        onChange={this.onChangeSelect}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <ButtonGroup basic>
                        <Can do="convert" on="Requisition">
                          <Button
                            disabled={isInactiveProject}
                            id={`convert-req-btn-${
                              requisition.project.cli
                              }`}
                            onClick={
                              requisition.status === "IS_REQ_APPROVED"
                                ? () => !isInactiveProject && this.props.openCreate(requisition)
                                : null
                            }
                            icon
                          >
                            <IoLogoUsd
                              color={
                                requisition.status === "IS_REQ_APPROVED"
                                  ? null
                                  : !isInactiveProject && "#dddddd"
                              }
                            />
                          </Button>
                        </Can>
                        <Button
                          disabled={isInactiveProject}
                          id={`edit-req-btn-${requisition.project.cli}`}
                          onClick={
                            ["IS_REQ_APPROVED", "IS_REQ_CANCEL", "IS_REQ_REVISED"]
                              .includes(requisition.status)
                              ? null
                              : () => !isInactiveProject && this.props.toggleEditRequisirtion(requisition)
                          }
                          icon
                        >
                          <IoMdCreate
                            style={
                              requisition.status !== "IS_REQ_REVISION"
                                ? !isInactiveProject && { color: "#dddddd" }
                                : null
                            }
                          />
                        </Button>
                        <Button
                          disabled={isInactiveProject}
                          id={`delete-req-btn-${requisition.project.cli}`}
                          onClick={
                            !isInactiveProject ? () => this._deletePO(requisition.id, requisition.status) : null
                          }
                          icon
                        >
                          <IoIosTrash />
                        </Button>
                        <Button
                          disabled={isInactiveProject}
                          id={`detail-req-btn-${requisition.project.cli}`}
                          onClick={
                            !isInactiveProject ? () => this.props.toggleDetailRequisirtion(requisition) : null
                          }
                          icon
                        >
                          <IoMdEye />
                        </Button>
                      </ButtonGroup>
                    </Table.Cell>
                  </Table.Row>
                );
              }
            })}
          </Table.Body>
        </Table>
        <Modal size="small" open={this.state.modalErrorOpen}>
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <p>Primero debes cancelar la requisición</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.handleClose}
              style={theme.styles.buttons.red}
            >
              Aceptar
            </Button>
          </Modal.Actions>
        </Modal>
        <Confirm
          open={this.state.modalOpen}
          cancelButton="Cancelar"
          confirmButton="Aceptar"
          onCancel={() => this.handleCloseModal()}
          onConfirm={() => this.saveStatus()}
          content={`¿Deseas cambiar el estado de la requisición a ${
            this.state.statusText
            }?`}
        />
      </Container>
    );
  }
}
