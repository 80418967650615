import gql from "graphql-tag";
import { client } from "../../apollo-config";

const DELETE_ADITIVE_CONTRACT = async ({
  idContract,
  idAditive,
  amountAditives
}, { GET_ADITIVES_CONTRACT, QUERY_CONTRACTS }) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContract(
        data: {
          amountAditives: ${parseFloat(amountAditives)}
          aditives: {
            delete: {
              id: "${idAditive}"
            }
          }
        },
        where: { id: "${idContract}" }
        ) {
          id
          finishDate
          realFinishDate
        }
      }
    `,
    refetchQueries: [{ query: QUERY_CONTRACTS }],
    update: (proxy, { data: { updateContract } }) => {
      const query = proxy.readQuery({ query: GET_ADITIVES_CONTRACT, variables: { id: idContract } });
      const aditives = query.contract.aditives.filter(aditive => aditive.id !== idAditive);

      const data = {
        contract: { ...query.contract,
          amountAditives: +amountAditives,
          aditives
        }
      }

      proxy.writeQuery({
        query: GET_ADITIVES_CONTRACT,
        variables: { id: idContract },
        data
      })
    }
  });
  return mutation;
};

export default DELETE_ADITIVE_CONTRACT;
