import gql from "graphql-tag";
import { client } from "../../apollo-config";

const login = async (email, password) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        createLogin(
          data: { email: "${email}", password: "${password}" }
        ) {
          session
        }
      }
    `,
  });
  return mutation;
};

export default login;
