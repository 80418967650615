import React from "react";
import { TabPanel } from "../../Atoms";
import TabContainer from "../TabContainer";
import OriginalContractList from "../OriginalContractList";
import ContractAditivesList from "../ContractAditivesList";
import ContractSummary from "../ContractSummary";

const ContractAccountBalance = props => {
  const { id, readOnly } = props;

  return (
    <TabContainer active="original">
      <TabPanel name="original" text="Contrato original">
        <OriginalContractList id={id} readonly={readOnly}/>
      </TabPanel>
      <TabPanel name="additives" text="Aditivas">
        <ContractAditivesList id={id} readonly={readOnly}/>
      </TabPanel>
      <TabPanel name="summary" text="Resumen">
        <ContractSummary id={id} readonly={readOnly} />
      </TabPanel>
    </TabContainer>
  )
}

export default ContractAccountBalance;
