import React from "react";
import { Container, Table } from "semantic-ui-react";
import RowsQueryAccountBalanceDocuments from "./RowsQueryAccountBalanceDocuments";

class AccountBalanceDocumentsQuery extends React.Component {
  render () {
    const { handleDeleteDocument, handleShowModal, cli, contractAccountBalance: { documentsContractAccountBalance = [] } } = this.props;
    return(
      <Container fluid className="Wrapper__Body-Container-Table MarginTop">
        <Table basic="very" compact="very" textAlign="center" sortable className="Wrapper__Body-Table" singleLine={true}>
          <Table.Header className="Wrapper__Body-Table-Header Small">
            <Table.Row>
              <Table.HeaderCell>
                Tipo
              </Table.HeaderCell>
              <Table.HeaderCell>
                Número de factura
              </Table.HeaderCell>
              <Table.HeaderCell>
                Presupuesto CLI
              </Table.HeaderCell>
              <Table.HeaderCell>
                Concepto
              </Table.HeaderCell>
              <Table.HeaderCell>
                Moneda
              </Table.HeaderCell>
              <Table.HeaderCell>
                Fecha
              </Table.HeaderCell>
              <Table.HeaderCell>
                Importe
              </Table.HeaderCell>
              <Table.HeaderCell>
                Amortizado
              </Table.HeaderCell>
              <Table.HeaderCell>
                Retenido
              </Table.HeaderCell>
              <Table.HeaderCell>
                Total
              </Table.HeaderCell>
              <Table.HeaderCell>
                Elaboró
              </Table.HeaderCell>
              <Table.HeaderCell>
                Estatus
              </Table.HeaderCell>
              <Table.HeaderCell className="Wrapper__Body-Table-Header-NoHover"/>
            </Table.Row>
          </Table.Header>
          <Table.Body className="Wrapper__Body-Table-Body Small">
            {documentsContractAccountBalance.map(original => (
              <React.Fragment key={original.id}>
                <RowsQueryAccountBalanceDocuments
                  key={original.id}
                  cli={cli}
                  original={original}
                  handleDeleteDocument={handleDeleteDocument}
                  handleShowModal={handleShowModal}
                />
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      </Container>
    )
  }
}

export default AccountBalanceDocumentsQuery;
