import { client } from "../../../apollo-config";
import { CREATE_CONTRACT } from "./CREATE_CONTRACT";
import { format } from "../../../../helpers";
import { UPDATE_CONTRACT } from "./UPDATE_CONTRACT";

const createContract = async ({ id, ...params }) => {
  const { contractor, documentsContractor } = params

  const documentsContractorToCreate = documentsContractor.map(document => ({
    ...document,
    elaborated: contractor.id,
    dateElaborated: format.date(new Date(), 2),
    refUpload: {
      create: [
        {
          ...document.refUpload[0],
          createDate: format.date(new Date(), 2)
        }
      ]
    }
  }))

  const variables = formatVariables({
    ...params,
    documentsContractor: { create: documentsContractorToCreate }
  })

  return client.mutate({
    mutation: CREATE_CONTRACT,
    variables
  })
}

const updateContract = async ({ id, ...params }) => {
  const { contractor, documentsContractor } = params

  const documentsContractorToUpsert = documentsContractor.map(({ id, ...document }) => {
    const { refUpload } = document || {}
    const [ refUploadDocument ] = refUpload || []
    const { id: refDocumentId, ...cleanRefUploadDocument } = refUploadDocument || {}

    return {
      update: {
        refUpload: {
          update: {
            data: {
              ...cleanRefUploadDocument,
              createDate: format.date(new Date(), 2)
            },
            where: { id: document.refUpload[0].id || "" }
          }
        }
      },
      create: {
        ...document,
        elaborated: contractor.id,
        dateElaborated: format.date(new Date(), 2),
        refUpload: {
          create: [
            {
              ...cleanRefUploadDocument,
              createDate: format.date(new Date(), 2)
            }
          ]
        }
      },
      where: { id: id || "" }
    }
  })

  const { data } = formatVariables({
    ...params,
    realFinishDate: params.finishDate,
    documentsContractor: { upsert: documentsContractorToUpsert }
  })

  return client.mutate({
    mutation: UPDATE_CONTRACT,
    variables: {
      id,
      data
    }
  })
}

const formatVariables = (variables) => {
  const { contractor, project, documentsContractor, ...data } = variables

  return {
    data: {
      ...data,
      contractor: {
        connect: {
          id: contractor.id
        }
      },
      project: {
        connect: {
          id: project.id
        }
      },
      documentsContractor
    }
  }
}

export default { createContract, updateContract}
