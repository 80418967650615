import React from "react"
import { Button, Grid, Segment, Form } from "semantic-ui-react";
import ContractQuery from "../../Molecules/ContractQuery";
import { Can } from "../../hoc/permissions";
import { titleCase } from "../../../helpers";
import { useCurrentUser } from "../../../hooks";
import { PaginationBar } from "../../Molecules/PaginationBar";
import { GET_CONTRACT_BY_CONTRACTOR, QUERY_CONTRACTS } from "../../../graphql/queries/contractors";
import moment from "moment/moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";

class ContractList extends React.Component {
  constructor(props) {
    super(props);
    const { currentUser: { user: { userRol, id: userID }} } = useCurrentUser();
    const query = userRol === "CONTRACTOR" ? GET_CONTRACT_BY_CONTRACTOR : QUERY_CONTRACTS;
    const id = userRol === "CONTRACTOR" ? userID : null;

    this.state = {
      filter: query,
      totalRecords: 0,
      perPageRecords: 0,
      filterUpdated: true,
      restartCurrentPage: false,
      filterVariables: {
        id,
        status: undefined,
        search: "",
        searchUpper: "",
        searchLower: "",
        searchCaps: "",
        date: undefined,
        date_lt: undefined,
        first: 10,
        skip: 0
      }
    };
  }

  onPageChange = (page) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        skip: 10 * (page - 1)
      }
    }))
  };

  onPerPageChange = (perPage) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecords: perPage,
      filterVariables: {
        ...prevState.filterVariables,
        first: perPage
      }
    }))
  };

  debounce = (callback, delay) => {
    let timeOutId = null;
    return (...args) => {
      if (timeOutId) clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    }
  }

  searchNow = (e, { value }) => {
    this.setState(prevState => ({
      ...prevState,
      filterUpdated: true,
      restartCurrentPage: true,
      filterVariables: {
        ...prevState.filterVariables,
        search: !value ? "" : value,
        searchUpper: !value ? "" : value.toUpperCase(),
        searchLower: !value ? "" : value.toLowerCase(),
        searchCaps: !value ? "" : titleCase(value),
      }
    }))
  };

  onFiltersDate = dates => {
    if (dates && dates.length === 2) {
      let { 0: date, 1: date_lt } = dates
      date = moment(date).format('YYYY-MM-DD')
      date_lt = moment(date_lt).format('YYYY-MM-DD')

      this.setState(prevState => ({
        ...prevState,
        filterUpdated: true,
        restartCurrentPage: true,
        filterVariables: {
          ...prevState.filterVariables,
          date,
          date_lt
        }
      }))
    }

    if (dates === null) {
      this.setState(prevState => ({
        ...prevState,
        filterUpdated: true,
        restartCurrentPage: true,
        filterVariables: {
          ...prevState.filterVariables,
          date: undefined,
          date_lt: undefined
        }
      }))
    }
  }

  onFiltersStatus = status => {
    this.setState(prevState => ({
      ...prevState,
      filterUpdated: true,
      restartCurrentPage: true,
      filterVariables: {
        ...prevState.filterVariables,
        status: !status ? undefined : status
      }
    }))
  }

  onCompleteData = data => {
    const totalRecords = data.contracts.length
    const count = data.count.length

    if (this.state.filterUpdated) {
      this.setState(prevState => ({
        ...prevState,
        filterUpdated: false,
        restartCurrentPage: false,
        totalRecords: count,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords
      }))

      this.onPageChange(1)
    }
  };

  onOrderChange = (header) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        orderBy: header.orderEnum
      }
    }));
  }

  onReload = () => {
    this.setState(prevState =>({
      ...prevState,
    }))
  }

  render() {
    const { id, filter, filterVariables, totalRecords, perPageRecords } = this.state
    const { openModal } = this.props

    const statusOptions = [
      { key: 0, text: "Todas", value: "" },
      { key: 1, text: "Activas", value: "ACTIVE" },
      { key: 2, text: "Inactivas", value: "INACTIVE" },
      { key: 3, text: "Finalizado", value: "FINISHED" },
    ]

    return (
      <Segment>
        <Grid columns="equal">
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column>
              <Form
                className="Wrapper__Body-Form Jax"
                style={{ display: 'flex' }}>
                <Form.Input
                  placeholder="Buscar"
                  icon="search"
                  width="6"
                  onChange={this.debounce(this.searchNow, 500)}
                />
                <div
                  style={{ margin: "0 1rem" }}>
                  <Form.Select
                    placeholder="Estatus"
                    name="status"
                    options={statusOptions}
                    value={this.state.filterVariables.status}
                    onChange={(e, { value }) => this.onFiltersStatus(value)}
                  />
                </div>
                <SemanticDatepicker
                  style={{ }}
                  name="date"
                  placeholder="Fecha"
                  type="range"
                  format="DD/MM/YYYY"
                  onDateChange={this.onFiltersDate}
                />
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Can do="create" on="Contracts">
                <Button
                  basic
                  onClick={() => this.props.openModal("create")}
                  icon="plus"
                  content="Contrato"
                  className="Wrapper__Body-Form-ButtonAdd"/>
              </Can>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div style={{ overflowX: "auto", width: "100%" }}>
                <ContractQuery
                  id={id}
                  filter={filter}
                  filterVariables={filterVariables}
                  action={this.onCompleteData}
                  onReload={this.onReload}
                  onOrderChange={this.onOrderChange}
                  openModal={openModal}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <PaginationBar
              restartCurrentPage={this.state.restartCurrentPage}
              onPageChange={(p) => this.onPageChange(p)}
              onPerPageChange={(p) => this.onPerPageChange(p)}
              totalRecords={totalRecords}
              perPageRecords={perPageRecords} />
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }
}

export default ContractList
