import gql from "graphql-tag";
import { client } from "../../apollo-config";

const getSupplies = async () => {
  const { data } = await client.query({
    query: gql`
      {
        supplies {
          id
          insID
          name
          description
          units
          purchasePrice
          count
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
  return data;
};
export default getSupplies;
