import React from "react";
import { Query } from "react-apollo";
import { Dropdown } from "semantic-ui-react";
import { getProjectsFull, getProjectsByUser } from "../../../graphql/queries/projects"

const SelectClient = props => {
  const {
    user: { id: idUser, userRol },
    label,
    name,
    error,
    value,
    onChange,
    disabled = false,
    width = 10
  } = props

  const getQuery = {
    fetchPolicy: "network-only"
  }

  const isRole = [
    "ADMINISTRATOR",
    "MANAGER",
    "PURCHASES_RESPONSIBLE",
    "ADMINISTRATIVE_COORDINATOR",
    "ZONE_COORDINATOR"
  ]
    .includes(userRol)

  if (isRole) {
    getQuery.query = getProjectsFull
    getQuery.variables = null
  } else {
    getQuery.query = getProjectsByUser
    getQuery.variables = { idUser }
  }

  return (
    <Query {...getQuery}>
      {({ loading, error, data }) => {
        if (loading) {
          return <p testid="loading-wrapper">loading...</p>;
        }
        if (error) {
          return <p testid="error-wrapper">error</p>;
        }

        const { projects } = data

        return (
          <Dropdown
            fluid
            selection
            options={projects}
            label={label}
            name={name}
            error={error}
            value={value}
            onChange={onChange}
            disabled={disabled}
            width={width}
          />
        );
      }}
    </Query>
  )
}

export default SelectClient;
