import React from "react";
import "./style.css";
import Button from "../../Atoms/button/index";

export default class ButtonGroup extends React.Component {
  render() {
    return (
      <div id={this.props.id} className="buttonGroup">
        {this.props.children}
      </div>
    );
  }
}
