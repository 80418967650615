import React from "react";
import { Link } from "../../../Atoms";

export default class PONavigation extends React.Component {
  renderLink = route => {
    const { activeRoute, navigateTo } = this.props;
    const { routeName, text, name } = route;
    const linkClass = routeName === activeRoute ? "link link-active" : "link";

    return (
      <Link
        onClick={() => navigateTo(route)}
        key={routeName}
        className={linkClass}
        iconName={name}
        text={text}
      />
    );
  };

  render() {
    const { className, routes } = this.props;
    const links = routes.map(this.renderLink);

    return (
      <div
        className={`navigation`}
        style={{ margin: 0, float: "left", width: "100%" }}
      >
        {links}
      </div>
    );
  }
}
