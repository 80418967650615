import { format } from "../helpers"

export default async ({ type, name, email }) => {
  const isProduction = process.env.NODE_ENV === 'production'
  const newEmail = isProduction ? email : 'qamail@jaxitank.com'

  await fetch(process.env.REACT_APP_MAILER, {
    method: "post",
    body: JSON.stringify({
      sender: process.env.REACT_APP_EMAIL_SENDER,
      email: newEmail,
      subject: "Documento ha sido autorizado",
      body: process.env.REACT_APP_TEMPLATE_STATUS_DOCUMENT_AB,
      params: {
        name: name,
        document: format.typeDocumentsContract(type),
        url: 'https://app.climas.com/estado-de-cuenta',
      },
      key: process.env.REACT_APP_MAILER_KEY,
      noReply: true,
    }),
    headers: { "Content-Type": "application/json" },
  });
};
