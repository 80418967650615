import React from "react";
import "./style.css";
import Title from "../../Atoms/title/index";
import Menu from "../../Molecules/menu/index";

export default class Header extends React.Component {
  render() {
    return (
      <div className="header-nav">
        <Title className="nav-title" title={this.props.title} />
        &nbsp; &nbsp; &nbsp; &nbsp;
        <Menu className={this.props.menuClass}>{this.props.children}</Menu>
      </div>
    );
  }
}
