const message = (projectName) => ({
  req: `No puedes crear una requisición si el proyecto ${projectName} esta inactivo`,
  oc: `No puedes crear una orden de compra si el proyecto ${projectName} esta inactivo`,
  contract: `No puedes crear un contrato si el proyecto ${projectName} esta inactivo`
})

export const alertProjectInactive = ({ status, module, projectName }) => {
  if (status === 'INACTIVE') {
    alert(message(projectName)[module]);
    return true
  }

  return false
}
