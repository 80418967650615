import gql from "graphql-tag";
import { client } from "../../apollo-config";
import bcrypt from "bcryptjs"

const updateContractor = async ({
  id,
  name,
  commercialName,
  street,
  colony,
  city,
  rfc,
  externalNumber,
  postalCode,
  personType,
  internalNumber,
  state,
  country,
  contacts,
  email,
  password
}) => {
  const salt = bcrypt.genSaltSync(10);
  const hash = bcrypt.hashSync(password ? password : "xxx", salt);

  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContractor(
          where: { id: "${id}" },
          data: {
            name: "${name}"
            commercialName: "${commercialName}"
            street: "${street}"
            colony: "${colony}"
            city: "${city}"
            rfc: "${rfc}"
            externalNumber: "${externalNumber}"
            postalCode: ${parseInt(postalCode)}
            personType: ${personType}
            internalNumber: "${internalNumber}"
            state: "${state}"
            country: "${country}"
            contacts: {
              create: [${contacts.map(contact => {
                if (contact.new) {
                  delete contact.new;
                  const str = JSON.stringify(contact);
                  var obj = str.replace(/\"([^(\")"]+)\":/g, "$1:");
                  obj = obj.replace(/\,type:"([^(\")"]+)"/g, ",type:$1");
                  return obj;
                }
              })}]
            }
            user: ${password ? `{
              update : {
                email: "${email}"
                password: "${hash}"
                name: "${name}"
              }
            }`: `{
                update : {
                  email: "${email}"
                  name: "${name}"
                }
              }`
            }
          }
        ) {
          id
        }
      }
    `
  });
  return mutation;
};

export default updateContractor;
