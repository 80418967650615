import React from "react";
import { ApolloClient } from "apollo-service";
import gql from "graphql-tag";
import {
  Button,
  Container,
  Grid,
  Segment,
  Image,
  Input,
  Message,
  Form,
} from "semantic-ui-react";
import logo from "../../../assets/logo";
import login from "../../../graphql/mutations/auth";
require("dotenv");

// Create an apollo client for a one time operation
const apolloClient = token => {
  const apollo = new ApolloClient(
    token,
    process.env.REACT_APP_DB_URL,
    process.env.REACT_APP_SERVER_WS
  );

  const client = apollo.getClient();
  return client;
};

const containerStyle = {
  position: "absolute",
  top: "0",
  width: "100%",
  height: "100%",
  background: "#fbfbfb",
};

const btnStyle = {
  color: "white",
  backgroundColor: "#b3363e",
  fontSize: "12px",
  borderRadius: "15px",
  width: "250px",
};

export default class CreatePassword extends React.Component {
  state = {
    token: null,
    payload: {
      email: "",
      password: "",
      passwordConfirm: "",
    },
    errors: {
      password: false,
      passwordConfirm: false,
    },
    updatePassword: (email, password) => {
      return gql`
      mutation {
        updateUser(
          where:{email:"${email}"}
          data: { password: "${password}" emailConfirmed:true }
        ) {
          id
        }
      }
    `;
    },
  };

  async componentWillMount() {
    const temp = window.location.pathname.split("temp=")[1];
    const email = window.location.pathname.split("email=")[1].split("temp=")[0];
    const session = await login(email, temp);
    const payload = this.state.payload;
    payload["email"] = email;
    this.setState({
      token: session["data"]["createLogin"]["session"]["token"],
    });
    if (session.data.createLogin.session.code === 400) {
      const conf = window.confirm("Url inválida");
      if (conf) {
        window.location = "/auth/login";
      } else {
        window.location = "/auth/login";
      }
    }
  }

  handleChange = (e, { name, value }) => {
    const payload = this.state.payload;
    payload[name] = value;
    this.setState({ ...payload });
  };

  validatePassword = () => {
    const { password, passwordConfirm } = this.state.payload;
    const passwordCondition = password !== "" && password.length >= 8;
    const passwordConfirmCondition =
      passwordConfirm !== "" && password.length >= 8;
    this.setState({
      errors: {
        password: !passwordCondition,
        passwordConfirm: !passwordConfirmCondition,
      },
    });
    return { isValid: passwordCondition };
  };

  updatePassword = async () => {
    const { updatePassword, payload } = this.state;
    const { email, password } = payload;
    const validation = this.validatePassword();
    if (validation.isValid) {
      const client = apolloClient(this.state.token);
      try {
        await client.mutate({
          mutation: updatePassword(email, password),
        });
        window.location = "/auth/login";
      } catch (err) {
        throw new Error(err);
      }
    }
  };

  render() {
    return (
      <Container style={containerStyle}>
        <Segment
          style={{ margin: "0 auto", marginTop: "10%", width: "30%" }}
          padded="very"
          size="mini"
          raised
        >
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Image size="mini" centered src={logo.image} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column />
            </Grid.Row>
            <Grid.Row textAlign="center">
              <Grid.Column>
                <Message warning>
                  <Message.Header>Crea tu contraseña</Message.Header>
                  <p>Crea una nueva contraseña para continuar.</p>
                </Message>
                <Form>
                  <Form.Field
                    label="Contraseña"
                    control={Input}
                    type="password"
                    name="password"
                    onChange={this.handleChange}
                    error={
                      this.state.payload.password !== "" &&
                      this.state.payload.password.length >= 8
                        ? false
                        : this.state.errors.password
                    }
                  />
                  <Form.Field
                    label="Confirmar contraseña"
                    control={Input}
                    type="password"
                    name="passwordConfirm"
                    onChange={this.handleChange}
                    error={
                      this.state.payload.passwordConfirm !== "" &&
                      this.state.payload.passwordConfirm.length >= 8
                        ? false
                        : this.state.errors.passwordConfirm
                    }
                  />
                  <Button
                    type="button"
                    style={btnStyle}
                    onClick={this.updatePassword}
                  >
                    Crear contraseña
                  </Button>
                  <p>La contraseña debe contener al menos ocho caracteres.</p>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
}
