import React from "react";
import CurrencyInput from "react-currency-input";
import { Form, Input, Segment, TextArea } from "semantic-ui-react";
import { ApolloProvider } from "react-apollo";
import { FormHeader } from "../../Molecules";
import validator from "../../../helpers/validator";
import { client } from "../../../graphql/apollo-config";
import { CREATE_SUPPLY, UPDATE_SUPPLY } from "../../../graphql/mutations/supplies";
import supplies from "../../../graphql/queries/purchases/getSupplies";
import OnLoading from "../../Molecules/OnLoading";
import { bugsnagClient } from "../../../bugsnag";

export default class AddSupplyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      insID: "",
      description: "",
      units: "",
      purchasePrice: 0,
      nameError: false,
      descriptionError: false,
      unitsError: false,
      purchasePriceError: false,
      existingItems: [],
      isLoading: true
    };
  }

  componentWillMount() {
    this.checkForExistingSupplies();
  }

  checkForExistingSupplies = async () => {
    const query = await supplies();

    if (this.props.data) {
      const { id, insID, name, description, purchasePrice, units } = this.props.data;

      this.setState(prevState => ({
        ...prevState,
        existingItems: query.supplies,
        id,
        insID,
        name,
        description,
        purchasePrice,
        units,
        isLoading: false
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        existingItems: query.supplies,
        isLoading: false
      }));
    }
  };

  save = async () => {
    const supply = this.getSupplyFields();
    const isValid = this.validateFields(supply);

    if (isValid.length === 0 && !this.supplyExists(supply)) {
      try {
        this.setState(prevState => ({ ...prevState, isLoading: true }));
        await CREATE_SUPPLY(supply);
        this.props.toggleSave();
      } catch (e) {
        this.setState(prevState => ({ ...prevState, isLoading: false }));
        bugsnagClient.notify(e);
      }
    } else {
      if (isValid.length !== 0) {
        alert("Por favor llena los campos faltantes");
      }
      if (this.supplyExists(supply)) {
        alert("El insumo ya existe");
      }
    }
  };

  update = async () => {
    const supply = this.getSupplyFields();
    const isValid = this.validateFields(supply);

    if (isValid.length === 0) {
      try {
        this.setState(prevState => ({ ...prevState, isLoading: true }));
        await UPDATE_SUPPLY(supply);
        this.props.toggleSave(supply["id"]);
      } catch (e) {
        this.setState(prevState => ({ ...prevState, isLoading: false }));
        bugsnagClient.notify(e);
      }
    } else {
      if (isValid.length !== 0) {
        alert("Por favor llena los campos faltantes");
      }
    }
  };

  supplyExists = supply => {
    const current = this.state.existingItems;
    let exists = false;
    current.map(i => {
      if (supply["name"] === i.name || supply["insID"] === i.insID) {
        exists = true;
      }
      return exists;
    });
    return exists;
  };

  handleChange = (e, { name, value }) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  validateFields = validationObject => {
    let errors = [];
    const validation = validator(validationObject);

    validation.map(field => {
      const key = field.insID;
      const errorKey = `${key}Error`;

      if (!field.isValid && key) {
        errors.push(key);
      }
      this.setState(data => ({
        ...data,
        [errorKey]: !field.isValid,
      }));
    });
    return errors;
  };

  getSupplyFields = () => {
    const { id, insID, name, units, description, purchasePrice } = this.state;
    return {
      id,
      insID,
      name,
      units,
      description,
      purchasePrice,
    };
  };

  render() {
    return (
      <ApolloProvider client={client}>
        <Segment
          raised
          className="Modal"
          style={{ margin: "0 auto", width: "60%" }}
        >
          <FormHeader
            saveId="save-supply-btn"
            cancelId="cancel-supply-btn"
            onSave={this.props.isUpdate ? this.update : this.save}
            onCancel={this.props.onCancel}
            submitting={this.state.isLoading}
            text={this.props.isUpdate ? "Editar insumo" : "Nuevo insumo"}
          />

          {this.state.isLoading
            ? <OnLoading loading={this.state.isLoading} />
            : (
                <>
                  <Form className="Modal__Form">
                    {this.props.isUpdate ? (
                      <Form.Field
                        control={Input}
                        label="CLAVE DE INSUMO"
                        name="insID"
                        value={
                          unescape(this.state.insID) !== "undefined"
                            ? unescape(this.state.insID)
                            : ""
                        }
                        onChange={this.handleChange}
                        size="mini"
                        error={this.state.keyError}
                        autoComplete="off"
                        readOnly
                      />
                    ) : null}

                    <Form.Field
                      control={Input}
                      label="NOMBRE DE INSUMO"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      size="mini"
                      error={this.state.nameError}
                      readOnly={this.props.isUpdate}
                    />

                    <Form.Field
                      control={TextArea}
                      label="DESCRIPCIÓN"
                      name="description"
                      value={
                        unescape(this.state.description) !== "undefined"
                          ? unescape(this.state.description)
                          : ""
                      }
                      onChange={this.handleChange}
                      size="mini"
                      error={this.state.descriptionError}
                    />
                    <Form.Field
                      control={Input}
                      label="UNIDAD DE MEDIDA"
                      name="units"
                      value={
                        unescape(this.state.units) !== "undefined"
                          ? unescape(this.state.units)
                          : ""
                      }
                      onChange={this.handleChange}
                      size="mini"
                      error={this.state.unitsError}
                    />
                    <Form.Field required>
                      <label>PRECIO UNITARIO</label>
                      <CurrencyInput
                        value={this.state.purchasePrice || ""}
                        onChangeEvent={(event, maskedvalue, floatvalue) =>
                          this.handleChange(null, {
                            name: "purchasePrice",
                            value: floatvalue,
                          })
                        }
                      />
                    </Form.Field>
                  </Form>
                </>
              )
          }
        </Segment>
      </ApolloProvider>
    );
  }
}
