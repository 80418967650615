import gql from "graphql-tag";
import { client } from "../../apollo-config";

export default async ({
  name,
  email,
  phone,
  phone_office,
  currentEmail,
  id
}) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation{
        updateVendor(
          data:{
            contacts:{
              updateMany:{
                data:{
                  email:"${email}"
                  name:"${name}"
                  phone:"${phone}"
                  phone_office:"${phone_office}"
                }
                where:{email:"${currentEmail}"}
              }
            }
          }
          where:{id:"${id}"}
        ){
          id
        }
      }
      `
  });
  return mutation;
};
