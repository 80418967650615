import React from "react";
import { Form, Button, Grid, Segment, Input } from "semantic-ui-react";
import UsersQuery from "../../Molecules/UsersQuery";
import { PaginationBar } from "../../Molecules/PaginationBar";
import theme from "../../_theme";
import { getUsers } from "../../../graphql/queries/users";
import { GET_USER_ONLY_CONTRACTORS } from "../../../graphql/queries/contractors";
import { titleCase } from "../../../helpers"
import { CreateUser } from "../index";
import ChangePassword from "../ChangePassword";

export default class UsersList extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(window.localStorage.getItem("session"))
      ? JSON.parse(window.localStorage.getItem("session")).user
      : ""

    const query = user.userRol === "CONTRACTOR_RESPONSIBLE"
      ? GET_USER_ONLY_CONTRACTORS
      : getUsers

    this.state = {
      filter: query,
      totalRecords: 0,
      perPageRecords: 0,
      perPageRecordsUpdated: true,
      searchBoxUpdated: false,
      totalRecordsUpdated: false,
      filterVariables: {
        search: ""
      },
      idUser: "",
      modalChangePass: false,
      modal: false
    };
  }

  onPageChange = (page) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        skip: prevState.perPageRecords * (page - 1)
      }
    }))
  };

  onPerPageChange = (perPage) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        first: perPage
      }
    }))
  };

  debounce = (callback, delay) => {
    let timeOutId = null;
    return (...args) => {
      if (timeOutId) clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    }
  }

  searchNow = (e, { name, value }) => {
    if(!value) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords: 0,
        perPageRecords: 10,
        perPageRecordsUpdated: true,
        searchBoxUpdated: false,
        totalRecordsUpdated: false,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        },
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: true,
        filter: getUsers,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        }
      }))
    }
  };

  onCompleteData = data => {
    const totalRecords = data.users.length

    if (!this.state.totalRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords,
        totalRecordsUpdated: true,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }

    if (!this.state.perPageRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        perPageRecordsUpdated: true,
        perPageRecords: totalRecords
      }))
    }

    if (this.state.searchBoxUpdated) {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: false,
        totalRecords,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }
  };

  onOrderChange = (header) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        orderBy: header.orderEnum
      }
    }));
  }

  onReload = () => {
    this.setState(prevState =>({
      ...prevState,
    }))
  }

  toggleOpenCreate = (idUser) => {
    this.setState(prevState => ({
      ...prevState,
      idUser,
      modal: !prevState.modal
    }))
  }

  toggleOpenChangePassword = idUser => {
    this.setState(prevState => ({
      ...prevState,
      idUser,
      modalChangePass: !prevState.modalChangePass
    }))
  }

  render() {
    const { filter, filterVariables, totalRecords, perPageRecords } = this.state

    return (
      <Segment>
        <Grid>
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column width="13">
              <Form className="Wrapper__Body-Form Jax">
                <Form.Field inline>
                  <label>Buscar</label>
                  <Input
                    id="search2"
                    name="search2"
                    style={theme.styles.searchInput}
                    icon="search"
                    onChange={this.debounce(this.searchNow, 500)}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column textAlign="right" width="3">
              <Button
                basic
                icon="plus"
                className="Wrapper__Body-Form-ButtonAdd"
                content="Usuario"
                onClick={this.toggleOpenCreate}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <UsersQuery
                filter={filter}
                filterVariables={filterVariables}
                action={this.onCompleteData}
                onReload={this.onReload}
                onOrderChange={this.onOrderChange}
                toggleOpenCreate={this.toggleOpenCreate}
                toggleOpenChangePassword={this.toggleOpenChangePassword}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <PaginationBar
              onPageChange={(p) => this.onPageChange(p)}
              onPerPageChange={(p) => this.onPerPageChange(p)}
              totalRecords={totalRecords}
              perPageRecords={perPageRecords} />
          </Grid.Row>
        </Grid>

        {this.state.modal && (
          <CreateUser
            idUser={this.state.idUser}
            open={this.state.modal}
            onClose={this.toggleOpenCreate}
          />
        )}

        {this.state.modalChangePass && (
          <ChangePassword
            id={this.state.idUser}
            toggleOpenChangePassword={this.toggleOpenChangePassword}
          />
        )}
      </Segment>
    );
  }
}
