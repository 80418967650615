import React from "react";
import { Button, Segment, Input, Grid, Form } from "semantic-ui-react";
import ProjectsQuery from "../../../../Molecules/projectsQuery";
import theme from "../../../../_theme";
import { PaginationBar } from "../../../../Molecules/PaginationBar";
import getProjects from "../../../../../graphql/queries/projects/getProjects";
import { useCurrentUser } from "../../../../../hooks";
import { titleCase } from "../../../../../helpers";

export default class ClientsTemplte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: getProjects,
      totalRecords: 0,
      perPageRecords: 0,
      perPageRecordsUpdated: true,
      searchBoxUpdated: false,
      totalRecordsUpdated: false,
      filterVariables: {
        search: ""
      }
    };
  }

  onPageChange = (page) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        skip: prevState.perPageRecords * (page - 1)
      }
    }))
  };

  onPerPageChange = (perPage) => {
    this.setState(prevState => ({
      ...prevState,
      perPageRecordsUpdated: false,
      filterVariables: {
        ...prevState.filterVariables,
        first: perPage
      }
    }))
  };

  debounce = (callback, delay) => {
    let timeOutId = null;
    return (...args) => {
      if (timeOutId) clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        callback.apply(null, args);
      }, delay);
    }
  }

  searchNow = (e, { name, value }) => {
    if(!value) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords: 0,
        perPageRecords: 10,
        perPageRecordsUpdated: true,
        searchBoxUpdated: false,
        totalRecordsUpdated: false,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        },
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: true,
        filter: getProjects,
        filterVariables: {
          search: value,
          searchUpper: value.toUpperCase(),
          searchLower: value.toLowerCase(),
          searchCaps: titleCase(value)
        }
      }))
    }
  };

  onCompleteData = data => {
    const totalRecords = data.projects.length

    if (!this.state.totalRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        totalRecords,
        totalRecordsUpdated: true,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }

    if (!this.state.perPageRecordsUpdated) {
      this.setState(prevState => ({
        ...prevState,
        perPageRecordsUpdated: true,
        perPageRecords: totalRecords
      }))
    }

    if (this.state.searchBoxUpdated) {
      this.setState(prevState => ({
        ...prevState,
        searchBoxUpdated: false,
        totalRecords,
        perPageRecords: totalRecords > 10 ? 10 : totalRecords,
        filterVariables: {
          ...prevState.filterVariables,
          first: 10,
          skip: 0
        }
      }))
    }
  };

  onOrderChange = (header) => {
    this.setState(prevState => ({
      ...prevState,
      filterVariables: {
        ...prevState.filterVariables,
        orderBy: header.orderEnum
      }
    }));
  }

  onReload = () => {
    this.setState(prevState =>({
      ...prevState,
    }))
  }

  render() {
    const { filter, filterVariables, totalRecords, perPageRecords } = this.state
    const { openCreate, onUpdate, openDetails } = this.props
    const { currentUser: { user: userRol }} = useCurrentUser();

    return (
      <Segment>
        <Grid>
          <Grid.Row className="Wrapper__Body-Content">
            <Grid.Column width={13}>
              <Form className="Wrapper__Body-Form Jax">
                <Form.Group inline>
                  <Form.Field inline>
                    <label>Buscar</label>
                    <Input
                      id="search2"
                      name="search2"
                      style={theme.styles.searchInput}
                      icon="search"
                      onChange={this.debounce(this.searchNow, 500)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>

            <Grid.Column width="3" textAlign="right">
              {userRol !== "PURCHASES_RESPONSIBLE" && (
                <Button
                  basic
                  icon="plus"
                  className="Wrapper__Body-Form-ButtonAdd"
                  id="add-project"
                  content="Proyecto"
                  onClick={() => openCreate(false)}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ProjectsQuery
                filter={filter}
                filterVariables={filterVariables}
                action={this.onCompleteData}
                onReload={this.onReload}
                onUpdate={onUpdate}
                openDetails={openDetails}
                onOrderChange={this.onOrderChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <PaginationBar
              onPageChange={(p) => this.onPageChange(p)}
              onPerPageChange={(p) => this.onPerPageChange(p)}
              totalRecords={totalRecords}
              perPageRecords={perPageRecords} />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
