import React from "react";
import "./style.css";

export default class Modal extends React.Component {
  render() {
    return (
      <div id={this.props.id} className="modal-overlay">
        {this.props.children}
      </div>
    );
  }
}
