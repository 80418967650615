export default {
  "folio": "Folio de orden",
  "data.project.name": "Nombre del proyecto",
  "data.vendor.commercialName": "Proveedor",
  "warehouseName": "Almacén",
  "data.wasUpdatedAt": "Fecha de creación",
  "data.arrivalDate": "Fecha de entrega",
  "data.total": "Total",
  "data.paymentStatus": "Facturación",
  "data.deliveryStatus": "Estatus",
};
