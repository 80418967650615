import gql from "graphql-tag";
import { client } from "../../apollo-config";

const DELETE_DOCUMENT_ADITIVE_ITEM = async ({
  idContract,
  idAditive,
  idDocumentAditive,
  idRef,
  invoiced,
  paided,
  amountDeductives
}, { GET_ADITIVES_CONTRACT }) => {
  const mutation = await client.mutate({
    mutation: gql`
      mutation {
        updateContract(
          where: { id: "${idContract}" }
          data: {
            aditives: {
              update: {
                where: { id: "${idAditive}" }
                data: {
                  invoiced: ${invoiced}
                  paided: ${paided}
                  amountDeductives: ${amountDeductives}
                  documentsAditivas: {
                    update: {
                      where: { id: "${idDocumentAditive}" }
                      data: { refUpload: { delete: { id: "${idRef}" } } }
                    }
                  }
                }
              }
            }
          }
        ) {
          id
        }
      }
    `,
    refetchQueries: [{ query: GET_ADITIVES_CONTRACT, variables: { id: idContract } }]
  });
  return mutation;
};

export default DELETE_DOCUMENT_ADITIVE_ITEM;
