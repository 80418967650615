import { Container, Dimmer, Loader } from "semantic-ui-react";
import React from "react";

const OnLoading = ({ loading, error }) => {
  if (loading) {
    return (
      <Container fluid className="Wrapper__Body-Loader">
        <Dimmer active inverted >
          <Loader inverted content='Cargando...' />
        </Dimmer>
      </Container>
    );
  }

  if (error) {
    return (
      <Container fluid className="Wrapper__Body-Loader">
        <Dimmer active inverted>
          <Loader inverted content='Error' />
        </Dimmer>
      </Container>
    )
  }

  return (
    <Container fluid textAlign="center">
      "No se encontraron resultados para la búsqueda "
    </Container>
  );
}

export default OnLoading;
