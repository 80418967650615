import moment from "moment";
import {
  format,
  openDocumentsContractor,
  closeDocumentsContractor,
  noMonthlyDocumentsContractor,
  listMonthlyDocumentsContractor,
  limitDateDocuments,
  contractWithNewDocuments,
} from "../helpers";

const savePercentage = (contract, newDocuments = []) => {
  /* Init Approach support document old contract */
  const finishDate = contract.realFinishDate || contract.finishDate

  const permitDate = moment(limitDateDocuments)
  const initDate = moment(contract.startDate)
  let openDocumentsTotal = openDocumentsContractor.length
  let closeDocumentsTotal = closeDocumentsContractor.length
  let monthDocumentsTotal = listMonthlyDocumentsContractor.length

  if (initDate < permitDate) {
    if (!contractWithNewDocuments.includes(contract.id)) {
      openDocumentsTotal = 1
      closeDocumentsTotal = 1
      monthDocumentsTotal = 9
    }
  }
  /* Fin Approach support document old contract */

  const documentsContractor = contract.documentsContractor || []

  const beforeOpenDocument = documentsContractor.filter(document => openDocumentsContractor.includes(document.type));
  const openDocument = newDocuments.filter(document => openDocumentsContractor.includes(document.type));
  const beforeCloseDocument = documentsContractor.filter(document => closeDocumentsContractor.includes(document.type));
  const closeDocument = newDocuments.filter(document => closeDocumentsContractor.includes(document.type));
  const beforeMonthDocument = documentsContractor.filter(document => !noMonthlyDocumentsContractor.includes(document.type));
  const monthDocument = newDocuments.filter(document => !noMonthlyDocumentsContractor.includes(document.type));

  let percentageDocuments = {};

  if (openDocument.length > 0) {
    const openPercentage = +(((openDocument.length + beforeOpenDocument.length) * 100) / openDocumentsTotal).toFixed(2);
    percentageDocuments.openPercentage = openPercentage >= 100 ? 100 : openPercentage
    percentageDocuments.closePercentage = contract.closePercentage;
    percentageDocuments.monthPercentage = contract.monthPercentage;
  }

  if (closeDocument.length > 0) {
    const closePercentage = +(((closeDocument.length + beforeCloseDocument.length) * 100) / closeDocumentsTotal).toFixed(2);
    percentageDocuments.openPercentage = contract.openPercentage;
    percentageDocuments.closePercentage = closePercentage >= 100 ? 100 : closePercentage
    percentageDocuments.monthPercentage = contract.monthPercentage;
  }

  if (monthDocument.length > 0) {
    let months = 0;
    let percentage = 0;
    /* Cantidad de documentos a agregar */
    const length = monthDocument.length;
    /* Documentos que ya estaban agregados */
    const beforeLength = beforeMonthDocument.length;

    // Documentos total a cumplir
    const monthsRealDocs = format.howDocumentMonthly(contract.startDate, finishDate);

    // Total de documentos al Mes Actual
    const nowMonth = format.howDocumentMonthly(contract.startDate, new Date());

    if (nowMonth < monthsRealDocs) {
      months = nowMonth;
    } else {
      months = monthsRealDocs
    }

    const documents = monthDocumentsTotal * months;
    percentage = ((length + beforeLength) * 100) / documents;
    percentage = +percentage.toFixed(2);

    percentageDocuments.openPercentage = contract.openPercentage;
    percentageDocuments.closePercentage = contract.closePercentage;
    /* Changed to unlock an account with duplicated documents
     * percentage giving more than 100 */
    percentageDocuments.monthPercentage = percentage >= 100 ? 100 : percentage;
  }

  return percentageDocuments;
}

const getPercentageMonthly = (contract) => {
  const finishDate = contract.realFinishDate || contract.finishDate

  /* Init Approach support document old contract */
  const permitDate = moment(limitDateDocuments)
  const initDate = moment(contract.startDate)
  let monthDocumentsTotal = listMonthlyDocumentsContractor.length

  if (initDate < permitDate) {
    if (!contractWithNewDocuments.includes(contract.id)) {
      monthDocumentsTotal = 9
    }
  }
  /* Fin Approach support document old contract */

  const documentsContractor = contract.documentsContractor || []
  const length = documentsContractor.filter(document => !noMonthlyDocumentsContractor.includes(document.type)).length;

  let months = 0;

  // Documentos total a cumplir
  const monthsRealDocs = format.howDocumentMonthly(contract.startDate, finishDate);

  // Total de documentos al Mes Actual
  const nowMonth = format.howDocumentMonthly(contract.startDate, new Date());

  if (nowMonth < monthsRealDocs) {
    months = nowMonth;
  } else {
    months = monthsRealDocs
  }

  const documents = monthDocumentsTotal * months;
  let percentage = +((length * 100) / documents).toFixed(2);

  return isNaN(percentage) ? 0 : percentage
}

const deletePercentage = (documentType, contract) => {
  const finishDate = contract.realFinishDate || contract.finishDate

  /* Init Approach support document old contract */
  const permitDate = moment(limitDateDocuments)
  const initDate = moment(contract.startDate)
  let openDocumentsTotal = openDocumentsContractor.length
  let closeDocumentsTotal = closeDocumentsContractor.length
  let monthDocumentsTotal = listMonthlyDocumentsContractor.length

  if (initDate < permitDate) {
    if (!contractWithNewDocuments.includes(contract.id)) {
      openDocumentsTotal = 1
      closeDocumentsTotal = 1
      monthDocumentsTotal = 9
    }
  }
  /* Fin Approach support document old contract */

  let percentageDocuments = {};
  const documentsContractor = contract.documentsContractor || []

  if (openDocumentsContractor.includes(documentType)) {
    const documents = documentsContractor.filter(document => openDocumentsContractor.includes(document.type));
    const openDocuments = documents.length > 0 ? documents.length - 1 : 0
    const openPercentage = +((openDocuments * 100) / openDocumentsTotal).toFixed(2);
    percentageDocuments.openPercentage = (openPercentage >= 100 && openDocumentsTotal === 1) ? 0 : openPercentage
    percentageDocuments.closePercentage = contract.closePercentage;
    percentageDocuments.monthPercentage = contract.monthPercentage;
  } else if (closeDocumentsContractor.includes(documentType)) {
    const documents = documentsContractor.filter(document => closeDocumentsContractor.includes(document.type));
    const closeDocuments = documents.length > 0 ? documents.length - 1 : 0
    const closePercentage = +((closeDocuments * 100) / closeDocumentsTotal).toFixed(2);
    percentageDocuments.openPercentage = contract.openPercentage;
    percentageDocuments.closePercentage = (closePercentage >= 100 && closeDocumentsTotal === 1) ? 0 : closePercentage
    percentageDocuments.monthPercentage = contract.monthPercentage;
  } else {
    let months = 0;

    // Documentos total a cumplir
    const monthsRealDocs = format.howDocumentMonthly(contract.startDate, finishDate);

    // Total de documentos al Mes Actual
    const nowMonth = format.howDocumentMonthly(contract.startDate, new Date());

    if (nowMonth < monthsRealDocs) {
      months = nowMonth;
    } else {
      months = monthsRealDocs
    }

    const documents = monthDocumentsTotal * months;
    let decrease = +(100 / documents).toFixed(2);
    let percentage = +(contract.monthPercentage - decrease).toFixed(2);

    percentageDocuments.openPercentage = contract.openPercentage;
    percentageDocuments.closePercentage = contract.closePercentage;
    percentageDocuments.monthPercentage = (percentage <= 0.99) ? 0 : percentage;
  }

  return percentageDocuments;
}

/**
 * This is closely based on the savePercentage function, so if the logic changes
 * on that function you should update this one too
 * @param contract
 */
const reCalculateDocsPercentage = (contract) => {
  /* Init Approach support document old contract */
  const finishDate = contract.realFinishDate || contract.finishDate

  const permitDate = moment(limitDateDocuments)
  const initDate = moment(contract.startDate)
  let openDocumentsTotal = openDocumentsContractor.length
  let closeDocumentsTotal = closeDocumentsContractor.length
  let monthDocumentsTotal = listMonthlyDocumentsContractor.length

  if (initDate < permitDate) {
    if (!contractWithNewDocuments.includes(contract.id)) {
      openDocumentsTotal = 1
      closeDocumentsTotal = 1
      monthDocumentsTotal = 9
    }
  }
  /* Fin Approach support document old contract */

  const documentsContractor = contract.documentsContractor || []

  const beforeOpenDocument = documentsContractor.filter(document => openDocumentsContractor.includes(document.type));
  const beforeCloseDocument = documentsContractor.filter(document => closeDocumentsContractor.includes(document.type));
  const beforeMonthDocument = documentsContractor.filter(document => !noMonthlyDocumentsContractor.includes(document.type));

  let percentageDocuments = {};

  // OPENING DOCS
  const openPercentage = +(((beforeOpenDocument.length) * 100) / openDocumentsTotal).toFixed(2);
  percentageDocuments.openPercentage = openPercentage >= 100 ? 100 : openPercentage
  percentageDocuments.closePercentage = contract.closePercentage;
  percentageDocuments.monthPercentage = contract.monthPercentage;


  // CLOSING DOCUMENTS
  const closePercentage = +(((beforeCloseDocument.length) * 100) / closeDocumentsTotal).toFixed(2);
  percentageDocuments.openPercentage = contract.openPercentage;
  percentageDocuments.closePercentage = closePercentage >= 100 ? 100 : closePercentage
  percentageDocuments.monthPercentage = contract.monthPercentage;

  // MONTHLY DOCS
  let months = 0;
  let percentage = 0;

  /* Documentos que ya estaban agregados */
  const beforeLength = beforeMonthDocument.length;

  // Documentos total a cumplir
  const monthsRealDocs = format.howDocumentMonthly(contract.startDate, finishDate);

  // Total de documentos al Mes Actual
  const nowMonth = format.howDocumentMonthly(contract.startDate, new Date());

  if (nowMonth < monthsRealDocs) {
    months = nowMonth;
  } else {
    months = monthsRealDocs
  }

  const documents = monthDocumentsTotal * months;
  percentage = ((beforeLength) * 100) / documents;
  percentage = +percentage.toFixed(2);

  percentageDocuments.openPercentage = contract.openPercentage;
  percentageDocuments.closePercentage = contract.closePercentage;
  /* Changed to unlock an account with duplicated documents
   * percentage giving more than 100 */
  percentageDocuments.monthPercentage = percentage >= 100 ? 100 : percentage;

  return percentageDocuments;
}

export default {
  deletePercentage,
  getPercentageMonthly,
  savePercentage,
  reCalculateDocsPercentage
};
