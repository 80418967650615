import React from "react";
import { Form, Input, TextArea } from "semantic-ui-react";
import CurrencyInput from "react-currency-input";
import InsumosForm from "../InsumosForm";

export default class GeneralForm extends React.Component {
  formatDate = date => {
    var monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day}/${monthNames[monthIndex]}/${year}`;
  };

  statusDict = {
    IS_PO_APPROVED: "Aprobada",
    IS_PO_REVIEW: "En revisión",
    IS_PO_REVISED: "Revisada",
    IS_PO_SENT: "Enviada",
    IS_PO_CANCEL: "Cancelada",
  };

  paymentConditionsDict = {
    CASH: "Contado",
    CREDIT: "Crédito",
  };

  render() {
    const { folio, requisition, project, vendor, warehouse } = this.props;
    let residents = project.residents.map(resident => resident.name)
    let responsibles = project.responsibles.map(responsible => responsible.name)
    let createdAt = new Date(requisition.createdAt);
    let shipmentDate = requisition.deliveryDate;

    return (
      <React.Fragment>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="FOLIO DE ORDEN"
              value={folio}
              readOnly
            />

            <Form.Field
              control={Input}
              label="FECHA DE CREACIÓN"
              value={this.formatDate(createdAt)}
              readOnly
            />
            <Form.Field
              control={Input}
              label="ESTATUS"
              value={this.statusDict[requisition.status]}
              readOnly
            />
            <Form.Field
              control={CurrencyInput}
              label="IMPORTE"
              value={requisition.subtotal}
              readOnly
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              label="FOLIO DE REQUISICIÓN"
              name="folio"
              control={Input}
              value={
                isNaN(parseInt(requisition.folio)) ? "" : requisition.folio
              }
              readOnly
              width={10}
              autoComplete="new-password"
            />
            <Form.Field
              control={Input}
              label="PROVEEDOR"
              width={10}
              value={vendor.commercialName}
              readOnly
            />
            <Form.Field
              control={Input}
              label="ELABORÓ"
              width={10}
              value={requisition.revisedBy}
              readOnly
            />
            <Form.Field
              control={Input}
              label="DESCUENTO"
              width={5}
              value={requisition.discountPercentage}
              readOnly
            />
            <Form.Field
              control={CurrencyInput}
              label="&nbsp;"
              width={5}
              value={requisition.discount}
              readOnly
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="NÚMERO DE PRESUPUESTO (CLI)"
              value={project.cli}
              readOnly
            />
            <Form.Field
              control={Input}
              label="ALMACÉN"
              value={warehouse[0].name}
              readOnly
            />
            <Form.Field
              control={Input}
              label="AUTORIZÓ"
              value={requisition.authorizedBy}
              readOnly
            />
            <Form.Field
              control={CurrencyInput}
              label="SUBTOTAL"
              value={requisition.amount}
              readOnly
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="NOMBRE DE PROYECTO"
              width={10}
              value={project.name}
              readOnly
            />
            <Form.Field
              control={Input}
              label="TIEMPO DE ENTREGA"
              width={10}
              value={shipmentDate}
              readOnly
            />
            <Form.Field
              control={Input}
              label="RESIDENTE"
              width={10}
              value={residents}
              readOnly
            />
            <Form.Field
              control={Input}
              label="IVA"
              width={10}
              value={requisition.iva}
              readOnly
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="RESPONSABLE DEL PROYECTO"
              width={10}
              value={responsibles}
              readOnly
            />
            <Form.Field
              control={Input}
              label="CONDICIONES DE PAGO"
              value={this.paymentConditionsDict[requisition.paymentConditions]}
              width={10}
              readOnly
            />
            <Form.Field
              control={Input}
              label="MONEDA"
              width={10}
              value={requisition.currency}
              readOnly
            />
            {/**retention */}
            <Form.Field
              control={Input}
              label="RETENCIÓN"
              width={5}
              value={requisition.retentionPercentage}
              readOnly
            />
            <Form.Field
              control={CurrencyInput}
              label="&nbsp;"
              width={5}
              value={requisition.retention}
              readOnly
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="SOLICITANTE"
              value={requisition.petitioner}
              readOnly
            />
            <Form.Field />
            <Form.Field />
            <Form.Field
              control={CurrencyInput}
              label="TOTAL"
              value={requisition.total}
              readOnly
            />
          </Form.Group>
          <Form.Field
            control={TextArea}
            label="UBICACIÓN DEL PROYECTO"
            value={
              requisition.project.address !== "undefined"
                ? requisition.project.address
                : ""
            }
            readOnly
          />
          <Form.Field
            control={TextArea}
            label="NOTAS"
            value={requisition.notes}
            readOnly
          />
        </Form>
      </React.Fragment>
    );
  }
}
