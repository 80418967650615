import gql from "graphql-tag"

const GET_CONTRACT_ADITIVE_ACCOUNT_BALANCE_BY_ID = gql `
  query getContractAditiveAccountBalanceById($id: ID!) {
    aditiveAccountBalance(where: { id: $id }) {
      id
      originalContract{
        IDFolio
        client{
          id
          commercialName
        }
        project{
          id
          cli
          name
          key: id
          text: name
          value: id
        }
      }
      currency
      amountAmortization
      amountRetained
      amountDeductives
      amountBilled
      amountPaid
      amountContract
      percentAmortization
      percentGuaranteeFund
      startDate
      finishDate
      status
      description
      amountAdvancePayment
      amountByAmortization
      amountByRetained
      amountTotalRetained
      amountContractUpdate
      amountByBilled
      amountByPaid
      documentsContractAccountBalance {
        id
        type
        concept
        elaborated {
          id
          name
        }
        dateElaborated
        currency
        amountAmortization
        amountGuaranteeFund
        invoiceNumber
        status
        iva
        amount
        amountIva
        total
        refUpload {
          id
          type
          reference
        }
      }
    }
  }
`;

export default GET_CONTRACT_ADITIVE_ACCOUNT_BALANCE_BY_ID;
